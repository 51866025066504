import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn } from '@angular/router';
import { ResponsePortalCaseLogDtoPageResponse } from '../../../../../../generated/apex-rest';
import { PortalService } from '../../portal.service';

export const PortalCaseLogsResolver: ResolveFn<ResponsePortalCaseLogDtoPageResponse> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(PortalService);

  const caseId = Number(route.paramMap.get('CaseId'));

  const caseItem = route.parent.data.cases.Collection.find((c) => Number(c.id) === caseId);

  if (!caseItem) {
    throw new Error('case item not found');
  }

  const projectId = caseItem.ProjectId;
  const apartmentId = caseItem.ApartmentId;

  if (!projectId || !apartmentId) {
    throw new Error('Project id and apartment id is required for case logs');
  }

  const { caseLogOrderBy, caseLogOrder, caseLogPage, caseLogLimit } = route.queryParams;

  const params: Params = {
    orderBy: caseLogOrderBy ?? 'createdAt',
    order: caseLogOrder ?? 'DESC',
    page: caseLogPage ?? '0',
    limit: caseLogLimit ?? '100',
  };

  return service.readProjectApartmentCaseLogs(projectId, apartmentId, caseId, ...Object.values(params));
};
