<div
  class="loader-wrapper"
  [class.fade-in]="loading"
  [class.overlay]="overlay">
  <div class="loader-inner df c ac">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 110 110"
      class="loader-svg">
      <g class="icon-wrapper">
        <g class="icon-animate">
          <path
            fill="#00919D"
            d="M40.5526 63.55L27.7526 50.75L52.4526 1.35C53.3526 -0.45 55.8526 -0.45 56.7526 1.35L72.0526 32.05L40.5526 63.55Z">
            <animate
              attributeName="fill"
              values="#00919D;#46464b;#00636f;#3fafb6;#7fccd0;#beeae9;#00919D"
              begin="0s"
              dur="4s"
              fill="freeze"
              repeatCount="indefinite" />
          </path>
        </g>
        <g class="icon-animate">
          <path
            fill="#323237"
            d="M106.653 109.05H2.45257C0.652571 109.05 -0.547426 107.15 0.252574 105.55L21.8526 62.35L40.5526 81.05L77.8526 43.75L108.753 105.65C109.653 107.15 108.453 109.05 106.653 109.05Z"></path>
        </g>
      </g>
    </svg>

    <div
      *ngIf="!!text"
      class="loader-text mat-h1 amt df ab jc">
      <span>{{ text }}</span>
      <div class="dot-flashing"></div>
    </div>
  </div>
</div>
