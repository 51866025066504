<h1 mat-dialog-title>
  <T str="Request: delete entry card"></T>
</h1>

<div mat-dialog-content>
  <form
    #form="ngForm"
    class="df">
    <mat-form-field class="f1">
      <input
        matInput
        type="text"
        name="message"
        [placeholder]="'Message' | translate"
        [(ngModel)]="message"
        required />
    </mat-form-field>
  </form>
</div>

<div
  mat-dialog-actions
  class="df">
  <button
    mat-button
    mat-dialog-close>
    <T str="Cancel"></T>
  </button>
  <button
    mat-raised-button
    (click)="delete()"
    color="primary"
    [disabled]="!form.valid">
    <T str="Send"></T>
  </button>
</div>
