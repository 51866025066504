import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Observable, Subscription, filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssumeUserComponent } from '../../../../apex/src/app/components/assume-user/assume-user.component';
import { AvatarModule } from '../../../../apex/src/app/components/avatar/avatar.module';
import { TranslateModule } from '../../../../apex/src/app/components/translate/translate.module';
import { Profile } from '../../../../apex/src/app/models/profile';
import { UserService } from '../../../../apex/src/app/services/user/user.service';
import { environment } from '../../../../apex/src/environments/environment';
import { PortalService } from '../portal.service';

@Component({
  selector: 'portal-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    AvatarModule,
    RouterLinkActive,
    MatIconModule,
    TranslateModule,
    AssumeUserComponent,
  ],
})
export default class SidebarComponent implements OnInit, OnDestroy {
  @Input() drawer: MatDrawer | null;

  entitiesRead$: Observable<number>;
  signOutUrl = `${environment.api}/user/auth/sign-out?destination=portal`;

  profile$: Observable<Profile>;

  subscription$$: Subscription = new Subscription();

  constructor(
    private router: Router,
    private portalService: PortalService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.entitiesRead$ = this.portalService.entitiesRead;
    this.profile$ = this.userService.profile$;

    this.subscription$$.add(
      this.profile$
        .pipe(
          filter((profile) => !!profile?.id),
          map((profile) => profile?.id),
        )
        .subscribe(),
    );

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => this.closeDrawer());
  }

  closeDrawer(): void {
    void this.drawer?.close();
  }

  ngOnDestroy(): void {
    this.subscription$$.unsubscribe();
  }

  signOut(): void {
    this.userService.clearProfile();
  }
}
