<ng-container *ngIf="viewDatas?.length; else noData">
  <ng-container *ngFor="let viewData of viewDatas">
    <apex-article-view-card [viewData]="viewData"></apex-article-view-card>
  </ng-container>

  <ng-container *ngIf="count > limit">
    <mat-paginator
      class="mat-elevation-z2 amh amb"
      [pageSizeOptions]="[25, 50, 100]"
      [length]="count"
      [pageIndex]="page"
      [pageSize]="limit"
      showFirstLastButtons
      (page)="changePage($event)">
    </mat-paginator>
  </ng-container>
</ng-container>

<ng-template #noData>
  <div class="df ac jc ap am">
    <T str="No news yet"></T>
  </div>
</ng-template>
