import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Obj } from 'projects/apex/src/app/features/object/object.model';
import { Profile } from 'projects/apex/src/app/models/profile';
import { Tenancy } from 'projects/apex/src/app/models/tenancy';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { t } from '../../../../apex/src/app/components/translate/translate.function';

interface IRouteData {
  profile: Profile;
  tenancy: Tenancy;
  objects: Obj[];
}

@Component({
  selector: 'portal-tenancy',
  templateUrl: 'portal-tenancy-page.component.html',
})
export class PortalTenancyPageComponent implements OnInit, OnDestroy {
  @Input() tenancy: Tenancy;

  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  activeLink: string;

  links = [
    {
      path: 'info',
      name: t('Information'),
    },
    {
      path: 'cases',
      name: t('Cases'),
    },
    {
      path: 'files',
      name: t('Files'),
    },
    {
      path: 'access-card',
      name: t('Access Card'),
    },
  ];

  data$: Observable<IRouteData>;

  profile$: Observable<Profile>;
  tenancy$: Observable<Tenancy>;
  objects$: Observable<Obj[]>;

  subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.data$ = this.route.data.pipe(
      map((d) => ({
        profile: d.profile,
        tenancy: d.tenancy,
        objects: d.objects,
      })),
    );

    this.profile$ = this.data$.pipe(map((d) => d.profile));
    this.tenancy$ = this.data$.pipe(map((d) => d.tenancy));
    this.objects$ = this.data$.pipe(map((d) => d.objects));

    const parts = this.router.url.split('/');

    this.activeLink = parts.pop();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
