<div class="df r w ac">
  <apex-client-apartment-card
    *ngFor="let apartment of apartments"
    class="w-25 t-w-50 p-w-100"
    [apartment]="apartment"
    (clicked)="emitClicked(apartment)">
  </apex-client-apartment-card>
</div>
<div
  *ngIf="!apartments || !apartments.length"
  class="emptystate-mypage">
  <T str="No available addons"></T>
</div>
