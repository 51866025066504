import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PortalAddonUrlPipe } from '../../../../../../portal/src/app/apartment/addon/pipe/portal-addon-url.pipe';
import { AddonOverlayListComponent } from '../../../components/addon-overlay-list/addon-overlay-list.component';
import { AddonSearchButtonComponent } from '../../../components/addon-search-button/addon-search-button.component';
import { FilePreviewModule } from '../../../components/file-preview/file-preview.module';
import { FileUsagePreviewModule } from '../../../components/file-usage-preview/file-usage-preview.module';
import { ItemModule } from '../../../components/item/item.module';
import { LoaderModule } from '../../../components/loader/loader.module';
import { TextareaModule } from '../../../components/textarea/textarea.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { InViewDirectiveModule } from '../../../directives/in-view/in-view.directive.module';
import { ApexCurrencyPipeModule } from '../../../pipes/currency/currency.pipe.module';
import { ClientAddonAlertDialogComponent } from './components/addon-apartment-alert-dialog/alert-dialog.component';
import { ClientAddonApartmentCardComponent } from './components/addon-apartment-card/card.component';
import { ClientAddonApartmentInfoComponent } from './components/addon-apartment-info/info.component';
import { ClientAddonCartProductCardComponent } from './components/addon-cart-product-card/product-card.component';
import { ClientAddonCartProductListComponent } from './components/addon-cart-product-list/product-list.component';
import { ClientAddonCartTotalsComponent } from './components/addon-cart-totals/totals.component';
import { ClientAddonApartmentTreeNodeComponent } from './components/addon-category-tree-apartment-node/node.component';
import { ClientAddonApartmentTreeComponent } from './components/addon-category-tree-apartment/tree.component';
import { ClientAddonOrderCardComponent } from './components/addon-order-card/card.component';
import { ClientAddonOrderListComponent } from './components/addon-order-list/list.component';
import { ClientAddonOrderTotalsComponent } from './components/addon-order-totals/totals.component';
import { ClientApartmentCardComponent } from './components/apartment-card/apartment-card.component';
import { ClientAddonApartmentListComponent } from './components/apartment-list/apartment-list.component';
import { ClientAddonBreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ClientAddonOverviewComponent } from './components/overview/overview.component';
import { ClientAddonPageComponent } from './pages/addons/addons.component';
import { ClientAddonApartmentsPageComponent } from './pages/apartments/apartments.component';
import { ClientAddonCartPageComponent } from './pages/cart/cart.component';
import { ClientAddonConfirmationPageComponent } from './pages/confirmation/confirmation.component';
import { ClientAddonInfoPageComponent } from './pages/info/info.component';
import { ClientAddonOrderPageComponent } from './pages/order/order.component';
import { ClientAddonOrdersPageComponent } from './pages/orders/orders.component';
import { ClientAddonOverviewPageComponent } from './pages/overview/overview.component';

const components = [
  ClientAddonPageComponent,
  ClientAddonOverviewPageComponent,
  ClientAddonCartPageComponent,
  ClientAddonInfoPageComponent,
  ClientAddonOrdersPageComponent,
  ClientAddonOrderPageComponent,
  ClientAddonApartmentsPageComponent,
  ClientAddonConfirmationPageComponent,
  ClientAddonApartmentCardComponent,
  ClientAddonApartmentListComponent,
  ClientAddonBreadcrumbComponent,
  ClientApartmentCardComponent,
  ClientAddonApartmentInfoComponent,
  ClientAddonOrderCardComponent,
  ClientAddonOrderListComponent,
  ClientAddonCartProductCardComponent,
  ClientAddonCartProductListComponent,
  ClientAddonOrderTotalsComponent,
  ClientAddonApartmentTreeComponent,
  ClientAddonApartmentTreeNodeComponent,
  ClientAddonCartTotalsComponent,
  ClientAddonAlertDialogComponent,
  ClientAddonOverviewComponent,
];

@NgModule({
  declarations: [components],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    MatListModule,
    MatDialogModule,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatRippleModule,
    OverlayModule,
    MatAutocompleteModule,
    MatSelectModule,

    TranslateModule,
    FileUsagePreviewModule,
    ApexCurrencyPipeModule,
    TextareaModule,
    InViewDirectiveModule,
    FilePreviewModule,
    LoaderModule,
    PortalAddonUrlPipe,
    ItemModule,
    AddonSearchButtonComponent,
    AddonOverlayListComponent,
  ],
  exports: [components],
})
export class ClientPageAddonComponentsModule {}
