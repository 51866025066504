import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { orderBy } from 'lodash-es';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { AddonCart, Product } from 'projects/apex/src/app/models/addon-cart';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { scrollWrap } from 'projects/apex/src/app/utils/functions';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { t } from '../../../../../components/translate/translate.function';
import { snackErr } from '../../../../../modules/snack.module';
import { ClientAddonCartTotalsComponent } from '../../components/addon-cart-totals/totals.component';

@Component({
  selector: 'apex-client-addon-cart-page',
  templateUrl: './cart.component.html',
})
export class ClientAddonCartPageComponent implements OnInit, OnDestroy {
  @ViewChild(ClientAddonCartTotalsComponent) totals: ClientAddonCartTotalsComponent;

  cart: AddonCart;
  apartment: Apartment;
  valid: boolean;

  working = false;
  signing = '';

  addonManager: boolean;
  clientId: number;

  generating = false;

  inPortal = false;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientPageService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    scrollWrap(0, 0);

    const data = this.route.snapshot.data;

    this.cart = data.cart;
    this.apartment = data.apartment;
    this.addonManager = data.addonManager;

    if (this.cart) {
      this.checkIfValid();
    }

    this.signing = this.route.snapshot.queryParamMap.get('signing');

    if (this.cart?.id && this.signing) {
      this.working = true;

      if (this.signing === 'cancel' || this.signing === 'error') {
        this.working = false;
      }
    }

    this.inPortal = data.portal;
  }

  remove(product: Product): void {
    this.subscription.add(
      this.clientService
        .removeProductInCart(this.apartment.id, product.AddonId)
        .pipe(take(1))
        .subscribe((cart: AddonCart) => {
          this.refreshCart(cart);
          this.checkIfValid();
        }),
    );
  }

  update(product: Product): void {
    this.subscription.add(
      this.clientService
        .addProductInCart(this.apartment.id, product.AddonId, product.ClientComment)
        .pipe(take(1))
        .subscribe((cart: AddonCart) => {
          this.refreshCart(cart);
          this.checkIfValid();
        }),
    );
  }

  order(): void {
    this.checkIfValid();

    if (this.valid) {
      this.working = true;

      if (this.addonManager) {
        void this.router.navigate(['../', this.clientId, 'order'], { relativeTo: this.route });
      } else {
        this.generating = true;

        this.subscription.add(
          this.clientService
            .orderAddonCart(this.cart.ApartmentId)
            .pipe(take(1))
            .subscribe({
              next: (res) => {
                this.generating = false;

                if (res.signing) {
                  window.location.href = res.url;
                } else {
                  if (this.inPortal) {
                    void this.router.navigate([
                      '/project',
                      this.apartment.ProjectId,
                      'apartment',
                      this.apartment.id,
                      'addon',
                      'confirmation',
                    ]);
                  } else {
                    void this.router.navigate(['minside', 'tilvalg', this.apartment.id, 'bekreftelse']);
                  }
                }
              },
              error: (err) => {
                this.generating = false;
                snackErr(t('Error'), err);
              },
            }),
        );
      }
    }
  }

  checkIfValid(): void {
    let valid = true;

    if (this.cart?.Product?.length) {
      this.cart.Product.forEach((product: Product) => {
        if (product && product.Markings.length < product.AddonApartment.markingMin) {
          valid = false;
        }
      });
    } else {
      valid = false;
    }

    this.valid = valid;
  }

  refreshCart(cart: AddonCart): void {
    if (this.cart) {
      this.cart.id = cart.id;
      this.cart.ApartmentId = cart.ApartmentId;
      this.cart.Product = this.cart.Product.filter((p) =>
        cart.Product.find((pp) => pp.AddonApartmentId === p.AddonApartmentId),
      ).map((p) =>
        Object.assign(
          p,
          cart.Product.find((pp) => pp.AddonApartmentId === p.AddonApartmentId),
        ),
      );
      this.cart.Product = orderBy(this.cart.Product, ['Addon.name'], ['asc']);
      this.totals.refreshTotals(cart.Product);
    }
  }

  back(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
