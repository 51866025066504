import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment/moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseFileUsageDto, ResponsePortalApartmentDto } from '../../../../../../../generated/apex-rest';

@Component({
  selector: 'apex-apartment-info',
  templateUrl: 'apartment-info.component.html',
  styleUrls: ['apartment-info.component.scss'],
})
export class ApartmentInfoComponent {
  @Input() expanded = false;

  apartment$: Observable<ResponsePortalApartmentDto>;
  companyLogos: ResponseFileUsageDto[] = [];
  bannerImages: ResponseFileUsageDto[] = [];

  ownerName = '';
  handoverDate: Date;

  constructor(private route: ActivatedRoute) {
    this.apartment$ = this.route.data.pipe(
      map(
        (data) =>
          [data.apartment, data.companyLogos, data.bannerImages] as [
            ResponsePortalApartmentDto,
            ResponseFileUsageDto[],
            ResponseFileUsageDto[],
          ],
      ),
      map(([apartment, companyLogos, bannerImages]) => {
        this.companyLogos = companyLogos;
        this.bannerImages = bannerImages;

        const { projectOwner } = apartment.Project ?? {};

        if (apartment.takeoverAt) {
          this.handoverDate = moment(apartment.takeoverAt).toDate();
        }

        if (!!projectOwner && projectOwner.length > 0) {
          this.ownerName = projectOwner;
        } else {
          this.ownerName = apartment.Project?.Company?.name ?? '';
        }

        return apartment;
      }),
    );
  }
}
