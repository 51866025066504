import { Component, Input, OnChanges } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { CreatePortalCaseLogDto, ResponsePortalCaseLogDto } from '../../../../../../../generated/apex-rest';
import { CaseLog } from '../../../../../../apex/src/app/models/case-log';
import { User } from '../../../../../../apex/src/app/models/user';
import { snack } from '../../../../../../apex/src/app/modules/snack.module';
import { PortalService } from '../../../portal.service';

@Component({
  selector: 'portal-portal-case-log',
  templateUrl: './portal-case-log.component.html',
})
export class PortalCaseLogComponent implements OnChanges {
  @Input() projectId: number;
  @Input() apartmentId: number;
  @Input() caseId: number;

  @Input() caseLogPageResponse: ResponsePortalCaseLogDto[];
  viewCaseLogs: CaseLog[] = [];

  createCaseLog: CreatePortalCaseLogDto = {
    forContractor: 1,
    message: '',
  };

  limit = 100;
  page = 0;
  count = 0;

  constructor(
    private portalService: PortalService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnChanges(): void {
    this.viewCaseLogs = this.caseLogPageResponse?.map((log) => this.mapLogToCaseLog(log));
  }

  changePage(event: PageEvent): void {
    this.page = event.pageIndex;
    this.limit = event.pageSize;

    this.updateQueryParams();
  }

  async addMessage(form: NgForm): Promise<void> {
    if (!this.createCaseLog?.message) {
      return;
    }

    const savedCaseLog = await this.portalService.createProjectApartmentCaseLog(
      this.projectId,
      this.apartmentId,
      this.caseId,
      this.createCaseLog,
    );

    snack(t('Message sent'));

    this.createCaseLog.message = '';

    if (form) {
      form.reset();

      Object.values(form.controls).forEach((control) => control.markAsPending());
    }

    this.viewCaseLogs.unshift(this.mapLogToCaseLog(savedCaseLog));
  }

  private mapLogToCaseLog(log: ResponsePortalCaseLogDto): CaseLog {
    return new CaseLog({
      CaseId: this.caseId,
      message: log.message,
      status: null,
      creatorRole: 0,
      LogMailId: log.EmailId,
      UserId: -1,
      migratedFor: 1,
      createdAt: new Date(log.createdAt),
      updatedAt: new Date(log.updatedAt),
      system: log.system,
      forClient: 1,
      forContractor: 0,
      externalId: '',
      error: false,
      translate: log.translate,
      translateVars: log.translateVars as Record<string, string | number | boolean | null>,
      id: log.id,

      User: log.Creator as unknown as User,
    });
  }

  private updateQueryParams(): void {
    const queryParams: Params = {
      caseLogPage: this.page,
      caseLogLimit: this.limit,
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }
}
