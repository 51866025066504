/// <reference types="googlemaps" />

import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, signal, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, merge, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseAddressDto } from '../../../../../../../generated/apex-rest';
import GoogleMapPlacesService from '../../../services/google-map-places/google-map-places.service';
import GoogleMapPlacesServiceModule from '../../../services/google-map-places/google-map-places.service.module';
import { locale } from '../../../utils/functions';

@Component({
  selector: 'apex-formatted-address',
  templateUrl: './formatted-address.component.html',
  standalone: true,
  imports: [CommonModule, GoogleMapPlacesServiceModule],
})
export class FormattedAddressComponent implements OnChanges, OnInit, OnDestroy {
  @Input({ required: true })
  address: ResponseAddressDto;

  locale = 'nb';

  formattedAddress = signal('');

  private readonly subscription = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly googleMapPlacesService: GoogleMapPlacesService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.address) {
      return;
    }

    const previous = changes.address.previousValue as ResponseAddressDto;
    const current = changes.address.currentValue as ResponseAddressDto;

    if (previous?.placeId === current?.placeId) {
      return;
    }

    if (!current?.placeId) {
      this.formattedAddress.set(`${current.line1}, ${current.postal}, ${current.line2}`);

      return;
    }

    void this.getFormattedAddress(current.placeId);
  }

  ngOnInit(): void {
    // locale is either on logged in user or in the template data (print)
    const profile$ = this.route.data.pipe(map((data) => data.profile));
    const templateData$ = this.route.data.pipe(map((data) => data.templateData));

    const locale$$ = merge(profile$, templateData$).subscribe((data) => {
      if (!data) {
        return;
      }

      // get the locale from the route or default to the navigator locale
      this.locale = data.locale ?? locale();
    });

    this.subscription.add(locale$$);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async getFormattedAddress(placeId: string): Promise<void> {
    const googleFormattedAddress = await firstValueFrom(
      this.googleMapPlacesService.getFormattedAddress(placeId, this.locale),
    );

    if (googleFormattedAddress) {
      this.formattedAddress.set(googleFormattedAddress);

      return;
    }

    if (!this.address) {
      return;
    }

    this.formattedAddress.set(`${this.address.line1}, ${this.address.postal}, ${this.address.line2}`);
  }
}
