import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { AddressType, ResponseAddressDto } from '../../../../../../generated/apex-rest';

@Pipe({
  name: 'firstVisitationAddress',
  standalone: true,
})
export class FirstVisitationAddressPipe implements PipeTransform {
  transform(responseAddressDtos?: ResponseAddressDto[]): ResponseAddressDto | undefined {
    const orderedAddresses = orderBy(responseAddressDtos, (d) => DateTime.fromISO(d.updatedAt), ['desc']);

    return orderedAddresses?.find((responseAddressDto) => responseAddressDto.type === AddressType.Visit);
  }
}
