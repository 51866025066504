import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ResponsePortalApartmentDto } from '../../../../../../generated/apex-rest';
import { PortalService } from '../../portal.service';

export const PortalApartmentResolver: ResolveFn<ResponsePortalApartmentDto> = (route: ActivatedRouteSnapshot) => {
  const service = inject(PortalService);

  const projectId = Number(route.paramMap.get('ProjectId'));
  const apartmentId = Number(route.paramMap.get('ApartmentId'));

  return service.readProjectApartment(projectId, apartmentId);
};
