import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CaseParams } from '../../../models/case-params';
import { Agreement } from '../../object/project/agreement/agreement.model';

@Component({
  selector: 'apex-portal-agreement',
  templateUrl: './view.component.html',
})
export class PortalAgreementComponent implements OnInit, OnDestroy {
  agreement: Agreement;
  caseQueryParams = new CaseParams();

  private subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.data.subscribe({
        next: (d) => {
          this.agreement = d.agreement;

          this.setCaseQueryParams();
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setCaseQueryParams(): void {
    this.caseQueryParams.Object = this.agreement?.ObjectId;
    this.caseQueryParams.Agreement = this.agreement?.id;
    this.caseQueryParams.showOpen = true;
    this.caseQueryParams.showCompleted = true;
    this.caseQueryParams.showCaseManagers = true;
  }
}
