import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { ValidatePasswordDirective } from 'projects/apex/src/app/directives/validate-password/validate-password.directive';

const directives = [ValidatePasswordDirective];

@NgModule({
  imports: [CommonModule, MatInputModule],
  providers: [],
  declarations: directives,
  exports: directives,
})
export class ValidatePasswordDirectiveModule {}
