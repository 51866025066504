import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { FileSizePipeModule } from '../../pipes/file-size/file-size.module';
import { TranslateModule } from '../translate/translate.module';
import { FileUsageAttachmentsComponent } from './file-usage-attachments.component';

@NgModule({
  declarations: [FileUsageAttachmentsComponent],
  exports: [FileUsageAttachmentsComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    TranslateModule,
    FileSizePipeModule,
    MatSelectModule,
    MatListModule,
    FormsModule,
  ],
})
export class FileUsageAttachmentsModule {}
