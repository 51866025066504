<apex-page-info [title]="title"></apex-page-info>

<apex-tenancies
  class="am"
  view="cards"
  [tenancies]="tenancies"
  [expanded]="true">
</apex-tenancies>

<apex-box
  class="am no-padding"
  icon="description"
  [expanded]="true">
  <T
    title
    str="Agreements ({count})"
    [vars]="{
      count: agreementComponent?.agreements?.length ?? 0,
    }">
  </T>
  <T
    description
    str="A list of all your agreements, click on one too see more information"></T>

  <apex-agreements
    [objectId]="0"
    #agreementComponent>
    <section
      class="amt amh display-grid-cards card-width-250"
      *ngIf="agreementComponent?.viewAgreements$ | async as viewAgreements">
      <ng-container *ngFor="let agreement of viewAgreements">
        <div
          mat-ripple
          [routerLink]="['agreement', agreement.id]"
          class="new df c mat-elevation-z2 disable-focus">
          <div class="df ac amv amh">
            <mat-icon fontSet="material-icons-sharp">description</mat-icon>
            <div class="aml">
              {{ agreement.name }}
            </div>
          </div>
          <div class="df ac amb amh">
            <mat-icon fontSet="material-icons-sharp">date_range</mat-icon>
            <div
              class="aml"
              [matTooltip]="'Period' | translate">
              {{ agreement?.from | dater | date: 'shortDate' }}
              -
              {{ agreement?.to | dater | date: 'shortDate' }}
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!viewAgreements.length">
        <div class="df ac ap">
          <T str="No agreements to show"></T>
        </div>
      </ng-container>
    </section>
    <mat-paginator
      class="bg-color-transparent"
      [pageSizeOptions]="[5, 10, 25, 50, 100]"
      [length]="agreementComponent?.count"
      [pageIndex]="agreementComponent?.page"
      [pageSize]="agreementComponent?.limit"
      (page)="agreementComponent?.onChangePage($event)">
    </mat-paginator>
  </apex-agreements>
</apex-box>

<section class="am">
  <apex-products [products]="products"> </apex-products>
</section>
