import moment from 'moment';
import { Customer } from './customer';
import { Field } from './field';
import { Model } from './model';

export class Request extends Model<Request> {
  caseName?: string;
  clientName?: string;
  clientAddress?: string;
  clientPhone?: string;
  clientMail?: string;
  ProjectId?: string;
  ApartmentId?: string;
  description?: string;
  apartmentNumber?: string;
  projectName?: string;
  ClientId?: string;
  requestTime: number;

  FieldId: number;
  Field?: Field;

  CustomerId: number;
  migrated: boolean;

  Customer?: Customer;

  get date(): Date {
    return moment.unix(this.requestTime).toDate();
  }
}
