<apex-box
  class="no-padding"
  icon="security"
  [expanded]="expanded">
  <T
    title
    str="Products ({count})"
    [vars]="{
      count: products?.length ?? 0,
    }">
  </T>
  <T
    description
    str="A list of all your products, click on one to see more information"></T>

  <section
    class="am display-grid-cards card-width-250"
    *ngIf="products?.length; else emptyState">
    <ng-container *ngFor="let product of products">
      <div
        mat-ripple
        [routerLink]="['product', product.id]"
        class="new df c mat-elevation-z2 disable-focus">
        <div class="df ac amv amh">
          <mat-icon fontSet="material-icons-sharp">security</mat-icon>
          <div class="aml">
            {{ product.name }}
          </div>
        </div>
        <div class="df ac amb amh">
          <mat-icon fontSet="material-icons-sharp">code</mat-icon>
          <div
            class="aml"
            [matTooltip]="'Serial number' | translate">
            {{ product.data.serialNumber }}
          </div>
        </div>
      </div>
    </ng-container>
  </section>

  <ng-template #emptyState>
    <div class="df ac ap">
      <T str="No products to show"></T>
    </div>
  </ng-template>
</apex-box>
