<portal-topbar [drawer]="drawer"></portal-topbar>
<mat-drawer-container class="df c f1 print-scroll-visible">
  <mat-drawer
    #drawer
    [mode]="mode$ | async">
    <portal-sidebar [drawer]="drawer"></portal-sidebar>
  </mat-drawer>

  <div
    cdkScrollable
    class="scrollable f1 df c h-100 print-scroll-visible">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
