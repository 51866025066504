import { AddressType } from '../../../../../../generated/apex-rest';
import { t } from '../translate/translate.function';

type AddressTypeForTranslation = {
  [model in AddressType]: string;
};

export const getTranslatedAddressTypes = (): AddressTypeForTranslation => ({
  [AddressType.Mail]: t('Mail'),
  [AddressType.Visit]: t('Visitation'),
  [AddressType.Invoice]: t('Invoice'),
});
