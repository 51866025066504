import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Subject, debounceTime, distinct, filter, map, mergeMap, tap } from 'rxjs';
import { TranslateModule } from '../../translate/translate.module';
import { AddressAutocompleteResult } from './address-autocomplete-result.type';

@Component({
  selector: 'apex-address-autocomplete',
  templateUrl: './address-autocomplete.component.html',
  standalone: true,
  imports: [CommonModule, MatAutocompleteModule, MatFormFieldModule, MatInputModule, TranslateModule],
})
export class AddressAutocompleteComponent implements OnDestroy {
  @Input()
  disabled = false;

  searchChange$: Subject<Event> = new Subject<Event>();
  searchResults$ = new Subject<google.maps.places.AutocompletePrediction[]>();

  selectedOption$ = new Subject<MatAutocompleteSelectedEvent>();

  @Output()
  addressChange = new EventEmitter<AddressAutocompleteResult | null>();

  private searchChange$$ = this.searchChange$
    .pipe(
      map((event) => (event.target as HTMLInputElement)?.value),
      distinct(),
      filter((search) => search?.length > 2),
      debounceTime(250),
      mergeMap((search) => this.search(search)),
    )
    .subscribe(this.searchResults$);

  private selectedOption$$ = this.selectedOption$
    .pipe(
      tap((event) => event),
      filter((event) => !!event?.option?.value),
      map((event) => event.option.value as google.maps.places.AutocompletePrediction),
      tap((prediction) => prediction),
      filter((prediction) => !!prediction?.place_id),
      mergeMap((prediction) => this.fetchFromPlacesGeocoderByPlaceId(prediction.place_id)),
    )
    .subscribe((result) => {
      const streetNumber = result.address_components.find((component) => component.types.includes('street_number'));
      const routerOrStreetName = result.address_components.find(
        (component) => component.types.includes('route') || component.types.includes('street_address'),
      );

      const postalTown = result.address_components.find((component) => component.types.includes('postal_town'));

      const neighborhood = result.address_components.find((component) => component.types.includes('neighborhood'));
      const locality = result.address_components.find((component) => component.types.includes('locality'));
      const administrativeLevels = result.address_components.filter((component) => {
        const typesStartsWithAdministrative = component.types.some((type) => type.startsWith('administrative'));

        return typesStartsWithAdministrative;
      });

      const postalCode = result.address_components.find((component) => component.types.includes('postal_code'));
      const country = result.address_components.find((component) => component.types.includes('country'));

      const addressLine1Components = [
        routerOrStreetName?.long_name,
        streetNumber?.long_name,
        postalTown?.long_name,
      ].filter((component) => !!component);

      const administrativeLevelsString = administrativeLevels
        .reverse()
        .map((component) => component.long_name)
        .join(', ')
        .trim();
      const addressLine2Components = [neighborhood?.long_name, locality?.long_name, administrativeLevelsString].filter(
        (component) => !!component,
      );

      this.addressChange.emit({
        line1: addressLine1Components.join(', '),
        line2: addressLine2Components.join(', '),
        postal: postalCode?.long_name ?? '',
        countryCodes: country?.short_name ?? '',
        placeId: result.place_id,
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng(),
      });
    });

  ngOnDestroy(): void {
    this.searchChange$$.unsubscribe();
    this.selectedOption$$.unsubscribe();
  }

  async search(search: string): Promise<google.maps.places.AutocompletePrediction[]> {
    return new Promise((resolve) => {
      const service = new google.maps.places.AutocompleteService();

      service.getPlacePredictions({ input: search }, (results) => {
        resolve(results);
      });
    });
  }

  async fetchFromPlacesGeocoderByPlaceId(placeId: string): Promise<google.maps.GeocoderResult> {
    return new Promise((resolve) => {
      const service = new google.maps.Geocoder();

      service.geocode({ placeId }, (results) => {
        resolve(results[0]);
      });
    });
  }

  displayFn(prediction: google.maps.places.AutocompletePrediction): string {
    return (prediction?.description ?? '').trim();
  }
}
