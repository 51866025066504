import { Injectable } from '@angular/core';
import {
  AddonApartmentApi,
  AddonOrderApi,
  ResponsePortalAddonApartmentDtoPageResponse,
  ResponsePortalAddonOrderDtoPageResponse,
} from '../../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../../../../../apex/src/app/utils/rest-axios-config';

@Injectable()
export class PortalAddonOrdersService {
  constructor() {}

  addonOrderApi = new AddonOrderApi(restAxiosConfig());
  addonAparmentApi = new AddonApartmentApi(restAxiosConfig());

  async readAddonOrders(
    projectId: number,
    apartmentId: number,
    page: number,
    limit: number,
  ): Promise<ResponsePortalAddonOrderDtoPageResponse> {
    const response = await this.addonOrderApi.portalAddonOrderControllerReadCollection(
      projectId,
      apartmentId,
      page,
      limit,
    );

    return response.data;
  }

  async readAddonApartments(
    projectId: number,
    apartmentId: number,
    page: number,
    limit: number,
  ): Promise<ResponsePortalAddonApartmentDtoPageResponse> {
    const response = await this.addonAparmentApi.portalAddonApartmentControllerReadCollection(
      projectId,
      apartmentId,
      page,
      limit,
    );

    return response.data;
  }
}
