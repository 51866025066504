import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ResponsePortalCaseDto,
  ResponsePortalCaseDtoPageResponse,
  ResponsePortalCaseLogDto,
} from '../../../../../generated/apex-rest';
import { PortalCaseViewDialogComponent } from '../apartment/case/view/portal-case-view-dialog.component';
import { PortalService } from '../portal.service';

@Component({
  template: '',
  standalone: true,
})
export class PortalCasesViewDialogEntryComponent implements OnInit {
  cases: ResponsePortalCaseDto[] = [];
  projectId: number;
  apartmentId: number;
  caseId: number;
  caseItem: ResponsePortalCaseDto;
  caselogs: ResponsePortalCaseLogDto[] = [];

  response: ResponsePortalCaseDtoPageResponse;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private portalService: PortalService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.caseId = Number(this.route.snapshot.params.CaseId);

    this.response = await this.route.snapshot.data.cases;
    this.cases = this.route.snapshot.data.cases;
    this.caseItem = this.route.snapshot.data.caseItem;

    this.caselogs = this.route.snapshot.data.caseLogs;

    this.projectId = this.route.snapshot.data.caseItem.ProjectId;
    this.apartmentId = this.route.snapshot.data.caseItem.ApartmentId;

    const markProjectApartmentCaseRead = this.route.snapshot.data.caseMark;

    const data = {
      caseId: this.caseId,
      apartmentId: this.apartmentId,
      projectId: this.projectId,
      caseLogs: this.caselogs,
      markProjectApartmentCaseRead,
      caseItem: this.caseItem,
    };

    this.dialog
      .open(PortalCaseViewDialogComponent, {
        data,
      })
      .afterClosed()
      .subscribe(() => {
        void this.router.navigate(['..'], { relativeTo: this.route, queryParamsHandling: 'preserve' });
      });
  }
}
