<form
  #caseLogMessageForm="ngForm"
  class="df ap c mat-elevation-z2 aph apt amv"
  (ngSubmit)="addMessage(caseLogMessageForm)">
  <div class="df f1">
    <mat-form-field class="f1">
      <mat-label>
        <T str="Message"></T>
      </mat-label>

      <textarea
        matInput
        [(ngModel)]="createCaseLog.message"
        name="message"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="10"
        required>
      </textarea>
    </mat-form-field>
  </div>

  <div class="df f1 jsb">
    <mat-checkbox
      class="f1"
      name="forContractor"
      [checked]="createCaseLog.forContractor === 1"
      (change)="createCaseLog.forContractor = $event?.checked ? 1 : 0">
      <T
        str="Visible for contractor"
        context="portal-case-log"
        _context="portal-case-log"></T>
    </mat-checkbox>

    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="caseLogMessageForm.invalid">
      <T
        str="Send"
        context="portal-case-log"
        _context="portal-case-log"></T>
    </button>
  </div>
</form>

<apex-case-logs
  [caseLogs]="viewCaseLogs"
  [showUserCard]="false"
  [disableToggleActions]="true">
  <ng-container
    pagination
    *ngIf="count > limit">
    <mat-paginator
      [pageSizeOptions]="[25, 50, 100]"
      [length]="count"
      [pageIndex]="page"
      [pageSize]="limit"
      showFirstLastButtons
      (page)="changePage($event)">
    </mat-paginator>
  </ng-container>
</apex-case-logs>
