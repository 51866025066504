import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { HammerTimeModule } from '../../directives/hammer-time/hammer-time.module';
import { LinkCardsComponent } from './link-cards.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatRippleModule,
    MatBadgeModule,
    MatTooltipModule,
    HammerTimeModule,
  ],
  exports: [LinkCardsComponent],
  declarations: [LinkCardsComponent],
  providers: [],
})
export class LinkCardsModule {}
