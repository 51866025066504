import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Profile } from 'projects/apex/src/app/models/profile';
import { ResponsePortalMainObjectDto } from '../../../../../generated/apex-rest';
import { Product } from '../../../../apex/src/app/features/object/safety/safety.model';

@Component({
  selector: 'portal-start',
  templateUrl: 'portal-start-page.component.html',
})
export class PortalStartPageComponent implements OnInit {
  profile: Profile;
  entities: ResponsePortalMainObjectDto[] = [];
  products: Product[] = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.profile = this.route.snapshot.data.profile;
    this.entities = this.route.snapshot.data.entities ?? [];
    this.products = this.route.snapshot.data.products ?? [];
  }
}
