import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ResponsePortalCaseDtoPageResponse } from '../../../../../../generated/apex-rest';
import { PortalCasesService } from '../services/portal-cases.service';

export const PortalCasesResolver: (route: ActivatedRouteSnapshot) => Promise<ResponsePortalCaseDtoPageResponse> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(PortalCasesService);

  const limit = Number(route.queryParams.caseLimit) || undefined;
  const page = Number(route.queryParams.casePage) || undefined;
  const search = route.queryParams.search || '';
  const apartmentId = Number(route.queryParams.ApartmentId) || undefined;

  return service.readCollection(search, apartmentId, page, limit);
};
