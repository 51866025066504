<div class="scrollable-horizontal df c f1">
  <table
    class="space-between-columns"
    mat-table
    [dataSource]="documentsDataSource"
    matSort>
    <ng-container matColumnDef="projectName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <T str="Project"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        {{ element.projectName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="apartmentName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <T str="Unit"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        {{ element.apartmentName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <T str="Type"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        {{ element.type }}
      </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <T str="Description"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        {{ element.description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="openFileButton">
      <th
        mat-header-cell
        *matHeaderCellDef></th>
      <td
        class="text-align-right"
        mat-cell
        *matCellDef="let element">
        <ng-container *ngIf="element.FileUsage?.File">
          <button
            type="button"
            mat-icon-button
            color="primary"
            [matTooltip]="'Open file' | translate"
            (click)="openFile(element.FileUsage.File)">
            <mat-icon>file_open</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="fileName">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <T str="File"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        {{ element.fileName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header>
        <T str="Date"></T>
      </th>
      <td
        mat-cell
        *matCellDef="let element">
        {{ element.createdAt | date: 'shortDate' }}
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"></tr>

    <tr
      class="mat-row"
      *matNoDataRow>
      <td
        class="mat-cell ap"
        colspan="6">
        <T str="No documents to show"></T>
      </td>
    </tr>
  </table>
</div>
