<form
  (submit)="$event.stopPropagation(); $event.preventDefault()"
  class="df f1">
  <mat-form-field class="f1">
    <mat-label>
      <T str="Address"></T>
    </mat-label>

    <input
      type="text"
      [placeholder]="'Search for an address' | translate"
      matInput
      [matAutocomplete]="auto"
      [disabled]="disabled"
      (keyup)="searchChange$.next($event)" />

    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn"
      (optionSelected)="selectedOption$.next($event)">
      <mat-option
        *ngFor="let searchResult of searchResults$ | async"
        [value]="searchResult">
        {{ searchResult.description }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
