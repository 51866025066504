import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Obj } from '../../../../../apex/src/app/features/object/object.model';
import { EntryCard } from '../../../../../apex/src/app/features/object/project/tenancy/entry-card/entry-card.model';
import { EntryCardModule } from '../../../../../apex/src/app/features/object/project/tenancy/entry-card/entry-card.module';
import { EntryCardsComponent } from '../../../../../apex/src/app/features/object/project/tenancy/entry-card/list.component';
import { EntryCardRequestDeleteDialogComponent } from '../../../../../apex/src/app/features/portal/tenancy/entry-card-request-delete-dialog.component';
import { EntryCardRequestDialogComponent } from '../../../../../apex/src/app/features/portal/tenancy/entry-card-request-dialog.component';
import { Profile } from '../../../../../apex/src/app/models/profile';
import { Tenancy } from '../../../../../apex/src/app/models/tenancy';

@Component({
  selector: 'portal-tenancy-access-card',
  templateUrl: 'portal-tenancy-access-card-page.component.html',
  standalone: true,
  imports: [EntryCardModule, CommonModule],
})
export default class PortalTenancyAccessCardPageComponent implements OnInit, OnDestroy {
  tenancy: Tenancy;
  objects: Obj[];
  profile: Profile;

  subscription = new Subscription();

  @ViewChild('entryCards') entryCardComp: EntryCardsComponent;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  addEntryCard(): void {
    this.subscription.add(
      this.dialog
        .open(EntryCardRequestDialogComponent, {
          data: { tenancyId: this.tenancy.id },
          autoFocus: false,
        })
        .afterClosed()
        .subscribe({
          next: (ref) => {
            if (ref?.entryCard) {
              this.entryCardComp.viewEntryCards.push(ref.entryCard);
            }
          },
        }),
    );
  }

  deleteEntryCard(ec: EntryCard): void {
    this.subscription.add(
      this.dialog
        .open(EntryCardRequestDeleteDialogComponent, {
          data: ec,
        })
        .afterClosed()
        .subscribe(),
    );
  }

  editEntryCard(ec: EntryCard): void {
    this.subscription.add(
      this.dialog
        .open(EntryCardRequestDialogComponent, {
          data: {
            tenancyId: this.tenancy.id,
            entryCard: ec,
          },
        })
        .afterClosed()
        .subscribe(),
    );
  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.data
        .pipe(map((data) => [data.tenancy, data.objects, data.profile] as [Tenancy, Obj[], Profile]))
        .subscribe({
          next: ([tenancy, objects, profile]) => {
            this.tenancy = tenancy;
            this.objects = objects;
            this.profile = profile;
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
