<apex-client-addon-breadcrumb
  [cart]="addonCart"
  [apartment]="addonApartment.Apartment"
  [editable]="editable"
  [addon]="addonApartment.Addon">
</apex-client-addon-breadcrumb>

<apex-client-addon-apartment-info
  [addonApartment]="addonApartment"
  [product]="product"
  [editable]="editable"
  (remove)="removeProduct()"
  (add)="addProduct($event)">
</apex-client-addon-apartment-info>
<div
  *ngIf="otherAddons?.length"
  class="info-other-addons">
  <p>
    <T
      str="Other addons for {categoryName}"
      [vars]="{ categoryName: addonCategory.name }">
    </T>
  </p>
  <div class="other-addons-container">
    <ng-container *ngFor="let addon of otherAddons">
      <apex-client-addon-apartment-card
        class="mat-elevation-z2"
        [addonApartment]="addon.AddonApartments[0]"
        [editable]="false"
        (addonClicked)="goToAddon($event)">
      </apex-client-addon-apartment-card>
    </ng-container>
  </div>
</div>

<button
  mat-raised-button
  class="amb"
  (click)="back()">
  <T str="Back"></T>
</button>
