<apex-client-addon-breadcrumb
  [apartment]="apartment"
  [inConfirmation]="true">
</apex-client-addon-breadcrumb>

<div>
  <!-- @todo should this be translated? -->
  <!-- This gets from defaultsTexts -->
  <h1>{{ title | translate }}</h1>
  <div [innerHTML]="content"></div>
</div>
<div>
  <button
    mat-button
    [routerLink]="
      inPortal
        | portalAddonUrlPipe
          : ['../../ordre']
          : ['/project', apartment.ProjectId, 'apartment', apartment.id, 'addon', 'order']
    ">
    <T str="View order"></T>
  </button>
</div>
