import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientPageDocumentsComponent } from './client-page-documents.component';
import { ClientPageDocumentsResolver } from './client-page-documents.resolver';

const routes: Routes = [
  {
    path: '',
    component: ClientPageDocumentsComponent,
    resolve: {
      documents: ClientPageDocumentsResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ClientPageDocumentsRoutingModule {}
