import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { FileUsageViewerDialogComponent } from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer-dialog.component';
import { FileUsageViewerMode } from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer.types';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { Product } from 'projects/apex/src/app/models/addon-cart';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';

@Component({
  selector: 'apex-client-addon-cart-product-card',
  templateUrl: './product-card.component.html',
})
export class ClientAddonCartProductCardComponent implements OnInit, OnDestroy {
  @Output() remove = new EventEmitter<Product>();
  @Output() update = new EventEmitter<Product>();
  @Input() product: Product;
  @Input() editable = false;
  @Input() addonManager = false;

  fileUsages: FileUsage[];
  isLastDayOrWeek: 'day' | 'week';
  timeLeft: string;
  commentChange: Subject<string> = new Subject<string>();

  inPortal = false;
  productCardUrl: (string | number)[];

  private subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private clientService: ClientPageService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getAddonImages();
    this.isOnLastDayOrWeek();
    this.calculateTimeLeft();

    this.subscription.add(
      this.commentChange
        .pipe(debounceTime(1000), distinctUntilChanged())
        .subscribe(() => this.update.emit(this.product)),
    );

    const snapshot = this.route.snapshot;
    const data = snapshot.data;

    this.inPortal = data.portal;
    this.productCardUrl = [
      '/minside',
      'tilvalg',
      this.product.AddonApartment.ApartmentId,
      this.product.AddonApartmentId,
    ];

    if (this.addonManager) {
      this.productCardUrl = ['../', this.product.AddonApartmentId];
    }

    if (this.inPortal) {
      const projectId = snapshot.paramMap.get('ProjectId');
      const apartmentId = snapshot.paramMap.get('ApartmentId');

      this.productCardUrl = ['/project', projectId, 'apartment', apartmentId, 'addon', this.product.AddonApartmentId];
    }
  }

  openAddonApartmentMarking(addonApartment: AddonApartment): void {
    this.subscription.add(
      this.clientService
        .getFloorplanImage(addonApartment)
        .pipe(take(1))
        .subscribe((floorplans: FileUsage[]) => {
          const dialogRef = this.dialog.open(FileUsageViewerDialogComponent, {
            data: {
              mode: FileUsageViewerMode.Mark,
              fileUsages: floorplans,
              editable: this.editable,
              client: true,
              modelData: {
                model: 'addon-apartment',
                modelId: addonApartment.id,
              },
              markingData: {
                min: addonApartment.markingMin,
                max: addonApartment.markingMax,
              },
            },
          });

          this.subscription.add(
            dialogRef
              .afterClosed()
              .pipe(take(1))
              .subscribe((res) => {
                if (this.editable) {
                  this.product = {
                    ...this.product,
                    quantity: res?.markings?.length ?? this.product.quantity,
                    Markings: res?.markings ?? this.product.Markings,
                  };
                  this.update.emit(this.product);
                }
              }),
          );
        }),
    );
  }

  changeComment(comment: string): void {
    this.product.ClientComment = comment;
    this.commentChange.next(comment);
  }

  productClick(): void {
    if (!this.editable) {
      // show snackbar message that signing is in progress?
      return;
    }

    void this.router.navigate(['../', this.product.AddonApartmentId], { relativeTo: this.route });
  }

  isOnLastDayOrWeek(): void {
    const now = moment();
    const end = moment(this.product.AddonApartment.dateEnd).endOf('day');
    const diff = end.diff(now, 'days');

    if (diff === 0) {
      this.isLastDayOrWeek = 'day';
    } else if (diff <= 7) {
      this.isLastDayOrWeek = 'week';
    } else {
      this.isLastDayOrWeek = null;
    }
  }

  calculateTimeLeft(): void {
    const now = moment();
    const end = moment(this.product.AddonApartment.dateEnd).endOf('day');

    this.timeLeft = now.to(end);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getAddonImages(): void {
    this.subscription.add(
      this.clientService
        .getAddonImages(this.product.Addon)
        .pipe(take(1))
        .subscribe((fileUsages: FileUsage[]) => {
          this.fileUsages = fileUsages;
        }),
    );
  }
}
