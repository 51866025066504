import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { PageNotFoundModule } from 'projects/apex/src/app/components/page-not-found/page-not-found.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { TenancyService } from 'projects/apex/src/app/features/object/project/tenancy/tenancy.service';
import { ApexPortalModule } from 'projects/apex/src/app/features/portal/portal.module';
import { HttpErrorInterceptor } from 'projects/apex/src/app/interceptors/http-error.interceptor';
import { FilterService } from 'projects/apex/src/app/services/filter/filter.service';
import { SwService } from 'projects/apex/src/app/services/sw/sw.service';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { RaygunErrorHandler } from 'projects/apex/src/app/utils/error-handler';
import { locale } from 'projects/apex/src/app/utils/functions';
import { localeInitializer } from 'projects/apex/src/app/utils/locale-init';
import { PortalProfilePageModule } from 'projects/portal/src/app/profile/portal-profile-page.module';
import { PortalStartPageModule } from 'projects/portal/src/app/start/portal-start-page.module';
import { PortalTenancyPageModule } from 'projects/portal/src/app/tenancy/portal-tenancy-page.module';
import { SidebarService } from '../../../apex/src/app/components/sidebar/services/sidebar.service';
import { TopbarModule } from '../../../apex/src/app/components/topbar/topbar.module';
import { RequestStatusInterceptor } from '../../../apex/src/app/interceptors/status/request-status.interceptor';
import StatusServiceModule from '../../../apex/src/app/interceptors/status/status.service.module';
import { SnackModule } from '../../../apex/src/app/modules/snack.module';
import { LocalStorageService } from '../../../apex/src/app/services/local-storage/local-storage.service';
import { LocaleProvider } from '../../../apex/src/app/utils/locale-provider';
import { portalAuthDestinationProvider } from '../../../apex/src/app/utils/types/app-url.token';
import { environment } from '../../../apex/src/environments/environment';
import { ApartmentPageModule } from './apartment/apartment-page.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortalService } from './portal.service';
import SidebarComponent from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    AppRoutingModule,

    PortalStartPageModule,
    PortalTenancyPageModule,
    PortalProfilePageModule,
    ApexPortalModule,
    PageNotFoundModule,
    ApartmentPageModule,
    SnackModule,
    StatusServiceModule,

    HammerModule,

    TranslateModule,
    MatSidenavModule,
    TopbarModule,
    TopbarComponent,
    SidebarComponent,
  ],
  providers: [
    SwService,
    LocaleProvider,
    {
      provide: APP_INITIALIZER,
      useValue: (): Promise<void> => Promise.resolve(localeInitializer(locale())),
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: locale(),
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestStatusInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },

    UserService,
    FilterService,
    TenancyService,
    PortalService,
    SidebarService,
    LocalStorageService,

    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: `${environment.appUrl}/tinymce/tinymce.min.js`,
    },
    portalAuthDestinationProvider(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
