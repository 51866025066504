import { CommonModule } from '@angular/common';
import { Component, Input, ViewChildren } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { t } from '@transifex/native';
import { map } from 'bluebird';
import JSZip from 'jszip';
import { BoxModule } from '../../../../components/box/box.module';
import { ItemModule } from '../../../../components/item/item.module';
import QrCodeImageComponent from '../../../../components/qr-code-image/qr-code-image.component';
import { QrCodeModule } from '../../../../components/qr-code/qr-code.module';
import { TranslateModule } from '../../../../components/translate/translate.module';
import { snack } from '../../../../modules/snack.module';
import { presentDownload } from '../../../../utils/functions';
import { Obj } from '../../object.model';
import { SortHierarchyPipe } from './pipes/sort-hierarchy.pipe';

@Component({
  selector: 'apex-object-list-qr',
  templateUrl: './object-list-qr.component.html',
  standalone: true,
  imports: [
    CommonModule,
    BoxModule,
    MatListModule,
    QrCodeModule,
    QrCodeImageComponent,
    ItemModule,
    TranslateModule,
    MatButtonModule,
    SortHierarchyPipe,
  ],
})
export default class ObjectListQrComponent {
  @Input() objects: Obj[] = [];
  @Input() project: Obj;

  @ViewChildren(QrCodeImageComponent) qrCodeImageComponents?: QrCodeImageComponent[];

  async downloadAll(): Promise<void> {
    snack(t('Downloading all QR codes, this might take a minute...'));

    const data = await map(
      this.qrCodeImageComponents,
      async (qrCodeImageComponent) => {
        const image = await qrCodeImageComponent.convertToImage();
        const fileName = qrCodeImageComponent.fileName;

        return {
          image,
          fileName,
        };
      },
      { concurrency: 1 },
    );

    const zip = new JSZip();

    zip.file('README.txt', t('QR codes for {projectName}', { projectName: this.project.name }));

    data.forEach((item) => {
      zip.file(item.fileName, item.image, { base64: true });
    });

    const zipFile = await zip.generateAsync({ type: 'blob' });

    const fileName = t('{projectName}-qr-codes.zip', { projectName: this.project.name });

    presentDownload(fileName, zipFile, 'application/zip');
  }
}
