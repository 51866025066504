import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Apartment } from '../../../models/apartment';
import { Profile } from '../../../models/profile';
import { Request } from '../../../models/request';
import { CollectionResponse } from '../../../utils/types';
import { RequestDialogComponent } from '../dialog/request-dialog.component';
import { RequestDialogData, RequestDialogReturnData } from '../dialog/request-dialog.types';

@Component({
  selector: 'apex-request-list',
  templateUrl: './request-list.component.html',
})
export class RequestListComponent implements OnDestroy {
  @Input() disableRequests = false;

  requests: Request[] = [];

  limit = 100;
  page = 0;
  count = 0;

  profile: Profile;
  apartment: Apartment;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.subscription.add(
      this.route.data
        .pipe(
          map(
            (data) =>
              [data.requests, data.profile, data.apartment] as [CollectionResponse<Request>, Profile, Apartment],
          ),
          map(([res, profile, apartment]) => {
            this.page = res?.page;
            this.count = res?.count;

            this.apartment = apartment;
            this.profile = profile;

            return res?.Collection ?? [];
          }),
          map((requests) => requests.map((request) => new Request(request))),
        )
        .subscribe({
          next: (requests) => {
            this.requests = requests;
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changePage(event: PageEvent): void {
    this.page = event.pageIndex;
    this.limit = event.pageSize;

    this.updateQueryParams();
  }

  createRequest(): void {
    const requestData: Partial<Request> = {
      CustomerId: this.apartment.Project?.CustomerId,
      ProjectId: String(this.apartment.ProjectId),
      ApartmentId: String(this.apartment.id),
      ClientId: String(this.profile.id),
    };

    this.dialog
      .open<RequestDialogComponent, RequestDialogData, RequestDialogReturnData>(RequestDialogComponent, {
        data: {
          request: new Request(requestData),
          mainObjects: this.route.snapshot.data.entities ?? [],
        },
        panelClass: ['apex-fullscreen-dialog', 'phone'],
      })
      .afterClosed()
      .pipe(filter((request) => !!request))
      .subscribe({
        next: (request) => {
          if (String(request.ApartmentId) === String(this.apartment.id)) {
            this.requests.unshift(new Request(request));
          }
        },
      });
  }

  private updateQueryParams(): void {
    const queryParams: Params = {
      requestPage: this.page,
      requestLimit: this.limit,
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }
}
