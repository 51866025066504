<section class="df c mat-elevation-z2">
  <header class="df r jsb ac ap">
    <h4 class="mat-h2 rm">
      #{{ product.Addon.id }}
      <a [routerLink]="productCardUrl">
        {{ product.Addon.name }}
      </a>
    </h4>
    <button
      *ngIf="editable"
      mat-icon-button
      (click)="remove.emit(product)">
      <mat-icon>clear</mat-icon>
    </button>
  </header>
  <mat-divider></mat-divider>
  <section class="df r main-section">
    <main class="f2 df c">
      <section class="df c gap am">
        <apex-title-item>
          <T
            title
            str="Category"></T>
          {{ product?.Addon?.AddonCategory?.Parent ? product?.Addon?.AddonCategory?.Parent?.name + ' - ' : '' }}
          {{ product?.Addon?.AddonCategory?.name }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Description"></T>
          {{ product.Addon.description }}
        </apex-title-item>
      </section>

      <section class="df c gap am">
        <apex-title-item>
          <T
            title
            str="Expires"></T>
          <span
            [ngClass]="
              isLastDayOrWeek === 'day' ? ' error-color' : isLastDayOrWeek === 'week' ? 'warning-color' : 'gray-color'
            "
            [matTooltip]="product.AddonApartment.dateEnd | date: 'shortDate'">
            {{ timeLeft }}
          </span>
        </apex-title-item>

        <mat-form-field
          floatLabel="always"
          subscriptSizing="dynamic">
          <mat-label>
            <T str="Comment"></T>
          </mat-label>
          <input
            [placeholder]="'Write your comment here' | translate"
            matInput
            [disabled]="!editable"
            [ngModel]="product.ClientComment"
            (ngModelChange)="changeComment($event)" />
        </mat-form-field>
      </section>

      <section class="df c gap am">
        <apex-title-item>
          <T
            title
            str="Quantity"></T>
          <section class="df jsb ac">
            {{ product.quantity || 1 }}
            <ng-container *ngIf="product.Markings && product.Markings.length">
              <button
                mat-icon-button
                (click)="openAddonApartmentMarking(product.AddonApartment)"
                [disabled]="
                  !editable || (product.AddonApartment.markingMin === 0 && product.AddonApartment.markingMax === 0)
                "
                [matTooltip]="'Click to edit markings' | translate">
                <mat-icon
                  svgIcon="floorplan"
                  [matBadge]="product?.Markings?.length ?? 0"
                  [matBadgeHidden]="
                    !product?.Markings ||
                    (product?.AddonApartment?.markingMin === 0 && product?.AddonApartment?.markingMax === 0)
                  "
                  [matBadgeColor]="
                    product?.Markings && product?.Markings?.length >= product?.AddonApartment?.markingMin
                      ? 'primary'
                      : 'warn'
                  ">
                </mat-icon>
              </button>
            </ng-container>
          </section>
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Price (excl. VAT)"></T>
          {{ product.AddonApartment.priceOut | apexCurrency: 'NOK' : '' }}
        </apex-title-item>
      </section>
      <mat-divider></mat-divider>
      <footer class="am df">
        <apex-client-addon-order-totals
          class="f1 df jfs"
          [product]="product">
        </apex-client-addon-order-totals>
      </footer>
    </main>
    <mat-divider [vertical]="true"></mat-divider>
    <aside
      class="image-container"
      mat-ripple
      (click)="productClick()">
      <apex-file-usage-preview
        *ngIf="fileUsages"
        [fileUsages]="fileUsages"
        fit="contain">
      </apex-file-usage-preview>
    </aside>
  </section>
</section>
