import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'projects/apex/src/app/components/avatar/avatar.module';
import { PageInfoModule } from 'projects/apex/src/app/components/page-info/page-info.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { FilePreviewModule } from '../../../../apex/src/app/components/file-preview/file-preview.module';
import { FileUsageCarouselModule } from '../../../../apex/src/app/components/file-usage-carousel/file-usage-carousel.module';
import { ItemModule } from '../../../../apex/src/app/components/item/item.module';
import { LogoModule } from '../../../../apex/src/app/components/logo/logo.module';
import { ProductsModule } from '../../../../apex/src/app/features/object/safety/product/products/products.module';
import { MainObjectAddressComponent } from '../address/main-object-address.component';
import { PortalStartPageComponent } from './portal-start-page.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    PageInfoModule,
    AvatarModule,
    MatDividerModule,
    MatCardModule,
    FileUsageCarouselModule,
    ItemModule,
    MatButtonModule,
    ProductsModule,
    FilePreviewModule,
    MainObjectAddressComponent,
    MatSidenavModule,
    LogoModule,
  ],
  exports: [PortalStartPageComponent],
  declarations: [PortalStartPageComponent],
})
export class PortalStartPageModule {}
