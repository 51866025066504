<section class="df c">
  <header class="df r jsb ac apvs apl">
    <div class="header-content df f1 ac w">
      <section class="df w-60">
        <div
          class="df ac amr"
          *ngIf="addonOrder.status === 'new'"
          [ngClass]="status.color"
          [matTooltip]="'Ready for review' | translate">
          <mat-icon> watch_later</mat-icon>
        </div>
        <div
          class="amr df ac"
          *ngIf="addonOrder.status === 'accepted'"
          [ngClass]="status.color"
          [matTooltip]="'Waiting for client to sign' | translate">
          <mat-icon> check_circle</mat-icon>
        </div>
        <div
          class="error-color amr df ac"
          *ngIf="addonOrder.status === 'denied'"
          [matTooltip]="'Denied, see manager comment for reason' | translate">
          <mat-icon>error</mat-icon>
        </div>
        <div
          class="warning-color amr df ac"
          *ngIf="addonOrder.status === 'canceled'"
          [matTooltip]="'Canceled' | translate">
          <mat-icon>canceled</mat-icon>
        </div>
        <h4
          class="mat-h2 rm df ac"
          [id]="addonOrder?.id">
          #{{ addonOrder?.id }}
          <a
            class="amls"
            [routerLink]="
              inPortal
                | portalAddonUrlPipe
                  : ['/minside', 'tilvalg', 'ordre', addonOrder.id]
                  : [portalBaseUrl, 'order', addonOrder.id]
            ">
            {{ addonOrder.Addon.name }}
          </a>
        </h4>
      </section>
      <section class="df f1 totals amh">
        <div class="df fdot5 c ac amr">
          <T str="Quantity"></T>
          {{ addonOrder.quantity }}
        </div>

        <div class="df f1 c afe amr">
          <T str="Price"></T>
          {{ priceIncVat | apexCurrency: 'NOK' : '' }}
        </div>

        <div class="df f1 c afe aml">
          <T str="Total"></T>
          {{ totalIncVat | apexCurrency: 'NOK' : '' }}
        </div>
      </section>
    </div>
    <button
      mat-icon-button
      type="button"
      (click)="expanded = !expanded">
      <mat-icon>{{ expanded ? 'expand_less' : 'expand_more' }}</mat-icon>
    </button>
  </header>
  <mat-divider *ngIf="expanded"></mat-divider>
  <section
    class="df r main-section"
    *ngIf="expanded">
    <main class="f2 df c">
      <section class="df c gap am">
        <apex-title-item>
          <T
            title
            str="Category"></T>
          {{
            addonOrder?.Addon?.AddonCategory?.Parent
              ? addonOrder?.Addon?.AddonCategory?.name + ' (' + addonOrder?.Addon?.AddonCategory?.Parent?.name + ')'
              : addonOrder?.Addon?.AddonCategory?.name
          }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Description"></T>
          {{ addonOrder?.Addon?.description }}
        </apex-title-item>
      </section>

      <section class="df c gap am">
        <apex-title-item>
          <T
            title
            str="Created"></T>
          {{ addonOrder.createdAt | date }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Seller"></T>
          {{ addonOrder.seller }} / {{ addonOrder.sellerOrgnr }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Manager"></T>
          {{ addonOrder.Manager?.name }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Manager comment"></T>
          {{ addonOrder.ManagerComment }}
        </apex-title-item>

        <apex-title-item>
          <T
            title
            str="Comment"></T>
          {{ addonOrder.ClientComment }}
        </apex-title-item>
      </section>
      <section
        class="df c amh amb"
        *ngIf="addonOrder.Markings && addonOrder.Markings.length">
        <ng-container *ngIf="addonOrder.Markings && addonOrder.Markings.length">
          <button
            mat-icon-button
            (click)="markingsClicked()"
            [matTooltip]="'Show markings' | translate">
            <mat-icon
              svgIcon="floorplan"
              [matBadge]="addonOrder?.Markings?.length ?? 0"
              [matBadgeHidden]="
                !addonOrder?.Markings ||
                (addonOrder?.AddonApartment?.markingMin === 0 && addonOrder?.AddonApartment?.markingMax === 0)
              "
              [matBadgeColor]="
                addonOrder?.Markings && addonOrder?.Markings?.length >= addonOrder?.AddonApartment?.markingMin
                  ? 'primary'
                  : 'warn'
              ">
            </mat-icon>
          </button>
        </ng-container>
      </section>
      <section
        class="df ac jfs amb aml"
        *ngIf="status.status === 'new'">
        <button
          color="warn"
          mat-raised-button
          (click)="deny()">
          <T str="Cancel"></T>
        </button>
      </section>
    </main>
    <mat-divider [vertical]="true"></mat-divider>
    <aside class="image-container">
      <apex-file-usage-preview
        *ngIf="fileUsages"
        [fileUsages]="fileUsages"
        fit="contain"
        [routerLink]="
          inPortal
            | portalAddonUrlPipe
              : ['/minside', 'tilvalg', addonOrder.ApartmentId, addonOrder.AddonApartmentId]
              : [portalBaseUrl, addonOrder.AddonApartmentId]
        ">
      </apex-file-usage-preview>
    </aside>
  </section>
</section>
