import { CommonModule, KeyValuePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AxiosResponse } from 'axios';
import { Observable } from 'rxjs';
import {
  AllowedAddressModel,
  ApartmentApi,
  CompanyApi,
  CrmUserApi,
  DeleteResponseDto,
  ObjectApi,
  ProjectApi,
  ResponseAddressDto,
  ResponseAddressDtoEntityResponse,
  ResponseCountryDto,
  UserApi,
} from '../../../../../../../generated/apex-rest';
import { snack } from '../../../modules/snack.module';
import { restAxiosConfig } from '../../../utils/rest-axios-config';
import { t } from '../../translate/translate.function';
import { TranslateModule } from '../../translate/translate.module';
import { getTranslatedAddressTypes } from '../address-type-for-translation';
import { AddressAutocompleteResult } from '../autocomplete/address-autocomplete-result.type';
import { AddressAutocompleteComponent } from '../autocomplete/address-autocomplete.component';
import { SystemService } from '../services/system-service';
import { AddressFormDialogComponentData } from './address-form-dialog-component-data.type';

@Component({
  selector: 'apex-address-form-dialog',
  templateUrl: './address-form-dialog.component.html',
  standalone: true,
  providers: [SystemService],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    TranslateModule,
    MatSelectModule,
    MatDialogModule,
    AddressAutocompleteComponent,
    KeyValuePipe,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
  ],
})
export class AddressFormDialogComponent implements OnInit {
  translatedAddressModels = getTranslatedAddressTypes();
  countries$: Observable<ResponseCountryDto[]>;

  manualInput = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: AddressFormDialogComponentData,
    public dialogRef: MatDialogRef<ResponseAddressDto>,
    public systemService: SystemService,
  ) {}

  ngOnInit(): void {
    this.countries$ = this.systemService.fetchCountries();

    if (this.data.item?.id) {
      this.manualInput = !this.data.item.placeId;
    }
  }

  async submit(): Promise<void> {
    try {
      if (this.data?.item?.id) {
        if (this.data.addressModel === AllowedAddressModel.Company) {
          const api = new CompanyApi(restAxiosConfig());

          await this.closeDialogWithEntity(
            api.addressControllerCompanyUpdate(
              String(this.data.addressModelId),
              String(this.data.item.id),
              this.data.item,
            ),
          );
        }

        if (this.data.addressModel === AllowedAddressModel.Project) {
          const api = new ProjectApi(restAxiosConfig());

          await this.closeDialogWithEntity(
            api.addressControllerProjectUpdate(
              String(this.data.addressModelId),
              String(this.data.item.id),
              this.data.item,
            ),
          );
        }

        if (this.data.addressModel === AllowedAddressModel.Object) {
          const api = new ObjectApi(restAxiosConfig());

          await this.closeDialogWithEntity(
            api.addressControllerObjectUpdate(
              String(this.data.addressModelId),
              String(this.data.item.id),
              this.data.item,
            ),
          );
        }

        if (this.data.addressModel === AllowedAddressModel.User) {
          const api = new UserApi(restAxiosConfig());

          await this.closeDialogWithEntity(
            api.addressControllerUserUpdate(
              String(this.data.addressModelId),
              String(this.data.item.id),
              this.data.item,
            ),
          );
        }

        if (this.data.addressModel === AllowedAddressModel.CrmUser) {
          const api = new CrmUserApi(restAxiosConfig());

          await this.closeDialogWithEntity(
            api.addressControllerCrmUserUpdate(
              String(this.data.addressModelId),
              String(this.data.item.id),
              this.data.item,
            ),
          );
        }

        if (this.data.addressModel === AllowedAddressModel.Apartment) {
          const api = new ApartmentApi(restAxiosConfig());

          await this.closeDialogWithEntity(
            api.addressControllerApartmentUpdate(
              String(this.data.addressModelId),
              String(this.data.item.id),
              this.data.item,
            ),
          );
        }

        return;
      }

      if (this.data.addressModel === AllowedAddressModel.Company) {
        const api = new CompanyApi(restAxiosConfig());

        await this.closeDialogWithEntity(
          api.addressControllerCompanyCreate(String(this.data.addressModelId), this.data.item),
        );

        return;
      }

      if (this.data.addressModel === AllowedAddressModel.Project) {
        const api = new ProjectApi(restAxiosConfig());

        await this.closeDialogWithEntity(
          api.addressControllerProjectCreate(String(this.data.addressModelId), this.data.item),
        );

        return;
      }

      if (this.data.addressModel === AllowedAddressModel.Object) {
        const api = new ObjectApi(restAxiosConfig());

        await this.closeDialogWithEntity(
          api.addressControllerObjectCreate(String(this.data.addressModelId), this.data.item),
        );

        return;
      }

      if (this.data.addressModel === AllowedAddressModel.User) {
        const api = new UserApi(restAxiosConfig());

        await this.closeDialogWithEntity(
          api.addressControllerUserCreate(String(this.data.addressModelId), this.data.item),
        );

        return;
      }

      if (this.data.addressModel === AllowedAddressModel.CrmUser) {
        const api = new CrmUserApi(restAxiosConfig());

        await this.closeDialogWithEntity(
          api.addressControllerCrmUserCreate(String(this.data.addressModelId), this.data.item),
        );

        return;
      }

      if (this.data.addressModel === AllowedAddressModel.Apartment) {
        const api = new ApartmentApi(restAxiosConfig());

        await this.closeDialogWithEntity(
          api.addressControllerApartmentCreate(String(this.data.addressModelId), this.data.item),
        );

        return;
      }
    } catch (error) {
      snack(t('Could not create address'));
    }
  }

  async delete(): Promise<void> {
    if (this.data.addressModel === AllowedAddressModel.Company) {
      const api = new CompanyApi(restAxiosConfig());

      await this.closeDialogWithDelete(
        api.addressControllerCompanyDelete(String(this.data.addressModelId), String(this.data.item.id)),
      );

      return;
    }

    if (this.data.addressModel === AllowedAddressModel.Project) {
      const api = new ProjectApi(restAxiosConfig());

      await this.closeDialogWithDelete(
        api.addressControllerProjectDelete(String(this.data.addressModelId), String(this.data.item.id)),
      );

      return;
    }

    if (this.data.addressModel === AllowedAddressModel.Object) {
      const api = new ObjectApi(restAxiosConfig());

      await this.closeDialogWithDelete(
        api.addressControllerObjectDelete(String(this.data.addressModelId), String(this.data.item.id)),
      );

      return;
    }

    if (this.data.addressModel === AllowedAddressModel.User) {
      const api = new UserApi(restAxiosConfig());

      await this.closeDialogWithDelete(
        api.addressControllerUserDelete(String(this.data.addressModelId), String(this.data.item.id)),
      );

      return;
    }

    if (this.data.addressModel === AllowedAddressModel.CrmUser) {
      const api = new CrmUserApi(restAxiosConfig());

      await this.closeDialogWithDelete(
        api.addressControllerCrmUserDelete(String(this.data.addressModelId), String(this.data.item.id)),
      );

      return;
    }

    if (this.data.addressModel === AllowedAddressModel.Apartment) {
      const api = new ApartmentApi(restAxiosConfig());

      await this.closeDialogWithDelete(
        api.addressControllerApartmentDelete(String(this.data.addressModelId), String(this.data.item.id)),
      );

      return;
    }
  }

  async closeDialogWithEntity(
    responsePromise: Promise<AxiosResponse<ResponseAddressDtoEntityResponse>>,
  ): Promise<void> {
    const response = await responsePromise;

    snack(t('Address saved'));

    return this.dialogRef.close(response.data.Entity);
  }

  async closeDialogWithDelete(responsePromise: Promise<AxiosResponse<DeleteResponseDto>>): Promise<void> {
    await responsePromise;

    snack(t('Address deleted'));

    return this.dialogRef.close(true);
  }

  fillResult($event: AddressAutocompleteResult): void {
    this.data.item.line1 = $event.line1;
    this.data.item.line2 = $event.line2;
    this.data.item.latitude = $event.latitude;
    this.data.item.longitude = $event.longitude;
    this.data.item.postal = $event.postal;
    this.data.item.countryCode = $event.countryCodes;
    this.data.item.placeId = $event.placeId;
  }

  manualInputChange(change: MatCheckboxChange): void {
    this.manualInput = change.checked;
  }

  removePlaceId(): void {
    this.data.item.placeId = null;
  }
}
