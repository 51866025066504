<div mat-dialog-title>
  <div class="df f1 jsb ac">
    <h1>
      <T str="New case"></T>
    </h1>
    <button
      mat-icon-button
      (click)="dialogRef.close()">
      <mat-icon> clear</mat-icon>
    </button>
  </div>
</div>

<form
  #form="ngForm"
  (ngSubmit)="save()">
  <mat-dialog-content class="df c ap">
    <div class="df">
      <mat-form-field class="f1">
        <mat-label>
          <T str="Case name"></T>
        </mat-label>
        <input
          matInput
          cdkFocusInitial
          required
          name="name"
          [(ngModel)]="data.case.name"
          type="text" />
      </mat-form-field>
    </div>
    <div class="df">
      <mat-form-field class="f1">
        <mat-label>
          <T str="Description"></T>
        </mat-label>
        <input
          matInput
          name="description"
          [(ngModel)]="data.case.description"
          type="text" />
      </mat-form-field>
    </div>
    <div class="df">
      <ng-container *ngIf="showFields$ | async">
        <apex-autocomplete
          name="ObjectFieldId"
          class="f1 amr"
          type="ObjectField"
          [label]="'Discipline' | translate"
          [(ngModel)]="data.case.ObjectFieldId"
          [params]="{
            ObjectId: data.case.ObjectId,
            TenancyId: data.case.TenancyId,
          }">
        </apex-autocomplete>
      </ng-container>
      <mat-form-field class="f1">
        <mat-label>
          <T str="Location"></T>
        </mat-label>
        <input
          matInput
          name="location"
          [(ngModel)]="data.case.location"
          type="text" />
      </mat-form-field>
    </div>
    <ng-container *ngIf="tenancy$ | async as tenancy">
      <mat-form-field>
        <mat-label>
          <T str="Object"></T>
        </mat-label>
        <mat-select
          name="ObjectId"
          [(ngModel)]="caze.ObjectId"
          required
          class="steppable">
          <ng-container *ngFor="let object of getObjects(tenancy)">
            <mat-option
              [value]="object.id"
              class="apex-case-dialog-object-select-step"
              [ngClass]="getObjectClass(object)">
              {{ object.name }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="floorPlans?.length > 0">
      <apex-embedded-markings-viewer
        [fileUsages]="floorPlans"
        [model]="MarkingModelType.Case"
        [modelId]="caze.id"
        class="amb"
        (markingsChanged)="updateMarkings($event)">
      </apex-embedded-markings-viewer>
    </ng-container>
    <apex-file-usage
      #files
      class="f1"
      name="files"
      view="List"
      [title]="'Files' | translate"
      self="case"
      [selfId]="data.case?.id ? data.case.id : 0">
    </apex-file-usage>
  </mat-dialog-content>

  <mat-dialog-actions class="df">
    <div class="df f1 jfe">
      <button
        mat-raised-button
        type="button"
        (click)="dialogRef.close()">
        <T str="Cancel"></T>
      </button>
      <button
        mat-raised-button
        type="submit"
        class="aml"
        [disabled]="form.invalid"
        color="primary">
        <T str="Save"></T>
      </button>
    </div>
  </mat-dialog-actions>
</form>
