import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ItemModule } from '../../../../../../../apex/src/app/components/item/item.module';
import { TranslateModule } from '../../../../../../../apex/src/app/components/translate/translate.module';
import { HistoryBackModule } from '../../../../../../../apex/src/app/directives/history-back/history-back.module';
import { ClientPageAddonComponentsModule } from '../../../../../../../apex/src/app/features/client-page/addon/client-page-addon-components.module';
import { ClientPageService } from '../../../../../../../apex/src/app/features/client-page/client-page.service';
import { ApexCurrencyPipeModule } from '../../../../../../../apex/src/app/pipes/currency/currency.pipe.module';
import { PortalAddonOrderListComponent } from './portal-addon-order-list.component';

@NgModule({
  declarations: [PortalAddonOrderListComponent],
  imports: [
    CommonModule,
    MatCheckboxModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    ClientPageAddonComponentsModule,
    MatChipsModule,
    ItemModule,
    HistoryBackModule,
    MatPaginatorModule,
    ApexCurrencyPipeModule,
  ],
  providers: [ClientPageService],
  exports: [PortalAddonOrderListComponent],
})
export class PortalAddonOrderListModule {}
