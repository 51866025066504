<apex-box
  [expanded]="expanded"
  class="no-padding container steppable"
  [loading]="loading">
  <T
    title
    str="Objects ({sortedLength}/{objectsLength})"
    [vars]="{
      sortedLength: sortedList?.length ?? 0,
      objectsLength: objects?.length ?? 0,
    }">
  </T>

  <ng-container right>
    <button
      [matTooltip]="
        '{anyExpanded, select, false {Collapse all} other {Expand all}}' | translate: { anyExpanded: anyExpanded() }
      "
      type="button"
      mat-icon-button
      (click)="$event.stopPropagation(); globalExpCol()"
      [disabled]="!anyChildren()">
      <mat-icon>{{ anyExpanded() ? 'unfold_more' : 'unfold_less' }}</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngIf="sortedList?.length; else emptyList">
    <apex-drag
      [parent]="el"
      [singleMode]="true">
      <section class="df c">
        <ng-container *ngFor="let item of dragItems">
          <div class="df ac bb">
            <ng-container *ngTemplateOutlet="object; context: { item: item || {} }"></ng-container>
          </div>
        </ng-container>
      </section>
    </apex-drag>

    <section>
      <ng-container *ngFor="let item of sortedList">
        <div
          class="wrapit df ac bb"
          apexDrop
          [class.hide]="dragIds.includes(item.id)"
          (apexDrop)="drop(item)">
          <button
            apexDrag
            *ngIf="allowDrag"
            [disabled]="!item.ParentId"
            [apexDragDisabled]="!item.ParentId"
            (apexDragStart)="drag(item)"
            (apexDragEnd)="dragEnd()"
            model="object"
            [modelId]="item.id"
            mat-icon-button
            type="button">
            <mat-icon>drag_handle</mat-icon>
          </button>
          <ng-container *ngTemplateOutlet="object; context: { item: item }"></ng-container>
        </div>
      </ng-container>
    </section>
  </ng-container>

  <ng-template #emptyList>
    <div class="am ap df ac jc">
      <p>
        {{ emptyMessage }}
      </p>
    </div>
  </ng-template>
</apex-box>

<ng-template
  #object
  let-item="item">
  <div
    [ngClass]="'steps-' + objectSteps[item.id]"
    class="df f1 steps jsb ac bl new"
    matRipple
    [routerLink]="getObjectURL(item)">
    <div
      class="f1 df jsb ac"
      [ngClass]="item.highlighted ? 'highlighted' : ''">
      <a
        *ngIf="!view"
        class="aps df ac"
        (click)="$event.stopPropagation()"
        [routerLink]="getObjectURL(item)">
        <mat-icon class="amrs">{{ item | icon }}</mat-icon>
        {{ item.name }}
      </a>
      <div
        *ngIf="view"
        class="aps df ac">
        <mat-icon class="amrs">{{ item | icon }}</mat-icon>
        <span class="amr">{{ item.name }}</span>
        <span>{{ item.data?.address }}</span>
      </div>
      <div>
        <button
          mat-icon-button
          *ngIf="objectChildren[item.id]"
          (click)="expCol(item.id); $event.stopPropagation()">
          <mat-icon
            [matTooltip]="'Expand' | translate"
            *ngIf="expandedCollapsed[item.id]">
            expand_more
          </mat-icon>
          <mat-icon
            [matTooltip]="'Collapse' | translate"
            *ngIf="!expandedCollapsed[item.id]">
            expand_less
          </mat-icon>
        </button>
        <button
          *ngIf="!view"
          mat-icon-button
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="goMenu"
          [matMenuTriggerData]="{
            id: item.id,
            pid: item.ParentId,
            add: true,
            item: item,
          }">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<mat-menu #goMenu="matMenu">
  <ng-template
    matMenuContent
    let-id="id"
    let-pid="pid"
    let-add="add"
    let-item="item">
    <button
      mat-menu-item
      [disabled]="!add"
      [routerLink]="[id, 'new']"
      routerLinkActive="router-link-active">
      <mat-icon>add</mat-icon>
      <T str="Add"></T>
    </button>
    <button
      mat-menu-item
      [routerLink]="!item.ParentId ? ['/object', 'project', id, 'edit'] : [id, 'edit']"
      routerLinkActive="router-link-active">
      <mat-icon>edit</mat-icon>
      <T str="Edit"></T>
    </button>
    <button
      mat-menu-item
      *ngIf="item.ParentId"
      [matTooltip]="'Duplicate' | translate"
      [routerLink]="[pid, 'new']"
      [queryParams]="{ copyFrom: id }"
      queryParamsHandling="merge"
      routerLinkActive="router-link-active">
      <mat-icon>content_copy</mat-icon>
      <T str="Duplicate"></T>
    </button>
    <button
      mat-menu-item
      (click)="delete(item)">
      <mat-icon>delete</mat-icon>
      <T str="Delete"></T>
    </button>
  </ng-template>
</mat-menu>
