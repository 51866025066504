import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ClientPageInfo } from 'projects/apex/src/app/models/client-page-info';
import { map } from 'rxjs/operators';
import { HttpExtra } from '../../../services/http/http-extra';
import { CollectionResponse } from '../../../utils/types';
import { ClientPageInfoService } from '../client-page-info.service';

export const ClientPageInfosResolver: ResolveFn<CollectionResponse<ClientPageInfo>> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(ClientPageInfoService);

  const { limit, page, ProjectId, order } = route.queryParams;

  const params: Record<string, string> = {
    limit: limit ?? '25',
    page: page ?? '0',
    order: order ?? 'desc',
  };

  if (!!Number(ProjectId)) {
    params.projectId = ProjectId;
  }

  const extra: HttpExtra = {
    params,
  };

  return service.query(extra).pipe(
    map((res) => {
      res.Collection = res.Collection.map((info) => new ClientPageInfo(info));

      return res;
    }),
  );
};
