import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileUsageModule } from '../../../components/file-usage/file-usage.module';
import { PageInfoModule } from '../../../components/page-info/page-info.module';
import { TextareaModule } from '../../../components/textarea/textarea.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { PortalProductComponent } from './info.component';
import { ProductRequestDialogComponent } from './new-request-dialog.component';
import { PortalProductService } from './product.service';

@NgModule({
  declarations: [PortalProductComponent, ProductRequestDialogComponent],
  imports: [
    CommonModule,
    FormsModule,

    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatDividerModule,
    MatDialogModule,
    MatFormFieldModule,
    TextareaModule,
    MatInputModule,

    PageInfoModule,
    TranslateModule,
    FileUsageModule,
  ],
  exports: [PortalProductComponent, ProductRequestDialogComponent],
  providers: [PortalProductService],
})
export class PortalProductModule {}
