<section class="router-outlet-content apt">
  <div class="df c gap">
    <div class="df gap-horizontal ap mat-elevation-z2">
      <mat-form-field
        subscriptSizing="dynamic"
        class="f1">
        <mat-label>
          <T str="Search"></T>
        </mat-label>
        <input
          matInput
          type="text"
          name="searchStr"
          [(ngModel)]="search"
          (ngModelChange)="searchStr()" />
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label><T str="Apartments"></T></mat-label>
        <mat-select
          [(ngModel)]="selectedApartmentId"
          (selectionChange)="updateQueryParams(0)"
          name="apartment">
          <mat-option><T str="Reset"></T></mat-option>
          <mat-option
            [value]="apartment.id"
            *ngFor="let apartment of apartments">
            <span class="df c">
              <span>
                {{ apartment.name }}
              </span>
              <span
                class="mat-caption"
                *ngIf="apartment?.Project?.name">
                {{ apartment.Project.name }}
              </span>
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- need a way to disable request, mainObjects could get that information
    <div>
      <button
        mat-raised-button
        color="primary"
        (click)="createRequest()">
        <T str="Create request"></T>
      </button>
    </div>
     -->
  </div>
  <portal-portal-case-list></portal-portal-case-list>
</section>
