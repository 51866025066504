import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../../../services/http/http.service';
import { Product } from '../../object/safety/safety.model';
import { ProductRequestData } from './new-request-dialog.component';

@Injectable()
export class PortalProductService extends HttpService<Product> {
  route = 'portal/product';

  postRequest(productId: number, requestData: ProductRequestData): Observable<null> {
    return this.http.post<null>(`${this.apiUrl}/${this.route}/${productId}/case`, requestData, this.options);
  }
}
