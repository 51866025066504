import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule } from '../../../../../../apex/src/app/components/translate/translate.module';
import { CaseLogsModule } from '../../../../../../apex/src/app/features/case/logs/case-logs.module';
import { PortalService } from '../../../portal.service';
import { PortalCaseLogComponent } from './portal-case-log.component';

@NgModule({
  declarations: [PortalCaseLogComponent],
  exports: [PortalCaseLogComponent],
  imports: [
    CommonModule,
    CaseLogsModule,
    MatPaginatorModule,
    MatInputModule,
    TranslateModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
  ],
  providers: [PortalService],
})
export class PortalCaseLogModule {}
