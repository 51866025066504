import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AddonCategory } from 'projects/apex/src/app/models/addon-category';
import { AddonOrder } from 'projects/apex/src/app/models/addon-order';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { scrollWrap } from 'projects/apex/src/app/utils/functions';

@Component({
  selector: 'apex-client-addon-order-page',
  templateUrl: './order.component.html',
})
export class ClientAddonOrderPageComponent implements OnInit {
  addonOrder: AddonOrder;
  addonCategory: AddonCategory;

  inPortal = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    scrollWrap(0, 0);

    const data = this.route.snapshot.data;

    this.addonOrder = data.addonOrder;
    this.addonOrder.AddonApartment.Addon = this.addonOrder.Addon;

    this.inPortal = data.portal;
  }

  goToApartment(apartment: Apartment): void {
    if (this.inPortal) {
      void this.router.navigate(['/project', apartment.ProjectId, 'apartment', apartment.id, 'addon']);
    } else {
      void this.router.navigate(['minside', 'tilvalg', apartment.id]);
    }
  }

  back(): void {
    this.location.back();
  }
}
