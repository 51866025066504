<div class="mat-elevation-z2">
  <apex-box
    class="no-padding"
    [expanded]="expanded"
    [expandable]="expandable"
    icon="import_contacts">
    <T
      title
      str="Addresses ({count})"
      [vars]="{ count: addresses?.length }"></T>

    <T
      description
      str="Add multiple addresses if you need">
    </T>
    <button
      *ngIf="allowEdit"
      right
      mat-icon-button
      type="button"
      [matTooltip]="'New Address' | translate"
      (click)="newAddress(); $event.stopPropagation()">
      <mat-icon>add</mat-icon>
    </button>
    <div
      class="add-margin-left"
      *ngIf="addresses?.length === 0">
      <p><T str="No addresses to show"></T></p>
    </div>
    <div class="list-wrapper scrollable-vertical">
      <mat-list>
        <ng-container
          *ngFor="let item of addresses"
          class="mat-elevation-z2">
          <mat-list-item lines="3">
            <span matListItemTitle>
              <apex-formatted-address [address]="item"></apex-formatted-address>
              <!--<ng-container *ngIf="item.line1">{{ item?.line1 }} , </ng-container>
              <ng-container *ngIf="item?.line2">{{ item.line2 }} </ng-container>
              <ng-container *ngIf="item?.postal">
                ,
                {{ item.postal }}
              </ng-container>
              <ng-container *ngIf="item?.countryCode">
                ,
                {{ item.countryCode }}
              </ng-container>-->
            </span>
            <span matListItemLine
              >{{ translatedAddressModels[item?.type] }}
              <ng-container *ngIf="item?.reference">
                -
                <T
                  str="Reference: {reference}"
                  [vars]="{ reference: item?.reference }"></T>
              </ng-container>
            </span>
            <span
              matListItemLine
              *ngIf="item?.note">
              <T
                str="Note: {note}"
                [vars]="{ note: item.note }"></T>
            </span>
            <span matListItemMeta>
              <button
                *ngIf="allowEdit"
                (click)="edit(item)"
                mat-icon-button
                [matTooltip]="'Edit' | translate">
                <mat-icon>edit</mat-icon>
              </button>
            </span>
          </mat-list-item>

          <mat-divider></mat-divider>
        </ng-container>
      </mat-list>
    </div>
  </apex-box>
</div>
