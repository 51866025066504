<ng-container *ngIf="profile && tenancy && objects">
  <apex-cases
    #cases
    [listFullSize]="true"
    class="mat-elevation-z2 am set-min-height"
    style="--min-height: 400px"
    dialogClass="tenancy"
    [customAdd]="customAdd"
    [newCaseValues]="{ ObjectId: tenancy.topObject.id }"
    [standardParams]="{ Tenancy: tenancy.id, showOpen: true, showCompleted: true, showCaseManagers: true }"
    [showCalendar]="false">
  </apex-cases>
</ng-container>
