import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { UserService } from '../../../services/user/user.service';
import { Product } from '../../object/safety/safety.model';
import { PortalProductService } from './product.service';

export const PortalProductResolver: ResolveFn<Product> = (route: ActivatedRouteSnapshot) => {
  const service = inject(PortalProductService);

  const id = Number(route.paramMap.get('id'));

  return service.get(id);
};

export const PortalProductsResolver: ResolveFn<Product[]> = () => {
  const service = inject(PortalProductService);
  const userService = inject(UserService);

  if (!userService.profile?.salesCount) {
    return of([]);
  }

  return service.query();
};
