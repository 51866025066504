<h1
  class="mat-headline-5"
  mat-dialog-title>
  <T str="Alert"></T>
</h1>

<mat-dialog-content>
  <div *ngIf="data?.expiredAddons?.length">
    <h2 class="mat-h3">
      <T
        str="The following {count, plural, =0 {addons} one {addon} other {addons}} from your cart are expired and have been removed:"
        [vars]="{ count: data?.expiredAddons?.length ?? 0 }">
      </T>
    </h2>
    <mat-list>
      <mat-list-item
        lines="2"
        *ngFor="let expiredAddon of data.expiredAddons">
        <p matListItemLine>{{ expiredAddon.Addon.name }}</p>
        <p matListItemLine>
          <T
            str="Expired on {dateEnd}"
            [vars]="{
              dateEnd: quickDate(expiredAddon.AddonApartment.dateEnd, 'short'),
              _comment: 'Shows short date',
            }">
          </T>
        </p>
      </mat-list-item>
    </mat-list>
  </div>

  <div *ngIf="data?.addonsExpireToday?.length">
    <h2>
      <T
        str="The following {count, plural, one {addon} other {addons}} from your cart will expire today:"
        [vars]="{ count: data?.addonsExpireToday?.length ?? 0 }">
      </T>
    </h2>
    <mat-list>
      <mat-list-item
        lines="1"
        *ngFor="let addonsExpireToday of data.addonsExpireToday">
        <p matListItemLine>{{ addonsExpireToday.Addon.name }}</p>
      </mat-list-item>
    </mat-list>
  </div>

  <div *ngIf="data?.addonsExpireTomorrow?.length">
    <h2>
      <T
        str="The following {count, plural, one {addon} other {addons}} from your cart will expire tomorrow:"
        [vars]="{ count: data?.addonsExpireTomorrow?.length ?? 0 }">
      </T>
    </h2>
    <mat-list>
      <mat-list-item
        lines="1"
        *ngFor="let addonsExpireTomorrow of data.addonsExpireTomorrow">
        <p matListItemLine>{{ addonsExpireTomorrow.Addon.name }}</p>
      </mat-list-item>
    </mat-list>
  </div>

  <div *ngIf="data?.updatedAddons?.length">
    <h2>
      <T
        str="The following {count, plural, one {addon} other {addons}} from your cart has new values and is therefore removed from cart:"
        [vars]="{ count: data?.updatedAddons?.length ?? 0 }">
      </T>
    </h2>
    <mat-list>
      <mat-list-item
        lines="1"
        *ngFor="let updatedAddons of data.updatedAddons">
        <a
          matListItemLine
          target="_blank"
          [routerLink]="[
            'minside',
            'tilvalg',
            updatedAddons.AddonApartment.ApartmentId,
            updatedAddons.AddonApartmentId,
          ]">
          {{ updatedAddons.Addon.name }}</a
        >
      </mat-list-item>
    </mat-list>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    mat-dialog-close
    cdkFocusInitial>
    <T str="Ok"></T>
  </button>
</mat-dialog-actions>
