<div
  class="addon-card-wrapper df c"
  [class.selected]="currentSelected"
  (click)="addonClicked.emit(addonApartment)"
  (apexInView)="$event && inView()"
  [attr.id]="addonApartment.Addon.name"
  inViewOnlyOnce
  matRipple>
  <div class="filusage-wrapper">
    <apex-file-usage-preview
      *ngIf="fileUsages"
      [fileUsages]="fileUsages">
    </apex-file-usage-preview>
  </div>

  <mat-icon
    class="selectedIcon"
    [ngClass]="{
      'op-1': currentSelected,
      'op-0': !currentSelected,
    }">
    check_circle
  </mat-icon>

  <div class="info apls apts apbxs f1 df">
    <div class="text-wrapper df c f1 amrxs oh ap">
      <h4 class="line-clamp-2 mat-h3 rmb addon-name">
        {{ addonApartment.Addon.name }}
      </h4>
      <div
        class="dib mat-caption hyphens expires"
        [ngClass]="
          hasExpired
            ? ''
            : isLastDayOrWeek === 'day'
              ? ' error-color'
              : isLastDayOrWeek === 'week'
                ? 'warning-color'
                : ''
        "
        [matTooltip]="addonApartment.dateEnd | date: 'shortDate'">
        <T
          str="Expired"
          *ngIf="hasExpired"></T>
        <T
          str="{timeLeft} left"
          *ngIf="!hasExpired"
          [vars]="{ timeLeft }"></T>
      </div>
      <div class="f1 df c">
        <div class="addon-text-container f1 df afe ambs amt oh">
          <ng-container *ngIf="!addonApartment.standardForCategory">
            <div class="df ac">
              <div
                class="truncate"
                [matTooltip]="addonApartment.priceOut * ((addonApartment.VAT + 100) / 100) | apexCurrency: 'NOK' : ''">
                {{ addonApartment.priceOut * ((addonApartment.VAT + 100) / 100) | apexCurrency: 'NOK' : '' }}
              </div>
              <div
                *ngIf="addonApartment?.unitPrice"
                class="mat-h4 rm truncate">
                &nbsp;
                <T str="per unit"></T>
              </div>
            </div>
          </ng-container>
        </div>
        <div
          class="mat-h4 truncate ambs"
          *ngIf="addonApartment.standardForCategory">
          <T str="Standard"></T>
        </div>
      </div>
    </div>
    <div
      class="df c amrxs jfe p-jsb"
      *ngIf="!addonApartment.standardForCategory && editable && !hasExpired">
      <div>
        <button
          mat-icon-button
          *ngIf="currentSelected && (addonApartment.markingMin !== 0 || addonApartment.markingMax !== 0)"
          [matTooltip]="'Click to edit markings' | translate"
          (click)="$event.stopPropagation(); markingClicked()"
          (mousedown)="$event.stopPropagation()">
          <mat-icon
            svgIcon="floorplan"
            [matBadgeHidden]="!addonApartment?.Markings || !addonApartment?.Markings?.length"
            [matBadge]="
              addonApartment?.Markings?.length >= addonApartment?.markingMin ? addonApartment?.Markings?.length : '!'
            "
            [matBadgeColor]="
              addonApartment.Markings && addonApartment.Markings.length >= addonApartment.markingMin
                ? 'primary'
                : 'warn'
            ">
          </mat-icon>
        </button>
      </div>
      <button
        mat-icon-button
        *ngIf="!currentSelected"
        (click)="$event.stopPropagation(); cartClicked()"
        (mousedown)="$event.stopPropagation()"
        [matTooltip]="addTooltip">
        <mat-icon>add_shopping_cart</mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="currentSelected"
        (click)="$event.stopPropagation(); remove.emit(addonApartment)"
        (mousedown)="$event.stopPropagation()">
        <mat-icon>remove_shopping_cart</mat-icon>
      </button>
    </div>
  </div>
</div>
