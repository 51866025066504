import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { orderBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { AllowedAddressModel, ResponseAddressDto } from '../../../../../../generated/apex-rest';
import { BoxModule } from '../box/box.module';
import { t } from '../translate/translate.function';
import { TranslateModule } from '../translate/translate.module';
import { AddressFormDialogComponentData } from './address-form-dialog/address-form-dialog-component-data.type';
import { AddressFormDialogComponent } from './address-form-dialog/address-form-dialog.component';
import { getTranslatedAddressTypes } from './address-type-for-translation';
import { AddressAutocompleteComponent } from './autocomplete/address-autocomplete.component';
import { FormattedAddressComponent } from './formatted-address/formatted-address.component';

@Component({
  selector: 'apex-addresses',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
    FormsModule,
    CommonModule,
    MatInputModule,
    RouterLink,
    TranslateModule,
    MatCardModule,
    AddressAutocompleteComponent,
    BoxModule,
    MatTooltipModule,
    FormattedAddressComponent,
  ],
})
export class AddressComponent implements OnInit {
  addresses: ResponseAddressDto[] = [];
  translatedAddressModels = getTranslatedAddressTypes();

  @Input() expandable = false;
  @Input() expanded = true;
  @Input() title: string = t('Addresses');
  @Input() allowEdit = false;
  @Input() modelId = '';
  @Input() addressModel: AllowedAddressModel;

  private subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.data.subscribe((data) => {
        this.addresses = orderBy(data.addresses, ['createdAt'], ['desc']);
      }),
    );
  }

  newAddress(): void {
    const item = {} as ResponseAddressDto;

    this.dialog
      .open<AddressFormDialogComponent, AddressFormDialogComponentData, ResponseAddressDto | true | undefined>(
        AddressFormDialogComponent,
        {
          data: { item, addressModel: this.addressModel, addressModelId: this.modelId },
        },
      )
      .afterClosed()
      .subscribe((address) => {
        if (!address) {
          return;
        }

        if (address === true) {
          return;
        }

        this.addresses.push(address);
        this.addresses = orderBy(this.addresses, ['createdAt'], ['desc']);
      });
  }

  edit(item): void {
    this.dialog
      .open<AddressFormDialogComponent, AddressFormDialogComponentData, ResponseAddressDto | true | undefined>(
        AddressFormDialogComponent,
        {
          data: { item, addressModelId: this.modelId, addressModel: this.addressModel },
        },
      )
      .afterClosed()
      .subscribe((address) => {
        if (!address) {
          return;
        }

        if (address === true) {
          const index = this.addresses.findIndex((i) => i.id === item.id);

          this.addresses.splice(index, 1);

          return;
        }
      });
  }
}
