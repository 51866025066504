import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { locale as l } from '../../utils/functions';

@Pipe({
  name: 'apexCurrency',
})
export class ApexCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(
    value: number | string,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null;
  transform(
    value: null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): null;
  transform(
    value: number | string | null | undefined,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string,
  ): string | null {
    if (typeof value !== 'number') {
      return null;
    }

    const transformed = super.transform(value, currencyCode, display, digitsInfo, locale ? locale : l());

    return (transformed ?? '').trim();
  }
}
