import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'projects/apex/src/app/components/avatar/avatar.module';
import { PortalCaseDialogModule } from 'projects/apex/src/app/components/case-dialog/case-dialog.module';
import { FileUsageModule } from 'projects/apex/src/app/components/file-usage/file-usage.module';
import { ModelRoleModule } from 'projects/apex/src/app/components/model-role/model-role.module';
import { PageInfoModule } from 'projects/apex/src/app/components/page-info/page-info.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { CaseModule } from 'projects/apex/src/app/features/case/case.module';
import { ObjectListModule } from 'projects/apex/src/app/features/object/components/object-list/object-list.module';
import { TenancyModule } from 'projects/apex/src/app/features/object/project/tenancy/tenancy.module';
import { BoxModule } from '../../../../apex/src/app/components/box/box.module';
import { MapModule } from '../../../../apex/src/app/components/map/map.module';
import { IconPipeModule } from '../../../../apex/src/app/pipes/icon/icon-pipe.module';
import { PortalTenancyPageComponent } from './portal-tenancy-page.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    TranslateModule,
    PageInfoModule,
    TenancyModule,
    ModelRoleModule,
    ObjectListModule,
    CaseModule,
    AvatarModule,
    FileUsageModule,
    PortalCaseDialogModule,

    MatDialogModule,
    MatIconModule,
    MatTabsModule,
    BoxModule,
    MatTooltipModule,
    IconPipeModule,
    MapModule,
    MatDividerModule,
    MatTabsModule,
    FormsModule,
  ],
  exports: [PortalTenancyPageComponent],
  declarations: [PortalTenancyPageComponent],
})
export class PortalTenancyPageModule {}
