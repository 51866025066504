import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortalCaseDialogComponent } from '../../../../../apex/src/app/components/case-dialog/case-dialog.component';
import { PortalCaseDialogData } from '../../../../../apex/src/app/components/case-dialog/types';
import { CaseModule } from '../../../../../apex/src/app/features/case/case.module';
import { Obj } from '../../../../../apex/src/app/features/object/object.model';
import { Case } from '../../../../../apex/src/app/models/case';
import { Profile } from '../../../../../apex/src/app/models/profile';
import { Tenancy } from '../../../../../apex/src/app/models/tenancy';

@Component({
  selector: 'portal-tenancy-cases',
  templateUrl: './portal-tenancy-case-page.component.html',
  standalone: true,
  imports: [CaseModule, CommonModule],
})
export default class PortalTenancyCasePageComponent implements OnInit, OnDestroy {
  tenancy: Tenancy;
  objects: Obj[];
  profile: Profile;

  subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  customAdd(newCase?: Case): Observable<Case> {
    return this.dialog
      .open<PortalCaseDialogComponent, PortalCaseDialogData, PortalCaseDialogData>(PortalCaseDialogComponent, {
        data: {
          case: {
            ...newCase,
            ObjectId: newCase.ObjectId ?? null,
            notifyContractor: true,
            notifyClient: false,
            sendSMS: false,
          },
        },
      })
      .afterClosed()
      .pipe(map((d) => d?.case));
  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.data
        .pipe(map((data) => [data.tenancy, data.objects, data.profile] as [Tenancy, Obj[], Profile]))
        .subscribe({
          next: ([tenancy, objects, profile]) => {
            this.tenancy = tenancy;
            this.objects = objects;
            this.profile = profile;
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
