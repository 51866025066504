<section>
  <apex-title-item class="amb">
    <ng-container title>
      <div class="mat-headline-6 rm">
        {{ viewCase?.name }}
      </div>
    </ng-container>

    {{ viewCase?.description }}
  </apex-title-item>

  <mat-divider></mat-divider>

  <section class="display-grid-cards amv">
    <ng-container *ngIf="viewCase?.CaseCategory">
      <apex-title-item>
        <T
          title
          str="Category">
        </T>

        {{ viewCase?.CaseCategory?.name | translate }}
      </apex-title-item>
    </ng-container>

    <ng-container *ngIf="viewCase?.Field">
      <apex-title-item>
        <T
          title
          str="Discipline">
        </T>

        {{ viewCase?.Field.name }}
      </apex-title-item>
    </ng-container>

    <ng-container *ngIf="viewCase?.location">
      <apex-title-item>
        <T
          title
          str="Location">
        </T>

        {{ viewCase?.location }}
      </apex-title-item>
    </ng-container>
  </section>

  <apex-file-usage
    [disableToolsAsClient]="true"
    class="amv"
    self="case"
    [selfId]="viewCase?.id"
    name="files"
    [title]="'Files' | translate">
  </apex-file-usage>

  <portal-portal-case-log
    [projectId]="projectId"
    [apartmentId]="apartmentId"
    [caseId]="viewCase?.id"
    [caseLogPageResponse]="caseLogPageResponse"></portal-portal-case-log>
</section>
