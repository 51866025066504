import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { BoxModule } from 'projects/apex/src/app/components/box/box.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';

import { MatRippleModule } from '@angular/material/core';
import { ProductsComponent } from './products.component';

@NgModule({
  imports: [CommonModule, RouterModule, MatIconModule, MatTooltipModule, BoxModule, TranslateModule, MatRippleModule],
  exports: [ProductsComponent],
  declarations: [ProductsComponent],
  providers: [],
})
export class ProductsModule {}
