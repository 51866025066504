import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { orderBy } from 'lodash-es';
import {
  ResponsePortalCaseDto,
  ResponsePortalFileDto,
  ResponsePortalMainObjectDto,
} from '../../../../../../../../generated/apex-rest';
import {
  FileViewerDialogComponent,
  FileViewerDialogData,
} from '../../../../../../../apex/src/app/components/file-viewer/dialog.component';
import { File } from '../../../../../../../apex/src/app/models/file';
import { PortalService } from '../../../../portal.service';

@Component({
  selector: 'portal-portal-case-list-item',
  templateUrl: './portal-case-list-item.component.html',
})
export class PortalCaseListItemComponent implements OnInit {
  @Input() caseItem: ResponsePortalCaseDto;
  @Input() dense = false;

  files: File[];

  apartments: Array<ResponsePortalMainObjectDto> = [];
  apartmentName: string;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private portalService: PortalService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.apartments = this.route.snapshot.data.entities ?? [];

    this.apartmentName = this.apartments.find((a) => a.id === String(this.caseItem.ApartmentId))?.name;

    this.caseItem.FileUsages = orderBy(this.caseItem.FileUsages, ['fileSort'], ['asc']);

    this.files = this.caseItem.FileUsages.flatMap((fu) => this.mapPortalFileToFile(fu.File));
  }

  viewFiles(): void {
    this.dialog
      .open<FileViewerDialogComponent, FileViewerDialogData, null>(FileViewerDialogComponent, {
        data: {
          files: this.files,
          showFolderAction: false,
        },
      })
      .afterClosed()
      .subscribe();
  }

  private mapPortalFileToFile(file: ResponsePortalFileDto): File {
    return {
      ...file,
      path: '',
      size: 0,
      signed: {
        ...file.signed,
      },
      createdAt: new Date(file.createdAt),
      updatedAt: new Date(file.updatedAt),
      deletedAt: undefined,
    };
  }
}
