import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'projects/apex/src/app/components/page-not-found/page-not-found.component';
import { ApartmentInfoComponent } from '../../../../apex/src/app/features/apartment/info/apartment-info.component';
import { ClientPageInfosResolver } from '../../../../apex/src/app/features/client-page-info/list/list.resolve';
import { ClientQueryAddonCategoryByApartmentResolver } from '../../../../apex/src/app/features/client-page/client-page.resolve';
import { ClientPageDocumentsComponent } from '../../../../apex/src/app/features/client-page/documents/client-page-documents.component';
import { ClientPageDocumentsForApartmentResolver } from '../../../../apex/src/app/features/client-page/documents/client-page-documents.resolver';
import { RequestsResolver } from '../../../../apex/src/app/features/request/request.resolver';
import { PortalCasesViewDialogEntryComponent } from '../cases/portal-case-view-entry.component';
import { PortalAddonOrdersComponent } from './addon/orders/portal-addon-orders';
import { PortalAddonApartmentsResolver } from './addon/resolver/portal-addon-apartments.resolver';
import { PortalAddonOrdersResolver } from './addon/resolver/portal-addon-orders.resolver';
import { ApartmentPageComponent } from './apartment-page.component';
import { CaseRequestPageComponent } from './case/case-request-page.component';
import {
  PortalApartmentCaseLogsResolver,
  PortalApartmentCaseResolver,
  PortalApartmentCasesResolver,
  PortalApartmentMarkCaseReadResolver,
} from './case/case-request-page.resolver';
import { NewsComponent } from './news/news.component';
import { PortalApartmentArticlesResolver } from './news/resolver/portal-apartment-articles.resolver';
import { PortalApartmentBannerImagesResolver } from './resolver/portal-apartment-banner-images.resolver';
import { PortalApartmentCompanyLogosResolver } from './resolver/portal-apartment-company-logos.resolver';
import { PortalApartmentResolver } from './resolver/portal-apartment.resolver';

const routes: Routes = [
  {
    path: '',
    component: ApartmentPageComponent,
    resolve: {
      apartment: PortalApartmentResolver,
      bannerImages: PortalApartmentBannerImagesResolver,
      companyLogos: PortalApartmentCompanyLogosResolver,
    },
    children: [
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'news',
      },
      {
        path: 'news',
        runGuardsAndResolvers: 'always',
        resolve: {
          clientPageInfos: ClientPageInfosResolver,
          articles: PortalApartmentArticlesResolver,
        },
        component: NewsComponent,
      },
      {
        path: 'info',
        component: ApartmentInfoComponent,
      },
      {
        path: 'case',
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',

        resolve: {
          cases: PortalApartmentCasesResolver,
          requests: RequestsResolver,
        },
        component: CaseRequestPageComponent,
        children: [
          {
            path: ':CaseId',
            runGuardsAndResolvers: 'always',
            resolve: {
              caseRead: PortalApartmentMarkCaseReadResolver,
              caseItem: PortalApartmentCaseResolver,
              caseLogs: PortalApartmentCaseLogsResolver,
            },
            component: PortalCasesViewDialogEntryComponent,
          },
        ],
      },
      {
        path: 'document',
        resolve: {
          documents: ClientPageDocumentsForApartmentResolver,
        },
        component: ClientPageDocumentsComponent,
      },
      {
        path: 'addon-orders',
        component: PortalAddonOrdersComponent,
        resolve: {
          addonOrders: PortalAddonOrdersResolver,
          addonApartments: PortalAddonApartmentsResolver,
          addonCategories: ClientQueryAddonCategoryByApartmentResolver,
        },
      },
      {
        path: 'mom',
        loadChildren: () =>
          import('../../../../apex/src/app/features/apartment-mom/apartment-mom.module').then(
            (m) => m.ApartmentMOMModule,
          ),
      },
      {
        path: 'addon',
        data: {
          portal: true,
        },
        loadChildren: () => import('./addon/portal-addon-module.module').then((mod) => mod.PortalAddonModuleModule),
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ApartmentPageRoutingModule {}
