import { Pipe, PipeTransform } from '@angular/core';
import { AddonOrderCardData } from '../orders/types/addon-order-card-data.type';

@Pipe({
  name: 'hasAddons',
  standalone: true,
})
export class HasAddonsPipe implements PipeTransform {
  transform(categoryId: number, addonOrders: AddonOrderCardData[], addonApartments: AddonOrderCardData[]): boolean {
    const hasAddonOrders = addonOrders.some((ao) => ao.AddonId === categoryId);
    const hasStandardAddons = addonApartments.some((aa) => aa.standardForCategory && aa.AddonId === categoryId);

    return hasAddonOrders || hasStandardAddons;
  }
}
