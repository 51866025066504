import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutocompleteModule } from '../../../components/autocomplete/autocomplete.module';
import { FileUsageModule } from '../../../components/file-usage/file-usage.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { RequestService } from '../request.service';
import { RequestDialogComponent } from './request-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,

    TranslateModule,
    FileUsageModule,
    AutocompleteModule,

    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
  ],
  exports: [RequestDialogComponent],
  declarations: [RequestDialogComponent],
  providers: [RequestService],
})
export class RequestDialogModule {}
