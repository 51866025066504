import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { CaseParams } from '../../../models/case-params';
import { Control, Product, ProductType } from '../../object/safety/safety.model';
import { ProductRequestDialogComponent } from './new-request-dialog.component';

@Component({
  selector: 'apex-portal-product',
  templateUrl: './info.component.html',
})
export class PortalProductComponent implements OnInit, OnDestroy {
  product: Product;
  controls: Control[] = [];
  productType: ProductType;

  caseQueryParams = new CaseParams();

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.data.subscribe({
        next: (d: { product: Product }) => {
          this.product = d.product;
          this.productType = d.product?.ProductType;

          this.controls = (d.product?.Controls || []).sort((a: Control, b: Control) => {
            const da = moment(a?.data?.date).unix();
            const db = moment(b?.data?.date).unix();

            return db - da;
          });

          this.setCaseQueryParams();
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  newRequest(): void {
    this.subscription.add(
      this.dialog
        .open(ProductRequestDialogComponent, {
          data: {
            productId: this.product.id,
          },
        })
        .afterClosed()
        .subscribe(),
    );
  }

  private setCaseQueryParams(): void {
    this.caseQueryParams.Object = this.product?.id;
    this.caseQueryParams.showOpen = true;
    this.caseQueryParams.showCompleted = true;
    this.caseQueryParams.showCaseManagers = true;
  }
}
