<apex-client-addon-breadcrumb></apex-client-addon-breadcrumb>
<!-- <p>
  <T
    str="Choose a apartment for which you want to make addons on. Apartments are shown if there are available
    addons on the apartment.">
  </T>
</p> -->
<apex-client-addon-apartment-list
  [apartments]="apartments"
  (clicked)="goToApartment($event)">
</apex-client-addon-apartment-list>
