<div class="apex-client-addon-breadcrumb">
  <div class="df ac gap wrap jfe ambs p-amts">
    <!--
    <button
      mat-raised-button
      [routerLink]="['/project', apartment?.Project?.id, 'apartment', apartment?.id, 'addon-orders']">
      <T str="Order overview"></T>
      <mat-icon>preview</mat-icon>
    </button>
    -->
    <div *ngIf="apartment">
      <button
        mat-raised-button
        [matBadge]="cart?.Product?.length ?? 0"
        [matBadgeHidden]="!cart?.Product?.length"
        [routerLink]="cartUrl"
        [matTooltip]="'Cart' | translate">
        <ng-container *ngIf="cart; else noCart">
          {{ cartAmount | apexCurrency: 'NOK' : '' }}
        </ng-container>
        <ng-template #noCart>
          {{ 0 | apexCurrency: 'NOK' : '' }}
        </ng-template>

        <mat-icon>shopping_cart</mat-icon>
      </button>
    </div>
    <div *ngIf="!addonManager">
      <button
        mat-raised-button
        [routerLink]="inPortal | portalAddonUrlPipe: ['/minside', 'tilvalg', 'ordre'] : [portalBaseUrl, 'order']"
        [matTooltip]="'Orders' | translate: { _context: 'portal:addon' }">
        <mat-icon>receipt_long</mat-icon>

        <T
          str="Orders"
          _context="portal:addon"
          context="portal:addon"></T>
      </button>
    </div>

    <div *ngIf="this?.apartment?.Project?.allowAddonOverview">
      <button
        mat-raised-button
        [routerLink]="['/', 'project', this.apartment.ProjectId, 'apartment', this.apartment.id, 'addon', 'overview']"
        [matTooltip]="'Overview' | translate: { _context: 'portal:addon' }">
        <T
          str="Overview"
          _context="portal:addon"
          context="portal:addon"></T>
      </button>
    </div>

    <div class="df r ac d-jfe p-jc">
      <div *ngIf="apartment && !addon">
        <button
          mat-icon-button
          (click)="openFloorplans()"
          [matTooltip]="'Floorplans' | translate">
          <mat-icon svgIcon="floorplan"></mat-icon>
        </button>
      </div>

      <div>
        <ng-content select="[toc]"></ng-content>
      </div>

      <div>
        <ng-content select="[search]"></ng-content>
        <ng-content select="[amount]"></ng-content>
      </div>
    </div>
  </div>
</div>

<div class="df amb f1 jfs apt">
  <div class="df">
    <a
      *ngIf="addonManager"
      [routerLink]="['/addon']">
      <T str="Addon"></T>
    </a>
    <a
      *ngIf="!addonManager"
      [routerLink]="inPortal | portalAddonUrlPipe: ['/minside', 'tilvalg'] : [portalBaseUrl]">
      <T str="Addon"></T>
    </a>
  </div>
  <ng-container *ngIf="inOrder">
    <div class="df jc">
      <mat-icon inline>chevron_right</mat-icon>
    </div>
    <div class="df">
      <a [routerLink]="inPortal | portalAddonUrlPipe: ['/minside', 'tilvalg', 'ordre'] : [portalBaseUrl, 'order']">
        <T
          str="Orders"
          _context="portal:addon"
          context="portal:addon"></T>
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="addonOrder">
    <div class="df jc">
      <mat-icon inline>chevron_right</mat-icon>
    </div>
    <div class="df">
      <a
        [routerLink]="
          inPortal
            | portalAddonUrlPipe
              : ['/minside', 'tilvalg', 'ordre', addonOrder.id]
              : [portalBaseUrl, 'order', addonOrder.id]
        ">
        {{ addonOrder.id }}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="apartment">
    <div class="df jc">
      <mat-icon inline>chevron_right</mat-icon>
    </div>
    <div class="df">
      <a
        *ngIf="addonManager"
        [routerLink]="['/project', apartment.Project.id, 'addons']"
        >{{ apartment.Project.name }}</a
      >
      <a *ngIf="!addonManager">{{ apartment.Project.name }}</a>
    </div>
    <div class="df jc">
      <mat-icon inline>chevron_right</mat-icon>
    </div>
    <div class="df">
      <a [routerLink]="inPortal | portalAddonUrlPipe: ['../'] : [portalBaseUrl]">
        {{ apartment.name }}
      </a>
    </div>
    <ng-container *ngIf="overview">
      <div class="df jc">
        <mat-icon inline>chevron_right</mat-icon>
      </div>
      <div class="df">
        <a
          [routerLink]="
            inPortal
              | portalAddonUrlPipe: ['/minside', 'tilvalg', apartment.id, 'overview'] : [portalBaseUrl, 'overview']
          ">
          <T str="Overview"></T>
        </a>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="addon && !inCart">
    <div class="df jc">
      <mat-icon inline>chevron_right</mat-icon>
    </div>
    <div class="df">
      <a
        [routerLink]="
          inPortal | portalAddonUrlPipe: ['/minside', 'tilvalg', apartment.id, addon.name] : [portalBaseUrl, addon.name]
        ">
        {{ addon.name }}
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="inCart">
    <div class="df jc">
      <mat-icon inline>chevron_right</mat-icon>
    </div>
    <div class="df">
      <a
        class="truncate"
        [routerLink]="
          inPortal | portalAddonUrlPipe: ['/minside', 'tilvalg', apartment.id, 'cart'] : [portalBaseUrl, 'cart']
        ">
        <T str="Cart"></T>
      </a>
    </div>
  </ng-container>
  <ng-container *ngIf="inConfirmation">
    <div class="df jc">
      <mat-icon inline>chevron_right</mat-icon>
    </div>
    <div class="df">
      <a
        class="truncate"
        [routerLink]="
          inPortal
            | portalAddonUrlPipe: ['/minside', 'tilvalg', apartment.id, 'bekreftelse'] : [portalBaseUrl, 'confirmation']
        ">
        <T str="Confirmation"></T>
      </a>
    </div>
  </ng-container>
</div>
