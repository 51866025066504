import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILanguage } from '@transifex/native';
import { Profile } from 'projects/apex/src/app/models/profile';
import { Observable, from } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { t } from '../../../components/translate/translate.function';
import { TranslationService } from '../../../components/translate/translation.service';
import { snack, snackErr } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';
import ProfileService from './profile.service';

@Component({
  selector: 'apex-profile-form',
  templateUrl: 'profile-form.component.html',
})
export class ProfileFormComponent {
  @Input() hideSettings = false;

  profile$: Observable<Profile>;
  languages$: Observable<ILanguage[]>;

  newPassword = '';
  verifyPassword = '';

  private currentLocale = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private userService: UserService,
    private profileService: ProfileService,
  ) {
    this.profile$ = this.route.data.pipe(
      map((d) => d.profile as Profile),
      tap((profile) => {
        this.currentLocale = profile.locale;
      }),
      filter((p) => !!p),
      map((profile) => {
        if (!profile.UserPreferencesUnread) {
          profile.UserPreferencesUnread = {
            caseChanged: true,
            caseManagerMessage: true,
            clientMessage: true,
            contractorMessage: true,
            statusChangeAccepted: true,
            statusChangeCompleted: true,
            statusChangeDenied: true,
            statusChangeFinished: true,
            statusChangeReopened: true,
            systemMessage: true,
          };
        }

        return profile;
      }),
    );

    this.languages$ = from(this.translationService.getLanguages());
  }

  save(): void {
    this.route.data
      .pipe(
        map((d) => d.profile as Profile),
        mergeMap((profile) => this.userService.save(profile)),
        take(1),
        tap((user) => this.userService.setProfile(user as Profile)),
      )
      .subscribe({
        next: (profile) => {
          snack(t('Saved'));

          if (profile.locale !== this.currentLocale) {
            setTimeout(() => location.reload(), 250);
          }
        },
        error: (err) => snackErr(t('Failed to save'), err),
      });
  }

  changePassword(): void {
    this.userService
      .changePassword(this.newPassword, this.verifyPassword)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          snack(t('Your password has been changed'));

          return;
        }

        snack(t('You password could not be changed'));
      });
  }

  async clearStartpage(): Promise<void> {
    return this.profileService.deleteStartpage(this.profileService.userId);
  }

  get hasStartpage(): boolean {
    return this.profileService.hasStartpage;
  }

  get startpageUrl(): string {
    return this.userService?.profile?.startpage ?? '/case';
  }
}
