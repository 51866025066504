<div class="df jfe">
  <div class="df c afe ap">
    <h5 class="mat-h4 rmv">
      <T str="Total (excl. VAT)"></T>
      {{ totals.priceExcludingVAT | apexCurrency: 'NOK' : '' }}
    </h5>
    <h5 class="mat-h4">
      <T str="VAT"></T>
      {{ totals.priceVAT | apexCurrency: 'NOK' : '' }}
    </h5>
    <h3 class="mat-h3">
      <T str="Total (incl. VAT)"></T>
      {{ totals.priceIncludingVAT | apexCurrency: 'NOK' : '' }}
    </h3>
  </div>
</div>
