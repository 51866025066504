import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Apartment } from 'projects/apex/src/app/models/apartment';

@Component({
  selector: 'apex-client-addon-apartment-list',
  templateUrl: './apartment-list.component.html',
})
export class ClientAddonApartmentListComponent {
  @Input() apartments: Apartment[];
  @Output() clicked = new EventEmitter<Apartment>();

  emitClicked(apartment: Apartment): void {
    this.clicked.emit(apartment);
  }
}
