import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { Project } from 'projects/apex/src/app/models/project';
import { scrollWrap } from 'projects/apex/src/app/utils/functions';

@Component({
  selector: 'apex-client-addon-orders-page',
  templateUrl: './orders.component.html',
})
export class ClientAddonOrdersPageComponent implements OnInit {
  projects: Project[];

  inPortal = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
  ) {}

  ngOnInit(): void {
    scrollWrap(0, 0);

    const data = this.route.snapshot.data;

    this.projects = data.projects;
    this.inPortal = data.portal;
  }

  goToApartment(apartment: Apartment): void {
    if (this.inPortal) {
      void this.router.navigate(['/project', apartment.ProjectId, 'apartment', apartment.id, 'addon']);
    } else {
      void this.router.navigate(['minside', 'tilvalg', apartment.id]);
    }
  }

  back(): void {
    this.location.back();
  }
}
