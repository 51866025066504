<div mat-dialog-title>
  <h1>
    <T
      *ngIf="data.item.id"
      str="Edit Address"></T>
    <T
      *ngIf="!data.item.id"
      str="New Address"></T>
  </h1>
  <div class="position-absolute right-0">
    <button
      mat-icon-button
      (click)="dialogRef.close()"
      [matTooltip]="'Close' | translate">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
</div>

<div mat-dialog-content>
  <form #editForm="ngForm">
    <apex-address-autocomplete
      class="f1"
      [disabled]="manualInput"
      (addressChange)="fillResult($event)"></apex-address-autocomplete>

    <mat-checkbox
      [checked]="manualInput"
      (change)="manualInputChange($event)"
      [matTooltip]="'Check this if you want to input the address manually' | translate">
      <T str="Can't find in search"></T>
    </mat-checkbox>

    <div class="df gap-horizontal p-c t-c">
      <mat-form-field>
        <mat-label><T str="Address Line 1"></T></mat-label>
        <input
          matInput
          name="line1"
          type="text"
          [disabled]="!manualInput"
          [(ngModel)]="data.item.line1"
          (ngModelChange)="removePlaceId()" />
      </mat-form-field>

      <mat-form-field>
        <mat-label><T str="Address Line 2"></T></mat-label>
        <input
          matInput
          name="line2"
          type="text"
          [disabled]="!manualInput"
          [(ngModel)]="data.item.line2"
          (ngModelChange)="removePlaceId()" />
      </mat-form-field>
    </div>

    <div class="df gap-horizontal p-c">
      <mat-form-field>
        <mat-label><T str="Postal Code"></T></mat-label>
        <input
          matInput
          name="postal"
          type="text"
          [disabled]="!manualInput"
          [(ngModel)]="data.item.postal"
          (ngModelChange)="removePlaceId()"
          required />
      </mat-form-field>

      <mat-form-field>
        <mat-label><T str="Country Code"></T> </mat-label>
        <mat-select
          [(ngModel)]="data.item.countryCode"
          [disabled]="!manualInput"
          name="countries"
          (ngModelChange)="removePlaceId()"
          required>
          <mat-option
            *ngFor="let country of countries$ | async"
            [value]="country.alpha2">
            {{ country.country }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="df gap-horizontal p-c">
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select
          [(ngModel)]="data.item.type"
          name="type"
          required>
          <mat-option
            *ngFor="let type of translatedAddressModels | keyvalue"
            [value]="type.key">
            {{ type.value }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label><T str="Email"></T></mat-label>
        <input
          matInput
          name="email"
          type="email"
          [(ngModel)]="data.item.email" />
      </mat-form-field>
    </div>

    <div class="df gap-horizontal p-c">
      <mat-form-field>
        <mat-label><T str="Note"></T></mat-label>
        <input
          matInput
          name="note"
          type="text"
          [(ngModel)]="data.item.note" />
      </mat-form-field>

      <mat-form-field>
        <mat-label><T str="Reference"></T> </mat-label>
        <input
          matInput
          name="reference"
          type="text"
          [(ngModel)]="data.item.reference" />
      </mat-form-field>
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button
    type="button"
    mat-raised-button
    color="warn"
    [disabled]="!data?.item?.id"
    (click)="delete()">
    <T str="Delete"></T>
  </button>

  <button
    mat-raised-button
    color="primary"
    type="button"
    [disabled]="editForm.invalid"
    (click)="submit()">
    <T str="Save"></T>
  </button>
</div>
