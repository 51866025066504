import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ModelRoleModule } from '../../../components/model-role/model-role.module';
import { PageInfoModule } from '../../../components/page-info/page-info.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { DaterModule } from '../../../pipes/dater/dater.module';
import { IconPipeModule } from '../../../pipes/icon/icon-pipe.module';
import { CaseModule } from '../../case/case.module';

import { FileUsageModule } from '../../../components/file-usage/file-usage.module';
import { PortalAgreementComponent } from './view.component';

@NgModule({
  imports: [
    CommonModule,

    MatIconModule,
    MatTooltipModule,

    ModelRoleModule,
    TranslateModule,
    PageInfoModule,
    CaseModule,
    IconPipeModule,

    DaterModule,
    FileUsageModule,
  ],
  exports: [],
  declarations: [PortalAgreementComponent],
  providers: [],
})
export class PortalAgreementModule {}
