import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { find, includes } from 'lodash-es';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { Profile } from 'projects/apex/src/app/models/profile';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponsePortalApartmentDto, ResponsePortalFileUsageDto } from '../../../../../generated/apex-rest';
import { LinkCard } from '../../../../apex/src/app/components/link-cards/link-cards.types';
import { t } from '../../../../apex/src/app/components/translate/translate.function';
import { CaseParams } from '../../../../apex/src/app/models/case-params';

interface IRouteData {
  apartment: ResponsePortalApartmentDto;
  bannerImages: ResponsePortalFileUsageDto[];
  companyLogos: ResponsePortalFileUsageDto[];
  profile: Profile;
}

@Component({
  selector: 'portal-apartment-page',
  templateUrl: 'apartment-page.component.html',
})
export class ApartmentPageComponent implements OnInit, OnDestroy {
  apartment: ResponsePortalApartmentDto = null;
  bannerImages: ResponsePortalFileUsageDto[] = [];
  companyLogos: ResponsePortalFileUsageDto[] = [];
  profile: Profile = null;
  activeLink: LinkCard;

  links: LinkCard[] = [
    {
      icon: 'article',
      title: 'News',
      translated: t('News'),
      url: 'news',
    },
    {
      icon: 'list',
      title: 'Requests',
      translated: t('Requests'),
      url: 'case',
    },
    {
      icon: 'info',
      title: 'Info',
      translated: t('Info'),
      url: 'info',
    },

    {
      icon: 'description',
      title: 'Documents',
      translated: t('Documents'),
      url: 'document',
    },
    {
      icon: 'folder',
      title: 'MOM',
      translated: t('MOM'),
      url: '',
    },
    {
      icon: 'add_business',
      title: 'Addon',
      translated: t('Addon'),
      url: 'addon',
    },
  ];

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.data.pipe(map((data) => data as IRouteData)).subscribe({
        next: (data) => {
          this.apartment = data.apartment;
          this.profile = data.profile;
          this.bannerImages = data.bannerImages;
          this.companyLogos = data.companyLogos;

          const momLink = this.links.find((l) => l.title === 'MOM');

          if (momLink) {
            momLink.url = `mom/${this.apartment.ProjectId}/${this.apartment?.id}`;
          }
        },
      }),
    );

    this.subscription.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.setCurrentLink();
        }
      }),
    );

    this.setCurrentLink();
  }

  setCurrentLink(): void {
    const currentUrl = find(this.links, (l) => includes(this.router.url, l.url));

    if (!!currentUrl) {
      this.activeLink = currentUrl;
    } else {
      this.activeLink = this.links[0];
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getCaseParams(apartment: Apartment): CaseParams {
    return {
      Apartment: apartment.id,
      showOpen: true,
      showCompleted: true,
      showCaseManagers: true,
    };
  }
}
