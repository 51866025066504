/* tslint:disable */
/* eslint-disable */
/**
 * APEX REST API
 * Documentation for APEX REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AddonOrderStatus = {
    New: 'new',
    Denied: 'denied',
    Canceled: 'canceled',
    Accepted: 'accepted'
} as const;

export type AddonOrderStatus = typeof AddonOrderStatus[keyof typeof AddonOrderStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const AddressType = {
    Mail: 'mail',
    Invoice: 'invoice',
    Visit: 'visit'
} as const;

export type AddressType = typeof AddressType[keyof typeof AddressType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AllowedAddressModel = {
    Company: 'Company',
    Project: 'Project',
    Apartment: 'Apartment',
    User: 'User',
    Object: 'Object',
    CrmUser: 'CrmUser'
} as const;

export type AllowedAddressModel = typeof AllowedAddressModel[keyof typeof AllowedAddressModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const AllowedArticleModel = {
    Project: 'Project',
    Object: 'Object',
    Company: 'Company',
    ConstructionSite: 'ConstructionSite',
    Agreement: 'Agreement'
} as const;

export type AllowedArticleModel = typeof AllowedArticleModel[keyof typeof AllowedArticleModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const AllowedArticleUsageModel = {
    Project: 'Project',
    Object: 'Object',
    Company: 'Company',
    ProjectApartment: 'ProjectApartment'
} as const;

export type AllowedArticleUsageModel = typeof AllowedArticleUsageModel[keyof typeof AllowedArticleUsageModel];


/**
 * 
 * @export
 * @interface ApprovedResponseDto
 */
export interface ApprovedResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ApprovedResponseDto
     */
    'approved': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ArticlePageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt',
    Title: 'title'
} as const;

export type ArticlePageOptionsOrderBy = typeof ArticlePageOptionsOrderBy[keyof typeof ArticlePageOptionsOrderBy];


/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleUsageCompany = {
    TakeoverOwnershipNew: 'takeover-ownership-new',
    TakeoverOwnershipUsed: 'takeover-ownership-used',
    AddonConfirmation: 'addon-confirmation',
    AddonPdf: 'addon-pdf',
    AddonProjectText: 'addon-project-text',
    TakeoverTenancyPrivate: 'takeover-tenancy-private',
    TakeoverTenancyCommercial: 'takeover-tenancy-commercial',
    TakeoverTenancyPrivateReturn: 'takeover-tenancy-private-return',
    TakeoverTenancyCommercialReturn: 'takeover-tenancy-commercial-return'
} as const;

export type ArticleUsageCompany = typeof ArticleUsageCompany[keyof typeof ArticleUsageCompany];


/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleUsageObject = {
    TenancyPrivate: 'takeover-tenancy-private',
    TenancyCommercial: 'takeover-tenancy-commercial',
    TenancyPrivateReturn: 'takeover-tenancy-private-return',
    TenancyCommercialReturn: 'takeover-tenancy-commercial-return',
    OwnershipNew: 'takeover-ownership-new',
    OwnershipUsed: 'takeover-ownership-used'
} as const;

export type ArticleUsageObject = typeof ArticleUsageObject[keyof typeof ArticleUsageObject];


/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleUsageProject = {
    TakeoverOwnershipNew: 'takeover-ownership-new',
    TakeoverOwnershipUsed: 'takeover-ownership-used',
    AddonConfirmation: 'addon-confirmation',
    AddonPdf: 'addon-pdf',
    AddonProjectText: 'addon-project-text'
} as const;

export type ArticleUsageProject = typeof ArticleUsageProject[keyof typeof ArticleUsageProject];


/**
 * 
 * @export
 * @enum {string}
 */

export const ArticleUsageProjectApartment = {
    New: 'takeover-new',
    Used: 'takeover-used'
} as const;

export type ArticleUsageProjectApartment = typeof ArticleUsageProjectApartment[keyof typeof ArticleUsageProjectApartment];


/**
 * 
 * @export
 * @interface ArticleUsagesNamesDto
 */
export interface ArticleUsagesNamesDto {
    /**
     * 
     * @type {ArticleUsageProject}
     * @memberof ArticleUsagesNamesDto
     */
    'articleUsageProject': ArticleUsageProject;
    /**
     * 
     * @type {ArticleUsageObject}
     * @memberof ArticleUsagesNamesDto
     */
    'articleUsageObject': ArticleUsageObject;
    /**
     * 
     * @type {ArticleUsageCompany}
     * @memberof ArticleUsagesNamesDto
     */
    'articleUsageCompany': ArticleUsageCompany;
    /**
     * 
     * @type {ArticleUsageProjectApartment}
     * @memberof ArticleUsagesNamesDto
     */
    'articleUsageProjectApartment': ArticleUsageProjectApartment;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AuthDestination = {
    App: 'app',
    Inspection: 'inspection',
    Portal: 'portal',
    Print: 'print',
    Reporting: 'reporting',
    Requests: 'requests'
} as const;

export type AuthDestination = typeof AuthDestination[keyof typeof AuthDestination];


/**
 * 
 * @export
 * @interface CaseApartmentPrintDto
 */
export interface CaseApartmentPrintDto {
    /**
     * 
     * @type {number}
     * @memberof CaseApartmentPrintDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CaseApartmentPrintDto
     */
    'name': string | null;
    /**
     * 
     * @type {Array<ResponseUserCardDto>}
     * @memberof CaseApartmentPrintDto
     */
    'Clients'?: Array<ResponseUserCardDto>;
    /**
     * 
     * @type {Array<ResponseAddressDto>}
     * @memberof CaseApartmentPrintDto
     */
    'Addresses'?: Array<ResponseAddressDto>;
}
/**
 * 
 * @export
 * @interface CaseContractorPrintDto
 */
export interface CaseContractorPrintDto {
    /**
     * 
     * @type {CaseContractorStatus}
     * @memberof CaseContractorPrintDto
     */
    'status': CaseContractorStatus;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CaseContractorStatus = {
    New: 'new',
    Accepted: 'accepted',
    Declined: 'declined',
    Completed: 'completed'
} as const;

export type CaseContractorStatus = typeof CaseContractorStatus[keyof typeof CaseContractorStatus];


/**
 * 
 * @export
 * @interface CaseExportPrintDto
 */
export interface CaseExportPrintDto {
    /**
     * 
     * @type {Locale}
     * @memberof CaseExportPrintDto
     */
    'locale': Locale;
    /**
     * 
     * @type {Array<CasePrintDto>}
     * @memberof CaseExportPrintDto
     */
    'Cases'?: Array<CasePrintDto>;
    /**
     * 
     * @type {UserPrintDto}
     * @memberof CaseExportPrintDto
     */
    'Creator': UserPrintDto;
}


/**
 * 
 * @export
 * @interface CaseObjectPrintDto
 */
export interface CaseObjectPrintDto {
    /**
     * 
     * @type {string}
     * @memberof CaseObjectPrintDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CasePrintDto
 */
export interface CasePrintDto {
    /**
     * 
     * @type {string}
     * @memberof CasePrintDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof CasePrintDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CasePrintDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof CasePrintDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CasePrintDto
     */
    'deadlineAt': string;
    /**
     * 
     * @type {string}
     * @memberof CasePrintDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CasePrintDto
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof CasePrintDto
     */
    'location': string;
    /**
     * 
     * @type {FieldPrintDto}
     * @memberof CasePrintDto
     */
    'Field'?: FieldPrintDto;
    /**
     * 
     * @type {ResponseObjectFieldIdnDto}
     * @memberof CasePrintDto
     */
    'ObjectField'?: ResponseObjectFieldIdnDto;
    /**
     * 
     * @type {UserPrintDto}
     * @memberof CasePrintDto
     */
    'CaseManager'?: UserPrintDto;
    /**
     * 
     * @type {Array<ContractorPrintDto>}
     * @memberof CasePrintDto
     */
    'Contractors'?: Array<ContractorPrintDto>;
    /**
     * 
     * @type {ResponseUserCardDto}
     * @memberof CasePrintDto
     */
    'Client'?: ResponseUserCardDto;
    /**
     * 
     * @type {CaseObjectPrintDto}
     * @memberof CasePrintDto
     */
    'Object'?: CaseObjectPrintDto;
    /**
     * 
     * @type {CaseProjectPrintDto}
     * @memberof CasePrintDto
     */
    'Project'?: CaseProjectPrintDto;
    /**
     * 
     * @type {CaseApartmentPrintDto}
     * @memberof CasePrintDto
     */
    'Apartment'?: CaseApartmentPrintDto;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof CasePrintDto
     */
    'FileUsages'?: Array<ResponseFileUsageDto>;
    /**
     * 
     * @type {Array<ResponseMarkingDto>}
     * @memberof CasePrintDto
     */
    'Markings'?: Array<ResponseMarkingDto>;
    /**
     * 
     * @type {ChecklistPrintDto}
     * @memberof CasePrintDto
     */
    'Checklist'?: ChecklistPrintDto;
    /**
     * 
     * @type {Array<ResponseCaseLogDto>}
     * @memberof CasePrintDto
     */
    'CaseLogs'?: Array<ResponseCaseLogDto>;
    /**
     * 
     * @type {string}
     * @memberof CasePrintDto
     */
    'completedAt': string | null;
    /**
     * 
     * @type {PrintCreatorDto}
     * @memberof CasePrintDto
     */
    'Creator'?: PrintCreatorDto;
    /**
     * 
     * @type {TenancyPrintDto}
     * @memberof CasePrintDto
     */
    'Tenancy'?: TenancyPrintDto;
    /**
     * 
     * @type {ObjectOwnershipPrintDto}
     * @memberof CasePrintDto
     */
    'ObjectOwnership'?: ObjectOwnershipPrintDto;
}
/**
 * 
 * @export
 * @interface CaseProjectPrintDto
 */
export interface CaseProjectPrintDto {
    /**
     * 
     * @type {string}
     * @memberof CaseProjectPrintDto
     */
    'name': string | null;
    /**
     * 
     * @type {CompanyPrintDto}
     * @memberof CaseProjectPrintDto
     */
    'Company'?: CompanyPrintDto;
}
/**
 * 
 * @export
 * @interface ChecklistCaseManagerPrintDto
 */
export interface ChecklistCaseManagerPrintDto {
    /**
     * 
     * @type {string}
     * @memberof ChecklistCaseManagerPrintDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ChecklistCategoryPrintDto
 */
export interface ChecklistCategoryPrintDto {
    /**
     * 
     * @type {string}
     * @memberof ChecklistCategoryPrintDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ChecklistGroupPrintDto
 */
export interface ChecklistGroupPrintDto {
    /**
     * 
     * @type {number}
     * @memberof ChecklistGroupPrintDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistGroupPrintDto
     */
    'ParentId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ChecklistGroupPrintDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ChecklistGroupPrintDto
     */
    'description': string | null;
    /**
     * 
     * @type {Array<ChecklistPrintDto>}
     * @memberof ChecklistGroupPrintDto
     */
    'Checklists': Array<ChecklistPrintDto>;
    /**
     * 
     * @type {Array<ChecklistGroupPrintDto>}
     * @memberof ChecklistGroupPrintDto
     */
    'Children': Array<ChecklistGroupPrintDto>;
    /**
     * 
     * @type {ChecklistCaseManagerPrintDto}
     * @memberof ChecklistGroupPrintDto
     */
    'CaseManager': ChecklistCaseManagerPrintDto;
    /**
     * 
     * @type {CaseObjectPrintDto}
     * @memberof ChecklistGroupPrintDto
     */
    'Object'?: CaseObjectPrintDto;
    /**
     * 
     * @type {CaseProjectPrintDto}
     * @memberof ChecklistGroupPrintDto
     */
    'Project'?: CaseProjectPrintDto;
    /**
     * 
     * @type {ProjectApartmentPrintDto}
     * @memberof ChecklistGroupPrintDto
     */
    'Apartment'?: ProjectApartmentPrintDto;
    /**
     * 
     * @type {number}
     * @memberof ChecklistGroupPrintDto
     */
    'sortOrder': number;
}
/**
 * 
 * @export
 * @interface ChecklistItemCasePrintDto
 */
export interface ChecklistItemCasePrintDto {
    /**
     * 
     * @type {number}
     * @memberof ChecklistItemCasePrintDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChecklistItemCasePrintDto
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof ChecklistItemCasePrintDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ChecklistItemCasePrintDto
     */
    'completed': number;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ChecklistItemCasePrintDto
     */
    'FileUsages'?: Array<ResponseFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ChecklistItemPrintDto
 */
export interface ChecklistItemPrintDto {
    /**
     * 
     * @type {number}
     * @memberof ChecklistItemPrintDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistItemPrintDto
     */
    'SectionId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ChecklistItemPrintDto
     */
    'note': string;
    /**
     * 
     * @type {boolean}
     * @memberof ChecklistItemPrintDto
     */
    'notApplicable': boolean;
    /**
     * 
     * @type {ChecklistItemCasePrintDto}
     * @memberof ChecklistItemPrintDto
     */
    'Case': ChecklistItemCasePrintDto;
    /**
     * 
     * @type {Array<ChecklistItemCasePrintDto>}
     * @memberof ChecklistItemPrintDto
     */
    'Cases': Array<ChecklistItemCasePrintDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChecklistItemType = {
    Checkbox: 'checkbox',
    Text: 'text',
    Number: 'number',
    Date: 'date',
    Image: 'image',
    Select: 'select'
} as const;

export type ChecklistItemType = typeof ChecklistItemType[keyof typeof ChecklistItemType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ChecklistPageOptionsOrderBy = {
    Name: 'name',
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;

export type ChecklistPageOptionsOrderBy = typeof ChecklistPageOptionsOrderBy[keyof typeof ChecklistPageOptionsOrderBy];


/**
 * 
 * @export
 * @interface ChecklistPrintDto
 */
export interface ChecklistPrintDto {
    /**
     * 
     * @type {number}
     * @memberof ChecklistPrintDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ChecklistPrintDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ChecklistPrintDto
     */
    'description': string | null;
    /**
     * 
     * @type {Array<ChecklistItemPrintDto>}
     * @memberof ChecklistPrintDto
     */
    'Items': Array<ChecklistItemPrintDto>;
    /**
     * 
     * @type {Array<ChecklistSectionPrintDto>}
     * @memberof ChecklistPrintDto
     */
    'ChecklistSections': Array<ChecklistSectionPrintDto>;
    /**
     * 
     * @type {ChecklistCategoryPrintDto}
     * @memberof ChecklistPrintDto
     */
    'Category'?: ChecklistCategoryPrintDto;
    /**
     * 
     * @type {ChecklistCaseManagerPrintDto}
     * @memberof ChecklistPrintDto
     */
    'CaseManager'?: ChecklistCaseManagerPrintDto | null;
    /**
     * 
     * @type {CaseObjectPrintDto}
     * @memberof ChecklistPrintDto
     */
    'Object'?: CaseObjectPrintDto;
    /**
     * 
     * @type {CaseProjectPrintDto}
     * @memberof ChecklistPrintDto
     */
    'Project'?: CaseProjectPrintDto;
    /**
     * 
     * @type {ProjectApartmentPrintDto}
     * @memberof ChecklistPrintDto
     */
    'Apartment'?: ProjectApartmentPrintDto;
    /**
     * 
     * @type {number}
     * @memberof ChecklistPrintDto
     */
    'sortOrder': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChecklistSectionPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt',
    Name: 'name'
} as const;

export type ChecklistSectionPageOptionsOrderBy = typeof ChecklistSectionPageOptionsOrderBy[keyof typeof ChecklistSectionPageOptionsOrderBy];


/**
 * 
 * @export
 * @interface ChecklistSectionPrintDto
 */
export interface ChecklistSectionPrintDto {
    /**
     * 
     * @type {number}
     * @memberof ChecklistSectionPrintDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistSectionPrintDto
     */
    'ParentId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ChecklistSectionPrintDto
     */
    'name': string;
    /**
     * 
     * @type {Array<ChecklistItemPrintDto>}
     * @memberof ChecklistSectionPrintDto
     */
    'ChecklistItems': Array<ChecklistItemPrintDto>;
    /**
     * 
     * @type {Array<ChecklistSectionPrintDto>}
     * @memberof ChecklistSectionPrintDto
     */
    'ChecklistSections': Array<ChecklistSectionPrintDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ChecklistTemplatePageOptionsOrderBy = {
    Name: 'name',
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;

export type ChecklistTemplatePageOptionsOrderBy = typeof ChecklistTemplatePageOptionsOrderBy[keyof typeof ChecklistTemplatePageOptionsOrderBy];


/**
 * 
 * @export
 * @enum {string}
 */

export const ChecklistTemplateSectionPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt',
    Name: 'name'
} as const;

export type ChecklistTemplateSectionPageOptionsOrderBy = typeof ChecklistTemplateSectionPageOptionsOrderBy[keyof typeof ChecklistTemplateSectionPageOptionsOrderBy];


/**
 * 
 * @export
 * @interface CollectionResponseDto
 */
export interface CollectionResponseDto {
    /**
     * 
     * @type {object}
     * @memberof CollectionResponseDto
     */
    'data'?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectionResponseDto
     */
    'Collection': Array<string>;
}
/**
 * 
 * @export
 * @interface CompanyConsentCompanyConsentDataDto
 */
export interface CompanyConsentCompanyConsentDataDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyConsentCompanyConsentDataDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyConsentCompanyConsentDataDto
     */
    'mail'?: string;
}
/**
 * 
 * @export
 * @interface CompanyPrintDto
 */
export interface CompanyPrintDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyPrintDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ContractorPrintDto
 */
export interface ContractorPrintDto {
    /**
     * 
     * @type {string}
     * @memberof ContractorPrintDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ContractorPrintDto
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorPrintDto
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {CompanyPrintDto}
     * @memberof ContractorPrintDto
     */
    'Company'?: CompanyPrintDto;
    /**
     * 
     * @type {CaseContractorPrintDto}
     * @memberof ContractorPrintDto
     */
    'CaseContractor': CaseContractorPrintDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CpiCountryCode = {
    No: 'no'
} as const;

export type CpiCountryCode = typeof CpiCountryCode[keyof typeof CpiCountryCode];


/**
 * 
 * @export
 * @interface CreateAddressDto
 */
export interface CreateAddressDto {
    /**
     * 
     * @type {AddressType}
     * @memberof CreateAddressDto
     */
    'type': AddressType;
    /**
     * A two char ISO31661 Alpha2 code
     * @type {string}
     * @memberof CreateAddressDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'postal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateAddressDto
     */
    'placeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAddressDto
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateAddressDto
     */
    'longitude'?: number | null;
}


/**
 * 
 * @export
 * @interface CreateArticleDto
 */
export interface CreateArticleDto {
    /**
     * The Id of the Company creating the article
     * @type {number}
     * @memberof CreateArticleDto
     */
    'CompanyId': number;
    /**
     * List of FileKeys to associate with this Article
     * @type {Array<string>}
     * @memberof CreateArticleDto
     */
    'filesFileKeys': Array<string>;
    /**
     * List of FileKeys for the images of this Article
     * @type {Array<string>}
     * @memberof CreateArticleDto
     */
    'imagesFileKeys': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateArticleDto
     */
    'FolderId': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateArticleDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateArticleDto
     */
    'lead'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateArticleDto
     */
    'content': string;
    /**
     * 
     * @type {Array<CreateArticleModelDto>}
     * @memberof CreateArticleDto
     */
    'articleModels': Array<CreateArticleModelDto>;
}
/**
 * 
 * @export
 * @interface CreateArticleModelDto
 */
export interface CreateArticleModelDto {
    /**
     * 
     * @type {AllowedArticleModel}
     * @memberof CreateArticleModelDto
     */
    'model': AllowedArticleModel;
    /**
     * 
     * @type {string}
     * @memberof CreateArticleModelDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof CreateArticleModelDto
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateArticleModelDto
     */
    'modelId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateArticleModelDto
     */
    'public': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateArticleModelDto
     */
    'forClients'?: boolean;
}


/**
 * 
 * @export
 * @interface CreateArticleUsageDto
 */
export interface CreateArticleUsageDto {
    /**
     * 
     * @type {string}
     * @memberof CreateArticleUsageDto
     */
    'ArticleId': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateArticleUsageDto
     */
    'requireExplicitConsent': boolean;
}
/**
 * 
 * @export
 * @interface CreateChecklistTemplateDto
 */
export interface CreateChecklistTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateChecklistTemplateDto
     */
    'CategoryId': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateChecklistTemplateDto
     */
    'customerAccess': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateChecklistTemplateDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistTemplateDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface CreateChecklistTemplateItemDto
 */
export interface CreateChecklistTemplateItemDto {
    /**
     * 
     * @type {number}
     * @memberof CreateChecklistTemplateItemDto
     */
    'CategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateChecklistTemplateItemDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {object}
     * @memberof CreateChecklistTemplateItemDto
     */
    'data': object;
    /**
     * 
     * @type {number}
     * @memberof CreateChecklistTemplateItemDto
     */
    'fieldId': number;
    /**
     * 
     * @type {ChecklistItemType}
     * @memberof CreateChecklistTemplateItemDto
     */
    'checklistItemType'?: ChecklistItemType;
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistTemplateItemDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistTemplateItemDto
     */
    'description': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateChecklistTemplateItemDto
     */
    'selectOptions'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface CreateChecklistTemplateSectionDto
 */
export interface CreateChecklistTemplateSectionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistTemplateSectionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateChecklistTemplateSectionDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface CreateCompanyConsentCompanyContactDto
 */
export interface CreateCompanyConsentCompanyContactDto {
    /**
     * The id of the user who should be able to give consent
     * @type {number}
     * @memberof CreateCompanyConsentCompanyContactDto
     */
    'UserId': number;
}
/**
 * 
 * @export
 * @interface CreateCompanyConsentCompanyDto
 */
export interface CreateCompanyConsentCompanyDto {
    /**
     * The id of the company receiving the consent. If different from the request user´s CompanyId, it is checked to see if request user is admin for this Company and used in stead if it is
     * @type {number}
     * @memberof CreateCompanyConsentCompanyDto
     */
    'CompanyId': number;
    /**
     * If admins are allowed to consent
     * @type {boolean}
     * @memberof CreateCompanyConsentCompanyDto
     */
    'allowAdmins': boolean;
    /**
     * If all contacts must consent before the consent is considered approved
     * @type {boolean}
     * @memberof CreateCompanyConsentCompanyDto
     */
    'requireAllContacts': boolean;
    /**
     * The ids of the users who should be able to consent. Admins have implicit access
     * @type {Array<number>}
     * @memberof CreateCompanyConsentCompanyDto
     */
    'UserIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface CreateCompanyConsentDto
 */
export interface CreateCompanyConsentDto {
    /**
     * Name of the Consent to be created
     * @type {string}
     * @memberof CreateCompanyConsentDto
     */
    'name': string;
    /**
     * The ID of the Company asking for consent
     * @type {number}
     * @memberof CreateCompanyConsentDto
     */
    'CompanyId': number;
    /**
     * List of FileIds to associate with this Consent
     * @type {Array<number>}
     * @memberof CreateCompanyConsentDto
     */
    'FileIds': Array<number>;
    /**
     * List of ConsentCompanies to associate with this Consent
     * @type {Array<CreateCompanyConsentCompanyDto>}
     * @memberof CreateCompanyConsentDto
     */
    'CompanyConsentCompanies'?: Array<CreateCompanyConsentCompanyDto>;
}
/**
 * 
 * @export
 * @interface CreateConsentCompanyConsentDto
 */
export interface CreateConsentCompanyConsentDto {
    /**
     * If the User has approved the associated consent
     * @type {boolean}
     * @memberof CreateConsentCompanyConsentDto
     */
    'approved': boolean;
}
/**
 * 
 * @export
 * @interface CreateCrmUserDto
 */
export interface CreateCrmUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateCrmUserDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateCrmUserDto
     */
    'UserId'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'postal'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateCrmUserDto
     */
    'language'?: string;
}
/**
 * 
 * @export
 * @interface CreateFieldDto
 */
export interface CreateFieldDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFieldDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateFieldDto
     */
    'CompanyId': number;
    /**
     * A hexadecimal color with leading # like your browser likes
     * @type {string}
     * @memberof CreateFieldDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateFieldDto
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface CreateFileSigningPolicyDto
 */
export interface CreateFileSigningPolicyDto {
    /**
     * Intended filename
     * @type {string}
     * @memberof CreateFileSigningPolicyDto
     */
    'name': string;
    /**
     * The file\'s mimetype
     * @type {string}
     * @memberof CreateFileSigningPolicyDto
     */
    'type': string;
    /**
     * Size in bytes, a maximum of 4GB is allowed
     * @type {number}
     * @memberof CreateFileSigningPolicyDto
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface CreateFileUsageDto
 */
export interface CreateFileUsageDto {
    /**
     * 
     * @type {number}
     * @memberof CreateFileUsageDto
     */
    'FileId': number;
    /**
     * 
     * @type {number}
     * @memberof CreateFileUsageDto
     */
    'fileSort': number;
    /**
     * 
     * @type {string}
     * @memberof CreateFileUsageDto
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface CreateFolderDto
 */
export interface CreateFolderDto {
    /**
     * 
     * @type {string}
     * @memberof CreateFolderDto
     */
    'ParentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFolderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateFolderDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface CreateHelpUrlDto
 */
export interface CreateHelpUrlDto {
    /**
     * 
     * @type {string}
     * @memberof CreateHelpUrlDto
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHelpUrlDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CreateHelpUrlDto
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface CreateModelTraitDto
 */
export interface CreateModelTraitDto {
    /**
     * 
     * @type {string}
     * @memberof CreateModelTraitDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateModelTraitDto
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateModelTraitDto
     */
    'public': boolean;
}
/**
 * 
 * @export
 * @interface CreatePortalCaseLogDto
 */
export interface CreatePortalCaseLogDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePortalCaseLogDto
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePortalCaseLogDto
     */
    'forContractor'?: number;
}
/**
 * 
 * @export
 * @interface CreateProjectApartmentClientDto
 */
export interface CreateProjectApartmentClientDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectApartmentClientDto
     */
    'CrmUserId': string;
}
/**
 * 
 * @export
 * @interface CreateProjectApartmentTakeoverDto
 */
export interface CreateProjectApartmentTakeoverDto {
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverSignatureDto>}
     * @memberof CreateProjectApartmentTakeoverDto
     */
    'signatures': Array<ProjectApartmentTakeoverSignatureDto>;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataDto}
     * @memberof CreateProjectApartmentTakeoverDto
     */
    'data': ProjectApartmentTakeoverDataDto;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectApartmentTakeoverDto
     */
    'hash': string;
}
/**
 * 
 * @export
 * @interface CreateSidebarItemPinnedDto
 */
export interface CreateSidebarItemPinnedDto {
    /**
     * 
     * @type {SidebarModel}
     * @memberof CreateSidebarItemPinnedDto
     */
    'model': SidebarModel;
    /**
     * 
     * @type {string}
     * @memberof CreateSidebarItemPinnedDto
     */
    'modelId': string;
}


/**
 * 
 * @export
 * @interface CreateUnwantedIncidentDto
 */
export interface CreateUnwantedIncidentDto {
    /**
     * The id of the project associated with this unwanted incident
     * @type {number}
     * @memberof CreateUnwantedIncidentDto
     */
    'ProjectId'?: number | null;
    /**
     * The id of the object associated with this unwanted incident
     * @type {number}
     * @memberof CreateUnwantedIncidentDto
     */
    'ObjectId'?: number | null;
    /**
     * The name of the user reporting this unwanted incident
     * @type {string}
     * @memberof CreateUnwantedIncidentDto
     */
    'reporterName': string;
    /**
     * The email of the user reporting this unwanted incident
     * @type {string}
     * @memberof CreateUnwantedIncidentDto
     */
    'reporterEmail': string;
    /**
     * The mobile phone number of the user reporting this unwanted incident
     * @type {string}
     * @memberof CreateUnwantedIncidentDto
     */
    'reporterMobile': string;
    /**
     * The name of the company of the reporting user reporting this unwanted incident
     * @type {string}
     * @memberof CreateUnwantedIncidentDto
     */
    'reporterCompany': string;
    /**
     * The description of the unwanted incident
     * @type {string}
     * @memberof CreateUnwantedIncidentDto
     */
    'incident': string;
    /**
     * The immediate action taken when the unwanted incident occurred
     * @type {string}
     * @memberof CreateUnwantedIncidentDto
     */
    'immediateAction': string;
    /**
     * The consequence of the incident
     * @type {string}
     * @memberof CreateUnwantedIncidentDto
     */
    'consequence'?: string;
    /**
     * The types of unwanted incident. Can be more than one
     * @type {Array<string>}
     * @memberof CreateUnwantedIncidentDto
     */
    'types': Array<CreateUnwantedIncidentDtoTypesEnum>;
    /**
     * List of FileKeys to associate with this UnwantedIncident
     * @type {Array<string>}
     * @memberof CreateUnwantedIncidentDto
     */
    'fileKeys': Array<string>;
    /**
     * 
     * @type {Array<UnwantedIncidentMarkingDto>}
     * @memberof CreateUnwantedIncidentDto
     */
    'Markings'?: Array<UnwantedIncidentMarkingDto>;
}

export const CreateUnwantedIncidentDtoTypesEnum = {
    PersonInjury: 'person-injury',
    MaterialDamage: 'material-damage',
    Accident: 'accident',
    NearAccident: 'near-accident',
    Observation: 'observation'
} as const;

export type CreateUnwantedIncidentDtoTypesEnum = typeof CreateUnwantedIncidentDtoTypesEnum[keyof typeof CreateUnwantedIncidentDtoTypesEnum];

/**
 * 
 * @export
 * @interface CreateUnwantedIncidentProcessActionCaseDto
 */
export interface CreateUnwantedIncidentProcessActionCaseDto {
    /**
     * Name of the case to create
     * @type {string}
     * @memberof CreateUnwantedIncidentProcessActionCaseDto
     */
    'name': string;
    /**
     * A description for the case to create
     * @type {string}
     * @memberof CreateUnwantedIncidentProcessActionCaseDto
     */
    'description': string;
    /**
     * The id of the project associated with this unwanted incident
     * @type {number}
     * @memberof CreateUnwantedIncidentProcessActionCaseDto
     */
    'ProjectId'?: number;
    /**
     * The id of the object associated with this unwanted incident
     * @type {number}
     * @memberof CreateUnwantedIncidentProcessActionCaseDto
     */
    'ObjectId'?: number;
    /**
     * The deadline date in Date format
     * @type {string}
     * @memberof CreateUnwantedIncidentProcessActionCaseDto
     */
    'deadlineAt'?: string;
    /**
     * The id of the case category to attach to this case
     * @type {number}
     * @memberof CreateUnwantedIncidentProcessActionCaseDto
     */
    'CaseCategoryId': number;
    /**
     * The id of the User that should be CaseManager for the Action, defaults to CaseManager from Project
     * @type {number}
     * @memberof CreateUnwantedIncidentProcessActionCaseDto
     */
    'CaseManagerId'?: number;
}
/**
 * 
 * @export
 * @interface CreateUnwantedIncidentProcessActionDto
 */
export interface CreateUnwantedIncidentProcessActionDto {
    /**
     * 
     * @type {UnwantedIncidentProcessActionType}
     * @memberof CreateUnwantedIncidentProcessActionDto
     */
    'type': UnwantedIncidentProcessActionType;
    /**
     * 
     * @type {CreateUnwantedIncidentProcessActionCaseDto}
     * @memberof CreateUnwantedIncidentProcessActionDto
     */
    'Case': CreateUnwantedIncidentProcessActionCaseDto;
}


/**
 * 
 * @export
 * @interface CreateUnwantedIncidentProcessDto
 */
export interface CreateUnwantedIncidentProcessDto {
    /**
     * Notes on the unwanted incident
     * @type {string}
     * @memberof CreateUnwantedIncidentProcessDto
     */
    'notes': string;
    /**
     * 
     * @type {UnwantedIncidentProcessStatus}
     * @memberof CreateUnwantedIncidentProcessDto
     */
    'status': UnwantedIncidentProcessStatus;
}


/**
 * 
 * @export
 * @interface CreateUserStartPageDto
 */
export interface CreateUserStartPageDto {
    /**
     * The start page to set
     * @type {string}
     * @memberof CreateUserStartPageDto
     */
    'startPage': string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CrmUserPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;

export type CrmUserPageOptionsOrderBy = typeof CrmUserPageOptionsOrderBy[keyof typeof CrmUserPageOptionsOrderBy];


/**
 * 
 * @export
 * @interface CrmUserPrintDto
 */
export interface CrmUserPrintDto {
    /**
     * 
     * @type {string}
     * @memberof CrmUserPrintDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseUserCardDto}
     * @memberof CrmUserPrintDto
     */
    'User'?: ResponseUserCardDto;
}
/**
 * 
 * @export
 * @interface DeleteResponseDto
 */
export interface DeleteResponseDto {
    /**
     * 
     * @type {object}
     * @memberof DeleteResponseDto
     */
    'data'?: object;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteResponseDto
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface EntityResponseDto
 */
export interface EntityResponseDto {
    /**
     * 
     * @type {object}
     * @memberof EntityResponseDto
     */
    'data'?: object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FieldPageOptionsOrderBy = {
    Name: 'name',
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;

export type FieldPageOptionsOrderBy = typeof FieldPageOptionsOrderBy[keyof typeof FieldPageOptionsOrderBy];


/**
 * 
 * @export
 * @interface FieldPrintDto
 */
export interface FieldPrintDto {
    /**
     * 
     * @type {string}
     * @memberof FieldPrintDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FieldPrintDto
     */
    'color': string | null;
}
/**
 * 
 * @export
 * @interface FileUsageCountDto
 */
export interface FileUsageCountDto {
    /**
     * 
     * @type {number}
     * @memberof FileUsageCountDto
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FileUsageCountDtoEntity
 */
export interface FileUsageCountDtoEntity {
    /**
     * 
     * @type {FileUsageCountDto}
     * @memberof FileUsageCountDtoEntity
     */
    'Entity'?: FileUsageCountDto;
}
/**
 * 
 * @export
 * @interface FileUsageCountDtoEntityResponse
 */
export interface FileUsageCountDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof FileUsageCountDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {FileUsageCountDto}
     * @memberof FileUsageCountDtoEntityResponse
     */
    'Entity'?: FileUsageCountDto;
}
/**
 * 
 * @export
 * @interface FileUsageFileSortDto
 */
export interface FileUsageFileSortDto {
    /**
     * 
     * @type {number}
     * @memberof FileUsageFileSortDto
     */
    'FileId': number;
    /**
     * 
     * @type {number}
     * @memberof FileUsageFileSortDto
     */
    'fileSort': number;
}
/**
 * 
 * @export
 * @interface FileUsageFileSortDtoCollection
 */
export interface FileUsageFileSortDtoCollection {
    /**
     * 
     * @type {Array<FileUsageFileSortDto>}
     * @memberof FileUsageFileSortDtoCollection
     */
    'Collection'?: Array<FileUsageFileSortDto>;
}
/**
 * 
 * @export
 * @interface FileUsageFileSortDtoCollectionResponse
 */
export interface FileUsageFileSortDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof FileUsageFileSortDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<FileUsageFileSortDto>}
     * @memberof FileUsageFileSortDtoCollectionResponse
     */
    'Collection': Array<FileUsageFileSortDto>;
}
/**
 * 
 * @export
 * @interface FileUsageSortDto
 */
export interface FileUsageSortDto {
    /**
     * 
     * @type {Array<FileUsageFileSortDto>}
     * @memberof FileUsageSortDto
     */
    'sorts': Array<FileUsageFileSortDto>;
}
/**
 * 
 * @export
 * @interface FilterDto
 */
export interface FilterDto {
    /**
     * 
     * @type {FilterDto}
     * @memberof FilterDto
     */
    'Parent'?: FilterDto;
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof FilterDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface FilterDtoCollection
 */
export interface FilterDtoCollection {
    /**
     * 
     * @type {Array<FilterDto>}
     * @memberof FilterDtoCollection
     */
    'Collection'?: Array<FilterDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof FilterDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface FilterDtoPageResponse
 */
export interface FilterDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof FilterDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof FilterDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<FilterDto>}
     * @memberof FilterDtoPageResponse
     */
    'Collection': Array<FilterDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FolderItemPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt',
    Name: 'name',
    Type: 'type'
} as const;

export type FolderItemPageOptionsOrderBy = typeof FolderItemPageOptionsOrderBy[keyof typeof FolderItemPageOptionsOrderBy];


/**
 * 
 * @export
 * @enum {string}
 */

export const FolderItemType = {
    Folder: 'folder',
    File: 'file'
} as const;

export type FolderItemType = typeof FolderItemType[keyof typeof FolderItemType];


/**
 * 
 * @export
 * @enum {string}
 */

export const FolderModel = {
    Customer: 'customer',
    User: 'user',
    Project: 'project',
    Apartment: 'apartment',
    Object: 'object',
    Field: 'field',
    ObjectField: 'object-field',
    Mom: 'mom',
    Upload: 'upload',
    Hse: 'hse'
} as const;

export type FolderModel = typeof FolderModel[keyof typeof FolderModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const FolderPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt',
    Name: 'name'
} as const;

export type FolderPageOptionsOrderBy = typeof FolderPageOptionsOrderBy[keyof typeof FolderPageOptionsOrderBy];


/**
 * 
 * @export
 * @interface GeometryCrs
 */
export interface GeometryCrs {
    /**
     * 
     * @type {GeometryCrsProperties}
     * @memberof GeometryCrs
     */
    'properties': GeometryCrsProperties;
}
/**
 * 
 * @export
 * @interface GeometryCrsProperties
 */
export interface GeometryCrsProperties {
    /**
     * 
     * @type {string}
     * @memberof GeometryCrsProperties
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GeometryDto
 */
export interface GeometryDto {
    /**
     * 
     * @type {GeometryType}
     * @memberof GeometryDto
     */
    'type': GeometryType;
    /**
     * 
     * @type {GeometryDtoCoordinates}
     * @memberof GeometryDto
     */
    'coordinates': GeometryDtoCoordinates;
    /**
     * 
     * @type {GeometryCrs}
     * @memberof GeometryDto
     */
    'crs'?: GeometryCrs;
}


/**
 * @type GeometryDtoCoordinates
 * @export
 */
export type GeometryDtoCoordinates = Array<Array<number>> | Array<number>;

/**
 * 
 * @export
 * @enum {string}
 */

export const GeometryType = {
    Point: 'Point',
    LineString: 'LineString',
    Polygon: 'Polygon'
} as const;

export type GeometryType = typeof GeometryType[keyof typeof GeometryType];


/**
 * 
 * @export
 * @enum {string}
 */

export const InspectionInvolvedUserType = {
    CurrentUser: 'current-user',
    Casemanager: 'casemanager',
    Contractor: 'contractor',
    Client: 'client'
} as const;

export type InspectionInvolvedUserType = typeof InspectionInvolvedUserType[keyof typeof InspectionInvolvedUserType];


/**
 * 
 * @export
 * @interface InspectionPrintDataDto
 */
export interface InspectionPrintDataDto {
    /**
     * 
     * @type {string}
     * @memberof InspectionPrintDataDto
     */
    'inspectionDate': string;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof InspectionPrintDataDto
     */
    'customerLogos'?: Array<ResponseFileUsageDto>;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof InspectionPrintDataDto
     */
    'projectLogos'?: Array<ResponseFileUsageDto>;
    /**
     * 
     * @type {ResponseCaseCategoryDto}
     * @memberof InspectionPrintDataDto
     */
    'Category': ResponseCaseCategoryDto;
    /**
     * 
     * @type {ResponseFieldDto}
     * @memberof InspectionPrintDataDto
     */
    'Field'?: ResponseFieldDto;
    /**
     * 
     * @type {Array<ResponseTenancyIdnDto>}
     * @memberof InspectionPrintDataDto
     */
    'Tenancies': Array<ResponseTenancyIdnDto>;
    /**
     * 
     * @type {ProjectPrintDto}
     * @memberof InspectionPrintDataDto
     */
    'Project'?: ProjectPrintDto;
    /**
     * 
     * @type {ObjectPrintDto}
     * @memberof InspectionPrintDataDto
     */
    'Object'?: ObjectPrintDto;
    /**
     * 
     * @type {ResponseObjectFieldIdnDto}
     * @memberof InspectionPrintDataDto
     */
    'ObjectField'?: ResponseObjectFieldIdnDto;
    /**
     * 
     * @type {Array<InvolvedUserPrintDto>}
     * @memberof InspectionPrintDataDto
     */
    'Users': Array<InvolvedUserPrintDto>;
    /**
     * 
     * @type {UserPrintDto}
     * @memberof InspectionPrintDataDto
     */
    'CaseManager': UserPrintDto;
    /**
     * 
     * @type {Array<ChecklistPrintDto>}
     * @memberof InspectionPrintDataDto
     */
    'Checklists'?: Array<ChecklistPrintDto>;
    /**
     * 
     * @type {Array<ChecklistGroupPrintDto>}
     * @memberof InspectionPrintDataDto
     */
    'ChecklistGroups'?: Array<ChecklistGroupPrintDto>;
    /**
     * 
     * @type {Array<CasePrintDto>}
     * @memberof InspectionPrintDataDto
     */
    'Cases'?: Array<CasePrintDto>;
    /**
     * 
     * @type {Array<ResponseFileDto>}
     * @memberof InspectionPrintDataDto
     */
    'Files'?: Array<ResponseFileDto>;
    /**
     * 
     * @type {string}
     * @memberof InspectionPrintDataDto
     */
    'notes'?: string;
}
/**
 * 
 * @export
 * @interface InspectionPrintDto
 */
export interface InspectionPrintDto {
    /**
     * 
     * @type {Locale}
     * @memberof InspectionPrintDto
     */
    'locale': Locale;
    /**
     * 
     * @type {InspectionPrintDataDto}
     * @memberof InspectionPrintDto
     */
    'data': InspectionPrintDataDto;
    /**
     * 
     * @type {PrintCreatorDto}
     * @memberof InspectionPrintDto
     */
    'Creator': PrintCreatorDto;
    /**
     * 
     * @type {Array<InspectionPrintSignaturesDto>}
     * @memberof InspectionPrintDto
     */
    'signatures'?: Array<InspectionPrintSignaturesDto>;
}


/**
 * 
 * @export
 * @interface InspectionPrintSignaturesDto
 */
export interface InspectionPrintSignaturesDto {
    /**
     * 
     * @type {number}
     * @memberof InspectionPrintSignaturesDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InspectionPrintSignaturesDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InspectionPrintSignaturesDto
     */
    'signature': string;
}
/**
 * 
 * @export
 * @interface InvolvedUserPrintDto
 */
export interface InvolvedUserPrintDto {
    /**
     * 
     * @type {InspectionInvolvedUserType}
     * @memberof InvolvedUserPrintDto
     */
    'type'?: InspectionInvolvedUserType;
    /**
     * 
     * @type {string}
     * @memberof InvolvedUserPrintDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InvolvedUserPrintDto
     */
    'mail': string;
    /**
     * 
     * @type {boolean}
     * @memberof InvolvedUserPrintDto
     */
    'present'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvolvedUserPrintDto
     */
    'signer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvolvedUserPrintDto
     */
    'report'?: boolean;
}


/**
 * Language code for protocol
 * @export
 * @enum {string}
 */

export const Locale = {
    Nb: 'nb',
    En: 'en',
    Da: 'da',
    De: 'de',
    El: 'el',
    Et: 'et',
    Lt: 'lt',
    Lv: 'lv',
    Pl: 'pl',
    Pt: 'pt',
    Sq: 'sq',
    Sv: 'sv'
} as const;

export type Locale = typeof Locale[keyof typeof Locale];


/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {AuthDestination}
     * @memberof LoginDto
     */
    'destination'?: AuthDestination;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}


/**
 * 
 * @export
 * @interface MarkingDataDto
 */
export interface MarkingDataDto {
    /**
     * 
     * @type {number}
     * @memberof MarkingDataDto
     */
    'imageHeight'?: number;
    /**
     * 
     * @type {number}
     * @memberof MarkingDataDto
     */
    'imageWidth'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MarkingModel = {
    Case: 'case',
    AddonApartment: 'addon-apartment'
} as const;

export type MarkingModel = typeof MarkingModel[keyof typeof MarkingModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const MeterType = {
    ColdWater: 'cold-water',
    HotWater: 'hot-water',
    DistrictHeating: 'district-heating',
    Power: 'power',
    Gas: 'gas',
    Temperature: 'temperature',
    Moisture: 'moisture',
    SprinklerPressure: 'sprinkler-pressure',
    Other: 'other'
} as const;

export type MeterType = typeof MeterType[keyof typeof MeterType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MeteringPointStatus = {
    Active: 'Active',
    Inactive: 'Inactive',
    Invalid: 'Invalid'
} as const;

export type MeteringPointStatus = typeof MeteringPointStatus[keyof typeof MeteringPointStatus];


/**
 * The mime type of the file
 * @export
 * @enum {string}
 */

export const MimeType = {
    ApplicationPdf: 'application/pdf',
    AudioAac: 'audio/aac',
    ApplicationXAbiword: 'application/x-abiword',
    ApplicationXFreearc: 'application/x-freearc',
    ImageAvif: 'image/avif',
    VideoXMsvideo: 'video/x-msvideo',
    ApplicationVndAmazonEbook: 'application/vnd.amazon.ebook',
    ApplicationOctetStream: 'application/octet-stream',
    ImageBmp: 'image/bmp',
    ApplicationXBzip: 'application/x-bzip',
    ApplicationXBzip2: 'application/x-bzip2',
    ApplicationXCdf: 'application/x-cdf',
    ApplicationXCsh: 'application/x-csh',
    TextCss: 'text/css',
    TextCsv: 'text/csv',
    ApplicationMsword: 'application/msword',
    ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ApplicationVndMsFontobject: 'application/vnd.ms-fontobject',
    ApplicationEpubzip: 'application/epub+zip',
    ApplicationGzip: 'application/gzip',
    ImageGif: 'image/gif',
    TextHtml: 'text/html',
    ImageVndMicrosoftIcon: 'image/vnd.microsoft.icon',
    TextCalendar: 'text/calendar',
    ApplicationJavaArchive: 'application/java-archive',
    ImageJpeg: 'image/jpeg',
    TextJavascript: 'text/javascript',
    ApplicationJson: 'application/json',
    ApplicationLdjson: 'application/ld+json',
    AudioMidi: 'audio/midi',
    AudioXMidi: 'audio/x-midi',
    AudioMpeg: 'audio/mpeg',
    VideoMp4: 'video/mp4',
    VideoMpeg: 'video/mpeg',
    ApplicationVndAppleInstallerxml: 'application/vnd.apple.installer+xml',
    ApplicationVndOasisOpendocumentPresentation: 'application/vnd.oasis.opendocument.presentation',
    ApplicationVndOasisOpendocumentSpreadsheet: 'application/vnd.oasis.opendocument.spreadsheet',
    ApplicationVndOasisOpendocumentText: 'application/vnd.oasis.opendocument.text',
    AudioOgg: 'audio/ogg',
    VideoOgg: 'video/ogg',
    ApplicationOgg: 'application/ogg',
    AudioOpus: 'audio/opus',
    FontOtf: 'font/otf',
    ImagePng: 'image/png',
    ApplicationXHttpdPhp: 'application/x-httpd-php',
    ApplicationVndMsPowerpoint: 'application/vnd.ms-powerpoint',
    ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ApplicationVndRar: 'application/vnd.rar',
    ApplicationRtf: 'application/rtf',
    ApplicationXSh: 'application/x-sh',
    ImageSvgxml: 'image/svg+xml',
    ApplicationXShockwaveFlash: 'application/x-shockwave-flash',
    ApplicationXTar: 'application/x-tar',
    ImageTiff: 'image/tiff',
    VideoMp2t: 'video/mp2t',
    FontTtf: 'font/ttf',
    TextPlain: 'text/plain',
    ApplicationVndVisio: 'application/vnd.visio',
    AudioWav: 'audio/wav',
    AudioWebm: 'audio/webm',
    VideoWebm: 'video/webm',
    ImageWebp: 'image/webp',
    FontWoff: 'font/woff',
    FontWoff2: 'font/woff2',
    ApplicationXhtmlxml: 'application/xhtml+xml',
    ApplicationVndMsExcel: 'application/vnd.ms-excel',
    ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ApplicationXml: 'application/xml',
    ApplicationVndMozillaXulxml: 'application/vnd.mozilla.xul+xml',
    ApplicationZip: 'application/zip',
    Video3gppAudio3gpp: 'video/3gpp; audio/3gpp',
    Video3gpp2Audio3gpp2: 'video/3gpp2; audio/3gpp2',
    ApplicationX7zCompressed: 'application/x-7z-compressed'
} as const;

export type MimeType = typeof MimeType[keyof typeof MimeType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelTraitModel = {
    Project: 'Project',
    ProjectApartment: 'ProjectApartment',
    Addon: 'Addon',
    ContractorAddon: 'ContractorAddon',
    Object: 'Object'
} as const;

export type ModelTraitModel = typeof ModelTraitModel[keyof typeof ModelTraitModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelTraitPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt',
    Name: 'name',
    Value: 'value'
} as const;

export type ModelTraitPageOptionsOrderBy = typeof ModelTraitPageOptionsOrderBy[keyof typeof ModelTraitPageOptionsOrderBy];


/**
 * 
 * @export
 * @interface NumberIdnDto
 */
export interface NumberIdnDto {
    /**
     * 
     * @type {number}
     * @memberof NumberIdnDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NumberIdnDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ObjectInvolvedUser = {
    CaseManager: 'case-manager',
    Custodian: 'custodian',
    ProjectManager: 'project-manager',
    Contact: 'contact',
    Contractor: 'contractor',
    UnwantedIncidentManager: 'unwanted-incident-manager',
    AgreementInvolved: 'agreement-involved'
} as const;

export type ObjectInvolvedUser = typeof ObjectInvolvedUser[keyof typeof ObjectInvolvedUser];


/**
 * 
 * @export
 * @interface ObjectOwnershipPrintDto
 */
export interface ObjectOwnershipPrintDto {
    /**
     * 
     * @type {Array<ResponseUserCardDto>}
     * @memberof ObjectOwnershipPrintDto
     */
    'Users'?: Array<ResponseUserCardDto>;
    /**
     * 
     * @type {Array<CrmUserPrintDto>}
     * @memberof ObjectOwnershipPrintDto
     */
    'CRMUsers'?: Array<CrmUserPrintDto>;
}
/**
 * 
 * @export
 * @interface ObjectPrintDataDto
 */
export interface ObjectPrintDataDto {
    /**
     * 
     * @type {string}
     * @memberof ObjectPrintDataDto
     */
    'address'?: string;
}
/**
 * 
 * @export
 * @interface ObjectPrintDto
 */
export interface ObjectPrintDto {
    /**
     * 
     * @type {string}
     * @memberof ObjectPrintDto
     */
    'name': string;
    /**
     * 
     * @type {Array<ObjectPrintDto>}
     * @memberof ObjectPrintDto
     */
    'Children'?: Array<ObjectPrintDto>;
    /**
     * 
     * @type {ObjectPrintDataDto}
     * @memberof ObjectPrintDto
     */
    'data': ObjectPrintDataDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Order = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type Order = typeof Order[keyof typeof Order];


/**
 * 
 * @export
 * @interface PageMetaDto
 */
export interface PageMetaDto {
    /**
     * 
     * @type {number}
     * @memberof PageMetaDto
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof PageMetaDto
     */
    'limit': number;
    /**
     * 
     * @type {number}
     * @memberof PageMetaDto
     */
    'itemCount': number;
    /**
     * 
     * @type {number}
     * @memberof PageMetaDto
     */
    'pageCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof PageMetaDto
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageMetaDto
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PageOptionsDefaultOrderBy = {
    Id: 'id',
    Name: 'name',
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;

export type PageOptionsDefaultOrderBy = typeof PageOptionsDefaultOrderBy[keyof typeof PageOptionsDefaultOrderBy];


/**
 * 
 * @export
 * @interface PageResponseDto
 */
export interface PageResponseDto {
    /**
     * 
     * @type {object}
     * @memberof PageResponseDto
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof PageResponseDto
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof PageResponseDto
     */
    'Collection': Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PortalCaseLogPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;

export type PortalCaseLogPageOptionsOrderBy = typeof PortalCaseLogPageOptionsOrderBy[keyof typeof PortalCaseLogPageOptionsOrderBy];


/**
 * 
 * @export
 * @enum {string}
 */

export const PortalCasePageOptionsOrderBy = {
    Name: 'name',
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt',
    ArchivedAt: 'archivedAt'
} as const;

export type PortalCasePageOptionsOrderBy = typeof PortalCasePageOptionsOrderBy[keyof typeof PortalCasePageOptionsOrderBy];


/**
 * 
 * @export
 * @enum {string}
 */

export const PortalCasesPageOptionsOrderBy = {
    CreatedAt: 'createdAt'
} as const;

export type PortalCasesPageOptionsOrderBy = typeof PortalCasesPageOptionsOrderBy[keyof typeof PortalCasesPageOptionsOrderBy];


/**
 * 
 * @export
 * @interface PrintCreatorDto
 */
export interface PrintCreatorDto {
    /**
     * 
     * @type {string}
     * @memberof PrintCreatorDto
     */
    'name': string;
    /**
     * 
     * @type {CompanyPrintDto}
     * @memberof PrintCreatorDto
     */
    'Company': CompanyPrintDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectApartmentAgreementType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ProjectApartmentAgreementType = typeof ProjectApartmentAgreementType[keyof typeof ProjectApartmentAgreementType];


/**
 * 
 * @export
 * @interface ProjectApartmentPrintDto
 */
export interface ProjectApartmentPrintDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentPrintDto
     */
    'name': string | null;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverAddonCategoryDto
 */
export interface ProjectApartmentTakeoverAddonCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverAddonCategoryDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataAddonOrderAddonDto
 */
export interface ProjectApartmentTakeoverDataAddonOrderAddonDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataAddonOrderAddonDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataAddonOrderAddonDto
     */
    'description': string;
    /**
     * 
     * @type {ProjectApartmentTakeoverAddonCategoryDto}
     * @memberof ProjectApartmentTakeoverDataAddonOrderAddonDto
     */
    'AddonCategory'?: ProjectApartmentTakeoverAddonCategoryDto;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataAddonOrderCaseDto
 */
export interface ProjectApartmentTakeoverDataAddonOrderCaseDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataAddonOrderCaseDto
     */
    'completed': number;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataAddonOrderDto
 */
export interface ProjectApartmentTakeoverDataAddonOrderDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataAddonOrderDto
     */
    'CaseId'?: number | null;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataAddonOrderCaseDto}
     * @memberof ProjectApartmentTakeoverDataAddonOrderDto
     */
    'Case'?: ProjectApartmentTakeoverDataAddonOrderCaseDto;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataAddonOrderAddonDto}
     * @memberof ProjectApartmentTakeoverDataAddonOrderDto
     */
    'Addon': ProjectApartmentTakeoverDataAddonOrderAddonDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataAddonOrderDto
     */
    'ClientComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataAddonOrderDto
     */
    'ManagerComment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataAddonOrderDto
     */
    'quantity'?: number | null;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataApartmentDto
 */
export interface ProjectApartmentTakeoverDataApartmentDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'unitType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'municipalityLandNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'titleNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'sectionNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'parkingLot'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'storageRoomNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'agreementType': number;
    /**
     * 
     * @type {Array<ResponseModelTraitDto>}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'modelTraits'?: Array<ResponseModelTraitDto>;
    /**
     * 
     * @type {Array<ResponseAddressDto>}
     * @memberof ProjectApartmentTakeoverDataApartmentDto
     */
    'Addresses'?: Array<ResponseAddressDto>;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataCaseDto
 */
export interface ProjectApartmentTakeoverDataCaseDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataCaseDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataCaseDto
     */
    'CaseCategoryId': ProjectApartmentTakeoverDataCaseDtoCaseCategoryIdEnum;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataCaseDto
     */
    'name': string;
}

export const ProjectApartmentTakeoverDataCaseDtoCaseCategoryIdEnum = {
    NUMBER_3: 3,
    NUMBER_7: 7
} as const;

export type ProjectApartmentTakeoverDataCaseDtoCaseCategoryIdEnum = typeof ProjectApartmentTakeoverDataCaseDtoCaseCategoryIdEnum[keyof typeof ProjectApartmentTakeoverDataCaseDtoCaseCategoryIdEnum];

/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataChecklistDto
 */
export interface ProjectApartmentTakeoverDataChecklistDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataChecklistDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataChecklistDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataChecklistDto
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataChecklistDto
     */
    'cases'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataChecklistDto
     */
    'casesCompleted'?: number;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataChecklistGroupDto
 */
export interface ProjectApartmentTakeoverDataChecklistGroupDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataChecklistGroupDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataChecklistGroupDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataChecklistGroupDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataChecklistGroupDto
     */
    'cases'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataChecklistGroupDto
     */
    'casesCompleted'?: number;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataConsentDto
 */
export interface ProjectApartmentTakeoverDataConsentDto {
    /**
     * 
     * @type {ResponseArticleUsageDto}
     * @memberof ProjectApartmentTakeoverDataConsentDto
     */
    'articleUsage': ResponseArticleUsageDto;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataConsentDto
     */
    'readAndUnderstood'?: boolean;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataDocumentsDto
 */
export interface ProjectApartmentTakeoverDataDocumentsDto {
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataDocumentsFileUsageDto>}
     * @memberof ProjectApartmentTakeoverDataDocumentsDto
     */
    'certificatesOfCompletion'?: Array<ProjectApartmentTakeoverDataDocumentsFileUsageDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataDocumentsFileUsageDto>}
     * @memberof ProjectApartmentTakeoverDataDocumentsDto
     */
    'temporaryUsePermits'?: Array<ProjectApartmentTakeoverDataDocumentsFileUsageDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataDocumentsFileUsageDto>}
     * @memberof ProjectApartmentTakeoverDataDocumentsDto
     */
    'amendmentAgreements'?: Array<ProjectApartmentTakeoverDataDocumentsFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataDocumentsFileUsageDto
 */
export interface ProjectApartmentTakeoverDataDocumentsFileUsageDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataDocumentsFileUsageDto
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataDto
 */
export interface ProjectApartmentTakeoverDataDto {
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataInvolvedUserDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'involved': Array<ProjectApartmentTakeoverDataInvolvedUserDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataChecklistDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'checklists': Array<ProjectApartmentTakeoverDataChecklistDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataChecklistGroupDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'checklistGroups': Array<ProjectApartmentTakeoverDataChecklistGroupDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataCaseDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'cases': Array<ProjectApartmentTakeoverDataCaseDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataSupplierDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'suppliers'?: Array<ProjectApartmentTakeoverDataSupplierDto>;
    /**
     * 
     * @type {ProjectApartmentTakeoverSignatureType}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'signatureType': ProjectApartmentTakeoverSignatureType;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataFileDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'attachments': Array<ProjectApartmentTakeoverDataFileDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataMeterDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'meters': Array<ProjectApartmentTakeoverDataMeterDto>;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataDocumentsDto}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'documents': ProjectApartmentTakeoverDataDocumentsDto;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataConsentDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'consents': Array<ProjectApartmentTakeoverDataConsentDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataAddonOrderDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'addonOrders'?: Array<ProjectApartmentTakeoverDataAddonOrderDto>;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'caseManagerId': number;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDestinationDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'recipients': Array<ProjectApartmentTakeoverDestinationDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverSimultaneousSignerDto>}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'simultaneousSigners'?: Array<ProjectApartmentTakeoverSimultaneousSignerDto>;
    /**
     * 
     * @type {Locale}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'locale': Locale;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'note'?: string;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataProjectDto}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'project': ProjectApartmentTakeoverDataProjectDto;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataApartmentDto}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'apartment': ProjectApartmentTakeoverDataApartmentDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'remediation'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'receivedSettlement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'clientMoveIn': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'clientChoosesSupplierConsent'?: boolean;
    /**
     * 
     * @type {ProjectSellerDto}
     * @memberof ProjectApartmentTakeoverDataDto
     */
    'seller': ProjectSellerDto;
}


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataFileDto
 */
export interface ProjectApartmentTakeoverDataFileDto {
    /**
     * 
     * @type {MimeType}
     * @memberof ProjectApartmentTakeoverDataFileDto
     */
    'type': MimeType;
    /**
     * The file key of the file
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataFileDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataFileDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataFileDto
     */
    'size': number;
}


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataInvolvedUserDto
 */
export interface ProjectApartmentTakeoverDataInvolvedUserDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'city'?: string;
    /**
     * 
     * @type {ProjectApartmentTakeoverInvolvedUserRole}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'role'?: ProjectApartmentTakeoverInvolvedUserRole;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'mail': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'present': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'report': boolean;
    /**
     * 
     * @type {TakeoverSupplierOptionSubscriberContactPersonDto}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'contactPerson'?: TakeoverSupplierOptionSubscriberContactPersonDto;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'signer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataInvolvedUserDto
     */
    'proxy'?: boolean;
}


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataMeterDto
 */
export interface ProjectApartmentTakeoverDataMeterDto {
    /**
     * 
     * @type {MeterType}
     * @memberof ProjectApartmentTakeoverDataMeterDto
     */
    'type': MeterType;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataMeterDto
     */
    'number'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataMeterDto
     */
    'externalNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataMeterDto
     */
    'externalNumberStatus'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataMeterDto
     */
    'value'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataMeterDto
     */
    'send': boolean;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataInvolvedUserDto}
     * @memberof ProjectApartmentTakeoverDataMeterDto
     */
    'contact'?: ProjectApartmentTakeoverDataInvolvedUserDto;
}


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataPrintDto
 */
export interface ProjectApartmentTakeoverDataPrintDto {
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataInvolvedUserDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'involved': Array<ProjectApartmentTakeoverDataInvolvedUserDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDestinationDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'recipients': Array<ProjectApartmentTakeoverDestinationDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverSimultaneousSignerDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'simultaneousSigners'?: Array<ProjectApartmentTakeoverSimultaneousSignerDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataConsentDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'consents': Array<ProjectApartmentTakeoverDataConsentDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataAddonOrderDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'addonOrders'?: Array<ProjectApartmentTakeoverDataAddonOrderDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataMeterDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'meters': Array<ProjectApartmentTakeoverDataMeterDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDataSupplierPrintDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'suppliers'?: Array<ProjectApartmentTakeoverDataSupplierPrintDto>;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataProjectDto}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'project'?: ProjectApartmentTakeoverDataProjectDto;
    /**
     * 
     * @type {ResponseMainObjectSellerDto}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'seller'?: ResponseMainObjectSellerDto;
    /**
     * 
     * @type {ResponseProjectApartmentCardDto}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'apartment'?: ResponseProjectApartmentCardDto;
    /**
     * 
     * @type {Array<ChecklistPrintDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'checklists'?: Array<ChecklistPrintDto>;
    /**
     * 
     * @type {Array<ChecklistGroupPrintDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'checklistGroups'?: Array<ChecklistGroupPrintDto>;
    /**
     * 
     * @type {Array<CasePrintDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'cases'?: Array<CasePrintDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'receivedSettlement': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'clientMoveIn': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'remediation'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'note'?: string;
    /**
     * 
     * @type {Array<ResponseFileDto>}
     * @memberof ProjectApartmentTakeoverDataPrintDto
     */
    'attachments'?: Array<ResponseFileDto>;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataProjectDto
 */
export interface ProjectApartmentTakeoverDataProjectDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataProjectDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataProjectDto
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataProjectDto
     */
    'projectOwner': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataProjectDto
     */
    'projectOwnerCompanyIdentification': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataProjectDto
     */
    'meterWarmWaterMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataProjectDto
     */
    'meterColdWaterMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataProjectDto
     */
    'meterDistrictHeatingMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataProjectDto
     */
    'meterPowerLineMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataProjectDto
     */
    'meterGasMail'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataSupplierDto
 */
export interface ProjectApartmentTakeoverDataSupplierDto {
    /**
     * 
     * @type {ProjectApartmentTakeoverDataInvolvedUserDto}
     * @memberof ProjectApartmentTakeoverDataSupplierDto
     */
    'subscriber': ProjectApartmentTakeoverDataInvolvedUserDto;
    /**
     * 
     * @type {ProjectApartmentTakeoverSupplierOptionDto}
     * @memberof ProjectApartmentTakeoverDataSupplierDto
     */
    'option': ProjectApartmentTakeoverSupplierOptionDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataSupplierDto
     */
    'commonText': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataSupplierDto
     */
    'readAndUnderstood': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataSupplierDto
     */
    'readRightsOfWithdrawal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataSupplierDto
     */
    'readRightsOfWithdrawalForm'?: boolean;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataSupplierPrintDto
 */
export interface ProjectApartmentTakeoverDataSupplierPrintDto {
    /**
     * 
     * @type {ProjectApartmentTakeoverDataInvolvedUserDto}
     * @memberof ProjectApartmentTakeoverDataSupplierPrintDto
     */
    'subscriber': ProjectApartmentTakeoverDataInvolvedUserDto;
    /**
     * 
     * @type {ResponseTakeoverSupplierOptionDto}
     * @memberof ProjectApartmentTakeoverDataSupplierPrintDto
     */
    'option': ResponseTakeoverSupplierOptionDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataSupplierPrintDto
     */
    'commonText': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataSupplierPrintDto
     */
    'readAndUnderstood': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataSupplierPrintDto
     */
    'readRightsOfWithdrawal'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataSupplierPrintDto
     */
    'readRightsOfWithdrawalForm'?: boolean;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDataTransferMeterDto
 */
export interface ProjectApartmentTakeoverDataTransferMeterDto {
    /**
     * 
     * @type {MeterType}
     * @memberof ProjectApartmentTakeoverDataTransferMeterDto
     */
    'type': MeterType;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataTransferMeterDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataTransferMeterDto
     */
    'externalNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverDataTransferMeterDto
     */
    'value'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverDataTransferMeterDto
     */
    'send': boolean;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataInvolvedUserDto}
     * @memberof ProjectApartmentTakeoverDataTransferMeterDto
     */
    'contact': ProjectApartmentTakeoverDataInvolvedUserDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDataTransferMeterDto
     */
    'email': string;
}


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverDestinationDto
 */
export interface ProjectApartmentTakeoverDestinationDto {
    /**
     * 
     * @type {ProjectTakeoverDestinationContentTypeToken}
     * @memberof ProjectApartmentTakeoverDestinationDto
     */
    'contentType': ProjectTakeoverDestinationContentTypeToken;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDestinationDto
     */
    'recipientName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverDestinationDto
     */
    'recipientDestination'?: string;
}


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverInvolvedUserRecipientDto
 */
export interface ProjectApartmentTakeoverInvolvedUserRecipientDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'city'?: string;
    /**
     * 
     * @type {ProjectApartmentTakeoverInvolvedUserRole}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'role'?: ProjectApartmentTakeoverInvolvedUserRole;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'mail': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'present': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'report': boolean;
    /**
     * 
     * @type {TakeoverSupplierOptionSubscriberContactPersonDto}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'contactPerson'?: TakeoverSupplierOptionSubscriberContactPersonDto;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'signer'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverInvolvedUserRecipientDto
     */
    'proxy'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectApartmentTakeoverInvolvedUserRole = {
    Casemanager: 'casemanager',
    Contractor: 'contractor',
    Client: 'client',
    Owner: 'owner',
    PowerSubscriber: 'power-subscriber',
    GasSubscriber: 'gas-subscriber',
    MeterContact: 'meter-contact'
} as const;

export type ProjectApartmentTakeoverInvolvedUserRole = typeof ProjectApartmentTakeoverInvolvedUserRole[keyof typeof ProjectApartmentTakeoverInvolvedUserRole];


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverPrintDto
 */
export interface ProjectApartmentTakeoverPrintDto {
    /**
     * 
     * @type {Locale}
     * @memberof ProjectApartmentTakeoverPrintDto
     */
    'locale': Locale;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataPrintDto}
     * @memberof ProjectApartmentTakeoverPrintDto
     */
    'data': ProjectApartmentTakeoverDataPrintDto;
    /**
     * 
     * @type {PrintCreatorDto}
     * @memberof ProjectApartmentTakeoverPrintDto
     */
    'Creator': PrintCreatorDto;
    /**
     * 
     * @type {Array<InspectionPrintSignaturesDto>}
     * @memberof ProjectApartmentTakeoverPrintDto
     */
    'signatures': Array<InspectionPrintSignaturesDto>;
}


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverSignatureDto
 */
export interface ProjectApartmentTakeoverSignatureDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverSignatureDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverSignatureDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverSignatureDto
     */
    'signature': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectApartmentTakeoverSignatureType = {
    Handwritten: 'handwritten',
    Bankid: 'bankid'
} as const;

export type ProjectApartmentTakeoverSignatureType = typeof ProjectApartmentTakeoverSignatureType[keyof typeof ProjectApartmentTakeoverSignatureType];


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverSimultaneousSignerDto
 */
export interface ProjectApartmentTakeoverSimultaneousSignerDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverSimultaneousSignerDto
     */
    'id': number;
    /**
     * Reason why this user needs to sign
     * @type {string}
     * @memberof ProjectApartmentTakeoverSimultaneousSignerDto
     */
    'comment': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverSimultaneousSignerDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ProjectApartmentTakeoverSimultaneousSignerDto
     */
    'company': ResponseCompanyIDNDto;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverSimultaneousSignerDto
     */
    'mail': string;
}
/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverSupplierOptionDto
 */
export interface ProjectApartmentTakeoverSupplierOptionDto {
    /**
     * 
     * @type {number}
     * @memberof ProjectApartmentTakeoverSupplierOptionDto
     */
    'id': number;
    /**
     * 
     * @type {TakeoverSupplierOptionType}
     * @memberof ProjectApartmentTakeoverSupplierOptionDto
     */
    'type': TakeoverSupplierOptionType;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverSupplierOptionDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApartmentTakeoverSupplierOptionDto
     */
    'b2b': boolean;
}


/**
 * 
 * @export
 * @interface ProjectApartmentTakeoverTransferDataFileDto
 */
export interface ProjectApartmentTakeoverTransferDataFileDto {
    /**
     * 
     * @type {Locale}
     * @memberof ProjectApartmentTakeoverTransferDataFileDto
     */
    'locale': Locale;
    /**
     * 
     * @type {string}
     * @memberof ProjectApartmentTakeoverTransferDataFileDto
     */
    'createdAt': string;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataTransferMeterDto}
     * @memberof ProjectApartmentTakeoverTransferDataFileDto
     */
    'meter': ProjectApartmentTakeoverDataTransferMeterDto;
    /**
     * 
     * @type {ProjectApartmentTakeoverSignatureDto}
     * @memberof ProjectApartmentTakeoverTransferDataFileDto
     */
    'signature': ProjectApartmentTakeoverSignatureDto;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataProjectDto}
     * @memberof ProjectApartmentTakeoverTransferDataFileDto
     */
    'project': ProjectApartmentTakeoverDataProjectDto;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataApartmentDto}
     * @memberof ProjectApartmentTakeoverTransferDataFileDto
     */
    'apartment': ProjectApartmentTakeoverDataApartmentDto;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectInvolvedUser = {
    CaseManager: 'case-manager',
    Contractor: 'contractor',
    UnwantedIncidentManager: 'unwanted-incident-manager',
    ProjectManager: 'project-manager',
    AddonManager: 'addon-manager'
} as const;

export type ProjectInvolvedUser = typeof ProjectInvolvedUser[keyof typeof ProjectInvolvedUser];


/**
 * 
 * @export
 * @interface ProjectPrintDto
 */
export interface ProjectPrintDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectPrintDto
     */
    'name': string | null;
    /**
     * 
     * @type {ProjectApartmentPrintDto}
     * @memberof ProjectPrintDto
     */
    'Apartment': ProjectApartmentPrintDto;
}
/**
 * 
 * @export
 * @interface ProjectSellerDto
 */
export interface ProjectSellerDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectSellerDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSellerDto
     */
    'companyIdentification': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectTakeoverDestinationContentTypeToken = {
    Protocol: 'protocol',
    MeasurementsAndClientInfo: 'measurements-and-client-info'
} as const;

export type ProjectTakeoverDestinationContentTypeToken = typeof ProjectTakeoverDestinationContentTypeToken[keyof typeof ProjectTakeoverDestinationContentTypeToken];


/**
 * 
 * @export
 * @interface ResponseAddonCardDto
 */
export interface ResponseAddonCardDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonCardDto
     */
    'id': number;
    /**
     * 
     * @type {NumberIdnDto}
     * @memberof ResponseAddonCardDto
     */
    'AddonCategory': NumberIdnDto | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddonCardDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddonCardDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ResponseAddonOrderCardDto
 */
export interface ResponseAddonOrderCardDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonOrderCardDto
     */
    'id': number;
    /**
     * 
     * @type {ResponseAddonCardDto}
     * @memberof ResponseAddonOrderCardDto
     */
    'Addon'?: ResponseAddonCardDto;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonOrderCardDto
     */
    'CaseId'?: number | null;
    /**
     * 
     * @type {ProjectApartmentTakeoverDataAddonOrderCaseDto}
     * @memberof ResponseAddonOrderCardDto
     */
    'Case'?: ProjectApartmentTakeoverDataAddonOrderCaseDto;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddonOrderCardDto
     */
    'ClientComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddonOrderCardDto
     */
    'ManagerComment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonOrderCardDto
     */
    'quantity'?: number | null;
}
/**
 * 
 * @export
 * @interface ResponseAddonOrderStatusSummaryDto
 */
export interface ResponseAddonOrderStatusSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonOrderStatusSummaryDto
     */
    'new': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonOrderStatusSummaryDto
     */
    'accepted': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonOrderStatusSummaryDto
     */
    'denied': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonOrderStatusSummaryDto
     */
    'canceled': number;
}
/**
 * 
 * @export
 * @interface ResponseAddonSummaryDto
 */
export interface ResponseAddonSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonSummaryDto
     */
    'expired': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonSummaryDto
     */
    'active': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonSummaryDto
     */
    'contractorAddonsAwaitingApproval': number;
    /**
     * 
     * @type {ResponseAddonSummaryDtoOrdersByStatus}
     * @memberof ResponseAddonSummaryDto
     */
    'ordersByStatus': ResponseAddonSummaryDtoOrdersByStatus;
}
/**
 * 
 * @export
 * @interface ResponseAddonSummaryDtoOrdersByStatus
 */
export interface ResponseAddonSummaryDtoOrdersByStatus {
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonSummaryDtoOrdersByStatus
     */
    'new': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonSummaryDtoOrdersByStatus
     */
    'accepted': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonSummaryDtoOrdersByStatus
     */
    'denied': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddonSummaryDtoOrdersByStatus
     */
    'canceled': number;
}
/**
 * 
 * @export
 * @interface ResponseAddressDto
 */
export interface ResponseAddressDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'id': string;
    /**
     * 
     * @type {AllowedAddressModel}
     * @memberof ResponseAddressDto
     */
    'model': AllowedAddressModel;
    /**
     * 
     * @type {AddressType}
     * @memberof ResponseAddressDto
     */
    'type': AddressType;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddressDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'line1': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'postal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseAddressDto
     */
    'placeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddressDto
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseAddressDto
     */
    'longitude'?: number | null;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDtoCreator}
     * @memberof ResponseAddressDto
     */
    'Creator': ResponseUnwantedIncidentProcessDtoCreator | null;
}


/**
 * 
 * @export
 * @interface ResponseAddressDtoCollection
 */
export interface ResponseAddressDtoCollection {
    /**
     * 
     * @type {Array<ResponseAddressDto>}
     * @memberof ResponseAddressDtoCollection
     */
    'Collection'?: Array<ResponseAddressDto>;
}
/**
 * 
 * @export
 * @interface ResponseAddressDtoCollectionResponse
 */
export interface ResponseAddressDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseAddressDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseAddressDto>}
     * @memberof ResponseAddressDtoCollectionResponse
     */
    'Collection': Array<ResponseAddressDto>;
}
/**
 * 
 * @export
 * @interface ResponseAddressDtoEntity
 */
export interface ResponseAddressDtoEntity {
    /**
     * 
     * @type {ResponseAddressDto}
     * @memberof ResponseAddressDtoEntity
     */
    'Entity'?: ResponseAddressDto;
}
/**
 * 
 * @export
 * @interface ResponseAddressDtoEntityResponse
 */
export interface ResponseAddressDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseAddressDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseAddressDto}
     * @memberof ResponseAddressDtoEntityResponse
     */
    'Entity'?: ResponseAddressDto;
}
/**
 * 
 * @export
 * @interface ResponseAgreementSummaryDto
 */
export interface ResponseAgreementSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseAgreementSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseAgreementSummaryDto
     */
    'active': number;
}
/**
 * 
 * @export
 * @interface ResponseArticleDto
 */
export interface ResponseArticleDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseArticleDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseArticleDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleDto
     */
    'FolderId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleDto
     */
    'lead': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleDto
     */
    'content': string;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDtoCreator}
     * @memberof ResponseArticleDto
     */
    'Creator': ResponseUnwantedIncidentProcessDtoCreator | null;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseArticleDto
     */
    'Company': ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseArticleDtoCollection
 */
export interface ResponseArticleDtoCollection {
    /**
     * 
     * @type {Array<ResponseArticleDto>}
     * @memberof ResponseArticleDtoCollection
     */
    'Collection'?: Array<ResponseArticleDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseArticleDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseArticleDtoEntity
 */
export interface ResponseArticleDtoEntity {
    /**
     * 
     * @type {ResponseArticleDto}
     * @memberof ResponseArticleDtoEntity
     */
    'Entity'?: ResponseArticleDto;
}
/**
 * 
 * @export
 * @interface ResponseArticleDtoEntityResponse
 */
export interface ResponseArticleDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseArticleDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseArticleDto}
     * @memberof ResponseArticleDtoEntityResponse
     */
    'Entity'?: ResponseArticleDto;
}
/**
 * 
 * @export
 * @interface ResponseArticleDtoPageResponse
 */
export interface ResponseArticleDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseArticleDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseArticleDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseArticleDto>}
     * @memberof ResponseArticleDtoPageResponse
     */
    'Collection': Array<ResponseArticleDto>;
}
/**
 * 
 * @export
 * @interface ResponseArticleModelDto
 */
export interface ResponseArticleModelDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleModelDto
     */
    'id': string;
    /**
     * 
     * @type {AllowedArticleModel}
     * @memberof ResponseArticleModelDto
     */
    'model': AllowedArticleModel;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleModelDto
     */
    'ArticleId': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleModelDto
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleModelDto
     */
    'modelName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleModelDto
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleModelDto
     */
    'to'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseArticleModelDto
     */
    'public': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseArticleModelDto
     */
    'forClients': boolean;
}


/**
 * 
 * @export
 * @interface ResponseArticleModelDtoCollection
 */
export interface ResponseArticleModelDtoCollection {
    /**
     * 
     * @type {Array<ResponseArticleModelDto>}
     * @memberof ResponseArticleModelDtoCollection
     */
    'Collection'?: Array<ResponseArticleModelDto>;
}
/**
 * 
 * @export
 * @interface ResponseArticleModelDtoCollectionResponse
 */
export interface ResponseArticleModelDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseArticleModelDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseArticleModelDto>}
     * @memberof ResponseArticleModelDtoCollectionResponse
     */
    'Collection': Array<ResponseArticleModelDto>;
}
/**
 * 
 * @export
 * @interface ResponseArticleModelDtoEntity
 */
export interface ResponseArticleModelDtoEntity {
    /**
     * 
     * @type {ResponseArticleModelDto}
     * @memberof ResponseArticleModelDtoEntity
     */
    'Entity'?: ResponseArticleModelDto;
}
/**
 * 
 * @export
 * @interface ResponseArticleModelDtoEntityResponse
 */
export interface ResponseArticleModelDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseArticleModelDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseArticleModelDto}
     * @memberof ResponseArticleModelDtoEntityResponse
     */
    'Entity'?: ResponseArticleModelDto;
}
/**
 * 
 * @export
 * @interface ResponseArticleModelItemDto
 */
export interface ResponseArticleModelItemDto {
    /**
     * 
     * @type {ResponseIdNameAndParentDto}
     * @memberof ResponseArticleModelItemDto
     */
    'parent'?: ResponseIdNameAndParentDto | null;
    /**
     * 
     * @type {AllowedArticleModel}
     * @memberof ResponseArticleModelItemDto
     */
    'model': AllowedArticleModel;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleModelItemDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleModelItemDto
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface ResponseArticleModelItemDtoCollection
 */
export interface ResponseArticleModelItemDtoCollection {
    /**
     * 
     * @type {Array<ResponseArticleModelItemDto>}
     * @memberof ResponseArticleModelItemDtoCollection
     */
    'Collection'?: Array<ResponseArticleModelItemDto>;
}
/**
 * 
 * @export
 * @interface ResponseArticleModelItemDtoCollectionResponse
 */
export interface ResponseArticleModelItemDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseArticleModelItemDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseArticleModelItemDto>}
     * @memberof ResponseArticleModelItemDtoCollectionResponse
     */
    'Collection': Array<ResponseArticleModelItemDto>;
}
/**
 * 
 * @export
 * @interface ResponseArticleSummaryDto
 */
export interface ResponseArticleSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseArticleSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseArticleSummaryDto
     */
    'active': number;
}
/**
 * 
 * @export
 * @interface ResponseArticleUsageDto
 */
export interface ResponseArticleUsageDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleUsageDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleUsageDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleUsageDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleUsageDto
     */
    'id': string;
    /**
     * 
     * @type {AllowedArticleUsageModel}
     * @memberof ResponseArticleUsageDto
     */
    'model': AllowedArticleUsageModel;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleUsageDto
     */
    'modelId': string;
    /**
     * 
     * @type {ResponseArticleDto}
     * @memberof ResponseArticleUsageDto
     */
    'Article': ResponseArticleDto;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleUsageDto
     */
    'ArticleId': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseArticleUsageDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseArticleUsageDto
     */
    'requireExplicitConsent': boolean;
}


/**
 * 
 * @export
 * @interface ResponseArticleUsageDtoCollection
 */
export interface ResponseArticleUsageDtoCollection {
    /**
     * 
     * @type {Array<ResponseArticleUsageDto>}
     * @memberof ResponseArticleUsageDtoCollection
     */
    'Collection'?: Array<ResponseArticleUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponseArticleUsageDtoCollectionResponse
 */
export interface ResponseArticleUsageDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseArticleUsageDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseArticleUsageDto>}
     * @memberof ResponseArticleUsageDtoCollectionResponse
     */
    'Collection': Array<ResponseArticleUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponseArticleUsageDtoEntity
 */
export interface ResponseArticleUsageDtoEntity {
    /**
     * 
     * @type {ResponseArticleUsageDto}
     * @memberof ResponseArticleUsageDtoEntity
     */
    'Entity'?: ResponseArticleUsageDto;
}
/**
 * 
 * @export
 * @interface ResponseArticleUsageDtoEntityResponse
 */
export interface ResponseArticleUsageDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseArticleUsageDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseArticleUsageDto}
     * @memberof ResponseArticleUsageDtoEntityResponse
     */
    'Entity'?: ResponseArticleUsageDto;
}
/**
 * 
 * @export
 * @interface ResponseAssumeUserDto
 */
export interface ResponseAssumeUserDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseAssumeUserDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseAssumeUserDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseAssumeUserDto
     */
    'mail': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseAssumeUserDto
     */
    'Company'?: ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseAssumeUserDtoCollection
 */
export interface ResponseAssumeUserDtoCollection {
    /**
     * 
     * @type {Array<ResponseAssumeUserDto>}
     * @memberof ResponseAssumeUserDtoCollection
     */
    'Collection'?: Array<ResponseAssumeUserDto>;
}
/**
 * 
 * @export
 * @interface ResponseAssumeUserDtoCollectionResponse
 */
export interface ResponseAssumeUserDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseAssumeUserDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseAssumeUserDto>}
     * @memberof ResponseAssumeUserDtoCollectionResponse
     */
    'Collection': Array<ResponseAssumeUserDto>;
}
/**
 * 
 * @export
 * @interface ResponseCaseCategoryDto
 */
export interface ResponseCaseCategoryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategoryDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseCaseCategoryDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ResponseCaseCategorySummaryDto
 */
export interface ResponseCaseCategorySummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'preInspection': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'takeoverInspection': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'oneYearInspection': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'claim': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'request': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'completionInspection': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'inspectionCommonArea': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'task': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'projecting': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'other': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'hse': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'qa': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'addon': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'operations': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'maintenance': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'deviation': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'safetyInspection': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'trialOperations': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseCategorySummaryDto
     */
    'startUp': number;
}
/**
 * 
 * @export
 * @interface ResponseCaseContractorStatusSummaryDto
 */
export interface ResponseCaseContractorStatusSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseContractorStatusSummaryDto
     */
    'declined': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseContractorStatusSummaryDto
     */
    'new': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseContractorStatusSummaryDto
     */
    'accepted': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseContractorStatusSummaryDto
     */
    'completed': number;
}
/**
 * 
 * @export
 * @interface ResponseCaseLogDto
 */
export interface ResponseCaseLogDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseCaseLogDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCaseLogDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCaseLogDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseLogDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseLogDto
     */
    'CaseId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseLogDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseLogDto
     */
    'EmailId'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ResponseCaseLogDto
     */
    'translateVars': { [key: string]: string; };
    /**
     * 
     * @type {ResponseUserCardDto}
     * @memberof ResponseCaseLogDto
     */
    'Creator'?: ResponseUserCardDto;
    /**
     * 
     * @type {ResponsePortalCaseLogEmailDto}
     * @memberof ResponseCaseLogDto
     */
    'Email'?: ResponsePortalCaseLogEmailDto;
    /**
     * 
     * @type {string}
     * @memberof ResponseCaseLogDto
     */
    'message': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseLogDto
     */
    'status': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseLogDto
     */
    'kt': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseLogDto
     */
    'creatorRole': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseLogDto
     */
    'forClient': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseLogDto
     */
    'forContractor': number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCaseLogDto
     */
    'error': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCaseLogDto
     */
    'system': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCaseLogDto
     */
    'translate': boolean;
}
/**
 * 
 * @export
 * @interface ResponseCaseSummaryDto
 */
export interface ResponseCaseSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'open': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'completed': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'unread': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'overdue': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'withoutCaseManager': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'withoutContractor': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'withoutField': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'deadlineToday': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDto
     */
    'archived': number;
    /**
     * Object with field as key and amount of cases as value
     * @type {{ [key: string]: number; }}
     * @memberof ResponseCaseSummaryDto
     */
    'byField': { [key: string]: number; };
    /**
     * 
     * @type {ResponseCaseSummaryDtoStatusesByContractor}
     * @memberof ResponseCaseSummaryDto
     */
    'statusesByContractor': ResponseCaseSummaryDtoStatusesByContractor;
    /**
     * 
     * @type {ResponseCaseCategorySummaryDto}
     * @memberof ResponseCaseSummaryDto
     */
    'byCategory': ResponseCaseCategorySummaryDto;
}
/**
 * 
 * @export
 * @interface ResponseCaseSummaryDtoStatusesByContractor
 */
export interface ResponseCaseSummaryDtoStatusesByContractor {
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDtoStatusesByContractor
     */
    'declined': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDtoStatusesByContractor
     */
    'new': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDtoStatusesByContractor
     */
    'accepted': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCaseSummaryDtoStatusesByContractor
     */
    'completed': number;
}
/**
 * 
 * @export
 * @interface ResponseChecklistDto
 */
export interface ResponseChecklistDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'CategoryId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'ObjectId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'CaseManagerId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'ProjectId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'FieldId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'ObjectFieldId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'ApartmentId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'openItems': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistDto
     */
    'itemsCompletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistDto
     */
    'deviationsCompletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistDto
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'caseCount': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'openDeviations': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistDto
     */
    'closedDeviations': number;
}
/**
 * 
 * @export
 * @interface ResponseChecklistDtoCollection
 */
export interface ResponseChecklistDtoCollection {
    /**
     * 
     * @type {Array<ResponseChecklistDto>}
     * @memberof ResponseChecklistDtoCollection
     */
    'Collection'?: Array<ResponseChecklistDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistDtoEntity
 */
export interface ResponseChecklistDtoEntity {
    /**
     * 
     * @type {ResponseChecklistDto}
     * @memberof ResponseChecklistDtoEntity
     */
    'Entity'?: ResponseChecklistDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistDtoEntityResponse
 */
export interface ResponseChecklistDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseChecklistDto}
     * @memberof ResponseChecklistDtoEntityResponse
     */
    'Entity'?: ResponseChecklistDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistDtoPageResponse
 */
export interface ResponseChecklistDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseChecklistDto>}
     * @memberof ResponseChecklistDtoPageResponse
     */
    'Collection': Array<ResponseChecklistDto>;
}
/**
 * 
 * @export
 * @interface ResponseChecklistItemDto
 */
export interface ResponseChecklistItemDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistItemDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistItemDto
     */
    'ChecklistTemplateItemId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistItemDto
     */
    'SectionId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistItemDto
     */
    'CaseId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistItemDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistItemDto
     */
    'note': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseChecklistItemDto
     */
    'notApplicable': boolean;
    /**
     * 
     * @type {ChecklistItemType}
     * @memberof ResponseChecklistItemDto
     */
    'checklistItemType'?: ChecklistItemType;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistItemDto
     */
    'value': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseChecklistItemDto
     */
    'selectOptions'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface ResponseChecklistItemDtoCollection
 */
export interface ResponseChecklistItemDtoCollection {
    /**
     * 
     * @type {Array<ResponseChecklistItemDto>}
     * @memberof ResponseChecklistItemDtoCollection
     */
    'Collection'?: Array<ResponseChecklistItemDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistItemDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistItemDtoEntity
 */
export interface ResponseChecklistItemDtoEntity {
    /**
     * 
     * @type {ResponseChecklistItemDto}
     * @memberof ResponseChecklistItemDtoEntity
     */
    'Entity'?: ResponseChecklistItemDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistItemDtoEntityResponse
 */
export interface ResponseChecklistItemDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistItemDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseChecklistItemDto}
     * @memberof ResponseChecklistItemDtoEntityResponse
     */
    'Entity'?: ResponseChecklistItemDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistItemDtoPageResponse
 */
export interface ResponseChecklistItemDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistItemDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistItemDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseChecklistItemDto>}
     * @memberof ResponseChecklistItemDtoPageResponse
     */
    'Collection': Array<ResponseChecklistItemDto>;
}
/**
 * 
 * @export
 * @interface ResponseChecklistSectionDto
 */
export interface ResponseChecklistSectionDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistSectionDto
     */
    'CreatorId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistSectionDto
     */
    'ChecklistId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistSectionDto
     */
    'ChecklistTemplateSectionId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistSectionDto
     */
    'ParentId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistSectionDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistSectionDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistSectionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ResponseChecklistSectionDtoCollection
 */
export interface ResponseChecklistSectionDtoCollection {
    /**
     * 
     * @type {Array<ResponseChecklistSectionDto>}
     * @memberof ResponseChecklistSectionDtoCollection
     */
    'Collection'?: Array<ResponseChecklistSectionDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistSectionDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistSectionDtoEntity
 */
export interface ResponseChecklistSectionDtoEntity {
    /**
     * 
     * @type {ResponseChecklistSectionDto}
     * @memberof ResponseChecklistSectionDtoEntity
     */
    'Entity'?: ResponseChecklistSectionDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistSectionDtoEntityResponse
 */
export interface ResponseChecklistSectionDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistSectionDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseChecklistSectionDto}
     * @memberof ResponseChecklistSectionDtoEntityResponse
     */
    'Entity'?: ResponseChecklistSectionDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistSectionDtoPageResponse
 */
export interface ResponseChecklistSectionDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistSectionDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistSectionDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseChecklistSectionDto>}
     * @memberof ResponseChecklistSectionDtoPageResponse
     */
    'Collection': Array<ResponseChecklistSectionDto>;
}
/**
 * 
 * @export
 * @interface ResponseChecklistSummaryDto
 */
export interface ResponseChecklistSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistSummaryDto
     */
    'open': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistSummaryDto
     */
    'completed': number;
    /**
     * Object with field as key and count as value
     * @type {{ [key: string]: number; }}
     * @memberof ResponseChecklistSummaryDto
     */
    'fieldsCount': { [key: string]: number; };
    /**
     * 
     * @type {ResponseCaseCategorySummaryDto}
     * @memberof ResponseChecklistSummaryDto
     */
    'byCategory': ResponseCaseCategorySummaryDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateDto
 */
export interface ResponseChecklistTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistTemplateDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistTemplateDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistTemplateDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateDto
     */
    'creatorId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateDto
     */
    'CategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistTemplateDto
     */
    'description': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseChecklistTemplateDto
     */
    'customerAccess': boolean;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateDtoCollection
 */
export interface ResponseChecklistTemplateDtoCollection {
    /**
     * 
     * @type {Array<ResponseChecklistTemplateDto>}
     * @memberof ResponseChecklistTemplateDtoCollection
     */
    'Collection'?: Array<ResponseChecklistTemplateDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistTemplateDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateDtoEntity
 */
export interface ResponseChecklistTemplateDtoEntity {
    /**
     * 
     * @type {ResponseChecklistTemplateDto}
     * @memberof ResponseChecklistTemplateDtoEntity
     */
    'Entity'?: ResponseChecklistTemplateDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateDtoEntityResponse
 */
export interface ResponseChecklistTemplateDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistTemplateDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseChecklistTemplateDto}
     * @memberof ResponseChecklistTemplateDtoEntityResponse
     */
    'Entity'?: ResponseChecklistTemplateDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateDtoPageResponse
 */
export interface ResponseChecklistTemplateDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistTemplateDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistTemplateDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseChecklistTemplateDto>}
     * @memberof ResponseChecklistTemplateDtoPageResponse
     */
    'Collection': Array<ResponseChecklistTemplateDto>;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateItemDto
 */
export interface ResponseChecklistTemplateItemDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'ChecklistTemplateId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'SectionId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'CategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'fieldId': number;
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'data': object;
    /**
     * 
     * @type {ChecklistItemType}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'checklistItemType'?: ChecklistItemType;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'description': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseChecklistTemplateItemDto
     */
    'selectOptions'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface ResponseChecklistTemplateItemDtoCollection
 */
export interface ResponseChecklistTemplateItemDtoCollection {
    /**
     * 
     * @type {Array<ResponseChecklistTemplateItemDto>}
     * @memberof ResponseChecklistTemplateItemDtoCollection
     */
    'Collection'?: Array<ResponseChecklistTemplateItemDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistTemplateItemDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateItemDtoEntity
 */
export interface ResponseChecklistTemplateItemDtoEntity {
    /**
     * 
     * @type {ResponseChecklistTemplateItemDto}
     * @memberof ResponseChecklistTemplateItemDtoEntity
     */
    'Entity'?: ResponseChecklistTemplateItemDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateItemDtoEntityResponse
 */
export interface ResponseChecklistTemplateItemDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistTemplateItemDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseChecklistTemplateItemDto}
     * @memberof ResponseChecklistTemplateItemDtoEntityResponse
     */
    'Entity'?: ResponseChecklistTemplateItemDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateItemDtoPageResponse
 */
export interface ResponseChecklistTemplateItemDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistTemplateItemDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistTemplateItemDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseChecklistTemplateItemDto>}
     * @memberof ResponseChecklistTemplateItemDtoPageResponse
     */
    'Collection': Array<ResponseChecklistTemplateItemDto>;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateSectionDto
 */
export interface ResponseChecklistTemplateSectionDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateSectionDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateSectionDto
     */
    'ChecklistTemplateId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateSectionDto
     */
    'ParentId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistTemplateSectionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseChecklistTemplateSectionDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseChecklistTemplateSectionDto
     */
    'sortOrder': number;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateSectionDtoCollection
 */
export interface ResponseChecklistTemplateSectionDtoCollection {
    /**
     * 
     * @type {Array<ResponseChecklistTemplateSectionDto>}
     * @memberof ResponseChecklistTemplateSectionDtoCollection
     */
    'Collection'?: Array<ResponseChecklistTemplateSectionDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistTemplateSectionDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateSectionDtoEntity
 */
export interface ResponseChecklistTemplateSectionDtoEntity {
    /**
     * 
     * @type {ResponseChecklistTemplateSectionDto}
     * @memberof ResponseChecklistTemplateSectionDtoEntity
     */
    'Entity'?: ResponseChecklistTemplateSectionDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateSectionDtoEntityResponse
 */
export interface ResponseChecklistTemplateSectionDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistTemplateSectionDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseChecklistTemplateSectionDto}
     * @memberof ResponseChecklistTemplateSectionDtoEntityResponse
     */
    'Entity'?: ResponseChecklistTemplateSectionDto;
}
/**
 * 
 * @export
 * @interface ResponseChecklistTemplateSectionDtoPageResponse
 */
export interface ResponseChecklistTemplateSectionDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseChecklistTemplateSectionDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseChecklistTemplateSectionDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseChecklistTemplateSectionDto>}
     * @memberof ResponseChecklistTemplateSectionDtoPageResponse
     */
    'Collection': Array<ResponseChecklistTemplateSectionDto>;
}
/**
 * 
 * @export
 * @interface ResponseClonedChecklistTemplateDto
 */
export interface ResponseClonedChecklistTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseClonedChecklistTemplateDto
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface ResponseClonedChecklistTemplateDtoEntity
 */
export interface ResponseClonedChecklistTemplateDtoEntity {
    /**
     * 
     * @type {ResponseClonedChecklistTemplateDto}
     * @memberof ResponseClonedChecklistTemplateDtoEntity
     */
    'Entity'?: ResponseClonedChecklistTemplateDto;
}
/**
 * 
 * @export
 * @interface ResponseClonedChecklistTemplateDtoEntityResponse
 */
export interface ResponseClonedChecklistTemplateDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseClonedChecklistTemplateDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseClonedChecklistTemplateDto}
     * @memberof ResponseClonedChecklistTemplateDtoEntityResponse
     */
    'Entity'?: ResponseClonedChecklistTemplateDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCollectionItemDto
 */
export interface ResponseCompanyConsentCollectionItemDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCollectionItemDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseCompanyConsentCollectionItemDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCompanyConsentCollectionItemDto
     */
    'canEdit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCompanyConsentCollectionItemDto
     */
    'approved': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCollectionItemDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseCompanyConsentCollectionItemDto
     */
    'Company': ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCollectionItemDtoCollection
 */
export interface ResponseCompanyConsentCollectionItemDtoCollection {
    /**
     * 
     * @type {Array<ResponseCompanyConsentCollectionItemDto>}
     * @memberof ResponseCompanyConsentCollectionItemDtoCollection
     */
    'Collection'?: Array<ResponseCompanyConsentCollectionItemDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseCompanyConsentCollectionItemDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCollectionItemDtoPageResponse
 */
export interface ResponseCompanyConsentCollectionItemDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCompanyConsentCollectionItemDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseCompanyConsentCollectionItemDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseCompanyConsentCollectionItemDto>}
     * @memberof ResponseCompanyConsentCollectionItemDtoPageResponse
     */
    'Collection': Array<ResponseCompanyConsentCollectionItemDto>;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyConsentDto
 */
export interface ResponseCompanyConsentCompanyConsentDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyConsentDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyConsentDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyConsentDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCompanyConsentCompanyConsentDto
     */
    'approved': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyConsentDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseCompanyConsentCompanyConsentDto
     */
    'UserId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyConsentDto
     */
    'CompanyConsentCompanyId': string;
    /**
     * 
     * @type {ResponseUserCardDto}
     * @memberof ResponseCompanyConsentCompanyConsentDto
     */
    'User': ResponseUserCardDto;
    /**
     * 
     * @type {CompanyConsentCompanyConsentDataDto}
     * @memberof ResponseCompanyConsentCompanyConsentDto
     */
    'data': CompanyConsentCompanyConsentDataDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyConsentDtoEntity
 */
export interface ResponseCompanyConsentCompanyConsentDtoEntity {
    /**
     * 
     * @type {ResponseCompanyConsentCompanyConsentDto}
     * @memberof ResponseCompanyConsentCompanyConsentDtoEntity
     */
    'Entity'?: ResponseCompanyConsentCompanyConsentDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyConsentDtoEntityResponse
 */
export interface ResponseCompanyConsentCompanyConsentDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCompanyConsentCompanyConsentDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseCompanyConsentCompanyConsentDto}
     * @memberof ResponseCompanyConsentCompanyConsentDtoEntityResponse
     */
    'Entity'?: ResponseCompanyConsentCompanyConsentDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyContactDto
 */
export interface ResponseCompanyConsentCompanyContactDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyContactDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyContactDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyContactDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyContactDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseCompanyConsentCompanyContactDto
     */
    'UserId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyContactDto
     */
    'CompanyConsentCompanyId': string;
    /**
     * 
     * @type {ResponseUserCardDto}
     * @memberof ResponseCompanyConsentCompanyContactDto
     */
    'User': ResponseUserCardDto;
    /**
     * 
     * @type {ResponseCompanyConsentCompanyDto}
     * @memberof ResponseCompanyConsentCompanyContactDto
     */
    'CompanyConsentCompany'?: ResponseCompanyConsentCompanyDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyContactDtoCollection
 */
export interface ResponseCompanyConsentCompanyContactDtoCollection {
    /**
     * 
     * @type {Array<ResponseCompanyConsentCompanyContactDto>}
     * @memberof ResponseCompanyConsentCompanyContactDtoCollection
     */
    'Collection'?: Array<ResponseCompanyConsentCompanyContactDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseCompanyConsentCompanyContactDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyContactDtoEntity
 */
export interface ResponseCompanyConsentCompanyContactDtoEntity {
    /**
     * 
     * @type {ResponseCompanyConsentCompanyContactDto}
     * @memberof ResponseCompanyConsentCompanyContactDtoEntity
     */
    'Entity'?: ResponseCompanyConsentCompanyContactDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyContactDtoEntityResponse
 */
export interface ResponseCompanyConsentCompanyContactDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCompanyConsentCompanyContactDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseCompanyConsentCompanyContactDto}
     * @memberof ResponseCompanyConsentCompanyContactDtoEntityResponse
     */
    'Entity'?: ResponseCompanyConsentCompanyContactDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyContactDtoPageResponse
 */
export interface ResponseCompanyConsentCompanyContactDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCompanyConsentCompanyContactDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseCompanyConsentCompanyContactDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseCompanyConsentCompanyContactDto>}
     * @memberof ResponseCompanyConsentCompanyContactDtoPageResponse
     */
    'Collection': Array<ResponseCompanyConsentCompanyContactDto>;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyDto
 */
export interface ResponseCompanyConsentCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'CompanyConsentId': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'allowAdmins': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'requireAllContacts': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'canConsent': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'approved': boolean;
    /**
     * 
     * @type {Array<ResponseCompanyConsentCompanyContactDto>}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'CompanyConsentCompanyContacts': Array<ResponseCompanyConsentCompanyContactDto>;
    /**
     * 
     * @type {Array<ResponseCompanyConsentCompanyConsentDto>}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'CompanyConsentCompanyConsents': Array<ResponseCompanyConsentCompanyConsentDto>;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseCompanyConsentCompanyDto
     */
    'Company': ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyDtoCollection
 */
export interface ResponseCompanyConsentCompanyDtoCollection {
    /**
     * 
     * @type {Array<ResponseCompanyConsentCompanyDto>}
     * @memberof ResponseCompanyConsentCompanyDtoCollection
     */
    'Collection'?: Array<ResponseCompanyConsentCompanyDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseCompanyConsentCompanyDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyDtoEntity
 */
export interface ResponseCompanyConsentCompanyDtoEntity {
    /**
     * 
     * @type {ResponseCompanyConsentCompanyDto}
     * @memberof ResponseCompanyConsentCompanyDtoEntity
     */
    'Entity'?: ResponseCompanyConsentCompanyDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyDtoEntityResponse
 */
export interface ResponseCompanyConsentCompanyDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCompanyConsentCompanyDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseCompanyConsentCompanyDto}
     * @memberof ResponseCompanyConsentCompanyDtoEntityResponse
     */
    'Entity'?: ResponseCompanyConsentCompanyDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentCompanyDtoPageResponse
 */
export interface ResponseCompanyConsentCompanyDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCompanyConsentCompanyDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseCompanyConsentCompanyDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseCompanyConsentCompanyDto>}
     * @memberof ResponseCompanyConsentCompanyDtoPageResponse
     */
    'Collection': Array<ResponseCompanyConsentCompanyDto>;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentDto
 */
export interface ResponseCompanyConsentDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseCompanyConsentDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCompanyConsentDto
     */
    'canEdit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseCompanyConsentDto
     */
    'approved': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseCompanyConsentDto
     */
    'Company': ResponseCompanyIDNDto;
    /**
     * 
     * @type {Array<ResponseCompanyConsentCompanyDto>}
     * @memberof ResponseCompanyConsentDto
     */
    'CompanyConsentCompanies': Array<ResponseCompanyConsentCompanyDto>;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentDtoEntity
 */
export interface ResponseCompanyConsentDtoEntity {
    /**
     * 
     * @type {ResponseCompanyConsentDto}
     * @memberof ResponseCompanyConsentDtoEntity
     */
    'Entity'?: ResponseCompanyConsentDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentDtoEntityResponse
 */
export interface ResponseCompanyConsentDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCompanyConsentDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseCompanyConsentDto}
     * @memberof ResponseCompanyConsentDtoEntityResponse
     */
    'Entity'?: ResponseCompanyConsentDto;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentIDNDto
 */
export interface ResponseCompanyConsentIDNDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentIDNDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyConsentIDNDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseCompanyConsentIDNDto
     */
    'Company': ResponseCompanyIDNDto | null;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentIDNDtoCollection
 */
export interface ResponseCompanyConsentIDNDtoCollection {
    /**
     * 
     * @type {Array<ResponseCompanyConsentIDNDto>}
     * @memberof ResponseCompanyConsentIDNDtoCollection
     */
    'Collection'?: Array<ResponseCompanyConsentIDNDto>;
}
/**
 * 
 * @export
 * @interface ResponseCompanyConsentIDNDtoCollectionResponse
 */
export interface ResponseCompanyConsentIDNDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCompanyConsentIDNDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseCompanyConsentIDNDto>}
     * @memberof ResponseCompanyConsentIDNDtoCollectionResponse
     */
    'Collection': Array<ResponseCompanyConsentIDNDto>;
}
/**
 * 
 * @export
 * @interface ResponseCompanyIDNDto
 */
export interface ResponseCompanyIDNDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseCompanyIDNDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseCompanyIDNDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ResponseCompanyIDNDtoCollection
 */
export interface ResponseCompanyIDNDtoCollection {
    /**
     * 
     * @type {Array<ResponseCompanyIDNDto>}
     * @memberof ResponseCompanyIDNDtoCollection
     */
    'Collection'?: Array<ResponseCompanyIDNDto>;
}
/**
 * 
 * @export
 * @interface ResponseCompanyIDNDtoCollectionResponse
 */
export interface ResponseCompanyIDNDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCompanyIDNDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseCompanyIDNDto>}
     * @memberof ResponseCompanyIDNDtoCollectionResponse
     */
    'Collection': Array<ResponseCompanyIDNDto>;
}
/**
 * 
 * @export
 * @interface ResponseContractorSetStatusDto
 */
export interface ResponseContractorSetStatusDto {
    /**
     * 
     * @type {CaseContractorStatus}
     * @memberof ResponseContractorSetStatusDto
     */
    'status': CaseContractorStatus;
}


/**
 * 
 * @export
 * @interface ResponseContractorSetStatusDtoEntity
 */
export interface ResponseContractorSetStatusDtoEntity {
    /**
     * 
     * @type {ResponseContractorSetStatusDto}
     * @memberof ResponseContractorSetStatusDtoEntity
     */
    'Entity'?: ResponseContractorSetStatusDto;
}
/**
 * 
 * @export
 * @interface ResponseContractorSetStatusDtoEntityResponse
 */
export interface ResponseContractorSetStatusDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseContractorSetStatusDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseContractorSetStatusDto}
     * @memberof ResponseContractorSetStatusDtoEntityResponse
     */
    'Entity'?: ResponseContractorSetStatusDto;
}
/**
 * 
 * @export
 * @interface ResponseContractorStatusDto
 */
export interface ResponseContractorStatusDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseContractorStatusDto
     */
    'fieldId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseContractorStatusDto
     */
    'completion': boolean;
    /**
     * 
     * @type {number}
     * @memberof ResponseContractorStatusDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseContractorStatusDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ResponseContractorStatusReportDto
 */
export interface ResponseContractorStatusReportDto {
    /**
     * 
     * @type {ResponseContractorStatusReportMetaDto}
     * @memberof ResponseContractorStatusReportDto
     */
    'meta': ResponseContractorStatusReportMetaDto;
    /**
     * 
     * @type {Array<NumberIdnDto>}
     * @memberof ResponseContractorStatusReportDto
     */
    'fields': Array<NumberIdnDto>;
    /**
     * 
     * @type {Array<ResponseContractorStatusDto>}
     * @memberof ResponseContractorStatusReportDto
     */
    'contractors': Array<ResponseContractorStatusDto>;
    /**
     * 
     * @type {ResponseContractorStatusReportFoldersDto}
     * @memberof ResponseContractorStatusReportDto
     */
    'folders': ResponseContractorStatusReportFoldersDto;
    /**
     * 
     * @type {Array<ResponseUserIdnDto>}
     * @memberof ResponseContractorStatusReportDto
     */
    'fileCreators': Array<ResponseUserIdnDto>;
}
/**
 * 
 * @export
 * @interface ResponseContractorStatusReportDtoEntity
 */
export interface ResponseContractorStatusReportDtoEntity {
    /**
     * 
     * @type {ResponseContractorStatusReportDto}
     * @memberof ResponseContractorStatusReportDtoEntity
     */
    'Entity'?: ResponseContractorStatusReportDto;
}
/**
 * 
 * @export
 * @interface ResponseContractorStatusReportDtoEntityResponse
 */
export interface ResponseContractorStatusReportDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseContractorStatusReportDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseContractorStatusReportDto}
     * @memberof ResponseContractorStatusReportDtoEntityResponse
     */
    'Entity'?: ResponseContractorStatusReportDto;
}
/**
 * 
 * @export
 * @interface ResponseContractorStatusReportFolderDto
 */
export interface ResponseContractorStatusReportFolderDto {
    /**
     * 
     * @type {Array<ResponseFolderDto>}
     * @memberof ResponseContractorStatusReportFolderDto
     */
    'folders': Array<ResponseFolderDto>;
    /**
     * 
     * @type {Array<ResponseFileDto>}
     * @memberof ResponseContractorStatusReportFolderDto
     */
    'files': Array<ResponseFileDto>;
}
/**
 * 
 * @export
 * @interface ResponseContractorStatusReportFoldersDto
 */
export interface ResponseContractorStatusReportFoldersDto {
    /**
     * 
     * @type {ResponseContractorStatusReportFolderDto}
     * @memberof ResponseContractorStatusReportFoldersDto
     */
    'project': ResponseContractorStatusReportFolderDto;
    /**
     * 
     * @type {ResponseContractorStatusReportFolderDto}
     * @memberof ResponseContractorStatusReportFoldersDto
     */
    'apartments': ResponseContractorStatusReportFolderDto;
    /**
     * Map of ProjectApartmentId to array of folder ids pointing to field folders
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ResponseContractorStatusReportFoldersDto
     */
    'apartmentFieldFolderIdsMap': { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface ResponseContractorStatusReportMetaDto
 */
export interface ResponseContractorStatusReportMetaDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseContractorStatusReportMetaDto
     */
    'countApartments': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseContractorStatusReportMetaDto
     */
    'countFields': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseContractorStatusReportMetaDto
     */
    'countContractors': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseContractorStatusReportMetaDto
     */
    'countContractorsCompleted': number;
}
/**
 * 
 * @export
 * @interface ResponseCountryDto
 */
export interface ResponseCountryDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseCountryDto
     */
    'country': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCountryDto
     */
    'alpha2': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCountryDto
     */
    'alpha3': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCountryDto
     */
    'num3': string;
}
/**
 * 
 * @export
 * @interface ResponseCountryDtoCollection
 */
export interface ResponseCountryDtoCollection {
    /**
     * 
     * @type {Array<ResponseCountryDto>}
     * @memberof ResponseCountryDtoCollection
     */
    'Collection'?: Array<ResponseCountryDto>;
}
/**
 * 
 * @export
 * @interface ResponseCountryDtoCollectionResponse
 */
export interface ResponseCountryDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCountryDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseCountryDto>}
     * @memberof ResponseCountryDtoCollectionResponse
     */
    'Collection': Array<ResponseCountryDto>;
}
/**
 * 
 * @export
 * @interface ResponseCpiYearlyAdjustmentDto
 */
export interface ResponseCpiYearlyAdjustmentDto {
    /**
     * 
     * @type {ResponseYearMonthValueDto}
     * @memberof ResponseCpiYearlyAdjustmentDto
     */
    'from': ResponseYearMonthValueDto;
    /**
     * 
     * @type {ResponseYearMonthValueDto}
     * @memberof ResponseCpiYearlyAdjustmentDto
     */
    'to': ResponseYearMonthValueDto;
    /**
     * 
     * @type {CpiCountryCode}
     * @memberof ResponseCpiYearlyAdjustmentDto
     */
    'country': CpiCountryCode;
    /**
     * 
     * @type {number}
     * @memberof ResponseCpiYearlyAdjustmentDto
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCpiYearlyAdjustmentDto
     */
    'percent': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCpiYearlyAdjustmentDto
     */
    'stepsFromMonth': number;
}


/**
 * 
 * @export
 * @interface ResponseCpiYearlyAdjustmentDtoEntity
 */
export interface ResponseCpiYearlyAdjustmentDtoEntity {
    /**
     * 
     * @type {ResponseCpiYearlyAdjustmentDto}
     * @memberof ResponseCpiYearlyAdjustmentDtoEntity
     */
    'Entity'?: ResponseCpiYearlyAdjustmentDto;
}
/**
 * 
 * @export
 * @interface ResponseCpiYearlyAdjustmentDtoEntityResponse
 */
export interface ResponseCpiYearlyAdjustmentDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCpiYearlyAdjustmentDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseCpiYearlyAdjustmentDto}
     * @memberof ResponseCpiYearlyAdjustmentDtoEntityResponse
     */
    'Entity'?: ResponseCpiYearlyAdjustmentDto;
}
/**
 * 
 * @export
 * @interface ResponseCrmUserDto
 */
export interface ResponseCrmUserDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseCrmUserDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseCrmUserDto
     */
    'UserId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'postal'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseCrmUserDto
     */
    'language'?: string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseCrmUserDto
     */
    'Company': ResponseCompanyIDNDto;
    /**
     * 
     * @type {ResponseUserCardDto}
     * @memberof ResponseCrmUserDto
     */
    'User'?: ResponseUserCardDto;
    /**
     * 
     * @type {Array<ResponseUserInviteDto>}
     * @memberof ResponseCrmUserDto
     */
    'UserInvites'?: Array<ResponseUserInviteDto>;
}
/**
 * 
 * @export
 * @interface ResponseCrmUserDtoCollection
 */
export interface ResponseCrmUserDtoCollection {
    /**
     * 
     * @type {Array<ResponseCrmUserDto>}
     * @memberof ResponseCrmUserDtoCollection
     */
    'Collection'?: Array<ResponseCrmUserDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseCrmUserDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseCrmUserDtoEntity
 */
export interface ResponseCrmUserDtoEntity {
    /**
     * 
     * @type {ResponseCrmUserDto}
     * @memberof ResponseCrmUserDtoEntity
     */
    'Entity'?: ResponseCrmUserDto;
}
/**
 * 
 * @export
 * @interface ResponseCrmUserDtoEntityResponse
 */
export interface ResponseCrmUserDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCrmUserDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseCrmUserDto}
     * @memberof ResponseCrmUserDtoEntityResponse
     */
    'Entity'?: ResponseCrmUserDto;
}
/**
 * 
 * @export
 * @interface ResponseCrmUserDtoPageResponse
 */
export interface ResponseCrmUserDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseCrmUserDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseCrmUserDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseCrmUserDto>}
     * @memberof ResponseCrmUserDtoPageResponse
     */
    'Collection': Array<ResponseCrmUserDto>;
}
/**
 * 
 * @export
 * @interface ResponseFieldDto
 */
export interface ResponseFieldDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseFieldDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFieldDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFieldDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseFieldDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseFieldDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseFieldDto
     */
    'CompanyId': number;
    /**
     * A hexadecimal color with leading # like your browser likes
     * @type {string}
     * @memberof ResponseFieldDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseFieldDto
     */
    'status': number;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseFieldDto
     */
    'Company': ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseFieldDtoCollection
 */
export interface ResponseFieldDtoCollection {
    /**
     * 
     * @type {Array<ResponseFieldDto>}
     * @memberof ResponseFieldDtoCollection
     */
    'Collection'?: Array<ResponseFieldDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseFieldDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseFieldDtoEntity
 */
export interface ResponseFieldDtoEntity {
    /**
     * 
     * @type {ResponseFieldDto}
     * @memberof ResponseFieldDtoEntity
     */
    'Entity'?: ResponseFieldDto;
}
/**
 * 
 * @export
 * @interface ResponseFieldDtoEntityResponse
 */
export interface ResponseFieldDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFieldDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseFieldDto}
     * @memberof ResponseFieldDtoEntityResponse
     */
    'Entity'?: ResponseFieldDto;
}
/**
 * 
 * @export
 * @interface ResponseFieldDtoPageResponse
 */
export interface ResponseFieldDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFieldDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseFieldDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseFieldDto>}
     * @memberof ResponseFieldDtoPageResponse
     */
    'Collection': Array<ResponseFieldDto>;
}
/**
 * 
 * @export
 * @interface ResponseFieldIDNDto
 */
export interface ResponseFieldIDNDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseFieldIDNDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseFieldIDNDto
     */
    'name': string;
    /**
     * A hexadecimal color with leading # like your browser likes
     * @type {string}
     * @memberof ResponseFieldIDNDto
     */
    'color'?: string | null;
}
/**
 * 
 * @export
 * @interface ResponseFieldIDNDtoCollection
 */
export interface ResponseFieldIDNDtoCollection {
    /**
     * 
     * @type {Array<ResponseFieldIDNDto>}
     * @memberof ResponseFieldIDNDtoCollection
     */
    'Collection'?: Array<ResponseFieldIDNDto>;
}
/**
 * 
 * @export
 * @interface ResponseFieldIDNDtoCollectionResponse
 */
export interface ResponseFieldIDNDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFieldIDNDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseFieldIDNDto>}
     * @memberof ResponseFieldIDNDtoCollectionResponse
     */
    'Collection': Array<ResponseFieldIDNDto>;
}
/**
 * 
 * @export
 * @interface ResponseFieldSummaryDto
 */
export interface ResponseFieldSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseFieldSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseFieldSummaryDto
     */
    'hasContractor': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseFieldSummaryDto
     */
    'missingContractor': number;
}
/**
 * 
 * @export
 * @interface ResponseFileDto
 */
export interface ResponseFileDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseFileDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseFileDto
     */
    'id': number;
    /**
     * MIME type of the File
     * @type {string}
     * @memberof ResponseFileDto
     */
    'type': string | null;
    /**
     * File size in bytes
     * @type {number}
     * @memberof ResponseFileDto
     */
    'size': number;
    /**
     * S3 Path
     * @type {string}
     * @memberof ResponseFileDto
     */
    'path': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseFileDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDtoCreator}
     * @memberof ResponseFileDto
     */
    'Creator': ResponseUnwantedIncidentProcessDtoCreator | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseFileDto
     */
    'CompanyId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileDto
     */
    'FolderId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileDto
     */
    'description': string | null;
    /**
     * 
     * @type {ResponseFileSignedDto}
     * @memberof ResponseFileDto
     */
    'signed': ResponseFileSignedDto;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileDto
     */
    'eTag': string | null;
    /**
     * 
     * @type {ResponseFileDtoCompany}
     * @memberof ResponseFileDto
     */
    'Company': ResponseFileDtoCompany | null;
}
/**
 * 
 * @export
 * @interface ResponseFileDtoCollection
 */
export interface ResponseFileDtoCollection {
    /**
     * 
     * @type {Array<ResponseFileDto>}
     * @memberof ResponseFileDtoCollection
     */
    'Collection'?: Array<ResponseFileDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseFileDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseFileDtoCompany
 */
export interface ResponseFileDtoCompany {
    /**
     * 
     * @type {number}
     * @memberof ResponseFileDtoCompany
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileDtoCompany
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ResponseFileDtoPageResponse
 */
export interface ResponseFileDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFileDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseFileDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseFileDto>}
     * @memberof ResponseFileDtoPageResponse
     */
    'Collection': Array<ResponseFileDto>;
}
/**
 * 
 * @export
 * @interface ResponseFileSignedDto
 */
export interface ResponseFileSignedDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSignedDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSignedDto
     */
    'withDispositionUrl': string;
}
/**
 * 
 * @export
 * @interface ResponseFileSigningPolicyDto
 */
export interface ResponseFileSigningPolicyDto {
    /**
     * 
     * @type {SigningPolicyACL}
     * @memberof ResponseFileSigningPolicyDto
     */
    'acl': SigningPolicyACL;
    /**
     * 
     * @type {MimeType}
     * @memberof ResponseFileSigningPolicyDto
     */
    'mimeType'?: MimeType;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSigningPolicyDto
     */
    'awsAccessKeyId': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSigningPolicyDto
     */
    'bucket': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSigningPolicyDto
     */
    'bucketUrl': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSigningPolicyDto
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSigningPolicyDto
     */
    'policy': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSigningPolicyDto
     */
    'signature': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSigningPolicyDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileSigningPolicyDto
     */
    'xAmzMetaFilename': string;
}


/**
 * 
 * @export
 * @interface ResponseFileSigningPolicyDtoEntity
 */
export interface ResponseFileSigningPolicyDtoEntity {
    /**
     * 
     * @type {ResponseFileSigningPolicyDto}
     * @memberof ResponseFileSigningPolicyDtoEntity
     */
    'Entity'?: ResponseFileSigningPolicyDto;
}
/**
 * 
 * @export
 * @interface ResponseFileSigningPolicyDtoEntityResponse
 */
export interface ResponseFileSigningPolicyDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFileSigningPolicyDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseFileSigningPolicyDto}
     * @memberof ResponseFileSigningPolicyDtoEntityResponse
     */
    'Entity'?: ResponseFileSigningPolicyDto;
}
/**
 * 
 * @export
 * @interface ResponseFileUsageDto
 */
export interface ResponseFileUsageDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseFileUsageDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileUsageDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileUsageDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseFileUsageDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseFileUsageDto
     */
    'UserId'?: number | null;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDtoCreator}
     * @memberof ResponseFileUsageDto
     */
    'User': ResponseUnwantedIncidentProcessDtoCreator | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseFileUsageDto
     */
    'FileId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileUsageDto
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseFileUsageDto
     */
    'fileSort': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileUsageDto
     */
    'self': ResponseFileUsageDtoSelfEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileUsageDto
     */
    'selfId': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFileUsageDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseFileDto}
     * @memberof ResponseFileUsageDto
     */
    'File': ResponseFileDto;
}

export const ResponseFileUsageDtoSelfEnum = {
    Apex: 'apex',
    Customer: 'customer',
    User: 'user',
    Consent: 'consent',
    Massmailing: 'massmailing',
    Addon: 'addon',
    AddonCart: 'addon-cart',
    AddonCategoryProject: 'addon-category-project',
    AddonOrder: 'addon-order',
    AddonProject: 'addon-project',
    ContractorAddon: 'contractor-addon',
    Object: 'object',
    Agreement: 'agreement',
    Tenancy: 'tenancy',
    Project: 'project',
    Apartment: 'apartment',
    ProjectApartmentTakeover: 'project-apartment-takeover',
    Takeover: 'takeover',
    Inspection: 'inspection',
    Case: 'case',
    RepeatableCase: 'repeatable-case',
    Request: 'request',
    Checklist: 'checklist',
    ChecklistItem: 'checklist-item',
    ChecklistTemplate: 'checklist-template',
    ChecklistTemplateItem: 'checklist-template-item',
    UnwantedIncident: 'unwanted-incident',
    Signing: 'signing',
    Article: 'article',
    TakeoverSupplierOption: 'takeover-supplier-option'
} as const;

export type ResponseFileUsageDtoSelfEnum = typeof ResponseFileUsageDtoSelfEnum[keyof typeof ResponseFileUsageDtoSelfEnum];

/**
 * 
 * @export
 * @interface ResponseFileUsageDtoCollection
 */
export interface ResponseFileUsageDtoCollection {
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ResponseFileUsageDtoCollection
     */
    'Collection'?: Array<ResponseFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponseFileUsageDtoCollectionResponse
 */
export interface ResponseFileUsageDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFileUsageDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ResponseFileUsageDtoCollectionResponse
     */
    'Collection': Array<ResponseFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponseFileUsageDtoEntity
 */
export interface ResponseFileUsageDtoEntity {
    /**
     * 
     * @type {ResponseFileUsageDto}
     * @memberof ResponseFileUsageDtoEntity
     */
    'Entity'?: ResponseFileUsageDto;
}
/**
 * 
 * @export
 * @interface ResponseFileUsageDtoEntityResponse
 */
export interface ResponseFileUsageDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFileUsageDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseFileUsageDto}
     * @memberof ResponseFileUsageDtoEntityResponse
     */
    'Entity'?: ResponseFileUsageDto;
}
/**
 * 
 * @export
 * @interface ResponseFolderAccessMetaDto
 */
export interface ResponseFolderAccessMetaDto {
    /**
     * 
     * @type {boolean}
     * @memberof ResponseFolderAccessMetaDto
     */
    'view': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseFolderAccessMetaDto
     */
    'collaborator': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseFolderAccessMetaDto
     */
    'edit': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseFolderAccessMetaDto
     */
    'owner': boolean;
}
/**
 * 
 * @export
 * @interface ResponseFolderDto
 */
export interface ResponseFolderDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderDto
     */
    'id': string;
    /**
     * 
     * @type {FolderModel}
     * @memberof ResponseFolderDto
     */
    'modelName': FolderModel;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderDto
     */
    'description': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderDto
     */
    'ParentId': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseFolderDto
     */
    'modelId': number | null;
    /**
     * 
     * @type {ResponseFolderAccessMetaDto}
     * @memberof ResponseFolderDto
     */
    'accessMeta'?: ResponseFolderAccessMetaDto;
    /**
     * 
     * @type {Array<ResponseFolderIdnDto>}
     * @memberof ResponseFolderDto
     */
    'Parents'?: Array<ResponseFolderIdnDto>;
}


/**
 * 
 * @export
 * @interface ResponseFolderDtoCollection
 */
export interface ResponseFolderDtoCollection {
    /**
     * 
     * @type {Array<ResponseFolderDto>}
     * @memberof ResponseFolderDtoCollection
     */
    'Collection'?: Array<ResponseFolderDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseFolderDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseFolderDtoCollection1
 */
export interface ResponseFolderDtoCollection1 {
    /**
     * 
     * @type {Array<ResponseFolderDto>}
     * @memberof ResponseFolderDtoCollection1
     */
    'Collection'?: Array<ResponseFolderDto>;
}
/**
 * 
 * @export
 * @interface ResponseFolderDtoCollectionResponse
 */
export interface ResponseFolderDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFolderDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseFolderDto>}
     * @memberof ResponseFolderDtoCollectionResponse
     */
    'Collection': Array<ResponseFolderDto>;
}
/**
 * 
 * @export
 * @interface ResponseFolderDtoEntity
 */
export interface ResponseFolderDtoEntity {
    /**
     * 
     * @type {ResponseFolderDto}
     * @memberof ResponseFolderDtoEntity
     */
    'Entity'?: ResponseFolderDto;
}
/**
 * 
 * @export
 * @interface ResponseFolderDtoEntityResponse
 */
export interface ResponseFolderDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFolderDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseFolderDto}
     * @memberof ResponseFolderDtoEntityResponse
     */
    'Entity'?: ResponseFolderDto;
}
/**
 * 
 * @export
 * @interface ResponseFolderDtoPageResponse
 */
export interface ResponseFolderDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFolderDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseFolderDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseFolderDto>}
     * @memberof ResponseFolderDtoPageResponse
     */
    'Collection': Array<ResponseFolderDto>;
}
/**
 * 
 * @export
 * @interface ResponseFolderIdnDto
 */
export interface ResponseFolderIdnDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderIdnDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderIdnDto
     */
    'ParentId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderIdnDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ResponseFolderInfoDto
 */
export interface ResponseFolderInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderInfoDto
     */
    'id': string;
    /**
     * 
     * @type {FolderModel}
     * @memberof ResponseFolderInfoDto
     */
    'modelName': FolderModel;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderInfoDto
     */
    'ParentId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderInfoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderInfoDto
     */
    'description': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseFolderInfoDto
     */
    'modelId': number | null;
    /**
     * 
     * @type {ResponseFolderAccessMetaDto}
     * @memberof ResponseFolderInfoDto
     */
    'accessMeta': ResponseFolderAccessMetaDto;
    /**
     * 
     * @type {Array<ResponseFolderIdnDto>}
     * @memberof ResponseFolderInfoDto
     */
    'tree': Array<ResponseFolderIdnDto>;
}


/**
 * 
 * @export
 * @interface ResponseFolderInfoDtoEntity
 */
export interface ResponseFolderInfoDtoEntity {
    /**
     * 
     * @type {ResponseFolderInfoDto}
     * @memberof ResponseFolderInfoDtoEntity
     */
    'Entity'?: ResponseFolderInfoDto;
}
/**
 * 
 * @export
 * @interface ResponseFolderInfoDtoEntityResponse
 */
export interface ResponseFolderInfoDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFolderInfoDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseFolderInfoDto}
     * @memberof ResponseFolderInfoDtoEntityResponse
     */
    'Entity'?: ResponseFolderInfoDto;
}
/**
 * 
 * @export
 * @interface ResponseFolderItemDto
 */
export interface ResponseFolderItemDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderItemDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderItemDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderItemDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {FolderItemType}
     * @memberof ResponseFolderItemDto
     */
    'type': FolderItemType;
    /**
     * 
     * @type {FolderModel}
     * @memberof ResponseFolderItemDto
     */
    'modelName': FolderModel;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderItemDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseFolderItemDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseFolderItemDto
     */
    'modelId': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseFolderItemDto
     */
    'isEmpty'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseFolderItemDto
     */
    'shared': boolean;
    /**
     * 
     * @type {ResponseFileSignedDto}
     * @memberof ResponseFolderItemDto
     */
    'signed'?: ResponseFileSignedDto;
}


/**
 * 
 * @export
 * @interface ResponseFolderItemDtoCollection
 */
export interface ResponseFolderItemDtoCollection {
    /**
     * 
     * @type {Array<ResponseFolderItemDto>}
     * @memberof ResponseFolderItemDtoCollection
     */
    'Collection'?: Array<ResponseFolderItemDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseFolderItemDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseFolderItemDtoPageResponse
 */
export interface ResponseFolderItemDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseFolderItemDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseFolderItemDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseFolderItemDto>}
     * @memberof ResponseFolderItemDtoPageResponse
     */
    'Collection': Array<ResponseFolderItemDto>;
}
/**
 * 
 * @export
 * @interface ResponseHashDto
 */
export interface ResponseHashDto {
    /**
     * A HEX representation of the hashed value according to provided algorithm
     * @type {string}
     * @memberof ResponseHashDto
     */
    'hash': string;
}
/**
 * 
 * @export
 * @interface ResponseHashDtoEntity
 */
export interface ResponseHashDtoEntity {
    /**
     * 
     * @type {ResponseHashDto}
     * @memberof ResponseHashDtoEntity
     */
    'Entity'?: ResponseHashDto;
}
/**
 * 
 * @export
 * @interface ResponseHashDtoEntityResponse
 */
export interface ResponseHashDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseHashDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseHashDto}
     * @memberof ResponseHashDtoEntityResponse
     */
    'Entity'?: ResponseHashDto;
}
/**
 * 
 * @export
 * @interface ResponseHelpUrlDto
 */
export interface ResponseHelpUrlDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseHelpUrlDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseHelpUrlDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseHelpUrlDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseHelpUrlDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseHelpUrlDto
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseHelpUrlDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseHelpUrlDto
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface ResponseHelpUrlDtoCollection
 */
export interface ResponseHelpUrlDtoCollection {
    /**
     * 
     * @type {Array<ResponseHelpUrlDto>}
     * @memberof ResponseHelpUrlDtoCollection
     */
    'Collection'?: Array<ResponseHelpUrlDto>;
}
/**
 * 
 * @export
 * @interface ResponseHelpUrlDtoCollectionResponse
 */
export interface ResponseHelpUrlDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseHelpUrlDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseHelpUrlDto>}
     * @memberof ResponseHelpUrlDtoCollectionResponse
     */
    'Collection': Array<ResponseHelpUrlDto>;
}
/**
 * 
 * @export
 * @interface ResponseHelpUrlDtoEntity
 */
export interface ResponseHelpUrlDtoEntity {
    /**
     * 
     * @type {ResponseHelpUrlDto}
     * @memberof ResponseHelpUrlDtoEntity
     */
    'Entity'?: ResponseHelpUrlDto;
}
/**
 * 
 * @export
 * @interface ResponseHelpUrlDtoEntityResponse
 */
export interface ResponseHelpUrlDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseHelpUrlDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseHelpUrlDto}
     * @memberof ResponseHelpUrlDtoEntityResponse
     */
    'Entity'?: ResponseHelpUrlDto;
}
/**
 * 
 * @export
 * @interface ResponseIdNameAndParentDto
 */
export interface ResponseIdNameAndParentDto {
    /**
     * 
     * @type {ResponseIdNameAndParentDto}
     * @memberof ResponseIdNameAndParentDto
     */
    'parent'?: ResponseIdNameAndParentDto | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseIdNameAndParentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseIdNameAndParentDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ResponseInspectionSummaryDto
 */
export interface ResponseInspectionSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseInspectionSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {ResponseCaseCategorySummaryDto}
     * @memberof ResponseInspectionSummaryDto
     */
    'byCategory': ResponseCaseCategorySummaryDto;
}
/**
 * 
 * @export
 * @interface ResponseLoginDto
 */
export interface ResponseLoginDto {
    /**
     * 
     * @type {AuthDestination}
     * @memberof ResponseLoginDto
     */
    'destination': AuthDestination;
    /**
     * 
     * @type {string}
     * @memberof ResponseLoginDto
     */
    'accessToken': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseLoginDto
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseLoginDto
     */
    'companyId': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseLoginDto
     */
    'success': boolean;
}


/**
 * 
 * @export
 * @interface ResponseLogoutDto
 */
export interface ResponseLogoutDto {
    /**
     * 
     * @type {boolean}
     * @memberof ResponseLogoutDto
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface ResponseMainObjectSellerDto
 */
export interface ResponseMainObjectSellerDto {
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ResponseMainObjectSellerDto
     */
    'logos': Array<ResponseFileUsageDto>;
    /**
     * 
     * @type {string}
     * @memberof ResponseMainObjectSellerDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseMainObjectSellerDto
     */
    'companyIdentification': string;
}
/**
 * 
 * @export
 * @interface ResponseMarkingDataDto
 */
export interface ResponseMarkingDataDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseMarkingDataDto
     */
    'imageWidth'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMarkingDataDto
     */
    'imageHeight'?: number;
}
/**
 * 
 * @export
 * @interface ResponseMarkingDto
 */
export interface ResponseMarkingDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseMarkingDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMarkingDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {MarkingModel}
     * @memberof ResponseMarkingDto
     */
    'model': MarkingModel;
    /**
     * 
     * @type {number}
     * @memberof ResponseMarkingDto
     */
    'FileUsageId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseMarkingDto
     */
    'icon': string | null;
    /**
     * 
     * @type {GeometryDto}
     * @memberof ResponseMarkingDto
     */
    'geometry': GeometryDto;
    /**
     * 
     * @type {ResponseUserIdnDto}
     * @memberof ResponseMarkingDto
     */
    'Creator'?: ResponseUserIdnDto;
    /**
     * 
     * @type {number}
     * @memberof ResponseMarkingDto
     */
    'modelId': number;
    /**
     * 
     * @type {ResponseFileUsageDto}
     * @memberof ResponseMarkingDto
     */
    'FileUsage'?: ResponseFileUsageDto;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseMarkingDto
     */
    'correct': boolean;
    /**
     * 
     * @type {ResponseMarkingDataDto}
     * @memberof ResponseMarkingDto
     */
    'data': ResponseMarkingDataDto;
}


/**
 * 
 * @export
 * @interface ResponseMeterSummaryDto
 */
export interface ResponseMeterSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseMeterSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeterSummaryDto
     */
    'inWarning': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseMeterSummaryDto
     */
    'inError': number;
}
/**
 * 
 * @export
 * @interface ResponseMeteringPointStatusDto
 */
export interface ResponseMeteringPointStatusDto {
    /**
     * 
     * @type {MeteringPointStatus}
     * @memberof ResponseMeteringPointStatusDto
     */
    'status': MeteringPointStatus;
}


/**
 * 
 * @export
 * @interface ResponseMeteringPointStatusDtoEntity
 */
export interface ResponseMeteringPointStatusDtoEntity {
    /**
     * 
     * @type {ResponseMeteringPointStatusDto}
     * @memberof ResponseMeteringPointStatusDtoEntity
     */
    'Entity'?: ResponseMeteringPointStatusDto;
}
/**
 * 
 * @export
 * @interface ResponseMeteringPointStatusDtoEntityResponse
 */
export interface ResponseMeteringPointStatusDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseMeteringPointStatusDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseMeteringPointStatusDto}
     * @memberof ResponseMeteringPointStatusDtoEntityResponse
     */
    'Entity'?: ResponseMeteringPointStatusDto;
}
/**
 * 
 * @export
 * @interface ResponseModelTraitDto
 */
export interface ResponseModelTraitDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseModelTraitDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseModelTraitDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseModelTraitDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseModelTraitDto
     */
    'id': number;
    /**
     * 
     * @type {ModelTraitModel}
     * @memberof ResponseModelTraitDto
     */
    'model': ModelTraitModel;
    /**
     * 
     * @type {number}
     * @memberof ResponseModelTraitDto
     */
    'CreatorId': number;
    /**
     * 
     * @type {ResponseUserIdnDto}
     * @memberof ResponseModelTraitDto
     */
    'Creator'?: ResponseUserIdnDto;
    /**
     * 
     * @type {string}
     * @memberof ResponseModelTraitDto
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseModelTraitDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseModelTraitDto
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseModelTraitDto
     */
    'public'?: boolean;
}


/**
 * 
 * @export
 * @interface ResponseModelTraitDtoCollection
 */
export interface ResponseModelTraitDtoCollection {
    /**
     * 
     * @type {Array<ResponseModelTraitDto>}
     * @memberof ResponseModelTraitDtoCollection
     */
    'Collection'?: Array<ResponseModelTraitDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseModelTraitDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseModelTraitDtoCollection1
 */
export interface ResponseModelTraitDtoCollection1 {
    /**
     * 
     * @type {Array<ResponseModelTraitDto>}
     * @memberof ResponseModelTraitDtoCollection1
     */
    'Collection'?: Array<ResponseModelTraitDto>;
}
/**
 * 
 * @export
 * @interface ResponseModelTraitDtoCollectionResponse
 */
export interface ResponseModelTraitDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseModelTraitDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseModelTraitDto>}
     * @memberof ResponseModelTraitDtoCollectionResponse
     */
    'Collection': Array<ResponseModelTraitDto>;
}
/**
 * 
 * @export
 * @interface ResponseModelTraitDtoEntity
 */
export interface ResponseModelTraitDtoEntity {
    /**
     * 
     * @type {ResponseModelTraitDto}
     * @memberof ResponseModelTraitDtoEntity
     */
    'Entity'?: ResponseModelTraitDto;
}
/**
 * 
 * @export
 * @interface ResponseModelTraitDtoEntityResponse
 */
export interface ResponseModelTraitDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseModelTraitDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseModelTraitDto}
     * @memberof ResponseModelTraitDtoEntityResponse
     */
    'Entity'?: ResponseModelTraitDto;
}
/**
 * 
 * @export
 * @interface ResponseModelTraitDtoPageResponse
 */
export interface ResponseModelTraitDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseModelTraitDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseModelTraitDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseModelTraitDto>}
     * @memberof ResponseModelTraitDtoPageResponse
     */
    'Collection': Array<ResponseModelTraitDto>;
}
/**
 * 
 * @export
 * @interface ResponseNearbyUnwantedIncidentProjectDto
 */
export interface ResponseNearbyUnwantedIncidentProjectDto {
    /**
     * 
     * @type {UnwantedIncidentProjectType}
     * @memberof ResponseNearbyUnwantedIncidentProjectDto
     */
    'type': UnwantedIncidentProjectType;
    /**
     * 
     * @type {number}
     * @memberof ResponseNearbyUnwantedIncidentProjectDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseNearbyUnwantedIncidentProjectDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseNearbyUnwantedIncidentProjectDto
     */
    'constructionSiteName': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseNearbyUnwantedIncidentProjectDto
     */
    'distance': number;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseNearbyUnwantedIncidentProjectDto
     */
    'Company': ResponseCompanyIDNDto;
}


/**
 * 
 * @export
 * @interface ResponseNearbyUnwantedIncidentProjectDtoCollection
 */
export interface ResponseNearbyUnwantedIncidentProjectDtoCollection {
    /**
     * 
     * @type {Array<ResponseNearbyUnwantedIncidentProjectDto>}
     * @memberof ResponseNearbyUnwantedIncidentProjectDtoCollection
     */
    'Collection'?: Array<ResponseNearbyUnwantedIncidentProjectDto>;
}
/**
 * 
 * @export
 * @interface ResponseNearbyUnwantedIncidentProjectDtoCollectionResponse
 */
export interface ResponseNearbyUnwantedIncidentProjectDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseNearbyUnwantedIncidentProjectDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseNearbyUnwantedIncidentProjectDto>}
     * @memberof ResponseNearbyUnwantedIncidentProjectDtoCollectionResponse
     */
    'Collection': Array<ResponseNearbyUnwantedIncidentProjectDto>;
}
/**
 * 
 * @export
 * @interface ResponseNewProjectApartmentTakeoverDto
 */
export interface ResponseNewProjectApartmentTakeoverDto {
    /**
     * 
     * @type {ResponseProjectApartmentCardDto}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'apartment': ResponseProjectApartmentCardDto;
    /**
     * 
     * @type {ResponseProjectCardDto}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'project': ResponseProjectCardDto;
    /**
     * 
     * @type {Array<ResponseProjectApartmentMeterCardDto>}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'meters': Array<ResponseProjectApartmentMeterCardDto>;
    /**
     * 
     * @type {Array<ResponseProjectApartmentTakeoverInvolvedDto>}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'involved': Array<ResponseProjectApartmentTakeoverInvolvedDto>;
    /**
     * 
     * @type {Array<ResponseAddonOrderCardDto>}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'addonOrders': Array<ResponseAddonOrderCardDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverDestinationDto>}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'protocolRecipients': Array<ProjectApartmentTakeoverDestinationDto>;
    /**
     * 
     * @type {Array<ResponseTakeoverSupplierOptionDto>}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'supplierOptions': Array<ResponseTakeoverSupplierOptionDto>;
    /**
     * 
     * @type {Array<ProjectApartmentTakeoverSimultaneousSignerDto>}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'simultaneousSigners': Array<ProjectApartmentTakeoverSimultaneousSignerDto>;
    /**
     * 
     * @type {ResponseProjectApartmentTakeoverFilesDto}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'files': ResponseProjectApartmentTakeoverFilesDto;
    /**
     * 
     * @type {Array<ResponseArticleUsageDto>}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'articleUsages': Array<ResponseArticleUsageDto>;
    /**
     * 
     * @type {ResponseMainObjectSellerDto}
     * @memberof ResponseNewProjectApartmentTakeoverDto
     */
    'seller': ResponseMainObjectSellerDto;
}
/**
 * 
 * @export
 * @interface ResponseNewProjectApartmentTakeoverDtoEntity
 */
export interface ResponseNewProjectApartmentTakeoverDtoEntity {
    /**
     * 
     * @type {ResponseNewProjectApartmentTakeoverDto}
     * @memberof ResponseNewProjectApartmentTakeoverDtoEntity
     */
    'Entity'?: ResponseNewProjectApartmentTakeoverDto;
}
/**
 * 
 * @export
 * @interface ResponseNewProjectApartmentTakeoverDtoEntityResponse
 */
export interface ResponseNewProjectApartmentTakeoverDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseNewProjectApartmentTakeoverDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseNewProjectApartmentTakeoverDto}
     * @memberof ResponseNewProjectApartmentTakeoverDtoEntityResponse
     */
    'Entity'?: ResponseNewProjectApartmentTakeoverDto;
}
/**
 * 
 * @export
 * @interface ResponseNotificationTokenDto
 */
export interface ResponseNotificationTokenDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseNotificationTokenDto
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface ResponseNotificationTokenDtoEntity
 */
export interface ResponseNotificationTokenDtoEntity {
    /**
     * 
     * @type {ResponseNotificationTokenDto}
     * @memberof ResponseNotificationTokenDtoEntity
     */
    'Entity'?: ResponseNotificationTokenDto;
}
/**
 * 
 * @export
 * @interface ResponseNotificationTokenDtoEntityResponse
 */
export interface ResponseNotificationTokenDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseNotificationTokenDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseNotificationTokenDto}
     * @memberof ResponseNotificationTokenDtoEntityResponse
     */
    'Entity'?: ResponseNotificationTokenDto;
}
/**
 * 
 * @export
 * @interface ResponseObjectFieldIdnDto
 */
export interface ResponseObjectFieldIdnDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFieldIdnDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectFieldIdnDto
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectFieldIdnDto
     */
    'color': string | null;
}
/**
 * 
 * @export
 * @interface ResponseObjectFieldSummaryDto
 */
export interface ResponseObjectFieldSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFieldSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFieldSummaryDto
     */
    'hasContractor': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFieldSummaryDto
     */
    'missingContractor': number;
}
/**
 * 
 * @export
 * @interface ResponseObjectFileUsageSummaryDto
 */
export interface ResponseObjectFileUsageSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFileUsageSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFileUsageSummaryDto
     */
    'files': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFileUsageSummaryDto
     */
    'bannerImages': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFileUsageSummaryDto
     */
    'floorplans': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFileUsageSummaryDto
     */
    'inspection': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectFileUsageSummaryDto
     */
    'fdv': number;
}
/**
 * 
 * @export
 * @interface ResponseObjectIDNDto
 */
export interface ResponseObjectIDNDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectIDNDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectIDNDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseObjectIDNDto
     */
    'Company': ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseObjectIDNDtoCollection
 */
export interface ResponseObjectIDNDtoCollection {
    /**
     * 
     * @type {Array<ResponseObjectIDNDto>}
     * @memberof ResponseObjectIDNDtoCollection
     */
    'Collection'?: Array<ResponseObjectIDNDto>;
}
/**
 * 
 * @export
 * @interface ResponseObjectIDNDtoCollectionResponse
 */
export interface ResponseObjectIDNDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseObjectIDNDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseObjectIDNDto>}
     * @memberof ResponseObjectIDNDtoCollectionResponse
     */
    'Collection': Array<ResponseObjectIDNDto>;
}
/**
 * 
 * @export
 * @interface ResponseObjectInvolvedDto
 */
export interface ResponseObjectInvolvedDto {
    /**
     * The object the user is involved on
     * @type {string}
     * @memberof ResponseObjectInvolvedDto
     */
    'objectName': string;
    /**
     * The role the user has
     * @type {string}
     * @memberof ResponseObjectInvolvedDto
     */
    'role': string;
    /**
     * Indicates if role should be translated
     * @type {boolean}
     * @memberof ResponseObjectInvolvedDto
     */
    'translateRole': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectInvolvedDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectInvolvedDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectInvolvedDto
     */
    'mobile': string;
}
/**
 * 
 * @export
 * @interface ResponseObjectInvolvedDtoCollection
 */
export interface ResponseObjectInvolvedDtoCollection {
    /**
     * 
     * @type {Array<ResponseObjectInvolvedDto>}
     * @memberof ResponseObjectInvolvedDtoCollection
     */
    'Collection'?: Array<ResponseObjectInvolvedDto>;
}
/**
 * 
 * @export
 * @interface ResponseObjectInvolvedDtoCollectionResponse
 */
export interface ResponseObjectInvolvedDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseObjectInvolvedDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseObjectInvolvedDto>}
     * @memberof ResponseObjectInvolvedDtoCollectionResponse
     */
    'Collection': Array<ResponseObjectInvolvedDto>;
}
/**
 * 
 * @export
 * @interface ResponseObjectInvolvedSummaryDto
 */
export interface ResponseObjectInvolvedSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedSummaryDto
     */
    'caseManager': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedSummaryDto
     */
    'contact': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedSummaryDto
     */
    'custodian': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedSummaryDto
     */
    'projectManager': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedSummaryDto
     */
    'unwantedIncidentManager': number;
}
/**
 * 
 * @export
 * @interface ResponseObjectInvolvedUserDto
 */
export interface ResponseObjectInvolvedUserDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'id': number;
    /**
     * 
     * @type {ObjectInvolvedUser}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'role': ObjectInvolvedUser;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'fieldId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'agreementId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'fieldName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'fieldColor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectInvolvedUserDto
     */
    'agreementName'?: string;
}


/**
 * 
 * @export
 * @interface ResponseObjectInvolvedUserDtoCollection
 */
export interface ResponseObjectInvolvedUserDtoCollection {
    /**
     * 
     * @type {Array<ResponseObjectInvolvedUserDto>}
     * @memberof ResponseObjectInvolvedUserDtoCollection
     */
    'Collection'?: Array<ResponseObjectInvolvedUserDto>;
}
/**
 * 
 * @export
 * @interface ResponseObjectInvolvedUserDtoCollectionResponse
 */
export interface ResponseObjectInvolvedUserDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseObjectInvolvedUserDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseObjectInvolvedUserDto>}
     * @memberof ResponseObjectInvolvedUserDtoCollectionResponse
     */
    'Collection': Array<ResponseObjectInvolvedUserDto>;
}
/**
 * 
 * @export
 * @interface ResponseObjectParentIdnDto
 */
export interface ResponseObjectParentIdnDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectParentIdnDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectParentIdnDto
     */
    'ParentId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectParentIdnDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseObjectParentIdnDto
     */
    'Company': ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseObjectParentIdnDtoCollection
 */
export interface ResponseObjectParentIdnDtoCollection {
    /**
     * 
     * @type {Array<ResponseObjectParentIdnDto>}
     * @memberof ResponseObjectParentIdnDtoCollection
     */
    'Collection'?: Array<ResponseObjectParentIdnDto>;
}
/**
 * 
 * @export
 * @interface ResponseObjectParentIdnDtoCollectionResponse
 */
export interface ResponseObjectParentIdnDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseObjectParentIdnDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseObjectParentIdnDto>}
     * @memberof ResponseObjectParentIdnDtoCollectionResponse
     */
    'Collection': Array<ResponseObjectParentIdnDto>;
}
/**
 * 
 * @export
 * @interface ResponseObjectSummaryDto
 */
export interface ResponseObjectSummaryDto {
    /**
     * 
     * @type {ResponseMeterSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'meter': ResponseMeterSummaryDto;
    /**
     * 
     * @type {ResponseTenancySummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'tenancy': ResponseTenancySummaryDto;
    /**
     * 
     * @type {ResponseAgreementSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'agreement': ResponseAgreementSummaryDto;
    /**
     * 
     * @type {ResponseInspectionSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'inspection': ResponseInspectionSummaryDto;
    /**
     * 
     * @type {ResponseChecklistSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'checklist': ResponseChecklistSummaryDto;
    /**
     * 
     * @type {ResponseChecklistSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'checklistGroup': ResponseChecklistSummaryDto;
    /**
     * 
     * @type {ResponseOwnershipSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'ownership': ResponseOwnershipSummaryDto;
    /**
     * 
     * @type {ResponseSaleSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'sale': ResponseSaleSummaryDto;
    /**
     * 
     * @type {ResponseObjectFieldSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'field': ResponseObjectFieldSummaryDto;
    /**
     * 
     * @type {ResponseRepeatableCaseSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'repeatableCase': ResponseRepeatableCaseSummaryDto;
    /**
     * 
     * @type {ResponseObjectInvolvedSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'involved': ResponseObjectInvolvedSummaryDto;
    /**
     * 
     * @type {ResponseObjectFileUsageSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'fileUsage': ResponseObjectFileUsageSummaryDto;
    /**
     * 
     * @type {ResponseCaseSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'case': ResponseCaseSummaryDto;
    /**
     * 
     * @type {ResponseUnwantedIncidentSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'unwantedIncident': ResponseUnwantedIncidentSummaryDto;
    /**
     * 
     * @type {ResponseArticleSummaryDto}
     * @memberof ResponseObjectSummaryDto
     */
    'article': ResponseArticleSummaryDto;
}
/**
 * 
 * @export
 * @interface ResponseObjectSummaryDtoEntity
 */
export interface ResponseObjectSummaryDtoEntity {
    /**
     * 
     * @type {ResponseObjectSummaryDto}
     * @memberof ResponseObjectSummaryDtoEntity
     */
    'Entity'?: ResponseObjectSummaryDto;
}
/**
 * 
 * @export
 * @interface ResponseObjectSummaryDtoEntityResponse
 */
export interface ResponseObjectSummaryDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseObjectSummaryDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseObjectSummaryDto}
     * @memberof ResponseObjectSummaryDtoEntityResponse
     */
    'Entity'?: ResponseObjectSummaryDto;
}
/**
 * 
 * @export
 * @interface ResponseObjectUnwantedIncidentInfoDto
 */
export interface ResponseObjectUnwantedIncidentInfoDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectUnwantedIncidentInfoDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectUnwantedIncidentInfoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseObjectUnwantedIncidentInfoDto
     */
    'address'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseObjectUnwantedIncidentInfoDto
     */
    'zipcode'?: number;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ResponseObjectUnwantedIncidentInfoDto
     */
    'floorPlans'?: Array<ResponseFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponseObjectUnwantedIncidentInfoDtoEntity
 */
export interface ResponseObjectUnwantedIncidentInfoDtoEntity {
    /**
     * 
     * @type {ResponseObjectUnwantedIncidentInfoDto}
     * @memberof ResponseObjectUnwantedIncidentInfoDtoEntity
     */
    'Entity'?: ResponseObjectUnwantedIncidentInfoDto;
}
/**
 * 
 * @export
 * @interface ResponseObjectUnwantedIncidentInfoDtoEntityResponse
 */
export interface ResponseObjectUnwantedIncidentInfoDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseObjectUnwantedIncidentInfoDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseObjectUnwantedIncidentInfoDto}
     * @memberof ResponseObjectUnwantedIncidentInfoDtoEntityResponse
     */
    'Entity'?: ResponseObjectUnwantedIncidentInfoDto;
}
/**
 * 
 * @export
 * @interface ResponseOwnershipSummaryDto
 */
export interface ResponseOwnershipSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseOwnershipSummaryDto
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ResponsePortalAddonApartmentDto
 */
export interface ResponsePortalAddonApartmentDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'AddonId': number;
    /**
     * 
     * @type {ResponsePortalAddonDto}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'Addon': ResponsePortalAddonDto;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'ProjectApartmentId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'ContractorId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'CreatorId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'filterFileUsage'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'amount': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'dateStart': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'dateEnd': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'unit': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'unitPriceIn': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'unitPrice': boolean;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'priceIn': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'priceOut': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'VAT': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'markingMin': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'markingMax': number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'standardForCategory': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'requireApproval': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortalAddonApartmentDto
     */
    'requireSignature': boolean;
}
/**
 * 
 * @export
 * @interface ResponsePortalAddonApartmentDtoCollection
 */
export interface ResponsePortalAddonApartmentDtoCollection {
    /**
     * 
     * @type {Array<ResponsePortalAddonApartmentDto>}
     * @memberof ResponsePortalAddonApartmentDtoCollection
     */
    'Collection'?: Array<ResponsePortalAddonApartmentDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalAddonApartmentDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalAddonApartmentDtoPageResponse
 */
export interface ResponsePortalAddonApartmentDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponsePortalAddonApartmentDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalAddonApartmentDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponsePortalAddonApartmentDto>}
     * @memberof ResponsePortalAddonApartmentDtoPageResponse
     */
    'Collection': Array<ResponsePortalAddonApartmentDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalAddonDto
 */
export interface ResponsePortalAddonDto {
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonDto
     */
    'CreatorId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ResponsePortalAddonOrderDto
 */
export interface ResponsePortalAddonOrderDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonOrderDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonOrderDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonOrderDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'id': number;
    /**
     * 
     * @type {AddonOrderStatus}
     * @memberof ResponsePortalAddonOrderDto
     */
    'status': AddonOrderStatus;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'ClientId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonOrderDto
     */
    'ClientComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonOrderDto
     */
    'seller': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonOrderDto
     */
    'sellerOrgnr': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonOrderDto
     */
    'dateProcessed'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonOrderDto
     */
    'dateSignature'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'ManagerId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalAddonOrderDto
     */
    'ManagerComment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'ProjectApartmentId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'AddonApartmentId': number;
    /**
     * 
     * @type {ResponsePortalAddonApartmentDto}
     * @memberof ResponsePortalAddonOrderDto
     */
    'AddonApartment': ResponsePortalAddonApartmentDto;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'AddonId': number;
    /**
     * 
     * @type {ResponsePortalAddonDto}
     * @memberof ResponsePortalAddonOrderDto
     */
    'Addon': ResponsePortalAddonDto;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'CartId'?: number | null;
    /**
     * 
     * @type {Array<ResponseMarkingDto>}
     * @memberof ResponsePortalAddonOrderDto
     */
    'Markings'?: Array<ResponseMarkingDto>;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'priceIn': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'priceOut': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'VAT': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'CaseId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalAddonOrderDto
     */
    'invoiced'?: number | null;
}


/**
 * 
 * @export
 * @interface ResponsePortalAddonOrderDtoCollection
 */
export interface ResponsePortalAddonOrderDtoCollection {
    /**
     * 
     * @type {Array<ResponsePortalAddonOrderDto>}
     * @memberof ResponsePortalAddonOrderDtoCollection
     */
    'Collection'?: Array<ResponsePortalAddonOrderDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalAddonOrderDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalAddonOrderDtoPageResponse
 */
export interface ResponsePortalAddonOrderDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponsePortalAddonOrderDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalAddonOrderDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponsePortalAddonOrderDto>}
     * @memberof ResponsePortalAddonOrderDtoPageResponse
     */
    'Collection': Array<ResponsePortalAddonOrderDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalApartmentDto
 */
export interface ResponsePortalApartmentDto {
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalApartmentDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalApartmentDto
     */
    'ProjectId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalApartmentDto
     */
    'name': string;
    /**
     * 
     * @type {ResponsePortalApartmentProjectDto}
     * @memberof ResponsePortalApartmentDto
     */
    'Project': ResponsePortalApartmentProjectDto;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalApartmentDto
     */
    'primarySpaceSquareMeter': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalApartmentDto
     */
    'sectionNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalApartmentDto
     */
    'unitType': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalApartmentDto
     */
    'takeoverAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalApartmentDto
     */
    'model': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortalApartmentDto
     */
    'disableRequests': boolean;
    /**
     * 
     * @type {Array<ResponseUserCardDto>}
     * @memberof ResponsePortalApartmentDto
     */
    'Clients': Array<ResponseUserCardDto>;
    /**
     * 
     * @type {Array<ResponseModelTraitDto>}
     * @memberof ResponsePortalApartmentDto
     */
    'Traits': Array<ResponseModelTraitDto>;
    /**
     * 
     * @type {Array<ResponseAddressDto>}
     * @memberof ResponsePortalApartmentDto
     */
    'Addresses'?: Array<ResponseAddressDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalApartmentDtoEntity
 */
export interface ResponsePortalApartmentDtoEntity {
    /**
     * 
     * @type {ResponsePortalApartmentDto}
     * @memberof ResponsePortalApartmentDtoEntity
     */
    'Entity'?: ResponsePortalApartmentDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalApartmentDtoEntityResponse
 */
export interface ResponsePortalApartmentDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponsePortalApartmentDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponsePortalApartmentDto}
     * @memberof ResponsePortalApartmentDtoEntityResponse
     */
    'Entity'?: ResponsePortalApartmentDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalApartmentProjectDto
 */
export interface ResponsePortalApartmentProjectDto {
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalApartmentProjectDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalApartmentProjectDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalApartmentProjectDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponsePortalApartmentProjectDto
     */
    'Company': ResponseCompanyIDNDto;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalApartmentProjectDto
     */
    'projectOwner': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalApartmentProjectDto
     */
    'projectOwnerOrgnr': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortalApartmentProjectDto
     */
    'disableRequests': boolean | null;
}
/**
 * 
 * @export
 * @interface ResponsePortalArticleDto
 */
export interface ResponsePortalArticleDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalArticleDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalArticleDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalArticleDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalArticleDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalArticleDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalArticleDto
     */
    'publishedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalArticleDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalArticleDto
     */
    'lead': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalArticleDto
     */
    'content': string;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDtoCreator}
     * @memberof ResponsePortalArticleDto
     */
    'Creator': ResponseUnwantedIncidentProcessDtoCreator | null;
    /**
     * 
     * @type {Array<ResponsePortalFileUsageDto>}
     * @memberof ResponsePortalArticleDto
     */
    'Images': Array<ResponsePortalFileUsageDto>;
    /**
     * 
     * @type {Array<ResponsePortalFileUsageDto>}
     * @memberof ResponsePortalArticleDto
     */
    'Files': Array<ResponsePortalFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalArticleDtoCollection
 */
export interface ResponsePortalArticleDtoCollection {
    /**
     * 
     * @type {Array<ResponsePortalArticleDto>}
     * @memberof ResponsePortalArticleDtoCollection
     */
    'Collection'?: Array<ResponsePortalArticleDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalArticleDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalArticleDtoPageResponse
 */
export interface ResponsePortalArticleDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponsePortalArticleDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalArticleDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponsePortalArticleDto>}
     * @memberof ResponsePortalArticleDtoPageResponse
     */
    'Collection': Array<ResponsePortalArticleDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalCaseDto
 */
export interface ResponsePortalCaseDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseDto
     */
    'archivedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseDto
     */
    'deadlineAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseDto
     */
    'completedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseDto
     */
    'FieldId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseDto
     */
    'CaseCategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseDto
     */
    'AddonOrderId': number;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortalCaseDto
     */
    'read': boolean;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseDto
     */
    'ApartmentId'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseDto
     */
    'ProjectId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseDto
     */
    'location': string;
    /**
     * 
     * @type {ResponseFieldIDNDto}
     * @memberof ResponsePortalCaseDto
     */
    'Field'?: ResponseFieldIDNDto;
    /**
     * 
     * @type {ResponseCaseCategoryDto}
     * @memberof ResponsePortalCaseDto
     */
    'CaseCategory': ResponseCaseCategoryDto;
    /**
     * 
     * @type {Array<ResponsePortalFileUsageDto>}
     * @memberof ResponsePortalCaseDto
     */
    'FileUsages': Array<ResponsePortalFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalCaseDtoCollection
 */
export interface ResponsePortalCaseDtoCollection {
    /**
     * 
     * @type {Array<ResponsePortalCaseDto>}
     * @memberof ResponsePortalCaseDtoCollection
     */
    'Collection'?: Array<ResponsePortalCaseDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalCaseDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalCaseDtoPageResponse
 */
export interface ResponsePortalCaseDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponsePortalCaseDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalCaseDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponsePortalCaseDto>}
     * @memberof ResponsePortalCaseDtoPageResponse
     */
    'Collection': Array<ResponsePortalCaseDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalCaseLogDto
 */
export interface ResponsePortalCaseLogDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseLogDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseLogDto
     */
    'EmailId'?: number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ResponsePortalCaseLogDto
     */
    'translateVars': { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseLogDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogDto
     */
    'message': string;
    /**
     * 
     * @type {ResponsePortalCaseLogEmailDto}
     * @memberof ResponsePortalCaseLogDto
     */
    'Email'?: ResponsePortalCaseLogEmailDto;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortalCaseLogDto
     */
    'system': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponsePortalCaseLogDto
     */
    'translate': boolean;
    /**
     * 
     * @type {ResponseUserCardDto}
     * @memberof ResponsePortalCaseLogDto
     */
    'Creator'?: ResponseUserCardDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalCaseLogDtoCollection
 */
export interface ResponsePortalCaseLogDtoCollection {
    /**
     * 
     * @type {Array<ResponsePortalCaseLogDto>}
     * @memberof ResponsePortalCaseLogDtoCollection
     */
    'Collection'?: Array<ResponsePortalCaseLogDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalCaseLogDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalCaseLogDtoEntity
 */
export interface ResponsePortalCaseLogDtoEntity {
    /**
     * 
     * @type {ResponsePortalCaseLogDto}
     * @memberof ResponsePortalCaseLogDtoEntity
     */
    'Entity'?: ResponsePortalCaseLogDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalCaseLogDtoEntityResponse
 */
export interface ResponsePortalCaseLogDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponsePortalCaseLogDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponsePortalCaseLogDto}
     * @memberof ResponsePortalCaseLogDtoEntityResponse
     */
    'Entity'?: ResponsePortalCaseLogDto;
}
/**
 * 
 * @export
 * @interface ResponsePortalCaseLogDtoPageResponse
 */
export interface ResponsePortalCaseLogDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponsePortalCaseLogDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponsePortalCaseLogDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponsePortalCaseLogDto>}
     * @memberof ResponsePortalCaseLogDtoPageResponse
     */
    'Collection': Array<ResponsePortalCaseLogDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalCaseLogEmailDto
 */
export interface ResponsePortalCaseLogEmailDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogEmailDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogEmailDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogEmailDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalCaseLogEmailDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogEmailDto
     */
    'fromEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogEmailDto
     */
    'fromName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogEmailDto
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogEmailDto
     */
    'bodyPlain': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalCaseLogEmailDto
     */
    'bodyHTML': string;
}
/**
 * 
 * @export
 * @interface ResponsePortalFileDto
 */
export interface ResponsePortalFileDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalFileDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalFileDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalFileDto
     */
    'id': number;
    /**
     * MIME type of the File
     * @type {string}
     * @memberof ResponsePortalFileDto
     */
    'type': string | null;
    /**
     * File size in bytes
     * @type {number}
     * @memberof ResponsePortalFileDto
     */
    'size': number;
    /**
     * 
     * @type {ResponseFileSignedDto}
     * @memberof ResponsePortalFileDto
     */
    'signed': ResponseFileSignedDto;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalFileDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalFileDto
     */
    'description': string | null;
}
/**
 * 
 * @export
 * @interface ResponsePortalFileUsageDto
 */
export interface ResponsePortalFileUsageDto {
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalFileUsageDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalFileUsageDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalFileUsageDto
     */
    'id': number;
    /**
     * 
     * @type {ResponsePortalFileDto}
     * @memberof ResponsePortalFileUsageDto
     */
    'File': ResponsePortalFileDto;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalFileUsageDto
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof ResponsePortalFileUsageDto
     */
    'fileSort': number;
}
/**
 * 
 * @export
 * @interface ResponsePortalFileUsageDtoCollection
 */
export interface ResponsePortalFileUsageDtoCollection {
    /**
     * 
     * @type {Array<ResponsePortalFileUsageDto>}
     * @memberof ResponsePortalFileUsageDtoCollection
     */
    'Collection'?: Array<ResponsePortalFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalFileUsageDtoCollectionResponse
 */
export interface ResponsePortalFileUsageDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponsePortalFileUsageDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponsePortalFileUsageDto>}
     * @memberof ResponsePortalFileUsageDtoCollectionResponse
     */
    'Collection': Array<ResponsePortalFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalMainObjectDto
 */
export interface ResponsePortalMainObjectDto {
    /**
     * 
     * @type {ResponseMainObjectSellerDto}
     * @memberof ResponsePortalMainObjectDto
     */
    'seller': ResponseMainObjectSellerDto;
    /**
     * 
     * @type {ResponseProjectIDNDto}
     * @memberof ResponsePortalMainObjectDto
     */
    'Project'?: ResponseProjectIDNDto | null;
    /**
     * 
     * @type {Array<ResponsePortalFileUsageDto>}
     * @memberof ResponsePortalMainObjectDto
     */
    'bannerImages': Array<ResponsePortalFileUsageDto>;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalMainObjectDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalMainObjectDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalMainObjectDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalMainObjectDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalMainObjectDto
     */
    'postal'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponsePortalMainObjectDto
     */
    'type': ResponsePortalMainObjectDtoTypeEnum;
    /**
     * 
     * @type {Array<ResponseAddressDto>}
     * @memberof ResponsePortalMainObjectDto
     */
    'Addresses'?: Array<ResponseAddressDto>;
}

export const ResponsePortalMainObjectDtoTypeEnum = {
    ProjectApartment: 'project-apartment',
    Tenancy: 'tenancy',
    Ownership: 'ownership'
} as const;

export type ResponsePortalMainObjectDtoTypeEnum = typeof ResponsePortalMainObjectDtoTypeEnum[keyof typeof ResponsePortalMainObjectDtoTypeEnum];

/**
 * 
 * @export
 * @interface ResponsePortalMainObjectDtoCollection
 */
export interface ResponsePortalMainObjectDtoCollection {
    /**
     * 
     * @type {Array<ResponsePortalMainObjectDto>}
     * @memberof ResponsePortalMainObjectDtoCollection
     */
    'Collection'?: Array<ResponsePortalMainObjectDto>;
}
/**
 * 
 * @export
 * @interface ResponsePortalMainObjectDtoCollectionResponse
 */
export interface ResponsePortalMainObjectDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponsePortalMainObjectDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponsePortalMainObjectDto>}
     * @memberof ResponsePortalMainObjectDtoCollectionResponse
     */
    'Collection': Array<ResponsePortalMainObjectDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentCardDto
 */
export interface ResponseProjectApartmentCardDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentCardDto
     */
    'id': number;
    /**
     * 
     * @type {ProjectApartmentAgreementType}
     * @memberof ResponseProjectApartmentCardDto
     */
    'agreementType': ProjectApartmentAgreementType;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentCardDto
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentCardDto
     */
    'sectionNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentCardDto
     */
    'fieldNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentCardDto
     */
    'titleNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentCardDto
     */
    'municipalityLandNumber': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentCardDto
     */
    'takeoverDateUnix': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentCardDto
     */
    'parkingLot': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentCardDto
     */
    'storageRoomNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentCardDto
     */
    'unitType': string | null;
    /**
     * 
     * @type {Array<ResponseModelTraitDto>}
     * @memberof ResponseProjectApartmentCardDto
     */
    'modelTraits'?: Array<ResponseModelTraitDto>;
    /**
     * 
     * @type {Array<ResponseAddressDto>}
     * @memberof ResponseProjectApartmentCardDto
     */
    'Addresses'?: Array<ResponseAddressDto>;
}


/**
 * 
 * @export
 * @interface ResponseProjectApartmentCardDtoCollection
 */
export interface ResponseProjectApartmentCardDtoCollection {
    /**
     * 
     * @type {Array<ResponseProjectApartmentCardDto>}
     * @memberof ResponseProjectApartmentCardDtoCollection
     */
    'Collection'?: Array<ResponseProjectApartmentCardDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentCardDtoCollectionResponse
 */
export interface ResponseProjectApartmentCardDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseProjectApartmentCardDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseProjectApartmentCardDto>}
     * @memberof ResponseProjectApartmentCardDtoCollectionResponse
     */
    'Collection': Array<ResponseProjectApartmentCardDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentClientDto
 */
export interface ResponseProjectApartmentClientDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentClientDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentClientDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentClientDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentClientDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentClientDto
     */
    'ApartmentId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentClientDto
     */
    'CrmUserId': string;
    /**
     * 
     * @type {ResponseCrmUserDto}
     * @memberof ResponseProjectApartmentClientDto
     */
    'CrmUser': ResponseCrmUserDto;
    /**
     * 
     * @type {ResponseProjectApartmentCardDto}
     * @memberof ResponseProjectApartmentClientDto
     */
    'ProjectApartment': ResponseProjectApartmentCardDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentClientDtoCollection
 */
export interface ResponseProjectApartmentClientDtoCollection {
    /**
     * 
     * @type {Array<ResponseProjectApartmentClientDto>}
     * @memberof ResponseProjectApartmentClientDtoCollection
     */
    'Collection'?: Array<ResponseProjectApartmentClientDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseProjectApartmentClientDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentClientDtoEntity
 */
export interface ResponseProjectApartmentClientDtoEntity {
    /**
     * 
     * @type {ResponseProjectApartmentClientDto}
     * @memberof ResponseProjectApartmentClientDtoEntity
     */
    'Entity'?: ResponseProjectApartmentClientDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentClientDtoEntityResponse
 */
export interface ResponseProjectApartmentClientDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseProjectApartmentClientDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseProjectApartmentClientDto}
     * @memberof ResponseProjectApartmentClientDtoEntityResponse
     */
    'Entity'?: ResponseProjectApartmentClientDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentClientDtoPageResponse
 */
export interface ResponseProjectApartmentClientDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseProjectApartmentClientDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseProjectApartmentClientDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseProjectApartmentClientDto>}
     * @memberof ResponseProjectApartmentClientDtoPageResponse
     */
    'Collection': Array<ResponseProjectApartmentClientDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentClientSummaryDto
 */
export interface ResponseProjectApartmentClientSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentClientSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentClientSummaryDto
     */
    'invited': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentClientSummaryDto
     */
    'accepted': number;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentMeterCardDto
 */
export interface ResponseProjectApartmentMeterCardDto {
    /**
     * 
     * @type {MeterType}
     * @memberof ResponseProjectApartmentMeterCardDto
     */
    'type': MeterType;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentMeterCardDto
     */
    'number': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentMeterCardDto
     */
    'externalNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentMeterCardDto
     */
    'value'?: number;
}


/**
 * 
 * @export
 * @interface ResponseProjectApartmentSummaryDto
 */
export interface ResponseProjectApartmentSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'hasOwner': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'noOwners': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'sold': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'notSold': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'includedInSalesReporting': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'takeoverDatePast': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'takeoverDateToday': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'takeoverDateFuture': number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'unitTypeCount': { [key: string]: number; };
    /**
     * Object with field name as key and count as value
     * @type {{ [key: string]: number; }}
     * @memberof ResponseProjectApartmentSummaryDto
     */
    'fieldNumberCount': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentTakeoverDestinationDto
 */
export interface ResponseProjectApartmentTakeoverDestinationDto {
    /**
     * 
     * @type {ProjectTakeoverDestinationContentTypeToken}
     * @memberof ResponseProjectApartmentTakeoverDestinationDto
     */
    'contentType': ProjectTakeoverDestinationContentTypeToken;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverDestinationDto
     */
    'recipientName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverDestinationDto
     */
    'recipientDestination'?: string;
}


/**
 * 
 * @export
 * @interface ResponseProjectApartmentTakeoverDestinationDtoCollection
 */
export interface ResponseProjectApartmentTakeoverDestinationDtoCollection {
    /**
     * 
     * @type {Array<ResponseProjectApartmentTakeoverDestinationDto>}
     * @memberof ResponseProjectApartmentTakeoverDestinationDtoCollection
     */
    'Collection'?: Array<ResponseProjectApartmentTakeoverDestinationDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentTakeoverDestinationDtoCollectionResponse
 */
export interface ResponseProjectApartmentTakeoverDestinationDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseProjectApartmentTakeoverDestinationDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseProjectApartmentTakeoverDestinationDto>}
     * @memberof ResponseProjectApartmentTakeoverDestinationDtoCollectionResponse
     */
    'Collection': Array<ResponseProjectApartmentTakeoverDestinationDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentTakeoverDto
 */
export interface ResponseProjectApartmentTakeoverDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentTakeoverDto
     */
    'ProjectId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentTakeoverDto
     */
    'ApartmentId': number;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentTakeoverDtoEntity
 */
export interface ResponseProjectApartmentTakeoverDtoEntity {
    /**
     * 
     * @type {ResponseProjectApartmentTakeoverDto}
     * @memberof ResponseProjectApartmentTakeoverDtoEntity
     */
    'Entity'?: ResponseProjectApartmentTakeoverDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentTakeoverDtoEntityResponse
 */
export interface ResponseProjectApartmentTakeoverDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseProjectApartmentTakeoverDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseProjectApartmentTakeoverDto}
     * @memberof ResponseProjectApartmentTakeoverDtoEntityResponse
     */
    'Entity'?: ResponseProjectApartmentTakeoverDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentTakeoverFilesDto
 */
export interface ResponseProjectApartmentTakeoverFilesDto {
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ResponseProjectApartmentTakeoverFilesDto
     */
    'temporaryUsePermits'?: Array<ResponseFileUsageDto>;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ResponseProjectApartmentTakeoverFilesDto
     */
    'certificatesOfCompletion'?: Array<ResponseFileUsageDto>;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ResponseProjectApartmentTakeoverFilesDto
     */
    'amendmentAgreements'?: Array<ResponseFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectApartmentTakeoverInvolvedDto
 */
export interface ResponseProjectApartmentTakeoverInvolvedDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'mail': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'CompanyId': number | null;
    /**
     * 
     * @type {ProjectApartmentTakeoverInvolvedUserRole}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'role': ProjectApartmentTakeoverInvolvedUserRole;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'report': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'present': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'signer': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'proxy': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'mobile': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'address': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'zip': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'gender': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'dateOfBirth': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'mailNewCase': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'mailNewMessage': boolean;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseProjectApartmentTakeoverInvolvedDto
     */
    'Company'?: ResponseCompanyIDNDto;
}


/**
 * 
 * @export
 * @interface ResponseProjectCardDto
 */
export interface ResponseProjectCardDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectCardDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectCardDto
     */
    'TakeoverNewChecklistTemplateId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectCardDto
     */
    'TakeoverUsedChecklistTemplateId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'projectOwner': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'projectOwnerCompanyIdentification': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'meterPowerMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'meterWarmWaterMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'meterColdWaterMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'meterDistrictHeatingMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'meterPowerLineMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'meterGasMail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectCardDto
     */
    'countryCode': string;
}
/**
 * 
 * @export
 * @interface ResponseProjectFileUsageSummaryDto
 */
export interface ResponseProjectFileUsageSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'approvedFrameworkApplications': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'certificatesOfCompletion': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'files': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'floorplans': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'inspection': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'logos': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'publicFiles': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'temporaryUsePermits': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectFileUsageSummaryDto
     */
    'zoningPlans': number;
}
/**
 * 
 * @export
 * @interface ResponseProjectIDNDto
 */
export interface ResponseProjectIDNDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectIDNDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectIDNDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseProjectIDNDto
     */
    'Company': ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectInvolvedSummaryDto
 */
export interface ResponseProjectInvolvedSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectInvolvedSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectInvolvedSummaryDto
     */
    'caseManager': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectInvolvedSummaryDto
     */
    'unwantedIncidentManager': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectInvolvedSummaryDto
     */
    'addonManager': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectInvolvedSummaryDto
     */
    'simultaneousSigner': number;
}
/**
 * 
 * @export
 * @interface ResponseProjectInvolvedUserDto
 */
export interface ResponseProjectInvolvedUserDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectInvolvedUserDto
     */
    'id': number;
    /**
     * 
     * @type {ProjectInvolvedUser}
     * @memberof ResponseProjectInvolvedUserDto
     */
    'role': ProjectInvolvedUser;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectInvolvedUserDto
     */
    'companyId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectInvolvedUserDto
     */
    'fieldId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectInvolvedUserDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectInvolvedUserDto
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectInvolvedUserDto
     */
    'fieldName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectInvolvedUserDto
     */
    'fieldColor'?: string;
}


/**
 * 
 * @export
 * @interface ResponseProjectInvolvedUserDtoCollection
 */
export interface ResponseProjectInvolvedUserDtoCollection {
    /**
     * 
     * @type {Array<ResponseProjectInvolvedUserDto>}
     * @memberof ResponseProjectInvolvedUserDtoCollection
     */
    'Collection'?: Array<ResponseProjectInvolvedUserDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectInvolvedUserDtoCollectionResponse
 */
export interface ResponseProjectInvolvedUserDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseProjectInvolvedUserDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseProjectInvolvedUserDto>}
     * @memberof ResponseProjectInvolvedUserDtoCollectionResponse
     */
    'Collection': Array<ResponseProjectInvolvedUserDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectMomSummaryDto
 */
export interface ResponseProjectMomSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectMomSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectMomSummaryDto
     */
    'active': number;
    /**
     * Object with field name as key and count as value
     * @type {{ [key: string]: number; }}
     * @memberof ResponseProjectMomSummaryDto
     */
    'collaboratorsByField': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface ResponseProjectSummaryDto
 */
export interface ResponseProjectSummaryDto {
    /**
     * 
     * @type {ResponseProjectApartmentSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'apartment': ResponseProjectApartmentSummaryDto;
    /**
     * 
     * @type {ResponseProjectApartmentClientSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'client': ResponseProjectApartmentClientSummaryDto;
    /**
     * 
     * @type {ResponseInspectionSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'inspection'?: ResponseInspectionSummaryDto | null;
    /**
     * 
     * @type {ResponseChecklistSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'checklist': ResponseChecklistSummaryDto;
    /**
     * 
     * @type {ResponseChecklistSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'checklistGroup': ResponseChecklistSummaryDto;
    /**
     * 
     * @type {ResponseFieldSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'field': ResponseFieldSummaryDto;
    /**
     * 
     * @type {ResponseProjectFileUsageSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'fileUsage': ResponseProjectFileUsageSummaryDto;
    /**
     * 
     * @type {ResponseProjectMomSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'projectMom': ResponseProjectMomSummaryDto;
    /**
     * 
     * @type {ResponseAddonSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'addon'?: ResponseAddonSummaryDto | null;
    /**
     * 
     * @type {ResponseProjectInvolvedSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'involved': ResponseProjectInvolvedSummaryDto;
    /**
     * 
     * @type {ResponseUnwantedIncidentSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'unwantedIncident': ResponseUnwantedIncidentSummaryDto;
    /**
     * 
     * @type {ResponseCaseSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'case': ResponseCaseSummaryDto;
    /**
     * 
     * @type {ResponseArticleSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'article': ResponseArticleSummaryDto;
    /**
     * 
     * @type {ResponseRepeatableCaseSummaryDto}
     * @memberof ResponseProjectSummaryDto
     */
    'repeatableCase': ResponseRepeatableCaseSummaryDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectSummaryDtoEntity
 */
export interface ResponseProjectSummaryDtoEntity {
    /**
     * 
     * @type {ResponseProjectSummaryDto}
     * @memberof ResponseProjectSummaryDtoEntity
     */
    'Entity'?: ResponseProjectSummaryDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectSummaryDtoEntityResponse
 */
export interface ResponseProjectSummaryDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseProjectSummaryDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseProjectSummaryDto}
     * @memberof ResponseProjectSummaryDtoEntityResponse
     */
    'Entity'?: ResponseProjectSummaryDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectUnwantedIncidentInfoDto
 */
export interface ResponseProjectUnwantedIncidentInfoDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseProjectUnwantedIncidentInfoDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseProjectUnwantedIncidentInfoDto
     */
    'name': string | null;
    /**
     * 
     * @type {Array<ResponseAddressDto>}
     * @memberof ResponseProjectUnwantedIncidentInfoDto
     */
    'addresses': Array<ResponseAddressDto>;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ResponseProjectUnwantedIncidentInfoDto
     */
    'floorPlans'?: Array<ResponseFileUsageDto>;
}
/**
 * 
 * @export
 * @interface ResponseProjectUnwantedIncidentInfoDtoEntity
 */
export interface ResponseProjectUnwantedIncidentInfoDtoEntity {
    /**
     * 
     * @type {ResponseProjectUnwantedIncidentInfoDto}
     * @memberof ResponseProjectUnwantedIncidentInfoDtoEntity
     */
    'Entity'?: ResponseProjectUnwantedIncidentInfoDto;
}
/**
 * 
 * @export
 * @interface ResponseProjectUnwantedIncidentInfoDtoEntityResponse
 */
export interface ResponseProjectUnwantedIncidentInfoDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseProjectUnwantedIncidentInfoDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseProjectUnwantedIncidentInfoDto}
     * @memberof ResponseProjectUnwantedIncidentInfoDtoEntityResponse
     */
    'Entity'?: ResponseProjectUnwantedIncidentInfoDto;
}
/**
 * 
 * @export
 * @interface ResponseRepeatableCaseSummaryDto
 */
export interface ResponseRepeatableCaseSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseRepeatableCaseSummaryDto
     */
    'total': number;
    /**
     * Object with interval as key and count as value
     * @type {{ [key: string]: number; }}
     * @memberof ResponseRepeatableCaseSummaryDto
     */
    'byInterval': { [key: string]: number; };
}
/**
 * 
 * @export
 * @interface ResponseSaleSummaryDto
 */
export interface ResponseSaleSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseSaleSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseSaleSummaryDto
     */
    'buyerIsCrmUser': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseSaleSummaryDto
     */
    'buyerIsCompany': number;
}
/**
 * 
 * @export
 * @interface ResponseSidebarItemPinnedDto
 */
export interface ResponseSidebarItemPinnedDto {
    /**
     * 
     * @type {SidebarModel}
     * @memberof ResponseSidebarItemPinnedDto
     */
    'model': SidebarModel;
    /**
     * 
     * @type {string}
     * @memberof ResponseSidebarItemPinnedDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseSidebarItemPinnedDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseSidebarItemPinnedDto
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseSidebarItemPinnedDto
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseSidebarItemPinnedDto
     */
    'name'?: string;
}


/**
 * 
 * @export
 * @interface ResponseSidebarItemPinnedDtoCollection
 */
export interface ResponseSidebarItemPinnedDtoCollection {
    /**
     * 
     * @type {Array<ResponseSidebarItemPinnedDto>}
     * @memberof ResponseSidebarItemPinnedDtoCollection
     */
    'Collection'?: Array<ResponseSidebarItemPinnedDto>;
}
/**
 * 
 * @export
 * @interface ResponseSidebarItemPinnedDtoCollectionResponse
 */
export interface ResponseSidebarItemPinnedDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseSidebarItemPinnedDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseSidebarItemPinnedDto>}
     * @memberof ResponseSidebarItemPinnedDtoCollectionResponse
     */
    'Collection': Array<ResponseSidebarItemPinnedDto>;
}
/**
 * 
 * @export
 * @interface ResponseSidebarItemPinnedDtoEntity
 */
export interface ResponseSidebarItemPinnedDtoEntity {
    /**
     * 
     * @type {ResponseSidebarItemPinnedDto}
     * @memberof ResponseSidebarItemPinnedDtoEntity
     */
    'Entity'?: ResponseSidebarItemPinnedDto;
}
/**
 * 
 * @export
 * @interface ResponseSidebarItemPinnedDtoEntityResponse
 */
export interface ResponseSidebarItemPinnedDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseSidebarItemPinnedDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseSidebarItemPinnedDto}
     * @memberof ResponseSidebarItemPinnedDtoEntityResponse
     */
    'Entity'?: ResponseSidebarItemPinnedDto;
}
/**
 * 
 * @export
 * @interface ResponseTakeoverSupplierOptionDto
 */
export interface ResponseTakeoverSupplierOptionDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'id': number;
    /**
     * 
     * @type {TakeoverSupplierOptionType}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'type': TakeoverSupplierOptionType;
    /**
     * 
     * @type {number}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'forCompany': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'logo': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'info': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'infoLink': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'terms': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'termsLink': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'destination': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'approved': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'countryCode': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'productName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'productsLink'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'b2b': boolean;
    /**
     * 
     * @type {Array<ResponseFileUsageDto>}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'logos'?: Array<ResponseFileUsageDto>;
    /**
     * 
     * @type {ResponseTakeoverSupplierOptionsDocumentsDto}
     * @memberof ResponseTakeoverSupplierOptionDto
     */
    'documents'?: ResponseTakeoverSupplierOptionsDocumentsDto;
}


/**
 * 
 * @export
 * @interface ResponseTakeoverSupplierOptionDtoCollection
 */
export interface ResponseTakeoverSupplierOptionDtoCollection {
    /**
     * 
     * @type {Array<ResponseTakeoverSupplierOptionDto>}
     * @memberof ResponseTakeoverSupplierOptionDtoCollection
     */
    'Collection'?: Array<ResponseTakeoverSupplierOptionDto>;
}
/**
 * 
 * @export
 * @interface ResponseTakeoverSupplierOptionDtoCollectionResponse
 */
export interface ResponseTakeoverSupplierOptionDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseTakeoverSupplierOptionDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseTakeoverSupplierOptionDto>}
     * @memberof ResponseTakeoverSupplierOptionDtoCollectionResponse
     */
    'Collection': Array<ResponseTakeoverSupplierOptionDto>;
}
/**
 * 
 * @export
 * @interface ResponseTakeoverSupplierOptionsDocumentsDto
 */
export interface ResponseTakeoverSupplierOptionsDocumentsDto {
    /**
     * 
     * @type {ResponseFileUsageDto}
     * @memberof ResponseTakeoverSupplierOptionsDocumentsDto
     */
    'termsOfService'?: ResponseFileUsageDto;
    /**
     * 
     * @type {ResponseFileUsageDto}
     * @memberof ResponseTakeoverSupplierOptionsDocumentsDto
     */
    'rightOfWithdrawalInformation'?: ResponseFileUsageDto;
    /**
     * 
     * @type {ResponseFileUsageDto}
     * @memberof ResponseTakeoverSupplierOptionsDocumentsDto
     */
    'rightOfWithdrawalForm'?: ResponseFileUsageDto;
}
/**
 * 
 * @export
 * @interface ResponseTenancyIdnDto
 */
export interface ResponseTenancyIdnDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseTenancyIdnDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseTenancyIdnDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface ResponseTenancySummaryDto
 */
export interface ResponseTenancySummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseTenancySummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseTenancySummaryDto
     */
    'active': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseTenancySummaryDto
     */
    'privateTotal': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseTenancySummaryDto
     */
    'privateActive': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseTenancySummaryDto
     */
    'commercialTotal': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseTenancySummaryDto
     */
    'commercialActive': number;
}
/**
 * 
 * @export
 * @interface ResponseTimezoneDto
 */
export interface ResponseTimezoneDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseTimezoneDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTimezoneDto
     */
    'alternativeName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseTimezoneDto
     */
    'group': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResponseTimezoneDto
     */
    'continentCode': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTimezoneDto
     */
    'continentName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTimezoneDto
     */
    'countryName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTimezoneDto
     */
    'countryCode': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResponseTimezoneDto
     */
    'mainCities': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ResponseTimezoneDto
     */
    'rawOffsetInMinutes': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseTimezoneDto
     */
    'abbreviation': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseTimezoneDto
     */
    'rawFormat': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseTimezoneDto
     */
    'currentTimeOffsetInMinutes': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseTimezoneDto
     */
    'currentTimeFormat': string;
}
/**
 * 
 * @export
 * @interface ResponseTimezoneDtoCollection
 */
export interface ResponseTimezoneDtoCollection {
    /**
     * 
     * @type {Array<ResponseTimezoneDto>}
     * @memberof ResponseTimezoneDtoCollection
     */
    'Collection'?: Array<ResponseTimezoneDto>;
}
/**
 * 
 * @export
 * @interface ResponseTimezoneDtoCollectionResponse
 */
export interface ResponseTimezoneDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseTimezoneDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseTimezoneDto>}
     * @memberof ResponseTimezoneDtoCollectionResponse
     */
    'Collection': Array<ResponseTimezoneDto>;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentDto
 */
export interface ResponseUnwantedIncidentDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentDto
     */
    'ProjectId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentDto
     */
    'ObjectId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentDto
     */
    'CreatorId': number | null;
    /**
     * The types of unwanted incident. Can be more than one
     * @type {Array<string>}
     * @memberof ResponseUnwantedIncidentDto
     */
    'types': Array<ResponseUnwantedIncidentDtoTypesEnum>;
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentProcessDto>}
     * @memberof ResponseUnwantedIncidentDto
     */
    'UnwantedIncidentProcesses'?: Array<ResponseUnwantedIncidentProcessDto>;
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentMarkingDto>}
     * @memberof ResponseUnwantedIncidentDto
     */
    'Markings'?: Array<ResponseUnwantedIncidentMarkingDto>;
    /**
     * 
     * @type {ResponseProjectIDNDto}
     * @memberof ResponseUnwantedIncidentDto
     */
    'Project'?: ResponseProjectIDNDto;
    /**
     * 
     * @type {ResponseObjectIDNDto}
     * @memberof ResponseUnwantedIncidentDto
     */
    'Object'?: ResponseObjectIDNDto;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDtoCreator}
     * @memberof ResponseUnwantedIncidentDto
     */
    'Creator': ResponseUnwantedIncidentProcessDtoCreator | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'reporterName': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'reporterEmail': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'reporterMobile': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'reporterCompany': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'incident': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'immediateAction': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentDto
     */
    'consequence': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentDto
     */
    'serial': number;
}

export const ResponseUnwantedIncidentDtoTypesEnum = {
    PersonInjury: 'person-injury',
    MaterialDamage: 'material-damage',
    Accident: 'accident',
    NearAccident: 'near-accident',
    Observation: 'observation'
} as const;

export type ResponseUnwantedIncidentDtoTypesEnum = typeof ResponseUnwantedIncidentDtoTypesEnum[keyof typeof ResponseUnwantedIncidentDtoTypesEnum];

/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentDtoCollection
 */
export interface ResponseUnwantedIncidentDtoCollection {
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentDto>}
     * @memberof ResponseUnwantedIncidentDtoCollection
     */
    'Collection'?: Array<ResponseUnwantedIncidentDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUnwantedIncidentDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentDtoEntity
 */
export interface ResponseUnwantedIncidentDtoEntity {
    /**
     * 
     * @type {ResponseUnwantedIncidentDto}
     * @memberof ResponseUnwantedIncidentDtoEntity
     */
    'Entity'?: ResponseUnwantedIncidentDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentDtoEntityResponse
 */
export interface ResponseUnwantedIncidentDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUnwantedIncidentDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseUnwantedIncidentDto}
     * @memberof ResponseUnwantedIncidentDtoEntityResponse
     */
    'Entity'?: ResponseUnwantedIncidentDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentDtoPageResponse
 */
export interface ResponseUnwantedIncidentDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUnwantedIncidentDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUnwantedIncidentDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentDto>}
     * @memberof ResponseUnwantedIncidentDtoPageResponse
     */
    'Collection': Array<ResponseUnwantedIncidentDto>;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentMarkingDto
 */
export interface ResponseUnwantedIncidentMarkingDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentMarkingDto
     */
    'FileUsageId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentMarkingDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentMarkingDto
     */
    'icon'?: string;
    /**
     * 
     * @type {GeometryDto}
     * @memberof ResponseUnwantedIncidentMarkingDto
     */
    'geometry': GeometryDto;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentMarkingDto
     */
    'modelId': string;
    /**
     * 
     * @type {ResponseFileUsageDto}
     * @memberof ResponseUnwantedIncidentMarkingDto
     */
    'FileUsage'?: ResponseFileUsageDto;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUnwantedIncidentMarkingDto
     */
    'correct'?: boolean;
    /**
     * 
     * @type {MarkingDataDto}
     * @memberof ResponseUnwantedIncidentMarkingDto
     */
    'data'?: MarkingDataDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessActionCaseDto
 */
export interface ResponseUnwantedIncidentProcessActionCaseDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'deadlineAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'completedAt'?: string;
    /**
     * The id of the User that is the CaseManager for the Action
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'CaseManagerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'description': string;
    /**
     * 
     * @type {ResponseUserCardDto}
     * @memberof ResponseUnwantedIncidentProcessActionCaseDto
     */
    'CaseManager'?: ResponseUserCardDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessActionDto
 */
export interface ResponseUnwantedIncidentProcessActionDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'UnwantedIncidentProcessId': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'CaseId': number | null;
    /**
     * 
     * @type {UnwantedIncidentProcessActionType}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'type': UnwantedIncidentProcessActionType;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDtoCreator}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'Creator': ResponseUnwantedIncidentProcessDtoCreator | null;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessActionCaseDto}
     * @memberof ResponseUnwantedIncidentProcessActionDto
     */
    'Case': ResponseUnwantedIncidentProcessActionCaseDto;
}


/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessActionDtoCollection
 */
export interface ResponseUnwantedIncidentProcessActionDtoCollection {
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentProcessActionDto>}
     * @memberof ResponseUnwantedIncidentProcessActionDtoCollection
     */
    'Collection'?: Array<ResponseUnwantedIncidentProcessActionDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUnwantedIncidentProcessActionDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessActionDtoEntity
 */
export interface ResponseUnwantedIncidentProcessActionDtoEntity {
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessActionDto}
     * @memberof ResponseUnwantedIncidentProcessActionDtoEntity
     */
    'Entity'?: ResponseUnwantedIncidentProcessActionDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessActionDtoEntityResponse
 */
export interface ResponseUnwantedIncidentProcessActionDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUnwantedIncidentProcessActionDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessActionDto}
     * @memberof ResponseUnwantedIncidentProcessActionDtoEntityResponse
     */
    'Entity'?: ResponseUnwantedIncidentProcessActionDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessActionDtoPageResponse
 */
export interface ResponseUnwantedIncidentProcessActionDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUnwantedIncidentProcessActionDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUnwantedIncidentProcessActionDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentProcessActionDto>}
     * @memberof ResponseUnwantedIncidentProcessActionDtoPageResponse
     */
    'Collection': Array<ResponseUnwantedIncidentProcessActionDto>;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessDto
 */
export interface ResponseUnwantedIncidentProcessDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDto
     */
    'UnwantedIncidentId': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessDto
     */
    'CreatorId': number | null;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDtoCreator}
     * @memberof ResponseUnwantedIncidentProcessDto
     */
    'Creator': ResponseUnwantedIncidentProcessDtoCreator | null;
    /**
     * Notes on the unwanted incident
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDto
     */
    'notes': string;
    /**
     * 
     * @type {UnwantedIncidentProcessStatus}
     * @memberof ResponseUnwantedIncidentProcessDto
     */
    'status': UnwantedIncidentProcessStatus;
}


/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessDtoCollection
 */
export interface ResponseUnwantedIncidentProcessDtoCollection {
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentProcessDto>}
     * @memberof ResponseUnwantedIncidentProcessDtoCollection
     */
    'Collection'?: Array<ResponseUnwantedIncidentProcessDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUnwantedIncidentProcessDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessDtoCreator
 */
export interface ResponseUnwantedIncidentProcessDtoCreator {
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'mail': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'CompanyId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'mobile': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'address': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'zip': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'gender': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'dateOfBirth': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'mailNewCase': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'mailNewMessage': boolean;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseUnwantedIncidentProcessDtoCreator
     */
    'Company'?: ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessDtoEntity
 */
export interface ResponseUnwantedIncidentProcessDtoEntity {
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDto}
     * @memberof ResponseUnwantedIncidentProcessDtoEntity
     */
    'Entity'?: ResponseUnwantedIncidentProcessDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessDtoEntityResponse
 */
export interface ResponseUnwantedIncidentProcessDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUnwantedIncidentProcessDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseUnwantedIncidentProcessDto}
     * @memberof ResponseUnwantedIncidentProcessDtoEntityResponse
     */
    'Entity'?: ResponseUnwantedIncidentProcessDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessDtoPageResponse
 */
export interface ResponseUnwantedIncidentProcessDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUnwantedIncidentProcessDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUnwantedIncidentProcessDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentProcessDto>}
     * @memberof ResponseUnwantedIncidentProcessDtoPageResponse
     */
    'Collection': Array<ResponseUnwantedIncidentProcessDto>;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProcessStatusSummaryDto
 */
export interface ResponseUnwantedIncidentProcessStatusSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessStatusSummaryDto
     */
    'new': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessStatusSummaryDto
     */
    'processing': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessStatusSummaryDto
     */
    'pending': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProcessStatusSummaryDto
     */
    'finished': number;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProjectDto
 */
export interface ResponseUnwantedIncidentProjectDto {
    /**
     * 
     * @type {UnwantedIncidentProjectType}
     * @memberof ResponseUnwantedIncidentProjectDto
     */
    'type': UnwantedIncidentProjectType;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentProjectDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentProjectDto
     */
    'name': string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseUnwantedIncidentProjectDto
     */
    'Company': ResponseCompanyIDNDto;
}


/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProjectDtoCollection
 */
export interface ResponseUnwantedIncidentProjectDtoCollection {
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentProjectDto>}
     * @memberof ResponseUnwantedIncidentProjectDtoCollection
     */
    'Collection'?: Array<ResponseUnwantedIncidentProjectDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUnwantedIncidentProjectDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentProjectDtoPageResponse
 */
export interface ResponseUnwantedIncidentProjectDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUnwantedIncidentProjectDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUnwantedIncidentProjectDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentProjectDto>}
     * @memberof ResponseUnwantedIncidentProjectDtoPageResponse
     */
    'Collection': Array<ResponseUnwantedIncidentProjectDto>;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentReportingProjectDto
 */
export interface ResponseUnwantedIncidentReportingProjectDto {
    /**
     * 
     * @type {ResponseUnwantedIncidentReportingProjectModel}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'model': ResponseUnwantedIncidentReportingProjectModel;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'modelId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'companyId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'companyName': string;
    /**
     * 
     * @type {ResponseUnwantedIncidentReportingProjectTypesDto}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'types': ResponseUnwantedIncidentReportingProjectTypesDto;
    /**
     * 
     * @type {ResponseUnwantedIncidentReportingProjectProcessStatusesDto}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'processStatuses': ResponseUnwantedIncidentReportingProjectProcessStatusesDto;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'unwantedIncidents': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'unwantedIncidentActions': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'casesOpen': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectDto
     */
    'casesClosed': number;
}


/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentReportingProjectDtoCollection
 */
export interface ResponseUnwantedIncidentReportingProjectDtoCollection {
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentReportingProjectDto>}
     * @memberof ResponseUnwantedIncidentReportingProjectDtoCollection
     */
    'Collection'?: Array<ResponseUnwantedIncidentReportingProjectDto>;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentReportingProjectDtoCollectionResponse
 */
export interface ResponseUnwantedIncidentReportingProjectDtoCollectionResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUnwantedIncidentReportingProjectDtoCollectionResponse
     */
    'data'?: object;
    /**
     * 
     * @type {Array<ResponseUnwantedIncidentReportingProjectDto>}
     * @memberof ResponseUnwantedIncidentReportingProjectDtoCollectionResponse
     */
    'Collection': Array<ResponseUnwantedIncidentReportingProjectDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ResponseUnwantedIncidentReportingProjectModel = {
    Project: 'Project',
    Object: 'Object'
} as const;

export type ResponseUnwantedIncidentReportingProjectModel = typeof ResponseUnwantedIncidentReportingProjectModel[keyof typeof ResponseUnwantedIncidentReportingProjectModel];


/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentReportingProjectProcessStatusesDto
 */
export interface ResponseUnwantedIncidentReportingProjectProcessStatusesDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectProcessStatusesDto
     */
    'new': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectProcessStatusesDto
     */
    'pending': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectProcessStatusesDto
     */
    'processing': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectProcessStatusesDto
     */
    'finished': number;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentReportingProjectTypesDto
 */
export interface ResponseUnwantedIncidentReportingProjectTypesDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectTypesDto
     */
    'accident': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectTypesDto
     */
    'near-accident': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectTypesDto
     */
    'material-damage': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectTypesDto
     */
    'person-injury': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentReportingProjectTypesDto
     */
    'observation': number;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentSummaryDto
 */
export interface ResponseUnwantedIncidentSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDto
     */
    'inProcess': number;
    /**
     * 
     * @type {ResponseUnwantedIncidentSummaryDtoByType}
     * @memberof ResponseUnwantedIncidentSummaryDto
     */
    'byType': ResponseUnwantedIncidentSummaryDtoByType;
    /**
     * 
     * @type {ResponseUnwantedIncidentSummaryDtoProcessesByStatus}
     * @memberof ResponseUnwantedIncidentSummaryDto
     */
    'processesByStatus': ResponseUnwantedIncidentSummaryDtoProcessesByStatus;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentSummaryDtoByType
 */
export interface ResponseUnwantedIncidentSummaryDtoByType {
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDtoByType
     */
    'personInjury': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDtoByType
     */
    'materialDamage': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDtoByType
     */
    'accident': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDtoByType
     */
    'nearAccident': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDtoByType
     */
    'observation': number;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentSummaryDtoProcessesByStatus
 */
export interface ResponseUnwantedIncidentSummaryDtoProcessesByStatus {
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDtoProcessesByStatus
     */
    'new': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDtoProcessesByStatus
     */
    'processing': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDtoProcessesByStatus
     */
    'pending': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentSummaryDtoProcessesByStatus
     */
    'finished': number;
}
/**
 * 
 * @export
 * @interface ResponseUnwantedIncidentTypeSummaryDto
 */
export interface ResponseUnwantedIncidentTypeSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentTypeSummaryDto
     */
    'personInjury': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentTypeSummaryDto
     */
    'materialDamage': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentTypeSummaryDto
     */
    'accident': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentTypeSummaryDto
     */
    'nearAccident': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUnwantedIncidentTypeSummaryDto
     */
    'observation': number;
}
/**
 * 
 * @export
 * @interface ResponseUserCardDto
 */
export interface ResponseUserCardDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserCardDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'mail': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserCardDto
     */
    'CompanyId': number | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'mobile': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'address': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'zip': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'state': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'gender': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserCardDto
     */
    'dateOfBirth': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserCardDto
     */
    'mailNewCase': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserCardDto
     */
    'mailNewMessage': boolean;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseUserCardDto
     */
    'Company'?: ResponseCompanyIDNDto;
}
/**
 * 
 * @export
 * @interface ResponseUserDto
 */
export interface ResponseUserDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    'CompanyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'mail': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'avatar'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserDto
     */
    'mailNewCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserDto
     */
    'mailNewMessage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserDto
     */
    'mailNewRequest'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserDto
     */
    'mailChecklist'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserDto
     */
    'digestHourly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserDto
     */
    'digestDaily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserDto
     */
    'pushNotifications'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    'mailVerified'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    'infoVerified'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'hmsCard'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'hmsCardExpire'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'locale'?: ResponseUserDtoLocaleEnum;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    'paymentStatus'?: ResponseUserDtoPaymentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'contactName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'contactPhone'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'contactEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'phonePrivate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'phoneWork'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    'lastActive'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    'lastLogin'?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserDto
     */
    'regDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserDto
     */
    'startPage'?: string;
    /**
     * 
     * @type {ResponseCompanyIDNDto}
     * @memberof ResponseUserDto
     */
    'Company'?: ResponseCompanyIDNDto;
}

export const ResponseUserDtoLocaleEnum = {
    Nb: 'nb',
    En: 'en',
    Da: 'da',
    De: 'de',
    El: 'el',
    Et: 'et',
    Lt: 'lt',
    Lv: 'lv',
    Pl: 'pl',
    Pt: 'pt',
    Sq: 'sq',
    Sv: 'sv'
} as const;

export type ResponseUserDtoLocaleEnum = typeof ResponseUserDtoLocaleEnum[keyof typeof ResponseUserDtoLocaleEnum];
export const ResponseUserDtoPaymentStatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ResponseUserDtoPaymentStatusEnum = typeof ResponseUserDtoPaymentStatusEnum[keyof typeof ResponseUserDtoPaymentStatusEnum];

/**
 * 
 * @export
 * @interface ResponseUserDtoEntity
 */
export interface ResponseUserDtoEntity {
    /**
     * 
     * @type {ResponseUserDto}
     * @memberof ResponseUserDtoEntity
     */
    'Entity'?: ResponseUserDto;
}
/**
 * 
 * @export
 * @interface ResponseUserDtoEntityResponse
 */
export interface ResponseUserDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUserDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseUserDto}
     * @memberof ResponseUserDtoEntityResponse
     */
    'Entity'?: ResponseUserDto;
}
/**
 * 
 * @export
 * @interface ResponseUserFavoriteDto
 */
export interface ResponseUserFavoriteDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseUserFavoriteDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserFavoriteDto
     */
    'UserId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserFavoriteDto
     */
    'modelName': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserFavoriteDto
     */
    'modelId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserFavoriteDto
     */
    'name'?: string;
    /**
     * 
     * @type {ResponseUserIdnDto}
     * @memberof ResponseUserFavoriteDto
     */
    'User': ResponseUserIdnDto;
}
/**
 * 
 * @export
 * @interface ResponseUserIdnDto
 */
export interface ResponseUserIdnDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseUserIdnDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserIdnDto
     */
    'name': string | null;
}
/**
 * 
 * @export
 * @interface ResponseUserInviteDto
 */
export interface ResponseUserInviteDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserInviteDto
     */
    'CreatorId': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserInviteDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'CRMUserId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'acceptedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'declinedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'emailId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserInviteDto
     */
    'language': string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserInviteDto
     */
    'connectUser': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserInviteDto
     */
    'hasExpired': boolean;
}
/**
 * 
 * @export
 * @interface ResponseUserInviteDtoEntity
 */
export interface ResponseUserInviteDtoEntity {
    /**
     * 
     * @type {ResponseUserInviteDto}
     * @memberof ResponseUserInviteDtoEntity
     */
    'Entity'?: ResponseUserInviteDto;
}
/**
 * 
 * @export
 * @interface ResponseUserInviteDtoEntityResponse
 */
export interface ResponseUserInviteDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUserInviteDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseUserInviteDto}
     * @memberof ResponseUserInviteDtoEntityResponse
     */
    'Entity'?: ResponseUserInviteDto;
}
/**
 * 
 * @export
 * @interface ResponseUserNotificationDto
 */
export interface ResponseUserNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUserNotificationDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserNotificationDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserNotificationDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserNotificationDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserNotificationDto
     */
    'UserId': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserNotificationDto
     */
    'sentAt': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserNotificationDto
     */
    'read': boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserNotificationDto
     */
    'sentReference': string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserNotificationDto
     */
    'translatedText': string;
    /**
     * 
     * @type {UserNotificationDataDto}
     * @memberof ResponseUserNotificationDto
     */
    'data': UserNotificationDataDto;
}
/**
 * 
 * @export
 * @interface ResponseUserNotificationDtoCollection
 */
export interface ResponseUserNotificationDtoCollection {
    /**
     * 
     * @type {Array<ResponseUserNotificationDto>}
     * @memberof ResponseUserNotificationDtoCollection
     */
    'Collection'?: Array<ResponseUserNotificationDto>;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUserNotificationDtoCollection
     */
    'meta'?: PageMetaDto;
}
/**
 * 
 * @export
 * @interface ResponseUserNotificationDtoEntity
 */
export interface ResponseUserNotificationDtoEntity {
    /**
     * 
     * @type {ResponseUserNotificationDto}
     * @memberof ResponseUserNotificationDtoEntity
     */
    'Entity'?: ResponseUserNotificationDto;
}
/**
 * 
 * @export
 * @interface ResponseUserNotificationDtoEntityResponse
 */
export interface ResponseUserNotificationDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUserNotificationDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseUserNotificationDto}
     * @memberof ResponseUserNotificationDtoEntityResponse
     */
    'Entity'?: ResponseUserNotificationDto;
}
/**
 * 
 * @export
 * @interface ResponseUserNotificationDtoPageResponse
 */
export interface ResponseUserNotificationDtoPageResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUserNotificationDtoPageResponse
     */
    'data'?: object;
    /**
     * 
     * @type {PageMetaDto}
     * @memberof ResponseUserNotificationDtoPageResponse
     */
    'meta': PageMetaDto;
    /**
     * 
     * @type {Array<ResponseUserNotificationDto>}
     * @memberof ResponseUserNotificationDtoPageResponse
     */
    'Collection': Array<ResponseUserNotificationDto>;
}
/**
 * 
 * @export
 * @interface ResponseUserProfileDto
 */
export interface ResponseUserProfileDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'deletedAt': string | null;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserProfileDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'hmsCardExpire'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserProfileDto
     */
    'CompanyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'mail': string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'zip'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'locale'?: ResponseUserProfileDtoLocaleEnum;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserProfileDto
     */
    'regdate'?: number;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'hmsCard'?: string;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserProfileDto
     */
    'paymentStatus'?: ResponseUserProfileDtoPaymentStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserProfileDto
     */
    'mailNewCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserProfileDto
     */
    'mailNewMessage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserProfileDto
     */
    'mailNewRequest'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserProfileDto
     */
    'mailChecklist'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserProfileDto
     */
    'showTextOnButtons'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserProfileDto
     */
    'useExtendedFileViewer'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'startPage'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'ical'?: string;
    /**
     * 
     * @type {string}
     * @memberof ResponseUserProfileDto
     */
    'timezone'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserProfileDto
     */
    'digestHourly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserProfileDto
     */
    'digestDaily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ResponseUserProfileDto
     */
    'pushNotifications'?: boolean;
    /**
     * 
     * @type {Array<ResponseUserFavoriteDto>}
     * @memberof ResponseUserProfileDto
     */
    'userFavorites': Array<ResponseUserFavoriteDto>;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserProfileDto
     */
    'tenancyCount': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserProfileDto
     */
    'agreementsCount': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserProfileDto
     */
    'salesCount': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserProfileDto
     */
    'addonContractorCount': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserProfileDto
     */
    'projectMOMCount': number;
}

export const ResponseUserProfileDtoLocaleEnum = {
    Nb: 'nb',
    En: 'en',
    Da: 'da',
    De: 'de',
    El: 'el',
    Et: 'et',
    Lt: 'lt',
    Lv: 'lv',
    Pl: 'pl',
    Pt: 'pt',
    Sq: 'sq',
    Sv: 'sv'
} as const;

export type ResponseUserProfileDtoLocaleEnum = typeof ResponseUserProfileDtoLocaleEnum[keyof typeof ResponseUserProfileDtoLocaleEnum];
export const ResponseUserProfileDtoPaymentStatusEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ResponseUserProfileDtoPaymentStatusEnum = typeof ResponseUserProfileDtoPaymentStatusEnum[keyof typeof ResponseUserProfileDtoPaymentStatusEnum];

/**
 * 
 * @export
 * @interface ResponseUserProfileDtoEntity
 */
export interface ResponseUserProfileDtoEntity {
    /**
     * 
     * @type {ResponseUserProfileDto}
     * @memberof ResponseUserProfileDtoEntity
     */
    'Entity'?: ResponseUserProfileDto;
}
/**
 * 
 * @export
 * @interface ResponseUserProfileDtoEntityResponse
 */
export interface ResponseUserProfileDtoEntityResponse {
    /**
     * 
     * @type {object}
     * @memberof ResponseUserProfileDtoEntityResponse
     */
    'data'?: object;
    /**
     * 
     * @type {ResponseUserProfileDto}
     * @memberof ResponseUserProfileDtoEntityResponse
     */
    'Entity'?: ResponseUserProfileDto;
}
/**
 * 
 * @export
 * @interface ResponseYearMonthValueDto
 */
export interface ResponseYearMonthValueDto {
    /**
     * 
     * @type {number}
     * @memberof ResponseYearMonthValueDto
     */
    'year': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseYearMonthValueDto
     */
    'month': number;
    /**
     * 
     * @type {number}
     * @memberof ResponseYearMonthValueDto
     */
    'value': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SidebarModel = {
    Sidebar: 'sidebar',
    Bookmark: 'bookmark'
} as const;

export type SidebarModel = typeof SidebarModel[keyof typeof SidebarModel];


/**
 * The ACL type. Only allows private.
 * @export
 * @enum {string}
 */

export const SigningPolicyACL = {
    Private: 'private'
} as const;

export type SigningPolicyACL = typeof SigningPolicyACL[keyof typeof SigningPolicyACL];


/**
 * 
 * @export
 * @interface SwapFileDto
 */
export interface SwapFileDto {
    /**
     * 
     * @type {number}
     * @memberof SwapFileDto
     */
    'FileId': number;
}
/**
 * 
 * @export
 * @interface TakeoverSupplierOptionSubscriberContactPersonDto
 */
export interface TakeoverSupplierOptionSubscriberContactPersonDto {
    /**
     * 
     * @type {string}
     * @memberof TakeoverSupplierOptionSubscriberContactPersonDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TakeoverSupplierOptionSubscriberContactPersonDto
     */
    'mail': string;
    /**
     * 
     * @type {string}
     * @memberof TakeoverSupplierOptionSubscriberContactPersonDto
     */
    'phone': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TakeoverSupplierOptionType = {
    Power: 'power',
    Gas: 'gas'
} as const;

export type TakeoverSupplierOptionType = typeof TakeoverSupplierOptionType[keyof typeof TakeoverSupplierOptionType];


/**
 * 
 * @export
 * @interface TenancyPrintDto
 */
export interface TenancyPrintDto {
    /**
     * 
     * @type {string}
     * @memberof TenancyPrintDto
     */
    'name': string;
    /**
     * 
     * @type {Array<ResponseUserCardDto>}
     * @memberof TenancyPrintDto
     */
    'Users'?: Array<ResponseUserCardDto>;
    /**
     * 
     * @type {Array<CrmUserPrintDto>}
     * @memberof TenancyPrintDto
     */
    'CRMUsers'?: Array<CrmUserPrintDto>;
}
/**
 * 
 * @export
 * @interface UnwantedIncidentMarkingDto
 */
export interface UnwantedIncidentMarkingDto {
    /**
     * 
     * @type {number}
     * @memberof UnwantedIncidentMarkingDto
     */
    'FileUsageId': number;
    /**
     * 
     * @type {string}
     * @memberof UnwantedIncidentMarkingDto
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnwantedIncidentMarkingDto
     */
    'icon'?: string;
    /**
     * 
     * @type {GeometryDto}
     * @memberof UnwantedIncidentMarkingDto
     */
    'geometry': GeometryDto;
    /**
     * 
     * @type {boolean}
     * @memberof UnwantedIncidentMarkingDto
     */
    'correct'?: boolean;
    /**
     * 
     * @type {MarkingDataDto}
     * @memberof UnwantedIncidentMarkingDto
     */
    'data'?: MarkingDataDto;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UnwantedIncidentPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;

export type UnwantedIncidentPageOptionsOrderBy = typeof UnwantedIncidentPageOptionsOrderBy[keyof typeof UnwantedIncidentPageOptionsOrderBy];


/**
 * The type of action.
 * @export
 * @enum {string}
 */

export const UnwantedIncidentProcessActionType = {
    Immediate: 'immediate',
    Corrective: 'corrective',
    Preventive: 'preventive'
} as const;

export type UnwantedIncidentProcessActionType = typeof UnwantedIncidentProcessActionType[keyof typeof UnwantedIncidentProcessActionType];


/**
 * The status of this unwanted incident process.
 * @export
 * @enum {string}
 */

export const UnwantedIncidentProcessStatus = {
    New: 'new',
    Processing: 'processing',
    Pending: 'pending',
    Finished: 'finished'
} as const;

export type UnwantedIncidentProcessStatus = typeof UnwantedIncidentProcessStatus[keyof typeof UnwantedIncidentProcessStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const UnwantedIncidentProjectPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    Name: 'name'
} as const;

export type UnwantedIncidentProjectPageOptionsOrderBy = typeof UnwantedIncidentProjectPageOptionsOrderBy[keyof typeof UnwantedIncidentProjectPageOptionsOrderBy];


/**
 * 
 * @export
 * @enum {string}
 */

export const UnwantedIncidentProjectType = {
    Project: 'project',
    Object: 'object'
} as const;

export type UnwantedIncidentProjectType = typeof UnwantedIncidentProjectType[keyof typeof UnwantedIncidentProjectType];


/**
 * 
 * @export
 * @enum {string}
 */

export const UnwantedIncidentType = {
    PersonInjury: 'person-injury',
    MaterialDamage: 'material-damage',
    Accident: 'accident',
    NearAccident: 'near-accident',
    Observation: 'observation'
} as const;

export type UnwantedIncidentType = typeof UnwantedIncidentType[keyof typeof UnwantedIncidentType];


/**
 * 
 * @export
 * @interface UpdateAddressDto
 */
export interface UpdateAddressDto {
    /**
     * 
     * @type {AddressType}
     * @memberof UpdateAddressDto
     */
    'type'?: AddressType;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'line1'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'postal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'countryCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAddressDto
     */
    'placeId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAddressDto
     */
    'latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAddressDto
     */
    'longitude'?: number | null;
}


/**
 * 
 * @export
 * @interface UpdateArticleDto
 */
export interface UpdateArticleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleDto
     */
    'FolderId': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleDto
     */
    'lead'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateArticleDto
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface UpdateChecklistDto
 */
export interface UpdateChecklistDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistDto
     */
    'CompanyId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistDto
     */
    'CategoryId': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistDto
     */
    'ObjectId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistDto
     */
    'CaseManagerId': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistDto
     */
    'ProjectId': number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistDto
     */
    'FieldId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistDto
     */
    'ObjectFieldId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistDto
     */
    'ApartmentId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistDto
     */
    'description': string | null;
}
/**
 * 
 * @export
 * @interface UpdateChecklistItemDto
 */
export interface UpdateChecklistItemDto {
    /**
     * 
     * @type {ChecklistItemType}
     * @memberof UpdateChecklistItemDto
     */
    'checklistItemType'?: ChecklistItemType;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistItemDto
     */
    'value': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistItemDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateChecklistItemDto
     */
    'notApplicable': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistItemDto
     */
    'note': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateChecklistItemDto
     */
    'selectOptions'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface UpdateChecklistSectionDto
 */
export interface UpdateChecklistSectionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistSectionDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistSectionDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistSectionDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateChecklistTemplateDto
 */
export interface UpdateChecklistTemplateDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistTemplateDto
     */
    'CategoryId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistTemplateDto
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateChecklistTemplateDto
     */
    'customerAccess': boolean;
}
/**
 * 
 * @export
 * @interface UpdateChecklistTemplateItemDto
 */
export interface UpdateChecklistTemplateItemDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistTemplateItemDto
     */
    'CategoryId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistTemplateItemDto
     */
    'sortOrder': number;
    /**
     * 
     * @type {object}
     * @memberof UpdateChecklistTemplateItemDto
     */
    'data': object;
    /**
     * 
     * @type {number}
     * @memberof UpdateChecklistTemplateItemDto
     */
    'fieldId': number;
    /**
     * 
     * @type {ChecklistItemType}
     * @memberof UpdateChecklistTemplateItemDto
     */
    'checklistItemType'?: ChecklistItemType;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistTemplateItemDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistTemplateItemDto
     */
    'description': string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateChecklistTemplateItemDto
     */
    'selectOptions'?: Array<string> | null;
}


/**
 * 
 * @export
 * @interface UpdateChecklistTemplateSectionDto
 */
export interface UpdateChecklistTemplateSectionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistTemplateSectionDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateChecklistTemplateSectionDto
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface UpdateCompanyConsentCompanyDto
 */
export interface UpdateCompanyConsentCompanyDto {
    /**
     * If admins are allowed to consent
     * @type {boolean}
     * @memberof UpdateCompanyConsentCompanyDto
     */
    'allowAdmins': boolean;
    /**
     * If all contacts must consent before the consent is considered approved
     * @type {boolean}
     * @memberof UpdateCompanyConsentCompanyDto
     */
    'requireAllContacts': boolean;
}
/**
 * 
 * @export
 * @interface UpdateCrmUserDto
 */
export interface UpdateCrmUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'dateOfBirth'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'mobile'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'postal'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCrmUserDto
     */
    'language'?: string;
}
/**
 * 
 * @export
 * @interface UpdateFieldDto
 */
export interface UpdateFieldDto {
    /**
     * A hexadecimal color with leading # like your browser likes
     * @type {string}
     * @memberof UpdateFieldDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFieldDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFieldDto
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface UpdateFileUsageDto
 */
export interface UpdateFileUsageDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateFileUsageDto
     */
    'fileSort': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFileUsageDto
     */
    'fileName': string;
}
/**
 * 
 * @export
 * @interface UpdateFolderDto
 */
export interface UpdateFolderDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFolderDto
     */
    'ParentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFolderDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateFolderDto
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface UpdateHelpUrlDto
 */
export interface UpdateHelpUrlDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateHelpUrlDto
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHelpUrlDto
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateHelpUrlDto
     */
    'language': string;
}
/**
 * 
 * @export
 * @interface UpdateModelTraitDto
 */
export interface UpdateModelTraitDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateModelTraitDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateModelTraitDto
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateModelTraitDto
     */
    'public'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateSidebarItemPinnedDto
 */
export interface UpdateSidebarItemPinnedDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateSidebarItemPinnedDto
     */
    'sortOrder': number;
}
/**
 * 
 * @export
 * @interface UpdateUnwantedIncidentProcessActionDto
 */
export interface UpdateUnwantedIncidentProcessActionDto {
    /**
     * 
     * @type {UnwantedIncidentProcessActionType}
     * @memberof UpdateUnwantedIncidentProcessActionDto
     */
    'type': UnwantedIncidentProcessActionType;
}


/**
 * 
 * @export
 * @interface UpdateUnwantedIncidentProcessDto
 */
export interface UpdateUnwantedIncidentProcessDto {
    /**
     * Notes on the unwanted incident
     * @type {string}
     * @memberof UpdateUnwantedIncidentProcessDto
     */
    'notes': string;
    /**
     * 
     * @type {UnwantedIncidentProcessStatus}
     * @memberof UpdateUnwantedIncidentProcessDto
     */
    'status': UnwantedIncidentProcessStatus;
}


/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateUserDto
     */
    'CompanyId'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'mail'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'dateOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'zip'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'locale'?: UpdateUserDtoLocaleEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'hmsCard'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'hmsCardExpire'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'mailNewCase'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'mailNewMessage'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'mailNewRequest'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'mailChecklist'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'showTextOnButtons'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'useExtendedFileViewer'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'digestHourly'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'digestDaily'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserDto
     */
    'pushNotifications'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateUserDto
     */
    'status'?: UpdateUserDtoStatusEnum;
}

export const UpdateUserDtoLocaleEnum = {
    Nb: 'nb',
    En: 'en',
    Da: 'da',
    De: 'de',
    El: 'el',
    Et: 'et',
    Lt: 'lt',
    Lv: 'lv',
    Pl: 'pl',
    Pt: 'pt',
    Sq: 'sq',
    Sv: 'sv'
} as const;

export type UpdateUserDtoLocaleEnum = typeof UpdateUserDtoLocaleEnum[keyof typeof UpdateUserDtoLocaleEnum];
export const UpdateUserDtoStatusEnum = {
    NUMBER_MINUS_1: -1,
    NUMBER_0: 0,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type UpdateUserDtoStatusEnum = typeof UpdateUserDtoStatusEnum[keyof typeof UpdateUserDtoStatusEnum];

/**
 * 
 * @export
 * @interface UpdateUserNotificationDto
 */
export interface UpdateUserNotificationDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserNotificationDto
     */
    'read': boolean;
}
/**
 * 
 * @export
 * @interface UserNotificationDataDto
 */
export interface UserNotificationDataDto {
    /**
     * 
     * @type {UserNotificationModel}
     * @memberof UserNotificationDataDto
     */
    'model': UserNotificationModel;
    /**
     * 
     * @type {UserNotificationType}
     * @memberof UserNotificationDataDto
     */
    'type': UserNotificationType;
    /**
     * 
     * @type {number}
     * @memberof UserNotificationDataDto
     */
    'UserId': number;
    /**
     * 
     * @type {Array<UserNotificationDataDtoChangesInner>}
     * @memberof UserNotificationDataDto
     */
    'changes': Array<UserNotificationDataDtoChangesInner>;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationDataDto
     */
    'modelId': string;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationDataDto
     */
    'modelTitle': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationDataDto
     */
    'message': string | null;
}


/**
 * 
 * @export
 * @interface UserNotificationDataDtoChangesInner
 */
export interface UserNotificationDataDtoChangesInner {
    /**
     * 
     * @type {string}
     * @memberof UserNotificationDataDtoChangesInner
     */
    'field'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationDataDtoChangesInner
     */
    'previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserNotificationDataDtoChangesInner
     */
    'current'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserNotificationModel = {
    Case: 'Case'
} as const;

export type UserNotificationModel = typeof UserNotificationModel[keyof typeof UserNotificationModel];


/**
 * 
 * @export
 * @enum {string}
 */

export const UserNotificationPageOptionsOrderBy = {
    CreatedAt: 'createdAt',
    UpdatedAt: 'updatedAt'
} as const;

export type UserNotificationPageOptionsOrderBy = typeof UserNotificationPageOptionsOrderBy[keyof typeof UserNotificationPageOptionsOrderBy];


/**
 * 
 * @export
 * @enum {string}
 */

export const UserNotificationType = {
    CaseFileUsageCreate: 'CaseFileUsageCreate',
    CaseFileUsageDestroy: 'CaseFileUsageDestroy',
    CaseNew: 'CaseNew',
    CaseFinished: 'CaseFinished',
    CaseReopened: 'CaseReopened',
    System: 'System',
    CaseManagerMessage: 'CaseManagerMessage',
    ContractorMessage: 'ContractorMessage',
    ClientMessage: 'ClientMessage',
    StatusChangeAccepted: 'StatusChangeAccepted',
    StatusChangeDeclined: 'StatusChangeDeclined',
    StatusChangeCompleted: 'StatusChangeCompleted',
    AddedContractor: 'AddedContractor',
    RemovedContractor: 'RemovedContractor',
    StatusChangeFinished: 'StatusChangeFinished',
    StatusChangeReopened: 'StatusChangeReopened',
    CaseChanged: 'CaseChanged'
} as const;

export type UserNotificationType = typeof UserNotificationType[keyof typeof UserNotificationType];


/**
 * 
 * @export
 * @interface UserPrintDto
 */
export interface UserPrintDto {
    /**
     * 
     * @type {string}
     * @memberof UserPrintDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserPrintDto
     */
    'mail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserPrintDto
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {CompanyPrintDto}
     * @memberof UserPrintDto
     */
    'Company'?: CompanyPrintDto;
}

/**
 * AddonApi - axios parameter creator
 * @export
 */
export const AddonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a ModelTrait for Addon
         * @summary 
         * @param {string} addonId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonCreate: async (addonId: string, createModelTraitDto: CreateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addonId' is not null or undefined
            assertParamExists('modelTraitControllerAddonCreate', 'addonId', addonId)
            // verify required parameter 'createModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerAddonCreate', 'createModelTraitDto', createModelTraitDto)
            const localVarPath = `/addon/{addonId}/model-traits`
                .replace(`{${"addonId"}}`, encodeURIComponent(String(addonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ModelTrait for Addon
         * @summary 
         * @param {string} addonId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonDelete: async (addonId: string, modelTraitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addonId' is not null or undefined
            assertParamExists('modelTraitControllerAddonDelete', 'addonId', addonId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerAddonDelete', 'modelTraitId', modelTraitId)
            const localVarPath = `/addon/{addonId}/model-traits/{modelTraitId}`
                .replace(`{${"addonId"}}`, encodeURIComponent(String(addonId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all traits on Addon by modelId
         * @summary 
         * @param {string} addonId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonReadCollection: async (addonId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addonId' is not null or undefined
            assertParamExists('modelTraitControllerAddonReadCollection', 'addonId', addonId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('modelTraitControllerAddonReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('modelTraitControllerAddonReadCollection', 'limit', limit)
            const localVarPath = `/addon/{addonId}/model-traits`
                .replace(`{${"addonId"}}`, encodeURIComponent(String(addonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for Addon filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonReadCollectionOnModel: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerAddonReadCollectionOnModel', 'name', name)
            const localVarPath = `/addon/model-traits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for Addon
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonReadNameCollection: async (name: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerAddonReadNameCollection', 'name', name)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('modelTraitControllerAddonReadNameCollection', 'value', value)
            const localVarPath = `/addon/model-traits/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ModelTrait for Addon
         * @summary 
         * @param {string} addonId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonUpdate: async (addonId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addonId' is not null or undefined
            assertParamExists('modelTraitControllerAddonUpdate', 'addonId', addonId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerAddonUpdate', 'modelTraitId', modelTraitId)
            // verify required parameter 'updateModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerAddonUpdate', 'updateModelTraitDto', updateModelTraitDto)
            const localVarPath = `/addon/{addonId}/model-traits/{modelTraitId}`
                .replace(`{${"addonId"}}`, encodeURIComponent(String(addonId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddonApi - functional programming interface
 * @export
 */
export const AddonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddonApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a ModelTrait for Addon
         * @summary 
         * @param {string} addonId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerAddonCreate(addonId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerAddonCreate(addonId, createModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ModelTrait for Addon
         * @summary 
         * @param {string} addonId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerAddonDelete(addonId: string, modelTraitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerAddonDelete(addonId, modelTraitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all traits on Addon by modelId
         * @summary 
         * @param {string} addonId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerAddonReadCollection(addonId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerAddonReadCollection(addonId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for Addon filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerAddonReadCollectionOnModel(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerAddonReadCollectionOnModel(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for Addon
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerAddonReadNameCollection(name: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerAddonReadNameCollection(name, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a ModelTrait for Addon
         * @summary 
         * @param {string} addonId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerAddonUpdate(addonId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerAddonUpdate(addonId, modelTraitId, updateModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddonApi - factory interface
 * @export
 */
export const AddonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddonApiFp(configuration)
    return {
        /**
         * Create a ModelTrait for Addon
         * @summary 
         * @param {string} addonId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonCreate(addonId: string, createModelTraitDto: CreateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerAddonCreate(addonId, createModelTraitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ModelTrait for Addon
         * @summary 
         * @param {string} addonId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonDelete(addonId: string, modelTraitId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.modelTraitControllerAddonDelete(addonId, modelTraitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all traits on Addon by modelId
         * @summary 
         * @param {string} addonId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonReadCollection(addonId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseModelTraitDtoPageResponse> {
            return localVarFp.modelTraitControllerAddonReadCollection(addonId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for Addon filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonReadCollectionOnModel(name: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerAddonReadCollectionOnModel(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for Addon
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonReadNameCollection(name: string, value: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerAddonReadNameCollection(name, value, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ModelTrait for Addon
         * @summary 
         * @param {string} addonId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerAddonUpdate(addonId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerAddonUpdate(addonId, modelTraitId, updateModelTraitDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddonApi - object-oriented interface
 * @export
 * @class AddonApi
 * @extends {BaseAPI}
 */
export class AddonApi extends BaseAPI {
    /**
     * Create a ModelTrait for Addon
     * @summary 
     * @param {string} addonId 
     * @param {CreateModelTraitDto} createModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public modelTraitControllerAddonCreate(addonId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).modelTraitControllerAddonCreate(addonId, createModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ModelTrait for Addon
     * @summary 
     * @param {string} addonId 
     * @param {number} modelTraitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public modelTraitControllerAddonDelete(addonId: string, modelTraitId: number, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).modelTraitControllerAddonDelete(addonId, modelTraitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all traits on Addon by modelId
     * @summary 
     * @param {string} addonId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public modelTraitControllerAddonReadCollection(addonId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).modelTraitControllerAddonReadCollection(addonId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for Addon filtered by name
     * @summary 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public modelTraitControllerAddonReadCollectionOnModel(name: string, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).modelTraitControllerAddonReadCollectionOnModel(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for Addon
     * @summary 
     * @param {string} name 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public modelTraitControllerAddonReadNameCollection(name: string, value: string, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).modelTraitControllerAddonReadNameCollection(name, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ModelTrait for Addon
     * @summary 
     * @param {string} addonId 
     * @param {number} modelTraitId 
     * @param {UpdateModelTraitDto} updateModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApi
     */
    public modelTraitControllerAddonUpdate(addonId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig) {
        return AddonApiFp(this.configuration).modelTraitControllerAddonUpdate(addonId, modelTraitId, updateModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AddonApartmentApi - axios parameter creator
 * @export
 */
export const AddonApartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a collection of AddonApartments
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalAddonApartmentControllerReadCollection: async (projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalAddonApartmentControllerReadCollection', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalAddonApartmentControllerReadCollection', 'apartmentId', apartmentId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('portalAddonApartmentControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('portalAddonApartmentControllerReadCollection', 'limit', limit)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/addon-apartments`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddonApartmentApi - functional programming interface
 * @export
 */
export const AddonApartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddonApartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a collection of AddonApartments
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalAddonApartmentControllerReadCollection(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalAddonApartmentDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalAddonApartmentControllerReadCollection(projectId, apartmentId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddonApartmentApi - factory interface
 * @export
 */
export const AddonApartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddonApartmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a collection of AddonApartments
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalAddonApartmentControllerReadCollection(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponsePortalAddonApartmentDtoPageResponse> {
            return localVarFp.portalAddonApartmentControllerReadCollection(projectId, apartmentId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddonApartmentApi - object-oriented interface
 * @export
 * @class AddonApartmentApi
 * @extends {BaseAPI}
 */
export class AddonApartmentApi extends BaseAPI {
    /**
     * 
     * @summary Get a collection of AddonApartments
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonApartmentApi
     */
    public portalAddonApartmentControllerReadCollection(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return AddonApartmentApiFp(this.configuration).portalAddonApartmentControllerReadCollection(projectId, apartmentId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AddonOrderApi - axios parameter creator
 * @export
 */
export const AddonOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a collection of AddonOrders
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalAddonOrderControllerReadCollection: async (projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalAddonOrderControllerReadCollection', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalAddonOrderControllerReadCollection', 'apartmentId', apartmentId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('portalAddonOrderControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('portalAddonOrderControllerReadCollection', 'limit', limit)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/addon-orders`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddonOrderApi - functional programming interface
 * @export
 */
export const AddonOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddonOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a collection of AddonOrders
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalAddonOrderControllerReadCollection(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalAddonOrderDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalAddonOrderControllerReadCollection(projectId, apartmentId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddonOrderApi - factory interface
 * @export
 */
export const AddonOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddonOrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a collection of AddonOrders
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalAddonOrderControllerReadCollection(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponsePortalAddonOrderDtoPageResponse> {
            return localVarFp.portalAddonOrderControllerReadCollection(projectId, apartmentId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddonOrderApi - object-oriented interface
 * @export
 * @class AddonOrderApi
 * @extends {BaseAPI}
 */
export class AddonOrderApi extends BaseAPI {
    /**
     * 
     * @summary Get a collection of AddonOrders
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddonOrderApi
     */
    public portalAddonOrderControllerReadCollection(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return AddonOrderApiFp(this.configuration).portalAddonOrderControllerReadCollection(projectId, apartmentId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApartmentApi - axios parameter creator
 * @export
 */
export const ApartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an Address for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerApartmentCreate: async (apartmentId: string, createAddressDto: CreateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('addressControllerApartmentCreate', 'apartmentId', apartmentId)
            // verify required parameter 'createAddressDto' is not null or undefined
            assertParamExists('addressControllerApartmentCreate', 'createAddressDto', createAddressDto)
            const localVarPath = `/apartment/{apartmentId}/addresses`
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Address for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerApartmentDelete: async (apartmentId: string, addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('addressControllerApartmentDelete', 'apartmentId', apartmentId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerApartmentDelete', 'addressId', addressId)
            const localVarPath = `/apartment/{apartmentId}/addresses/{addressId}`
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all Addresses for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerApartmentReadCollection: async (apartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('addressControllerApartmentReadCollection', 'apartmentId', apartmentId)
            const localVarPath = `/apartment/{apartmentId}/addresses`
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an Address for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerApartmentUpdate: async (apartmentId: string, addressId: string, updateAddressDto: UpdateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('addressControllerApartmentUpdate', 'apartmentId', apartmentId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerApartmentUpdate', 'addressId', addressId)
            // verify required parameter 'updateAddressDto' is not null or undefined
            assertParamExists('addressControllerApartmentUpdate', 'updateAddressDto', updateAddressDto)
            const localVarPath = `/apartment/{apartmentId}/addresses/{addressId}`
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApartmentApi - functional programming interface
 * @export
 */
export const ApartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an Address for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerApartmentCreate(apartmentId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerApartmentCreate(apartmentId, createAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an Address for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerApartmentDelete(apartmentId: string, addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerApartmentDelete(apartmentId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all Addresses for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerApartmentReadCollection(apartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerApartmentReadCollection(apartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an Address for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerApartmentUpdate(apartmentId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerApartmentUpdate(apartmentId, addressId, updateAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApartmentApi - factory interface
 * @export
 */
export const ApartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApartmentApiFp(configuration)
    return {
        /**
         * Create an Address for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerApartmentCreate(apartmentId: string, createAddressDto: CreateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerApartmentCreate(apartmentId, createAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Address for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerApartmentDelete(apartmentId: string, addressId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.addressControllerApartmentDelete(apartmentId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all Addresses for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerApartmentReadCollection(apartmentId: string, options?: any): AxiosPromise<ResponseAddressDtoCollectionResponse> {
            return localVarFp.addressControllerApartmentReadCollection(apartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an Address for Apartment
         * @summary 
         * @param {string} apartmentId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerApartmentUpdate(apartmentId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerApartmentUpdate(apartmentId, addressId, updateAddressDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApartmentApi - object-oriented interface
 * @export
 * @class ApartmentApi
 * @extends {BaseAPI}
 */
export class ApartmentApi extends BaseAPI {
    /**
     * Create an Address for Apartment
     * @summary 
     * @param {string} apartmentId 
     * @param {CreateAddressDto} createAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentApi
     */
    public addressControllerApartmentCreate(apartmentId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig) {
        return ApartmentApiFp(this.configuration).addressControllerApartmentCreate(apartmentId, createAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Address for Apartment
     * @summary 
     * @param {string} apartmentId 
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentApi
     */
    public addressControllerApartmentDelete(apartmentId: string, addressId: string, options?: AxiosRequestConfig) {
        return ApartmentApiFp(this.configuration).addressControllerApartmentDelete(apartmentId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all Addresses for Apartment
     * @summary 
     * @param {string} apartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentApi
     */
    public addressControllerApartmentReadCollection(apartmentId: string, options?: AxiosRequestConfig) {
        return ApartmentApiFp(this.configuration).addressControllerApartmentReadCollection(apartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an Address for Apartment
     * @summary 
     * @param {string} apartmentId 
     * @param {string} addressId 
     * @param {UpdateAddressDto} updateAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApartmentApi
     */
    public addressControllerApartmentUpdate(apartmentId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig) {
        return ApartmentApiFp(this.configuration).addressControllerApartmentUpdate(apartmentId, addressId, updateAddressDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleAgreements: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/article-models/agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleCompanies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/article-models/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleConstructionSites: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/article-models/construction-sites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleObjectChildren: async (objectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleArticleModelsControllerReadArticleObjectChildren', 'objectId', objectId)
            const localVarPath = `/articles/article-models/objects/{objectId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleObjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/article-models/objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/article-models/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an Article
         * @param {CreateArticleDto} createArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerCreate: async (createArticleDto: CreateArticleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createArticleDto' is not null or undefined
            assertParamExists('articleControllerCreate', 'createArticleDto', createArticleDto)
            const localVarPath = `/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an Article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerDelete: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleControllerDelete', 'articleId', articleId)
            const localVarPath = `/articles/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read an Article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerRead: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleControllerRead', 'articleId', articleId)
            const localVarPath = `/articles/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all Articles
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerReadCollection: async (page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('articleControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('articleControllerReadCollection', 'limit', limit)
            const localVarPath = `/articles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (articleModel !== undefined) {
                localVarQueryParameter['ArticleModel'] = articleModel;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all files for an article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerReadFiles: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleControllerReadFiles', 'articleId', articleId)
            const localVarPath = `/articles/{articleId}/files`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all images for an article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerReadImages: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleControllerReadImages', 'articleId', articleId)
            const localVarPath = `/articles/{articleId}/images`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an Article
         * @param {string} articleId 
         * @param {UpdateArticleDto} updateArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerUpdate: async (articleId: string, updateArticleDto: UpdateArticleDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleControllerUpdate', 'articleId', articleId)
            // verify required parameter 'updateArticleDto' is not null or undefined
            assertParamExists('articleControllerUpdate', 'updateArticleDto', updateArticleDto)
            const localVarPath = `/articles/{articleId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateArticleDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an ArticleModel
         * @param {string} articleId 
         * @param {CreateArticleModelDto} createArticleModelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleModelControllerCreate: async (articleId: string, createArticleModelDto: CreateArticleModelDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleModelControllerCreate', 'articleId', articleId)
            // verify required parameter 'createArticleModelDto' is not null or undefined
            assertParamExists('articleModelControllerCreate', 'createArticleModelDto', createArticleModelDto)
            const localVarPath = `/articles/{articleId}/models`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleModelDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an ArticleModel
         * @param {string} articleId 
         * @param {string} articleModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleModelControllerDelete: async (articleId: string, articleModelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleModelControllerDelete', 'articleId', articleId)
            // verify required parameter 'articleModelId' is not null or undefined
            assertParamExists('articleModelControllerDelete', 'articleModelId', articleModelId)
            const localVarPath = `/articles/{articleId}/models/{articleModelId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"articleModelId"}}`, encodeURIComponent(String(articleModelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all ArticleModels of Article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleModelControllerReadCollection: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleModelControllerReadCollection', 'articleId', articleId)
            const localVarPath = `/articles/{articleId}/models`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesCount: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesCount', 'articleId', articleId)
            const localVarPath = `/file-usages/article/{articleId}/files/count`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesCreate: async (articleId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesCreate', 'articleId', articleId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/article/{articleId}/files`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesDelete: async (articleId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesDelete', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/article/{articleId}/files/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesReadCollection: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesReadCollection', 'articleId', articleId)
            const localVarPath = `/file-usages/article/{articleId}/files`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesSort: async (articleId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSort', 'articleId', articleId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/article/{articleId}/files/sort`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesSwap: async (articleId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSwap', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/article/{articleId}/files/swap/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesUpdate: async (articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesUpdate', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/article/{articleId}/files/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesCount: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesCount', 'articleId', articleId)
            const localVarPath = `/file-usages/article/{articleId}/images/count`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesCreate: async (articleId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesCreate', 'articleId', articleId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/article/{articleId}/images`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesDelete: async (articleId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesDelete', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/article/{articleId}/images/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesReadCollection: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesReadCollection', 'articleId', articleId)
            const localVarPath = `/file-usages/article/{articleId}/images`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesSort: async (articleId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSort', 'articleId', articleId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/article/{articleId}/images/sort`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesSwap: async (articleId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSwap', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/article/{articleId}/images/swap/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesUpdate: async (articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesUpdate', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/article/{articleId}/images/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleArticleModelsControllerReadArticleAgreements(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleModelItemDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleArticleModelsControllerReadArticleAgreements(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleArticleModelsControllerReadArticleCompanies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleModelItemDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleArticleModelsControllerReadArticleCompanies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleArticleModelsControllerReadArticleConstructionSites(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleModelItemDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleArticleModelsControllerReadArticleConstructionSites(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleArticleModelsControllerReadArticleObjectChildren(objectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleModelItemDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleArticleModelsControllerReadArticleObjectChildren(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleArticleModelsControllerReadArticleObjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleModelItemDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleArticleModelsControllerReadArticleObjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleArticleModelsControllerReadArticleProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleModelItemDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleArticleModelsControllerReadArticleProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an Article
         * @param {CreateArticleDto} createArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleControllerCreate(createArticleDto: CreateArticleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleControllerCreate(createArticleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an Article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleControllerDelete(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleControllerDelete(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read an Article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleControllerRead(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleControllerRead(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all Articles
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleControllerReadCollection(page, limit, order, orderBy, articleModel, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all files for an article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleControllerReadFiles(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleControllerReadFiles(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all images for an article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleControllerReadImages(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleControllerReadImages(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an Article
         * @param {string} articleId 
         * @param {UpdateArticleDto} updateArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleControllerUpdate(articleId: string, updateArticleDto: UpdateArticleDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleControllerUpdate(articleId, updateArticleDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an ArticleModel
         * @param {string} articleId 
         * @param {CreateArticleModelDto} createArticleModelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleModelControllerCreate(articleId: string, createArticleModelDto: CreateArticleModelDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleModelDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleModelControllerCreate(articleId, createArticleModelDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an ArticleModel
         * @param {string} articleId 
         * @param {string} articleModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleModelControllerDelete(articleId: string, articleModelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleModelControllerDelete(articleId, articleModelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all ArticleModels of Article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleModelControllerReadCollection(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleModelDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleModelControllerReadCollection(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesCount(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesCount(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesCreate(articleId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesDelete(articleId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesReadCollection(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesReadCollection(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesSort(articleId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesSwap(articleId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesUpdate(articleId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesCount(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesCount(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesCreate(articleId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesDelete(articleId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesReadCollection(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesReadCollection(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesSort(articleId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesSwap(articleId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesUpdate(articleId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleAgreements(options?: any): AxiosPromise<ResponseArticleModelItemDtoCollectionResponse> {
            return localVarFp.articleArticleModelsControllerReadArticleAgreements(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleCompanies(options?: any): AxiosPromise<ResponseArticleModelItemDtoCollectionResponse> {
            return localVarFp.articleArticleModelsControllerReadArticleCompanies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleConstructionSites(options?: any): AxiosPromise<ResponseArticleModelItemDtoCollectionResponse> {
            return localVarFp.articleArticleModelsControllerReadArticleConstructionSites(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleObjectChildren(objectId: number, options?: any): AxiosPromise<ResponseArticleModelItemDtoCollectionResponse> {
            return localVarFp.articleArticleModelsControllerReadArticleObjectChildren(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleObjects(options?: any): AxiosPromise<ResponseArticleModelItemDtoCollectionResponse> {
            return localVarFp.articleArticleModelsControllerReadArticleObjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleArticleModelsControllerReadArticleProjects(options?: any): AxiosPromise<ResponseArticleModelItemDtoCollectionResponse> {
            return localVarFp.articleArticleModelsControllerReadArticleProjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an Article
         * @param {CreateArticleDto} createArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerCreate(createArticleDto: CreateArticleDto, options?: any): AxiosPromise<ResponseArticleDtoEntityResponse> {
            return localVarFp.articleControllerCreate(createArticleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an Article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerDelete(articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleControllerDelete(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read an Article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerRead(articleId: string, options?: any): AxiosPromise<ResponseArticleDtoEntityResponse> {
            return localVarFp.articleControllerRead(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all Articles
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: any): AxiosPromise<ResponseArticleDtoPageResponse> {
            return localVarFp.articleControllerReadCollection(page, limit, order, orderBy, articleModel, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all files for an article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerReadFiles(articleId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.articleControllerReadFiles(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all images for an article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerReadImages(articleId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.articleControllerReadImages(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an Article
         * @param {string} articleId 
         * @param {UpdateArticleDto} updateArticleDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleControllerUpdate(articleId: string, updateArticleDto: UpdateArticleDto, options?: any): AxiosPromise<ResponseArticleDtoEntityResponse> {
            return localVarFp.articleControllerUpdate(articleId, updateArticleDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an ArticleModel
         * @param {string} articleId 
         * @param {CreateArticleModelDto} createArticleModelDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleModelControllerCreate(articleId: string, createArticleModelDto: CreateArticleModelDto, options?: any): AxiosPromise<ResponseArticleModelDtoEntityResponse> {
            return localVarFp.articleModelControllerCreate(articleId, createArticleModelDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an ArticleModel
         * @param {string} articleId 
         * @param {string} articleModelId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleModelControllerDelete(articleId: string, articleModelId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleModelControllerDelete(articleId, articleModelId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all ArticleModels of Article
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleModelControllerReadCollection(articleId: string, options?: any): AxiosPromise<ResponseArticleModelDtoCollectionResponse> {
            return localVarFp.articleModelControllerReadCollection(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesCount(articleId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleFilesCount(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleFilesCreate(articleId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerArticleFilesDelete(articleId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesReadCollection(articleId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerArticleFilesReadCollection(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerArticleFilesSort(articleId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleFilesSwap(articleId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleFilesUpdate(articleId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesCount(articleId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleImagesCount(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleImagesCreate(articleId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerArticleImagesDelete(articleId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesReadCollection(articleId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerArticleImagesReadCollection(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerArticleImagesSort(articleId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleImagesSwap(articleId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleImagesUpdate(articleId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleArticleModelsControllerReadArticleAgreements(options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleArticleModelsControllerReadArticleAgreements(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleArticleModelsControllerReadArticleCompanies(options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleArticleModelsControllerReadArticleCompanies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleArticleModelsControllerReadArticleConstructionSites(options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleArticleModelsControllerReadArticleConstructionSites(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleArticleModelsControllerReadArticleObjectChildren(objectId: number, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleArticleModelsControllerReadArticleObjectChildren(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleArticleModelsControllerReadArticleObjects(options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleArticleModelsControllerReadArticleObjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleArticleModelsControllerReadArticleProjects(options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleArticleModelsControllerReadArticleProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an Article
     * @param {CreateArticleDto} createArticleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleControllerCreate(createArticleDto: CreateArticleDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleControllerCreate(createArticleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an Article
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleControllerDelete(articleId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleControllerDelete(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read an Article
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleControllerRead(articleId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleControllerRead(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all Articles
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ArticlePageOptionsOrderBy} [orderBy] 
     * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleControllerReadCollection(page, limit, order, orderBy, articleModel, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all files for an article
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleControllerReadFiles(articleId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleControllerReadFiles(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all images for an article
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleControllerReadImages(articleId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleControllerReadImages(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an Article
     * @param {string} articleId 
     * @param {UpdateArticleDto} updateArticleDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleControllerUpdate(articleId: string, updateArticleDto: UpdateArticleDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleControllerUpdate(articleId, updateArticleDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an ArticleModel
     * @param {string} articleId 
     * @param {CreateArticleModelDto} createArticleModelDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleModelControllerCreate(articleId: string, createArticleModelDto: CreateArticleModelDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleModelControllerCreate(articleId, createArticleModelDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an ArticleModel
     * @param {string} articleId 
     * @param {string} articleModelId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleModelControllerDelete(articleId: string, articleModelId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleModelControllerDelete(articleId, articleModelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all ArticleModels of Article
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public articleModelControllerReadCollection(articleId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).articleModelControllerReadCollection(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleFilesCount(articleId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleFilesCount(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleFilesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleFilesCreate(articleId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleFilesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleFilesDelete(articleId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleFilesReadCollection(articleId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleFilesReadCollection(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleFilesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleFilesSort(articleId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleFilesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleFilesSwap(articleId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleFilesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleFilesUpdate(articleId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleImagesCount(articleId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleImagesCount(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleImagesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleImagesCreate(articleId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleImagesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleImagesDelete(articleId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleImagesReadCollection(articleId: string, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleImagesReadCollection(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleImagesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleImagesSort(articleId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleImagesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleImagesSwap(articleId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public fileUsageControllerArticleImagesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).fileUsageControllerArticleImagesUpdate(articleId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ArticleUsageApi - axios parameter creator
 * @export
 */
export const ArticleUsageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a article to \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/addon-confirmation`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/addon-confirmation/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/addon-confirmation`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/addon-pdf`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/addon-pdf/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/addon-pdf`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/addon-project-text`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/addon-project-text/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/addon-project-text`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages for Company
         * @summary 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyReadCollectionForModel: async (companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyReadCollectionForModel', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-new`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-new/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-new`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-used`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-used/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-used`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial-return`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial-return/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial-return`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private-return`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private-return/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private-return`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages for Object
         * @summary 
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectReadCollectionForModel: async (objectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectReadCollectionForModel', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-new`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-new/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-new`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-used`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-used/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-used`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial-return`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial-return/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial-return`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private-return`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private-return/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private-return`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/addon-confirmation`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/addon-confirmation/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/addon-confirmation`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/addon-pdf`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/addon-pdf/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/addon-pdf`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/addon-project-text`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/addon-project-text/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/addon-project-text`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages for ProjectApartment
         * @summary 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentReadCollectionForModel: async (projectApartmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentReadCollectionForModel', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/article-usages/project-apartment/{projectApartmentId}`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewCreate: async (projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewCreate', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-new`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewDelete: async (projectApartmentId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewDelete', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-new/{articleId}`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewReadCollection: async (projectApartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewReadCollection', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-new`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedCreate: async (projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedCreate', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-used`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedDelete: async (projectApartmentId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedDelete', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-used/{articleId}`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedReadCollection: async (projectApartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedReadCollection', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-used`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages for Project
         * @summary 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectReadCollectionForModel: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectReadCollectionForModel', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-new`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-new/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-new`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-used`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-used/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-used`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleUsageApi - functional programming interface
 * @export
 */
export const ArticleUsageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleUsageApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a article to \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonConfirmationCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonConfirmationCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonConfirmationDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonConfirmationDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonConfirmationReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonConfirmationReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonPdfCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonPdfCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonPdfDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonPdfDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonPdfReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonPdfReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonProjectTextCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonProjectTextCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonProjectTextDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonProjectTextDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonProjectTextReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonProjectTextReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages for Company
         * @summary 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyReadCollectionForModel(companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyReadCollectionForModel(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages for Object
         * @summary 
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectReadCollectionForModel(objectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectReadCollectionForModel(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonConfirmationCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonConfirmationCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonConfirmationDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonConfirmationDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonConfirmationReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonConfirmationReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonPdfCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonPdfCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonPdfDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonPdfDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonPdfReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonPdfReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonProjectTextCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonProjectTextCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonProjectTextDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonProjectTextDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonProjectTextReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonProjectTextReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages for ProjectApartment
         * @summary 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentReadCollectionForModel(projectApartmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentReadCollectionForModel(projectApartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages for Project
         * @summary 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectReadCollectionForModel(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectReadCollectionForModel(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleUsageApi - factory interface
 * @export
 */
export const ArticleUsageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleUsageApiFp(configuration)
    return {
        /**
         * Add a article to \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyAddonConfirmationCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyAddonConfirmationDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyAddonConfirmationReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyAddonPdfCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyAddonPdfDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyAddonPdfReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyAddonProjectTextCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyAddonProjectTextDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyAddonProjectTextReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages for Company
         * @summary 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyReadCollectionForModel(companyId: number, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyReadCollectionForModel(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages for Object
         * @summary 
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectReadCollectionForModel(objectId: number, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectReadCollectionForModel(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectAddonConfirmationCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectAddonConfirmationDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectAddonConfirmationReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectAddonPdfCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectAddonPdfDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectAddonPdfReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectAddonProjectTextCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectAddonProjectTextDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectAddonProjectTextReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages for ProjectApartment
         * @summary 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentReadCollectionForModel(projectApartmentId: number, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectApartmentReadCollectionForModel(projectApartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages for Project
         * @summary 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectReadCollectionForModel(projectId: number, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectReadCollectionForModel(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleUsageApi - object-oriented interface
 * @export
 * @class ArticleUsageApi
 * @extends {BaseAPI}
 */
export class ArticleUsageApi extends BaseAPI {
    /**
     * Add a article to \"AddonConfirmation\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyAddonConfirmationCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyAddonConfirmationCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonConfirmation\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyAddonConfirmationDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyAddonConfirmationDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonConfirmation\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyAddonConfirmationReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyAddonConfirmationReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonPdf\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyAddonPdfCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyAddonPdfCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonPdf\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyAddonPdfDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyAddonPdfDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonPdf\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyAddonPdfReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyAddonPdfReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonProjectText\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyAddonProjectTextCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyAddonProjectTextCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonProjectText\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyAddonProjectTextDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyAddonProjectTextDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonProjectText\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyAddonProjectTextReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyAddonProjectTextReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages for Company
     * @summary 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyReadCollectionForModel(companyId: number, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyReadCollectionForModel(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipNew\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipNew\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipUsed\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyCommercial\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyCommercialReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyPrivate\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyPrivateReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages for Object
     * @summary 
     * @param {number} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectReadCollectionForModel(objectId: number, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectReadCollectionForModel(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipNew\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipNew\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipUsed\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyCommercial\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyCommercialReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyPrivate\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyPrivateReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonConfirmation\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectAddonConfirmationCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectAddonConfirmationCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonConfirmation\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectAddonConfirmationDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectAddonConfirmationDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonConfirmation\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectAddonConfirmationReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectAddonConfirmationReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonPdf\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectAddonPdfCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectAddonPdfCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonPdf\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectAddonPdfDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectAddonPdfDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonPdf\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectAddonPdfReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectAddonPdfReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonProjectText\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectAddonProjectTextCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectAddonProjectTextCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonProjectText\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectAddonProjectTextDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectAddonProjectTextDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonProjectText\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectAddonProjectTextReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectAddonProjectTextReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages for ProjectApartment
     * @summary 
     * @param {number} projectApartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectApartmentReadCollectionForModel(projectApartmentId: number, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectApartmentReadCollectionForModel(projectApartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverNew\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverNew\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverNew\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverUsed\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverUsed\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverUsed\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages for Project
     * @summary 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectReadCollectionForModel(projectId: number, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectReadCollectionForModel(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipNew\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipNew\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipUsed\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleUsageApi
     */
    public articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ArticleUsageApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Assume the role of another user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAssumeUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('authControllerAssumeUser', 'userId', userId)
            const localVarPath = `/auth/assume/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login user
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerLogin', 'loginDto', loginDto)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout user
         * @param {AuthDestination} [destination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout: async (destination?: AuthDestination, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (destination !== undefined) {
                localVarQueryParameter['destination'] = destination;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Assume the role of another user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerAssumeUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerAssumeUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login user
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseLoginDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout user
         * @param {AuthDestination} [destination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogout(destination?: AuthDestination, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseLogoutDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogout(destination, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Assume the role of another user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerAssumeUser(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.authControllerAssumeUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login user
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginDto: LoginDto, options?: any): AxiosPromise<ResponseLoginDto> {
            return localVarFp.authControllerLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout user
         * @param {AuthDestination} [destination] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(destination?: AuthDestination, options?: any): AxiosPromise<ResponseLogoutDto> {
            return localVarFp.authControllerLogout(destination, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Assume the role of another user
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerAssumeUser(userId: number, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerAssumeUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login user
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout user
     * @param {AuthDestination} [destination] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogout(destination?: AuthDestination, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogout(destination, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistApi - axios parameter creator
 * @export
 */
export const ChecklistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a checklist
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistControllerDelete: async (checklistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistControllerDelete', 'checklistId', checklistId)
            const localVarPath = `/checklists/{checklistId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all checklists
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ChecklistPageOptionsOrderBy} [orderBy] 
         * @param {number} [fieldId] Filter results on Field
         * @param {number} [projectId] Filter results on Project
         * @param {number} [objectId] Filter results on Object
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistControllerReadCollection: async (page: number, limit: number, order?: Order, orderBy?: ChecklistPageOptionsOrderBy, fieldId?: number, projectId?: number, objectId?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('checklistControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('checklistControllerReadCollection', 'limit', limit)
            const localVarPath = `/checklists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (fieldId !== undefined) {
                localVarQueryParameter['FieldId'] = fieldId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['ProjectId'] = projectId;
            }

            if (objectId !== undefined) {
                localVarQueryParameter['ObjectId'] = objectId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read one checklist
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistControllerReadOne: async (checklistId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistControllerReadOne', 'checklistId', checklistId)
            const localVarPath = `/checklists/{checklistId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a checklist
         * @param {number} checklistId 
         * @param {UpdateChecklistDto} updateChecklistDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistControllerUpdate: async (checklistId: number, updateChecklistDto: UpdateChecklistDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistControllerUpdate', 'checklistId', checklistId)
            // verify required parameter 'updateChecklistDto' is not null or undefined
            assertParamExists('checklistControllerUpdate', 'updateChecklistDto', updateChecklistDto)
            const localVarPath = `/checklists/{checklistId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChecklistDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistApi - functional programming interface
 * @export
 */
export const ChecklistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a checklist
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistControllerDelete(checklistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistControllerDelete(checklistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all checklists
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ChecklistPageOptionsOrderBy} [orderBy] 
         * @param {number} [fieldId] Filter results on Field
         * @param {number} [projectId] Filter results on Project
         * @param {number} [objectId] Filter results on Object
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: ChecklistPageOptionsOrderBy, fieldId?: number, projectId?: number, objectId?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistControllerReadCollection(page, limit, order, orderBy, fieldId, projectId, objectId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read one checklist
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistControllerReadOne(checklistId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistControllerReadOne(checklistId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a checklist
         * @param {number} checklistId 
         * @param {UpdateChecklistDto} updateChecklistDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistControllerUpdate(checklistId: number, updateChecklistDto: UpdateChecklistDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistControllerUpdate(checklistId, updateChecklistDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistApi - factory interface
 * @export
 */
export const ChecklistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a checklist
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistControllerDelete(checklistId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.checklistControllerDelete(checklistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all checklists
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ChecklistPageOptionsOrderBy} [orderBy] 
         * @param {number} [fieldId] Filter results on Field
         * @param {number} [projectId] Filter results on Project
         * @param {number} [objectId] Filter results on Object
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: ChecklistPageOptionsOrderBy, fieldId?: number, projectId?: number, objectId?: number, search?: string, options?: any): AxiosPromise<ResponseChecklistDtoPageResponse> {
            return localVarFp.checklistControllerReadCollection(page, limit, order, orderBy, fieldId, projectId, objectId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read one checklist
         * @param {number} checklistId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistControllerReadOne(checklistId: number, options?: any): AxiosPromise<ResponseChecklistDtoEntityResponse> {
            return localVarFp.checklistControllerReadOne(checklistId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a checklist
         * @param {number} checklistId 
         * @param {UpdateChecklistDto} updateChecklistDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistControllerUpdate(checklistId: number, updateChecklistDto: UpdateChecklistDto, options?: any): AxiosPromise<ResponseChecklistDtoEntityResponse> {
            return localVarFp.checklistControllerUpdate(checklistId, updateChecklistDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistApi - object-oriented interface
 * @export
 * @class ChecklistApi
 * @extends {BaseAPI}
 */
export class ChecklistApi extends BaseAPI {
    /**
     * 
     * @summary Delete a checklist
     * @param {number} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public checklistControllerDelete(checklistId: number, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).checklistControllerDelete(checklistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all checklists
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ChecklistPageOptionsOrderBy} [orderBy] 
     * @param {number} [fieldId] Filter results on Field
     * @param {number} [projectId] Filter results on Project
     * @param {number} [objectId] Filter results on Object
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public checklistControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: ChecklistPageOptionsOrderBy, fieldId?: number, projectId?: number, objectId?: number, search?: string, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).checklistControllerReadCollection(page, limit, order, orderBy, fieldId, projectId, objectId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read one checklist
     * @param {number} checklistId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public checklistControllerReadOne(checklistId: number, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).checklistControllerReadOne(checklistId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a checklist
     * @param {number} checklistId 
     * @param {UpdateChecklistDto} updateChecklistDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistApi
     */
    public checklistControllerUpdate(checklistId: number, updateChecklistDto: UpdateChecklistDto, options?: AxiosRequestConfig) {
        return ChecklistApiFp(this.configuration).checklistControllerUpdate(checklistId, updateChecklistDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistItemApi - axios parameter creator
 * @export
 */
export const ChecklistItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a checklist item
         * @param {number} checklistId 
         * @param {number} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemControllerDelete: async (checklistId: number, checklistItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistItemControllerDelete', 'checklistId', checklistId)
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('checklistItemControllerDelete', 'checklistItemId', checklistItemId)
            const localVarPath = `/checklists/{checklistId}/checklist-items/{checklistItemId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)))
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ChecklistItems
         * @summary Read a collection of ChecklistItems
         * @param {number} checklistId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemControllerReadCollection: async (checklistId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistItemControllerReadCollection', 'checklistId', checklistId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('checklistItemControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('checklistItemControllerReadCollection', 'limit', limit)
            const localVarPath = `/checklists/{checklistId}/checklist-items`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a single ChecklistItem
         * @param {number} checklistId 
         * @param {number} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemControllerReadOne: async (checklistId: number, checklistItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistItemControllerReadOne', 'checklistId', checklistId)
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('checklistItemControllerReadOne', 'checklistItemId', checklistItemId)
            const localVarPath = `/checklists/{checklistId}/checklist-items/{checklistItemId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)))
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a checklist item
         * @param {number} checklistId 
         * @param {number} checklistItemId 
         * @param {UpdateChecklistItemDto} updateChecklistItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemControllerUpdate: async (checklistId: number, checklistItemId: number, updateChecklistItemDto: UpdateChecklistItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistItemControllerUpdate', 'checklistId', checklistId)
            // verify required parameter 'checklistItemId' is not null or undefined
            assertParamExists('checklistItemControllerUpdate', 'checklistItemId', checklistItemId)
            // verify required parameter 'updateChecklistItemDto' is not null or undefined
            assertParamExists('checklistItemControllerUpdate', 'updateChecklistItemDto', updateChecklistItemDto)
            const localVarPath = `/checklists/{checklistId}/checklist-items/{checklistItemId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)))
                .replace(`{${"checklistItemId"}}`, encodeURIComponent(String(checklistItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChecklistItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistItemApi - functional programming interface
 * @export
 */
export const ChecklistItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a checklist item
         * @param {number} checklistId 
         * @param {number} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistItemControllerDelete(checklistId: number, checklistItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistItemControllerDelete(checklistId, checklistItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ChecklistItems
         * @summary Read a collection of ChecklistItems
         * @param {number} checklistId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistItemControllerReadCollection(checklistId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistItemDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistItemControllerReadCollection(checklistId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a single ChecklistItem
         * @param {number} checklistId 
         * @param {number} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistItemControllerReadOne(checklistId: number, checklistItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistItemDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistItemControllerReadOne(checklistId, checklistItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a checklist item
         * @param {number} checklistId 
         * @param {number} checklistItemId 
         * @param {UpdateChecklistItemDto} updateChecklistItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistItemControllerUpdate(checklistId: number, checklistItemId: number, updateChecklistItemDto: UpdateChecklistItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistItemDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistItemControllerUpdate(checklistId, checklistItemId, updateChecklistItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistItemApi - factory interface
 * @export
 */
export const ChecklistItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistItemApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a checklist item
         * @param {number} checklistId 
         * @param {number} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemControllerDelete(checklistId: number, checklistItemId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.checklistItemControllerDelete(checklistId, checklistItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ChecklistItems
         * @summary Read a collection of ChecklistItems
         * @param {number} checklistId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemControllerReadCollection(checklistId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponseChecklistItemDtoPageResponse> {
            return localVarFp.checklistItemControllerReadCollection(checklistId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a single ChecklistItem
         * @param {number} checklistId 
         * @param {number} checklistItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemControllerReadOne(checklistId: number, checklistItemId: number, options?: any): AxiosPromise<ResponseChecklistItemDtoEntityResponse> {
            return localVarFp.checklistItemControllerReadOne(checklistId, checklistItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a checklist item
         * @param {number} checklistId 
         * @param {number} checklistItemId 
         * @param {UpdateChecklistItemDto} updateChecklistItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistItemControllerUpdate(checklistId: number, checklistItemId: number, updateChecklistItemDto: UpdateChecklistItemDto, options?: any): AxiosPromise<ResponseChecklistItemDtoEntityResponse> {
            return localVarFp.checklistItemControllerUpdate(checklistId, checklistItemId, updateChecklistItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistItemApi - object-oriented interface
 * @export
 * @class ChecklistItemApi
 * @extends {BaseAPI}
 */
export class ChecklistItemApi extends BaseAPI {
    /**
     * 
     * @summary Delete a checklist item
     * @param {number} checklistId 
     * @param {number} checklistItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistItemApi
     */
    public checklistItemControllerDelete(checklistId: number, checklistItemId: number, options?: AxiosRequestConfig) {
        return ChecklistItemApiFp(this.configuration).checklistItemControllerDelete(checklistId, checklistItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ChecklistItems
     * @summary Read a collection of ChecklistItems
     * @param {number} checklistId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistItemApi
     */
    public checklistItemControllerReadCollection(checklistId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ChecklistItemApiFp(this.configuration).checklistItemControllerReadCollection(checklistId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a single ChecklistItem
     * @param {number} checklistId 
     * @param {number} checklistItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistItemApi
     */
    public checklistItemControllerReadOne(checklistId: number, checklistItemId: number, options?: AxiosRequestConfig) {
        return ChecklistItemApiFp(this.configuration).checklistItemControllerReadOne(checklistId, checklistItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a checklist item
     * @param {number} checklistId 
     * @param {number} checklistItemId 
     * @param {UpdateChecklistItemDto} updateChecklistItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistItemApi
     */
    public checklistItemControllerUpdate(checklistId: number, checklistItemId: number, updateChecklistItemDto: UpdateChecklistItemDto, options?: AxiosRequestConfig) {
        return ChecklistItemApiFp(this.configuration).checklistItemControllerUpdate(checklistId, checklistItemId, updateChecklistItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistSectionApi - axios parameter creator
 * @export
 */
export const ChecklistSectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a ChecklistSection
         * @param {number} checklistId 
         * @param {number} checklistSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistSectionControllerDelete: async (checklistId: number, checklistSectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistSectionControllerDelete', 'checklistId', checklistId)
            // verify required parameter 'checklistSectionId' is not null or undefined
            assertParamExists('checklistSectionControllerDelete', 'checklistSectionId', checklistSectionId)
            const localVarPath = `/checklist/{checklistId}/checklist-sections/{checklistSectionId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)))
                .replace(`{${"checklistSectionId"}}`, encodeURIComponent(String(checklistSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all ChecklistSections
         * @param {number} checklistId 
         * @param {number} limit 
         * @param {number} page 
         * @param {Order} [order] 
         * @param {ChecklistSectionPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistSectionControllerReadCollection: async (checklistId: number, limit: number, page: number, order?: Order, orderBy?: ChecklistSectionPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistSectionControllerReadCollection', 'checklistId', checklistId)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('checklistSectionControllerReadCollection', 'limit', limit)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('checklistSectionControllerReadCollection', 'page', page)
            const localVarPath = `/checklist/{checklistId}/checklist-sections`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update ChecklistSection
         * @param {number} checklistId 
         * @param {number} checklistSectionId 
         * @param {UpdateChecklistSectionDto} updateChecklistSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistSectionControllerUpdate: async (checklistId: number, checklistSectionId: number, updateChecklistSectionDto: UpdateChecklistSectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistId' is not null or undefined
            assertParamExists('checklistSectionControllerUpdate', 'checklistId', checklistId)
            // verify required parameter 'checklistSectionId' is not null or undefined
            assertParamExists('checklistSectionControllerUpdate', 'checklistSectionId', checklistSectionId)
            // verify required parameter 'updateChecklistSectionDto' is not null or undefined
            assertParamExists('checklistSectionControllerUpdate', 'updateChecklistSectionDto', updateChecklistSectionDto)
            const localVarPath = `/checklist/{checklistId}/checklist-sections/{checklistSectionId}`
                .replace(`{${"checklistId"}}`, encodeURIComponent(String(checklistId)))
                .replace(`{${"checklistSectionId"}}`, encodeURIComponent(String(checklistSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChecklistSectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistSectionApi - functional programming interface
 * @export
 */
export const ChecklistSectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistSectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a ChecklistSection
         * @param {number} checklistId 
         * @param {number} checklistSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistSectionControllerDelete(checklistId: number, checklistSectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistSectionControllerDelete(checklistId, checklistSectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all ChecklistSections
         * @param {number} checklistId 
         * @param {number} limit 
         * @param {number} page 
         * @param {Order} [order] 
         * @param {ChecklistSectionPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistSectionControllerReadCollection(checklistId: number, limit: number, page: number, order?: Order, orderBy?: ChecklistSectionPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistSectionDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistSectionControllerReadCollection(checklistId, limit, page, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update ChecklistSection
         * @param {number} checklistId 
         * @param {number} checklistSectionId 
         * @param {UpdateChecklistSectionDto} updateChecklistSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistSectionControllerUpdate(checklistId: number, checklistSectionId: number, updateChecklistSectionDto: UpdateChecklistSectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistSectionDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistSectionControllerUpdate(checklistId, checklistSectionId, updateChecklistSectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistSectionApi - factory interface
 * @export
 */
export const ChecklistSectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistSectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a ChecklistSection
         * @param {number} checklistId 
         * @param {number} checklistSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistSectionControllerDelete(checklistId: number, checklistSectionId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.checklistSectionControllerDelete(checklistId, checklistSectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all ChecklistSections
         * @param {number} checklistId 
         * @param {number} limit 
         * @param {number} page 
         * @param {Order} [order] 
         * @param {ChecklistSectionPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistSectionControllerReadCollection(checklistId: number, limit: number, page: number, order?: Order, orderBy?: ChecklistSectionPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseChecklistSectionDtoPageResponse> {
            return localVarFp.checklistSectionControllerReadCollection(checklistId, limit, page, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update ChecklistSection
         * @param {number} checklistId 
         * @param {number} checklistSectionId 
         * @param {UpdateChecklistSectionDto} updateChecklistSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistSectionControllerUpdate(checklistId: number, checklistSectionId: number, updateChecklistSectionDto: UpdateChecklistSectionDto, options?: any): AxiosPromise<ResponseChecklistSectionDtoEntityResponse> {
            return localVarFp.checklistSectionControllerUpdate(checklistId, checklistSectionId, updateChecklistSectionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistSectionApi - object-oriented interface
 * @export
 * @class ChecklistSectionApi
 * @extends {BaseAPI}
 */
export class ChecklistSectionApi extends BaseAPI {
    /**
     * 
     * @summary Delete a ChecklistSection
     * @param {number} checklistId 
     * @param {number} checklistSectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistSectionApi
     */
    public checklistSectionControllerDelete(checklistId: number, checklistSectionId: number, options?: AxiosRequestConfig) {
        return ChecklistSectionApiFp(this.configuration).checklistSectionControllerDelete(checklistId, checklistSectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all ChecklistSections
     * @param {number} checklistId 
     * @param {number} limit 
     * @param {number} page 
     * @param {Order} [order] 
     * @param {ChecklistSectionPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistSectionApi
     */
    public checklistSectionControllerReadCollection(checklistId: number, limit: number, page: number, order?: Order, orderBy?: ChecklistSectionPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ChecklistSectionApiFp(this.configuration).checklistSectionControllerReadCollection(checklistId, limit, page, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update ChecklistSection
     * @param {number} checklistId 
     * @param {number} checklistSectionId 
     * @param {UpdateChecklistSectionDto} updateChecklistSectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistSectionApi
     */
    public checklistSectionControllerUpdate(checklistId: number, checklistSectionId: number, updateChecklistSectionDto: UpdateChecklistSectionDto, options?: AxiosRequestConfig) {
        return ChecklistSectionApiFp(this.configuration).checklistSectionControllerUpdate(checklistId, checklistSectionId, updateChecklistSectionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistTemplateApi - axios parameter creator
 * @export
 */
export const ChecklistTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Clone a ChecklistTemplate with children
         * @param {number} checklistTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerClone: async (checklistTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateControllerClone', 'checklistTemplateId', checklistTemplateId)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/clone`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a checklist template
         * @param {CreateChecklistTemplateDto} createChecklistTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerCreate: async (createChecklistTemplateDto: CreateChecklistTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createChecklistTemplateDto' is not null or undefined
            assertParamExists('checklistTemplateControllerCreate', 'createChecklistTemplateDto', createChecklistTemplateDto)
            const localVarPath = `/checklist-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChecklistTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a checklist template
         * @param {number} checklistTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerDelete: async (checklistTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateControllerDelete', 'checklistTemplateId', checklistTemplateId)
            const localVarPath = `/checklist-templates/{checklistTemplateId}`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all checklist templates
         * @summary Read all checklist templates
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ChecklistTemplatePageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerReadCollection: async (page: number, limit: number, order?: Order, orderBy?: ChecklistTemplatePageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('checklistTemplateControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('checklistTemplateControllerReadCollection', 'limit', limit)
            const localVarPath = `/checklist-templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a checklist template
         * @param {number} checklistTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerReadOne: async (checklistTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateControllerReadOne', 'checklistTemplateId', checklistTemplateId)
            const localVarPath = `/checklist-templates/{checklistTemplateId}`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a checklist template
         * @param {number} checklistTemplateId 
         * @param {UpdateChecklistTemplateDto} updateChecklistTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerUpdate: async (checklistTemplateId: number, updateChecklistTemplateDto: UpdateChecklistTemplateDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateControllerUpdate', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'updateChecklistTemplateDto' is not null or undefined
            assertParamExists('checklistTemplateControllerUpdate', 'updateChecklistTemplateDto', updateChecklistTemplateDto)
            const localVarPath = `/checklist-templates/{checklistTemplateId}`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChecklistTemplateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistTemplateApi - functional programming interface
 * @export
 */
export const ChecklistTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Clone a ChecklistTemplate with children
         * @param {number} checklistTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateControllerClone(checklistTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseClonedChecklistTemplateDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateControllerClone(checklistTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a checklist template
         * @param {CreateChecklistTemplateDto} createChecklistTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateControllerCreate(createChecklistTemplateDto: CreateChecklistTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateControllerCreate(createChecklistTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a checklist template
         * @param {number} checklistTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateControllerDelete(checklistTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateControllerDelete(checklistTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all checklist templates
         * @summary Read all checklist templates
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ChecklistTemplatePageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: ChecklistTemplatePageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateControllerReadCollection(page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a checklist template
         * @param {number} checklistTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateControllerReadOne(checklistTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateControllerReadOne(checklistTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a checklist template
         * @param {number} checklistTemplateId 
         * @param {UpdateChecklistTemplateDto} updateChecklistTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateControllerUpdate(checklistTemplateId: number, updateChecklistTemplateDto: UpdateChecklistTemplateDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateControllerUpdate(checklistTemplateId, updateChecklistTemplateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistTemplateApi - factory interface
 * @export
 */
export const ChecklistTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistTemplateApiFp(configuration)
    return {
        /**
         * 
         * @summary Clone a ChecklistTemplate with children
         * @param {number} checklistTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerClone(checklistTemplateId: number, options?: any): AxiosPromise<ResponseClonedChecklistTemplateDtoEntityResponse> {
            return localVarFp.checklistTemplateControllerClone(checklistTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a checklist template
         * @param {CreateChecklistTemplateDto} createChecklistTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerCreate(createChecklistTemplateDto: CreateChecklistTemplateDto, options?: any): AxiosPromise<ResponseChecklistTemplateDtoEntityResponse> {
            return localVarFp.checklistTemplateControllerCreate(createChecklistTemplateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a checklist template
         * @param {number} checklistTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerDelete(checklistTemplateId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.checklistTemplateControllerDelete(checklistTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all checklist templates
         * @summary Read all checklist templates
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ChecklistTemplatePageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: ChecklistTemplatePageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseChecklistTemplateDtoPageResponse> {
            return localVarFp.checklistTemplateControllerReadCollection(page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a checklist template
         * @param {number} checklistTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerReadOne(checklistTemplateId: number, options?: any): AxiosPromise<ResponseChecklistTemplateDtoEntityResponse> {
            return localVarFp.checklistTemplateControllerReadOne(checklistTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a checklist template
         * @param {number} checklistTemplateId 
         * @param {UpdateChecklistTemplateDto} updateChecklistTemplateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateControllerUpdate(checklistTemplateId: number, updateChecklistTemplateDto: UpdateChecklistTemplateDto, options?: any): AxiosPromise<ResponseChecklistTemplateDtoEntityResponse> {
            return localVarFp.checklistTemplateControllerUpdate(checklistTemplateId, updateChecklistTemplateDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistTemplateApi - object-oriented interface
 * @export
 * @class ChecklistTemplateApi
 * @extends {BaseAPI}
 */
export class ChecklistTemplateApi extends BaseAPI {
    /**
     * 
     * @summary Clone a ChecklistTemplate with children
     * @param {number} checklistTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateApi
     */
    public checklistTemplateControllerClone(checklistTemplateId: number, options?: AxiosRequestConfig) {
        return ChecklistTemplateApiFp(this.configuration).checklistTemplateControllerClone(checklistTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a checklist template
     * @param {CreateChecklistTemplateDto} createChecklistTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateApi
     */
    public checklistTemplateControllerCreate(createChecklistTemplateDto: CreateChecklistTemplateDto, options?: AxiosRequestConfig) {
        return ChecklistTemplateApiFp(this.configuration).checklistTemplateControllerCreate(createChecklistTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a checklist template
     * @param {number} checklistTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateApi
     */
    public checklistTemplateControllerDelete(checklistTemplateId: number, options?: AxiosRequestConfig) {
        return ChecklistTemplateApiFp(this.configuration).checklistTemplateControllerDelete(checklistTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all checklist templates
     * @summary Read all checklist templates
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ChecklistTemplatePageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateApi
     */
    public checklistTemplateControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: ChecklistTemplatePageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ChecklistTemplateApiFp(this.configuration).checklistTemplateControllerReadCollection(page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a checklist template
     * @param {number} checklistTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateApi
     */
    public checklistTemplateControllerReadOne(checklistTemplateId: number, options?: AxiosRequestConfig) {
        return ChecklistTemplateApiFp(this.configuration).checklistTemplateControllerReadOne(checklistTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a checklist template
     * @param {number} checklistTemplateId 
     * @param {UpdateChecklistTemplateDto} updateChecklistTemplateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateApi
     */
    public checklistTemplateControllerUpdate(checklistTemplateId: number, updateChecklistTemplateDto: UpdateChecklistTemplateDto, options?: AxiosRequestConfig) {
        return ChecklistTemplateApiFp(this.configuration).checklistTemplateControllerUpdate(checklistTemplateId, updateChecklistTemplateDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistTemplateItemApi - axios parameter creator
 * @export
 */
export const ChecklistTemplateItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {CreateChecklistTemplateItemDto} createChecklistTemplateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerCreate: async (checklistTemplateId: number, createChecklistTemplateItemDto: CreateChecklistTemplateItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateItemControllerCreate', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'createChecklistTemplateItemDto' is not null or undefined
            assertParamExists('checklistTemplateItemControllerCreate', 'createChecklistTemplateItemDto', createChecklistTemplateItemDto)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-item`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChecklistTemplateItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerDelete: async (checklistTemplateId: number, checklistTemplateItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateItemControllerDelete', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'checklistTemplateItemId' is not null or undefined
            assertParamExists('checklistTemplateItemControllerDelete', 'checklistTemplateItemId', checklistTemplateItemId)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-item/{checklistTemplateItemId}`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)))
                .replace(`{${"checklistTemplateItemId"}}`, encodeURIComponent(String(checklistTemplateItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read collection of ChecklistTemplateItems
         * @param {number} checklistTemplateId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerReadCollection: async (checklistTemplateId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateItemControllerReadCollection', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('checklistTemplateItemControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('checklistTemplateItemControllerReadCollection', 'limit', limit)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-item`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read one ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerReadOne: async (checklistTemplateId: number, checklistTemplateItemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateItemControllerReadOne', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'checklistTemplateItemId' is not null or undefined
            assertParamExists('checklistTemplateItemControllerReadOne', 'checklistTemplateItemId', checklistTemplateItemId)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-item/{checklistTemplateItemId}`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)))
                .replace(`{${"checklistTemplateItemId"}}`, encodeURIComponent(String(checklistTemplateItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateItemId 
         * @param {UpdateChecklistTemplateItemDto} updateChecklistTemplateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerUpdate: async (checklistTemplateId: number, checklistTemplateItemId: number, updateChecklistTemplateItemDto: UpdateChecklistTemplateItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateItemControllerUpdate', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'checklistTemplateItemId' is not null or undefined
            assertParamExists('checklistTemplateItemControllerUpdate', 'checklistTemplateItemId', checklistTemplateItemId)
            // verify required parameter 'updateChecklistTemplateItemDto' is not null or undefined
            assertParamExists('checklistTemplateItemControllerUpdate', 'updateChecklistTemplateItemDto', updateChecklistTemplateItemDto)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-item/{checklistTemplateItemId}`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)))
                .replace(`{${"checklistTemplateItemId"}}`, encodeURIComponent(String(checklistTemplateItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChecklistTemplateItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistTemplateItemApi - functional programming interface
 * @export
 */
export const ChecklistTemplateItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistTemplateItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {CreateChecklistTemplateItemDto} createChecklistTemplateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateItemControllerCreate(checklistTemplateId: number, createChecklistTemplateItemDto: CreateChecklistTemplateItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateItemDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateItemControllerCreate(checklistTemplateId, createChecklistTemplateItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateItemControllerDelete(checklistTemplateId: number, checklistTemplateItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateItemControllerDelete(checklistTemplateId, checklistTemplateItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read collection of ChecklistTemplateItems
         * @param {number} checklistTemplateId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateItemControllerReadCollection(checklistTemplateId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateItemDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateItemControllerReadCollection(checklistTemplateId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read one ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateItemControllerReadOne(checklistTemplateId: number, checklistTemplateItemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateItemDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateItemControllerReadOne(checklistTemplateId, checklistTemplateItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateItemId 
         * @param {UpdateChecklistTemplateItemDto} updateChecklistTemplateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateItemControllerUpdate(checklistTemplateId: number, checklistTemplateItemId: number, updateChecklistTemplateItemDto: UpdateChecklistTemplateItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateItemDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateItemControllerUpdate(checklistTemplateId, checklistTemplateItemId, updateChecklistTemplateItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistTemplateItemApi - factory interface
 * @export
 */
export const ChecklistTemplateItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistTemplateItemApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {CreateChecklistTemplateItemDto} createChecklistTemplateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerCreate(checklistTemplateId: number, createChecklistTemplateItemDto: CreateChecklistTemplateItemDto, options?: any): AxiosPromise<ResponseChecklistTemplateItemDtoEntityResponse> {
            return localVarFp.checklistTemplateItemControllerCreate(checklistTemplateId, createChecklistTemplateItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerDelete(checklistTemplateId: number, checklistTemplateItemId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.checklistTemplateItemControllerDelete(checklistTemplateId, checklistTemplateItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read collection of ChecklistTemplateItems
         * @param {number} checklistTemplateId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerReadCollection(checklistTemplateId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponseChecklistTemplateItemDtoPageResponse> {
            return localVarFp.checklistTemplateItemControllerReadCollection(checklistTemplateId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read one ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerReadOne(checklistTemplateId: number, checklistTemplateItemId: number, options?: any): AxiosPromise<ResponseChecklistTemplateItemDtoEntityResponse> {
            return localVarFp.checklistTemplateItemControllerReadOne(checklistTemplateId, checklistTemplateItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a ChecklistTemplateItem
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateItemId 
         * @param {UpdateChecklistTemplateItemDto} updateChecklistTemplateItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateItemControllerUpdate(checklistTemplateId: number, checklistTemplateItemId: number, updateChecklistTemplateItemDto: UpdateChecklistTemplateItemDto, options?: any): AxiosPromise<ResponseChecklistTemplateItemDtoEntityResponse> {
            return localVarFp.checklistTemplateItemControllerUpdate(checklistTemplateId, checklistTemplateItemId, updateChecklistTemplateItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistTemplateItemApi - object-oriented interface
 * @export
 * @class ChecklistTemplateItemApi
 * @extends {BaseAPI}
 */
export class ChecklistTemplateItemApi extends BaseAPI {
    /**
     * 
     * @summary Create a ChecklistTemplateItem
     * @param {number} checklistTemplateId 
     * @param {CreateChecklistTemplateItemDto} createChecklistTemplateItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateItemApi
     */
    public checklistTemplateItemControllerCreate(checklistTemplateId: number, createChecklistTemplateItemDto: CreateChecklistTemplateItemDto, options?: AxiosRequestConfig) {
        return ChecklistTemplateItemApiFp(this.configuration).checklistTemplateItemControllerCreate(checklistTemplateId, createChecklistTemplateItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a ChecklistTemplateItem
     * @param {number} checklistTemplateId 
     * @param {number} checklistTemplateItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateItemApi
     */
    public checklistTemplateItemControllerDelete(checklistTemplateId: number, checklistTemplateItemId: number, options?: AxiosRequestConfig) {
        return ChecklistTemplateItemApiFp(this.configuration).checklistTemplateItemControllerDelete(checklistTemplateId, checklistTemplateItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read collection of ChecklistTemplateItems
     * @param {number} checklistTemplateId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateItemApi
     */
    public checklistTemplateItemControllerReadCollection(checklistTemplateId: number, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ChecklistTemplateItemApiFp(this.configuration).checklistTemplateItemControllerReadCollection(checklistTemplateId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read one ChecklistTemplateItem
     * @param {number} checklistTemplateId 
     * @param {number} checklistTemplateItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateItemApi
     */
    public checklistTemplateItemControllerReadOne(checklistTemplateId: number, checklistTemplateItemId: number, options?: AxiosRequestConfig) {
        return ChecklistTemplateItemApiFp(this.configuration).checklistTemplateItemControllerReadOne(checklistTemplateId, checklistTemplateItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a ChecklistTemplateItem
     * @param {number} checklistTemplateId 
     * @param {number} checklistTemplateItemId 
     * @param {UpdateChecklistTemplateItemDto} updateChecklistTemplateItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateItemApi
     */
    public checklistTemplateItemControllerUpdate(checklistTemplateId: number, checklistTemplateItemId: number, updateChecklistTemplateItemDto: UpdateChecklistTemplateItemDto, options?: AxiosRequestConfig) {
        return ChecklistTemplateItemApiFp(this.configuration).checklistTemplateItemControllerUpdate(checklistTemplateId, checklistTemplateItemId, updateChecklistTemplateItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ChecklistTemplateSectionApi - axios parameter creator
 * @export
 */
export const ChecklistTemplateSectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {CreateChecklistTemplateSectionDto} createChecklistTemplateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerCreate: async (checklistTemplateId: number, createChecklistTemplateSectionDto: CreateChecklistTemplateSectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerCreate', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'createChecklistTemplateSectionDto' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerCreate', 'createChecklistTemplateSectionDto', createChecklistTemplateSectionDto)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-section`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createChecklistTemplateSectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerDelete: async (checklistTemplateId: number, checklistTemplateSectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerDelete', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'checklistTemplateSectionId' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerDelete', 'checklistTemplateSectionId', checklistTemplateSectionId)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-section/{checklistTemplateSectionId}`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)))
                .replace(`{${"checklistTemplateSectionId"}}`, encodeURIComponent(String(checklistTemplateSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all ChecklistTemplateSections
         * @param {number} checklistTemplateId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ChecklistTemplateSectionPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerReadCollection: async (checklistTemplateId: number, page: number, limit: number, order?: Order, orderBy?: ChecklistTemplateSectionPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerReadCollection', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerReadCollection', 'limit', limit)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-section`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read one ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerReadOne: async (checklistTemplateId: number, checklistTemplateSectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerReadOne', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'checklistTemplateSectionId' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerReadOne', 'checklistTemplateSectionId', checklistTemplateSectionId)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-section/{checklistTemplateSectionId}`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)))
                .replace(`{${"checklistTemplateSectionId"}}`, encodeURIComponent(String(checklistTemplateSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateSectionId 
         * @param {UpdateChecklistTemplateSectionDto} updateChecklistTemplateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerUpdate: async (checklistTemplateId: number, checklistTemplateSectionId: number, updateChecklistTemplateSectionDto: UpdateChecklistTemplateSectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checklistTemplateId' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerUpdate', 'checklistTemplateId', checklistTemplateId)
            // verify required parameter 'checklistTemplateSectionId' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerUpdate', 'checklistTemplateSectionId', checklistTemplateSectionId)
            // verify required parameter 'updateChecklistTemplateSectionDto' is not null or undefined
            assertParamExists('checklistTemplateSectionControllerUpdate', 'updateChecklistTemplateSectionDto', updateChecklistTemplateSectionDto)
            const localVarPath = `/checklist-templates/{checklistTemplateId}/checklist-templates-section/{checklistTemplateSectionId}`
                .replace(`{${"checklistTemplateId"}}`, encodeURIComponent(String(checklistTemplateId)))
                .replace(`{${"checklistTemplateSectionId"}}`, encodeURIComponent(String(checklistTemplateSectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateChecklistTemplateSectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChecklistTemplateSectionApi - functional programming interface
 * @export
 */
export const ChecklistTemplateSectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChecklistTemplateSectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {CreateChecklistTemplateSectionDto} createChecklistTemplateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateSectionControllerCreate(checklistTemplateId: number, createChecklistTemplateSectionDto: CreateChecklistTemplateSectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateSectionDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateSectionControllerCreate(checklistTemplateId, createChecklistTemplateSectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateSectionControllerDelete(checklistTemplateId: number, checklistTemplateSectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateSectionControllerDelete(checklistTemplateId, checklistTemplateSectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all ChecklistTemplateSections
         * @param {number} checklistTemplateId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ChecklistTemplateSectionPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateSectionControllerReadCollection(checklistTemplateId: number, page: number, limit: number, order?: Order, orderBy?: ChecklistTemplateSectionPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateSectionDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateSectionControllerReadCollection(checklistTemplateId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read one ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateSectionControllerReadOne(checklistTemplateId: number, checklistTemplateSectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateSectionDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateSectionControllerReadOne(checklistTemplateId, checklistTemplateSectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateSectionId 
         * @param {UpdateChecklistTemplateSectionDto} updateChecklistTemplateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checklistTemplateSectionControllerUpdate(checklistTemplateId: number, checklistTemplateSectionId: number, updateChecklistTemplateSectionDto: UpdateChecklistTemplateSectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseChecklistTemplateSectionDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checklistTemplateSectionControllerUpdate(checklistTemplateId, checklistTemplateSectionId, updateChecklistTemplateSectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ChecklistTemplateSectionApi - factory interface
 * @export
 */
export const ChecklistTemplateSectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChecklistTemplateSectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {CreateChecklistTemplateSectionDto} createChecklistTemplateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerCreate(checklistTemplateId: number, createChecklistTemplateSectionDto: CreateChecklistTemplateSectionDto, options?: any): AxiosPromise<ResponseChecklistTemplateSectionDtoEntityResponse> {
            return localVarFp.checklistTemplateSectionControllerCreate(checklistTemplateId, createChecklistTemplateSectionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerDelete(checklistTemplateId: number, checklistTemplateSectionId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.checklistTemplateSectionControllerDelete(checklistTemplateId, checklistTemplateSectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all ChecklistTemplateSections
         * @param {number} checklistTemplateId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ChecklistTemplateSectionPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerReadCollection(checklistTemplateId: number, page: number, limit: number, order?: Order, orderBy?: ChecklistTemplateSectionPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseChecklistTemplateSectionDtoPageResponse> {
            return localVarFp.checklistTemplateSectionControllerReadCollection(checklistTemplateId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read one ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateSectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerReadOne(checklistTemplateId: number, checklistTemplateSectionId: number, options?: any): AxiosPromise<ResponseChecklistTemplateSectionDtoEntityResponse> {
            return localVarFp.checklistTemplateSectionControllerReadOne(checklistTemplateId, checklistTemplateSectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a ChecklistTemplateSection
         * @param {number} checklistTemplateId 
         * @param {number} checklistTemplateSectionId 
         * @param {UpdateChecklistTemplateSectionDto} updateChecklistTemplateSectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checklistTemplateSectionControllerUpdate(checklistTemplateId: number, checklistTemplateSectionId: number, updateChecklistTemplateSectionDto: UpdateChecklistTemplateSectionDto, options?: any): AxiosPromise<ResponseChecklistTemplateSectionDtoEntityResponse> {
            return localVarFp.checklistTemplateSectionControllerUpdate(checklistTemplateId, checklistTemplateSectionId, updateChecklistTemplateSectionDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChecklistTemplateSectionApi - object-oriented interface
 * @export
 * @class ChecklistTemplateSectionApi
 * @extends {BaseAPI}
 */
export class ChecklistTemplateSectionApi extends BaseAPI {
    /**
     * 
     * @summary Create a ChecklistTemplateSection
     * @param {number} checklistTemplateId 
     * @param {CreateChecklistTemplateSectionDto} createChecklistTemplateSectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateSectionApi
     */
    public checklistTemplateSectionControllerCreate(checklistTemplateId: number, createChecklistTemplateSectionDto: CreateChecklistTemplateSectionDto, options?: AxiosRequestConfig) {
        return ChecklistTemplateSectionApiFp(this.configuration).checklistTemplateSectionControllerCreate(checklistTemplateId, createChecklistTemplateSectionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a ChecklistTemplateSection
     * @param {number} checklistTemplateId 
     * @param {number} checklistTemplateSectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateSectionApi
     */
    public checklistTemplateSectionControllerDelete(checklistTemplateId: number, checklistTemplateSectionId: number, options?: AxiosRequestConfig) {
        return ChecklistTemplateSectionApiFp(this.configuration).checklistTemplateSectionControllerDelete(checklistTemplateId, checklistTemplateSectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all ChecklistTemplateSections
     * @param {number} checklistTemplateId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ChecklistTemplateSectionPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateSectionApi
     */
    public checklistTemplateSectionControllerReadCollection(checklistTemplateId: number, page: number, limit: number, order?: Order, orderBy?: ChecklistTemplateSectionPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ChecklistTemplateSectionApiFp(this.configuration).checklistTemplateSectionControllerReadCollection(checklistTemplateId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read one ChecklistTemplateSection
     * @param {number} checklistTemplateId 
     * @param {number} checklistTemplateSectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateSectionApi
     */
    public checklistTemplateSectionControllerReadOne(checklistTemplateId: number, checklistTemplateSectionId: number, options?: AxiosRequestConfig) {
        return ChecklistTemplateSectionApiFp(this.configuration).checklistTemplateSectionControllerReadOne(checklistTemplateId, checklistTemplateSectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a ChecklistTemplateSection
     * @param {number} checklistTemplateId 
     * @param {number} checklistTemplateSectionId 
     * @param {UpdateChecklistTemplateSectionDto} updateChecklistTemplateSectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChecklistTemplateSectionApi
     */
    public checklistTemplateSectionControllerUpdate(checklistTemplateId: number, checklistTemplateSectionId: number, updateChecklistTemplateSectionDto: UpdateChecklistTemplateSectionDto, options?: AxiosRequestConfig) {
        return ChecklistTemplateSectionApiFp(this.configuration).checklistTemplateSectionControllerUpdate(checklistTemplateId, checklistTemplateSectionId, updateChecklistTemplateSectionDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an Address for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCompanyCreate: async (companyId: string, createAddressDto: CreateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addressControllerCompanyCreate', 'companyId', companyId)
            // verify required parameter 'createAddressDto' is not null or undefined
            assertParamExists('addressControllerCompanyCreate', 'createAddressDto', createAddressDto)
            const localVarPath = `/company/{companyId}/addresses`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Address for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCompanyDelete: async (companyId: string, addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addressControllerCompanyDelete', 'companyId', companyId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerCompanyDelete', 'addressId', addressId)
            const localVarPath = `/company/{companyId}/addresses/{addressId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all Addresses for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCompanyReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addressControllerCompanyReadCollection', 'companyId', companyId)
            const localVarPath = `/company/{companyId}/addresses`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an Address for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCompanyUpdate: async (companyId: string, addressId: string, updateAddressDto: UpdateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addressControllerCompanyUpdate', 'companyId', companyId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerCompanyUpdate', 'addressId', addressId)
            // verify required parameter 'updateAddressDto' is not null or undefined
            assertParamExists('addressControllerCompanyUpdate', 'updateAddressDto', updateAddressDto)
            const localVarPath = `/company/{companyId}/addresses/{addressId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/addon-confirmation`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/addon-confirmation/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonConfirmationReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/addon-confirmation`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/addon-pdf`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/addon-pdf/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonPdfReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/addon-pdf`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/addon-project-text`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/addon-project-text/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyAddonProjectTextReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/addon-project-text`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-new`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-new/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipNewReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-new`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-used`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-used/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-ownership-used`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial-return`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial-return/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-commercial-return`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate: async (companyId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate', 'companyId', companyId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private-return`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete: async (companyId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete', 'companyId', companyId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private-return/{articleId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection', 'companyId', companyId)
            const localVarPath = `/article-usages/company/{companyId}/takeover-tenancy-private-return`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get companies user or company token can administer
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCanAdminister: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company/can-administer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an Address for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerCompanyCreate(companyId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerCompanyCreate(companyId, createAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an Address for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerCompanyDelete(companyId: string, addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerCompanyDelete(companyId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all Addresses for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerCompanyReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerCompanyReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an Address for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerCompanyUpdate(companyId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerCompanyUpdate(companyId, addressId, updateAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonConfirmationCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonConfirmationCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonConfirmationDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonConfirmationDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonConfirmationReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonConfirmationReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonPdfCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonPdfCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonPdfDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonPdfDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonPdfReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonPdfReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonProjectTextCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonProjectTextCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonProjectTextDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonProjectTextDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyAddonProjectTextReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyAddonProjectTextReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get companies user or company token can administer
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyControllerCanAdminister(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyIDNDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyControllerCanAdminister(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * Create an Address for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCompanyCreate(companyId: string, createAddressDto: CreateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerCompanyCreate(companyId, createAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Address for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCompanyDelete(companyId: string, addressId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.addressControllerCompanyDelete(companyId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all Addresses for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCompanyReadCollection(companyId: string, options?: any): AxiosPromise<ResponseAddressDtoCollectionResponse> {
            return localVarFp.addressControllerCompanyReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an Address for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCompanyUpdate(companyId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerCompanyUpdate(companyId, addressId, updateAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyAddonConfirmationCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyAddonConfirmationDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonConfirmationReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyAddonConfirmationReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyAddonPdfCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyAddonPdfDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonPdfReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyAddonPdfReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyAddonProjectTextCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyAddonProjectTextDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyAddonProjectTextReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyAddonProjectTextReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Company
         * @summary 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get companies user or company token can administer
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyControllerCanAdminister(name?: string, options?: any): AxiosPromise<ResponseCompanyIDNDtoCollectionResponse> {
            return localVarFp.companyControllerCanAdminister(name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * Create an Address for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateAddressDto} createAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public addressControllerCompanyCreate(companyId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).addressControllerCompanyCreate(companyId, createAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Address for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public addressControllerCompanyDelete(companyId: string, addressId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).addressControllerCompanyDelete(companyId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all Addresses for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public addressControllerCompanyReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).addressControllerCompanyReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an Address for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} addressId 
     * @param {UpdateAddressDto} updateAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public addressControllerCompanyUpdate(companyId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).addressControllerCompanyUpdate(companyId, addressId, updateAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonConfirmation\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyAddonConfirmationCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyAddonConfirmationCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonConfirmation\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyAddonConfirmationDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyAddonConfirmationDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonConfirmation\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyAddonConfirmationReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyAddonConfirmationReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonPdf\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyAddonPdfCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyAddonPdfCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonPdf\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyAddonPdfDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyAddonPdfDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonPdf\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyAddonPdfReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyAddonPdfReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonProjectText\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyAddonProjectTextCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyAddonProjectTextCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonProjectText\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyAddonProjectTextDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyAddonProjectTextDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonProjectText\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyAddonProjectTextReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyAddonProjectTextReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipNew\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipNewCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipNewDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipNew\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipNewReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipUsed\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipUsedCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipUsedDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverOwnershipUsedReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyCommercial\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyCommercialReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialReturnCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialReturnDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyCommercialReturnReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyPrivate\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyPrivateReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateReturnCreate(companyId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId: string, articleId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateReturnDelete(companyId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Company
     * @summary 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).articleUsageControllerCompanyTakeoverTenancyPrivateReturnReadCollection(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get companies user or company token can administer
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyControllerCanAdminister(name?: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyControllerCanAdminister(name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConsentApi - axios parameter creator
 * @export
 */
export const ConsentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Consent Consent
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {CreateConsentCompanyConsentDto} createConsentCompanyConsentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyConsentControllerCreate: async (companyConsentId: string, companyId: number, createConsentCompanyConsentDto: CreateConsentCompanyConsentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyConsentControllerCreate', 'companyConsentId', companyConsentId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyConsentCompanyConsentControllerCreate', 'companyId', companyId)
            // verify required parameter 'createConsentCompanyConsentDto' is not null or undefined
            assertParamExists('companyConsentCompanyConsentControllerCreate', 'createConsentCompanyConsentDto', createConsentCompanyConsentDto)
            const localVarPath = `/company-consents/{companyConsentId}/companies/{companyId}/company-consent-company-consents`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createConsentCompanyConsentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Consent
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyConsentControllerDelete: async (companyConsentId: string, companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyConsentControllerDelete', 'companyConsentId', companyConsentId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyConsentCompanyConsentControllerDelete', 'companyId', companyId)
            const localVarPath = `/company-consents/{companyConsentId}/companies/{companyId}/company-consent-company-consents`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Consent Contact
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {CreateCompanyConsentCompanyContactDto} createCompanyConsentCompanyContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyContactControllerCreate: async (companyConsentId: string, companyId: number, createCompanyConsentCompanyContactDto: CreateCompanyConsentCompanyContactDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerCreate', 'companyConsentId', companyConsentId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerCreate', 'companyId', companyId)
            // verify required parameter 'createCompanyConsentCompanyContactDto' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerCreate', 'createCompanyConsentCompanyContactDto', createCompanyConsentCompanyContactDto)
            const localVarPath = `/company-consents/{companyConsentId}/companies/{companyId}/company-consent-company-contacts`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyConsentCompanyContactDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Consent Contact
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyContactControllerDelete: async (companyConsentId: string, companyId: number, userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerDelete', 'companyConsentId', companyConsentId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerDelete', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerDelete', 'userId', userId)
            const localVarPath = `/company-consents/{companyConsentId}/companies/{companyId}/company-consent-company-contacts/{userId}`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all Contacts of a Consent
         * @param {number} page 
         * @param {number} limit 
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyContactControllerReadCollection: async (page: number, limit: number, companyConsentId: string, companyId: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerReadCollection', 'limit', limit)
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerReadCollection', 'companyConsentId', companyConsentId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyConsentCompanyContactControllerReadCollection', 'companyId', companyId)
            const localVarPath = `/company-consents/{companyConsentId}/companies/{companyId}/company-consent-company-contacts`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if a ConsentCompany is approved
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerApproved: async (companyConsentId: string, companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyControllerApproved', 'companyConsentId', companyConsentId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyConsentCompanyControllerApproved', 'companyId', companyId)
            const localVarPath = `/company-consents/{companyConsentId}/companies/{companyId}/approved`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a ConsentCompany
         * @param {string} companyConsentId 
         * @param {CreateCompanyConsentCompanyDto} createCompanyConsentCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerCreate: async (companyConsentId: string, createCompanyConsentCompanyDto: CreateCompanyConsentCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyControllerCreate', 'companyConsentId', companyConsentId)
            // verify required parameter 'createCompanyConsentCompanyDto' is not null or undefined
            assertParamExists('companyConsentCompanyControllerCreate', 'createCompanyConsentCompanyDto', createCompanyConsentCompanyDto)
            const localVarPath = `/company-consents/{companyConsentId}/companies`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyConsentCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Consent Company
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerDelete: async (companyConsentId: string, companyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyControllerDelete', 'companyConsentId', companyConsentId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyConsentCompanyControllerDelete', 'companyId', companyId)
            const localVarPath = `/company-consents/{companyConsentId}/companies/{companyId}`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all ConsentCompanies of a Consent
         * @param {string} companyConsentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerReadCollection: async (companyConsentId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyControllerReadCollection', 'companyConsentId', companyConsentId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('companyConsentCompanyControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyConsentCompanyControllerReadCollection', 'limit', limit)
            const localVarPath = `/company-consents/{companyConsentId}/companies`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a ConsentCompany
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {UpdateCompanyConsentCompanyDto} updateCompanyConsentCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerUpdate: async (companyConsentId: string, companyId: number, updateCompanyConsentCompanyDto: UpdateCompanyConsentCompanyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentCompanyControllerUpdate', 'companyConsentId', companyConsentId)
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('companyConsentCompanyControllerUpdate', 'companyId', companyId)
            // verify required parameter 'updateCompanyConsentCompanyDto' is not null or undefined
            assertParamExists('companyConsentCompanyControllerUpdate', 'updateCompanyConsentCompanyDto', updateCompanyConsentCompanyDto)
            const localVarPath = `/company-consents/{companyConsentId}/companies/{companyId}`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)))
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyConsentCompanyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If receiving company has no admins, at least one ConsentContact must be included
         * @summary Create a Consent
         * @param {CreateCompanyConsentDto} createCompanyConsentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerCreate: async (createCompanyConsentDto: CreateCompanyConsentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCompanyConsentDto' is not null or undefined
            assertParamExists('companyConsentControllerCreate', 'createCompanyConsentDto', createCompanyConsentDto)
            const localVarPath = `/company-consents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyConsentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Consent
         * @param {string} companyConsentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerDelete: async (companyConsentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentControllerDelete', 'companyConsentId', companyConsentId)
            const localVarPath = `/company-consents/{companyConsentId}`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a Consent
         * @param {string} companyConsentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerRead: async (companyConsentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyConsentId' is not null or undefined
            assertParamExists('companyConsentControllerRead', 'companyConsentId', companyConsentId)
            const localVarPath = `/company-consents/{companyConsentId}`
                .replace(`{${"companyConsentId"}}`, encodeURIComponent(String(companyConsentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all Consents of request user
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerReadCollection: async (page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('companyConsentControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('companyConsentControllerReadCollection', 'limit', limit)
            const localVarPath = `/company-consents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all Consents of Request User that should be consented to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerReadMissingUserConsent: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/company-consents/missing-user-consents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsentApi - functional programming interface
 * @export
 */
export const ConsentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConsentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Consent Consent
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {CreateConsentCompanyConsentDto} createConsentCompanyConsentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyConsentControllerCreate(companyConsentId: string, companyId: number, createConsentCompanyConsentDto: CreateConsentCompanyConsentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentCompanyConsentDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyConsentControllerCreate(companyConsentId, companyId, createConsentCompanyConsentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Consent
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyConsentControllerDelete(companyConsentId: string, companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyConsentControllerDelete(companyConsentId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Consent Contact
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {CreateCompanyConsentCompanyContactDto} createCompanyConsentCompanyContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyContactControllerCreate(companyConsentId: string, companyId: number, createCompanyConsentCompanyContactDto: CreateCompanyConsentCompanyContactDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentCompanyContactDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyContactControllerCreate(companyConsentId, companyId, createCompanyConsentCompanyContactDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Consent Contact
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyContactControllerDelete(companyConsentId: string, companyId: number, userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyContactControllerDelete(companyConsentId, companyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all Contacts of a Consent
         * @param {number} page 
         * @param {number} limit 
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyContactControllerReadCollection(page: number, limit: number, companyConsentId: string, companyId: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentCompanyContactDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyContactControllerReadCollection(page, limit, companyConsentId, companyId, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if a ConsentCompany is approved
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyControllerApproved(companyConsentId: string, companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovedResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyControllerApproved(companyConsentId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a ConsentCompany
         * @param {string} companyConsentId 
         * @param {CreateCompanyConsentCompanyDto} createCompanyConsentCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyControllerCreate(companyConsentId: string, createCompanyConsentCompanyDto: CreateCompanyConsentCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentCompanyDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyControllerCreate(companyConsentId, createCompanyConsentCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Consent Company
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyControllerDelete(companyConsentId: string, companyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyControllerDelete(companyConsentId, companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all ConsentCompanies of a Consent
         * @param {string} companyConsentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyControllerReadCollection(companyConsentId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentCompanyDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyControllerReadCollection(companyConsentId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a ConsentCompany
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {UpdateCompanyConsentCompanyDto} updateCompanyConsentCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentCompanyControllerUpdate(companyConsentId: string, companyId: number, updateCompanyConsentCompanyDto: UpdateCompanyConsentCompanyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentCompanyDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentCompanyControllerUpdate(companyConsentId, companyId, updateCompanyConsentCompanyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * If receiving company has no admins, at least one ConsentContact must be included
         * @summary Create a Consent
         * @param {CreateCompanyConsentDto} createCompanyConsentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentControllerCreate(createCompanyConsentDto: CreateCompanyConsentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentControllerCreate(createCompanyConsentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Consent
         * @param {string} companyConsentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentControllerDelete(companyConsentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentControllerDelete(companyConsentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a Consent
         * @param {string} companyConsentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentControllerRead(companyConsentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentControllerRead(companyConsentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all Consents of request user
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentCollectionItemDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentControllerReadCollection(page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all Consents of Request User that should be consented to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyConsentControllerReadMissingUserConsent(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCompanyConsentIDNDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyConsentControllerReadMissingUserConsent(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConsentApi - factory interface
 * @export
 */
export const ConsentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConsentApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Consent Consent
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {CreateConsentCompanyConsentDto} createConsentCompanyConsentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyConsentControllerCreate(companyConsentId: string, companyId: number, createConsentCompanyConsentDto: CreateConsentCompanyConsentDto, options?: any): AxiosPromise<ResponseCompanyConsentCompanyConsentDtoEntityResponse> {
            return localVarFp.companyConsentCompanyConsentControllerCreate(companyConsentId, companyId, createConsentCompanyConsentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Consent
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyConsentControllerDelete(companyConsentId: string, companyId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.companyConsentCompanyConsentControllerDelete(companyConsentId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Consent Contact
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {CreateCompanyConsentCompanyContactDto} createCompanyConsentCompanyContactDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyContactControllerCreate(companyConsentId: string, companyId: number, createCompanyConsentCompanyContactDto: CreateCompanyConsentCompanyContactDto, options?: any): AxiosPromise<ResponseCompanyConsentCompanyContactDtoEntityResponse> {
            return localVarFp.companyConsentCompanyContactControllerCreate(companyConsentId, companyId, createCompanyConsentCompanyContactDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Consent Contact
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyContactControllerDelete(companyConsentId: string, companyId: number, userId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.companyConsentCompanyContactControllerDelete(companyConsentId, companyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all Contacts of a Consent
         * @param {number} page 
         * @param {number} limit 
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyContactControllerReadCollection(page: number, limit: number, companyConsentId: string, companyId: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponseCompanyConsentCompanyContactDtoPageResponse> {
            return localVarFp.companyConsentCompanyContactControllerReadCollection(page, limit, companyConsentId, companyId, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if a ConsentCompany is approved
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerApproved(companyConsentId: string, companyId: number, options?: any): AxiosPromise<ApprovedResponseDto> {
            return localVarFp.companyConsentCompanyControllerApproved(companyConsentId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a ConsentCompany
         * @param {string} companyConsentId 
         * @param {CreateCompanyConsentCompanyDto} createCompanyConsentCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerCreate(companyConsentId: string, createCompanyConsentCompanyDto: CreateCompanyConsentCompanyDto, options?: any): AxiosPromise<ResponseCompanyConsentCompanyDtoEntityResponse> {
            return localVarFp.companyConsentCompanyControllerCreate(companyConsentId, createCompanyConsentCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Consent Company
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerDelete(companyConsentId: string, companyId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.companyConsentCompanyControllerDelete(companyConsentId, companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all ConsentCompanies of a Consent
         * @param {string} companyConsentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerReadCollection(companyConsentId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponseCompanyConsentCompanyDtoPageResponse> {
            return localVarFp.companyConsentCompanyControllerReadCollection(companyConsentId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a ConsentCompany
         * @param {string} companyConsentId 
         * @param {number} companyId 
         * @param {UpdateCompanyConsentCompanyDto} updateCompanyConsentCompanyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentCompanyControllerUpdate(companyConsentId: string, companyId: number, updateCompanyConsentCompanyDto: UpdateCompanyConsentCompanyDto, options?: any): AxiosPromise<ResponseCompanyConsentCompanyDtoEntityResponse> {
            return localVarFp.companyConsentCompanyControllerUpdate(companyConsentId, companyId, updateCompanyConsentCompanyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * If receiving company has no admins, at least one ConsentContact must be included
         * @summary Create a Consent
         * @param {CreateCompanyConsentDto} createCompanyConsentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerCreate(createCompanyConsentDto: CreateCompanyConsentDto, options?: any): AxiosPromise<ResponseCompanyConsentDtoEntityResponse> {
            return localVarFp.companyConsentControllerCreate(createCompanyConsentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Consent
         * @param {string} companyConsentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerDelete(companyConsentId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.companyConsentControllerDelete(companyConsentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a Consent
         * @param {string} companyConsentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerRead(companyConsentId: string, options?: any): AxiosPromise<ResponseCompanyConsentDtoEntityResponse> {
            return localVarFp.companyConsentControllerRead(companyConsentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all Consents of request user
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponseCompanyConsentCollectionItemDtoPageResponse> {
            return localVarFp.companyConsentControllerReadCollection(page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all Consents of Request User that should be consented to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyConsentControllerReadMissingUserConsent(options?: any): AxiosPromise<ResponseCompanyConsentIDNDtoCollectionResponse> {
            return localVarFp.companyConsentControllerReadMissingUserConsent(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConsentApi - object-oriented interface
 * @export
 * @class ConsentApi
 * @extends {BaseAPI}
 */
export class ConsentApi extends BaseAPI {
    /**
     * 
     * @summary Create a Consent Consent
     * @param {string} companyConsentId 
     * @param {number} companyId 
     * @param {CreateConsentCompanyConsentDto} createConsentCompanyConsentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyConsentControllerCreate(companyConsentId: string, companyId: number, createConsentCompanyConsentDto: CreateConsentCompanyConsentDto, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyConsentControllerCreate(companyConsentId, companyId, createConsentCompanyConsentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Consent
     * @param {string} companyConsentId 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyConsentControllerDelete(companyConsentId: string, companyId: number, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyConsentControllerDelete(companyConsentId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Consent Contact
     * @param {string} companyConsentId 
     * @param {number} companyId 
     * @param {CreateCompanyConsentCompanyContactDto} createCompanyConsentCompanyContactDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyContactControllerCreate(companyConsentId: string, companyId: number, createCompanyConsentCompanyContactDto: CreateCompanyConsentCompanyContactDto, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyContactControllerCreate(companyConsentId, companyId, createCompanyConsentCompanyContactDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Consent Contact
     * @param {string} companyConsentId 
     * @param {number} companyId 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyContactControllerDelete(companyConsentId: string, companyId: number, userId: number, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyContactControllerDelete(companyConsentId, companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all Contacts of a Consent
     * @param {number} page 
     * @param {number} limit 
     * @param {string} companyConsentId 
     * @param {number} companyId 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyContactControllerReadCollection(page: number, limit: number, companyConsentId: string, companyId: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyContactControllerReadCollection(page, limit, companyConsentId, companyId, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if a ConsentCompany is approved
     * @param {string} companyConsentId 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyControllerApproved(companyConsentId: string, companyId: number, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyControllerApproved(companyConsentId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a ConsentCompany
     * @param {string} companyConsentId 
     * @param {CreateCompanyConsentCompanyDto} createCompanyConsentCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyControllerCreate(companyConsentId: string, createCompanyConsentCompanyDto: CreateCompanyConsentCompanyDto, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyControllerCreate(companyConsentId, createCompanyConsentCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Consent Company
     * @param {string} companyConsentId 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyControllerDelete(companyConsentId: string, companyId: number, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyControllerDelete(companyConsentId, companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all ConsentCompanies of a Consent
     * @param {string} companyConsentId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyControllerReadCollection(companyConsentId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyControllerReadCollection(companyConsentId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a ConsentCompany
     * @param {string} companyConsentId 
     * @param {number} companyId 
     * @param {UpdateCompanyConsentCompanyDto} updateCompanyConsentCompanyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentCompanyControllerUpdate(companyConsentId: string, companyId: number, updateCompanyConsentCompanyDto: UpdateCompanyConsentCompanyDto, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentCompanyControllerUpdate(companyConsentId, companyId, updateCompanyConsentCompanyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * If receiving company has no admins, at least one ConsentContact must be included
     * @summary Create a Consent
     * @param {CreateCompanyConsentDto} createCompanyConsentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentControllerCreate(createCompanyConsentDto: CreateCompanyConsentDto, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentControllerCreate(createCompanyConsentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Consent
     * @param {string} companyConsentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentControllerDelete(companyConsentId: string, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentControllerDelete(companyConsentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a Consent
     * @param {string} companyConsentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentControllerRead(companyConsentId: string, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentControllerRead(companyConsentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all Consents of request user
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentControllerReadCollection(page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all Consents of Request User that should be consented to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsentApi
     */
    public companyConsentControllerReadMissingUserConsent(options?: AxiosRequestConfig) {
        return ConsentApiFp(this.configuration).companyConsentControllerReadMissingUserConsent(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContractorAddonApi - axios parameter creator
 * @export
 */
export const ContractorAddonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a ModelTrait for ContractorAddon
         * @summary 
         * @param {string} contractorAddonId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonCreate: async (contractorAddonId: string, createModelTraitDto: CreateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorAddonId' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonCreate', 'contractorAddonId', contractorAddonId)
            // verify required parameter 'createModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonCreate', 'createModelTraitDto', createModelTraitDto)
            const localVarPath = `/contractor-addon/{contractorAddonId}/model-traits`
                .replace(`{${"contractorAddonId"}}`, encodeURIComponent(String(contractorAddonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ModelTrait for ContractorAddon
         * @summary 
         * @param {string} contractorAddonId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonDelete: async (contractorAddonId: string, modelTraitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorAddonId' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonDelete', 'contractorAddonId', contractorAddonId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonDelete', 'modelTraitId', modelTraitId)
            const localVarPath = `/contractor-addon/{contractorAddonId}/model-traits/{modelTraitId}`
                .replace(`{${"contractorAddonId"}}`, encodeURIComponent(String(contractorAddonId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all traits on ContractorAddon by modelId
         * @summary 
         * @param {string} contractorAddonId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonReadCollection: async (contractorAddonId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorAddonId' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonReadCollection', 'contractorAddonId', contractorAddonId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonReadCollection', 'limit', limit)
            const localVarPath = `/contractor-addon/{contractorAddonId}/model-traits`
                .replace(`{${"contractorAddonId"}}`, encodeURIComponent(String(contractorAddonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for ContractorAddon filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonReadCollectionOnModel: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonReadCollectionOnModel', 'name', name)
            const localVarPath = `/contractor-addon/model-traits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for ContractorAddon
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonReadNameCollection: async (name: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonReadNameCollection', 'name', name)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonReadNameCollection', 'value', value)
            const localVarPath = `/contractor-addon/model-traits/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ModelTrait for ContractorAddon
         * @summary 
         * @param {string} contractorAddonId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonUpdate: async (contractorAddonId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractorAddonId' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonUpdate', 'contractorAddonId', contractorAddonId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonUpdate', 'modelTraitId', modelTraitId)
            // verify required parameter 'updateModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerContractorAddonUpdate', 'updateModelTraitDto', updateModelTraitDto)
            const localVarPath = `/contractor-addon/{contractorAddonId}/model-traits/{modelTraitId}`
                .replace(`{${"contractorAddonId"}}`, encodeURIComponent(String(contractorAddonId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContractorAddonApi - functional programming interface
 * @export
 */
export const ContractorAddonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContractorAddonApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a ModelTrait for ContractorAddon
         * @summary 
         * @param {string} contractorAddonId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerContractorAddonCreate(contractorAddonId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerContractorAddonCreate(contractorAddonId, createModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ModelTrait for ContractorAddon
         * @summary 
         * @param {string} contractorAddonId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerContractorAddonDelete(contractorAddonId: string, modelTraitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerContractorAddonDelete(contractorAddonId, modelTraitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all traits on ContractorAddon by modelId
         * @summary 
         * @param {string} contractorAddonId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerContractorAddonReadCollection(contractorAddonId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerContractorAddonReadCollection(contractorAddonId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for ContractorAddon filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerContractorAddonReadCollectionOnModel(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerContractorAddonReadCollectionOnModel(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for ContractorAddon
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerContractorAddonReadNameCollection(name: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerContractorAddonReadNameCollection(name, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a ModelTrait for ContractorAddon
         * @summary 
         * @param {string} contractorAddonId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerContractorAddonUpdate(contractorAddonId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerContractorAddonUpdate(contractorAddonId, modelTraitId, updateModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContractorAddonApi - factory interface
 * @export
 */
export const ContractorAddonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContractorAddonApiFp(configuration)
    return {
        /**
         * Create a ModelTrait for ContractorAddon
         * @summary 
         * @param {string} contractorAddonId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonCreate(contractorAddonId: string, createModelTraitDto: CreateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerContractorAddonCreate(contractorAddonId, createModelTraitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ModelTrait for ContractorAddon
         * @summary 
         * @param {string} contractorAddonId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonDelete(contractorAddonId: string, modelTraitId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.modelTraitControllerContractorAddonDelete(contractorAddonId, modelTraitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all traits on ContractorAddon by modelId
         * @summary 
         * @param {string} contractorAddonId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonReadCollection(contractorAddonId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseModelTraitDtoPageResponse> {
            return localVarFp.modelTraitControllerContractorAddonReadCollection(contractorAddonId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for ContractorAddon filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonReadCollectionOnModel(name: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerContractorAddonReadCollectionOnModel(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for ContractorAddon
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonReadNameCollection(name: string, value: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerContractorAddonReadNameCollection(name, value, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ModelTrait for ContractorAddon
         * @summary 
         * @param {string} contractorAddonId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerContractorAddonUpdate(contractorAddonId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerContractorAddonUpdate(contractorAddonId, modelTraitId, updateModelTraitDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContractorAddonApi - object-oriented interface
 * @export
 * @class ContractorAddonApi
 * @extends {BaseAPI}
 */
export class ContractorAddonApi extends BaseAPI {
    /**
     * Create a ModelTrait for ContractorAddon
     * @summary 
     * @param {string} contractorAddonId 
     * @param {CreateModelTraitDto} createModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorAddonApi
     */
    public modelTraitControllerContractorAddonCreate(contractorAddonId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig) {
        return ContractorAddonApiFp(this.configuration).modelTraitControllerContractorAddonCreate(contractorAddonId, createModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ModelTrait for ContractorAddon
     * @summary 
     * @param {string} contractorAddonId 
     * @param {number} modelTraitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorAddonApi
     */
    public modelTraitControllerContractorAddonDelete(contractorAddonId: string, modelTraitId: number, options?: AxiosRequestConfig) {
        return ContractorAddonApiFp(this.configuration).modelTraitControllerContractorAddonDelete(contractorAddonId, modelTraitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all traits on ContractorAddon by modelId
     * @summary 
     * @param {string} contractorAddonId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorAddonApi
     */
    public modelTraitControllerContractorAddonReadCollection(contractorAddonId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ContractorAddonApiFp(this.configuration).modelTraitControllerContractorAddonReadCollection(contractorAddonId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for ContractorAddon filtered by name
     * @summary 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorAddonApi
     */
    public modelTraitControllerContractorAddonReadCollectionOnModel(name: string, options?: AxiosRequestConfig) {
        return ContractorAddonApiFp(this.configuration).modelTraitControllerContractorAddonReadCollectionOnModel(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for ContractorAddon
     * @summary 
     * @param {string} name 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorAddonApi
     */
    public modelTraitControllerContractorAddonReadNameCollection(name: string, value: string, options?: AxiosRequestConfig) {
        return ContractorAddonApiFp(this.configuration).modelTraitControllerContractorAddonReadNameCollection(name, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ModelTrait for ContractorAddon
     * @summary 
     * @param {string} contractorAddonId 
     * @param {number} modelTraitId 
     * @param {UpdateModelTraitDto} updateModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContractorAddonApi
     */
    public modelTraitControllerContractorAddonUpdate(contractorAddonId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig) {
        return ContractorAddonApiFp(this.configuration).modelTraitControllerContractorAddonUpdate(contractorAddonId, modelTraitId, updateModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CrmUserApi - axios parameter creator
 * @export
 */
export const CrmUserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an Address for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCrmUserCreate: async (crmUserId: string, createAddressDto: CreateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('addressControllerCrmUserCreate', 'crmUserId', crmUserId)
            // verify required parameter 'createAddressDto' is not null or undefined
            assertParamExists('addressControllerCrmUserCreate', 'createAddressDto', createAddressDto)
            const localVarPath = `/crmUser/{crmUserId}/addresses`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Address for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCrmUserDelete: async (crmUserId: string, addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('addressControllerCrmUserDelete', 'crmUserId', crmUserId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerCrmUserDelete', 'addressId', addressId)
            const localVarPath = `/crmUser/{crmUserId}/addresses/{addressId}`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all Addresses for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCrmUserReadCollection: async (crmUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('addressControllerCrmUserReadCollection', 'crmUserId', crmUserId)
            const localVarPath = `/crmUser/{crmUserId}/addresses`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an Address for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCrmUserUpdate: async (crmUserId: string, addressId: string, updateAddressDto: UpdateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('addressControllerCrmUserUpdate', 'crmUserId', crmUserId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerCrmUserUpdate', 'addressId', addressId)
            // verify required parameter 'updateAddressDto' is not null or undefined
            assertParamExists('addressControllerCrmUserUpdate', 'updateAddressDto', updateAddressDto)
            const localVarPath = `/crmUser/{crmUserId}/addresses/{addressId}`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Attach a CRMUser to a User with same email
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerAttachCrmUserToUser: async (crmUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('crmUserControllerAttachCrmUserToUser', 'crmUserId', crmUserId)
            const localVarPath = `/crm-users/{crmUserId}/attach`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a CRMUser
         * @param {CreateCrmUserDto} createCrmUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerCreate: async (createCrmUserDto: CreateCrmUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCrmUserDto' is not null or undefined
            assertParamExists('crmUserControllerCreate', 'createCrmUserDto', createCrmUserDto)
            const localVarPath = `/crm-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCrmUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a CrmUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerDelete: async (crmUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('crmUserControllerDelete', 'crmUserId', crmUserId)
            const localVarPath = `/crm-users/{crmUserId}`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Detach a CRMUser from User
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerDetachCrmUserFromUser: async (crmUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('crmUserControllerDetachCrmUserFromUser', 'crmUserId', crmUserId)
            const localVarPath = `/crm-users/{crmUserId}/detach`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a CRMUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerRead: async (crmUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('crmUserControllerRead', 'crmUserId', crmUserId)
            const localVarPath = `/crm-users/{crmUserId}`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {CrmUserPageOptionsOrderBy} [orderBy] 
         * @param {number} [objectId] Filter results on ObjectId
         * @param {number} [projectId] Filter results on ProjectId. Designed for future use
         * @param {number} [companyId] Filter results on CompanyId.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerReadCollection: async (page: number, limit: number, order?: Order, orderBy?: CrmUserPageOptionsOrderBy, objectId?: number, projectId?: number, companyId?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('crmUserControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('crmUserControllerReadCollection', 'limit', limit)
            const localVarPath = `/crm-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (objectId !== undefined) {
                localVarQueryParameter['ObjectId'] = objectId;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['ProjectId'] = projectId;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['CompanyId'] = companyId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns UserInvites from a given CRMUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerReadUserInvite: async (crmUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('crmUserControllerReadUserInvite', 'crmUserId', crmUserId)
            const localVarPath = `/crm-users/{crmUserId}/user-invites`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a User with same email as CRMUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerReadUserWithEqualEmail: async (crmUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('crmUserControllerReadUserWithEqualEmail', 'crmUserId', crmUserId)
            const localVarPath = `/crm-users/{crmUserId}/user`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an CrmUser
         * @param {string} crmUserId 
         * @param {UpdateCrmUserDto} updateCrmUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerUpdate: async (crmUserId: string, updateCrmUserDto: UpdateCrmUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'crmUserId' is not null or undefined
            assertParamExists('crmUserControllerUpdate', 'crmUserId', crmUserId)
            // verify required parameter 'updateCrmUserDto' is not null or undefined
            assertParamExists('crmUserControllerUpdate', 'updateCrmUserDto', updateCrmUserDto)
            const localVarPath = `/crm-users/{crmUserId}`
                .replace(`{${"crmUserId"}}`, encodeURIComponent(String(crmUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCrmUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CrmUserApi - functional programming interface
 * @export
 */
export const CrmUserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CrmUserApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an Address for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerCrmUserCreate(crmUserId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerCrmUserCreate(crmUserId, createAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an Address for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerCrmUserDelete(crmUserId: string, addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerCrmUserDelete(crmUserId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all Addresses for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerCrmUserReadCollection(crmUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerCrmUserReadCollection(crmUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an Address for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerCrmUserUpdate(crmUserId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerCrmUserUpdate(crmUserId, addressId, updateAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Attach a CRMUser to a User with same email
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUserControllerAttachCrmUserToUser(crmUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCrmUserDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUserControllerAttachCrmUserToUser(crmUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a CRMUser
         * @param {CreateCrmUserDto} createCrmUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUserControllerCreate(createCrmUserDto: CreateCrmUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCrmUserDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUserControllerCreate(createCrmUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a CrmUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUserControllerDelete(crmUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUserControllerDelete(crmUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Detach a CRMUser from User
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUserControllerDetachCrmUserFromUser(crmUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUserControllerDetachCrmUserFromUser(crmUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a CRMUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUserControllerRead(crmUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCrmUserDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUserControllerRead(crmUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {CrmUserPageOptionsOrderBy} [orderBy] 
         * @param {number} [objectId] Filter results on ObjectId
         * @param {number} [projectId] Filter results on ProjectId. Designed for future use
         * @param {number} [companyId] Filter results on CompanyId.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUserControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: CrmUserPageOptionsOrderBy, objectId?: number, projectId?: number, companyId?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCrmUserDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUserControllerReadCollection(page, limit, order, orderBy, objectId, projectId, companyId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns UserInvites from a given CRMUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUserControllerReadUserInvite(crmUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserInviteDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUserControllerReadUserInvite(crmUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a User with same email as CRMUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUserControllerReadUserWithEqualEmail(crmUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCrmUserDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUserControllerReadUserWithEqualEmail(crmUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an CrmUser
         * @param {string} crmUserId 
         * @param {UpdateCrmUserDto} updateCrmUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async crmUserControllerUpdate(crmUserId: string, updateCrmUserDto: UpdateCrmUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCrmUserDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.crmUserControllerUpdate(crmUserId, updateCrmUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CrmUserApi - factory interface
 * @export
 */
export const CrmUserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CrmUserApiFp(configuration)
    return {
        /**
         * Create an Address for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCrmUserCreate(crmUserId: string, createAddressDto: CreateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerCrmUserCreate(crmUserId, createAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Address for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCrmUserDelete(crmUserId: string, addressId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.addressControllerCrmUserDelete(crmUserId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all Addresses for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCrmUserReadCollection(crmUserId: string, options?: any): AxiosPromise<ResponseAddressDtoCollectionResponse> {
            return localVarFp.addressControllerCrmUserReadCollection(crmUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an Address for CrmUser
         * @summary 
         * @param {string} crmUserId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerCrmUserUpdate(crmUserId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerCrmUserUpdate(crmUserId, addressId, updateAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Attach a CRMUser to a User with same email
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerAttachCrmUserToUser(crmUserId: string, options?: any): AxiosPromise<ResponseCrmUserDtoEntityResponse> {
            return localVarFp.crmUserControllerAttachCrmUserToUser(crmUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a CRMUser
         * @param {CreateCrmUserDto} createCrmUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerCreate(createCrmUserDto: CreateCrmUserDto, options?: any): AxiosPromise<ResponseCrmUserDtoEntityResponse> {
            return localVarFp.crmUserControllerCreate(createCrmUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a CrmUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerDelete(crmUserId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.crmUserControllerDelete(crmUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Detach a CRMUser from User
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerDetachCrmUserFromUser(crmUserId: string, options?: any): AxiosPromise<void> {
            return localVarFp.crmUserControllerDetachCrmUserFromUser(crmUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a CRMUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerRead(crmUserId: string, options?: any): AxiosPromise<ResponseCrmUserDtoEntityResponse> {
            return localVarFp.crmUserControllerRead(crmUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {CrmUserPageOptionsOrderBy} [orderBy] 
         * @param {number} [objectId] Filter results on ObjectId
         * @param {number} [projectId] Filter results on ProjectId. Designed for future use
         * @param {number} [companyId] Filter results on CompanyId.
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: CrmUserPageOptionsOrderBy, objectId?: number, projectId?: number, companyId?: number, search?: string, options?: any): AxiosPromise<ResponseCrmUserDtoPageResponse> {
            return localVarFp.crmUserControllerReadCollection(page, limit, order, orderBy, objectId, projectId, companyId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns UserInvites from a given CRMUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerReadUserInvite(crmUserId: string, options?: any): AxiosPromise<ResponseUserInviteDtoEntityResponse> {
            return localVarFp.crmUserControllerReadUserInvite(crmUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a User with same email as CRMUser
         * @param {string} crmUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerReadUserWithEqualEmail(crmUserId: string, options?: any): AxiosPromise<ResponseCrmUserDtoEntityResponse> {
            return localVarFp.crmUserControllerReadUserWithEqualEmail(crmUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an CrmUser
         * @param {string} crmUserId 
         * @param {UpdateCrmUserDto} updateCrmUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        crmUserControllerUpdate(crmUserId: string, updateCrmUserDto: UpdateCrmUserDto, options?: any): AxiosPromise<ResponseCrmUserDtoEntityResponse> {
            return localVarFp.crmUserControllerUpdate(crmUserId, updateCrmUserDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CrmUserApi - object-oriented interface
 * @export
 * @class CrmUserApi
 * @extends {BaseAPI}
 */
export class CrmUserApi extends BaseAPI {
    /**
     * Create an Address for CrmUser
     * @summary 
     * @param {string} crmUserId 
     * @param {CreateAddressDto} createAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public addressControllerCrmUserCreate(crmUserId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).addressControllerCrmUserCreate(crmUserId, createAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Address for CrmUser
     * @summary 
     * @param {string} crmUserId 
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public addressControllerCrmUserDelete(crmUserId: string, addressId: string, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).addressControllerCrmUserDelete(crmUserId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all Addresses for CrmUser
     * @summary 
     * @param {string} crmUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public addressControllerCrmUserReadCollection(crmUserId: string, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).addressControllerCrmUserReadCollection(crmUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an Address for CrmUser
     * @summary 
     * @param {string} crmUserId 
     * @param {string} addressId 
     * @param {UpdateAddressDto} updateAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public addressControllerCrmUserUpdate(crmUserId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).addressControllerCrmUserUpdate(crmUserId, addressId, updateAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Attach a CRMUser to a User with same email
     * @param {string} crmUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public crmUserControllerAttachCrmUserToUser(crmUserId: string, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).crmUserControllerAttachCrmUserToUser(crmUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a CRMUser
     * @param {CreateCrmUserDto} createCrmUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public crmUserControllerCreate(createCrmUserDto: CreateCrmUserDto, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).crmUserControllerCreate(createCrmUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a CrmUser
     * @param {string} crmUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public crmUserControllerDelete(crmUserId: string, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).crmUserControllerDelete(crmUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Detach a CRMUser from User
     * @param {string} crmUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public crmUserControllerDetachCrmUserFromUser(crmUserId: string, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).crmUserControllerDetachCrmUserFromUser(crmUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a CRMUser
     * @param {string} crmUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public crmUserControllerRead(crmUserId: string, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).crmUserControllerRead(crmUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {CrmUserPageOptionsOrderBy} [orderBy] 
     * @param {number} [objectId] Filter results on ObjectId
     * @param {number} [projectId] Filter results on ProjectId. Designed for future use
     * @param {number} [companyId] Filter results on CompanyId.
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public crmUserControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: CrmUserPageOptionsOrderBy, objectId?: number, projectId?: number, companyId?: number, search?: string, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).crmUserControllerReadCollection(page, limit, order, orderBy, objectId, projectId, companyId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns UserInvites from a given CRMUser
     * @param {string} crmUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public crmUserControllerReadUserInvite(crmUserId: string, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).crmUserControllerReadUserInvite(crmUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a User with same email as CRMUser
     * @param {string} crmUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public crmUserControllerReadUserWithEqualEmail(crmUserId: string, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).crmUserControllerReadUserWithEqualEmail(crmUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an CrmUser
     * @param {string} crmUserId 
     * @param {UpdateCrmUserDto} updateCrmUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CrmUserApi
     */
    public crmUserControllerUpdate(crmUserId: string, updateCrmUserDto: UpdateCrmUserDto, options?: AxiosRequestConfig) {
        return CrmUserApiFp(this.configuration).crmUserControllerUpdate(crmUserId, updateCrmUserDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CryptoApi - axios parameter creator
 * @export
 */
export const CryptoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerHash: async (body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('cryptoControllerHash', 'body', body)
            const localVarPath = `/crypto/hash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CryptoApi - functional programming interface
 * @export
 */
export const CryptoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CryptoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cryptoControllerHash(body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseHashDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cryptoControllerHash(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CryptoApi - factory interface
 * @export
 */
export const CryptoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CryptoApiFp(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cryptoControllerHash(body: object, options?: any): AxiosPromise<ResponseHashDtoEntityResponse> {
            return localVarFp.cryptoControllerHash(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CryptoApi - object-oriented interface
 * @export
 * @class CryptoApi
 * @extends {BaseAPI}
 */
export class CryptoApi extends BaseAPI {
    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CryptoApi
     */
    public cryptoControllerHash(body: object, options?: AxiosRequestConfig) {
        return CryptoApiFp(this.configuration).cryptoControllerHash(body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FieldApi - axios parameter creator
 * @export
 */
export const FieldApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateFieldDto} createFieldDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerCreate: async (createFieldDto: CreateFieldDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFieldDto' is not null or undefined
            assertParamExists('fieldControllerCreate', 'createFieldDto', createFieldDto)
            const localVarPath = `/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFieldDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerDelete: async (fieldId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('fieldControllerDelete', 'fieldId', fieldId)
            const localVarPath = `/fields/{fieldId}`
                .replace(`{${"fieldId"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerRead: async (fieldId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('fieldControllerRead', 'fieldId', fieldId)
            const localVarPath = `/fields/{fieldId}`
                .replace(`{${"fieldId"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {FieldPageOptionsOrderBy} [orderBy] 
         * @param {number} [companyId] Filter results on CompanyId
         * @param {number} [status] Filter results on status
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerReadCollection: async (page: number, limit: number, order?: Order, orderBy?: FieldPageOptionsOrderBy, companyId?: number, status?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('fieldControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('fieldControllerReadCollection', 'limit', limit)
            const localVarPath = `/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (companyId !== undefined) {
                localVarQueryParameter['CompanyId'] = companyId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} fieldId 
         * @param {UpdateFieldDto} updateFieldDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerUpdate: async (fieldId: number, updateFieldDto: UpdateFieldDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('fieldControllerUpdate', 'fieldId', fieldId)
            // verify required parameter 'updateFieldDto' is not null or undefined
            assertParamExists('fieldControllerUpdate', 'updateFieldDto', updateFieldDto)
            const localVarPath = `/fields/{fieldId}`
                .replace(`{${"fieldId"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFieldDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldApi - functional programming interface
 * @export
 */
export const FieldApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateFieldDto} createFieldDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerCreate(createFieldDto: CreateFieldDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFieldDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerCreate(createFieldDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerDelete(fieldId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerDelete(fieldId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerRead(fieldId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFieldDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerRead(fieldId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {FieldPageOptionsOrderBy} [orderBy] 
         * @param {number} [companyId] Filter results on CompanyId
         * @param {number} [status] Filter results on status
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: FieldPageOptionsOrderBy, companyId?: number, status?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFieldDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerReadCollection(page, limit, order, orderBy, companyId, status, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} fieldId 
         * @param {UpdateFieldDto} updateFieldDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fieldControllerUpdate(fieldId: number, updateFieldDto: UpdateFieldDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFieldDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fieldControllerUpdate(fieldId, updateFieldDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FieldApi - factory interface
 * @export
 */
export const FieldApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateFieldDto} createFieldDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerCreate(createFieldDto: CreateFieldDto, options?: any): AxiosPromise<ResponseFieldDtoEntityResponse> {
            return localVarFp.fieldControllerCreate(createFieldDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerDelete(fieldId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fieldControllerDelete(fieldId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerRead(fieldId: number, options?: any): AxiosPromise<ResponseFieldDtoEntityResponse> {
            return localVarFp.fieldControllerRead(fieldId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {FieldPageOptionsOrderBy} [orderBy] 
         * @param {number} [companyId] Filter results on CompanyId
         * @param {number} [status] Filter results on status
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: FieldPageOptionsOrderBy, companyId?: number, status?: number, search?: string, options?: any): AxiosPromise<ResponseFieldDtoPageResponse> {
            return localVarFp.fieldControllerReadCollection(page, limit, order, orderBy, companyId, status, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} fieldId 
         * @param {UpdateFieldDto} updateFieldDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fieldControllerUpdate(fieldId: number, updateFieldDto: UpdateFieldDto, options?: any): AxiosPromise<ResponseFieldDtoEntityResponse> {
            return localVarFp.fieldControllerUpdate(fieldId, updateFieldDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FieldApi - object-oriented interface
 * @export
 * @class FieldApi
 * @extends {BaseAPI}
 */
export class FieldApi extends BaseAPI {
    /**
     * 
     * @param {CreateFieldDto} createFieldDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldControllerCreate(createFieldDto: CreateFieldDto, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldControllerCreate(createFieldDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fieldId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldControllerDelete(fieldId: number, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldControllerDelete(fieldId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fieldId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldControllerRead(fieldId: number, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldControllerRead(fieldId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {FieldPageOptionsOrderBy} [orderBy] 
     * @param {number} [companyId] Filter results on CompanyId
     * @param {number} [status] Filter results on status
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: FieldPageOptionsOrderBy, companyId?: number, status?: number, search?: string, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldControllerReadCollection(page, limit, order, orderBy, companyId, status, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} fieldId 
     * @param {UpdateFieldDto} updateFieldDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldApi
     */
    public fieldControllerUpdate(fieldId: number, updateFieldDto: UpdateFieldDto, options?: AxiosRequestConfig) {
        return FieldApiFp(this.configuration).fieldControllerUpdate(fieldId, updateFieldDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Post a file policy
         * @param {CreateFileSigningPolicyDto} createFileSigningPolicyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerSign: async (createFileSigningPolicyDto: CreateFileSigningPolicyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFileSigningPolicyDto' is not null or undefined
            assertParamExists('fileControllerSign', 'createFileSigningPolicyDto', createFileSigningPolicyDto)
            const localVarPath = `/files/upload/sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileSigningPolicyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Post a file policy
         * @param {CreateFileSigningPolicyDto} createFileSigningPolicyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileControllerSign(createFileSigningPolicyDto: CreateFileSigningPolicyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileSigningPolicyDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileControllerSign(createFileSigningPolicyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Post a file policy
         * @param {CreateFileSigningPolicyDto} createFileSigningPolicyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileControllerSign(createFileSigningPolicyDto: CreateFileSigningPolicyDto, options?: any): AxiosPromise<ResponseFileSigningPolicyDtoEntityResponse> {
            return localVarFp.fileControllerSign(createFileSigningPolicyDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Post a file policy
     * @param {CreateFileSigningPolicyDto} createFileSigningPolicyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public fileControllerSign(createFileSigningPolicyDto: CreateFileSigningPolicyDto, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).fileControllerSign(createFileSigningPolicyDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileUsageApi - axios parameter creator
 * @export
 */
export const FileUsageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Count all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesCount: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesCount', 'articleId', articleId)
            const localVarPath = `/file-usages/article/{articleId}/files/count`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesCreate: async (articleId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesCreate', 'articleId', articleId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/article/{articleId}/files`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesDelete: async (articleId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesDelete', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/article/{articleId}/files/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesReadCollection: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesReadCollection', 'articleId', articleId)
            const localVarPath = `/file-usages/article/{articleId}/files`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesSort: async (articleId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSort', 'articleId', articleId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/article/{articleId}/files/sort`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesSwap: async (articleId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSwap', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/article/{articleId}/files/swap/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesUpdate: async (articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesUpdate', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleFilesUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/article/{articleId}/files/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesCount: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesCount', 'articleId', articleId)
            const localVarPath = `/file-usages/article/{articleId}/images/count`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesCreate: async (articleId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesCreate', 'articleId', articleId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/article/{articleId}/images`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesDelete: async (articleId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesDelete', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/article/{articleId}/images/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesReadCollection: async (articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesReadCollection', 'articleId', articleId)
            const localVarPath = `/file-usages/article/{articleId}/images`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesSort: async (articleId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSort', 'articleId', articleId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/article/{articleId}/images/sort`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesSwap: async (articleId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSwap', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/article/{articleId}/images/swap/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesUpdate: async (articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesUpdate', 'articleId', articleId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerArticleImagesUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/article/{articleId}/images/{fileId}`
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosCount: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosCount', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/count`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosCreate: async (takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosCreate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosDelete: async (takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosDelete', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosReadCollection: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosReadCollection', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosSort: async (takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSort', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/sort`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosSwap: async (takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSwap', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/swap/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosUpdate: async (takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosUpdate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/count`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate: async (takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete: async (takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort: async (takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/sort`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap: async (takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/swap/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate: async (takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/count`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate: async (takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete: async (takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort: async (takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/sort`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap: async (takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/swap/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate: async (takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/count`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate: async (takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete: async (takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort: async (takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/sort`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap: async (takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/swap/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate: async (takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesCount: async (unwantedIncidentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesCount', 'unwantedIncidentId', unwantedIncidentId)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/count`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesCreate: async (unwantedIncidentId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesCreate', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesDelete: async (unwantedIncidentId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesDelete', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/{fileId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesReadCollection: async (unwantedIncidentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesReadCollection', 'unwantedIncidentId', unwantedIncidentId)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesSort: async (unwantedIncidentId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSort', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/sort`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesSwap: async (unwantedIncidentId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSwap', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/swap/{fileId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesUpdate: async (unwantedIncidentId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesUpdate', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/{fileId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileUsageApi - functional programming interface
 * @export
 */
export const FileUsageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileUsageApiAxiosParamCreator(configuration)
    return {
        /**
         * Count all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesCount(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesCount(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesCreate(articleId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesDelete(articleId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesReadCollection(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesReadCollection(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesSort(articleId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesSwap(articleId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleFilesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleFilesUpdate(articleId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesCount(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesCount(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesCreate(articleId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesDelete(articleId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesReadCollection(articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesReadCollection(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesSort(articleId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesSwap(articleId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerArticleImagesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerArticleImagesUpdate(articleId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileUsageApi - factory interface
 * @export
 */
export const FileUsageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileUsageApiFp(configuration)
    return {
        /**
         * Count all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesCount(articleId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleFilesCount(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleFilesCreate(articleId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerArticleFilesDelete(articleId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesReadCollection(articleId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerArticleFilesReadCollection(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerArticleFilesSort(articleId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleFilesSwap(articleId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"Files\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleFilesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleFilesUpdate(articleId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesCount(articleId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleImagesCount(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleImagesCreate(articleId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerArticleImagesDelete(articleId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesReadCollection(articleId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerArticleImagesReadCollection(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerArticleImagesSort(articleId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleImagesSwap(articleId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"Images\" for Article
         * @summary 
         * @param {string} articleId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerArticleImagesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerArticleImagesUpdate(articleId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileUsageApi - object-oriented interface
 * @export
 * @class FileUsageApi
 * @extends {BaseAPI}
 */
export class FileUsageApi extends BaseAPI {
    /**
     * Count all FileUsages in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleFilesCount(articleId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleFilesCount(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleFilesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleFilesCreate(articleId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleFilesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleFilesDelete(articleId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleFilesReadCollection(articleId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleFilesReadCollection(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleFilesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleFilesSort(articleId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleFilesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleFilesSwap(articleId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"Files\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleFilesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleFilesUpdate(articleId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleImagesCount(articleId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleImagesCount(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleImagesCreate(articleId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleImagesCreate(articleId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleImagesDelete(articleId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleImagesDelete(articleId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleImagesReadCollection(articleId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleImagesReadCollection(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleImagesSort(articleId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleImagesSort(articleId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleImagesSwap(articleId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleImagesSwap(articleId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"Images\" for Article
     * @summary 
     * @param {string} articleId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerArticleImagesUpdate(articleId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerArticleImagesUpdate(articleId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId: string, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileUsageApi
     */
    public fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return FileUsageApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilterApi - axios parameter creator
 * @export
 */
export const FilterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentFilterControllerReadObjectFilterCollection: async (page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('unwantedIncidentFilterControllerReadObjectFilterCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('unwantedIncidentFilterControllerReadObjectFilterCollection', 'limit', limit)
            const localVarPath = `/unwanted-incidents/filter/object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentFilterControllerReadProjectFilterCollection: async (page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('unwantedIncidentFilterControllerReadProjectFilterCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('unwantedIncidentFilterControllerReadProjectFilterCollection', 'limit', limit)
            const localVarPath = `/unwanted-incidents/filter/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilterApi - functional programming interface
 * @export
 */
export const FilterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentFilterControllerReadObjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentFilterControllerReadObjectFilterCollection(page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentFilterControllerReadProjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentFilterControllerReadProjectFilterCollection(page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilterApi - factory interface
 * @export
 */
export const FilterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilterApiFp(configuration)
    return {
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentFilterControllerReadObjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<FilterDtoPageResponse> {
            return localVarFp.unwantedIncidentFilterControllerReadObjectFilterCollection(page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentFilterControllerReadProjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<FilterDtoPageResponse> {
            return localVarFp.unwantedIncidentFilterControllerReadProjectFilterCollection(page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilterApi - object-oriented interface
 * @export
 * @class FilterApi
 * @extends {BaseAPI}
 */
export class FilterApi extends BaseAPI {
    /**
     * 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public unwantedIncidentFilterControllerReadObjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return FilterApiFp(this.configuration).unwantedIncidentFilterControllerReadObjectFilterCollection(page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilterApi
     */
    public unwantedIncidentFilterControllerReadProjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return FilterApiFp(this.configuration).unwantedIncidentFilterControllerReadProjectFilterCollection(page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FolderApi - axios parameter creator
 * @export
 */
export const FolderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a folder
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerCreate: async (createFolderDto: CreateFolderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createFolderDto' is not null or undefined
            assertParamExists('folderControllerCreate', 'createFolderDto', createFolderDto)
            const localVarPath = `/folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFolderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a folder
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerDelete: async (folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('folderControllerDelete', 'folderId', folderId)
            const localVarPath = `/folders/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a folder
         * @param {string} folderId 
         * @param {FolderItemPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerRead: async (folderId: string, orderBy: FolderItemPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('folderControllerRead', 'folderId', folderId)
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('folderControllerRead', 'orderBy', orderBy)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('folderControllerRead', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('folderControllerRead', 'limit', limit)
            const localVarPath = `/folders/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a paginated collection of a folders children
         * @param {string} folderId 
         * @param {FolderPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadChildren: async (folderId: string, orderBy: FolderPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('folderControllerReadChildren', 'folderId', folderId)
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('folderControllerReadChildren', 'orderBy', orderBy)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('folderControllerReadChildren', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('folderControllerReadChildren', 'limit', limit)
            const localVarPath = `/folders/{folderId}/children`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadHome: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/folders/home`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a folder info
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadInfo: async (folderId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('folderControllerReadInfo', 'folderId', folderId)
            const localVarPath = `/folders/{folderId}/info`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a paginated collection of shared folder and files
         * @param {FolderItemPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadShared: async (orderBy: FolderItemPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('folderControllerReadShared', 'orderBy', orderBy)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('folderControllerReadShared', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('folderControllerReadShared', 'limit', limit)
            const localVarPath = `/folders/shared`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a paginated collection of uploaded files
         * @param {FolderPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadUpload: async (orderBy: FolderPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderBy' is not null or undefined
            assertParamExists('folderControllerReadUpload', 'orderBy', orderBy)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('folderControllerReadUpload', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('folderControllerReadUpload', 'limit', limit)
            const localVarPath = `/folders/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a folder
         * @param {string} folderId 
         * @param {UpdateFolderDto} updateFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerUpdate: async (folderId: string, updateFolderDto: UpdateFolderDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'folderId' is not null or undefined
            assertParamExists('folderControllerUpdate', 'folderId', folderId)
            // verify required parameter 'updateFolderDto' is not null or undefined
            assertParamExists('folderControllerUpdate', 'updateFolderDto', updateFolderDto)
            const localVarPath = `/folders/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFolderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderApi - functional programming interface
 * @export
 */
export const FolderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a folder
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderControllerCreate(createFolderDto: CreateFolderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolderDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderControllerCreate(createFolderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a folder
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderControllerDelete(folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderControllerDelete(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a folder
         * @param {string} folderId 
         * @param {FolderItemPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderControllerRead(folderId: string, orderBy: FolderItemPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolderDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderControllerRead(folderId, orderBy, page, limit, order, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a paginated collection of a folders children
         * @param {string} folderId 
         * @param {FolderPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderControllerReadChildren(folderId: string, orderBy: FolderPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolderItemDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderControllerReadChildren(folderId, orderBy, page, limit, order, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderControllerReadHome(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolderDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderControllerReadHome(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a folder info
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderControllerReadInfo(folderId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolderInfoDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderControllerReadInfo(folderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a paginated collection of shared folder and files
         * @param {FolderItemPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderControllerReadShared(orderBy: FolderItemPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolderDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderControllerReadShared(orderBy, page, limit, order, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a paginated collection of uploaded files
         * @param {FolderPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderControllerReadUpload(orderBy: FolderPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderControllerReadUpload(orderBy, page, limit, order, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a folder
         * @param {string} folderId 
         * @param {UpdateFolderDto} updateFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderControllerUpdate(folderId: string, updateFolderDto: UpdateFolderDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFolderDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderControllerUpdate(folderId, updateFolderDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FolderApi - factory interface
 * @export
 */
export const FolderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolderApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a folder
         * @param {CreateFolderDto} createFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerCreate(createFolderDto: CreateFolderDto, options?: any): AxiosPromise<ResponseFolderDtoEntityResponse> {
            return localVarFp.folderControllerCreate(createFolderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a folder
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerDelete(folderId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.folderControllerDelete(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a folder
         * @param {string} folderId 
         * @param {FolderItemPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerRead(folderId: string, orderBy: FolderItemPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: any): AxiosPromise<ResponseFolderDtoPageResponse> {
            return localVarFp.folderControllerRead(folderId, orderBy, page, limit, order, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a paginated collection of a folders children
         * @param {string} folderId 
         * @param {FolderPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadChildren(folderId: string, orderBy: FolderPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: any): AxiosPromise<ResponseFolderItemDtoPageResponse> {
            return localVarFp.folderControllerReadChildren(folderId, orderBy, page, limit, order, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadHome(options?: any): AxiosPromise<ResponseFolderDtoCollectionResponse> {
            return localVarFp.folderControllerReadHome(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a folder info
         * @param {string} folderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadInfo(folderId: string, options?: any): AxiosPromise<ResponseFolderInfoDtoEntityResponse> {
            return localVarFp.folderControllerReadInfo(folderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a paginated collection of shared folder and files
         * @param {FolderItemPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadShared(orderBy: FolderItemPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: any): AxiosPromise<ResponseFolderDtoPageResponse> {
            return localVarFp.folderControllerReadShared(orderBy, page, limit, order, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a paginated collection of uploaded files
         * @param {FolderPageOptionsOrderBy} orderBy 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerReadUpload(orderBy: FolderPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: any): AxiosPromise<ResponseFileDtoPageResponse> {
            return localVarFp.folderControllerReadUpload(orderBy, page, limit, order, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a folder
         * @param {string} folderId 
         * @param {UpdateFolderDto} updateFolderDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderControllerUpdate(folderId: string, updateFolderDto: UpdateFolderDto, options?: any): AxiosPromise<ResponseFolderDtoEntityResponse> {
            return localVarFp.folderControllerUpdate(folderId, updateFolderDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FolderApi - object-oriented interface
 * @export
 * @class FolderApi
 * @extends {BaseAPI}
 */
export class FolderApi extends BaseAPI {
    /**
     * 
     * @summary Create a folder
     * @param {CreateFolderDto} createFolderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderControllerCreate(createFolderDto: CreateFolderDto, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderControllerCreate(createFolderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a folder
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderControllerDelete(folderId: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderControllerDelete(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a folder
     * @param {string} folderId 
     * @param {FolderItemPageOptionsOrderBy} orderBy 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderControllerRead(folderId: string, orderBy: FolderItemPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderControllerRead(folderId, orderBy, page, limit, order, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a paginated collection of a folders children
     * @param {string} folderId 
     * @param {FolderPageOptionsOrderBy} orderBy 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderControllerReadChildren(folderId: string, orderBy: FolderPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderControllerReadChildren(folderId, orderBy, page, limit, order, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderControllerReadHome(options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderControllerReadHome(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a folder info
     * @param {string} folderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderControllerReadInfo(folderId: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderControllerReadInfo(folderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a paginated collection of shared folder and files
     * @param {FolderItemPageOptionsOrderBy} orderBy 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderControllerReadShared(orderBy: FolderItemPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderControllerReadShared(orderBy, page, limit, order, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a paginated collection of uploaded files
     * @param {FolderPageOptionsOrderBy} orderBy 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderControllerReadUpload(orderBy: FolderPageOptionsOrderBy, page: number, limit: number, order?: Order, search?: string, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderControllerReadUpload(orderBy, page, limit, order, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a folder
     * @param {string} folderId 
     * @param {UpdateFolderDto} updateFolderDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderControllerUpdate(folderId: string, updateFolderDto: UpdateFolderDto, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderControllerUpdate(folderId, updateFolderDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HelpURLApi - axios parameter creator
 * @export
 */
export const HelpURLApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Help URL
         * @param {CreateHelpUrlDto} createHelpUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerCreate: async (createHelpUrlDto: CreateHelpUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createHelpUrlDto' is not null or undefined
            assertParamExists('helpUrlControllerCreate', 'createHelpUrlDto', createHelpUrlDto)
            const localVarPath = `/help-urls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createHelpUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a HelpUrl
         * @param {string} helpUrlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerDelete: async (helpUrlId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'helpUrlId' is not null or undefined
            assertParamExists('helpUrlControllerDelete', 'helpUrlId', helpUrlId)
            const localVarPath = `/help-urls/{helpUrlId}`
                .replace(`{${"helpUrlId"}}`, encodeURIComponent(String(helpUrlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the collection of Help URLs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerReadCollection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/help-urls`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the collection of Help URLs filtered by language
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerReadCollectionByLanguage: async (language: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'language' is not null or undefined
            assertParamExists('helpUrlControllerReadCollectionByLanguage', 'language', language)
            const localVarPath = `/help-urls/{language}`
                .replace(`{${"language"}}`, encodeURIComponent(String(language)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a Help URL
         * @param {string} helpUrlId 
         * @param {UpdateHelpUrlDto} updateHelpUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerUpdate: async (helpUrlId: string, updateHelpUrlDto: UpdateHelpUrlDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'helpUrlId' is not null or undefined
            assertParamExists('helpUrlControllerUpdate', 'helpUrlId', helpUrlId)
            // verify required parameter 'updateHelpUrlDto' is not null or undefined
            assertParamExists('helpUrlControllerUpdate', 'updateHelpUrlDto', updateHelpUrlDto)
            const localVarPath = `/help-urls/{helpUrlId}`
                .replace(`{${"helpUrlId"}}`, encodeURIComponent(String(helpUrlId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateHelpUrlDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HelpURLApi - functional programming interface
 * @export
 */
export const HelpURLApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HelpURLApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Help URL
         * @param {CreateHelpUrlDto} createHelpUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpUrlControllerCreate(createHelpUrlDto: CreateHelpUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseHelpUrlDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpUrlControllerCreate(createHelpUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a HelpUrl
         * @param {string} helpUrlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpUrlControllerDelete(helpUrlId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpUrlControllerDelete(helpUrlId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the collection of Help URLs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpUrlControllerReadCollection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseHelpUrlDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpUrlControllerReadCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the collection of Help URLs filtered by language
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpUrlControllerReadCollectionByLanguage(language: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseHelpUrlDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpUrlControllerReadCollectionByLanguage(language, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a Help URL
         * @param {string} helpUrlId 
         * @param {UpdateHelpUrlDto} updateHelpUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async helpUrlControllerUpdate(helpUrlId: string, updateHelpUrlDto: UpdateHelpUrlDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseHelpUrlDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helpUrlControllerUpdate(helpUrlId, updateHelpUrlDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HelpURLApi - factory interface
 * @export
 */
export const HelpURLApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HelpURLApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Help URL
         * @param {CreateHelpUrlDto} createHelpUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerCreate(createHelpUrlDto: CreateHelpUrlDto, options?: any): AxiosPromise<ResponseHelpUrlDtoEntityResponse> {
            return localVarFp.helpUrlControllerCreate(createHelpUrlDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a HelpUrl
         * @param {string} helpUrlId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerDelete(helpUrlId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.helpUrlControllerDelete(helpUrlId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the collection of Help URLs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerReadCollection(options?: any): AxiosPromise<ResponseHelpUrlDtoCollectionResponse> {
            return localVarFp.helpUrlControllerReadCollection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the collection of Help URLs filtered by language
         * @param {string} language 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerReadCollectionByLanguage(language: string, options?: any): AxiosPromise<ResponseHelpUrlDtoCollectionResponse> {
            return localVarFp.helpUrlControllerReadCollectionByLanguage(language, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a Help URL
         * @param {string} helpUrlId 
         * @param {UpdateHelpUrlDto} updateHelpUrlDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        helpUrlControllerUpdate(helpUrlId: string, updateHelpUrlDto: UpdateHelpUrlDto, options?: any): AxiosPromise<ResponseHelpUrlDtoEntityResponse> {
            return localVarFp.helpUrlControllerUpdate(helpUrlId, updateHelpUrlDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HelpURLApi - object-oriented interface
 * @export
 * @class HelpURLApi
 * @extends {BaseAPI}
 */
export class HelpURLApi extends BaseAPI {
    /**
     * 
     * @summary Create a Help URL
     * @param {CreateHelpUrlDto} createHelpUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpURLApi
     */
    public helpUrlControllerCreate(createHelpUrlDto: CreateHelpUrlDto, options?: AxiosRequestConfig) {
        return HelpURLApiFp(this.configuration).helpUrlControllerCreate(createHelpUrlDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a HelpUrl
     * @param {string} helpUrlId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpURLApi
     */
    public helpUrlControllerDelete(helpUrlId: string, options?: AxiosRequestConfig) {
        return HelpURLApiFp(this.configuration).helpUrlControllerDelete(helpUrlId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the collection of Help URLs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpURLApi
     */
    public helpUrlControllerReadCollection(options?: AxiosRequestConfig) {
        return HelpURLApiFp(this.configuration).helpUrlControllerReadCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the collection of Help URLs filtered by language
     * @param {string} language 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpURLApi
     */
    public helpUrlControllerReadCollectionByLanguage(language: string, options?: AxiosRequestConfig) {
        return HelpURLApiFp(this.configuration).helpUrlControllerReadCollectionByLanguage(language, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a Help URL
     * @param {string} helpUrlId 
     * @param {UpdateHelpUrlDto} updateHelpUrlDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HelpURLApi
     */
    public helpUrlControllerUpdate(helpUrlId: string, updateHelpUrlDto: UpdateHelpUrlDto, options?: AxiosRequestConfig) {
        return HelpURLApiFp(this.configuration).helpUrlControllerUpdate(helpUrlId, updateHelpUrlDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InspectionApi - axios parameter creator
 * @export
 */
export const InspectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete an Inspection
         * @param {number} inspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectionControllerDelete: async (inspectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inspectionId' is not null or undefined
            assertParamExists('inspectionControllerDelete', 'inspectionId', inspectionId)
            const localVarPath = `/inspections/{inspectionId}`
                .replace(`{${"inspectionId"}}`, encodeURIComponent(String(inspectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InspectionApi - functional programming interface
 * @export
 */
export const InspectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InspectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete an Inspection
         * @param {number} inspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inspectionControllerDelete(inspectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inspectionControllerDelete(inspectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InspectionApi - factory interface
 * @export
 */
export const InspectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InspectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete an Inspection
         * @param {number} inspectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspectionControllerDelete(inspectionId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.inspectionControllerDelete(inspectionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InspectionApi - object-oriented interface
 * @export
 * @class InspectionApi
 * @extends {BaseAPI}
 */
export class InspectionApi extends BaseAPI {
    /**
     * 
     * @summary Delete an Inspection
     * @param {number} inspectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspectionApi
     */
    public inspectionControllerDelete(inspectionId: number, options?: AxiosRequestConfig) {
        return InspectionApiFp(this.configuration).inspectionControllerDelete(inspectionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register a user and it´s device in Pusher Beam
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGenerateToken: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/notifications/generate-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all UserNotifications
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {UserNotificationPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userNotificationControllerReadCollectionForUser: async (page: number, limit: number, order?: Order, orderBy?: UserNotificationPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('userNotificationControllerReadCollectionForUser', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('userNotificationControllerReadCollectionForUser', 'limit', limit)
            const localVarPath = `/profiles/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a UserNotification
         * @param {string} userNotificationId 
         * @param {UpdateUserNotificationDto} updateUserNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userNotificationControllerUpdate: async (userNotificationId: string, updateUserNotificationDto: UpdateUserNotificationDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userNotificationId' is not null or undefined
            assertParamExists('userNotificationControllerUpdate', 'userNotificationId', userNotificationId)
            // verify required parameter 'updateUserNotificationDto' is not null or undefined
            assertParamExists('userNotificationControllerUpdate', 'updateUserNotificationDto', updateUserNotificationDto)
            const localVarPath = `/profiles/notifications/{userNotificationId}`
                .replace(`{${"userNotificationId"}}`, encodeURIComponent(String(userNotificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserNotificationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register a user and it´s device in Pusher Beam
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async notificationControllerGenerateToken(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseNotificationTokenDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.notificationControllerGenerateToken(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all UserNotifications
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {UserNotificationPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userNotificationControllerReadCollectionForUser(page: number, limit: number, order?: Order, orderBy?: UserNotificationPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserNotificationDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userNotificationControllerReadCollectionForUser(page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a UserNotification
         * @param {string} userNotificationId 
         * @param {UpdateUserNotificationDto} updateUserNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userNotificationControllerUpdate(userNotificationId: string, updateUserNotificationDto: UpdateUserNotificationDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserNotificationDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userNotificationControllerUpdate(userNotificationId, updateUserNotificationDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Register a user and it´s device in Pusher Beam
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notificationControllerGenerateToken(options?: any): AxiosPromise<ResponseNotificationTokenDtoEntityResponse> {
            return localVarFp.notificationControllerGenerateToken(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all UserNotifications
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {UserNotificationPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userNotificationControllerReadCollectionForUser(page: number, limit: number, order?: Order, orderBy?: UserNotificationPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseUserNotificationDtoPageResponse> {
            return localVarFp.userNotificationControllerReadCollectionForUser(page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a UserNotification
         * @param {string} userNotificationId 
         * @param {UpdateUserNotificationDto} updateUserNotificationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userNotificationControllerUpdate(userNotificationId: string, updateUserNotificationDto: UpdateUserNotificationDto, options?: any): AxiosPromise<ResponseUserNotificationDtoEntityResponse> {
            return localVarFp.userNotificationControllerUpdate(userNotificationId, updateUserNotificationDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Register a user and it´s device in Pusher Beam
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public notificationControllerGenerateToken(options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).notificationControllerGenerateToken(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all UserNotifications
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {UserNotificationPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public userNotificationControllerReadCollectionForUser(page: number, limit: number, order?: Order, orderBy?: UserNotificationPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).userNotificationControllerReadCollectionForUser(page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a UserNotification
     * @param {string} userNotificationId 
     * @param {UpdateUserNotificationDto} updateUserNotificationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public userNotificationControllerUpdate(userNotificationId: string, updateUserNotificationDto: UpdateUserNotificationDto, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).userNotificationControllerUpdate(userNotificationId, updateUserNotificationDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ObjectApi - axios parameter creator
 * @export
 */
export const ObjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an Address for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerObjectCreate: async (objectId: string, createAddressDto: CreateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('addressControllerObjectCreate', 'objectId', objectId)
            // verify required parameter 'createAddressDto' is not null or undefined
            assertParamExists('addressControllerObjectCreate', 'createAddressDto', createAddressDto)
            const localVarPath = `/object/{objectId}/addresses`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Address for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerObjectDelete: async (objectId: string, addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('addressControllerObjectDelete', 'objectId', objectId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerObjectDelete', 'addressId', addressId)
            const localVarPath = `/object/{objectId}/addresses/{addressId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all Addresses for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerObjectReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('addressControllerObjectReadCollection', 'objectId', objectId)
            const localVarPath = `/object/{objectId}/addresses`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an Address for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerObjectUpdate: async (objectId: string, addressId: string, updateAddressDto: UpdateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('addressControllerObjectUpdate', 'objectId', objectId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerObjectUpdate', 'addressId', addressId)
            // verify required parameter 'updateAddressDto' is not null or undefined
            assertParamExists('addressControllerObjectUpdate', 'updateAddressDto', updateAddressDto)
            const localVarPath = `/object/{objectId}/addresses/{addressId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-new`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-new/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipNewReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-new`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-used`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-used/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverOwnershipUsedReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-ownership-used`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial-return`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial-return/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-commercial-return`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate: async (objectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate', 'objectId', objectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private-return`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete: async (objectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete', 'objectId', objectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private-return/{articleId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection: async (objectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection', 'objectId', objectId)
            const localVarPath = `/article-usages/object/{objectId}/takeover-tenancy-private-return`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a ModelTrait for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectCreate: async (objectId: string, createModelTraitDto: CreateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('modelTraitControllerObjectCreate', 'objectId', objectId)
            // verify required parameter 'createModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerObjectCreate', 'createModelTraitDto', createModelTraitDto)
            const localVarPath = `/object/{objectId}/model-traits`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ModelTrait for Object
         * @summary 
         * @param {string} objectId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectDelete: async (objectId: string, modelTraitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('modelTraitControllerObjectDelete', 'objectId', objectId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerObjectDelete', 'modelTraitId', modelTraitId)
            const localVarPath = `/object/{objectId}/model-traits/{modelTraitId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all traits on Object by modelId
         * @summary 
         * @param {string} objectId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectReadCollection: async (objectId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('modelTraitControllerObjectReadCollection', 'objectId', objectId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('modelTraitControllerObjectReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('modelTraitControllerObjectReadCollection', 'limit', limit)
            const localVarPath = `/object/{objectId}/model-traits`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for Object filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectReadCollectionOnModel: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerObjectReadCollectionOnModel', 'name', name)
            const localVarPath = `/object/model-traits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for Object
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectReadNameCollection: async (name: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerObjectReadNameCollection', 'name', name)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('modelTraitControllerObjectReadNameCollection', 'value', value)
            const localVarPath = `/object/model-traits/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ModelTrait for Object
         * @summary 
         * @param {string} objectId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectUpdate: async (objectId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('modelTraitControllerObjectUpdate', 'objectId', objectId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerObjectUpdate', 'modelTraitId', modelTraitId)
            // verify required parameter 'updateModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerObjectUpdate', 'updateModelTraitDto', updateModelTraitDto)
            const localVarPath = `/object/{objectId}/model-traits/{modelTraitId}`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nonAuthObjectControllerReadProjectUnwantedIncidentInfo: async (objectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('nonAuthObjectControllerReadProjectUnwantedIncidentInfo', 'objectId', objectId)
            const localVarPath = `/non-auth/objects/{objectId}/unwanted-incident-info`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists children of object
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerReadObjectChildren: async (objectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectControllerReadObjectChildren', 'objectId', objectId)
            const localVarPath = `/objects/{objectId}/children`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists all involved users on a Project
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerReadObjectInvolved: async (objectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectControllerReadObjectInvolved', 'objectId', objectId)
            const localVarPath = `/objects/{objectId}/involved`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Lists all top objects user has access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerReadTopObjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reads a list of involved users
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectInvolvedControllerRead: async (objectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectInvolvedControllerRead', 'objectId', objectId)
            const localVarPath = `/objects/{objectId}/involved/users`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a summary for Object
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectSummaryControllerSummary: async (objectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectId' is not null or undefined
            assertParamExists('objectSummaryControllerSummary', 'objectId', objectId)
            const localVarPath = `/objects/{objectId}/summaries`
                .replace(`{${"objectId"}}`, encodeURIComponent(String(objectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ObjectApi - functional programming interface
 * @export
 */
export const ObjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ObjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an Address for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerObjectCreate(objectId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerObjectCreate(objectId, createAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an Address for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerObjectDelete(objectId: string, addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerObjectDelete(objectId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all Addresses for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerObjectReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerObjectReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an Address for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerObjectUpdate(objectId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerObjectUpdate(objectId, addressId, updateAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a ModelTrait for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerObjectCreate(objectId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerObjectCreate(objectId, createModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ModelTrait for Object
         * @summary 
         * @param {string} objectId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerObjectDelete(objectId: string, modelTraitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerObjectDelete(objectId, modelTraitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all traits on Object by modelId
         * @summary 
         * @param {string} objectId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerObjectReadCollection(objectId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerObjectReadCollection(objectId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for Object filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerObjectReadCollectionOnModel(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerObjectReadCollectionOnModel(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for Object
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerObjectReadNameCollection(name: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerObjectReadNameCollection(name, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a ModelTrait for Object
         * @summary 
         * @param {string} objectId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerObjectUpdate(objectId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerObjectUpdate(objectId, modelTraitId, updateModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nonAuthObjectControllerReadProjectUnwantedIncidentInfo(objectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseObjectUnwantedIncidentInfoDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nonAuthObjectControllerReadProjectUnwantedIncidentInfo(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lists children of object
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerReadObjectChildren(objectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseObjectParentIdnDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerReadObjectChildren(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lists all involved users on a Project
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerReadObjectInvolved(objectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseObjectInvolvedDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerReadObjectInvolved(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Lists all top objects user has access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectControllerReadTopObjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseObjectIDNDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectControllerReadTopObjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reads a list of involved users
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectInvolvedControllerRead(objectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseObjectInvolvedUserDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectInvolvedControllerRead(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a summary for Object
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async objectSummaryControllerSummary(objectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseObjectSummaryDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.objectSummaryControllerSummary(objectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ObjectApi - factory interface
 * @export
 */
export const ObjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ObjectApiFp(configuration)
    return {
        /**
         * Create an Address for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerObjectCreate(objectId: string, createAddressDto: CreateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerObjectCreate(objectId, createAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Address for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerObjectDelete(objectId: string, addressId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.addressControllerObjectDelete(objectId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all Addresses for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerObjectReadCollection(objectId: string, options?: any): AxiosPromise<ResponseAddressDtoCollectionResponse> {
            return localVarFp.addressControllerObjectReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an Address for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerObjectUpdate(objectId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerObjectUpdate(objectId, addressId, updateAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Object
         * @summary 
         * @param {string} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a ModelTrait for Object
         * @summary 
         * @param {string} objectId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectCreate(objectId: string, createModelTraitDto: CreateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerObjectCreate(objectId, createModelTraitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ModelTrait for Object
         * @summary 
         * @param {string} objectId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectDelete(objectId: string, modelTraitId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.modelTraitControllerObjectDelete(objectId, modelTraitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all traits on Object by modelId
         * @summary 
         * @param {string} objectId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectReadCollection(objectId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseModelTraitDtoPageResponse> {
            return localVarFp.modelTraitControllerObjectReadCollection(objectId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for Object filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectReadCollectionOnModel(name: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerObjectReadCollectionOnModel(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for Object
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectReadNameCollection(name: string, value: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerObjectReadNameCollection(name, value, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ModelTrait for Object
         * @summary 
         * @param {string} objectId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerObjectUpdate(objectId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerObjectUpdate(objectId, modelTraitId, updateModelTraitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nonAuthObjectControllerReadProjectUnwantedIncidentInfo(objectId: number, options?: any): AxiosPromise<ResponseObjectUnwantedIncidentInfoDtoEntityResponse> {
            return localVarFp.nonAuthObjectControllerReadProjectUnwantedIncidentInfo(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists children of object
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerReadObjectChildren(objectId: number, options?: any): AxiosPromise<ResponseObjectParentIdnDtoCollectionResponse> {
            return localVarFp.objectControllerReadObjectChildren(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists all involved users on a Project
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerReadObjectInvolved(objectId: number, options?: any): AxiosPromise<ResponseObjectInvolvedDtoCollectionResponse> {
            return localVarFp.objectControllerReadObjectInvolved(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Lists all top objects user has access to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectControllerReadTopObjects(options?: any): AxiosPromise<ResponseObjectIDNDtoCollectionResponse> {
            return localVarFp.objectControllerReadTopObjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reads a list of involved users
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectInvolvedControllerRead(objectId: number, options?: any): AxiosPromise<ResponseObjectInvolvedUserDtoCollectionResponse> {
            return localVarFp.objectInvolvedControllerRead(objectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a summary for Object
         * @param {number} objectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        objectSummaryControllerSummary(objectId: number, options?: any): AxiosPromise<ResponseObjectSummaryDtoEntityResponse> {
            return localVarFp.objectSummaryControllerSummary(objectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ObjectApi - object-oriented interface
 * @export
 * @class ObjectApi
 * @extends {BaseAPI}
 */
export class ObjectApi extends BaseAPI {
    /**
     * Create an Address for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateAddressDto} createAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public addressControllerObjectCreate(objectId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).addressControllerObjectCreate(objectId, createAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Address for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public addressControllerObjectDelete(objectId: string, addressId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).addressControllerObjectDelete(objectId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all Addresses for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public addressControllerObjectReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).addressControllerObjectReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an Address for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} addressId 
     * @param {UpdateAddressDto} updateAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public addressControllerObjectUpdate(objectId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).addressControllerObjectUpdate(objectId, addressId, updateAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipNew\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipNewCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipNewDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipNew\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipNewReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipUsed\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipUsedCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipUsedDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverOwnershipUsedReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyCommercial\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyCommercial\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyCommercial\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyCommercialReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialReturnCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyCommercialReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialReturnDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyCommercialReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyCommercialReturnReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyPrivate\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyPrivate\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyPrivate\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverTenancyPrivateReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateReturnCreate(objectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverTenancyPrivateReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateReturnDelete(objectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverTenancyPrivateReturn\" for Object
     * @summary 
     * @param {string} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).articleUsageControllerObjectTakeoverTenancyPrivateReturnReadCollection(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a ModelTrait for Object
     * @summary 
     * @param {string} objectId 
     * @param {CreateModelTraitDto} createModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public modelTraitControllerObjectCreate(objectId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).modelTraitControllerObjectCreate(objectId, createModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ModelTrait for Object
     * @summary 
     * @param {string} objectId 
     * @param {number} modelTraitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public modelTraitControllerObjectDelete(objectId: string, modelTraitId: number, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).modelTraitControllerObjectDelete(objectId, modelTraitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all traits on Object by modelId
     * @summary 
     * @param {string} objectId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public modelTraitControllerObjectReadCollection(objectId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).modelTraitControllerObjectReadCollection(objectId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for Object filtered by name
     * @summary 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public modelTraitControllerObjectReadCollectionOnModel(name: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).modelTraitControllerObjectReadCollectionOnModel(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for Object
     * @summary 
     * @param {string} name 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public modelTraitControllerObjectReadNameCollection(name: string, value: string, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).modelTraitControllerObjectReadNameCollection(name, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ModelTrait for Object
     * @summary 
     * @param {string} objectId 
     * @param {number} modelTraitId 
     * @param {UpdateModelTraitDto} updateModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public modelTraitControllerObjectUpdate(objectId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).modelTraitControllerObjectUpdate(objectId, modelTraitId, updateModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public nonAuthObjectControllerReadProjectUnwantedIncidentInfo(objectId: number, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).nonAuthObjectControllerReadProjectUnwantedIncidentInfo(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists children of object
     * @param {number} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerReadObjectChildren(objectId: number, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).objectControllerReadObjectChildren(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists all involved users on a Project
     * @param {number} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerReadObjectInvolved(objectId: number, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).objectControllerReadObjectInvolved(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Lists all top objects user has access to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectControllerReadTopObjects(options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).objectControllerReadTopObjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reads a list of involved users
     * @param {number} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectInvolvedControllerRead(objectId: number, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).objectInvolvedControllerRead(objectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a summary for Object
     * @param {number} objectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ObjectApi
     */
    public objectSummaryControllerSummary(objectId: number, options?: AxiosRequestConfig) {
        return ObjectApiFp(this.configuration).objectSummaryControllerSummary(objectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PortalApi - axios parameter creator
 * @export
 */
export const PortalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalApartmentControllerReadApartment: async (projectId: number, apartmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalApartmentControllerReadApartment', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalApartmentControllerReadApartment', 'apartmentId', apartmentId)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalApartmentControllerReadApartmentBannerImages: async (projectId: number, apartmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalApartmentControllerReadApartmentBannerImages', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalApartmentControllerReadApartmentBannerImages', 'apartmentId', apartmentId)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/banner-images`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalApartmentControllerReadCompanyLogos: async (projectId: number, apartmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalApartmentControllerReadCompanyLogos', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalApartmentControllerReadCompanyLogos', 'apartmentId', apartmentId)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/company-logos`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated collection of cases
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PortalCasesPageOptionsOrderBy} [orderBy] 
         * @param {number} [apartmentId] Filter results based on ApartmentId
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalCaseControllerReadCollection: async (page: number, limit: number, order?: Order, orderBy?: PortalCasesPageOptionsOrderBy, apartmentId?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('portalCaseControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('portalCaseControllerReadCollection', 'limit', limit)
            const localVarPath = `/portal/cases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (apartmentId !== undefined) {
                localVarQueryParameter['ApartmentId'] = apartmentId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalControllerReadMainObjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/portal/main-objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks a case as read
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseControllerMarkCaseRead: async (projectId: number, apartmentId: number, caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerMarkCaseRead', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerMarkCaseRead', 'apartmentId', apartmentId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerMarkCaseRead', 'caseId', caseId)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/cases/{caseId}/read`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marks a case as unread
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseControllerMarkCaseUnread: async (projectId: number, apartmentId: number, caseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerMarkCaseUnread', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerMarkCaseUnread', 'apartmentId', apartmentId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerMarkCaseUnread', 'caseId', caseId)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/cases/{caseId}/unread`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated collection of cases
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PortalCasePageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseControllerReadCollection: async (projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PortalCasePageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerReadCollection', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerReadCollection', 'apartmentId', apartmentId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('portalProjectApartmentCaseControllerReadCollection', 'limit', limit)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/cases`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {CreatePortalCaseLogDto} createPortalCaseLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseLogControllerCreate: async (projectId: number, apartmentId: number, caseId: number, createPortalCaseLogDto: CreatePortalCaseLogDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseLogControllerCreate', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseLogControllerCreate', 'apartmentId', apartmentId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseLogControllerCreate', 'caseId', caseId)
            // verify required parameter 'createPortalCaseLogDto' is not null or undefined
            assertParamExists('portalProjectApartmentCaseLogControllerCreate', 'createPortalCaseLogDto', createPortalCaseLogDto)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/cases/{caseId}/logs`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPortalCaseLogDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PortalCaseLogPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseLogControllerReadCollection: async (projectId: number, apartmentId: number, caseId: number, page: number, limit: number, order?: Order, orderBy?: PortalCaseLogPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseLogControllerReadCollection', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseLogControllerReadCollection', 'apartmentId', apartmentId)
            // verify required parameter 'caseId' is not null or undefined
            assertParamExists('portalProjectApartmentCaseLogControllerReadCollection', 'caseId', caseId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('portalProjectApartmentCaseLogControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('portalProjectApartmentCaseLogControllerReadCollection', 'limit', limit)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/cases/{caseId}/logs`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)))
                .replace(`{${"caseId"}}`, encodeURIComponent(String(caseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {number} agreementId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectArticleControllerReadCollectionForAgreement: async (agreementId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'agreementId' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForAgreement', 'agreementId', agreementId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForAgreement', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForAgreement', 'limit', limit)
            const localVarPath = `/portal/agreement/{agreementId}/articles`
                .replace(`{${"agreementId"}}`, encodeURIComponent(String(agreementId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (articleModel !== undefined) {
                localVarQueryParameter['ArticleModel'] = articleModel;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectArticleControllerReadCollectionForApartment: async (projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForApartment', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForApartment', 'apartmentId', apartmentId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForApartment', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForApartment', 'limit', limit)
            const localVarPath = `/portal/projects/{projectId}/apartments/{apartmentId}/articles`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (articleModel !== undefined) {
                localVarQueryParameter['ArticleModel'] = articleModel;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {string} objectOwnershipId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectArticleControllerReadCollectionForOwnership: async (objectOwnershipId: string, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'objectOwnershipId' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForOwnership', 'objectOwnershipId', objectOwnershipId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForOwnership', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForOwnership', 'limit', limit)
            const localVarPath = `/portal/ownership/{objectOwnershipId}/articles`
                .replace(`{${"objectOwnershipId"}}`, encodeURIComponent(String(objectOwnershipId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (articleModel !== undefined) {
                localVarQueryParameter['ArticleModel'] = articleModel;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {number} tenancyId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectArticleControllerReadCollectionForTenancy: async (tenancyId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tenancyId' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForTenancy', 'tenancyId', tenancyId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForTenancy', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('portalProjectArticleControllerReadCollectionForTenancy', 'limit', limit)
            const localVarPath = `/portal/tenancy/{tenancyId}/articles`
                .replace(`{${"tenancyId"}}`, encodeURIComponent(String(tenancyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (articleModel !== undefined) {
                localVarQueryParameter['ArticleModel'] = articleModel;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PortalApi - functional programming interface
 * @export
 */
export const PortalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PortalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalApartmentControllerReadApartment(projectId: number, apartmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalApartmentDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalApartmentControllerReadApartment(projectId, apartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalApartmentControllerReadApartmentBannerImages(projectId: number, apartmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalApartmentControllerReadApartmentBannerImages(projectId, apartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalApartmentControllerReadCompanyLogos(projectId: number, apartmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalApartmentControllerReadCompanyLogos(projectId, apartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated collection of cases
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PortalCasesPageOptionsOrderBy} [orderBy] 
         * @param {number} [apartmentId] Filter results based on ApartmentId
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalCaseControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: PortalCasesPageOptionsOrderBy, apartmentId?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalCaseDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalCaseControllerReadCollection(page, limit, order, orderBy, apartmentId, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalControllerReadMainObjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalMainObjectDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalControllerReadMainObjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marks a case as read
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalProjectApartmentCaseControllerMarkCaseRead(projectId: number, apartmentId: number, caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalProjectApartmentCaseControllerMarkCaseRead(projectId, apartmentId, caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marks a case as unread
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalProjectApartmentCaseControllerMarkCaseUnread(projectId: number, apartmentId: number, caseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalProjectApartmentCaseControllerMarkCaseUnread(projectId, apartmentId, caseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated collection of cases
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PortalCasePageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalProjectApartmentCaseControllerReadCollection(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PortalCasePageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalCaseDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalProjectApartmentCaseControllerReadCollection(projectId, apartmentId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {CreatePortalCaseLogDto} createPortalCaseLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalProjectApartmentCaseLogControllerCreate(projectId: number, apartmentId: number, caseId: number, createPortalCaseLogDto: CreatePortalCaseLogDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalCaseLogDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalProjectApartmentCaseLogControllerCreate(projectId, apartmentId, caseId, createPortalCaseLogDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PortalCaseLogPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalProjectApartmentCaseLogControllerReadCollection(projectId: number, apartmentId: number, caseId: number, page: number, limit: number, order?: Order, orderBy?: PortalCaseLogPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalCaseLogDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalProjectApartmentCaseLogControllerReadCollection(projectId, apartmentId, caseId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {number} agreementId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalProjectArticleControllerReadCollectionForAgreement(agreementId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalArticleDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalProjectArticleControllerReadCollectionForAgreement(agreementId, page, limit, order, orderBy, articleModel, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalProjectArticleControllerReadCollectionForApartment(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalArticleDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalProjectArticleControllerReadCollectionForApartment(projectId, apartmentId, page, limit, order, orderBy, articleModel, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {string} objectOwnershipId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalProjectArticleControllerReadCollectionForOwnership(objectOwnershipId: string, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalArticleDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalProjectArticleControllerReadCollectionForOwnership(objectOwnershipId, page, limit, order, orderBy, articleModel, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {number} tenancyId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async portalProjectArticleControllerReadCollectionForTenancy(tenancyId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponsePortalArticleDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.portalProjectArticleControllerReadCollectionForTenancy(tenancyId, page, limit, order, orderBy, articleModel, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PortalApi - factory interface
 * @export
 */
export const PortalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PortalApiFp(configuration)
    return {
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalApartmentControllerReadApartment(projectId: number, apartmentId: number, options?: any): AxiosPromise<ResponsePortalApartmentDtoEntityResponse> {
            return localVarFp.portalApartmentControllerReadApartment(projectId, apartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalApartmentControllerReadApartmentBannerImages(projectId: number, apartmentId: number, options?: any): AxiosPromise<ResponsePortalFileUsageDtoCollectionResponse> {
            return localVarFp.portalApartmentControllerReadApartmentBannerImages(projectId, apartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalApartmentControllerReadCompanyLogos(projectId: number, apartmentId: number, options?: any): AxiosPromise<ResponsePortalFileUsageDtoCollectionResponse> {
            return localVarFp.portalApartmentControllerReadCompanyLogos(projectId, apartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated collection of cases
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PortalCasesPageOptionsOrderBy} [orderBy] 
         * @param {number} [apartmentId] Filter results based on ApartmentId
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalCaseControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: PortalCasesPageOptionsOrderBy, apartmentId?: number, search?: string, options?: any): AxiosPromise<ResponsePortalCaseDtoPageResponse> {
            return localVarFp.portalCaseControllerReadCollection(page, limit, order, orderBy, apartmentId, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalControllerReadMainObjects(options?: any): AxiosPromise<ResponsePortalMainObjectDtoCollectionResponse> {
            return localVarFp.portalControllerReadMainObjects(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks a case as read
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseControllerMarkCaseRead(projectId: number, apartmentId: number, caseId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.portalProjectApartmentCaseControllerMarkCaseRead(projectId, apartmentId, caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marks a case as unread
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseControllerMarkCaseUnread(projectId: number, apartmentId: number, caseId: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.portalProjectApartmentCaseControllerMarkCaseUnread(projectId, apartmentId, caseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated collection of cases
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PortalCasePageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseControllerReadCollection(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PortalCasePageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponsePortalCaseDtoPageResponse> {
            return localVarFp.portalProjectApartmentCaseControllerReadCollection(projectId, apartmentId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {CreatePortalCaseLogDto} createPortalCaseLogDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseLogControllerCreate(projectId: number, apartmentId: number, caseId: number, createPortalCaseLogDto: CreatePortalCaseLogDto, options?: any): AxiosPromise<ResponsePortalCaseLogDtoEntityResponse> {
            return localVarFp.portalProjectApartmentCaseLogControllerCreate(projectId, apartmentId, caseId, createPortalCaseLogDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} caseId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PortalCaseLogPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectApartmentCaseLogControllerReadCollection(projectId: number, apartmentId: number, caseId: number, page: number, limit: number, order?: Order, orderBy?: PortalCaseLogPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponsePortalCaseLogDtoPageResponse> {
            return localVarFp.portalProjectApartmentCaseLogControllerReadCollection(projectId, apartmentId, caseId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {number} agreementId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectArticleControllerReadCollectionForAgreement(agreementId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: any): AxiosPromise<ResponsePortalArticleDtoPageResponse> {
            return localVarFp.portalProjectArticleControllerReadCollectionForAgreement(agreementId, page, limit, order, orderBy, articleModel, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectArticleControllerReadCollectionForApartment(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: any): AxiosPromise<ResponsePortalArticleDtoPageResponse> {
            return localVarFp.portalProjectArticleControllerReadCollectionForApartment(projectId, apartmentId, page, limit, order, orderBy, articleModel, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {string} objectOwnershipId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectArticleControllerReadCollectionForOwnership(objectOwnershipId: string, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: any): AxiosPromise<ResponsePortalArticleDtoPageResponse> {
            return localVarFp.portalProjectArticleControllerReadCollectionForOwnership(objectOwnershipId, page, limit, order, orderBy, articleModel, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a paginated collection of articles
         * @param {number} tenancyId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ArticlePageOptionsOrderBy} [orderBy] 
         * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        portalProjectArticleControllerReadCollectionForTenancy(tenancyId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: any): AxiosPromise<ResponsePortalArticleDtoPageResponse> {
            return localVarFp.portalProjectArticleControllerReadCollectionForTenancy(tenancyId, page, limit, order, orderBy, articleModel, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PortalApi - object-oriented interface
 * @export
 * @class PortalApi
 * @extends {BaseAPI}
 */
export class PortalApi extends BaseAPI {
    /**
     * 
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalApartmentControllerReadApartment(projectId: number, apartmentId: number, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalApartmentControllerReadApartment(projectId, apartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalApartmentControllerReadApartmentBannerImages(projectId: number, apartmentId: number, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalApartmentControllerReadApartmentBannerImages(projectId, apartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalApartmentControllerReadCompanyLogos(projectId: number, apartmentId: number, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalApartmentControllerReadCompanyLogos(projectId, apartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated collection of cases
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PortalCasesPageOptionsOrderBy} [orderBy] 
     * @param {number} [apartmentId] Filter results based on ApartmentId
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalCaseControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: PortalCasesPageOptionsOrderBy, apartmentId?: number, search?: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalCaseControllerReadCollection(page, limit, order, orderBy, apartmentId, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalControllerReadMainObjects(options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalControllerReadMainObjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks a case as read
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalProjectApartmentCaseControllerMarkCaseRead(projectId: number, apartmentId: number, caseId: number, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalProjectApartmentCaseControllerMarkCaseRead(projectId, apartmentId, caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marks a case as unread
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {number} caseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalProjectApartmentCaseControllerMarkCaseUnread(projectId: number, apartmentId: number, caseId: number, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalProjectApartmentCaseControllerMarkCaseUnread(projectId, apartmentId, caseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated collection of cases
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PortalCasePageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalProjectApartmentCaseControllerReadCollection(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: PortalCasePageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalProjectApartmentCaseControllerReadCollection(projectId, apartmentId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {number} caseId 
     * @param {CreatePortalCaseLogDto} createPortalCaseLogDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalProjectApartmentCaseLogControllerCreate(projectId: number, apartmentId: number, caseId: number, createPortalCaseLogDto: CreatePortalCaseLogDto, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalProjectApartmentCaseLogControllerCreate(projectId, apartmentId, caseId, createPortalCaseLogDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {number} caseId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PortalCaseLogPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalProjectApartmentCaseLogControllerReadCollection(projectId: number, apartmentId: number, caseId: number, page: number, limit: number, order?: Order, orderBy?: PortalCaseLogPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalProjectApartmentCaseLogControllerReadCollection(projectId, apartmentId, caseId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated collection of articles
     * @param {number} agreementId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ArticlePageOptionsOrderBy} [orderBy] 
     * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalProjectArticleControllerReadCollectionForAgreement(agreementId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalProjectArticleControllerReadCollectionForAgreement(agreementId, page, limit, order, orderBy, articleModel, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated collection of articles
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ArticlePageOptionsOrderBy} [orderBy] 
     * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalProjectArticleControllerReadCollectionForApartment(projectId: number, apartmentId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalProjectArticleControllerReadCollectionForApartment(projectId, apartmentId, page, limit, order, orderBy, articleModel, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated collection of articles
     * @param {string} objectOwnershipId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ArticlePageOptionsOrderBy} [orderBy] 
     * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalProjectArticleControllerReadCollectionForOwnership(objectOwnershipId: string, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalProjectArticleControllerReadCollectionForOwnership(objectOwnershipId, page, limit, order, orderBy, articleModel, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a paginated collection of articles
     * @param {number} tenancyId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ArticlePageOptionsOrderBy} [orderBy] 
     * @param {AllowedArticleModel} [articleModel] Filter results on ArticleModel
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PortalApi
     */
    public portalProjectArticleControllerReadCollectionForTenancy(tenancyId: number, page: number, limit: number, order?: Order, orderBy?: ArticlePageOptionsOrderBy, articleModel?: AllowedArticleModel, search?: string, options?: AxiosRequestConfig) {
        return PortalApiFp(this.configuration).portalProjectArticleControllerReadCollectionForTenancy(tenancyId, page, limit, order, orderBy, articleModel, search, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an Address for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerProjectCreate: async (projectId: string, createAddressDto: CreateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addressControllerProjectCreate', 'projectId', projectId)
            // verify required parameter 'createAddressDto' is not null or undefined
            assertParamExists('addressControllerProjectCreate', 'createAddressDto', createAddressDto)
            const localVarPath = `/project/{projectId}/addresses`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Address for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerProjectDelete: async (projectId: string, addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addressControllerProjectDelete', 'projectId', projectId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerProjectDelete', 'addressId', addressId)
            const localVarPath = `/project/{projectId}/addresses/{addressId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all Addresses for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerProjectReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addressControllerProjectReadCollection', 'projectId', projectId)
            const localVarPath = `/project/{projectId}/addresses`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an Address for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerProjectUpdate: async (projectId: string, addressId: string, updateAddressDto: UpdateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('addressControllerProjectUpdate', 'projectId', projectId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerProjectUpdate', 'addressId', addressId)
            // verify required parameter 'updateAddressDto' is not null or undefined
            assertParamExists('addressControllerProjectUpdate', 'updateAddressDto', updateAddressDto)
            const localVarPath = `/project/{projectId}/addresses/{addressId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/addon-confirmation`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/addon-confirmation/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonConfirmationReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/addon-confirmation`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/addon-pdf`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/addon-pdf/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonPdfReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/addon-pdf`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/addon-project-text`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/addon-project-text/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectAddonProjectTextReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/addon-project-text`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-new`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-new/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipNewReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-new`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedCreate: async (projectId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedCreate', 'projectId', projectId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-used`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedDelete: async (projectId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedDelete', 'projectId', projectId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-used/{articleId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedReadCollection: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('articleUsageControllerProjectTakeoverOwnershipUsedReadCollection', 'projectId', projectId)
            const localVarPath = `/article-usages/project/{projectId}/takeover-ownership-used`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a ModelTrait for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectCreate: async (projectId: string, createModelTraitDto: CreateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('modelTraitControllerProjectCreate', 'projectId', projectId)
            // verify required parameter 'createModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerProjectCreate', 'createModelTraitDto', createModelTraitDto)
            const localVarPath = `/project/{projectId}/model-traits`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ModelTrait for Project
         * @summary 
         * @param {string} projectId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectDelete: async (projectId: string, modelTraitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('modelTraitControllerProjectDelete', 'projectId', projectId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerProjectDelete', 'modelTraitId', modelTraitId)
            const localVarPath = `/project/{projectId}/model-traits/{modelTraitId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all traits on Project by modelId
         * @summary 
         * @param {string} projectId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectReadCollection: async (projectId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('modelTraitControllerProjectReadCollection', 'projectId', projectId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('modelTraitControllerProjectReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('modelTraitControllerProjectReadCollection', 'limit', limit)
            const localVarPath = `/project/{projectId}/model-traits`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for Project filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectReadCollectionOnModel: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerProjectReadCollectionOnModel', 'name', name)
            const localVarPath = `/project/model-traits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for Project
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectReadNameCollection: async (name: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerProjectReadNameCollection', 'name', name)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('modelTraitControllerProjectReadNameCollection', 'value', value)
            const localVarPath = `/project/model-traits/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ModelTrait for Project
         * @summary 
         * @param {string} projectId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectUpdate: async (projectId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('modelTraitControllerProjectUpdate', 'projectId', projectId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerProjectUpdate', 'modelTraitId', modelTraitId)
            // verify required parameter 'updateModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerProjectUpdate', 'updateModelTraitDto', updateModelTraitDto)
            const localVarPath = `/project/{projectId}/model-traits/{modelTraitId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nonAuthProjectControllerReadUnwantedIncidentInfo: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('nonAuthProjectControllerReadUnwantedIncidentInfo', 'projectId', projectId)
            const localVarPath = `/non-auth/projects/{projectId}/unwanted-incident-info`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read ProjectApartments without associated ProjectApartmentTakeoverLocks
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerReadApartmentsWithoutTakeoverLock: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectControllerReadApartmentsWithoutTakeoverLock', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/apartments/takeover/open`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reads a list of a projects involved users
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInvolvedControllerRead: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectInvolvedControllerRead', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/involved`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectMomControllerReadContractorStatusReport: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectMomControllerReadContractorStatusReport', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/moms/reports/contractor-status`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectMomControllerReadFieldsUsersCanContributeToCaseStatus: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectMomControllerReadFieldsUsersCanContributeToCaseStatus', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/moms/fields`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectMomControllerSetContractorStatusComplete: async (projectId: number, fieldId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectMomControllerSetContractorStatusComplete', 'projectId', projectId)
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('projectMomControllerSetContractorStatusComplete', 'fieldId', fieldId)
            const localVarPath = `/projects/{projectId}/moms/fields/{fieldId}/complete`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"fieldId"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectMomControllerSetContractorStatusIncomplete: async (projectId: number, fieldId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectMomControllerSetContractorStatusIncomplete', 'projectId', projectId)
            // verify required parameter 'fieldId' is not null or undefined
            assertParamExists('projectMomControllerSetContractorStatusIncomplete', 'fieldId', fieldId)
            const localVarPath = `/projects/{projectId}/moms/fields/{fieldId}/incomplete`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"fieldId"}}`, encodeURIComponent(String(fieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a summary for Project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectSummaryControllerSummary: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectSummaryControllerSummary', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/summaries`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an Address for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerProjectCreate(projectId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerProjectCreate(projectId, createAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an Address for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerProjectDelete(projectId: string, addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerProjectDelete(projectId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all Addresses for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerProjectReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerProjectReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an Address for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerProjectUpdate(projectId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerProjectUpdate(projectId, addressId, updateAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonConfirmationCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonConfirmationCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonConfirmationDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonConfirmationDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonConfirmationReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonConfirmationReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonPdfCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonPdfCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonPdfDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonPdfDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonPdfReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonPdfReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonProjectTextCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonProjectTextCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonProjectTextDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonProjectTextDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectAddonProjectTextReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectAddonProjectTextReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a ModelTrait for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectCreate(projectId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectCreate(projectId, createModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ModelTrait for Project
         * @summary 
         * @param {string} projectId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectDelete(projectId: string, modelTraitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectDelete(projectId, modelTraitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all traits on Project by modelId
         * @summary 
         * @param {string} projectId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectReadCollection(projectId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectReadCollection(projectId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for Project filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectReadCollectionOnModel(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectReadCollectionOnModel(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for Project
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectReadNameCollection(name: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectReadNameCollection(name, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a ModelTrait for Project
         * @summary 
         * @param {string} projectId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectUpdate(projectId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectUpdate(projectId, modelTraitId, updateModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nonAuthProjectControllerReadUnwantedIncidentInfo(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectUnwantedIncidentInfoDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nonAuthProjectControllerReadUnwantedIncidentInfo(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read ProjectApartments without associated ProjectApartmentTakeoverLocks
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectControllerReadApartmentsWithoutTakeoverLock(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectApartmentCardDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectControllerReadApartmentsWithoutTakeoverLock(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reads a list of a projects involved users
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInvolvedControllerRead(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectInvolvedUserDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInvolvedControllerRead(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectMomControllerReadContractorStatusReport(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseContractorStatusReportDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectMomControllerReadContractorStatusReport(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectMomControllerReadFieldsUsersCanContributeToCaseStatus(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFieldIDNDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectMomControllerReadFieldsUsersCanContributeToCaseStatus(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectMomControllerSetContractorStatusComplete(projectId: number, fieldId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseContractorSetStatusDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectMomControllerSetContractorStatusComplete(projectId, fieldId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectMomControllerSetContractorStatusIncomplete(projectId: number, fieldId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseContractorSetStatusDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectMomControllerSetContractorStatusIncomplete(projectId, fieldId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets a summary for Project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectSummaryControllerSummary(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectSummaryDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectSummaryControllerSummary(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * Create an Address for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerProjectCreate(projectId: string, createAddressDto: CreateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerProjectCreate(projectId, createAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Address for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerProjectDelete(projectId: string, addressId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.addressControllerProjectDelete(projectId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all Addresses for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerProjectReadCollection(projectId: string, options?: any): AxiosPromise<ResponseAddressDtoCollectionResponse> {
            return localVarFp.addressControllerProjectReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an Address for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerProjectUpdate(projectId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerProjectUpdate(projectId, addressId, updateAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectAddonConfirmationCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectAddonConfirmationDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonConfirmation\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonConfirmationReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectAddonConfirmationReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectAddonPdfCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectAddonPdfDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonPdf\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonPdfReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectAddonPdfReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectAddonProjectTextCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectAddonProjectTextDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"AddonProjectText\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectAddonProjectTextReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectAddonProjectTextReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipNew\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Project
         * @summary 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a ModelTrait for Project
         * @summary 
         * @param {string} projectId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectCreate(projectId: string, createModelTraitDto: CreateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerProjectCreate(projectId, createModelTraitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ModelTrait for Project
         * @summary 
         * @param {string} projectId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectDelete(projectId: string, modelTraitId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.modelTraitControllerProjectDelete(projectId, modelTraitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all traits on Project by modelId
         * @summary 
         * @param {string} projectId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectReadCollection(projectId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseModelTraitDtoPageResponse> {
            return localVarFp.modelTraitControllerProjectReadCollection(projectId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for Project filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectReadCollectionOnModel(name: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerProjectReadCollectionOnModel(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for Project
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectReadNameCollection(name: string, value: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerProjectReadNameCollection(name, value, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ModelTrait for Project
         * @summary 
         * @param {string} projectId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectUpdate(projectId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerProjectUpdate(projectId, modelTraitId, updateModelTraitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nonAuthProjectControllerReadUnwantedIncidentInfo(projectId: number, options?: any): AxiosPromise<ResponseProjectUnwantedIncidentInfoDtoEntityResponse> {
            return localVarFp.nonAuthProjectControllerReadUnwantedIncidentInfo(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read ProjectApartments without associated ProjectApartmentTakeoverLocks
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectControllerReadApartmentsWithoutTakeoverLock(projectId: number, options?: any): AxiosPromise<ResponseProjectApartmentCardDtoCollectionResponse> {
            return localVarFp.projectControllerReadApartmentsWithoutTakeoverLock(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reads a list of a projects involved users
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInvolvedControllerRead(projectId: number, options?: any): AxiosPromise<ResponseProjectInvolvedUserDtoCollectionResponse> {
            return localVarFp.projectInvolvedControllerRead(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectMomControllerReadContractorStatusReport(projectId: number, options?: any): AxiosPromise<ResponseContractorStatusReportDtoEntityResponse> {
            return localVarFp.projectMomControllerReadContractorStatusReport(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectMomControllerReadFieldsUsersCanContributeToCaseStatus(projectId: number, options?: any): AxiosPromise<ResponseFieldIDNDtoCollectionResponse> {
            return localVarFp.projectMomControllerReadFieldsUsersCanContributeToCaseStatus(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectMomControllerSetContractorStatusComplete(projectId: number, fieldId: number, options?: any): AxiosPromise<ResponseContractorSetStatusDtoEntityResponse> {
            return localVarFp.projectMomControllerSetContractorStatusComplete(projectId, fieldId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} fieldId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectMomControllerSetContractorStatusIncomplete(projectId: number, fieldId: number, options?: any): AxiosPromise<ResponseContractorSetStatusDtoEntityResponse> {
            return localVarFp.projectMomControllerSetContractorStatusIncomplete(projectId, fieldId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets a summary for Project
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectSummaryControllerSummary(projectId: number, options?: any): AxiosPromise<ResponseProjectSummaryDtoEntityResponse> {
            return localVarFp.projectSummaryControllerSummary(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * Create an Address for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateAddressDto} createAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public addressControllerProjectCreate(projectId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).addressControllerProjectCreate(projectId, createAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Address for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public addressControllerProjectDelete(projectId: string, addressId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).addressControllerProjectDelete(projectId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all Addresses for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public addressControllerProjectReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).addressControllerProjectReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an Address for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} addressId 
     * @param {UpdateAddressDto} updateAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public addressControllerProjectUpdate(projectId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).addressControllerProjectUpdate(projectId, addressId, updateAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonConfirmation\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectAddonConfirmationCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectAddonConfirmationCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonConfirmation\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectAddonConfirmationDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectAddonConfirmationDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonConfirmation\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectAddonConfirmationReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectAddonConfirmationReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonPdf\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectAddonPdfCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectAddonPdfCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonPdf\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectAddonPdfDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectAddonPdfDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonPdf\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectAddonPdfReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectAddonPdfReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"AddonProjectText\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectAddonProjectTextCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectAddonProjectTextCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"AddonProjectText\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectAddonProjectTextDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectAddonProjectTextDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"AddonProjectText\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectAddonProjectTextReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectAddonProjectTextReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipNew\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipNewCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipNew\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipNewDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipNew\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipNewReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverOwnershipUsed\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipUsedCreate(projectId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverOwnershipUsed\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId: string, articleId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipUsedDelete(projectId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverOwnershipUsed\" for Project
     * @summary 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).articleUsageControllerProjectTakeoverOwnershipUsedReadCollection(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a ModelTrait for Project
     * @summary 
     * @param {string} projectId 
     * @param {CreateModelTraitDto} createModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public modelTraitControllerProjectCreate(projectId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).modelTraitControllerProjectCreate(projectId, createModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ModelTrait for Project
     * @summary 
     * @param {string} projectId 
     * @param {number} modelTraitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public modelTraitControllerProjectDelete(projectId: string, modelTraitId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).modelTraitControllerProjectDelete(projectId, modelTraitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all traits on Project by modelId
     * @summary 
     * @param {string} projectId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public modelTraitControllerProjectReadCollection(projectId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).modelTraitControllerProjectReadCollection(projectId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for Project filtered by name
     * @summary 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public modelTraitControllerProjectReadCollectionOnModel(name: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).modelTraitControllerProjectReadCollectionOnModel(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for Project
     * @summary 
     * @param {string} name 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public modelTraitControllerProjectReadNameCollection(name: string, value: string, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).modelTraitControllerProjectReadNameCollection(name, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ModelTrait for Project
     * @summary 
     * @param {string} projectId 
     * @param {number} modelTraitId 
     * @param {UpdateModelTraitDto} updateModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public modelTraitControllerProjectUpdate(projectId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).modelTraitControllerProjectUpdate(projectId, modelTraitId, updateModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public nonAuthProjectControllerReadUnwantedIncidentInfo(projectId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).nonAuthProjectControllerReadUnwantedIncidentInfo(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read ProjectApartments without associated ProjectApartmentTakeoverLocks
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectControllerReadApartmentsWithoutTakeoverLock(projectId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectControllerReadApartmentsWithoutTakeoverLock(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reads a list of a projects involved users
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInvolvedControllerRead(projectId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInvolvedControllerRead(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectMomControllerReadContractorStatusReport(projectId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectMomControllerReadContractorStatusReport(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectMomControllerReadFieldsUsersCanContributeToCaseStatus(projectId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectMomControllerReadFieldsUsersCanContributeToCaseStatus(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} fieldId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectMomControllerSetContractorStatusComplete(projectId: number, fieldId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectMomControllerSetContractorStatusComplete(projectId, fieldId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} fieldId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectMomControllerSetContractorStatusIncomplete(projectId: number, fieldId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectMomControllerSetContractorStatusIncomplete(projectId, fieldId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets a summary for Project
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectSummaryControllerSummary(projectId: number, options?: AxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectSummaryControllerSummary(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApartmentApi - axios parameter creator
 * @export
 */
export const ProjectApartmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a article to \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewCreate: async (projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewCreate', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-new`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewDelete: async (projectApartmentId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewDelete', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-new/{articleId}`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewReadCollection: async (projectApartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverNewReadCollection', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-new`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a article to \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedCreate: async (projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedCreate', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'createArticleUsageDto' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedCreate', 'createArticleUsageDto', createArticleUsageDto)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-used`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createArticleUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ArticleUsage in \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedDelete: async (projectApartmentId: string, articleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedDelete', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedDelete', 'articleId', articleId)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-used/{articleId}`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)))
                .replace(`{${"articleId"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ArticleUsages \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedReadCollection: async (projectApartmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('articleUsageControllerProjectApartmentTakeoverUsedReadCollection', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/article-usages/projectApartment/{projectApartmentId}/takeover-used`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a ModelTrait for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentCreate: async (projectApartmentId: string, createModelTraitDto: CreateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentCreate', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'createModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentCreate', 'createModelTraitDto', createModelTraitDto)
            const localVarPath = `/project-apartment/{projectApartmentId}/model-traits`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ModelTrait for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentDelete: async (projectApartmentId: string, modelTraitId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentDelete', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentDelete', 'modelTraitId', modelTraitId)
            const localVarPath = `/project-apartment/{projectApartmentId}/model-traits/{modelTraitId}`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all traits on ProjectApartment by modelId
         * @summary 
         * @param {string} projectApartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentReadCollection: async (projectApartmentId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentReadCollection', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentReadCollection', 'limit', limit)
            const localVarPath = `/project-apartment/{projectApartmentId}/model-traits`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for ProjectApartment filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentReadCollectionOnModel: async (name: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentReadCollectionOnModel', 'name', name)
            const localVarPath = `/project-apartment/model-traits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read a collection of ModelTraits for ProjectApartment
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentReadNameCollection: async (name: string, value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentReadNameCollection', 'name', name)
            // verify required parameter 'value' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentReadNameCollection', 'value', value)
            const localVarPath = `/project-apartment/model-traits/{name}`
                .replace(`{${"name"}}`, encodeURIComponent(String(name)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a ModelTrait for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentUpdate: async (projectApartmentId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentUpdate', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'modelTraitId' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentUpdate', 'modelTraitId', modelTraitId)
            // verify required parameter 'updateModelTraitDto' is not null or undefined
            assertParamExists('modelTraitControllerProjectApartmentUpdate', 'updateModelTraitDto', updateModelTraitDto)
            const localVarPath = `/project-apartment/{projectApartmentId}/model-traits/{modelTraitId}`
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)))
                .replace(`{${"modelTraitId"}}`, encodeURIComponent(String(modelTraitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateModelTraitDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read ProjectApartments
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentControllerReadApartments: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentControllerReadApartments', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/apartments`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete ProjectApartmentTakeoverLocks associated to a ProjectApartment
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentControllerUnlockApartmentForTakeover: async (projectId: number, projectApartmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentControllerUnlockApartmentForTakeover', 'projectId', projectId)
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('projectApartmentControllerUnlockApartmentForTakeover', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/projects/{projectId}/apartments/{projectApartmentId}/takeovers/locks`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApartmentApi - functional programming interface
 * @export
 */
export const ProjectApartmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApartmentApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a article to \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a article to \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId, createArticleUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ArticleUsage in \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId: string, articleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId, articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ArticleUsages \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseArticleUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a ModelTrait for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectApartmentCreate(projectApartmentId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectApartmentCreate(projectApartmentId, createModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ModelTrait for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectApartmentDelete(projectApartmentId: string, modelTraitId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectApartmentDelete(projectApartmentId, modelTraitId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all traits on ProjectApartment by modelId
         * @summary 
         * @param {string} projectApartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectApartmentReadCollection(projectApartmentId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectApartmentReadCollection(projectApartmentId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for ProjectApartment filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectApartmentReadCollectionOnModel(name: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectApartmentReadCollectionOnModel(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read a collection of ModelTraits for ProjectApartment
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectApartmentReadNameCollection(name: string, value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectApartmentReadNameCollection(name, value, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a ModelTrait for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modelTraitControllerProjectApartmentUpdate(projectApartmentId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseModelTraitDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modelTraitControllerProjectApartmentUpdate(projectApartmentId, modelTraitId, updateModelTraitDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read ProjectApartments
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentControllerReadApartments(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectApartmentCardDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentControllerReadApartments(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete ProjectApartmentTakeoverLocks associated to a ProjectApartment
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentControllerUnlockApartmentForTakeover(projectId: number, projectApartmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentControllerUnlockApartmentForTakeover(projectId, projectApartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApartmentApi - factory interface
 * @export
 */
export const ProjectApartmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApartmentApiFp(configuration)
    return {
        /**
         * Add a article to \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverNew\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a article to \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateArticleUsageDto} createArticleUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: any): AxiosPromise<ResponseArticleUsageDtoEntityResponse> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId, createArticleUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ArticleUsage in \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {string} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId: string, articleId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId, articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ArticleUsages \"TakeoverUsed\" for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId: string, options?: any): AxiosPromise<ResponseArticleUsageDtoCollectionResponse> {
            return localVarFp.articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a ModelTrait for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {CreateModelTraitDto} createModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentCreate(projectApartmentId: string, createModelTraitDto: CreateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerProjectApartmentCreate(projectApartmentId, createModelTraitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ModelTrait for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {number} modelTraitId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentDelete(projectApartmentId: string, modelTraitId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.modelTraitControllerProjectApartmentDelete(projectApartmentId, modelTraitId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all traits on ProjectApartment by modelId
         * @summary 
         * @param {string} projectApartmentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentReadCollection(projectApartmentId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseModelTraitDtoPageResponse> {
            return localVarFp.modelTraitControllerProjectApartmentReadCollection(projectApartmentId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for ProjectApartment filtered by name
         * @summary 
         * @param {string} name 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentReadCollectionOnModel(name: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerProjectApartmentReadCollectionOnModel(name, options).then((request) => request(axios, basePath));
        },
        /**
         * Read a collection of ModelTraits for ProjectApartment
         * @summary 
         * @param {string} name 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentReadNameCollection(name: string, value: string, options?: any): AxiosPromise<ResponseModelTraitDtoCollectionResponse> {
            return localVarFp.modelTraitControllerProjectApartmentReadNameCollection(name, value, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a ModelTrait for ProjectApartment
         * @summary 
         * @param {string} projectApartmentId 
         * @param {number} modelTraitId 
         * @param {UpdateModelTraitDto} updateModelTraitDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modelTraitControllerProjectApartmentUpdate(projectApartmentId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: any): AxiosPromise<ResponseModelTraitDtoEntityResponse> {
            return localVarFp.modelTraitControllerProjectApartmentUpdate(projectApartmentId, modelTraitId, updateModelTraitDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read ProjectApartments
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentControllerReadApartments(projectId: number, options?: any): AxiosPromise<ResponseProjectApartmentCardDtoCollectionResponse> {
            return localVarFp.projectApartmentControllerReadApartments(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete ProjectApartmentTakeoverLocks associated to a ProjectApartment
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentControllerUnlockApartmentForTakeover(projectId: number, projectApartmentId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.projectApartmentControllerUnlockApartmentForTakeover(projectId, projectApartmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApartmentApi - object-oriented interface
 * @export
 * @class ProjectApartmentApi
 * @extends {BaseAPI}
 */
export class ProjectApartmentApi extends BaseAPI {
    /**
     * Add a article to \"TakeoverNew\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverNewCreate(projectApartmentId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverNew\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId: string, articleId: string, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverNewDelete(projectApartmentId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverNew\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId: string, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverNewReadCollection(projectApartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a article to \"TakeoverUsed\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {CreateArticleUsageDto} createArticleUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId: string, createArticleUsageDto: CreateArticleUsageDto, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverUsedCreate(projectApartmentId, createArticleUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ArticleUsage in \"TakeoverUsed\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {string} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId: string, articleId: string, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverUsedDelete(projectApartmentId, articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ArticleUsages \"TakeoverUsed\" for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId: string, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).articleUsageControllerProjectApartmentTakeoverUsedReadCollection(projectApartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a ModelTrait for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {CreateModelTraitDto} createModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public modelTraitControllerProjectApartmentCreate(projectApartmentId: string, createModelTraitDto: CreateModelTraitDto, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).modelTraitControllerProjectApartmentCreate(projectApartmentId, createModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ModelTrait for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {number} modelTraitId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public modelTraitControllerProjectApartmentDelete(projectApartmentId: string, modelTraitId: number, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).modelTraitControllerProjectApartmentDelete(projectApartmentId, modelTraitId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all traits on ProjectApartment by modelId
     * @summary 
     * @param {string} projectApartmentId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {ModelTraitPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public modelTraitControllerProjectApartmentReadCollection(projectApartmentId: string, page: number, limit: number, order?: Order, orderBy?: ModelTraitPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).modelTraitControllerProjectApartmentReadCollection(projectApartmentId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for ProjectApartment filtered by name
     * @summary 
     * @param {string} name 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public modelTraitControllerProjectApartmentReadCollectionOnModel(name: string, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).modelTraitControllerProjectApartmentReadCollectionOnModel(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read a collection of ModelTraits for ProjectApartment
     * @summary 
     * @param {string} name 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public modelTraitControllerProjectApartmentReadNameCollection(name: string, value: string, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).modelTraitControllerProjectApartmentReadNameCollection(name, value, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a ModelTrait for ProjectApartment
     * @summary 
     * @param {string} projectApartmentId 
     * @param {number} modelTraitId 
     * @param {UpdateModelTraitDto} updateModelTraitDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public modelTraitControllerProjectApartmentUpdate(projectApartmentId: string, modelTraitId: number, updateModelTraitDto: UpdateModelTraitDto, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).modelTraitControllerProjectApartmentUpdate(projectApartmentId, modelTraitId, updateModelTraitDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read ProjectApartments
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public projectApartmentControllerReadApartments(projectId: number, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).projectApartmentControllerReadApartments(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete ProjectApartmentTakeoverLocks associated to a ProjectApartment
     * @param {number} projectId 
     * @param {number} projectApartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentApi
     */
    public projectApartmentControllerUnlockApartmentForTakeover(projectId: number, projectApartmentId: number, options?: AxiosRequestConfig) {
        return ProjectApartmentApiFp(this.configuration).projectApartmentControllerUnlockApartmentForTakeover(projectId, projectApartmentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApartmentClientApi - axios parameter creator
 * @export
 */
export const ProjectApartmentClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a ProjectApartmentClient
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {CreateProjectApartmentClientDto} createProjectApartmentClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerCreate: async (projectId: number, projectApartmentId: number, createProjectApartmentClientDto: CreateProjectApartmentClientDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentClientControllerCreate', 'projectId', projectId)
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('projectApartmentClientControllerCreate', 'projectApartmentId', projectApartmentId)
            // verify required parameter 'createProjectApartmentClientDto' is not null or undefined
            assertParamExists('projectApartmentClientControllerCreate', 'createProjectApartmentClientDto', createProjectApartmentClientDto)
            const localVarPath = `/projects/{projectId}/apartments/{projectApartmentId}/clients`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectApartmentClientDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a ProjectApartmentClient
         * @summary Delete a ProjectApartmentClient
         * @param {number} projectApartmentClientId 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerDelete: async (projectApartmentClientId: number, projectId: number, projectApartmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentClientId' is not null or undefined
            assertParamExists('projectApartmentClientControllerDelete', 'projectApartmentClientId', projectApartmentClientId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentClientControllerDelete', 'projectId', projectId)
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('projectApartmentClientControllerDelete', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/projects/{projectId}/apartments/{projectApartmentId}/clients/{projectApartmentClientId}`
                .replace(`{${"projectApartmentClientId"}}`, encodeURIComponent(String(projectApartmentClientId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a ProjectApartmentClient
         * @param {number} projectApartmentClientId 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerRead: async (projectApartmentClientId: number, projectId: number, projectApartmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentClientId' is not null or undefined
            assertParamExists('projectApartmentClientControllerRead', 'projectApartmentClientId', projectApartmentClientId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentClientControllerRead', 'projectId', projectId)
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('projectApartmentClientControllerRead', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/projects/{projectId}/apartments/{projectApartmentId}/clients/{projectApartmentClientId}`
                .replace(`{${"projectApartmentClientId"}}`, encodeURIComponent(String(projectApartmentClientId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all ProjectApartmentClients for ProjectApartment
         * @summary Read all ProjectApartmentClients for ProjectApartment
         * @param {number} page 
         * @param {number} limit 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerReadCollection: async (page: number, limit: number, projectId: number, projectApartmentId: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('projectApartmentClientControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('projectApartmentClientControllerReadCollection', 'limit', limit)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentClientControllerReadCollection', 'projectId', projectId)
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('projectApartmentClientControllerReadCollection', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/projects/{projectId}/apartments/{projectApartmentId}/clients`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a ProjectApartmentClient
         * @param {number} projectApartmentClientId 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerUpdate: async (projectApartmentClientId: number, projectId: number, projectApartmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectApartmentClientId' is not null or undefined
            assertParamExists('projectApartmentClientControllerUpdate', 'projectApartmentClientId', projectApartmentClientId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentClientControllerUpdate', 'projectId', projectId)
            // verify required parameter 'projectApartmentId' is not null or undefined
            assertParamExists('projectApartmentClientControllerUpdate', 'projectApartmentId', projectApartmentId)
            const localVarPath = `/projects/{projectId}/apartments/{projectApartmentId}/clients/{projectApartmentClientId}`
                .replace(`{${"projectApartmentClientId"}}`, encodeURIComponent(String(projectApartmentClientId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"projectApartmentId"}}`, encodeURIComponent(String(projectApartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApartmentClientApi - functional programming interface
 * @export
 */
export const ProjectApartmentClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApartmentClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a ProjectApartmentClient
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {CreateProjectApartmentClientDto} createProjectApartmentClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentClientControllerCreate(projectId: number, projectApartmentId: number, createProjectApartmentClientDto: CreateProjectApartmentClientDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectApartmentClientDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentClientControllerCreate(projectId, projectApartmentId, createProjectApartmentClientDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a ProjectApartmentClient
         * @summary Delete a ProjectApartmentClient
         * @param {number} projectApartmentClientId 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentClientControllerDelete(projectApartmentClientId: number, projectId: number, projectApartmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentClientControllerDelete(projectApartmentClientId, projectId, projectApartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a ProjectApartmentClient
         * @param {number} projectApartmentClientId 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentClientControllerRead(projectApartmentClientId: number, projectId: number, projectApartmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectApartmentClientDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentClientControllerRead(projectApartmentClientId, projectId, projectApartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all ProjectApartmentClients for ProjectApartment
         * @summary Read all ProjectApartmentClients for ProjectApartment
         * @param {number} page 
         * @param {number} limit 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentClientControllerReadCollection(page: number, limit: number, projectId: number, projectApartmentId: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectApartmentClientDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentClientControllerReadCollection(page, limit, projectId, projectApartmentId, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a ProjectApartmentClient
         * @param {number} projectApartmentClientId 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentClientControllerUpdate(projectApartmentClientId: number, projectId: number, projectApartmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentClientControllerUpdate(projectApartmentClientId, projectId, projectApartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApartmentClientApi - factory interface
 * @export
 */
export const ProjectApartmentClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApartmentClientApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a ProjectApartmentClient
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {CreateProjectApartmentClientDto} createProjectApartmentClientDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerCreate(projectId: number, projectApartmentId: number, createProjectApartmentClientDto: CreateProjectApartmentClientDto, options?: any): AxiosPromise<ResponseProjectApartmentClientDtoEntityResponse> {
            return localVarFp.projectApartmentClientControllerCreate(projectId, projectApartmentId, createProjectApartmentClientDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a ProjectApartmentClient
         * @summary Delete a ProjectApartmentClient
         * @param {number} projectApartmentClientId 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerDelete(projectApartmentClientId: number, projectId: number, projectApartmentId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.projectApartmentClientControllerDelete(projectApartmentClientId, projectId, projectApartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a ProjectApartmentClient
         * @param {number} projectApartmentClientId 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerRead(projectApartmentClientId: number, projectId: number, projectApartmentId: number, options?: any): AxiosPromise<ResponseProjectApartmentClientDtoEntityResponse> {
            return localVarFp.projectApartmentClientControllerRead(projectApartmentClientId, projectId, projectApartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all ProjectApartmentClients for ProjectApartment
         * @summary Read all ProjectApartmentClients for ProjectApartment
         * @param {number} page 
         * @param {number} limit 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerReadCollection(page: number, limit: number, projectId: number, projectApartmentId: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponseProjectApartmentClientDtoPageResponse> {
            return localVarFp.projectApartmentClientControllerReadCollection(page, limit, projectId, projectApartmentId, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a ProjectApartmentClient
         * @param {number} projectApartmentClientId 
         * @param {number} projectId 
         * @param {number} projectApartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentClientControllerUpdate(projectApartmentClientId: number, projectId: number, projectApartmentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.projectApartmentClientControllerUpdate(projectApartmentClientId, projectId, projectApartmentId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApartmentClientApi - object-oriented interface
 * @export
 * @class ProjectApartmentClientApi
 * @extends {BaseAPI}
 */
export class ProjectApartmentClientApi extends BaseAPI {
    /**
     * 
     * @summary Create a ProjectApartmentClient
     * @param {number} projectId 
     * @param {number} projectApartmentId 
     * @param {CreateProjectApartmentClientDto} createProjectApartmentClientDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentClientApi
     */
    public projectApartmentClientControllerCreate(projectId: number, projectApartmentId: number, createProjectApartmentClientDto: CreateProjectApartmentClientDto, options?: AxiosRequestConfig) {
        return ProjectApartmentClientApiFp(this.configuration).projectApartmentClientControllerCreate(projectId, projectApartmentId, createProjectApartmentClientDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a ProjectApartmentClient
     * @summary Delete a ProjectApartmentClient
     * @param {number} projectApartmentClientId 
     * @param {number} projectId 
     * @param {number} projectApartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentClientApi
     */
    public projectApartmentClientControllerDelete(projectApartmentClientId: number, projectId: number, projectApartmentId: number, options?: AxiosRequestConfig) {
        return ProjectApartmentClientApiFp(this.configuration).projectApartmentClientControllerDelete(projectApartmentClientId, projectId, projectApartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a ProjectApartmentClient
     * @param {number} projectApartmentClientId 
     * @param {number} projectId 
     * @param {number} projectApartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentClientApi
     */
    public projectApartmentClientControllerRead(projectApartmentClientId: number, projectId: number, projectApartmentId: number, options?: AxiosRequestConfig) {
        return ProjectApartmentClientApiFp(this.configuration).projectApartmentClientControllerRead(projectApartmentClientId, projectId, projectApartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all ProjectApartmentClients for ProjectApartment
     * @summary Read all ProjectApartmentClients for ProjectApartment
     * @param {number} page 
     * @param {number} limit 
     * @param {number} projectId 
     * @param {number} projectApartmentId 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentClientApi
     */
    public projectApartmentClientControllerReadCollection(page: number, limit: number, projectId: number, projectApartmentId: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return ProjectApartmentClientApiFp(this.configuration).projectApartmentClientControllerReadCollection(page, limit, projectId, projectApartmentId, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a ProjectApartmentClient
     * @param {number} projectApartmentClientId 
     * @param {number} projectId 
     * @param {number} projectApartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentClientApi
     */
    public projectApartmentClientControllerUpdate(projectApartmentClientId: number, projectId: number, projectApartmentId: number, options?: AxiosRequestConfig) {
        return ProjectApartmentClientApiFp(this.configuration).projectApartmentClientControllerUpdate(projectApartmentClientId, projectId, projectApartmentId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectApartmentTakeoverApi - axios parameter creator
 * @export
 */
export const ProjectApartmentTakeoverApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {CreateProjectApartmentTakeoverDto} createProjectApartmentTakeoverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentTakeoverControllerCreate: async (projectId: number, apartmentId: number, createProjectApartmentTakeoverDto: CreateProjectApartmentTakeoverDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentTakeoverControllerCreate', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('projectApartmentTakeoverControllerCreate', 'apartmentId', apartmentId)
            // verify required parameter 'createProjectApartmentTakeoverDto' is not null or undefined
            assertParamExists('projectApartmentTakeoverControllerCreate', 'createProjectApartmentTakeoverDto', createProjectApartmentTakeoverDto)
            const localVarPath = `/projects/{projectId}/apartments/{apartmentId}/takeovers`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectApartmentTakeoverDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentTakeoverControllerReadNewTakeoverData: async (projectId: number, apartmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentTakeoverControllerReadNewTakeoverData', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('projectApartmentTakeoverControllerReadNewTakeoverData', 'apartmentId', apartmentId)
            const localVarPath = `/projects/{projectId}/apartments/{apartmentId}/takeovers/new`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {Array<ProjectApartmentTakeoverInvolvedUserRecipientDto>} projectApartmentTakeoverInvolvedUserRecipientDto 
         * @param {Array<any>} [projectApartmentTakeoverSupplierOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentTakeoverControllerReadRecipients: async (projectId: number, apartmentId: number, projectApartmentTakeoverInvolvedUserRecipientDto: Array<ProjectApartmentTakeoverInvolvedUserRecipientDto>, projectApartmentTakeoverSupplierOption?: Array<any>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectApartmentTakeoverControllerReadRecipients', 'projectId', projectId)
            // verify required parameter 'apartmentId' is not null or undefined
            assertParamExists('projectApartmentTakeoverControllerReadRecipients', 'apartmentId', apartmentId)
            // verify required parameter 'projectApartmentTakeoverInvolvedUserRecipientDto' is not null or undefined
            assertParamExists('projectApartmentTakeoverControllerReadRecipients', 'projectApartmentTakeoverInvolvedUserRecipientDto', projectApartmentTakeoverInvolvedUserRecipientDto)
            const localVarPath = `/projects/{projectId}/apartments/{apartmentId}/takeovers/recipients`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"apartmentId"}}`, encodeURIComponent(String(apartmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (projectApartmentTakeoverSupplierOption) {
                localVarQueryParameter['projectApartmentTakeoverSupplierOption'] = projectApartmentTakeoverSupplierOption;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectApartmentTakeoverInvolvedUserRecipientDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApartmentTakeoverApi - functional programming interface
 * @export
 */
export const ProjectApartmentTakeoverApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApartmentTakeoverApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {CreateProjectApartmentTakeoverDto} createProjectApartmentTakeoverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentTakeoverControllerCreate(projectId: number, apartmentId: number, createProjectApartmentTakeoverDto: CreateProjectApartmentTakeoverDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectApartmentTakeoverDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentTakeoverControllerCreate(projectId, apartmentId, createProjectApartmentTakeoverDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentTakeoverControllerReadNewTakeoverData(projectId: number, apartmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseNewProjectApartmentTakeoverDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentTakeoverControllerReadNewTakeoverData(projectId, apartmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {Array<ProjectApartmentTakeoverInvolvedUserRecipientDto>} projectApartmentTakeoverInvolvedUserRecipientDto 
         * @param {Array<any>} [projectApartmentTakeoverSupplierOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectApartmentTakeoverControllerReadRecipients(projectId: number, apartmentId: number, projectApartmentTakeoverInvolvedUserRecipientDto: Array<ProjectApartmentTakeoverInvolvedUserRecipientDto>, projectApartmentTakeoverSupplierOption?: Array<any>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectApartmentTakeoverDestinationDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectApartmentTakeoverControllerReadRecipients(projectId, apartmentId, projectApartmentTakeoverInvolvedUserRecipientDto, projectApartmentTakeoverSupplierOption, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectApartmentTakeoverApi - factory interface
 * @export
 */
export const ProjectApartmentTakeoverApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApartmentTakeoverApiFp(configuration)
    return {
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {CreateProjectApartmentTakeoverDto} createProjectApartmentTakeoverDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentTakeoverControllerCreate(projectId: number, apartmentId: number, createProjectApartmentTakeoverDto: CreateProjectApartmentTakeoverDto, options?: any): AxiosPromise<ResponseProjectApartmentTakeoverDtoEntityResponse> {
            return localVarFp.projectApartmentTakeoverControllerCreate(projectId, apartmentId, createProjectApartmentTakeoverDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentTakeoverControllerReadNewTakeoverData(projectId: number, apartmentId: number, options?: any): AxiosPromise<ResponseNewProjectApartmentTakeoverDtoEntityResponse> {
            return localVarFp.projectApartmentTakeoverControllerReadNewTakeoverData(projectId, apartmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} projectId 
         * @param {number} apartmentId 
         * @param {Array<ProjectApartmentTakeoverInvolvedUserRecipientDto>} projectApartmentTakeoverInvolvedUserRecipientDto 
         * @param {Array<any>} [projectApartmentTakeoverSupplierOption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectApartmentTakeoverControllerReadRecipients(projectId: number, apartmentId: number, projectApartmentTakeoverInvolvedUserRecipientDto: Array<ProjectApartmentTakeoverInvolvedUserRecipientDto>, projectApartmentTakeoverSupplierOption?: Array<any>, options?: any): AxiosPromise<ResponseProjectApartmentTakeoverDestinationDtoCollectionResponse> {
            return localVarFp.projectApartmentTakeoverControllerReadRecipients(projectId, apartmentId, projectApartmentTakeoverInvolvedUserRecipientDto, projectApartmentTakeoverSupplierOption, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApartmentTakeoverApi - object-oriented interface
 * @export
 * @class ProjectApartmentTakeoverApi
 * @extends {BaseAPI}
 */
export class ProjectApartmentTakeoverApi extends BaseAPI {
    /**
     * 
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {CreateProjectApartmentTakeoverDto} createProjectApartmentTakeoverDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentTakeoverApi
     */
    public projectApartmentTakeoverControllerCreate(projectId: number, apartmentId: number, createProjectApartmentTakeoverDto: CreateProjectApartmentTakeoverDto, options?: AxiosRequestConfig) {
        return ProjectApartmentTakeoverApiFp(this.configuration).projectApartmentTakeoverControllerCreate(projectId, apartmentId, createProjectApartmentTakeoverDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentTakeoverApi
     */
    public projectApartmentTakeoverControllerReadNewTakeoverData(projectId: number, apartmentId: number, options?: AxiosRequestConfig) {
        return ProjectApartmentTakeoverApiFp(this.configuration).projectApartmentTakeoverControllerReadNewTakeoverData(projectId, apartmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} projectId 
     * @param {number} apartmentId 
     * @param {Array<ProjectApartmentTakeoverInvolvedUserRecipientDto>} projectApartmentTakeoverInvolvedUserRecipientDto 
     * @param {Array<any>} [projectApartmentTakeoverSupplierOption] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApartmentTakeoverApi
     */
    public projectApartmentTakeoverControllerReadRecipients(projectId: number, apartmentId: number, projectApartmentTakeoverInvolvedUserRecipientDto: Array<ProjectApartmentTakeoverInvolvedUserRecipientDto>, projectApartmentTakeoverSupplierOption?: Array<any>, options?: AxiosRequestConfig) {
        return ProjectApartmentTakeoverApiFp(this.configuration).projectApartmentTakeoverControllerReadRecipients(projectId, apartmentId, projectApartmentTakeoverInvolvedUserRecipientDto, projectApartmentTakeoverSupplierOption, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportingApi - axios parameter creator
 * @export
 */
export const ReportingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentReportingControllerReadProjectCollection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/unwanted-incidents/reporting/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportingApi - functional programming interface
 * @export
 */
export const ReportingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentReportingControllerReadProjectCollection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentReportingProjectDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentReportingControllerReadProjectCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportingApi - factory interface
 * @export
 */
export const ReportingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentReportingControllerReadProjectCollection(options?: any): AxiosPromise<ResponseUnwantedIncidentReportingProjectDtoCollectionResponse> {
            return localVarFp.unwantedIncidentReportingControllerReadProjectCollection(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportingApi - object-oriented interface
 * @export
 * @class ReportingApi
 * @extends {BaseAPI}
 */
export class ReportingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public unwantedIncidentReportingControllerReadProjectCollection(options?: AxiosRequestConfig) {
        return ReportingApiFp(this.configuration).unwantedIncidentReportingControllerReadProjectCollection(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SidebarApi - axios parameter creator
 * @export
 */
export const SidebarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Pin a sidebar item
         * @param {CreateSidebarItemPinnedDto} createSidebarItemPinnedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sidebarControllerCreateSidebarItemPinned: async (createSidebarItemPinnedDto: CreateSidebarItemPinnedDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSidebarItemPinnedDto' is not null or undefined
            assertParamExists('sidebarControllerCreateSidebarItemPinned', 'createSidebarItemPinnedDto', createSidebarItemPinnedDto)
            const localVarPath = `/sidebars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSidebarItemPinnedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a SidebarItemPinned
         * @param {string} sidebarItemPinnedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sidebarControllerDelete: async (sidebarItemPinnedId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sidebarItemPinnedId' is not null or undefined
            assertParamExists('sidebarControllerDelete', 'sidebarItemPinnedId', sidebarItemPinnedId)
            const localVarPath = `/sidebars/{sidebarItemPinnedId}`
                .replace(`{${"sidebarItemPinnedId"}}`, encodeURIComponent(String(sidebarItemPinnedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the collection of SidebarItemPinned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sidebarControllerReadCollection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sidebars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an SidebarItemPinned
         * @param {string} sidebarItemPinnedId 
         * @param {UpdateSidebarItemPinnedDto} updateSidebarItemPinnedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sidebarControllerUpdate: async (sidebarItemPinnedId: string, updateSidebarItemPinnedDto: UpdateSidebarItemPinnedDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sidebarItemPinnedId' is not null or undefined
            assertParamExists('sidebarControllerUpdate', 'sidebarItemPinnedId', sidebarItemPinnedId)
            // verify required parameter 'updateSidebarItemPinnedDto' is not null or undefined
            assertParamExists('sidebarControllerUpdate', 'updateSidebarItemPinnedDto', updateSidebarItemPinnedDto)
            const localVarPath = `/sidebars/{sidebarItemPinnedId}`
                .replace(`{${"sidebarItemPinnedId"}}`, encodeURIComponent(String(sidebarItemPinnedId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSidebarItemPinnedDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SidebarApi - functional programming interface
 * @export
 */
export const SidebarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SidebarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Pin a sidebar item
         * @param {CreateSidebarItemPinnedDto} createSidebarItemPinnedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sidebarControllerCreateSidebarItemPinned(createSidebarItemPinnedDto: CreateSidebarItemPinnedDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseSidebarItemPinnedDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sidebarControllerCreateSidebarItemPinned(createSidebarItemPinnedDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a SidebarItemPinned
         * @param {string} sidebarItemPinnedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sidebarControllerDelete(sidebarItemPinnedId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sidebarControllerDelete(sidebarItemPinnedId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the collection of SidebarItemPinned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sidebarControllerReadCollection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseSidebarItemPinnedDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sidebarControllerReadCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an SidebarItemPinned
         * @param {string} sidebarItemPinnedId 
         * @param {UpdateSidebarItemPinnedDto} updateSidebarItemPinnedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sidebarControllerUpdate(sidebarItemPinnedId: string, updateSidebarItemPinnedDto: UpdateSidebarItemPinnedDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseSidebarItemPinnedDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sidebarControllerUpdate(sidebarItemPinnedId, updateSidebarItemPinnedDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SidebarApi - factory interface
 * @export
 */
export const SidebarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SidebarApiFp(configuration)
    return {
        /**
         * 
         * @summary Pin a sidebar item
         * @param {CreateSidebarItemPinnedDto} createSidebarItemPinnedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sidebarControllerCreateSidebarItemPinned(createSidebarItemPinnedDto: CreateSidebarItemPinnedDto, options?: any): AxiosPromise<ResponseSidebarItemPinnedDtoEntityResponse> {
            return localVarFp.sidebarControllerCreateSidebarItemPinned(createSidebarItemPinnedDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a SidebarItemPinned
         * @param {string} sidebarItemPinnedId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sidebarControllerDelete(sidebarItemPinnedId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.sidebarControllerDelete(sidebarItemPinnedId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the collection of SidebarItemPinned
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sidebarControllerReadCollection(options?: any): AxiosPromise<ResponseSidebarItemPinnedDtoCollectionResponse> {
            return localVarFp.sidebarControllerReadCollection(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an SidebarItemPinned
         * @param {string} sidebarItemPinnedId 
         * @param {UpdateSidebarItemPinnedDto} updateSidebarItemPinnedDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sidebarControllerUpdate(sidebarItemPinnedId: string, updateSidebarItemPinnedDto: UpdateSidebarItemPinnedDto, options?: any): AxiosPromise<ResponseSidebarItemPinnedDtoEntityResponse> {
            return localVarFp.sidebarControllerUpdate(sidebarItemPinnedId, updateSidebarItemPinnedDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SidebarApi - object-oriented interface
 * @export
 * @class SidebarApi
 * @extends {BaseAPI}
 */
export class SidebarApi extends BaseAPI {
    /**
     * 
     * @summary Pin a sidebar item
     * @param {CreateSidebarItemPinnedDto} createSidebarItemPinnedDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SidebarApi
     */
    public sidebarControllerCreateSidebarItemPinned(createSidebarItemPinnedDto: CreateSidebarItemPinnedDto, options?: AxiosRequestConfig) {
        return SidebarApiFp(this.configuration).sidebarControllerCreateSidebarItemPinned(createSidebarItemPinnedDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a SidebarItemPinned
     * @param {string} sidebarItemPinnedId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SidebarApi
     */
    public sidebarControllerDelete(sidebarItemPinnedId: string, options?: AxiosRequestConfig) {
        return SidebarApiFp(this.configuration).sidebarControllerDelete(sidebarItemPinnedId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the collection of SidebarItemPinned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SidebarApi
     */
    public sidebarControllerReadCollection(options?: AxiosRequestConfig) {
        return SidebarApiFp(this.configuration).sidebarControllerReadCollection(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an SidebarItemPinned
     * @param {string} sidebarItemPinnedId 
     * @param {UpdateSidebarItemPinnedDto} updateSidebarItemPinnedDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SidebarApi
     */
    public sidebarControllerUpdate(sidebarItemPinnedId: string, updateSidebarItemPinnedDto: UpdateSidebarItemPinnedDto, options?: AxiosRequestConfig) {
        return SidebarApiFp(this.configuration).sidebarControllerUpdate(sidebarItemPinnedId, updateSidebarItemPinnedDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SystemApi - axios parameter creator
 * @export
 */
export const SystemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check status of metering point on ElHub
         * @param {string} meteringPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemControllerMeteringPointStatus: async (meteringPointId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meteringPointId' is not null or undefined
            assertParamExists('systemControllerMeteringPointStatus', 'meteringPointId', meteringPointId)
            const localVarPath = `/system/el-hub/metering-point-status/{meteringPointId}`
                .replace(`{${"meteringPointId"}}`, encodeURIComponent(String(meteringPointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemControllerReadCountries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Will fallback to last CPI value found and then adjust starting point to one year before.
         * @summary Calculate the yearly CPI increase for a country
         * @param {'no'} country 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemControllerReadCpiYearlyAdjustment: async (country: 'no', year: number, month: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'country' is not null or undefined
            assertParamExists('systemControllerReadCpiYearlyAdjustment', 'country', country)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('systemControllerReadCpiYearlyAdjustment', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('systemControllerReadCpiYearlyAdjustment', 'month', month)
            const localVarPath = `/system/cpi/yearly-adjustment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemControllerReadTimezones: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/system/timezones`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SystemApi - functional programming interface
 * @export
 */
export const SystemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SystemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check status of metering point on ElHub
         * @param {string} meteringPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemControllerMeteringPointStatus(meteringPointId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMeteringPointStatusDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemControllerMeteringPointStatus(meteringPointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemControllerReadCountries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCountryDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemControllerReadCountries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Will fallback to last CPI value found and then adjust starting point to one year before.
         * @summary Calculate the yearly CPI increase for a country
         * @param {'no'} country 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemControllerReadCpiYearlyAdjustment(country: 'no', year: number, month: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCpiYearlyAdjustmentDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemControllerReadCpiYearlyAdjustment(country, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async systemControllerReadTimezones(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTimezoneDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.systemControllerReadTimezones(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SystemApi - factory interface
 * @export
 */
export const SystemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SystemApiFp(configuration)
    return {
        /**
         * 
         * @summary Check status of metering point on ElHub
         * @param {string} meteringPointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemControllerMeteringPointStatus(meteringPointId: string, options?: any): AxiosPromise<ResponseMeteringPointStatusDtoEntityResponse> {
            return localVarFp.systemControllerMeteringPointStatus(meteringPointId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all countries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemControllerReadCountries(options?: any): AxiosPromise<ResponseCountryDtoCollectionResponse> {
            return localVarFp.systemControllerReadCountries(options).then((request) => request(axios, basePath));
        },
        /**
         * Will fallback to last CPI value found and then adjust starting point to one year before.
         * @summary Calculate the yearly CPI increase for a country
         * @param {'no'} country 
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemControllerReadCpiYearlyAdjustment(country: 'no', year: number, month: number, options?: any): AxiosPromise<ResponseCpiYearlyAdjustmentDtoEntityResponse> {
            return localVarFp.systemControllerReadCpiYearlyAdjustment(country, year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all timezones
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemControllerReadTimezones(options?: any): AxiosPromise<ResponseTimezoneDtoCollectionResponse> {
            return localVarFp.systemControllerReadTimezones(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SystemApi - object-oriented interface
 * @export
 * @class SystemApi
 * @extends {BaseAPI}
 */
export class SystemApi extends BaseAPI {
    /**
     * 
     * @summary Check status of metering point on ElHub
     * @param {string} meteringPointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemControllerMeteringPointStatus(meteringPointId: string, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).systemControllerMeteringPointStatus(meteringPointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all countries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemControllerReadCountries(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).systemControllerReadCountries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Will fallback to last CPI value found and then adjust starting point to one year before.
     * @summary Calculate the yearly CPI increase for a country
     * @param {'no'} country 
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemControllerReadCpiYearlyAdjustment(country: 'no', year: number, month: number, options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).systemControllerReadCpiYearlyAdjustment(country, year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all timezones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApi
     */
    public systemControllerReadTimezones(options?: AxiosRequestConfig) {
        return SystemApiFp(this.configuration).systemControllerReadTimezones(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TakeoverSupplierOptionApi - axios parameter creator
 * @export
 */
export const TakeoverSupplierOptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Count all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosCount: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosCount', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/count`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosCreate: async (takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosCreate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosDelete: async (takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosDelete', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosReadCollection: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosReadCollection', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosSort: async (takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSort', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/sort`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosSwap: async (takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSwap', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/swap/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosUpdate: async (takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosUpdate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionLogosUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/logos/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/count`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate: async (takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete: async (takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort: async (takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/sort`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap: async (takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/swap/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate: async (takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-form/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/count`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate: async (takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete: async (takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort: async (takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/sort`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap: async (takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/swap/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate: async (takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/right-of-withdrawal-information/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Count all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/count`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate: async (takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete: async (takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection: async (takeoverSupplierOptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort: async (takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/sort`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap: async (takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/swap/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate: async (takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'takeoverSupplierOptionId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate', 'takeoverSupplierOptionId', takeoverSupplierOptionId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/takeover-supplier-option/{takeoverSupplierOptionId}/terms-of-service/{fileId}`
                .replace(`{${"takeoverSupplierOptionId"}}`, encodeURIComponent(String(takeoverSupplierOptionId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all TakeoverSupplierOptions on given projectId
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeoverSupplierOptionControllerReadTakeoverSupplierOptions: async (projectId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('takeoverSupplierOptionControllerReadTakeoverSupplierOptions', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/takeover-supplier-options`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TakeoverSupplierOptionApi - functional programming interface
 * @export
 */
export const TakeoverSupplierOptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TakeoverSupplierOptionApiAxiosParamCreator(configuration)
    return {
        /**
         * Count all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Count all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all TakeoverSupplierOptions on given projectId
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takeoverSupplierOptionControllerReadTakeoverSupplierOptions(projectId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseTakeoverSupplierOptionDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takeoverSupplierOptionControllerReadTakeoverSupplierOptions(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TakeoverSupplierOptionApi - factory interface
 * @export
 */
export const TakeoverSupplierOptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TakeoverSupplierOptionApiFp(configuration)
    return {
        /**
         * Count all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"Logos\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Count all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"TermsOfService\" for TakeoverSupplierOption
         * @summary 
         * @param {string} takeoverSupplierOptionId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all TakeoverSupplierOptions on given projectId
         * @param {number} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeoverSupplierOptionControllerReadTakeoverSupplierOptions(projectId: number, options?: any): AxiosPromise<ResponseTakeoverSupplierOptionDtoCollectionResponse> {
            return localVarFp.takeoverSupplierOptionControllerReadTakeoverSupplierOptions(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TakeoverSupplierOptionApi - object-oriented interface
 * @export
 * @class TakeoverSupplierOptionApi
 * @extends {BaseAPI}
 */
export class TakeoverSupplierOptionApi extends BaseAPI {
    /**
     * Count all FileUsages in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosCount(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosReadCollection(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"Logos\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionLogosUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCount(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormReadCollection(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"RightOfWithdrawalForm\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalFormUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCount(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationReadCollection(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"RightOfWithdrawalInformation\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionRightOfWithdrawalInformationUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Count all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceCount(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceCreate(takeoverSupplierOptionId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceDelete(takeoverSupplierOptionId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId: string, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceReadCollection(takeoverSupplierOptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceSort(takeoverSupplierOptionId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceSwap(takeoverSupplierOptionId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"TermsOfService\" for TakeoverSupplierOption
     * @summary 
     * @param {string} takeoverSupplierOptionId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).fileUsageControllerTakeoverSupplierOptionTermsOfServiceUpdate(takeoverSupplierOptionId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all TakeoverSupplierOptions on given projectId
     * @param {number} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TakeoverSupplierOptionApi
     */
    public takeoverSupplierOptionControllerReadTakeoverSupplierOptions(projectId: number, options?: AxiosRequestConfig) {
        return TakeoverSupplierOptionApiFp(this.configuration).takeoverSupplierOptionControllerReadTakeoverSupplierOptions(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UnwantedIncidentApi - axios parameter creator
 * @export
 */
export const UnwantedIncidentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Count all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesCount: async (unwantedIncidentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesCount', 'unwantedIncidentId', unwantedIncidentId)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/count`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a file to \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesCreate: async (unwantedIncidentId: string, createFileUsageDto: CreateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesCreate', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'createFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesCreate', 'createFileUsageDto', createFileUsageDto)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a File in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesDelete: async (unwantedIncidentId: string, fileId: number, deleteFile?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesDelete', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesDelete', 'fileId', fileId)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/{fileId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deleteFile !== undefined) {
                localVarQueryParameter['deleteFile'] = deleteFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all FileUsages \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesReadCollection: async (unwantedIncidentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesReadCollection', 'unwantedIncidentId', unwantedIncidentId)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sort all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesSort: async (unwantedIncidentId: string, fileUsageSortDto: FileUsageSortDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSort', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'fileUsageSortDto' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSort', 'fileUsageSortDto', fileUsageSortDto)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/sort`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fileUsageSortDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Swap a File in FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesSwap: async (unwantedIncidentId: string, fileId: number, swapFileDto: SwapFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSwap', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSwap', 'fileId', fileId)
            // verify required parameter 'swapFileDto' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesSwap', 'swapFileDto', swapFileDto)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/swap/{fileId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesUpdate: async (unwantedIncidentId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesUpdate', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesUpdate', 'fileId', fileId)
            // verify required parameter 'updateFileUsageDto' is not null or undefined
            assertParamExists('fileUsageControllerUnwantedIncidentFilesUpdate', 'updateFileUsageDto', updateFileUsageDto)
            const localVarPath = `/file-usages/unwanted-incident/{unwantedIncidentId}/files/{fileId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"fileId"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFileUsageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an unwanted incident without being authenticated
         * @param {CreateUnwantedIncidentDto} createUnwantedIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nonAuthUnwantedIncidentControllerCreate: async (createUnwantedIncidentDto: CreateUnwantedIncidentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUnwantedIncidentDto' is not null or undefined
            assertParamExists('nonAuthUnwantedIncidentControllerCreate', 'createUnwantedIncidentDto', createUnwantedIncidentDto)
            const localVarPath = `/non-auth/unwanted-incidents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUnwantedIncidentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an unwanted incident
         * @param {CreateUnwantedIncidentDto} createUnwantedIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerCreate: async (createUnwantedIncidentDto: CreateUnwantedIncidentDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUnwantedIncidentDto' is not null or undefined
            assertParamExists('unwantedIncidentControllerCreate', 'createUnwantedIncidentDto', createUnwantedIncidentDto)
            const localVarPath = `/unwanted-incidents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUnwantedIncidentDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerDelete: async (unwantedIncidentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentControllerDelete', 'unwantedIncidentId', unwantedIncidentId)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read an unwanted incident
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerRead: async (unwantedIncidentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentControllerRead', 'unwantedIncidentId', unwantedIncidentId)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all unwanted incidents on projects the user is UnwantedIncidentManager on
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {UnwantedIncidentPageOptionsOrderBy} [orderBy] 
         * @param {number} [projectId] Filter results on ProjectId
         * @param {number} [objectId] Filter results on ObjectId
         * @param {Array<'person-injury' | 'material-damage' | 'accident' | 'near-accident' | 'observation'>} [types] The types of unwanted incident you want to filer on. If provided will only show those types
         * @param {UnwantedIncidentType} [type] Use types instead
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerReadCollection: async (page: number, limit: number, order?: Order, orderBy?: UnwantedIncidentPageOptionsOrderBy, projectId?: number, objectId?: number, types?: Array<'person-injury' | 'material-damage' | 'accident' | 'near-accident' | 'observation'>, type?: UnwantedIncidentType, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('unwantedIncidentControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('unwantedIncidentControllerReadCollection', 'limit', limit)
            const localVarPath = `/unwanted-incidents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (projectId !== undefined) {
                localVarQueryParameter['ProjectId'] = projectId;
            }

            if (objectId !== undefined) {
                localVarQueryParameter['ObjectId'] = objectId;
            }

            if (types) {
                localVarQueryParameter['types'] = types;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerUpdate: async (unwantedIncidentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentControllerUpdate', 'unwantedIncidentId', unwantedIncidentId)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentFilterControllerReadObjectFilterCollection: async (page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('unwantedIncidentFilterControllerReadObjectFilterCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('unwantedIncidentFilterControllerReadObjectFilterCollection', 'limit', limit)
            const localVarPath = `/unwanted-incidents/filter/object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentFilterControllerReadProjectFilterCollection: async (page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('unwantedIncidentFilterControllerReadProjectFilterCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('unwantedIncidentFilterControllerReadProjectFilterCollection', 'limit', limit)
            const localVarPath = `/unwanted-incidents/filter/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an unwanted incident process action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {CreateUnwantedIncidentProcessActionDto} createUnwantedIncidentProcessActionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerCreate: async (unwantedIncidentId: string, unwantedIncidentProcessId: string, createUnwantedIncidentProcessActionDto: CreateUnwantedIncidentProcessActionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerCreate', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'unwantedIncidentProcessId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerCreate', 'unwantedIncidentProcessId', unwantedIncidentProcessId)
            // verify required parameter 'createUnwantedIncidentProcessActionDto' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerCreate', 'createUnwantedIncidentProcessActionDto', createUnwantedIncidentProcessActionDto)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes/{unwantedIncidentProcessId}/actions`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"unwantedIncidentProcessId"}}`, encodeURIComponent(String(unwantedIncidentProcessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUnwantedIncidentProcessActionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an unwanted incident\'s process\' action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {string} unwantedIncidentProcessActionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerDelete: async (unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerDelete', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'unwantedIncidentProcessId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerDelete', 'unwantedIncidentProcessId', unwantedIncidentProcessId)
            // verify required parameter 'unwantedIncidentProcessActionId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerDelete', 'unwantedIncidentProcessActionId', unwantedIncidentProcessActionId)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes/{unwantedIncidentProcessId}/actions/{unwantedIncidentProcessActionId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"unwantedIncidentProcessId"}}`, encodeURIComponent(String(unwantedIncidentProcessId)))
                .replace(`{${"unwantedIncidentProcessActionId"}}`, encodeURIComponent(String(unwantedIncidentProcessActionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read an unwanted incident\'s process\' action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {string} unwantedIncidentProcessActionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerRead: async (unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerRead', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'unwantedIncidentProcessId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerRead', 'unwantedIncidentProcessId', unwantedIncidentProcessId)
            // verify required parameter 'unwantedIncidentProcessActionId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerRead', 'unwantedIncidentProcessActionId', unwantedIncidentProcessActionId)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes/{unwantedIncidentProcessId}/actions/{unwantedIncidentProcessActionId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"unwantedIncidentProcessId"}}`, encodeURIComponent(String(unwantedIncidentProcessId)))
                .replace(`{${"unwantedIncidentProcessActionId"}}`, encodeURIComponent(String(unwantedIncidentProcessActionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read all unwanted incident\'s process\' actions
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerReadCollection: async (unwantedIncidentId: string, unwantedIncidentProcessId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerReadCollection', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'unwantedIncidentProcessId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerReadCollection', 'unwantedIncidentProcessId', unwantedIncidentProcessId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerReadCollection', 'limit', limit)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes/{unwantedIncidentProcessId}/actions`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"unwantedIncidentProcessId"}}`, encodeURIComponent(String(unwantedIncidentProcessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an unwanted incident\'s process\' action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {string} unwantedIncidentProcessActionId 
         * @param {UpdateUnwantedIncidentProcessActionDto} updateUnwantedIncidentProcessActionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerUpdate: async (unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, updateUnwantedIncidentProcessActionDto: UpdateUnwantedIncidentProcessActionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerUpdate', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'unwantedIncidentProcessId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerUpdate', 'unwantedIncidentProcessId', unwantedIncidentProcessId)
            // verify required parameter 'unwantedIncidentProcessActionId' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerUpdate', 'unwantedIncidentProcessActionId', unwantedIncidentProcessActionId)
            // verify required parameter 'updateUnwantedIncidentProcessActionDto' is not null or undefined
            assertParamExists('unwantedIncidentProcessActionControllerUpdate', 'updateUnwantedIncidentProcessActionDto', updateUnwantedIncidentProcessActionDto)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes/{unwantedIncidentProcessId}/actions/{unwantedIncidentProcessActionId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"unwantedIncidentProcessId"}}`, encodeURIComponent(String(unwantedIncidentProcessId)))
                .replace(`{${"unwantedIncidentProcessActionId"}}`, encodeURIComponent(String(unwantedIncidentProcessActionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUnwantedIncidentProcessActionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {CreateUnwantedIncidentProcessDto} createUnwantedIncidentProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerCreate: async (unwantedIncidentId: string, createUnwantedIncidentProcessDto: CreateUnwantedIncidentProcessDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerCreate', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'createUnwantedIncidentProcessDto' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerCreate', 'createUnwantedIncidentProcessDto', createUnwantedIncidentProcessDto)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUnwantedIncidentProcessDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerDelete: async (unwantedIncidentId: string, unwantedIncidentProcessId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerDelete', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'unwantedIncidentProcessId' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerDelete', 'unwantedIncidentProcessId', unwantedIncidentProcessId)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes/{unwantedIncidentProcessId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"unwantedIncidentProcessId"}}`, encodeURIComponent(String(unwantedIncidentProcessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerRead: async (unwantedIncidentId: string, unwantedIncidentProcessId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerRead', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'unwantedIncidentProcessId' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerRead', 'unwantedIncidentProcessId', unwantedIncidentProcessId)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes/{unwantedIncidentProcessId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"unwantedIncidentProcessId"}}`, encodeURIComponent(String(unwantedIncidentProcessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read an unwanted incident\'s processes
         * @param {string} unwantedIncidentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerReadCollection: async (unwantedIncidentId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerReadCollection', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerReadCollection', 'limit', limit)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {UpdateUnwantedIncidentProcessDto} updateUnwantedIncidentProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerUpdate: async (unwantedIncidentId: string, unwantedIncidentProcessId: string, updateUnwantedIncidentProcessDto: UpdateUnwantedIncidentProcessDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unwantedIncidentId' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerUpdate', 'unwantedIncidentId', unwantedIncidentId)
            // verify required parameter 'unwantedIncidentProcessId' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerUpdate', 'unwantedIncidentProcessId', unwantedIncidentProcessId)
            // verify required parameter 'updateUnwantedIncidentProcessDto' is not null or undefined
            assertParamExists('unwantedIncidentProcessControllerUpdate', 'updateUnwantedIncidentProcessDto', updateUnwantedIncidentProcessDto)
            const localVarPath = `/unwanted-incidents/{unwantedIncidentId}/processes/{unwantedIncidentProcessId}`
                .replace(`{${"unwantedIncidentId"}}`, encodeURIComponent(String(unwantedIncidentId)))
                .replace(`{${"unwantedIncidentProcessId"}}`, encodeURIComponent(String(unwantedIncidentProcessId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUnwantedIncidentProcessDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a collection of projects with UnwantedIncidents enabled
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {UnwantedIncidentProjectPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProjectControllerReadCollection: async (page: number, limit: number, order?: Order, orderBy?: UnwantedIncidentProjectPageOptionsOrderBy, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('unwantedIncidentProjectControllerReadCollection', 'page', page)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('unwantedIncidentProjectControllerReadCollection', 'limit', limit)
            const localVarPath = `/unwanted-incidents/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a collection of projects with UnwantedIncidents enabled
         * @param {number} lng 
         * @param {number} lat 
         * @param {number} [distance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProjectControllerReadNearbyCollection: async (lng: number, lat: number, distance?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lng' is not null or undefined
            assertParamExists('unwantedIncidentProjectControllerReadNearbyCollection', 'lng', lng)
            // verify required parameter 'lat' is not null or undefined
            assertParamExists('unwantedIncidentProjectControllerReadNearbyCollection', 'lat', lat)
            const localVarPath = `/unwanted-incidents/projects/nearby`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (distance !== undefined) {
                localVarQueryParameter['distance'] = distance;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentReportingControllerReadProjectCollection: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/unwanted-incidents/reporting/project`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UnwantedIncidentApi - functional programming interface
 * @export
 */
export const UnwantedIncidentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UnwantedIncidentApiAxiosParamCreator(configuration)
    return {
        /**
         * Count all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageCountDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a file to \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId, createFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a File in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId, fileId, deleteFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all FileUsages \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sort all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileUsageFileSortDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId, fileUsageSortDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Swap a File in FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId, fileId, swapFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseFileUsageDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId, fileId, updateFileUsageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an unwanted incident without being authenticated
         * @param {CreateUnwantedIncidentDto} createUnwantedIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nonAuthUnwantedIncidentControllerCreate(createUnwantedIncidentDto: CreateUnwantedIncidentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nonAuthUnwantedIncidentControllerCreate(createUnwantedIncidentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an unwanted incident
         * @param {CreateUnwantedIncidentDto} createUnwantedIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentControllerCreate(createUnwantedIncidentDto: CreateUnwantedIncidentDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentControllerCreate(createUnwantedIncidentDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentControllerDelete(unwantedIncidentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentControllerDelete(unwantedIncidentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read an unwanted incident
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentControllerRead(unwantedIncidentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentControllerRead(unwantedIncidentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all unwanted incidents on projects the user is UnwantedIncidentManager on
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {UnwantedIncidentPageOptionsOrderBy} [orderBy] 
         * @param {number} [projectId] Filter results on ProjectId
         * @param {number} [objectId] Filter results on ObjectId
         * @param {Array<'person-injury' | 'material-damage' | 'accident' | 'near-accident' | 'observation'>} [types] The types of unwanted incident you want to filer on. If provided will only show those types
         * @param {UnwantedIncidentType} [type] Use types instead
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: UnwantedIncidentPageOptionsOrderBy, projectId?: number, objectId?: number, types?: Array<'person-injury' | 'material-damage' | 'accident' | 'near-accident' | 'observation'>, type?: UnwantedIncidentType, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentControllerReadCollection(page, limit, order, orderBy, projectId, objectId, types, type, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentControllerUpdate(unwantedIncidentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentControllerUpdate(unwantedIncidentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentFilterControllerReadObjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentFilterControllerReadObjectFilterCollection(page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentFilterControllerReadProjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilterDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentFilterControllerReadProjectFilterCollection(page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an unwanted incident process action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {CreateUnwantedIncidentProcessActionDto} createUnwantedIncidentProcessActionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessActionControllerCreate(unwantedIncidentId: string, unwantedIncidentProcessId: string, createUnwantedIncidentProcessActionDto: CreateUnwantedIncidentProcessActionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentProcessActionDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessActionControllerCreate(unwantedIncidentId, unwantedIncidentProcessId, createUnwantedIncidentProcessActionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an unwanted incident\'s process\' action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {string} unwantedIncidentProcessActionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessActionControllerDelete(unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessActionControllerDelete(unwantedIncidentId, unwantedIncidentProcessId, unwantedIncidentProcessActionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read an unwanted incident\'s process\' action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {string} unwantedIncidentProcessActionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessActionControllerRead(unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentProcessActionDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessActionControllerRead(unwantedIncidentId, unwantedIncidentProcessId, unwantedIncidentProcessActionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read all unwanted incident\'s process\' actions
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessActionControllerReadCollection(unwantedIncidentId: string, unwantedIncidentProcessId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentProcessActionDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessActionControllerReadCollection(unwantedIncidentId, unwantedIncidentProcessId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an unwanted incident\'s process\' action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {string} unwantedIncidentProcessActionId 
         * @param {UpdateUnwantedIncidentProcessActionDto} updateUnwantedIncidentProcessActionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessActionControllerUpdate(unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, updateUnwantedIncidentProcessActionDto: UpdateUnwantedIncidentProcessActionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentProcessActionDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessActionControllerUpdate(unwantedIncidentId, unwantedIncidentProcessId, unwantedIncidentProcessActionId, updateUnwantedIncidentProcessActionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {CreateUnwantedIncidentProcessDto} createUnwantedIncidentProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessControllerCreate(unwantedIncidentId: string, createUnwantedIncidentProcessDto: CreateUnwantedIncidentProcessDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentProcessDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessControllerCreate(unwantedIncidentId, createUnwantedIncidentProcessDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessControllerDelete(unwantedIncidentId: string, unwantedIncidentProcessId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessControllerDelete(unwantedIncidentId, unwantedIncidentProcessId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessControllerRead(unwantedIncidentId: string, unwantedIncidentProcessId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentProcessDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessControllerRead(unwantedIncidentId, unwantedIncidentProcessId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read an unwanted incident\'s processes
         * @param {string} unwantedIncidentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessControllerReadCollection(unwantedIncidentId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentProcessDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessControllerReadCollection(unwantedIncidentId, page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {UpdateUnwantedIncidentProcessDto} updateUnwantedIncidentProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProcessControllerUpdate(unwantedIncidentId: string, unwantedIncidentProcessId: string, updateUnwantedIncidentProcessDto: UpdateUnwantedIncidentProcessDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentProcessDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProcessControllerUpdate(unwantedIncidentId, unwantedIncidentProcessId, updateUnwantedIncidentProcessDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a collection of projects with UnwantedIncidents enabled
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {UnwantedIncidentProjectPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProjectControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: UnwantedIncidentProjectPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentProjectDtoPageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProjectControllerReadCollection(page, limit, order, orderBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a collection of projects with UnwantedIncidents enabled
         * @param {number} lng 
         * @param {number} lat 
         * @param {number} [distance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentProjectControllerReadNearbyCollection(lng: number, lat: number, distance?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseNearbyUnwantedIncidentProjectDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentProjectControllerReadNearbyCollection(lng, lat, distance, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unwantedIncidentReportingControllerReadProjectCollection(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUnwantedIncidentReportingProjectDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unwantedIncidentReportingControllerReadProjectCollection(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UnwantedIncidentApi - factory interface
 * @export
 */
export const UnwantedIncidentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UnwantedIncidentApiFp(configuration)
    return {
        /**
         * Count all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId: string, options?: any): AxiosPromise<FileUsageCountDtoEntityResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a file to \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {CreateFileUsageDto} createFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId: string, createFileUsageDto: CreateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId, createFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a File in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {boolean} [deleteFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId: string, fileId: number, deleteFile?: boolean, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId, fileId, deleteFile, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all FileUsages \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId: string, options?: any): AxiosPromise<ResponseFileUsageDtoCollectionResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Sort all FileUsages in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {FileUsageSortDto} fileUsageSortDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId: string, fileUsageSortDto: FileUsageSortDto, options?: any): AxiosPromise<FileUsageFileSortDtoCollectionResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId, fileUsageSortDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Swap a File in FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {SwapFileDto} swapFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId: string, fileId: number, swapFileDto: SwapFileDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId, fileId, swapFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a FileUsage in \"Files\" for UnwantedIncident
         * @summary 
         * @param {string} unwantedIncidentId 
         * @param {number} fileId 
         * @param {UpdateFileUsageDto} updateFileUsageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: any): AxiosPromise<ResponseFileUsageDtoEntityResponse> {
            return localVarFp.fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId, fileId, updateFileUsageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an unwanted incident without being authenticated
         * @param {CreateUnwantedIncidentDto} createUnwantedIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nonAuthUnwantedIncidentControllerCreate(createUnwantedIncidentDto: CreateUnwantedIncidentDto, options?: any): AxiosPromise<ResponseUnwantedIncidentDtoEntityResponse> {
            return localVarFp.nonAuthUnwantedIncidentControllerCreate(createUnwantedIncidentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an unwanted incident
         * @param {CreateUnwantedIncidentDto} createUnwantedIncidentDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerCreate(createUnwantedIncidentDto: CreateUnwantedIncidentDto, options?: any): AxiosPromise<ResponseUnwantedIncidentDtoEntityResponse> {
            return localVarFp.unwantedIncidentControllerCreate(createUnwantedIncidentDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerDelete(unwantedIncidentId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.unwantedIncidentControllerDelete(unwantedIncidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read an unwanted incident
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerRead(unwantedIncidentId: string, options?: any): AxiosPromise<ResponseUnwantedIncidentDtoEntityResponse> {
            return localVarFp.unwantedIncidentControllerRead(unwantedIncidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all unwanted incidents on projects the user is UnwantedIncidentManager on
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {UnwantedIncidentPageOptionsOrderBy} [orderBy] 
         * @param {number} [projectId] Filter results on ProjectId
         * @param {number} [objectId] Filter results on ObjectId
         * @param {Array<'person-injury' | 'material-damage' | 'accident' | 'near-accident' | 'observation'>} [types] The types of unwanted incident you want to filer on. If provided will only show those types
         * @param {UnwantedIncidentType} [type] Use types instead
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: UnwantedIncidentPageOptionsOrderBy, projectId?: number, objectId?: number, types?: Array<'person-injury' | 'material-damage' | 'accident' | 'near-accident' | 'observation'>, type?: UnwantedIncidentType, search?: string, options?: any): AxiosPromise<ResponseUnwantedIncidentDtoPageResponse> {
            return localVarFp.unwantedIncidentControllerReadCollection(page, limit, order, orderBy, projectId, objectId, types, type, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} unwantedIncidentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentControllerUpdate(unwantedIncidentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unwantedIncidentControllerUpdate(unwantedIncidentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentFilterControllerReadObjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<FilterDtoPageResponse> {
            return localVarFp.unwantedIncidentFilterControllerReadObjectFilterCollection(page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentFilterControllerReadProjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<FilterDtoPageResponse> {
            return localVarFp.unwantedIncidentFilterControllerReadProjectFilterCollection(page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an unwanted incident process action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {CreateUnwantedIncidentProcessActionDto} createUnwantedIncidentProcessActionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerCreate(unwantedIncidentId: string, unwantedIncidentProcessId: string, createUnwantedIncidentProcessActionDto: CreateUnwantedIncidentProcessActionDto, options?: any): AxiosPromise<ResponseUnwantedIncidentProcessActionDtoEntityResponse> {
            return localVarFp.unwantedIncidentProcessActionControllerCreate(unwantedIncidentId, unwantedIncidentProcessId, createUnwantedIncidentProcessActionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an unwanted incident\'s process\' action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {string} unwantedIncidentProcessActionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerDelete(unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.unwantedIncidentProcessActionControllerDelete(unwantedIncidentId, unwantedIncidentProcessId, unwantedIncidentProcessActionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read an unwanted incident\'s process\' action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {string} unwantedIncidentProcessActionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerRead(unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, options?: any): AxiosPromise<ResponseUnwantedIncidentProcessActionDtoEntityResponse> {
            return localVarFp.unwantedIncidentProcessActionControllerRead(unwantedIncidentId, unwantedIncidentProcessId, unwantedIncidentProcessActionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read all unwanted incident\'s process\' actions
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerReadCollection(unwantedIncidentId: string, unwantedIncidentProcessId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponseUnwantedIncidentProcessActionDtoPageResponse> {
            return localVarFp.unwantedIncidentProcessActionControllerReadCollection(unwantedIncidentId, unwantedIncidentProcessId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an unwanted incident\'s process\' action
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {string} unwantedIncidentProcessActionId 
         * @param {UpdateUnwantedIncidentProcessActionDto} updateUnwantedIncidentProcessActionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessActionControllerUpdate(unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, updateUnwantedIncidentProcessActionDto: UpdateUnwantedIncidentProcessActionDto, options?: any): AxiosPromise<ResponseUnwantedIncidentProcessActionDtoEntityResponse> {
            return localVarFp.unwantedIncidentProcessActionControllerUpdate(unwantedIncidentId, unwantedIncidentProcessId, unwantedIncidentProcessActionId, updateUnwantedIncidentProcessActionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {CreateUnwantedIncidentProcessDto} createUnwantedIncidentProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerCreate(unwantedIncidentId: string, createUnwantedIncidentProcessDto: CreateUnwantedIncidentProcessDto, options?: any): AxiosPromise<ResponseUnwantedIncidentProcessDtoEntityResponse> {
            return localVarFp.unwantedIncidentProcessControllerCreate(unwantedIncidentId, createUnwantedIncidentProcessDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerDelete(unwantedIncidentId: string, unwantedIncidentProcessId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.unwantedIncidentProcessControllerDelete(unwantedIncidentId, unwantedIncidentProcessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerRead(unwantedIncidentId: string, unwantedIncidentProcessId: string, options?: any): AxiosPromise<ResponseUnwantedIncidentProcessDtoEntityResponse> {
            return localVarFp.unwantedIncidentProcessControllerRead(unwantedIncidentId, unwantedIncidentProcessId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read an unwanted incident\'s processes
         * @param {string} unwantedIncidentId 
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {PageOptionsDefaultOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerReadCollection(unwantedIncidentId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: any): AxiosPromise<ResponseUnwantedIncidentProcessDtoPageResponse> {
            return localVarFp.unwantedIncidentProcessControllerReadCollection(unwantedIncidentId, page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an unwanted incident\'s process
         * @param {string} unwantedIncidentId 
         * @param {string} unwantedIncidentProcessId 
         * @param {UpdateUnwantedIncidentProcessDto} updateUnwantedIncidentProcessDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProcessControllerUpdate(unwantedIncidentId: string, unwantedIncidentProcessId: string, updateUnwantedIncidentProcessDto: UpdateUnwantedIncidentProcessDto, options?: any): AxiosPromise<ResponseUnwantedIncidentProcessDtoEntityResponse> {
            return localVarFp.unwantedIncidentProcessControllerUpdate(unwantedIncidentId, unwantedIncidentProcessId, updateUnwantedIncidentProcessDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a collection of projects with UnwantedIncidents enabled
         * @param {number} page 
         * @param {number} limit 
         * @param {Order} [order] 
         * @param {UnwantedIncidentProjectPageOptionsOrderBy} [orderBy] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProjectControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: UnwantedIncidentProjectPageOptionsOrderBy, search?: string, options?: any): AxiosPromise<ResponseUnwantedIncidentProjectDtoPageResponse> {
            return localVarFp.unwantedIncidentProjectControllerReadCollection(page, limit, order, orderBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a collection of projects with UnwantedIncidents enabled
         * @param {number} lng 
         * @param {number} lat 
         * @param {number} [distance] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentProjectControllerReadNearbyCollection(lng: number, lat: number, distance?: number, options?: any): AxiosPromise<ResponseNearbyUnwantedIncidentProjectDtoCollectionResponse> {
            return localVarFp.unwantedIncidentProjectControllerReadNearbyCollection(lng, lat, distance, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unwantedIncidentReportingControllerReadProjectCollection(options?: any): AxiosPromise<ResponseUnwantedIncidentReportingProjectDtoCollectionResponse> {
            return localVarFp.unwantedIncidentReportingControllerReadProjectCollection(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UnwantedIncidentApi - object-oriented interface
 * @export
 * @class UnwantedIncidentApi
 * @extends {BaseAPI}
 */
export class UnwantedIncidentApi extends BaseAPI {
    /**
     * Count all FileUsages in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesCount(unwantedIncidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a file to \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {CreateFileUsageDto} createFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId: string, createFileUsageDto: CreateFileUsageDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesCreate(unwantedIncidentId, createFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a File in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {number} fileId 
     * @param {boolean} [deleteFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId: string, fileId: number, deleteFile?: boolean, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesDelete(unwantedIncidentId, fileId, deleteFile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all FileUsages \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesReadCollection(unwantedIncidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sort all FileUsages in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {FileUsageSortDto} fileUsageSortDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId: string, fileUsageSortDto: FileUsageSortDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesSort(unwantedIncidentId, fileUsageSortDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Swap a File in FileUsage in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {number} fileId 
     * @param {SwapFileDto} swapFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId: string, fileId: number, swapFileDto: SwapFileDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesSwap(unwantedIncidentId, fileId, swapFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a FileUsage in \"Files\" for UnwantedIncident
     * @summary 
     * @param {string} unwantedIncidentId 
     * @param {number} fileId 
     * @param {UpdateFileUsageDto} updateFileUsageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId: string, fileId: number, updateFileUsageDto: UpdateFileUsageDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).fileUsageControllerUnwantedIncidentFilesUpdate(unwantedIncidentId, fileId, updateFileUsageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an unwanted incident without being authenticated
     * @param {CreateUnwantedIncidentDto} createUnwantedIncidentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public nonAuthUnwantedIncidentControllerCreate(createUnwantedIncidentDto: CreateUnwantedIncidentDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).nonAuthUnwantedIncidentControllerCreate(createUnwantedIncidentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an unwanted incident
     * @param {CreateUnwantedIncidentDto} createUnwantedIncidentDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentControllerCreate(createUnwantedIncidentDto: CreateUnwantedIncidentDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentControllerCreate(createUnwantedIncidentDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} unwantedIncidentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentControllerDelete(unwantedIncidentId: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentControllerDelete(unwantedIncidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read an unwanted incident
     * @param {string} unwantedIncidentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentControllerRead(unwantedIncidentId: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentControllerRead(unwantedIncidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all unwanted incidents on projects the user is UnwantedIncidentManager on
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {UnwantedIncidentPageOptionsOrderBy} [orderBy] 
     * @param {number} [projectId] Filter results on ProjectId
     * @param {number} [objectId] Filter results on ObjectId
     * @param {Array<'person-injury' | 'material-damage' | 'accident' | 'near-accident' | 'observation'>} [types] The types of unwanted incident you want to filer on. If provided will only show those types
     * @param {UnwantedIncidentType} [type] Use types instead
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: UnwantedIncidentPageOptionsOrderBy, projectId?: number, objectId?: number, types?: Array<'person-injury' | 'material-damage' | 'accident' | 'near-accident' | 'observation'>, type?: UnwantedIncidentType, search?: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentControllerReadCollection(page, limit, order, orderBy, projectId, objectId, types, type, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} unwantedIncidentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentControllerUpdate(unwantedIncidentId: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentControllerUpdate(unwantedIncidentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentFilterControllerReadObjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentFilterControllerReadObjectFilterCollection(page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentFilterControllerReadProjectFilterCollection(page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentFilterControllerReadProjectFilterCollection(page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an unwanted incident process action
     * @param {string} unwantedIncidentId 
     * @param {string} unwantedIncidentProcessId 
     * @param {CreateUnwantedIncidentProcessActionDto} createUnwantedIncidentProcessActionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessActionControllerCreate(unwantedIncidentId: string, unwantedIncidentProcessId: string, createUnwantedIncidentProcessActionDto: CreateUnwantedIncidentProcessActionDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessActionControllerCreate(unwantedIncidentId, unwantedIncidentProcessId, createUnwantedIncidentProcessActionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an unwanted incident\'s process\' action
     * @param {string} unwantedIncidentId 
     * @param {string} unwantedIncidentProcessId 
     * @param {string} unwantedIncidentProcessActionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessActionControllerDelete(unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessActionControllerDelete(unwantedIncidentId, unwantedIncidentProcessId, unwantedIncidentProcessActionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read an unwanted incident\'s process\' action
     * @param {string} unwantedIncidentId 
     * @param {string} unwantedIncidentProcessId 
     * @param {string} unwantedIncidentProcessActionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessActionControllerRead(unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessActionControllerRead(unwantedIncidentId, unwantedIncidentProcessId, unwantedIncidentProcessActionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read all unwanted incident\'s process\' actions
     * @param {string} unwantedIncidentId 
     * @param {string} unwantedIncidentProcessId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessActionControllerReadCollection(unwantedIncidentId: string, unwantedIncidentProcessId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessActionControllerReadCollection(unwantedIncidentId, unwantedIncidentProcessId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an unwanted incident\'s process\' action
     * @param {string} unwantedIncidentId 
     * @param {string} unwantedIncidentProcessId 
     * @param {string} unwantedIncidentProcessActionId 
     * @param {UpdateUnwantedIncidentProcessActionDto} updateUnwantedIncidentProcessActionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessActionControllerUpdate(unwantedIncidentId: string, unwantedIncidentProcessId: string, unwantedIncidentProcessActionId: string, updateUnwantedIncidentProcessActionDto: UpdateUnwantedIncidentProcessActionDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessActionControllerUpdate(unwantedIncidentId, unwantedIncidentProcessId, unwantedIncidentProcessActionId, updateUnwantedIncidentProcessActionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an unwanted incident\'s process
     * @param {string} unwantedIncidentId 
     * @param {CreateUnwantedIncidentProcessDto} createUnwantedIncidentProcessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessControllerCreate(unwantedIncidentId: string, createUnwantedIncidentProcessDto: CreateUnwantedIncidentProcessDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessControllerCreate(unwantedIncidentId, createUnwantedIncidentProcessDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete an unwanted incident\'s process
     * @param {string} unwantedIncidentId 
     * @param {string} unwantedIncidentProcessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessControllerDelete(unwantedIncidentId: string, unwantedIncidentProcessId: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessControllerDelete(unwantedIncidentId, unwantedIncidentProcessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read an unwanted incident\'s process
     * @param {string} unwantedIncidentId 
     * @param {string} unwantedIncidentProcessId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessControllerRead(unwantedIncidentId: string, unwantedIncidentProcessId: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessControllerRead(unwantedIncidentId, unwantedIncidentProcessId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read an unwanted incident\'s processes
     * @param {string} unwantedIncidentId 
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {PageOptionsDefaultOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessControllerReadCollection(unwantedIncidentId: string, page: number, limit: number, order?: Order, orderBy?: PageOptionsDefaultOrderBy, search?: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessControllerReadCollection(unwantedIncidentId, page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an unwanted incident\'s process
     * @param {string} unwantedIncidentId 
     * @param {string} unwantedIncidentProcessId 
     * @param {UpdateUnwantedIncidentProcessDto} updateUnwantedIncidentProcessDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProcessControllerUpdate(unwantedIncidentId: string, unwantedIncidentProcessId: string, updateUnwantedIncidentProcessDto: UpdateUnwantedIncidentProcessDto, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProcessControllerUpdate(unwantedIncidentId, unwantedIncidentProcessId, updateUnwantedIncidentProcessDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a collection of projects with UnwantedIncidents enabled
     * @param {number} page 
     * @param {number} limit 
     * @param {Order} [order] 
     * @param {UnwantedIncidentProjectPageOptionsOrderBy} [orderBy] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProjectControllerReadCollection(page: number, limit: number, order?: Order, orderBy?: UnwantedIncidentProjectPageOptionsOrderBy, search?: string, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProjectControllerReadCollection(page, limit, order, orderBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a collection of projects with UnwantedIncidents enabled
     * @param {number} lng 
     * @param {number} lat 
     * @param {number} [distance] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentProjectControllerReadNearbyCollection(lng: number, lat: number, distance?: number, options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentProjectControllerReadNearbyCollection(lng, lat, distance, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UnwantedIncidentApi
     */
    public unwantedIncidentReportingControllerReadProjectCollection(options?: AxiosRequestConfig) {
        return UnwantedIncidentApiFp(this.configuration).unwantedIncidentReportingControllerReadProjectCollection(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an Address for User
         * @summary 
         * @param {string} userId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUserCreate: async (userId: string, createAddressDto: CreateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addressControllerUserCreate', 'userId', userId)
            // verify required parameter 'createAddressDto' is not null or undefined
            assertParamExists('addressControllerUserCreate', 'createAddressDto', createAddressDto)
            const localVarPath = `/user/{userId}/addresses`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an Address for User
         * @summary 
         * @param {string} userId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUserDelete: async (userId: string, addressId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addressControllerUserDelete', 'userId', userId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerUserDelete', 'addressId', addressId)
            const localVarPath = `/user/{userId}/addresses/{addressId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Read all Addresses for User
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUserReadCollection: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addressControllerUserReadCollection', 'userId', userId)
            const localVarPath = `/user/{userId}/addresses`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an Address for User
         * @summary 
         * @param {string} userId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUserUpdate: async (userId: string, addressId: string, updateAddressDto: UpdateAddressDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addressControllerUserUpdate', 'userId', userId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('addressControllerUserUpdate', 'addressId', addressId)
            // verify required parameter 'updateAddressDto' is not null or undefined
            assertParamExists('addressControllerUserUpdate', 'updateAddressDto', updateAddressDto)
            const localVarPath = `/user/{userId}/addresses/{addressId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"addressId"}}`, encodeURIComponent(String(addressId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAddressDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets a users start page
         * @param {number} userId 
         * @param {CreateUserStartPageDto} createUserStartPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateStartPage: async (userId: number, createUserStartPageDto: CreateUserStartPageDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerCreateStartPage', 'userId', userId)
            // verify required parameter 'createUserStartPageDto' is not null or undefined
            assertParamExists('userControllerCreateStartPage', 'createUserStartPageDto', createUserStartPageDto)
            const localVarPath = `/users/{userId}/startpage`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserStartPageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a users start page
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteStartPage: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerDeleteStartPage', 'userId', userId)
            const localVarPath = `/users/{userId}/startpage`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of users that you can be
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAssumeUsers: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerGetAssumeUsers', 'userId', userId)
            const localVarPath = `/users/{userId}/assume`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRead: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerRead', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user
         * @param {number} userId 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate: async (userId: number, updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('userControllerUpdate', 'userId', userId)
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('userControllerUpdate', 'updateUserDto', updateUserDto)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Read a User profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileControllerRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication CompanyToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an Address for User
         * @summary 
         * @param {string} userId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerUserCreate(userId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerUserCreate(userId, createAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an Address for User
         * @summary 
         * @param {string} userId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerUserDelete(userId: string, addressId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerUserDelete(userId, addressId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Read all Addresses for User
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerUserReadCollection(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerUserReadCollection(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an Address for User
         * @summary 
         * @param {string} userId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressControllerUserUpdate(userId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAddressDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressControllerUserUpdate(userId, addressId, updateAddressDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets a users start page
         * @param {number} userId 
         * @param {CreateUserStartPageDto} createUserStartPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerCreateStartPage(userId: number, createUserStartPageDto: CreateUserStartPageDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerCreateStartPage(userId, createUserStartPageDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a users start page
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerDeleteStartPage(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerDeleteStartPage(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of users that you can be
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerGetAssumeUsers(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseAssumeUserDtoCollectionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerGetAssumeUsers(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerRead(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerRead(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user
         * @param {number} userId 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userControllerUpdate(userId: number, updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userControllerUpdate(userId, updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Read a User profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userProfileControllerRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUserProfileDtoEntityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userProfileControllerRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Create an Address for User
         * @summary 
         * @param {string} userId 
         * @param {CreateAddressDto} createAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUserCreate(userId: string, createAddressDto: CreateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerUserCreate(userId, createAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an Address for User
         * @summary 
         * @param {string} userId 
         * @param {string} addressId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUserDelete(userId: string, addressId: string, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.addressControllerUserDelete(userId, addressId, options).then((request) => request(axios, basePath));
        },
        /**
         * Read all Addresses for User
         * @summary 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUserReadCollection(userId: string, options?: any): AxiosPromise<ResponseAddressDtoCollectionResponse> {
            return localVarFp.addressControllerUserReadCollection(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an Address for User
         * @summary 
         * @param {string} userId 
         * @param {string} addressId 
         * @param {UpdateAddressDto} updateAddressDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressControllerUserUpdate(userId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: any): AxiosPromise<ResponseAddressDtoEntityResponse> {
            return localVarFp.addressControllerUserUpdate(userId, addressId, updateAddressDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets a users start page
         * @param {number} userId 
         * @param {CreateUserStartPageDto} createUserStartPageDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerCreateStartPage(userId: number, createUserStartPageDto: CreateUserStartPageDto, options?: any): AxiosPromise<void> {
            return localVarFp.userControllerCreateStartPage(userId, createUserStartPageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a users start page
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerDeleteStartPage(userId: number, options?: any): AxiosPromise<DeleteResponseDto> {
            return localVarFp.userControllerDeleteStartPage(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of users that you can be
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerGetAssumeUsers(userId: number, options?: any): AxiosPromise<ResponseAssumeUserDtoCollectionResponse> {
            return localVarFp.userControllerGetAssumeUsers(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read user
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerRead(userId: number, options?: any): AxiosPromise<ResponseUserDtoEntityResponse> {
            return localVarFp.userControllerRead(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user
         * @param {number} userId 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userControllerUpdate(userId: number, updateUserDto: UpdateUserDto, options?: any): AxiosPromise<ResponseUserDtoEntityResponse> {
            return localVarFp.userControllerUpdate(userId, updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Read a User profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userProfileControllerRead(options?: any): AxiosPromise<ResponseUserProfileDtoEntityResponse> {
            return localVarFp.userProfileControllerRead(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Create an Address for User
     * @summary 
     * @param {string} userId 
     * @param {CreateAddressDto} createAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addressControllerUserCreate(userId: string, createAddressDto: CreateAddressDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addressControllerUserCreate(userId, createAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an Address for User
     * @summary 
     * @param {string} userId 
     * @param {string} addressId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addressControllerUserDelete(userId: string, addressId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addressControllerUserDelete(userId, addressId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Read all Addresses for User
     * @summary 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addressControllerUserReadCollection(userId: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addressControllerUserReadCollection(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an Address for User
     * @summary 
     * @param {string} userId 
     * @param {string} addressId 
     * @param {UpdateAddressDto} updateAddressDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addressControllerUserUpdate(userId: string, addressId: string, updateAddressDto: UpdateAddressDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addressControllerUserUpdate(userId, addressId, updateAddressDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets a users start page
     * @param {number} userId 
     * @param {CreateUserStartPageDto} createUserStartPageDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerCreateStartPage(userId: number, createUserStartPageDto: CreateUserStartPageDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerCreateStartPage(userId, createUserStartPageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a users start page
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerDeleteStartPage(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerDeleteStartPage(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of users that you can be
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerGetAssumeUsers(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerGetAssumeUsers(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read user
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerRead(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerRead(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user
     * @param {number} userId 
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userControllerUpdate(userId: number, updateUserDto: UpdateUserDto, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userControllerUpdate(userId, updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Read a User profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userProfileControllerRead(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userProfileControllerRead(options).then((request) => request(this.axios, this.basePath));
    }
}


