import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddressComponent } from '../../../components/adresses/address.component';
import { AvatarModule } from '../../../components/avatar/avatar.module';
import { AvatarsModule } from '../../../components/avatar/avatars.module';
import { BoxModule } from '../../../components/box/box.module';
import { PageInfoModule } from '../../../components/page-info/page-info.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { PostalDirectiveModule } from '../../../directives/postal/postal.directive.module';
import { ValidateEqualDirectiveModule } from '../../../directives/validate-equal/validate-equal.module';
import { ValidatePasswordDirectiveModule } from '../../../directives/validate-password/validate-password.module';
import { ProfileFormComponent } from './profile-form.component';
import { ProfileRoutingModule } from './profile-routing.module';
import ProfileService from './profile.service';

@NgModule({
  imports: [
    ProfileRoutingModule,

    CommonModule,
    FormsModule,

    TranslateModule,
    AvatarModule,
    AvatarsModule,
    PageInfoModule,
    PostalDirectiveModule,
    ValidatePasswordDirectiveModule,
    ValidateEqualDirectiveModule,

    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    BoxModule,
    AddressComponent,
  ],
  declarations: [ProfileFormComponent],
  exports: [ProfileFormComponent],
  providers: [ProfileService],
})
export class ProfileModule {}
