import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export default class GoogleMapPlacesService {
  private readonly placesApi = new google.maps.places.PlacesService(document.createElement('div'));

  /**
   * Gets a formatted address from the Google Maps places api
   * @param placeId the place id of the address to get
   * @param language the language to get the address in
   *
   * See https://developers.google.com/maps/faq#languagesupport for a list of supported languages
   */
  getFormattedAddress(placeId: string, language: string): Observable<string> {
    const subject$ = new Subject<string>();

    this.placesApi.getDetails(
      {
        placeId,
        fields: ['formatted_address'],
        language,
      } as google.maps.places.PlaceDetailsRequest,
      (result) => {
        subject$.next(result?.formatted_address ?? null);
      },
    );

    return subject$.pipe(take(1));
  }
}
