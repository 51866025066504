import { Component, Input } from '@angular/core';
import { ResponsePortalCaseDto, ResponsePortalCaseLogDto } from '../../../../../../../generated/apex-rest';

@Component({
  selector: 'portal-portal-case-view',
  templateUrl: './portal-case-view.component.html',
})
export class PortalCaseViewComponent {
  @Input() projectId: number;
  @Input() apartmentId: number;

  @Input() viewCase: ResponsePortalCaseDto;
  @Input() caseLogPageResponse: Array<ResponsePortalCaseLogDto>;
}
