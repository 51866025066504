<div>
  <div
    *ngFor="let addonCategory of children"
    class="{{ 'level' + level }}">
    <apex-client-addon-apartment-tree-node
      [addonCategory]="addonCategory"
      [level]="nextLevel"
      [list]="list"
      [cart]="cart"
      (addonClicked)="addonClicked.emit($event)"
      (add)="add.emit($event)"
      (remove)="remove.emit($event)">
    </apex-client-addon-apartment-tree-node>
  </div>
</div>
