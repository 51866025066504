import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import HelpIconComponent from '../../../../apex/src/app/components/help-icon/help-icon.component';
import { TranslateModule } from '../../../../apex/src/app/components/translate/translate.module';
import { Profile } from '../../../../apex/src/app/models/profile';
import { UserService } from '../../../../apex/src/app/services/user/user.service';

@Component({
  selector: 'portal-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, MatButtonModule, HelpIconComponent],
})
export class TopbarComponent {
  @Input() drawer: MatDrawer;

  profile$: Observable<Profile>;

  constructor(private userService: UserService) {
    this.profile$ = this.userService.profile$;
  }

  toggle(): void {
    void this.drawer?.toggle();
  }
}
