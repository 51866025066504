<section class="display-grid-cards am">
  <ng-container *ngIf="entities?.length; else noEntities">
    <ng-container *ngFor="let entity of entities">
      <mat-card
        appearance="raised"
        class="df c">
        <ng-container *ngIf="entity.bannerImages">
          <div
            mat-card-image
            class="aspect-ratio-4-1 df jc">
            <apex-file-preview
              [showPlaceholder]="false"
              [showName]="false"
              [sizeContainer]="false"
              [size]="{ width: 800, height: 200 }"
              [fit]="'cover'"
              [file]="entity?.bannerImages[0]?.File"></apex-file-preview>
          </div>
        </ng-container>

        <mat-card-header>
          <mat-card-title>
            {{ entity.name }}
          </mat-card-title>
        </mat-card-header>

        <mat-card-content class="f1">
          <ng-container *ngIf="entity.Project">
            <p class="amb">
              {{ entity.Project.name }}
            </p>
          </ng-container>

          <portal-main-object-address [mainObject]="entity"></portal-main-object-address>

          <ng-container *ngIf="entity.seller">
            <apex-title-item class="apb">
              <T
                *ngIf="entity.type === 'tenancy'"
                title
                str="Landlord">
              </T>

              <div class="df ac">
                <div>
                  <T
                    *ngIf="entity.type !== 'tenancy'"
                    title
                    str="Bought from">
                  </T>
                </div>

                <div class="amlxs">
                  {{ entity.seller.name }}
                </div>
              </div>
            </apex-title-item>
          </ng-container>
        </mat-card-content>

        <mat-card-actions class="jsb">
          <div class="df asfe amls">
            <apex-logo [logoUrl]="entity.seller.logos[0]?.File?.signed?.url"></apex-logo>
          </div>

          <ng-container *ngIf="entity.type === 'tenancy'">
            <a
              mat-raised-button
              color="primary"
              [routerLink]="['tenancy', entity.id]">
              <T str="Go to tenancy"></T>
            </a>
          </ng-container>
          <ng-container *ngIf="entity.type === 'project-apartment'">
            <a
              mat-raised-button
              color="primary"
              [routerLink]="['project', entity.Project.id, 'apartment', entity.id]">
              <T str="Go to unit"></T>
            </a>
          </ng-container>
          <ng-container *ngIf="entity.type === 'ownership'">
            <a
              mat-raised-button
              color="primary"
              [routerLink]="['ownership', entity.id]">
              <T str="Go to ownership"></T>
            </a>
          </ng-container>
        </mat-card-actions>
      </mat-card>
    </ng-container>
  </ng-container>

  <ng-template #noEntities>
    <div class="df ac jc ap am">
      <T str="Nothing to show"></T>
    </div>
  </ng-template>
</section>

<section
  class="am"
  *ngIf="products.length">
  <apex-products [products]="products"></apex-products>
</section>
