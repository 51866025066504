import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { orderBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileViewerDialogComponent } from '../../../components/file-viewer/dialog.component';
import { t } from '../../../components/translate/translate.function';
import { Apartment } from '../../../models/apartment';
import { File as ApexFile } from '../../../models/file';
import { FileUsage } from '../../../models/file-usage';
import { Project } from '../../../models/project';
import { snackErr } from '../../../modules/snack.module';

type ApartmentDocumentResponseDto = {
  Project?: Partial<Project>;
  Apartment?: Partial<Apartment>;
  FileUsage?: FileUsage;
};

type ApartmentDocumentViewData = {
  projectName: string;
  apartmentName: string;
  type: string;
  description: string;
  fileName: string;
  createdAt: Date;

  Project?: Partial<Project>;
  Apartment?: Partial<Apartment>;
  FileUsage?: FileUsage;
};

const fileUsageTypeNames: Record<string, () => string> = {
  'inspection-pdf': () => t('Inspection'),
  floorplans: () => t('Floorplan'),
  'public-files': () => t('File'),
  'certificate-of-finance': () => t('Financial certificate'),
  takeovers: () => t('Takeover'),
  contracts: () => t('Contract'),
  'temporary-use-permits': () => t('Temporary use permit'),
  'certificates-of-completion': () => t('Certification of completion'),
  'amendment-agreements': () => t('Amendment agreement'),
};

@Component({
  selector: 'apex-client-page-documents',
  templateUrl: './client-page-documents.component.html',
})
export class ClientPageDocumentsComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatSort) sort: MatSort;

  documents: ApartmentDocumentViewData[] = [];

  documentsDataSource = new MatTableDataSource<ApartmentDocumentViewData>();
  displayedColumns: string[] = [
    'projectName',
    'apartmentName',
    'type',
    'description',
    'openFileButton',
    'fileName',
    'createdAt',
  ];

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.subscription.add(
      this.route.data.pipe(map((data) => data.documents as ApartmentDocumentResponseDto[])).subscribe({
        next: (documents) => {
          this.documents = documents?.map((d) => ({
            ...d,
            projectName: d.Project?.name ?? '',
            apartmentName: d.Apartment?.name ?? '',
            type:
              d.FileUsage?.self === 'inspection'
                ? fileUsageTypeNames[`inspection-${d.FileUsage?.name}`]()
                : fileUsageTypeNames[d.FileUsage?.name](),
            description: d.FileUsage?.File?.description ?? '',
            fileName: d.FileUsage?.File?.name ?? '',
            createdAt: d.FileUsage?.createdAt ?? null,
          }));

          this.documentsDataSource.data = orderBy(this.documents, ['projectName', 'apartmentName', 'type']);
        },
        error: (err) => snackErr(t('Failed to get documents'), err),
      }),
    );
  }

  ngAfterViewInit(): void {
    this.documentsDataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  openFile(file: ApexFile): void {
    this.dialog.open(FileViewerDialogComponent, {
      data: {
        file,
        showFolderAction: false,
      },
      autoFocus: false,
    });
  }
}
