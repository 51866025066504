<ng-container *ngIf="profile$ | async as profile">
  <ng-container *ngIf="tenancy$ | async as tenancy">
    <ng-container *ngIf="objects$ | async as objects">
      <apex-page-info
        [returnDestination]="['/']"
        [title]="tenancy?.name"
        icon="home_work"
        fontSet="material-icons-sharp">
        <a [routerLink]="['/profile']">
          <apex-avatar
            size="small"
            [user]="profile"></apex-avatar>
        </a>
      </apex-page-info>

      <nav
        mat-tab-nav-bar
        [tabPanel]="outlet">
        <a
          mat-tab-link
          *ngFor="let link of links"
          (click)="activeLink = link.path"
          [active]="activeLink === link.path"
          [routerLink]="['/', 'tenancy', tenancy.id, link.path]">
          {{ link.name }}
        </a>
      </nav>

      <mat-tab-nav-panel #outlet>
        <router-outlet></router-outlet>
      </mat-tab-nav-panel>
    </ng-container>
  </ng-container>
</ng-container>
