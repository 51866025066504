import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '../../../components/translate/translate.module';

import { ClientPageInfoViewDialogComponent } from './client-page-info-view.component';

@NgModule({
  imports: [CommonModule, TranslateModule, MatDialogModule, MatButtonModule, MatIconModule],
  exports: [ClientPageInfoViewDialogComponent],
  declarations: [ClientPageInfoViewDialogComponent],
  providers: [],
})
export class ClientPageInfoViewDialogModule {}
