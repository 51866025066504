<ng-container *ngIf="addonCategories && parentAddonCategories">
  <div
    *ngIf="projectText?.content"
    class="project-text"
    [innerHTML]="projectText.content"></div>
  <div class="df c">
    <apex-client-addon-apartment-tree
      (addonClicked)="scrollToAddon($event)"
      (addonClicked)="($event)"
      [level]="0"
      [children]="parentAddonCategories"
      [list]="addonCategories">
    </apex-client-addon-apartment-tree>
  </div>
  <mat-divider></mat-divider>
  <div
    [id]="'aa-' + aa.id"
    *ngFor="let aa of sorted(addonApartments)"
    class="mat-elevation-z2 amv">
    <apex-client-addon-apartment-info
      [addonApartment]="aa"
      [editable]="false">
    </apex-client-addon-apartment-info>
  </div>
</ng-container>
