import { CdkConnectedOverlay, CdkOverlayOrigin, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, Injector, Input, OnDestroy } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription, filter, fromEvent, merge } from 'rxjs';
import { InViewDirectiveModule } from '../../directives/in-view/in-view.directive.module';
import { CategoryOrAddon } from '../../features/client-page/addon/pages/addons/addons.types';
import { AddonOverlayListComponent } from '../addon-overlay-list/addon-overlay-list.component';
import { FilePreviewModule } from '../file-preview/file-preview.module';
import { TranslateModule } from '../translate/translate.module';
import { categoriesAndAddons, filteredCategoriesAndAddons, inputWidth } from './addon-search-button.model';

@Component({
  selector: 'apex-addon-search-button',
  templateUrl: './addon-search-button.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    InViewDirectiveModule,
    FilePreviewModule,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    TranslateModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
  ],
})
export class AddonSearchButtonComponent implements OnDestroy {
  @Input() CategoriesAndAddons: CategoryOrAddon[] = [];
  @Input() filteredCategoriesAndAddons: CategoryOrAddon[] = [];

  constructor(
    private overlay: Overlay,
    private injector: Injector,
    private elementRef: ElementRef,
  ) {}

  private overlayRef: OverlayRef;

  subscription$$ = new Subscription();

  @HostListener('click', ['$event']) hostClick($event: Event): void {
    $event.stopPropagation();
  }

  clickSearch(): void {
    if (this.overlayRef) {
      return;
    }

    const addonBreadcrumb = this.elementRef.nativeElement?.parentElement?.parentElement?.parentElement?.parentElement;
    const addonSearchButton = this.elementRef.nativeElement;

    let overlayWidth = 350;

    if (addonBreadcrumb) {
      const addonBreadCrumbClientRect = addonBreadcrumb.getClientRects()[0];
      const addonSearchButtonClientRect = addonSearchButton.getClientRects()[0];

      if (!!addonBreadCrumbClientRect && !!addonSearchButtonClientRect) {
        const left = addonBreadCrumbClientRect.left;
        const right = addonSearchButtonClientRect.right;

        overlayWidth = right - left;
      }
    }

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'top',
          offsetX: 0,
          offsetY: -5,
        },
      ]);

    const config = new OverlayConfig({
      hasBackdrop: false,
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      disposeOnNavigation: true,
    });

    this.overlayRef = this.overlay.create(config);

    const injector = Injector.create({
      parent: this.injector,
      providers: [
        {
          provide: categoriesAndAddons,
          useValue: this.CategoriesAndAddons,
        },
        {
          provide: filteredCategoriesAndAddons,
          useValue: this.filteredCategoriesAndAddons,
        },
        {
          provide: OverlayRef,
          useValue: this.overlayRef,
        },
        {
          provide: inputWidth,
          useValue: overlayWidth,
        },
      ],
    });

    const componentPortal = new ComponentPortal(AddonOverlayListComponent, null, injector);

    this.subscription$$.add(
      merge(
        this.overlayRef.attach(componentPortal).instance.closed,
        fromEvent(window, 'click'),
        fromEvent<KeyboardEvent>(window, 'keyup').pipe(filter((e) => e.key === 'Escape' || e.key === 'Esc')),
      ).subscribe(() => {
        this.close();
      }),
    );
  }

  close(): void {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  ngOnDestroy(): void {
    this.subscription$$.unsubscribe();
  }
}
