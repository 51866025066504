<apex-client-addon-breadcrumb [inOrder]="true"> </apex-client-addon-breadcrumb>

<apex-client-addon-order-list [projects]="projects"> </apex-client-addon-order-list>

<div class="amv">
  <button
    mat-raised-button
    (click)="back()">
    <T str="Back"></T>
  </button>
</div>
