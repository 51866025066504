import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject, Subscription, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ResponseFileUsageDto, ResponsePortalFileUsageDto } from '../../../../../../generated/apex-rest';
import { FileUsage } from '../../models/file-usage';

@Component({
  selector: 'apex-file-usage-carousel',
  templateUrl: './file-usage-carousel.component.html',
})
export class FileUsageCarouselComponent implements OnInit, OnDestroy, OnChanges {
  /**
   * A filtered list of these will be used, based on mimetype starts with image/.
   */
  @Input() fileUsages: Array<FileUsage | ResponseFileUsageDto | ResponsePortalFileUsageDto> = [];
  /**
   * delay in ms for the interval between slides
   */
  @Input() delay = 5000;
  /**
   * Will only slide when true, can still use navigation.
   */
  @Input() slideState = true;

  @Input() width = 1280;
  @Input() height = 720;

  currentSlide = 0;

  startNumber = 0;

  private interval$ = new Subject<boolean>();
  private interval$$ = this.interval$.pipe(switchMap(() => interval(this.delay)));

  subscriptions = new Subscription();

  onPreviousClick(): void {
    const previous = this.currentSlide - 1;
    const next = this.currentSlide + 1;

    this.currentSlide = previous < 0 ? this.fileUsages.length - 1 : previous;

    if (this.fileUsages) {
      this.currentSlide = previous < 0 ? this.fileUsages.length - 1 : previous;
    } else {
      this.currentSlide = next < 0 ? this.fileUsages.length + 1 : next;
    }

    if (this.slideState === true) {
      this.interval$.next(true);
    }
  }

  onNextClick(): void {
    const next = this.currentSlide + 1;

    this.currentSlide = next === this.fileUsages.length ? 0 : next;

    if (this.fileUsages) {
      this.currentSlide = next === this.fileUsages.length ? 0 : next;
    } else {
      this.currentSlide = next < 0 ? this.fileUsages.length - 1 : next;
    }

    if (this.slideState === true) {
      this.interval$.next(true);
    }
  }

  slide(): void {
    this.onNextClick();
  }

  ngOnInit(): void {
    if (this.slideState) {
      this.subscriptions.add(this.interval$$.subscribe(() => this.slide()));

      this.interval$.next(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fileUsages) {
      this.fileUsages = this.fileUsages.filter((f) => f.File?.type?.startsWith('image/'));
    }

    if (this.slideState === false) {
      this.startNumber = 1;
      this.subscriptions.unsubscribe();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
