<header mat-dialog-title>
  <div class="df jsb ac f1">
    <span> #{{ viewCase?.id }} </span>
  </div>
</header>

<main mat-dialog-content>
  <portal-portal-case-view
    [projectId]="projectId"
    [apartmentId]="apartmentId"
    [viewCase]="viewCase"
    [caseLogPageResponse]="responseCaseLogs">
  </portal-portal-case-view>
</main>

<footer mat-dialog-actions>
  <div class="df jfe ac f1">
    <button
      mat-raised-button
      cdkfocusinitial
      color="primary"
      (click)="closeDialog()">
      <T str="Close"></T>
    </button>
  </div>
</footer>
