import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { AddonCart, Product } from 'projects/apex/src/app/models/addon-cart';
import { AddonCategory } from 'projects/apex/src/app/models/addon-category';
import { AddonOrder, AddonOrderStatus } from 'projects/apex/src/app/models/addon-order';
import { scrollWrap } from 'projects/apex/src/app/utils/functions';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Addon } from '../../../../../models/addon';

@Component({
  selector: 'apex-client-addon-info-page',
  templateUrl: './info.component.html',
})
export class ClientAddonInfoPageComponent implements OnInit, OnDestroy {
  addonApartment: AddonApartment = new AddonApartment();
  addonCart: AddonCart;
  addonOrders: AddonOrder[];
  addonCategory: AddonCategory;
  product: Product;
  editable = true;

  otherAddons: Addon[] = [];

  inPortal = false;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientPageService,
    private location: Location,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.data.subscribe((data) => {
        scrollWrap(0, 0);

        this.addonApartment = data.addonApartment;
        this.addonCart = data.cart;
        this.addonOrders = data.addonOrders;
        this.addonCategory = data.addonCategory;

        if (this.addonCategory && this.addonCategory.Addons && this.addonCategory.Addons.length) {
          this.addonCategory.Addons = this.addonCategory.Addons.filter((a) => a.id !== this.addonApartment.AddonId);
        }

        this.checkIfInCart();
        this.checkIfInOrders();

        this.otherAddons = (this.addonCategory?.Addons || []).filter((a) => {
          const addonApartment = a?.AddonApartments?.find((aa) => aa.ApartmentId === this.addonApartment.ApartmentId);

          if (!addonApartment) {
            return false;
          }

          const startDate = moment(addonApartment.dateStart);
          const endDate = moment(addonApartment.dateEnd);

          return moment().isBetween(startDate, endDate, 'day', '[]');
        });
      }),
    );

    const data = this.route.snapshot.data;

    this.inPortal = data.portal;
  }

  removeProduct(): void {
    this.subscription.add(
      this.clientService
        .removeProductInCart(this.addonApartment.ApartmentId, this.addonApartment.AddonId)
        .pipe(take(1))
        .subscribe(() => {
          void this.router.navigate(['../'], {
            relativeTo: this.route,
            fragment: this.addonCategory?.name,
          });
        }),
    );
  }

  addProduct(data: { addonApartment: AddonApartment; comment: string }): void {
    this.subscription.add(
      this.clientService
        .addProductInCart(
          data.addonApartment.ApartmentId,
          data.addonApartment.AddonId,
          data.comment ? data.comment : '',
        )
        .pipe(take(1))
        .subscribe((addonCart: AddonCart) => {
          if (this.addonCart?.Product?.find((p) => p.AddonApartmentId === data.addonApartment.id)) {
            this.addonCart = addonCart;
            this.checkIfInCart();
          } else {
            void this.router.navigate(['../'], {
              relativeTo: this.route,
              fragment: this.addonCategory?.name,
            });
          }
        }),
    );
  }

  checkIfInCart(): void {
    this.product = this.addonCart?.Product?.find((product) => product.AddonApartmentId === this.addonApartment.id);
  }

  checkIfInOrders(): void {
    if (this.addonOrders) {
      this.editable = !this.addonOrders.find(
        (ao) =>
          (ao.status === AddonOrderStatus.New || ao.status === AddonOrderStatus.Approved) &&
          ao.ApartmentId === this.addonApartment.ApartmentId &&
          ao.Addon.AddonCategoryId === this.addonApartment.Addon.AddonCategoryId,
      );
    }
  }

  goToAddon(addonApartment: AddonApartment): void {
    if (addonApartment?.id) {
      if (this.inPortal) {
        const paramMap = this.route.snapshot.paramMap;
        const projectId = paramMap.get('ProjectId');
        const apartmentId = paramMap.get('ApartmentId');

        void this.router.navigate(['/project', projectId, 'apartment', apartmentId, 'addon', addonApartment.id]);
      } else {
        void this.router.navigate(['minside', 'tilvalg', addonApartment.ApartmentId, addonApartment.id]);
      }
    }
  }

  back(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
