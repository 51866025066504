import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '../../components/page-not-found/page-not-found.component';
import { AgreementResolver } from '../object/project/agreement/agreement.resolver';
import { TenancyResolver } from '../object/project/tenancy/tenancy.resolver';
import { PortalAgreementComponent } from './agreement/view.component';
import { PortalComponent } from './portal.component';
import { PortalProductComponent } from './product/info.component';
import { PortalProductResolver, PortalProductsResolver } from './product/product.resolver';
import { PortalTenancyComponent } from './tenancy/info.component';

export const routes: Routes = [
  {
    path: '',
    component: PortalComponent,
    resolve: {
      products: PortalProductsResolver,
    },
  },
  {
    path: 'tenancy/:tid',
    component: PortalTenancyComponent,
    resolve: {
      tenancy: TenancyResolver,
    },
  },
  {
    path: 'agreement/:aid',
    component: PortalAgreementComponent,
    resolve: {
      agreement: AgreementResolver,
    },
  },
  {
    path: 'product/:id',
    component: PortalProductComponent,
    resolve: {
      product: PortalProductResolver,
    },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ApexPortalRoutingModule {}
