<apex-client-addon-breadcrumb
  [cart]="cart"
  [apartment]="apartment"
  [inCart]="true">
</apex-client-addon-breadcrumb>

<ng-container *ngIf="cart?.Product?.length">
  <div
    class="info-box"
    *ngIf="signing">
    <div
      class="df r ac"
      *ngIf="signing === 'success'">
      <mat-spinner diameter="16"></mat-spinner> &nbsp;
      <p>
        <T str="Please wait while we process your order."></T>
      </p>
    </div>
    <p *ngIf="signing === 'cancel'">
      <T str="Your canceled the signing at Signicat, please try again."></T>
    </p>

    <p *ngIf="signing === 'error'">
      <T str="An error occured with signing at Signicat, please try again."></T>
    </p>
  </div>

  <div class="df jfe amts amb ap">
    <button
      mat-raised-button
      color="primary"
      (click)="order()"
      [disabled]="!valid || working || (addonManager && !clientId)">
      <T str="Confirm order"></T>
    </button>
  </div>

  <apex-client-addon-cart-product-list
    *ngIf="cart"
    [addonManager]="addonManager"
    [products]="cart.Product"
    [editable]="!signing"
    (remove)="remove($event)"
    (update)="update($event)">
  </apex-client-addon-cart-product-list>
  <apex-client-addon-cart-totals
    *ngIf="cart"
    [products]="cart.Product">
  </apex-client-addon-cart-totals>
  <div class="df r jsb ac amb ap">
    <button
      mat-raised-button
      [disabled]="!!signing"
      (click)="back()">
      <T str="Back"></T>
    </button>
    <div>
      <mat-form-field
        *ngIf="addonManager"
        class="amr">
        <mat-label>
          <T str="Customer"></T>
        </mat-label>
        <mat-select
          name="clientId"
          [(ngModel)]="clientId">
          <mat-option
            *ngFor="let client of apartment.Clients"
            [value]="client.id">
            {{ client.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        (click)="order()"
        [disabled]="!valid || working || (addonManager && !clientId)">
        <T str="Confirm order"></T>
      </button>
    </div>
  </div>

  <apex-loader
    [loading]="generating"
    [text]="'Creating order, please wait' | translate"
    [overlay]="true">
  </apex-loader>
</ng-container>

<ng-container *ngIf="!cart?.Product?.length">
  <div class="emptystate-mypage">
    <T str="You have no items in your cart"></T>
  </div>
  <button
    mat-raised-button
    class="amb"
    (click)="back()">
    <T str="Back"></T>
  </button>
</ng-container>
