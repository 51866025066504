<ng-container *ngFor="let caseItem of cases; last as last">
  <portal-portal-case-list-item [caseItem]="caseItem"></portal-portal-case-list-item>

  <mat-divider *ngIf="!last"></mat-divider>
</ng-container>

<router-outlet></router-outlet>

<ng-container *ngIf="count > limit">
  <mat-divider></mat-divider>
  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    [length]="count"
    [pageIndex]="page"
    [pageSize]="limit"
    showFirstLastButtons
    (page)="changePage($event)">
  </mat-paginator>
</ng-container>
