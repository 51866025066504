import { AddonCategory } from '../../models/addon-category';
import { nameCompare } from '../../utils/functions';

export const sortAddonCategories = (addonCategories: AddonCategory[]): AddonCategory[] =>
  addonCategories.sort((a: AddonCategory, b: AddonCategory) => {
    const sortA: number = a.AddonCategoryProjects?.length ? a.AddonCategoryProjects[0].sortOrder : a.sort;
    const sortB: number = b.AddonCategoryProjects?.length ? b.AddonCategoryProjects[0].sortOrder : b.sort;

    if (sortA === sortB) {
      return nameCompare(a, b);
    }

    return sortA > sortB ? 1 : sortB > sortA ? -1 : 0;
  });
