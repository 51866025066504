import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { remove } from 'lodash-es';
import { ConfirmDialogComponent } from 'projects/apex/src/app/components/confirm-dialog/confirm-dialog.component';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { nameCompare } from 'projects/apex/src/app/utils/functions';
import { take } from 'rxjs/operators';
import { CommercialType, MetaType, Obj } from '../../object.model';
import { ObjectService } from '../../object.service';
import { SafetyType } from '../../safety/safety.model';

interface SortedObject extends Obj {
  highlighted: boolean;
}

@Component({
  selector: 'apex-object-list',
  templateUrl: './object-list.component.html',
})
export class ObjectListComponent implements OnInit, OnChanges {
  @Input() id: number;
  @Input() objects: Obj[];
  @Input() typesToHide: (CommercialType | SafetyType)[] = [];
  @Input() view: boolean;
  @Input() highlight: Obj[];
  @Input() allowDrag = false;
  @Input() asClient = false;
  @Input() expanded = false;

  get emptyMessage(): string {
    const message = this.asClient
      ? 'No objects to show'
      : 'No objects to show, create a new one and it will show up here.';

    return t(message);
  }

  objectsFilter = {
    search: '',
    building: true,
    floor: true,
    unit: true,
    room: true,
  };

  sortedList: SortedObject[] = [];

  loading = false;
  hideDrag = false;

  dragItem: Obj;
  dragIds: number[] = [];
  dragItems: Obj[] = [];

  public objectSteps = {};
  public objectChildren = {};
  public expandedCollapsed = {};

  constructor(
    public el: ElementRef,
    private service: ObjectService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.id) {
      const savedExpandedCollapsed = sessionStorage.getItem(`expandedCollapsed:${this.id}`);

      if (savedExpandedCollapsed) {
        this.expandedCollapsed = JSON.parse(savedExpandedCollapsed);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objects) {
      this.objectChange();
    }

    if (changes.id) {
      this.idChange();
    }
  }

  objectChange(): void {
    if (this.objects) {
      this.objects = this.objects.filter((o: Obj) => !this.typesToHide.includes(o.type));

      this.objects.forEach((obj) => {
        this.objectSteps[obj.id] = this.calcSteps(obj, 0);
        this.objectChildren[obj.id] = this.objects.some((o) => o.ParentId === obj.id);
      });

      this.loading = false;
      this.runFilter();
    }
  }

  idChange(): void {
    if (this.id) {
      this.loading = true;
      this.service
        .query<Obj>(MetaType.Commercial, null, {
          ParentId: this.id,
          children: true,
        })
        .pipe(take(1))
        .subscribe({
          next: (objects) => {
            this.objects = objects;
            this.objectChange();
          },
          complete: () => {
            this.loading = false;
          },
        });
    } else {
      this.objects = [];
    }
  }

  calcSteps(o: Obj, s: number): number {
    if (!s) {
      s = 0;
    }

    s++;

    if (o.ParentId) {
      const parent = this.objects.find((fo) => fo.id === o.ParentId);

      if (parent) {
        return this.calcSteps(parent, s);
      }
    }

    return s;
  }

  anyChildren(): boolean {
    return Object.values(this.objectChildren).some((v) => v === true);
  }

  public expCol(id: number): void {
    this.expandedCollapsed[id] = !this.expandedCollapsed[id];

    this.runFilter();
  }

  public globalExpCol(): void {
    if (this.anyExpanded()) {
      this.expandedCollapsed = {};
    } else {
      this.objects
        .filter((o) => !!o.ParentId)
        .forEach((o) => {
          this.expandedCollapsed[o.id] = true;
        });
    }

    this.runFilter();
  }

  anyExpanded(): boolean {
    return Object.keys(this.expandedCollapsed).some((k) => this.expandedCollapsed[k] === true);
  }

  runFilter(): void {
    const temp: Obj[] = [];

    const pushObject = (o: Obj): void => {
      temp.push(o);

      this.objects
        .sort(nameCompare)
        .filter((fo) => fo.ParentId === o.id && this.expandedCollapsed[o.id] !== true)
        .forEach((fo) => pushObject(fo));
    };

    Object.keys(this.objectSteps)
      .sort((a, b) => {
        const ao = this.objects.find((o) => o.id === Number(a));
        const bo = this.objects.find((o) => o.id === Number(b));

        return nameCompare(ao, bo);
      })
      .filter((k) => this.objectSteps[k] === 1)
      .forEach((k) => pushObject(this.objects.find((o) => o.id === parseInt(k, 10))));

    this.sortedList = temp.map((ot) =>
      Object.assign(ot, {
        highlighted: !!this.highlight?.find((o) => o?.id && ot.id === o.id),
      }),
    );

    if (this.id) {
      setTimeout(() => {
        sessionStorage.setItem(`expandedCollapsed:${this.id}`, JSON.stringify(this.expandedCollapsed));
      }, 250);
    }
  }

  delete(obj: Obj): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: t("Are you sure you want to delete this and all it's children?"),
        },
      })
      .afterClosed()
      .subscribe((d) => {
        if (d) {
          this.service
            .delete(obj)
            .pipe(take(1))
            .subscribe({
              next: () => {
                snack(t('Deleted'));
                this.removeItem(obj);
                this.runFilter();
              },
              error: (err) => {
                snackErr(t('Could not delete'), err);
              },
            });
        }
      });
  }

  removeItem(item: Obj): void {
    this.objects.forEach((o) => {
      if (o.ParentId === item.id) {
        this.removeItem(o);
      }
    });
    this.objectChildren[item.ParentId] = false;
    delete this.objectSteps[item.id];
    remove(this.objects, (o) => o.id === item.id);
  }

  drag(obj: Obj): void {
    const initStep = this.objectSteps[obj.id];
    const l = this.sortedList.length;

    this.dragItem = obj;
    this.dragItems = [this.dragItem];
    this.dragIds = [this.dragItem.id];

    for (let i = this.sortedList.indexOf(obj as SortedObject) + 1; i < l; i++) {
      const o = this.sortedList[i];

      if (this.objectSteps[o.id] > initStep) {
        this.dragIds.push(o.id);
        this.dragItems.push(o);
      } else {
        break;
      }
    }
  }

  dragEnd(): void {
    this.dragIds = [];
    this.dragItems = [];
  }

  drop(obj: Obj): void {
    const parent = obj;
    const droppedObj = this.objects.find((o) => o.id === this.dragItem?.id);

    const origParentId = droppedObj.ParentId;

    if (!droppedObj.ParentId) {
      throw Error('you can not move top level objects');
    }

    if (parent.id === droppedObj.id) {
      throw Error('you can not be your own parent');
    }

    droppedObj.ParentId = parent.id;
    this.highlight = [...this.dragItems];
    this.objectChange();

    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          text: t('Are you sure you want to move {from} to {to}', {
            from: droppedObj.name,
            to: parent.name,
          }),
        },
      })
      .afterClosed()
      .subscribe((move) => {
        if (move) {
          this.service.save(droppedObj).subscribe({
            next: (_) => {
              this.highlight = [];

              this.objectChange();
              snack(t('Moved'));
            },
            error: (err) => {
              droppedObj.ParentId = origParentId;
              this.highlight = [];

              this.objectChange();
              snackErr(t('Could not move'), err);
            },
          });
        } else {
          droppedObj.ParentId = origParentId;
          this.highlight = [];

          this.objectChange();
        }
      });
  }

  getObjectURL(item: SortedObject): string[] | null {
    if (this.asClient) {
      return null;
    }

    return !item.ParentId ? ['/object', 'project', String(item.id)] : [String(item.id)];
  }
}
