<apex-page-info
  [title]="tenancy?.name"
  icon="home_work"
  fontSet="material-icons-sharp">
</apex-page-info>
<div
  class="df c aps bsbb tenancy-wrapper set-height"
  style="--height: calc(100vh - 56px - 56px)">
  <section class="tenancy-info df w afs">
    <apex-tenancy-info
      class="ams mat-elevation-z2 f1 minw-45"
      [tenancy]="tenancy">
    </apex-tenancy-info>
    <apex-model-role
      class="f1 ams minw-45"
      model="tenancy"
      [viewMode]="true"
      [modelId]="tenancy.id"
      role="administrator"
      [emptyStateText]="'No administrators added for this tenancy' | translate"
      [label]="'Administrator' | translate"
      [title]="'Administrators' | translate">
    </apex-model-role>
    <apex-object-list
      class="f1 df c mat-elevation-z2 ams minw-45"
      [view]="true"
      [highlight]="tenancy.Objects"
      [objects]="objects || tenancy.Objects">
    </apex-object-list>
  </section>

  <apex-file-usage
    class="ams"
    [disabled]="true"
    [title]="'Files' | translate"
    self="tenancy"
    name="public-files"
    [selfId]="tenancy?.id">
  </apex-file-usage>

  <apex-entry-cards
    class="ams minw-45"
    #entryCards
    [viewMode]="true"
    [tenancyId]="tenancy.id"
    (newEntryCardClick)="addEntryCard()"
    (deleteEntryCard)="deleteEntryCard($event)"
    (editEntryCard)="editEntryCard($event)">
  </apex-entry-cards>

  <apex-cases
    #cases
    [listFullSize]="true"
    class="mat-elevation-z2 ams f2 set-min-height"
    style="--min-height: 400px"
    dialogClass="tenancy"
    [standardParams]="caseQueryParams"
    [newCaseValues]="{ CaseCategoryId: 6, ObjectId: tenancy?.topObject?.id }"
    [showCalendar]="false"
    [customAdd]="customAdd">
  </apex-cases>
</div>
