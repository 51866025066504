import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponsePortalApartmentDto, ResponsePortalCaseDtoPageResponse } from '../../../../../../generated/apex-rest';
import { Request } from '../../../../../apex/src/app/models/request';
import { CollectionResponse } from '../../../../../apex/src/app/utils/types';

@Component({
  selector: 'portal-case-request-page',
  templateUrl: './case-request-page.component.html',
})
export class CaseRequestPageComponent implements OnInit {
  hasCasesOrRequests$: Observable<boolean>;
  portalApartment: Observable<ResponsePortalApartmentDto>;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.portalApartment = this.route.data.pipe(map((data) => data.apartment));

    this.hasCasesOrRequests$ = this.route.data.pipe(
      map((data) => [data.cases, data.requests] as [ResponsePortalCaseDtoPageResponse, CollectionResponse<Request>]),
      map(([casesRes, requestsRes]) => {
        const caseCount = casesRes?.meta?.itemCount ?? 0;
        const requestCount = requestsRes?.count ?? 0;

        return !!caseCount || !!requestCount;
      }),
    );
  }
}
