import { Injectable } from '@angular/core';
import { mergeMap, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseCountryDto, SystemApi } from '../../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../../utils/rest-axios-config';

@Injectable()
export class SystemService {
  fetchCountries(): Observable<ResponseCountryDto[]> {
    return of([]).pipe(
      mergeMap(() => this.systemApi.systemControllerReadCountries()),
      map((res) => res.data.Collection),
    );
  }

  private systemApi = new SystemApi(restAxiosConfig());
}
