import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { PortalCaseDialogComponent } from 'projects/apex/src/app/components/case-dialog/case-dialog.component';
import { PortalCaseDialogData } from 'projects/apex/src/app/components/case-dialog/types';
import { Observable, Subscription, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Case } from '../../../models/case';
import { CaseParams } from '../../../models/case-params';
import { Tenancy } from '../../../models/tenancy';
import { Obj } from '../../object/object.model';
import { Project } from '../../object/project/project.model';
import { EntryCard } from '../../object/project/tenancy/entry-card/entry-card.model';
import { EntryCardsComponent } from '../../object/project/tenancy/entry-card/list.component';
import { TenancyService } from '../../object/project/tenancy/tenancy.service';
import { EntryCardRequestDeleteDialogComponent } from './entry-card-request-delete-dialog.component';
import { EntryCardRequestDialogComponent } from './entry-card-request-dialog.component';

@Component({
  selector: 'apex-portal-tenancy',
  templateUrl: './info.component.html',
})
export class PortalTenancyComponent implements OnInit, OnDestroy {
  @ViewChild('entryCards') entryCardComp: EntryCardsComponent;

  tenancy: Tenancy;
  caseQueryParams = new CaseParams();

  objects: Obj[] = [];
  topObject: Project;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private service: TenancyService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const sub = this.route.data
      .pipe(
        switchMap((data) => {
          this.tenancy = data.tenancy;
          this.setCaseQueryParams();

          return this.tenancy.id
            ? this.service.tree(this.tenancy.id).pipe(map((res) => res.Collection))
            : of([] as Obj[]);
        }),
      )
      .subscribe({
        next: (objects) => {
          this.objects = objects;
          this.topObject = this.objects.find((o) => !o.ParentId) as Project;
        },
      });

    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addEntryCard(): void {
    this.subscription.add(
      this.dialog
        .open(EntryCardRequestDialogComponent, {
          data: { tenancyId: this.tenancy.id },
          autoFocus: false,
        })
        .afterClosed()
        .subscribe({
          next: (ref) => {
            if (ref?.entryCard) {
              this.entryCardComp.viewEntryCards.push(ref.entryCard);
            }
          },
        }),
    );
  }

  deleteEntryCard(ec: EntryCard): void {
    this.subscription.add(
      this.dialog
        .open(EntryCardRequestDeleteDialogComponent, {
          data: ec,
        })
        .afterClosed()
        .subscribe(),
    );
  }

  editEntryCard(ec: EntryCard): void {
    this.subscription.add(
      this.dialog
        .open(EntryCardRequestDialogComponent, {
          data: {
            tenancyId: this.tenancy.id,
            entryCard: ec,
          },
        })
        .afterClosed()
        .subscribe(),
    );
  }

  private setCaseQueryParams(): void {
    this.caseQueryParams.Tenancy = this.tenancy?.id;
    this.caseQueryParams.showOpen = true;
    this.caseQueryParams.showCompleted = true;
    this.caseQueryParams.showCaseManagers = true;
  }

  customAdd(newCase?: Case): Observable<Case> {
    const ref = this.dialog.open<PortalCaseDialogComponent, PortalCaseDialogData, PortalCaseDialogData>(
      PortalCaseDialogComponent,
      {
        data: {
          case: newCase,
        },
      },
    );

    return ref.afterClosed().pipe(map((d) => d?.case));
  }
}
