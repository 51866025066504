import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '../../../components/translate/translate.module';
import { ApartmentService } from '../../apartment/apartment.service';
import { ClientPageInfoService } from '../client-page-info.service';
import { ClientPageInfoViewDialogModule } from '../view/client-page-info-view.module';

import { BoxModule } from '../../../components/box/box.module';
import { ClientPageInfoBoxComponent } from './client-page-info-box.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    TranslateModule,
    ClientPageInfoViewDialogModule,
    BoxModule,

    MatListModule,
    MatExpansionModule,
    MatIconModule,
    MatPaginatorModule,
    MatDialogModule,
  ],
  exports: [ClientPageInfoBoxComponent],
  declarations: [ClientPageInfoBoxComponent],
  providers: [ClientPageInfoService, ApartmentService],
})
export class ClientPageInfoBoxModule {}
