import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'portalAddonUrlPipe',
  standalone: true,
})
export class PortalAddonUrlPipe implements PipeTransform {
  transform(
    inPortal: boolean | null | undefined,
    clientPageUrl: (string | number)[],
    portalUrl: (string | number)[],
  ): (string | number)[] {
    if (!inPortal) {
      return clientPageUrl;
    }

    return portalUrl;
  }
}
