import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { Addon } from 'projects/apex/src/app/models/addon';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { AddonCart } from 'projects/apex/src/app/models/addon-cart';
import { AddonCategory } from 'projects/apex/src/app/models/addon-category';
import { AddonCategoryProject } from 'projects/apex/src/app/models/addon-category-project';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { sortNatural } from 'projects/apex/src/app/utils/functions';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'apex-client-addon-apartment-tree-node',
  templateUrl: './node.component.html',
})
export class ClientAddonApartmentTreeNodeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() level = 0;
  @Input() addonCategory: AddonCategory;
  @Input() list: AddonCategory[] = [];
  @Input() client = false;
  @Input() cart: AddonCart;

  @Output() addonClicked = new EventEmitter<AddonApartment>();
  @Output() add = new EventEmitter<{ addonApartment: AddonApartment; comment: string }>();
  @Output() remove = new EventEmitter<AddonApartment>();

  children: AddonCategory[] = [];
  selectedAddonApartment: AddonApartment;
  comment: string;
  addonCategoryProject: AddonCategoryProject;
  empty = false;

  headerImageWidth: number;

  private subscriptions: Subscription[] = [];

  constructor(private clientService: ClientPageService) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.headerImageWidth = document.body.clientWidth;
  }

  ngOnInit(): void {
    if (this.addonCategory) {
      this.addonCategory.Addons = this.addonCategory.Addons.filter((a) => a.AddonApartments?.length);
      this.empty = !this.checkIfAddonCategoryHasVisibleAddonApartments(this.addonCategory);
    }

    this.setChildren();

    if (
      this.addonCategory &&
      this.addonCategory.AddonCategoryProjects &&
      this.addonCategory.AddonCategoryProjects.length > 0
    ) {
      this.addonCategoryProject = this.addonCategory.AddonCategoryProjects[0];
    }

    this.getCategoryImage();
    this.headerImageWidth = document.body.clientWidth;
    this.sortAddons();
  }

  ngOnChanges(): void {
    if (this.cart) {
      const productInCart = this.cart.Product?.find(
        (product) => product.Addon?.AddonCategoryId === this.addonCategory.id,
      );

      if (productInCart) {
        this.selectedAddonApartment = productInCart.AddonApartment;
        this.comment = productInCart.ClientComment;
      } else {
        this.setSelectedToStandard();
      }
    } else {
      this.setSelectedToStandard();
    }
  }

  checkIfAddonCategoryHasVisibleAddonApartments(addonCategory: AddonCategory): boolean {
    let result = false;

    if (this.checkIfAddonsGotVisibleAddonApartments(addonCategory.Addons)) {
      result = true;
    } else {
      this.list
        .filter((ac1) => ac1.ParentId === addonCategory.id)
        .forEach((ac2: AddonCategory) => {
          if (this.checkIfAddonsGotVisibleAddonApartments(ac2.Addons)) {
            result = true;
          } else {
            if (this.checkIfAddonCategoryHasVisibleAddonApartments(ac2)) {
              result = true;
            }
          }
        });
    }

    return result;
  }

  checkIfAddonsGotVisibleAddonApartments(addons: Addon[]): boolean {
    if (addons?.length && addons.find((a) => a.AddonApartments?.length)) {
      return true;
    }

    return false;
  }

  setSelectedToStandard(): void {
    const addon = this.addonCategory?.Addons?.find((aa) =>
      aa.AddonApartments && aa.AddonApartments.length ? aa.AddonApartments[0].standardForCategory === true : false,
    );

    if (addon) {
      this.selectedAddonApartment = addon.AddonApartments[0];
    } else {
      this.selectedAddonApartment = undefined;
    }
  }

  sortAddons(): void {
    if (this.addonCategory?.Addons?.length) {
      const standards = sortNatural(
        this.addonCategory.Addons.filter((a) => a.AddonApartments.find((aa) => aa.standardForCategory)),
        'name',
      );
      const normals = sortNatural(
        this.addonCategory.Addons.filter((a) => !a.AddonApartments.find((aa) => aa.standardForCategory)),
        'name',
      );

      this.addonCategory.Addons = (standards?.length ? standards : []).concat(normals?.length ? normals : []);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  categoryInView(inView: boolean, id: number): void {
    this.clientService.categoryInView.next({
      inView,
      id,
    });
  }

  private getCategoryImage(): void {
    if (this.addonCategoryProject) {
      const sub = this.clientService
        .getAddonCategoryProjectImage(this.addonCategoryProject)
        .pipe(take(1))
        .subscribe((fileUsages: FileUsage[]) => {
          if (fileUsages) {
            this.addonCategoryProject.FileUsages = fileUsages;
          }
        });

      this.subscriptions.push(sub);
    }
  }

  private setChildren(): void {
    this.children = this.list.filter((ac) => this.addonCategory.id === ac.ParentId);
  }
}
