<mat-card
  appearance="raised"
  class="amh amb mat-elevation-z2">
  <mat-card-header>
    <mat-card-subtitle
      class="apv"
      *ngIf="viewData.postedDate">
      {{ viewData.postedDate }}
    </mat-card-subtitle>
  </mat-card-header>

  <ng-container *ngIf="viewData.images?.length">
    <apex-file-usage-carousel
      class="apb"
      mat-card-image
      [fileUsages]="viewData.images">
    </apex-file-usage-carousel>
  </ng-container>

  <mat-card-content>
    <ng-container *ngIf="viewData.lead">
      {{ viewData.lead }}
    </ng-container>

    <div
      class="display-block line-break-anywhere"
      [innerHTML]="innerHtml"></div>

    <ng-container *ngIf="viewData.files?.length">
      <mat-divider></mat-divider>

      <apex-file-usage-attachments [fileUsages]="viewData.files"></apex-file-usage-attachments>
    </ng-container>
  </mat-card-content>
</mat-card>
