<div
  [style.width.px]="width"
  class="df AK-50-bg">
  <mat-form-field
    class="f1"
    subscriptSizing="dynamic">
    <input
      type="text"
      matInput
      [placeholder]="'Search' | translate"
      [(ngModel)]="search"
      (ngModelChange)="filterAutocomplete()"
      [matAutocomplete]="autoCompleteSearch" />
    <button
      mat-icon-button
      matSuffix
      (click)="closeOverlay()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-autocomplete
    #autoCompleteSearch="matAutocomplete"
    (optionSelected)="goTo($event?.option?.value)">
    <mat-option
      [value]="addonOrCategory?.category?.name ?? addonOrCategory?.addon?.name"
      *ngFor="let addonOrCategory of filteredCategories">
      <ng-container *ngIf="addonOrCategory.category">
        <span class="truncate">
          {{ addonOrCategory.category.name }}
        </span>
      </ng-container>
      <ng-container *ngIf="addonOrCategory.addon">
        <div
          class="df ac"
          (apexInView)="getAddonImage(addonOrCategory.addon)"
          inViewOnlyOnce>
          <div class="amrs">
            <apex-file-preview
              *ngIf="addonOrCategory?.addon?.images?.length"
              [file]="addonOrCategory?.addon?.images[0]?.File"
              fit="cover"
              [size]="{ width: 40, height: 30 }"></apex-file-preview>
          </div>
          <span class="truncate">
            {{ addonOrCategory.addon.name }}
          </span>
        </div>
      </ng-container>
    </mat-option>
  </mat-autocomplete>
</div>
