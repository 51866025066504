import { Overlay } from '@angular/cdk/overlay';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FileUsageViewerDialogComponent } from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer-dialog.component';
import {
  FileUsageViewerData,
  FileUsageViewerMode,
} from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer.types';
import { IAddonOrderStatus } from 'projects/apex/src/app/features/addon/services/addon-order/addon-order.service.types';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { AddonOrder, AddonOrderStatus } from 'projects/apex/src/app/models/addon-order';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { MarkingModelType } from 'projects/apex/src/app/models/marking';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'apex-client-addon-order-card',
  templateUrl: './card.component.html',
})
export class ClientAddonOrderCardComponent implements OnInit, OnDestroy {
  @Input() addonOrder: AddonOrder;

  expanded = false;

  AddonOrderStatus = AddonOrderStatus;
  status: IAddonOrderStatus;
  displayButtons = false;
  fileUsages: FileUsage[];

  priceIncVat: number;
  totalIncVat: number;

  inPortal = false;
  portalBaseUrl: string;

  private subscription = new Subscription();

  constructor(
    private clientService: ClientPageService,
    private overlay: Overlay,
    private dialog: MatDialog,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.getAll();
    this.getAddonImages();

    const ao = new AddonOrder(this.addonOrder);

    this.priceIncVat = ao.priceIncVat();
    this.totalIncVat = ao.totalIncVat();

    const snapshot = this.route.snapshot;

    this.inPortal = snapshot.data.portal;

    if (this.inPortal) {
      const projectId = snapshot.paramMap.get('ProjectId');
      const apartmentId = snapshot.paramMap.get('ApartmentId');

      this.portalBaseUrl = `/project/${projectId}/apartment/${apartmentId}/addon`;
    }
  }

  getAll(): void {
    this.status = this.clientService.getStatusInterface(this.addonOrder.status);
    this.getDisplayButtons();
  }

  getDisplayButtons(): void {
    this.displayButtons = this.addonOrder.status === AddonOrderStatus.Approved;
  }

  approve(): void {
    this.subscription.add(
      this.clientService
        .approveAddonOrder(this.addonOrder)
        .pipe(take(1))
        .subscribe((addonOrder: AddonOrder) => {
          this.addonOrder = addonOrder;
          this.getAll();
        }),
    );
  }

  deny(): void {
    this.subscription.add(
      this.clientService
        .denyAddonOrder(this.addonOrder)
        .pipe(take(1))
        .subscribe((addonOrder: AddonOrder) => {
          this.addonOrder = addonOrder;
          this.getAll();
        }),
    );
  }

  markingsClicked(): void {
    this.subscription.add(
      this.clientService
        .getFloorplanImage(this.addonOrder.AddonApartment)
        .pipe(take(1))
        .subscribe((floorplans: FileUsage[]) => {
          this.subscription.add(
            this.openAddonApartmentMarking({
              mode: FileUsageViewerMode.Mark,
              fileUsages: floorplans,
              editable: false,
              client: true,
              modelData: {
                model: MarkingModelType.AddonApartment,
                modelId: this.addonOrder.AddonApartment.id,
              },
              markingData: {
                min: this.addonOrder.AddonApartment.markingMin,
                max: this.addonOrder.AddonApartment.markingMax,
              },
            })
              .pipe(take(1))
              .subscribe(),
          );
        }),
    );
  }

  openAddonApartmentMarking(data: FileUsageViewerData): Observable<boolean> {
    return this.dialog
      .open(FileUsageViewerDialogComponent, {
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data,
      })
      .afterClosed();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getAddonImages(): void {
    this.subscription.add(
      this.clientService
        .getAddonImages(this.addonOrder.Addon)
        .pipe(take(1))
        .subscribe((fileUsages: FileUsage[]) => {
          this.fileUsages = fileUsages;
        }),
    );
  }
}
