import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { cloneDeep, isEmpty } from 'lodash-es';
import { AddonOrder, AddonOrderStatus } from 'projects/apex/src/app/models/addon-order';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { Project } from 'projects/apex/src/app/models/project';
import { constants } from 'projects/apex/src/app/utils/constants';
import { sortNatural } from 'projects/apex/src/app/utils/functions';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'apex-client-addon-order-list',
  templateUrl: './list.component.html',
})
export class ClientAddonOrderListComponent implements OnInit, OnDestroy {
  @Input() projects: Project[];
  filteredProjectsWithAddonOrders: Project[];

  newestProject: Project;
  newestApartment: Apartment;

  newChecked: boolean;
  acceptedChecked: boolean;
  deniedChecked: boolean;
  canceledChecked: boolean;

  private subscriptions: Subscription[] = [];

  private filterSubject: Subject<Params> = new Subject<Params>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    if (isEmpty(this.route.snapshot.queryParams)) {
      ['new', 'accepted'].forEach((key) => {
        this[`${key}Checked`] = true;
      });
      this.updateQueryParams();
    }

    const sub1 = this.route.queryParams?.subscribe({
      next: (params) => {
        ['new', 'accepted', 'denied', 'canceled'].forEach((key) => {
          this[`${key}Checked`] = params[`${key}Checked`] === 'true';
        });

        this.filter();
      },
    });

    const sub2 = this.filterSubject
      .pipe(debounceTime(constants.inputDebounceTime))
      .subscribe(() => this.updateQueryParams());

    this.subscriptions.push(sub1, sub2);
  }

  filterAndsort(): void {
    this.filteredProjectsWithAddonOrders = cloneDeep(this.projects);

    this.filteredProjectsWithAddonOrders?.forEach((p: Project) => {
      p.Apartments?.forEach((a: Apartment) => {
        a.AddonOrders = a?.AddonOrders
          ? a.AddonOrders?.filter((ao) => {
              const statusFilter =
                this.newChecked || this.acceptedChecked || this.deniedChecked || this.canceledChecked
                  ? (this.newChecked ? ao.status === AddonOrderStatus.New : false) ||
                    (this.acceptedChecked ? ao.status === AddonOrderStatus.Approved : false) ||
                    (this.deniedChecked ? ao.status === AddonOrderStatus.Denied : false) ||
                    (this.canceledChecked ? ao.status === AddonOrderStatus.Canceled : false)
                  : true;

              return statusFilter;
            })
          : [];
        sortNatural(a.AddonOrders, 'id').reverse();
      });
      sortNatural(p.Apartments, 'id');
    });
  }

  totalQuantityForApartment(apartment: Apartment): number {
    let totalQ = 0;

    this.projects?.forEach((p) => {
      p.Apartments?.find((a) => a.id === apartment.id)?.AddonOrders?.forEach((addonOrder) => {
        if (addonOrder.status === AddonOrderStatus.Approved) {
          totalQ += addonOrder.quantity;
        }
      });
    });

    return totalQ;
  }

  totalForApartment(apartment: Apartment): number {
    let total = 0;

    this.projects?.forEach((p) => {
      p.Apartments?.find((a) => a.id === apartment.id)?.AddonOrders?.forEach((addonOrder) => {
        if (addonOrder.status === AddonOrderStatus.Approved) {
          const addonOrderObj = new AddonOrder(addonOrder);

          total += addonOrderObj.totalIncVat();
        }
      });
    });

    return total;
  }

  onChangeFilter(): void {
    this.filterSubject.next(null);
  }

  filter(): void {
    this.filterAndsort();
  }

  updateQueryParams(): void {
    const queryParams: Params = {
      newChecked: this.newChecked,
      acceptedChecked: this.acceptedChecked,
      deniedChecked: this.deniedChecked,
      canceledChecked: this.canceledChecked,
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }
}
