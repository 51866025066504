import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DragModule } from 'projects/apex/src/app/components/drag/drag.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { IconPipeModule } from 'projects/apex/src/app/pipes/icon/icon-pipe.module';
import { ObjectSelectorModule } from '../selector/object-selector.module';
import { ObjectListPageComponent } from './object-list-page.component';

import { MatTabsModule } from '@angular/material/tabs';
import { BoxModule } from '../../../../components/box/box.module';
import ObjectListQrComponent from '../object-list-qr/object-list-qr.component';
import { SortHierarchyPipe } from '../object-list-qr/pipes/sort-hierarchy.pipe';
import { ObjectListComponent } from './object-list.component';

@NgModule({
  imports: [
    CommonModule,

    MatDialogModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatRippleModule,
    DragDropModule,

    TranslateModule,
    IconPipeModule,
    ObjectSelectorModule,
    DragModule,
    BoxModule,
    MatTabsModule,
    ObjectListQrComponent,
    SortHierarchyPipe,
  ],
  exports: [ObjectListComponent, ObjectListPageComponent],
  declarations: [ObjectListComponent, ObjectListPageComponent],
})
export class ObjectListModule {}
