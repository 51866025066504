<div class="left df jc">
  <ng-content select="[left]"></ng-content>
</div>
<mat-divider [vertical]="true"></mat-divider>
<div
  class="right oh"
  [ngClass]="{
    aps: dense,
    ap: !dense,
  }">
  <ng-content select="[right]"></ng-content>
</div>
