import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  AllowedArticleModel,
  ArticlePageOptionsOrderBy,
  CreatePortalCaseLogDto,
  PortalApi,
  PortalCaseLogPageOptionsOrderBy,
  PortalCasePageOptionsOrderBy,
  ResponsePortalApartmentDto,
  ResponsePortalArticleDtoPageResponse,
  ResponsePortalCaseDtoPageResponse,
  ResponsePortalCaseLogDto,
  ResponsePortalCaseLogDtoPageResponse,
  ResponsePortalFileUsageDto,
  ResponsePortalMainObjectDto,
} from '../../../../generated/apex-rest';
import { restAxiosConfig } from '../../../apex/src/app/utils/rest-axios-config';

@Injectable()
export class PortalService {
  entitiesRead: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  portalApi = new PortalApi(restAxiosConfig());

  async readProjectApartmentCases(
    projectId: number,
    apartmentId: number,
    orderBy?: PortalCasePageOptionsOrderBy,
    order?: 'ASC' | 'DESC',
    page?: number,
    limit?: number,
  ): Promise<ResponsePortalCaseDtoPageResponse> {
    if (!projectId || !apartmentId) {
      throw new Error('Project id and apartment id is required');
    }

    const response = await this.portalApi.portalProjectApartmentCaseControllerReadCollection(
      projectId,
      apartmentId,
      page,
      limit,
      order,
      orderBy,
    );

    return response.data;
  }

  async markProjectApartmentCaseRead(projectId: number, apartmentId: number, caseId: number): Promise<boolean> {
    if (!projectId || !apartmentId || !caseId) {
      throw new Error('Project id, apartment id and case id is required');
    }

    const response = await this.portalApi.portalProjectApartmentCaseControllerMarkCaseRead(
      projectId,
      apartmentId,
      caseId,
    );

    return response.data;
  }

  async markProjectApartmentCaseUnread(projectId: number, apartmentId: number, caseId: number): Promise<boolean> {
    if (!projectId || !apartmentId || !caseId) {
      throw new Error('Project id, apartment id and case id is required');
    }

    const response = await this.portalApi.portalProjectApartmentCaseControllerMarkCaseUnread(
      projectId,
      apartmentId,
      caseId,
    );

    return response.data;
  }

  async readProjectApartmentCaseLogs(
    projectId: number,
    apartmentId: number,
    caseId: number,
    orderBy?: PortalCaseLogPageOptionsOrderBy,
    order?: 'ASC' | 'DESC',
    page?: number,
    limit?: number,
  ): Promise<ResponsePortalCaseLogDtoPageResponse> {
    if (!projectId || !apartmentId || !caseId) {
      throw new Error('Project id, apartment id and case id is required');
    }

    const response = await this.portalApi.portalProjectApartmentCaseLogControllerReadCollection(
      projectId,
      apartmentId,
      caseId,
      page,
      limit,
      order,
      orderBy,
    );

    return response.data;
  }

  async createProjectApartmentCaseLog(
    projectId: number,
    apartmentId: number,
    caseId: number,
    createPortalCaseLogDto: CreatePortalCaseLogDto,
  ): Promise<ResponsePortalCaseLogDto> {
    if (!projectId || !apartmentId || !caseId || !createPortalCaseLogDto) {
      throw new Error('Project id, apartment id, case id and case log is required');
    }

    const response = await this.portalApi.portalProjectApartmentCaseLogControllerCreate(
      projectId,
      apartmentId,
      caseId,
      createPortalCaseLogDto,
    );

    return response.data.Entity;
  }

  async readProjectApartmentArticles(
    projectId: number,
    apartmentId: number,
    orderBy?: ArticlePageOptionsOrderBy,
    articleModel?: AllowedArticleModel,
    order?: 'ASC' | 'DESC',
    page?: number,
    limit?: number,
    search?: string,
  ): Promise<ResponsePortalArticleDtoPageResponse> {
    if (!projectId || !apartmentId) {
      throw new Error('Project id and apartment id is required');
    }

    const response = await this.portalApi.portalProjectArticleControllerReadCollectionForApartment(
      projectId,
      apartmentId,
      page,
      limit,
      order,
      orderBy,
      articleModel,
      search,
    );

    return response.data;
  }

  async readEntities(): Promise<Array<ResponsePortalMainObjectDto>> {
    const response = await this.portalApi.portalControllerReadMainObjects();

    this.entitiesRead.next(response?.data?.Collection?.length ?? 0);

    return response.data.Collection;
  }

  async readProjectApartment(projectId: number, apartmentId: number): Promise<ResponsePortalApartmentDto> {
    if (!projectId || !apartmentId) {
      throw new Error('Project id and apartment id is required');
    }

    const response = await this.portalApi.portalApartmentControllerReadApartment(projectId, apartmentId);

    return response.data.Entity;
  }

  async readProjectApartmentBannerImages(
    projectId: number,
    apartmentId: number,
  ): Promise<Array<ResponsePortalFileUsageDto>> {
    if (!projectId || !apartmentId) {
      throw new Error('Project id and apartment id is required');
    }

    const response = await this.portalApi.portalApartmentControllerReadApartmentBannerImages(projectId, apartmentId);

    return response.data.Collection;
  }

  async readProjectApartmentCompanyLogos(
    projectId: number,
    apartmentId: number,
  ): Promise<ResponsePortalFileUsageDto[]> {
    if (!projectId || !apartmentId) {
      throw new Error('Project id and apartment id is required');
    }

    const response = await this.portalApi.portalApartmentControllerReadCompanyLogos(projectId, apartmentId);

    return response.data.Collection;
  }
}
