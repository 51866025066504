<section class="filters mat-elevation-z2 amb amts">
  <div class="df ac w">
    <mat-checkbox
      class="checkbox-filter"
      (change)="onChangeFilter()"
      [(ngModel)]="newChecked">
      <div
        matRipple
        class="checkbox-filter-content df ac ap">
        <mat-icon
          class="amrs"
          [ngClass]="{ 'info-color': newChecked }"
          [fontSet]="newChecked ? 'material-icons' : 'material-icons-outlined'">
          watch_later
        </mat-icon>
        <T str="New"></T>
      </div>
    </mat-checkbox>
    <mat-checkbox
      class="checkbox-filter"
      (change)="onChangeFilter()"
      [(ngModel)]="acceptedChecked">
      <div
        matRipple
        class="checkbox-filter-content df ac ap">
        <mat-icon
          class="amrs"
          [ngClass]="{ 'success-color': acceptedChecked }"
          [fontSet]="acceptedChecked ? 'material-icons' : 'material-icons-outlined'">
          check_circle
        </mat-icon>
        <T str="Approved"></T>
      </div>
    </mat-checkbox>
    <mat-checkbox
      class="checkbox-filter"
      (change)="onChangeFilter()"
      [(ngModel)]="deniedChecked">
      <div
        matRipple
        class="checkbox-filter-content df ac ap">
        <mat-icon
          class="amrs"
          [ngClass]="{ 'error-color': deniedChecked }"
          [fontSet]="deniedChecked ? 'material-icons' : 'material-icons-outlined'">
          info
        </mat-icon>
        <T str="Denied"></T>
      </div>
    </mat-checkbox>
    <mat-checkbox
      class="checkbox-filter"
      (change)="onChangeFilter()"
      [(ngModel)]="canceledChecked">
      <div
        matRipple
        class="checkbox-filter-content df ac ap">
        <mat-icon
          class="amrs"
          [ngClass]="{ 'warning-color': canceledChecked }"
          [fontSet]="canceledChecked ? 'material-icons' : 'material-icons-outlined'">
          cancel
        </mat-icon>
        <T str="Canceled"></T>
      </div>
    </mat-checkbox>
  </div>
</section>

<section
  *ngFor="let p of filteredProjectsWithAddonOrders"
  class="df c">
  <section
    *ngFor="let a of p.Apartments"
    class="amb mat-elevation-z2">
    <header class="df apv apl sticky mat-elevation-z2 jsb ac">
      <div class="df f1 ac header-wrapper">
        <h2 class="rm df ac w-60">
          <mat-icon svgIcon="apartment"></mat-icon>
          {{ p.name }} / {{ a.name }}
        </h2>
        <section class="amh df f1 ac price-headers">
          <T
            class="df fdot5 jc amr"
            str="Quantity"></T>
          <T
            class="df f1 jfe amr"
            str="Price"></T>
          <T
            class="df f1 jfe aml"
            str="Total"></T>
        </section>
      </div>
    </header>
    <section
      *ngFor="let ao of a.AddonOrders"
      class="addon-order-row">
      <apex-client-addon-order-card [addonOrder]="ao"> </apex-client-addon-order-card>
    </section>
    <footer class="df f1 jfe apv apl">
      <div class="df f1 ac footer-wrapper">
        <div class="w-60"></div>
        <div class="footer-values df f1 amh">
          <h3 class="df fdot5 jc amr footer-value">
            <span class="footer-label"> <T str="Quantity"></T>: </span>
            {{ totalQuantityForApartment(a) || 0 }}
          </h3>
          <div class="df f1 jfe amr"></div>
          <h3
            class="df f1 jfe aml footer-value"
            [matTooltip]="'Calculated based on approved orders' | translate">
            <span class="footer-label"> <T str="Total"></T>: </span>
            {{ totalForApartment(a) || 0 | apexCurrency: 'NOK' : '' }}
          </h3>
        </div>
      </div>
    </footer>
  </section>
</section>

<div
  *ngIf="!projects || !projects.length"
  class="emptystate-mypage">
  <T str="You have no orders"></T>
</div>
