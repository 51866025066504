import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Apartment } from 'projects/apex/src/app/models/apartment';

@Component({
  selector: 'apex-client-apartment-card',
  templateUrl: './apartment-card.component.html',
})
export class ClientApartmentCardComponent {
  @Input() apartment: Apartment;
  @Output() clicked = new EventEmitter<Apartment>();

  constructor(private router: Router) {}

  emitClicked(): void {
    this.clicked.emit(this.apartment);
  }

  cartClicked(event: Event): void {
    event.stopPropagation();
    void this.router.navigate(['minside', 'tilvalg', this.apartment.id, 'cart']);
  }
}
