import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { uniqBy } from 'lodash-es';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { CaseService } from 'projects/apex/src/app/features/case/case.service';
import { Project } from 'projects/apex/src/app/features/object/project/project.model';
import { TenancyService } from 'projects/apex/src/app/features/object/project/tenancy/tenancy.service';
import { snack } from 'projects/apex/src/app/modules/snack.module';
import { Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ObjectWithDepth } from '../../features/object/object.model';
import { sortObjectsByDepth } from '../../features/object/utils';
import { Case } from '../../models/case';
import { FileUsage } from '../../models/file-usage';
import { Marking, MarkingModelType } from '../../models/marking';
import { Tenancy } from '../../models/tenancy';
import { PortalCaseDialogData } from './types';

@Component({
  selector: 'apex-case-dialog',
  templateUrl: 'case-dialog.component.html',
})
export class PortalCaseDialogComponent implements OnDestroy {
  @ViewChild('form') form: NgForm;

  showFields$: Observable<boolean>;

  MarkingModelType = MarkingModelType;

  get caze(): Case {
    return this.data.case;
  }

  get floorPlans(): FileUsage[] {
    return this.caze.Floorplans ?? [];
  }

  set floorPlans(floorPlans: FileUsage[]) {
    this.caze.Floorplans = floorPlans;
  }

  getObjects(tenancy: Tenancy): ObjectWithDepth[] {
    const allObjects = uniqBy([...tenancy.Objects, ...tenancy.indirectObjects], 'id');

    const groups = tenancy.Objects.map((obj) => sortObjectsByDepth(obj, allObjects));

    return groups.reduce((prev, curr) => [...prev, ...curr], []);
  }

  getObjectClass(obj: ObjectWithDepth): string {
    return `select-steps-${obj.depth + 1}`;
  }

  tenancy$: Observable<Tenancy>;

  subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: PortalCaseDialogData,
    public dialogRef: MatDialogRef<PortalCaseDialogComponent>,
    private caseService: CaseService,
    private tenancyService: TenancyService,
  ) {
    this.dialogRef.addPanelClass(['apex-fullscreen-dialog', 'phone']);

    this.showFields$ = this.tenancyService.get(this.caze.TenancyId).pipe(
      take(1),
      map((res) => (res?.Entity?.topObject as Project)?.data?.allowedTenancyObjectFieldIds),
      map((allowedFields) => allowedFields?.length > 0),
    );

    if (this.caze.TenancyId) {
      this.tenancy$ = this.tenancyService.get(this.caze.TenancyId).pipe(
        take(1),
        map((response) => response.Entity),
      );
    }

    const tenancyFloorPlans$ = this.tenancyService.getFloorplans(this.caze.TenancyId).pipe(take(1));

    const floorPlanSub = tenancyFloorPlans$.subscribe((floorPlans) => this.updateFloorPlans(floorPlans));

    this.subscription.add(floorPlanSub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save(): void {
    if (this.form.valid) {
      this.caseService
        .save(this.data.case)
        .pipe(take(1))
        .subscribe({
          next: (c) => {
            snack(t('Saved'));

            this.dialogRef.close({
              case: c,
            });
          },
        });
    }
  }

  updateFloorPlans(floorPlans: FileUsage[]): void {
    this.floorPlans = uniqBy([...(this.floorPlans ?? []), ...floorPlans], 'id');
  }

  updateMarkings(markings: Marking[]): void {
    if (!this.caze || !markings) {
      return;
    }

    this.caze.Markings = markings;
  }
}
