import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule } from '../../../components/translate/translate.module';
import { FieldService } from '../../field/field.service';
import { ProjectService } from '../../project/project.service';
import { RequestDialogModule } from '../dialog/request-dialog.module';
import { RequestService } from '../request.service';

import { BoxModule } from '../../../components/box/box.module';
import { ProjectApartmentService } from '../../project/apartment/project-apartment.service';
import { RequestBoxComponent } from './request-box.component';

@NgModule({
  imports: [
    CommonModule,

    TranslateModule,
    RequestDialogModule,
    BoxModule,

    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatPaginatorModule,
  ],
  exports: [RequestBoxComponent],
  declarations: [RequestBoxComponent],
  providers: [RequestService, FieldService, ProjectService, ProjectApartmentService],
})
export class RequestBoxModule {}
