<apex-page-info
  [title]="agreement?.name"
  icon="description"
  fontSet="material-icons-sharp">
</apex-page-info>

<section class="agreement-wrapper aps df c f1">
  <section class="df w afs">
    <div class="mat-elevation-z2 df c f1 ams aps">
      <div class="df ac ams">
        <mat-icon class="amr">description</mat-icon>
        {{ agreement.name }}
      </div>
      <div class="df ac ams">
        <mat-icon
          class="amr"
          [matTooltip]="'Object' | translate">
          {{ agreement.Object | icon }}
        </mat-icon>
        {{ agreement.Object?.name }}
      </div>
      <div class="df ac ams">
        <mat-icon
          class="amr"
          [matTooltip]="'Period' | translate">
          date_range
        </mat-icon>
        {{ agreement?.from | dater | date: 'shortDate' }}
        -
        {{ agreement?.to | dater | date: 'shortDate' }}
      </div>
    </div>

    <apex-model-role
      class="ams df c f1"
      model="agreement"
      [viewMode]="true"
      [modelId]="agreement.id"
      role="involved"
      [emptyStateText]="'No involved added for this agreement' | translate"
      [label]="'Involved' | translate"
      [title]="'Involved' | translate">
    </apex-model-role>
  </section>

  <apex-file-usage
    class="ams"
    [disabled]="true"
    [title]="'Files' | translate"
    self="agreement"
    name="public-files"
    [selfId]="agreement?.id">
  </apex-file-usage>

  <apex-cases
    #cases
    [listFullSize]="true"
    class="mat-elevation-z2 ams f1 set-min-height"
    style="--min-height: 400px"
    [standardParams]="caseQueryParams"
    [showCalendar]="false">
  </apex-cases>
</section>
