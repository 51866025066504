<ng-container *ngFor="let request of requests; last as last">
  <apex-case-list-item-wrapper>
    <ng-container left>
      <mat-icon
        class="apts"
        [matTooltip]="'Pending' | translate">
        pending_actions
      </mat-icon>
    </ng-container>

    <ng-container right>
      <section class="mat-headline-6 rm top-left">
        {{ request.caseName }}
      </section>

      <section class="df jfe">
        {{ request.clientName }}
      </section>

      <section class="df ac w gap main apts">
        <apex-title-item *ngIf="request.description">
          <T
            title
            str="Description">
          </T>

          {{ request.description }}
        </apex-title-item>
      </section>

      <section class="bottom-right mat-caption df ac jfe">
        <ng-container *ngIf="request.Field?.name"> {{ request.Field.name }} / </ng-container>
        {{ request.date | date: 'medium' }}
      </section>
    </ng-container>
  </apex-case-list-item-wrapper>

  <mat-divider *ngIf="!last"></mat-divider>
</ng-container>

<ng-container *ngIf="count > limit">
  <mat-divider></mat-divider>
  <mat-paginator
    [pageSizeOptions]="[25, 50, 100]"
    [length]="count"
    [pageIndex]="page"
    [pageSize]="limit"
    showFirstLastButtons
    (page)="changePage($event)">
  </mat-paginator>
</ng-container>

<button
  *ngIf="!disableRequests"
  class="mat-fab-extended"
  mat-fab
  extended
  (click)="createRequest()">
  <mat-icon>add</mat-icon>
  <T str="New request"></T>
</button>
