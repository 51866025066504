import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { map, mergeMap, take, toArray } from 'rxjs/operators';
import { FileUsageComponent } from '../../../components/file-usage/file-usage.component';
import { t } from '../../../components/translate/translate.function';
import { snack, snackErr } from '../../../modules/snack.module';
import { RequestService } from '../request.service';
import { RequestDialogData, RequestDialogReturnData } from './request-dialog.types';

/*
// @todo THINGS TO DO
When opened, set ProjectId (with internal function), trust me on this one
Put it on "Big Case List", should be good
When saved, check from caller if it is the same ApartmentId before adding to list
Apartment + Field Selectors could be on the same line
*/

@Component({
  selector: 'apex-request-dialog',
  templateUrl: 'request-dialog.component.html',
})
export class RequestDialogComponent implements OnInit, OnDestroy {
  @ViewChild('files') files: FileUsageComponent;
  subs = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<RequestDialogComponent, RequestDialogReturnData>,
    @Inject(MAT_DIALOG_DATA) public data: RequestDialogData,
    private requestService: RequestService,
  ) {}

  ngOnInit(): void {
    this.apartmentChange(Number(this.data?.request?.ApartmentId || 0));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  save(): void {
    const sub = this.requestService
      .save(this.data.request)
      .pipe(
        take(1),
        map((response) => response.Entity),
        mergeMap((r) => {
          this.files.selfId = r.id;

          return this.files.saveAll().pipe(
            toArray(),
            map((_) => r),
          );
        }),
      )
      .subscribe({
        next: (r) => {
          if (r) {
            snack(t('Saved'));
            this.dialogRef.close(r);
          }
        },
        error: (err) => snackErr(t('Could not save'), err),
      });

    this.subs.add(sub);
  }

  apartmentChange(apartmentId?: number): void {
    if (!apartmentId) {
      if (this.data?.request) {
        this.data.request.ProjectId = null;
      }

      return;
    }

    const apartment = this.data?.mainObjects?.find((a) => String(a.id) === String(apartmentId));

    if (apartment && this.data?.request) {
      this.data.request.ProjectId = String(apartment.Project.id);
      this.data.request.CustomerId = apartment.Project.Company.id;
    }
  }
}
