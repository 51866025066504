<section class="df jsb w-100">
  <div class="df f1 c afs amr">
    <T str="excl. VAT"></T>
    {{ totals.priceExcludingVAT | apexCurrency: 'NOK' : '' }}
  </div>

  <div class="df f1 c ac amr">
    <T str="VAT"></T>
    {{ totals.priceVAT | apexCurrency: 'NOK' : '' }}
  </div>

  <div class="df f1 c afe">
    <T str="incl. VAT"></T>
    {{ totals.priceIncludingVAT | apexCurrency: 'NOK' : '' }}
  </div>
</section>
