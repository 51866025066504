import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { ResponseFileDto, ResponseFileUsageDto } from '../../../../../../generated/apex-rest';
import { FilePreviewModule } from '../file-preview/file-preview.module';
import { FileUsageViewerDialogComponent } from '../file-usage-viewer/file-usage-viewer-dialog.component';
import { FileUsageViewerMode } from '../file-usage-viewer/file-usage-viewer.types';
import { TextClampComponent } from '../text-clamp/text-clamp.component';

@Component({
  selector: 'apex-grid-gallery',
  templateUrl: './grid-gallery.component.html',
  styleUrls: ['./grid-gallery.component.scss'],
  standalone: true,
  imports: [CommonModule, FilePreviewModule, MatCardModule, TextClampComponent],
})
export default class GridGalleryComponent implements OnInit {
  @Input() images: ResponseFileUsageDto[] = [];
  @Input() showName = false;
  @Input() countLimit = 3;
  @Input() onlyImages = false;

  @Input() showPreview = false;

  @Input() smallGrid = false;

  threeColumn = false;

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    if (this.onlyImages) {
      this.images = this.images.filter((file) => file.File.type.includes('image'));
    }
  }

  openPreview(image: ResponseFileDto): void {
    this.dialog.open(FileUsageViewerDialogComponent, {
      data: {
        editable: false,
        client: false,
        mode: FileUsageViewerMode.View,
        fileUsages: this.images,
        startingIndex: this.images.map((f) => f.File.id).indexOf(image.id),
      },
    });
  }
}
