import { Pipe, PipeTransform } from '@angular/core';
import { nameCompare } from '../../../../../utils/functions';

type SortableObject = { id: number; name: string; ParentId?: number; depth?: number };

@Pipe({
  name: 'sortHierarchy',
  standalone: true,
})
export class SortHierarchyPipe implements PipeTransform {
  transform<T extends SortableObject>(objects: T[]): (T & { depth: number })[] {
    const sortedObjects = objects?.sort((a, b) => {
      const aParentId = a.ParentId || 0;
      const bParentId = b.ParentId || 0;

      if (a.ParentId === b.ParentId) {
        return nameCompare(a, b);
      }

      return aParentId - bParentId;
    });

    const tree = this.buildTree(sortedObjects);

    return this.flattenTree(tree);
  }

  buildTree<T extends SortableObject>(objects: T[]): ObjTree<T>[] {
    const idToNodeMap = new Map<number, ObjTree<T>>();
    const roots: ObjTree<T>[] = [];

    if (objects) {
      objects.forEach((object) => {
        const node = new ObjTree<T>(object);

        idToNodeMap.set(object.id, node);

        if (!object.ParentId) {
          roots.push(node);
        }

        if (object.ParentId) {
          const parent = idToNodeMap.get(object.ParentId);
          const child = idToNodeMap.get(object.id);

          if (parent && child) {
            child.depth = parent.depth + 1;
            parent.children.push(child);
          }
        }
      });
    }

    return roots;
  }

  flattenTree<T>(roots: ObjTree<T>[]): (T & { depth: number })[] {
    const result: (T & { depth: number })[] = [];

    const traverse = (node: ObjTree<T>): void => {
      result.push({ ...node.item, depth: node.depth });

      node.children.forEach((child) => traverse(child));
    };

    roots.forEach((root) => traverse(root));

    return result;
  }
}

class ObjTree<T> {
  item: T;
  children: ObjTree<T>[] = [];
  depth: number;

  constructor(item: T) {
    this.item = item;
    this.depth = 1;
  }
}
