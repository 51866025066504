import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { t } from '../../components/translate/translate.function';
import { Tenancy } from '../../models/tenancy';
import { Product } from '../object/safety/safety.model';

@Component({
  selector: 'apex-portal',
  templateUrl: 'portal.component.html',
})
export class PortalComponent implements OnInit, OnDestroy {
  get title(): string {
    return t('Portal');
  }

  tenancies: Tenancy[] = [];
  products: Product[] = [];

  private subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const sub = this.route.data.subscribe({
      next: (d) => {
        this.tenancies = d.tenancies;
        this.products = d.products;
      },
    });

    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
