import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ResponsePortalFileUsageDto } from '../../../../../../generated/apex-rest';
import { PortalService } from '../../portal.service';

export const PortalApartmentBannerImagesResolver: ResolveFn<ResponsePortalFileUsageDto[]> = (
  route: ActivatedRouteSnapshot,
): Promise<ResponsePortalFileUsageDto[]> => {
  const service = inject(PortalService);

  const projectId = Number(route.paramMap.get('ProjectId'));
  const apartmentId = Number(route.paramMap.get('ApartmentId'));

  return service.readProjectApartmentBannerImages(projectId, apartmentId);
};
