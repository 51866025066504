import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';

import { PostalOutputDirective } from './postal-output.directive';
import { PostalDirective } from './postal.directive';

@NgModule({
  declarations: [PostalDirective, PostalOutputDirective],
  exports: [PostalDirective, PostalOutputDirective],
  imports: [CommonModule, MatInputModule],
  providers: [],
})
export class PostalDirectiveModule {}
