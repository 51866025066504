import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, from } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ResponsePortalCaseDto,
  ResponsePortalCaseLogDto,
  ResponsePortalCaseLogDtoPageResponse,
} from '../../../../../../../generated/apex-rest';
import { PortalService } from '../../../portal.service';

interface DialogData {
  caseId: number;
  apartmentId: number;
  projectId: number;
  caseLogs: Promise<ResponsePortalCaseLogDtoPageResponse>;
  markProjectApartmentCaseRead: boolean;
  caseItem: ResponsePortalCaseDto;
}

@Component({
  selector: 'portal-portal-case-view-dialog',
  templateUrl: './portal-case-view-dialog.component.html',
})
export class PortalCaseViewDialogComponent implements OnInit, OnDestroy {
  caseId: number;
  projectId: number;
  apartmentId: number;
  caseLogs: ResponsePortalCaseLogDtoPageResponse;
  markProjectApartmentCaseRead: boolean;

  viewCase: ResponsePortalCaseDto;
  responseCaseLogs: Array<ResponsePortalCaseLogDto>;

  private subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<PortalCaseViewDialogComponent>,
    private portalService: PortalService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    dialogRef.addPanelClass(['apex-fullscreen-dialog', 'phone']);
  }

  async ngOnInit(): Promise<void> {
    this.caseId = this.data.caseId;
    this.apartmentId = this.data.apartmentId;
    this.projectId = this.data.projectId;
    this.responseCaseLogs = (await this.data.caseLogs).Collection;
    this.markProjectApartmentCaseRead = this.data.markProjectApartmentCaseRead;
    this.viewCase = this.data.caseItem;

    const caseLogs = await this.data.caseLogs;

    this.responseCaseLogs = caseLogs.Collection;
    this.subscription.add(
      from(this.portalService.markProjectApartmentCaseRead(this.projectId, this.apartmentId, this.caseId))
        .pipe(map((data) => data))
        .subscribe({
          next: (data) => {
            this.markProjectApartmentCaseRead = data;
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
