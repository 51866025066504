import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { InViewDirectiveModule } from '../../../directives/in-view/in-view.directive.module';
import { FilePreviewModule } from '../../file-preview/file-preview.module';
import { FileViewerModule } from '../../file-viewer/file-viewer.module';
import { TranslateModule } from '../../translate/translate.module';

import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { FileSizePipeModule } from '../../../pipes/file-size/file-size.module';
import { FolderViewerComponent } from './viewer.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,

    MatIconModule,
    MatTooltipModule,
    MatRippleModule,
    MatButtonModule,

    FilePreviewModule,
    TranslateModule,
    InViewDirectiveModule,
    FileViewerModule,
    MatTableModule,
    FileSizePipeModule,
    MatSortModule,
  ],
  exports: [FolderViewerComponent],
  declarations: [FolderViewerComponent],
  providers: [],
})
export class FolderViewerModule {}
