import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { t } from '../../../components/translate/translate.function';
import { snack, snackErr } from '../../../modules/snack.module';
import { PortalProductService } from './product.service';

export class ProductRequestData {
  name: string;
  description: string;
}

@Component({
  selector: 'apex-new-product-request-dialog',
  templateUrl: './new-request-dialog.component.html',
})
export class ProductRequestDialogComponent implements OnDestroy {
  requestData = new ProductRequestData();

  private subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<ProductRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { productId: number },
    private service: PortalProductService,
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  save(): void {
    if (this.requestData?.name) {
      this.subscription.add(
        this.service.postRequest(this.data?.productId, this.requestData).subscribe({
          next: (_) => {
            snack(t('Saved'));
            this.dialogRef.close();
          },
          error: (err) => snackErr(t('Could not save'), err),
        }),
      );
    }
  }
}
