<apex-client-addon-breadcrumb
  [cart]="cart"
  [apartment]="apartment"
  [overview]="true">
</apex-client-addon-breadcrumb>

<apex-client-addon-overview
  [addonCategories]="addonCategories"
  [projectText]="projectText">
</apex-client-addon-overview>

<button
  mat-raised-button
  class="amb"
  (click)="back()">
  <T str="Back"></T>
</button>
