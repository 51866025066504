import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { CaseListItemWrapperComponent } from './case-list-item-wrapper.component';

@NgModule({
  declarations: [CaseListItemWrapperComponent],
  imports: [CommonModule, MatDividerModule],
  exports: [CaseListItemWrapperComponent],
})
export class CaseListItemWrapperModule {}
