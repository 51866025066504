import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BoxModule } from '../../../../../apex/src/app/components/box/box.module';
import { FolderViewerModule } from '../../../../../apex/src/app/components/folder/viewer/viewer.module';
import { PageInfoModule } from '../../../../../apex/src/app/components/page-info/page-info.module';
import { TranslateModule } from '../../../../../apex/src/app/components/translate/translate.module';
import { ApartmentMOMService } from '../../../../../apex/src/app/features/apartment-mom/apartment-mom.service';
import { PortalMOMViewerComponent } from './portal-mom-viewer.component';

@NgModule({
  imports: [
    CommonModule,

    TranslateModule,
    FolderViewerModule,
    PageInfoModule,
    BoxModule,

    MatIconModule,
    MatButtonModule,
  ],
  exports: [PortalMOMViewerComponent],
  declarations: [PortalMOMViewerComponent],
  providers: [ApartmentMOMService],
})
export class PortalMOMViewerModule {}
