import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { orderBy } from 'lodash-es';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponsePortalArticleDto, ResponsePortalArticleDtoPageResponse } from '../../../../../../generated/apex-rest';
import { ViewData } from '../../../../../apex/src/app/components/article-view-card/article-view-card.types';
import { Apartment } from '../../../../../apex/src/app/models/apartment';
import { ClientPageInfo } from '../../../../../apex/src/app/models/client-page-info';
import { UserService } from '../../../../../apex/src/app/services/user/user.service';
import { CollectionResponse } from '../../../../../apex/src/app/utils/types';

@Component({
  selector: 'portal-news',
  templateUrl: './news.component.html',
})
export class NewsComponent implements OnInit, OnDestroy {
  clientPageInfos: ClientPageInfo[] = [];
  articles: ResponsePortalArticleDto[] = [];

  viewDatas: ViewData[] = [];

  apartment: Apartment;

  limit = 100;
  page = 0;
  count = 0;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.data
        .pipe(
          map(
            (data) =>
              [data.clientPageInfos, data.apartment, data.articles] as [
                CollectionResponse<ClientPageInfo>,
                Apartment,
                ResponsePortalArticleDtoPageResponse,
              ],
          ),
          map(([clientPageInfoRes, apartment, articleRes]) => {
            if (articleRes?.meta?.itemCount > clientPageInfoRes?.count) {
              this.page = articleRes?.meta?.page ?? 0;
            } else {
              this.page = clientPageInfoRes?.page;
            }

            this.count = (clientPageInfoRes?.count ?? 0) + (articleRes?.meta?.itemCount ?? 0);

            this.apartment = apartment;

            return {
              clientPageInfos: clientPageInfoRes?.Collection ?? [],
              articles: articleRes?.Collection ?? [],
            };
          }),
        )
        .subscribe({
          next: ({ clientPageInfos, articles }) => {
            const currentUser = this.userService.profile;

            if (!currentUser) {
              throw new Error('No current user');
            }

            this.clientPageInfos = clientPageInfos.filter((info) => {
              const isSameProject = info.ProjectId === this.apartment.ProjectId;
              const isSameApartment = info.CustomerId === currentUser.CustomerId;

              return isSameProject || isSameApartment;
            });
            this.articles = articles;

            const tempViewDatas = [...this.clientPageInfos, ...this.articles].map<ViewData>((data) => {
              const viewData: ViewData = {};

              let momentDate = null;

              if ('lead' in data) {
                viewData.lead = data.lead;
                viewData.content = data.content;
                viewData.images = data.Images;
                viewData.files = data.Files;
                momentDate = moment(data.publishedDate);
              }

              if ('text' in data) {
                viewData.content = data.text;
                momentDate = moment.unix(data.date);
              }

              if (momentDate.isValid()) {
                viewData.postedMoment = momentDate;
                viewData.postedDate = momentDate.format('Do MMMM YYYY');
              }

              return viewData;
            });

            this.viewDatas = orderBy(tempViewDatas, ['postedMoment'], ['desc']);
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changePage(event: PageEvent): void {
    this.page = event.pageIndex;
    this.limit = event.pageSize;

    this.updateQueryParams();
  }

  private updateQueryParams(): void {
    const queryParams: Params = {
      page: this.page,
      limit: this.limit,
      ProjectId: this.apartment?.ProjectId,
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }
}
