import { Injectable } from '@angular/core';
import {
  Order,
  PageOptionsDefaultOrderBy,
  PortalApi,
  ResponsePortalCaseDto,
  ResponsePortalCaseDtoPageResponse,
} from '../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../../../../apex/src/app/utils/rest-axios-config';

@Injectable()
export class PortalCasesService {
  constructor() {}

  private readonly portalApi = new PortalApi(restAxiosConfig());

  cases: ResponsePortalCaseDto[] = [];

  async readCollection(
    search?: string,
    apartmentId?: number,
    page = 0,
    limit = 25,
  ): Promise<ResponsePortalCaseDtoPageResponse> {
    const pageObject = {
      page,
      limit,
      order: Order.Desc,
      orderBy: PageOptionsDefaultOrderBy.CreatedAt,
      search: search ?? '',
      ApartmentId: apartmentId,
    };

    const data = await this.portalApi.portalCaseControllerReadCollection(
      pageObject.page,
      pageObject.limit,
      pageObject.order,
      pageObject.orderBy,
      pageObject.ApartmentId,
      pageObject.search,
    );

    this.cases = data.data.Collection;

    return data.data;
  }

  async readCollectionForApartment(
    projectId?: number,
    apartmentId?: number,
    page = 0,
    limit = 25,
  ): Promise<ResponsePortalCaseDtoPageResponse> {
    const pageObject = {
      page,
      limit,
      order: Order.Desc,
      orderBy: PageOptionsDefaultOrderBy.CreatedAt,
    };

    const data = await this.portalApi.portalCaseControllerReadCollection(
      pageObject.page,
      pageObject.limit,
      pageObject.order,
      pageObject.orderBy,
    );

    if (projectId !== undefined && apartmentId !== undefined) {
      data.data.Collection = data.data.Collection.filter(
        (caseItem) =>
          Number(caseItem.ProjectId) === Number(projectId) && Number(caseItem.ApartmentId) === Number(apartmentId),
      );
    }

    return data.data;
  }
}
