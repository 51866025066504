import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatPaginatorModule } from '@angular/material/paginator';
import ArticleViewCardComponent from '../../../../../apex/src/app/components/article-view-card/article-view-card.component';
import { FileUsageAttachmentsModule } from '../../../../../apex/src/app/components/file-usage-attachments/file-usage-attachments.module';
import { FileUsageCarouselModule } from '../../../../../apex/src/app/components/file-usage-carousel/file-usage-carousel.module';
import { TranslateModule } from '../../../../../apex/src/app/components/translate/translate.module';
import { NewsComponent } from './news.component';

@NgModule({
  declarations: [NewsComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatPaginatorModule,
    FileUsageCarouselModule,
    MatDividerModule,
    FileUsageAttachmentsModule,
    TranslateModule,
    ArticleViewCardComponent,
  ],
})
export class NewsModule {}
