<div class="f1 df p-c">
  <div
    class="image"
    (click)="openFileUsages()"
    mat-ripple>
    <apex-file-usage-preview
      *ngIf="fileUsages"
      [fileUsages]="fileUsages"
      [showCaption]="true">
    </apex-file-usage-preview>
    <mat-icon *ngIf="fileUsages?.length">zoom_in</mat-icon>
  </div>
  <div class="f1 ass df c jsb">
    <div class="df c afs am hyphens">
      <div class="mat-h2 ambs">{{ addonApartment.Addon.name }}</div>
      <div class="mat-caption ambs">
        {{
          addonApartment?.Addon?.AddonCategory?.Parent ? addonApartment?.Addon?.AddonCategory?.Parent?.name + ' - ' : ''
        }}
        {{ addonApartment?.Addon?.AddonCategory?.name }}
      </div>
      <div
        class="dib mat-caption expires ambs"
        [ngClass]="
          hasExpired
            ? 'gray-color'
            : isLastDayOrWeek === 'day'
              ? ' error-color'
              : isLastDayOrWeek === 'week'
                ? 'warning-color'
                : 'gray-color'
        "
        [matTooltip]="addonApartment.dateEnd | date: 'shortDate'">
        <T
          str="Expired"
          *ngIf="hasExpired"></T>
        <T
          str="Expires {timeLeft}"
          *ngIf="!hasExpired"
          [vars]="{ timeLeft }"></T>
      </div>
      <div
        class="mat-body-2 ambs"
        *ngIf="addonApartment?.Addon?.description">
        <span class="white-space-collapse-preserve-spaces">{{ addonApartment.Addon.description }}</span>
      </div>
      <ng-container *ngIf="addonApartment?.Addon?.Traits?.length">
        <h3>
          <T str="Traits"></T>
        </h3>

        <ng-container *ngFor="let trait of addonApartment?.Addon?.Traits">
          <div class="df c ambs">
            <span class="mat-caption">
              {{ trait.name }}
            </span>
            {{ trait.value }}
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="addonApartment?.Addon?.Documents as Documents">
        <ng-container *ngIf="Documents.length">
          <h3>
            <T str="Documents"></T>
          </h3>

          <ng-container *ngFor="let document of Documents">
            <a
              mat-button
              color="primary"
              (click)="openFileUsage(Documents, document)">
              {{ document?.fileName ?? '' }}
            </a>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="fdvs.length">
        <h3>
          <T str="MOM"></T>
        </h3>

        <div class="df c afs">
          <ng-container *ngFor="let fdv of fdvs">
            <a
              mat-button
              color="primary"
              (click)="openFileUsage(fdvs, fdv)">
              {{ fdv?.fileName ?? '' }}
            </a>
          </ng-container>
        </div>
      </ng-container>
      <div
        class="mat-caption ambs"
        *ngIf="floorplans && (addonApartment.markingMin !== 0 || addonApartment.markingMax !== 0)">
        <ng-container *ngIf="addonApartment.markingMin > 0; else canMark">
          <T
            str="When adding to your cart you are required to mark where you want `{addonName}`"
            [vars]="{ addonName: addonApartment?.Addon?.name }">
          </T>
        </ng-container>
        <ng-template #canMark>
          <T
            str="When adding to your cart you can mark where you want `{addonName}`"
            [vars]="{ addonName: addonApartment?.Addon?.name }">
          </T>
        </ng-template>
      </div>
    </div>
    <div class="prices am df">
      <div
        *ngIf="!addonApartment.standardForCategory"
        class="f1 df c">
        <div class="mat-h4 df jsb ambs">
          <T str="Total (excl. VAT)"></T>
          <span>{{ totals.priceExcludingVAT | apexCurrency: 'NOK' : '' }}</span>
        </div>
        <div class="mat-h4 df jsb ambs">
          <T str="VAT"></T>
          <span>{{ totals.priceVAT | apexCurrency: 'NOK' : '' }}</span>
        </div>
        <div class="mat-h3 df jsb">
          <strong>
            <T str="Total (incl. VAT)"></T>
          </strong>
          <strong>
            <span>{{ totals.priceIncludingVAT | apexCurrency: 'NOK' : '' }}</span>
          </strong>
        </div>
        <div *ngIf="editable && !hasExpired">
          <apex-textarea
            [label]="'Comment' | translate"
            [value]="comment"
            (debouncedChange)="updateComment($event)">
          </apex-textarea>
          <div class="df jfe ac">
            <div class="amr">
              <button
                *ngIf="floorplans && product && (addonApartment.markingMin !== 0 || addonApartment.markingMax !== 0)"
                mat-icon-button
                (click)="markingClicked()">
                <mat-icon
                  svgIcon="floorplan"
                  [matBadge]="addonApartment?.Markings?.length ?? 0"
                  matBadgeColor="primary">
                </mat-icon>
              </button>
            </div>
            <button
              *ngIf="!product"
              mat-raised-button
              color="primary"
              (click)="addProductToCart()">
              <ng-container
                *ngIf="
                  !product && floorplans && (addonApartment.markingMin !== 0 || addonApartment.markingMax !== 0);
                  else noMarking
                ">
                <T str="Mark and add to cart"></T>
              </ng-container>
              <ng-template #noMarking>
                <T str="Add to cart"></T>
              </ng-template>
            </button>
            <button
              *ngIf="product"
              mat-flat-button
              color="primary"
              (click)="remove.emit(addonApartment)">
              <T str="Remove from cart"></T>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="addonApartment.standardForCategory"
      class="am">
      <h4 class="standard rmv">
        <T str="Standard"></T>
      </h4>
    </div>
  </div>
</div>
