import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FileUsageAttachmentsModule } from '../file-usage-attachments/file-usage-attachments.module';
import { FileUsageCarouselModule } from '../file-usage-carousel/file-usage-carousel.module';
import { ViewData } from './article-view-card.types';

@Component({
  selector: 'apex-article-view-card',
  templateUrl: './article-view-card.component.html',
  standalone: true,
  imports: [CommonModule, MatCardModule, FileUsageCarouselModule, MatListModule, FileUsageAttachmentsModule],
})
export default class ArticleViewCardComponent implements OnInit {
  @Input() viewData: ViewData;

  innerHtml: SafeHtml = '';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    if (this.viewData.content) {
      this.innerHtml = this.sanitize(this.viewData.content);
    }
  }

  sanitize(url: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(url);
  }
}
