import { NgModule } from '@angular/core';
import { ChecklistSectionPreviewModule } from '../../components/checklist-section-preview/checklist-section-preview.module';
import { DateTimeModule } from '../../components/date-time/date-time.module';
import { FileUsageModule } from '../../components/file-usage/file-usage.module';
import { MarkingService } from '../../services/marking/marking.service';
import { ApartmentService } from '../apartment/apartment.service';
import { ObjectSelectorModule } from '../object/components/selector/object-selector.module';
import { ObjectModule } from '../object/object.module';
import { EntryCardModule } from '../object/project/tenancy/entry-card/entry-card.module';
import { TenancyModule } from '../object/project/tenancy/tenancy.module';
import { ProjectService } from '../project/project.service';
import { CaseCalendarComponent } from './calendar/calendar.component';
import { CaseCategoryService } from './case-category.service';
import { CaseRoutingModule } from './case-routing.module';
import { CaseService } from './case.service';
import { CasesComponent } from './cases/cases.component';
import { CaseFilterDialogComponent } from './filter/filter-dialog.component';
import { CaseFilterShortcutsComponent } from './filter/filter-shortcuts.component';
import { CaseFilterComponent } from './filter/filter.component';
import { CaseFormDialogComponent } from './form/dialog.component';
import { CaseFormComponent } from './form/form.component';
import { CaseListComponent } from './list/list.component';
import { CaseMassEditComponent } from './list/mass-edit.component';
import { CaseMarkingComponent } from './marking/marking.component';
import { CasesPageComponent } from './pages/cases/cases.component';
import { CasePrintPageComponent } from './pages/print/print.component';
import { RepeatableCaseService } from './repeatable-case.service';
import { CaseViewDialogComponent } from './view/dialog.component';
import { CaseViewComponent } from './view/view.component';

import { CaseObjectInfoComponent } from './object-info/case-object-info.component';
import { CaseStatusMessageDialogComponent } from './view/status-message-dialog.component';

import { MapModule } from '../../components/map/map.module';
import { ListArrayPipeModule } from '../../pipes/list-array/list-array.pipe.module';
import { AgreementModule } from '../object/project/agreement/agreement.module';

import { CalendarDayViewComponent } from './calendar/day.component';
import { CalendarWeekViewComponent } from './calendar/week.component';

import { ChecklistItemModule } from '../checklist/item/checklist-item.module';
import { CaseChecklistItemsComponent } from './checklist/case-checklist-items.component';
import { ContractorDialogComponent } from './contractor/form-dialog.component';

import { CaseNewMessageModule } from './new-message/new-message.module';

import { FilterService } from 'projects/apex/src/app/services/filter/filter.service';
import { ObjectGPSSearchButtonModule } from '../object/components/gps-search-button/gps-search-button.module';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChecklistSectionModule } from 'projects/apex/src/app/components/checklist-section/checklist-section.module';
import { ProgressModule } from 'projects/apex/src/app/components/progress/progress.module';
import { ObjectFieldService } from 'projects/apex/src/app/features/object/project/field/object-field.service';
import { AutocompletePickerModule } from '../../components/autocomplete-picker/autocomplete-picker.module';
import { AutocompleteModule } from '../../components/autocomplete/autocomplete.module';
import { AvatarModule } from '../../components/avatar/avatar.module';
import { BoxModule } from '../../components/box/box.module';
import { ButtonModule } from '../../components/button/button.module';
import { ColorPickerModule } from '../../components/color-picker/color-picker.module';
import { EmbeddedMarkingsViewerModule } from '../../components/embedded-markings-viewer/embedded-markings-viewer.module';
import { FileUsageViewerModule } from '../../components/file-usage-viewer/file-usage-viewer.module';
import { FilterChipsModule } from '../../components/filter-chips/filter-chips.module';
import { ItemModule } from '../../components/item/item.module';
import { PageInfoModule } from '../../components/page-info/page-info.module';
import { QrCodeModule } from '../../components/qr-code/qr-code.module';
import { SanitizeHtmlComponent } from '../../components/sanitize-html/sanitize-html.component';
import { TagChipsModule } from '../../components/tag-chips/tag-chips.module';
import { TextareaModule } from '../../components/textarea/textarea.module';
import { TranslateModule } from '../../components/translate/translate.module';
import { TranslatorModule } from '../../components/translator/translator.module';
import { AnalyticsClickDirective } from '../../directives/analytics/analytics-click.directive';
import { AutoFocusDirectiveModule } from '../../directives/auto-focus/auto-focus..directive.module';
import { InViewDirectiveModule } from '../../directives/in-view/in-view.directive.module';
import { UserCardModule } from '../../directives/user-card/user-card.module';
import { DaterModule } from '../../pipes/dater/dater.module';
import { FormatCaseLogMessagePipeModule } from '../../pipes/format-case-log-message/format-case-log-message.module';
import { IconPipeModule } from '../../pipes/icon/icon-pipe.module';
import { MarkedPipeModule } from '../../pipes/marked/marked.pipe.module';
import { ReduceHtmlToStringModule } from '../../pipes/reduct-html-to-string/reduce-html-to-string.module';
import {
  ChecklistTemplateItemService,
  ChecklistTemplateSectionService,
  ChecklistTemplateService,
} from '../checklist-template/checklist-template.service';
import { ObjectOwnershipService } from '../object/project/ownership/object-ownership.service';
import { CaseAddonOrderModule } from './addon-order/case-addon-order.module';
import { CaseContractorsModule } from './contractor/case-contractors.module';
import { ApartmentFieldNumbersForProjectPipe } from './filter/apartment-field-numbers-for-project.pipe';
import { CaseFormCaseOrRepeatableCaseFileUsageSelfIdPipe } from './form/pipes/case-form-case-or-repeatable-case-file-usage-self-id.pipe';
import { CaseFormCaseOrRepeatableCaseFileUsageSelfPipe } from './form/pipes/case-form-case-or-repeatable-case-file-usage-self.pipe';
import { CaseLogsModule } from './logs/case-logs.module';
import { MarkingDialogComponent } from './marking/dialog/marking-dialog.component';
import { CaseMeterInfoModule } from './meter/case-meter-info.module';
import { CategoryIsPublicForClientPipe } from './pipes/category-is-public-for-client.pipe';
import { ClientsFromCasePipe } from './pipes/clients-from-case.pipe';
import { ContractorsFromCasePipe } from './pipes/contractors-from-case.pipe';
import IsCaseDeclinedPipe from './pipes/is-case-declined.pipe';
import IsCaseOthersPipe from './pipes/is-case-others.pipe';
import IsCasePendingPipe from './pipes/is-case-pending.pipe';
import IsDateOverduePipe from './pipes/is-date-overdue.pipe';
import { QueryParamAsArrayPipe } from './pipes/query-param-as-array.pipe';
import {
  StatusColorForContractorPipe,
  StatusIconForContractorPipe,
  StatusMessageForContractorPipe,
} from './pipes/status-for-contractor.pipe';

@NgModule({
  declarations: [
    CasesComponent,
    CasesPageComponent,
    CasePrintPageComponent,
    CaseFilterComponent,
    CaseFilterShortcutsComponent,
    CaseFilterDialogComponent,
    CaseViewComponent,
    CaseViewDialogComponent,
    CaseListComponent,
    CaseMarkingComponent,
    CaseFormComponent,
    CaseFormDialogComponent,
    CaseMassEditComponent,
    MarkingDialogComponent,

    CaseObjectInfoComponent,
    CaseChecklistItemsComponent,

    CaseCalendarComponent,
    CalendarDayViewComponent,
    CalendarWeekViewComponent,

    ContractorDialogComponent,
    CaseStatusMessageDialogComponent,

    ContractorDialogComponent,
  ],
  imports: [
    CaseRoutingModule,

    CommonModule,
    FormsModule,

    CaseContractorsModule,

    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogModule,
    MatMenuModule,
    MatProgressBarModule,
    MatRippleModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatExpansionModule,
    PortalModule,
    MatDividerModule,
    MatInputModule,
    MatChipsModule,

    ObjectModule,
    FileUsageModule,
    ObjectSelectorModule,
    DateTimeModule,
    TenancyModule,
    EntryCardModule,
    AgreementModule,
    ObjectGPSSearchButtonModule,
    ListArrayPipeModule,
    MapModule,
    ChecklistItemModule,
    ChecklistSectionModule,
    ChecklistSectionPreviewModule,
    ProgressModule,
    CaseNewMessageModule,
    ClipboardModule,
    TranslateModule,
    AutocompleteModule,
    FilterChipsModule,
    TextareaModule,
    IconPipeModule,
    FileUsageViewerModule,
    PageInfoModule,
    MarkedPipeModule,
    TranslatorModule,
    EmbeddedMarkingsViewerModule,
    DaterModule,
    ColorPickerModule,
    AvatarModule,
    UserCardModule,
    CaseMeterInfoModule,
    CaseAddonOrderModule,
    InViewDirectiveModule,
    CaseContractorsModule,
    FormatCaseLogMessagePipeModule,
    CaseLogsModule,
    ColorPickerModule,
    QrCodeModule,
    CategoryIsPublicForClientPipe,

    ButtonModule,
    ItemModule,
    AutoFocusDirectiveModule,
    BoxModule,
    MatListModule,
    AutocompletePickerModule,
    TagChipsModule,

    AnalyticsClickDirective,
    ClientsFromCasePipe,
    ContractorsFromCasePipe,
    StatusMessageForContractorPipe,
    StatusColorForContractorPipe,
    StatusIconForContractorPipe,
    MatChipsModule,
    IsCasePendingPipe,
    IsCaseDeclinedPipe,
    IsDateOverduePipe,
    QueryParamAsArrayPipe,
    MatButtonToggleModule,
    MatChipsModule,
    CaseFormCaseOrRepeatableCaseFileUsageSelfPipe,
    CaseFormCaseOrRepeatableCaseFileUsageSelfIdPipe,
    ReduceHtmlToStringModule,
    SanitizeHtmlComponent,
    IsCaseOthersPipe,
    ApartmentFieldNumbersForProjectPipe,
  ],
  providers: [
    CaseService,
    CaseCategoryService,
    MarkingService,
    ProjectService,
    ApartmentService,
    RepeatableCaseService,
    FilterService,
    ObjectFieldService,
    ChecklistTemplateService,
    ChecklistTemplateSectionService,
    ChecklistTemplateItemService,
    ObjectOwnershipService,
  ],
  exports: [
    CasesComponent,
    CaseFilterComponent,
    CaseFilterShortcutsComponent,
    CaseFilterDialogComponent,
    CaseViewComponent,
    CaseViewDialogComponent,
    CaseListComponent,
    CaseMarkingComponent,
    CaseFormComponent,
    CaseFormDialogComponent,
    CaseMassEditComponent,
    CaseContractorsModule,
  ],
})
export class CaseModule {}
