import { ApplicationRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogData } from '../../components/confirm-dialog/confirm-dialog.types';
import { t } from '../../components/translate/translate.function';

@Injectable()
export class SwService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private dialog: MatDialog,
  ) {}

  init(): void {
    this.updates.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_DETECTED') {
        this.dialog
          .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
            data: {
              title: t('Update'),
              text: t('A new version of APEX is available.'),
              description: t('Update now?'),
              disableClose: true,
              timer: {
                val: true,
                dur: 60000,
              },
            },
          })
          .afterClosed()
          .pipe(
            filter((confirm: boolean) => confirm === true),
            mergeMap(() => this.updates.activateUpdate()),
          )
          .subscribe({
            next: () => {
              document.location.reload();
            },
          });
      }
    });

    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
  }
}
