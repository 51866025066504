<mat-card
  appearance="raised"
  mat-ripple
  (click)="emitClicked()">
  <mat-card-content>
    <div class="df r jsb ac">
      <div class="df c">
        <div class="df jfs ac">
          <div class="amr">
            <mat-icon svgIcon="project"></mat-icon>
          </div>
          <div>
            <h5 class="hyphens mat-h3 rmv">{{ apartment.Project.name }}</h5>
          </div>
        </div>
        <div class="df jfs ac">
          <div class="amr">
            <mat-icon svgIcon="apartment"></mat-icon>
          </div>
          <div>
            <h4 class="hyphens mat-h2 rmv">{{ apartment.name }}</h4>
          </div>
        </div>
        <div class="amts">
          <T
            str="{count, plural, one {# order} other {# orders} }"
            [vars]="{ count: apartment?.AddonOrders?.length ?? 0 }"
            _context="portal:addon"
            context="portal:addon">
          </T>
        </div>
      </div>
      <div class="df jfe ac">
        <button
          mat-icon-button
          [disabled]="!apartment.AddonCart || !apartment?.AddonCart?.Product?.length"
          (click)="cartClicked($event)">
          <mat-icon
            [matBadge]="apartment?.AddonCart?.Product?.length ?? 0"
            [matBadgeHidden]="!apartment?.AddonCart || !apartment?.AddonCart?.Product?.length">
            shopping_cart
          </mat-icon>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
