import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn } from '@angular/router';
import {
  ResponsePortalCaseDto,
  ResponsePortalCaseDtoPageResponse,
  ResponsePortalCaseLogDtoPageResponse,
} from '../../../../../../generated/apex-rest';
import { PortalService } from '../../portal.service';

export const PortalApartmentCasesResolver: ResolveFn<ResponsePortalCaseDtoPageResponse> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(PortalService);

  const projectId = Number(route.paramMap.get('ProjectId'));
  const apartmentId = Number(route.paramMap.get('ApartmentId'));

  const { caseOrderBy, caseOrder, casePage, caseLimit } = route.queryParams;

  const params: Params = {
    orderBy: caseOrderBy ?? 'createdAt',
    order: caseOrder ?? 'DESC',
    page: casePage ?? '0',
    limit: caseLimit ?? '100',
  };

  return service.readProjectApartmentCases(projectId, apartmentId, ...Object.values(params));
};

export const PortalApartmentCaseLogsResolver: ResolveFn<ResponsePortalCaseLogDtoPageResponse> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(PortalService);

  const projectId = Number(route.paramMap.get('ProjectId'));
  const apartmentId = Number(route.paramMap.get('ApartmentId'));
  const caseId = Number(route.paramMap.get('CaseId'));

  const { caseLogOrderBy, caseLogOrder, caseLogPage, caseLogLimit } = route.queryParams;

  const params: Params = {
    orderBy: caseLogOrderBy ?? 'createdAt',
    order: caseLogOrder ?? 'DESC',
    page: caseLogPage ?? '0',
    limit: caseLogLimit ?? '100',
  };

  return service.readProjectApartmentCaseLogs(projectId, apartmentId, caseId, ...Object.values(params));
};

export const PortalApartmentCaseResolver: ResolveFn<ResponsePortalCaseDto> = (route: ActivatedRouteSnapshot) => {
  const caseId = Number(route.paramMap.get('CaseId'));
  const cases = (route.parent?.data?.cases as ResponsePortalCaseDtoPageResponse)?.Collection ?? [];

  return cases.find((c) => Number(c.id) === caseId);
};

export const PortalApartmentMarkCaseReadResolver: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  const service = inject(PortalService);

  const projectId = Number(route.paramMap.get('ProjectId'));
  const apartmentId = Number(route.paramMap.get('ApartmentId'));
  const caseId = Number(route.paramMap.get('CaseId'));

  return service.markProjectApartmentCaseRead(projectId, apartmentId, caseId);
};

export const PortalMarkCaseReadResolver: ResolveFn<boolean> = async (route: ActivatedRouteSnapshot) => {
  const service = inject(PortalService);

  const caseId = Number(route.paramMap.get('CaseId'));

  const caseItem = route.parent.data.cases.Collection.find((c) => Number(c.id) === caseId);

  if (!caseItem) {
    return false;
  }

  const projectId = caseItem.ProjectId;
  const apartmentId = caseItem.ApartmentId;

  if (!projectId || !apartmentId) {
    throw new Error('Project id and apartment id is required to mark portal case as read');
  }

  const markAsRead = await service.markProjectApartmentCaseRead(projectId, apartmentId, caseId);

  return markAsRead;
};
