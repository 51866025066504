import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'projects/apex/src/app/models/addon-cart';
import { ITotals, getTotals, sumTotals } from 'projects/apex/src/app/utils/functions';

@Component({
  selector: 'apex-client-addon-cart-totals',
  templateUrl: './totals.component.html',
})
export class ClientAddonCartTotalsComponent implements OnInit {
  @Input() products: Product[];

  totals: ITotals;

  ngOnInit(): void {
    this.refreshTotals(this.products);
  }

  public refreshTotals(products: Product[]): void {
    const totals = [];

    products.forEach((p) => {
      totals.push(
        getTotals(p.AddonApartment.priceOut, p.AddonApartment.VAT, p.AddonApartment.unitPrice ? p.quantity : 1),
      );
    });
    this.totals = sumTotals(totals);
  }
}
