import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn } from '@angular/router';
import { Observable } from 'rxjs';
import { Request } from '../../models/request';
import { CollectionResponse } from '../../utils/types';
import { RequestService } from './request.service';

export const RequestsResolver: ResolveFn<CollectionResponse<Request>> = (
  route: ActivatedRouteSnapshot,
): Observable<CollectionResponse<Request>> => {
  const service = inject(RequestService);

  const ApartmentId = route.paramMap.get('ApartmentId');
  const { requestLimit, requestPage, requestOrder, requestOrderBy } = route.queryParams;

  const params: Params = {
    ApartmentId,
    limit: requestLimit ?? '100',
    page: requestPage ?? '0',
    order: requestOrder ?? 'DESC',
    orderBy: requestOrderBy ?? 'createdAt',
  };

  return service.query({
    params,
  });
};
