<ng-container *ngIf="profile && tenancy && objects">
  <apex-file-usage
    [disabled]="true"
    class="f1 am"
    view="List"
    [title]="'Files' | translate"
    self="tenancy"
    [selfId]="tenancy.id"
    name="public-files">
  </apex-file-usage>
</ng-container>
