<h1 mat-dialog-title>
  <T str="New request"></T>
</h1>
<div mat-dialog-content>
  <form
    #requestForm="ngForm"
    class="df c">
    <mat-form-field>
      <input
        matInput
        type="text"
        name="name"
        [placeholder]="'Name' | translate"
        [(ngModel)]="requestData.name"
        required />
    </mat-form-field>

    <mat-form-field>
      <textarea
        matInput
        name="description"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="10"
        [placeholder]="'Description' | translate"
        [(ngModel)]="requestData.description">
      </textarea>
    </mat-form-field>
  </form>
</div>

<div
  mat-dialog-actions
  class="df">
  <button
    mat-button
    mat-dialog-close>
    <T str="Cancel"></T>
  </button>
  <button
    mat-raised-button
    (click)="save()"
    color="primary"
    [disabled]="!requestForm.valid">
    <T str="Save"></T>
  </button>
</div>
