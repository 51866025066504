<div class="addon-card-wrapper df column">
  <div class="fileusage-wrapper">
    <apex-file-usage-preview
      class="pointer-events-disable"
      *ngIf="fileUsages$ | async as fileUsages"
      [fileUsages]="fileUsages">
    </apex-file-usage-preview>
  </div>
  <div class="f1 ap">
    <h4>{{addonOrder.Addon.name}}</h4>
    <div>{{addonOrder.priceOut}}</div>
    <div *ngIf="isStandard">
      <span><T str="Standard"></T></span>
    </div>
  </div>
</div>
