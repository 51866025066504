import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { FileUsageViewerDialogComponent } from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer-dialog.component';
import {
  FileUsageViewerData,
  FileUsageViewerMode,
} from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer.types';
import { Addon } from 'projects/apex/src/app/models/addon';
import { AddonCart } from 'projects/apex/src/app/models/addon-cart';
import { AddonOrder } from 'projects/apex/src/app/models/addon-order';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { getTotals, sumTotals } from 'projects/apex/src/app/utils/functions';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClientPageService } from '../../../client-page.service';

@Component({
  selector: 'apex-client-addon-breadcrumb',
  templateUrl: './breadcrumb.component.html',
})
export class ClientAddonBreadcrumbComponent implements OnInit, OnDestroy {
  @Input() apartment: Apartment;
  @Input() addon: Addon;
  @Input() inCart = false;
  @Input() addonOrder: AddonOrder;
  @Input() inOrder = false;
  @Input() inConfirmation = false;
  @Input() cart: AddonCart;
  @Input() editable = true;
  @Input() overview: boolean;

  fileUsages: FileUsage[];
  floorplans: FileUsage[];

  fileUsageViewerData: FileUsageViewerData;
  addonManager: boolean;

  inPortal = false;
  portalBaseUrl: string;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private clientService: ClientPageService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    const data = this.route.snapshot?.data;

    this.inPortal = data?.portal;

    if (this.inPortal) {
      const paramMap = this.route.snapshot.paramMap;
      const projectId = paramMap.get('ProjectId');
      const apartmentId = paramMap.get('ApartmentId');

      this.apartment = data.apartment;
      this.cart = data.cart;

      this.portalBaseUrl = `/project/${projectId}/apartment/${apartmentId}/addon`;
    }

    this.addonManager = data?.addonManager;
  }

  getFloorplans(openDialog: () => void): void {
    this.subscription.add(
      this.clientService
        .getFloorPlans(this.apartment)
        .pipe(take(1))
        .subscribe((floorplans: FileUsage[]) => {
          this.floorplans = floorplans;

          if (this.floorplans && this.floorplans.length) {
            this.fileUsageViewerData = {
              fileUsages: this.floorplans,
              mode: FileUsageViewerMode.Mark,
              editable: this.editable,
              client: true,
            };
          }

          openDialog();
        }),
    );
  }

  openFloorplans(): void {
    this.getFloorplans(() =>
      this.dialog.open(FileUsageViewerDialogComponent, {
        data: {
          mode: 'view',
          fileUsages: this.floorplans,
          editable: false,
          client: true,
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get cartAmount(): number {
    const totals = [];

    this.cart?.Product.forEach((p) => {
      totals.push(
        getTotals(p.AddonApartment.priceOut, p.AddonApartment.VAT, p.AddonApartment.unitPrice ? p.quantity : 1),
      );
    });

    return sumTotals(totals)?.priceIncludingVAT;
  }

  get cartUrl(): (string | number)[] {
    if (this.addonManager) {
      return ['/project', this.apartment.ProjectId, 'addons', 'apartment', this.apartment.id, 'client', 'cart'];
    }

    if (this.inPortal) {
      return [this.portalBaseUrl, 'cart'];
    }

    return ['/minside', 'tilvalg', this.apartment.id, 'cart'];
  }
}
