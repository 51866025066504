import { Component, Input } from '@angular/core';
import { t } from '../translate/translate.function';

@Component({
  selector: 'apex-loader',
  templateUrl: './loader.component.html',
})
export class LoaderComponent {
  @Input() loading = false;
  @Input() text: string = t('Loading');
  @Input() overlay = false;
}
