import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { AddonCart } from 'projects/apex/src/app/models/addon-cart';
import { AddonCategory } from 'projects/apex/src/app/models/addon-category';
import { AddonOrder } from 'projects/apex/src/app/models/addon-order';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { Project } from 'projects/apex/src/app/models/project';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ClientPageService } from './client-page.service';

export const ClientGetAddonCategoryResolver: ResolveFn<AddonCategory> = (route: ActivatedRouteSnapshot) => {
  const clientService = inject(ClientPageService);

  return clientService.getAddonCategoryByAddonApartment(+route.paramMap.get('AddonApartmentId'));
};

export const ClientGetAddonOrderResolver: ResolveFn<AddonOrder> = (route: ActivatedRouteSnapshot) => {
  const clientService = inject(ClientPageService);

  return clientService.getAddonOrder(+route.paramMap.get('AddonOrderId'));
};

export const ClientQueryAddonOrderResolver: ResolveFn<AddonOrder[]> = () => {
  const clientService = inject(ClientPageService);

  return clientService.queryAddonOrder();
};

// Apartment

export const ClientGetApartmentResolver: ResolveFn<Apartment> = (route: ActivatedRouteSnapshot) => {
  const clientService = inject(ClientPageService);

  return clientService.getApartment(+route.paramMap.get('ApartmentId'));
};

export const ClientQueryApartmentWithAddonsResolver: ResolveFn<Apartment[]> = () => {
  const clientService = inject(ClientPageService);

  return clientService.queryApartmentWithAddons();
};

export const ClientGetApartmentWithAddonsResolver: ResolveFn<Apartment> = (route: ActivatedRouteSnapshot) => {
  const clientService = inject(ClientPageService);

  return clientService.getApartmentWithAddons(+route.paramMap.get('ApartmentId'));
};

// Project

export const ClientGetProjectWithAddonOrdersResolver: ResolveFn<Project[]> = () => {
  const clientService = inject(ClientPageService);

  return clientService.getProjectsWithAddonOrders();
};

// AddonCart

export const ClientGetAddonCartResolver: ResolveFn<AddonCart> = (
  route: ActivatedRouteSnapshot,
): Observable<AddonCart> => {
  const clientService = inject(ClientPageService);

  return clientService.getAddonCart(+route.paramMap.get('ApartmentId')).pipe(catchError(() => of(undefined)));
};

// AddonApartment

export const ClientGetAddonApartmentResolver: ResolveFn<AddonApartment> = (route: ActivatedRouteSnapshot) => {
  const clientService = inject(ClientPageService);

  return clientService.getAddonApartment(+route.paramMap.get('AddonApartmentId'));
};

export const ClientQueryAddonCategoryByApartmentResolver: ResolveFn<AddonCategory[]> = (
  route: ActivatedRouteSnapshot,
) => {
  const clientService = inject(ClientPageService);

  return clientService.queryAddonCategoryByApartment(+route.paramMap.get('ApartmentId'), true);
};

export const ClientQueryAllAddonCategoryByApartmentResolver: ResolveFn<AddonCategory[]> = (
  route: ActivatedRouteSnapshot,
) => {
  const clientService = inject(ClientPageService);

  return clientService.queryAddonCategoryByApartment(+route.paramMap.get('ApartmentId'), false);
};

export const ClientConfirmationTextResolver: ResolveFn<{ content: string; title: string }> = (
  route: ActivatedRouteSnapshot,
) => {
  const clientService = inject(ClientPageService);

  return clientService.getConfirmationText(+route.paramMap.get('ApartmentId'));
};

export const ClientProjectTextResolver: ResolveFn<{ content: string; title: string }> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(ClientPageService);

  const apartmentId = Number(route.paramMap.get('ApartmentId'));

  return service
    .getApartment(apartmentId)
    .pipe(mergeMap((apartment: Apartment) => service.getProjectText(apartment.ProjectId)));
};
