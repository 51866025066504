import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Product } from 'projects/apex/src/app/models/addon-cart';

@Component({
  selector: 'apex-client-addon-cart-product-list',
  templateUrl: './product-list.component.html',
})
export class ClientAddonCartProductListComponent {
  @Input() products: Product[];
  @Input() editable = false;
  @Input() addonManager = false;

  @Output() remove = new EventEmitter<Product>();
  @Output() update = new EventEmitter<Product>();
}
