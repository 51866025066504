import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { AddonCart } from 'projects/apex/src/app/models/addon-cart';
import { AddonCategory } from 'projects/apex/src/app/models/addon-category';
import { sortAddonCategories } from '../../../../addon/functions';

@Component({
  selector: 'apex-client-addon-apartment-tree',
  templateUrl: './tree.component.html',
})
export class ClientAddonApartmentTreeComponent implements OnInit {
  @Input() public level = 0;
  @Input() public list: AddonCategory[] = [];
  @Input() public children: AddonCategory[] = [];
  @Input() public cart: AddonCart;

  @Output() addonClicked = new EventEmitter<AddonApartment>();
  @Output() add = new EventEmitter<{ addonApartment: AddonApartment; comment: string }>();
  @Output() remove = new EventEmitter<AddonApartment>();

  nextLevel: number;

  dragging = false;

  ngOnInit(): void {
    this.nextLevel = this.level + 1;

    if (this.children) {
      this.children = sortAddonCategories(this.children);
    }
  }
}
