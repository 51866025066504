<div
  *ngIf="!empty"
  [attr.id]="addonCategory?.name"
  class="category-wrapper"
  [class.space]="level === 1">
  <div
    #header
    class="category df c"
    (apexInView)="categoryInView($event, addonCategory?.id)">
    <div
      class="image-name-wrapper"
      [ngClass]="{
        amb: !addonCategoryProject?.description,
        'no-image': !addonCategoryProject?.FileUsages?.length,
      }">
      <div
        *ngIf="level === 1 && addonCategoryProject?.FileUsages?.length"
        class="header level1">
        <apex-file-usage-preview
          [fileUsages]="addonCategoryProject.FileUsages"
          [navigation]="false">
        </apex-file-usage-preview>
      </div>
      <div
        *ngIf="level !== 1 && addonCategoryProject?.FileUsages?.length"
        class="header">
        <apex-file-usage-preview
          [fileUsages]="addonCategoryProject.FileUsages"
          [navigation]="false">
        </apex-file-usage-preview>
      </div>
      <h3
        class="rmb rmt category-title df c"
        [class.aml]="level !== 1 && addonCategoryProject?.FileUsages?.length"
        [ngClass]="'level' + (level - 1)"
        [class.category-title-move]="addonCategoryProject?.FileUsages?.length">
        {{ addonCategory?.name }}
      </h3>
    </div>
    <p
      class="white-space-pre-line amv"
      *ngIf="addonCategoryProject?.description">
      {{ addonCategoryProject.description }}
    </p>
  </div>
  <div class="addon-cards-container">
    <ng-container *ngFor="let addon of addonCategory?.Addons">
      <apex-client-addon-apartment-card
        class="mat-elevation-z2"
        [addonApartment]="addon.AddonApartments[0]"
        [currentSelected]="selectedAddonApartment && addon.id === selectedAddonApartment.AddonId"
        [comment]="selectedAddonApartment && addon.id === selectedAddonApartment.AddonId ? comment : ''"
        (addonClicked)="addonClicked.emit($event)"
        (add)="add.emit($event)"
        (remove)="setSelectedToStandard(); remove.emit($event)">
      </apex-client-addon-apartment-card>
    </ng-container>
  </div>
  <apex-client-addon-apartment-tree
    *ngIf="children"
    [level]="level"
    [list]="list"
    [children]="children"
    [cart]="cart"
    (addonClicked)="addonClicked.emit($event)"
    (add)="add.emit($event)"
    (remove)="remove.emit($event)">
  </apex-client-addon-apartment-tree>
</div>
