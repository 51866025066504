import { Component, Input } from '@angular/core';
import { Product } from '../../safety.model';

@Component({
  selector: 'apex-products',
  templateUrl: 'products.component.html',
})
export class ProductsComponent {
  @Input() products: Product[] = [];
  @Input() expanded = true;
}
