import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateModule } from '../../../../../../apex/src/app/components/translate/translate.module';
import { ClientPageAddonModule } from '../../../../../../apex/src/app/features/client-page/addon/client-page-addon.module';
import { AddonCategory } from '../../../../../../apex/src/app/models/addon-category';
import { PortalAddonOrderListModule } from '../order/list/portal-addon-order-list.module';
import { FilterAddonOrdersByCategoryPipe } from '../pipe/filter-addon-orders-by-category.pipe';
import { HasAddonsPipe } from '../pipe/has-addons.pipe';
import { StandardAddonForCategoryPipe } from '../pipe/standard-addon-for-category.pipe';
import { PortalAddonOrdersService } from '../service/portal-addon-orders.service';
import { PortalAddonOrderCardComponent } from './card/portal-addon-order-card';
import { AddonOrderCardData } from './types/addon-order-card-data.type';

@Component({
  selector: 'portal-addon-orders',
  templateUrl: './portal-addon-orders.html',
  styleUrls: ['./portal-addon-orders.scss'],
  standalone: true,
  imports: [
    ClientPageAddonModule,
    CommonModule,
    PortalAddonOrderListModule,
    PortalAddonOrderCardComponent,
    StandardAddonForCategoryPipe,
    TranslateModule,
    HasAddonsPipe,
    FilterAddonOrdersByCategoryPipe,
  ],
  providers: [PortalAddonOrdersService],
})
export class PortalAddonOrdersComponent implements OnInit, OnDestroy {
  constructor(private route: ActivatedRoute) {}

  addonApartments: AddonOrderCardData[] = [];
  addonOrders: AddonOrderCardData[] = [];
  addonCategories: AddonCategory[] = [];

  private subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.subscription.add(
      this.route.data.subscribe((data) => {
        this.addonApartments = data.addonApartments?.Collection;
        this.addonOrders = data.addonOrders?.Collection;
        this.addonCategories = data.addonCategories;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
