import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { FileUsageViewerDialogComponent } from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer-dialog.component';
import {
  FileUsageViewerCloseData,
  FileUsageViewerData,
  FileUsageViewerMode,
} from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer.types';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { Product } from 'projects/apex/src/app/models/addon-cart';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Marking, MarkingModelType } from 'projects/apex/src/app/models/marking';
import { ITotals, getTotals } from 'projects/apex/src/app/utils/functions';
import { Subject, Subscription } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';

@Component({
  selector: 'apex-client-addon-apartment-info',
  templateUrl: './info.component.html',
})
export class ClientAddonApartmentInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() addonApartment: AddonApartment;
  @Input() product: Product;
  @Input() editable = true;
  @Output() add = new EventEmitter<{ addonApartment: AddonApartment; comment: string }>();
  @Output() remove = new EventEmitter<AddonApartment>();
  @Output() clicked = new EventEmitter<AddonApartment>();

  fileUsages: FileUsage[];
  floorplans: FileUsage[];

  totals: ITotals;

  comment: string;
  hasExpired = false;

  fileUsageViewerData: FileUsageViewerData;

  isLastDayOrWeek: 'day' | 'week';
  timeLeft: string;

  fdv$ = new Subject<FileUsage[]>();
  fdvs: FileUsage[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private clientService: ClientPageService,
  ) {}

  ngOnInit(): void {
    this.getAddonImages();
    this.getFloorplans();
    this.getFdv();

    if (this.addonApartment.markingMax !== 0 || this.addonApartment.markingMin !== 0) {
      this.getAddonApartmentMarkings();
    } else {
      this.addonApartment.Markings = [];
    }

    this.getTotals();
    this.isOnLastDayOrWeek();
    this.calculateTimeLeft();
  }

  ngOnChanges(change: SimpleChanges): void {
    if (change.addonApartment) {
      this.ngOnInit();
    }

    if (this.product) {
      this.comment = this.product.ClientComment;
    }
  }

  getFdv(): void {
    const sub = this.fdv$.subscribe({
      next: (fus: FileUsage[]) => {
        if (fus.length) {
          this.fdvs = fus;
        }
      },
    });

    this.subscriptions.push(sub);

    if (this.addonApartment?.Addon) {
      this.clientService
        .getFdvs(this.addonApartment.Addon)
        .pipe(take(1))
        .subscribe({
          next: (fus: FileUsage[]) => {
            this.fdv$.next(fus);
          },
        });
    }
  }

  getFloorplans(): void {
    const sub = this.clientService
      .getFloorplanImage(this.addonApartment)
      .pipe(take(1))
      .subscribe((floorplans: FileUsage[]) => {
        this.floorplans = floorplans;

        if (this.floorplans && this.floorplans.length) {
          this.fileUsageViewerData = {
            fileUsages: this.floorplans,
            mode: FileUsageViewerMode.Mark,
            editable: this.editable,
            client: true,
            modelData: {
              model: MarkingModelType.AddonApartment,
              modelId: this.addonApartment.id,
            },
            markingData: {
              min: this.addonApartment.markingMin,
              max: this.addonApartment.markingMax,
            },
          };
        }
      });

    this.subscriptions.push(sub);
  }

  markingClicked(): void {
    const sub: Subscription = this.clientService
      .getFloorplanImage(this.addonApartment)
      .pipe(
        take(1),
        mergeMap((floorplans: FileUsage[]) =>
          this.dialog
            .open(FileUsageViewerDialogComponent, {
              data: {
                mode: FileUsageViewerMode.Mark,
                fileUsages: floorplans,
                editable: true,
                client: true,
                showFileUsageViewerComment: true,
                fileUsageViewerComment: this.comment,
                modelData: {
                  model: MarkingModelType.AddonApartment,
                  modelId: this.addonApartment.id,
                },
                markingData: {
                  min: this.addonApartment.markingMin,
                  max: this.addonApartment.markingMax,
                },
                text: `Marker hvor du vil ha "${this.addonApartment.Addon.name}"`, // @todo Translate
              },
            })
            .afterClosed(),
        ),
      )
      .subscribe({
        next: (value: FileUsageViewerCloseData) => {
          sub?.unsubscribe();

          if (value?.valid) {
            this.getAddonApartmentMarkings();
            this.comment = value.comment;
            this.product
              ? this.updateProductInCart()
              : this.add.emit({
                  addonApartment: this.addonApartment,
                  comment: this.comment,
                });
          }
        },
      });
  }

  getTotals(): void {
    this.totals = getTotals(
      this.addonApartment.priceOut,
      this.addonApartment.VAT,
      this.addonApartment.unitPrice ? (this.addonApartment.Markings ? this.addonApartment.Markings.length || 1 : 1) : 1,
    );
  }

  updateComment(comment: string): void {
    this.comment = comment;
    this.updateProductInCart();
  }

  updateProductInCart(): void {
    if (this.product) {
      this.addProductToCart();
    }
  }

  addProductToCart(): void {
    if (
      !this.product &&
      this.floorplans &&
      (this.addonApartment.markingMin !== 0 || this.addonApartment.markingMax !== 0)
    ) {
      this.markingClicked();
    } else {
      this.add.emit({
        addonApartment: this.addonApartment,
        comment: this.comment,
      });
    }
  }

  isOnLastDayOrWeek(): void {
    const now = moment();
    const end = moment(this.addonApartment.dateEnd).endOf('day');
    const diff = end.diff(now, 'days');

    if (diff === 0) {
      this.isLastDayOrWeek = 'day';
    } else if (diff <= 7) {
      this.isLastDayOrWeek = 'week';
    } else {
      this.isLastDayOrWeek = null;
    }
  }

  calculateTimeLeft(): void {
    const now = moment();
    const end = moment(this.addonApartment.dateEnd).endOf('day');

    this.timeLeft = now.to(end);
    this.hasExpired = now.isAfter(end);
  }

  openFileUsages(): void {
    if (this.fileUsages?.length) {
      const data: FileUsageViewerData = {
        fileUsages: this.fileUsages,
        mode: FileUsageViewerMode.View,
        editable: false,
        client: true,
      };

      this.dialog.open(FileUsageViewerDialogComponent, { data });
    }
  }

  openFileUsage(fileUsages: FileUsage[], fileUsage?: FileUsage): void {
    if (fileUsages.length) {
      const data: FileUsageViewerData = {
        fileUsages,
        mode: FileUsageViewerMode.View,
        editable: false,
        client: true,
      };

      const idx = fileUsages.indexOf(fileUsage);

      if (idx !== -1) {
        data.startingIndex = idx;
      }

      this.dialog.open(FileUsageViewerDialogComponent, { data });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  private getAddonImages(): void {
    if (this.addonApartment && this.addonApartment.Addon) {
      const sub = this.clientService
        .getAddonImages(this.addonApartment.Addon)
        .pipe(take(1))
        .subscribe({
          next: (fus) => {
            this.fileUsages = fus;
          },
        });

      this.subscriptions.push(sub);
    }
  }

  private getAddonApartmentMarkings(): void {
    if (this.addonApartment) {
      const sub = this.clientService
        .getAddonApartmentMarkingsOnFloorplans(this.addonApartment)
        .pipe(take(1))
        .subscribe((markings: Marking[]) => {
          this.addonApartment.Markings = markings;
          this.getTotals();
        });

      this.subscriptions.push(sub);
    }
  }
}
