import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { FileUsage } from '../../../models/file-usage';
import { ClientPageDocumentsService } from './client-page-documents.service';

export const ClientPageDocumentsResolver: ResolveFn<FileUsage[]> = () => {
  const service = inject(ClientPageDocumentsService);

  return service.query();
};

export const ClientPageDocumentsForApartmentResolver: ResolveFn<FileUsage[]> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ClientPageDocumentsService);

  const apartmentId = Number(route.paramMap.get('ApartmentId'));

  return service.readApartmentDocuments(apartmentId);
};
