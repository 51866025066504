import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'projects/apex/src/app/components/avatar/avatar.module';
import { ModelRoleModule } from 'projects/apex/src/app/components/model-role/model-role.module';
import { PageInfoModule } from 'projects/apex/src/app/components/page-info/page-info.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { ProfileModule } from 'projects/apex/src/app/features/user/profile/profile.module';
import { PortalProfilePageComponent } from 'projects/portal/src/app/profile/portal-profile-page.component';

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule, PageInfoModule, ModelRoleModule, AvatarModule, ProfileModule],
  exports: [PortalProfilePageComponent],
  declarations: [PortalProfilePageComponent],
})
export class PortalProfilePageModule {}
