import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FileUsageModule } from '../../../../../../apex/src/app/components/file-usage/file-usage.module';
import { ItemModule } from '../../../../../../apex/src/app/components/item/item.module';
import { TranslateModule } from '../../../../../../apex/src/app/components/translate/translate.module';
import { PortalCaseLogModule } from '../log/portal-case-log.module';
import { PortalCaseViewDialogEntryComponent } from './portal-case-view-dialog-entry.component';
import { PortalCaseViewDialogComponent } from './portal-case-view-dialog.component';
import { PortalCaseViewComponent } from './portal-case-view.component';

@NgModule({
  declarations: [PortalCaseViewComponent, PortalCaseViewDialogComponent, PortalCaseViewDialogEntryComponent],
  imports: [
    CommonModule,
    ItemModule,
    MatListModule,
    FileUsageModule,
    PortalCaseLogModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
  ],
  exports: [PortalCaseViewDialogComponent],
})
export class PortalCaseViewModule {}
