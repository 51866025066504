import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponsePortalCaseDto, ResponsePortalCaseDtoPageResponse } from '../../../../../../../generated/apex-rest';

@Component({
  selector: 'portal-portal-case-list',
  templateUrl: './portal-case-list.component.html',
})
export class PortalCaseListComponent implements OnInit, OnDestroy {
  cases: ResponsePortalCaseDto[] = [];

  limit = 25;
  page = 0;
  count = 0;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.data
        .pipe(
          map((data) => data.cases as ResponsePortalCaseDtoPageResponse),
          map((res) => {
            this.page = res?.meta?.page;
            this.count = res?.meta?.itemCount;

            return res?.Collection ?? [];
          }),
        )
        .subscribe({
          next: (cases) => {
            this.cases = cases;
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changePage(event: PageEvent): void {
    this.page = event.pageIndex;
    this.limit = event.pageSize;

    this.updateQueryParams();
  }

  private updateQueryParams(): void {
    const queryParams: Params = {
      casePage: this.page,
      caseLimit: this.limit,
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }
}
