<h1>Addon Orders</h1>

<ng-container *ngFor="let category of addonCategories">
  <ng-container *ngIf="category.id | hasAddons:addonOrders:addonApartments">
    <h3 class="rmb">{{category.name}}</h3>
    <p>{{category.AddonCategoryProjects[0].description}}</p>
    <div class="display-grid-cards card-width-200">
      <ng-container *ngFor="let ao of addonOrders | filterAddonOrdersByCategory:category.id">
        <portal-addon-order-card [addonOrder]="ao"></portal-addon-order-card>
      </ng-container>

      <ng-container *ngFor="let aa of addonApartments | standardAddonForCategory:category.id">
        <portal-addon-order-card
          [addonOrder]="aa"
          [isStandard]="true"></portal-addon-order-card>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="!addonOrders.length">
  <p><T str="No addon orders"></T></p>
</ng-container>
