import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HammerTimeModule } from '../../directives/hammer-time/hammer-time.module';
import { FilePreviewModule } from '../file-preview/file-preview.module';
import { TranslateModule } from '../translate/translate.module';
import { FileUsageCarouselComponent } from './file-usage-carousel.component';

@NgModule({
  declarations: [FileUsageCarouselComponent],
  exports: [FileUsageCarouselComponent],
  imports: [CommonModule, TranslateModule, MatIconModule, MatTooltipModule, FilePreviewModule, HammerTimeModule],
})
export class FileUsageCarouselModule {}
