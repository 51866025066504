import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ItemModule } from '../../../components/item/item.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { CaseListItemWrapperModule } from '../../case/list-item-wrapper/case-list-item-wrapper.module';
import { RequestListComponent } from './request-list.component';

@NgModule({
  declarations: [RequestListComponent],
  imports: [
    CommonModule,
    CaseListItemWrapperModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    MatDividerModule,
    MatPaginatorModule,
    ItemModule,
    MatButtonModule,
    MatDialogModule,
  ],
  exports: [RequestListComponent],
})
export class RequestListModule {}
