import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ResponsePortalMainObjectDto } from '../../../../../generated/apex-rest';
import { FormattedAddressComponent } from '../../../../apex/src/app/components/adresses/formatted-address/formatted-address.component';
import { FirstVisitationAddressPipe } from '../../../../apex/src/app/pipes/first-visitation-address/first-visitation-address.pipe';
import { MainObjectPipe } from './main-object.pipe';

@Component({
  selector: 'portal-main-object-address',
  templateUrl: 'main-object-address.component.html',
  standalone: true,
  imports: [CommonModule, FormattedAddressComponent, FirstVisitationAddressPipe, MainObjectPipe],
})
export class MainObjectAddressComponent {
  @Input({ required: true })
  mainObject: ResponsePortalMainObjectDto;
}
