import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { t } from '../../../components/translate/translate.function';
import { snack, snackErr } from '../../../modules/snack.module';
import { EntryCard } from '../../object/project/tenancy/entry-card/entry-card.model';
import { EntryCardService } from '../../object/project/tenancy/entry-card/entry-card.service';

@Component({
  selector: 'apex-entry-card-request-delete-dialog',
  templateUrl: './entry-card-request-delete-dialog.component.html',
})
export class EntryCardRequestDeleteDialogComponent implements OnDestroy {
  message: string;
  entryCard: EntryCard;

  private subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<EntryCardRequestDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EntryCard,
    private entryCardService: EntryCardService,
  ) {
    this.entryCard = data;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  delete(): void {
    if (this.entryCard && this.message) {
      this.subscription.add(
        this.entryCardService.delete(this.entryCard, this.message).subscribe({
          next: (_) => {
            snack(t('Delete request created'));
            this.dialogRef.close();
          },
          error: (err) => snackErr(t('Unable to create request'), err),
        }),
      );
    }
  }
}
