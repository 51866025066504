<ng-container *ngIf="profile && tenancy && objects">
  <div class="f1 am">
    <apex-entry-cards
      class="minw-45"
      #entryCards
      [viewMode]="true"
      [tenancyId]="tenancy.id"
      (newEntryCardClick)="addEntryCard()"
      (deleteEntryCard)="deleteEntryCard($event)"
      (editEntryCard)="editEntryCard($event)">
    </apex-entry-cards>
  </div>
</ng-container>
