import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { AddonCart } from 'projects/apex/src/app/models/addon-cart';
import { AddonCategory } from 'projects/apex/src/app/models/addon-category';
import { Apartment } from 'projects/apex/src/app/models/apartment';
import { Subscription } from 'rxjs';

import { scrollWrap } from 'projects/apex/src/app/utils/functions';

@Component({
  selector: 'apex-client-addon-overview-page',
  templateUrl: './overview.component.html',
})
export class ClientAddonOverviewPageComponent implements OnInit, OnDestroy {
  cart: AddonCart;
  apartment: Apartment;
  addonCategories: AddonCategory[];
  parentAddonCategories: AddonCategory[];

  addonApartments: AddonApartment[] = [];

  projectText: { content: string; title: string };

  totalPrice = 0;

  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
  ) {}

  ngOnInit(): void {
    scrollWrap(0, 0);

    const data = this.route.snapshot.data;

    this.apartment = data.apartment;
    this.addonCategories = data.addonCategories;
    this.cart = data.cart;
    this.parentAddonCategories = this.addonCategories.filter((ac) => !ac.ParentId);

    this.projectText = data.projectText;

    this.addonApartments = [].concat(...this.addonCategories.map((ac) => ac.Addons)).map((a) => a.AddonApartments[0]);
  }

  scrollToAddon(addonApartment: AddonApartment): void {
    const minsideOffset = 112;
    const wrap = document.getElementById('wrap');
    const element = document.getElementById(`aa-${addonApartment.id}`);

    wrap.scrollTo({
      top: element.offsetTop - minsideOffset,
      behavior: 'smooth',
    });
  }

  back(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }
}
