import { Pipe, PipeTransform } from '@angular/core';
import { AddonOrderCardData } from '../orders/types/addon-order-card-data.type';

@Pipe({
  name: 'standardAddonForCategory',
  standalone: true,
})
export class StandardAddonForCategoryPipe implements PipeTransform {
  transform(addonApartments: AddonOrderCardData[], categoryId: number): AddonOrderCardData[] {
    return addonApartments.filter((aa) => aa.standardForCategory && aa.AddonId === categoryId);
  }
}
