import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn } from '@angular/router';
import {
  ArticlePageOptionsOrderBy,
  ResponsePortalArticleDtoPageResponse,
} from '../../../../../../../generated/apex-rest';
import { PortalService } from '../../../portal.service';

export const PortalApartmentArticlesResolver: ResolveFn<ResponsePortalArticleDtoPageResponse> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(PortalService);

  const projectId = Number(route.paramMap.get('ProjectId'));
  const apartmentId = Number(route.paramMap.get('ApartmentId'));

  const { orderBy, order, page, limit, search } = route.queryParams;

  const params: Params = {
    orderBy: orderBy ?? ArticlePageOptionsOrderBy.CreatedAt,
    articleModel: undefined,
    order: order ?? 'DESC',
    page: page ?? '0',
    limit: limit ?? '100',
    search: search ?? '',
  };

  return service.readProjectApartmentArticles(projectId, apartmentId, ...Object.values(params));
};
