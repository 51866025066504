import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apartment } from 'projects/apex/src/app/models/apartment';

import { scrollWrap } from 'projects/apex/src/app/utils/functions';

@Component({
  selector: 'apex-client-addon-apartments-page',
  templateUrl: './apartments.component.html',
})
export class ClientAddonApartmentsPageComponent implements OnInit {
  apartments: Apartment[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    scrollWrap(0, 0);

    const data = this.route.snapshot.data;

    this.apartments = data.apartments;
  }

  goToApartment(apartment: Apartment): void {
    void this.router.navigate(['minside', 'tilvalg', apartment.id]);
  }
}
