import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  MatCard,
  MatCardContent,
  MatCardHeader,
  MatCardImage,
  MatCardSubtitle,
  MatCardTitle,
} from '@angular/material/card';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { FileUsagePreviewModule } from '../../../../../../../apex/src/app/components/file-usage-preview/file-usage-preview.module';
import { TranslateModule } from '../../../../../../../apex/src/app/components/translate/translate.module';
import { ClientPageService } from '../../../../../../../apex/src/app/features/client-page/client-page.service';
import { FileUsage } from '../../../../../../../apex/src/app/models/file-usage';
import { AddonOrderCardData } from '../types/addon-order-card-data.type';

@Component({
  selector: 'portal-addon-order-card',
  templateUrl: './portal-addon-order-card.html',
  standalone: true,
  imports: [
    MatCard,
    MatCardTitle,
    MatCardHeader,
    MatCardContent,
    MatCardImage,
    MatCardSubtitle,
    FileUsagePreviewModule,
    CommonModule,
    TranslateModule,
  ],
})
export class PortalAddonOrderCardComponent implements OnInit {
  constructor(private clientService: ClientPageService) {}

  @Input() addonOrder: AddonOrderCardData;
  @Input() isStandard: boolean;

  fileUsages$: Observable<FileUsage[]>;

  ngOnInit(): void {
    this.fileUsages$ = this.clientService.getAddonImages(this.addonOrder.Addon).pipe(take(1));
  }
}
