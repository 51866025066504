<h1 mat-dialog-title>
  <T
    str="Request: {action, select, true {edit} other {new}} entry card"
    [vars]="{
      action: !!entryCard?.id,
    }"></T>
</h1>
<div mat-dialog-content>
  <form
    #ecrdForm="ngForm"
    class="df c">
    <apex-autocomplete-text
      name="name"
      [(ngModel)]="entryCard.name"
      (ngModelChange)="setUser()"
      (modelChange)="setUserId($event?.id)"
      type="User"
      [label]="'Name' | translate"
      required>
    </apex-autocomplete-text>

    <mat-form-field>
      <input
        matInput
        type="email"
        name="email"
        [placeholder]="'Email' | translate"
        [(ngModel)]="entryCard.mail" />
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        type="number"
        name="phone"
        [placeholder]="'Phone' | translate"
        [(ngModel)]="entryCard.mobile" />
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        type="text"
        name="pin"
        [placeholder]="'Pin' | translate"
        [(ngModel)]="entryCard.pin" />
    </mat-form-field>

    <mat-form-field class="f1">
      <mat-chip-grid #chipList>
        <mat-chip-row
          *ngFor="let group of entryCard.EntryGroups"
          [removable]="true"
          (removed)="removeGroup(group)"
          [matTooltip]="group.description">
          {{ group.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip-row>
        <input
          name="entryGroupFilterSearch"
          [(ngModel)]="entryGroupFilterSearch"
          [placeholder]="'Entry groups' | translate"
          #entryGroupInput
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          (ngModelChange)="onChange()" />
      </mat-chip-grid>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="addGroup($event?.option?.value)">
        <mat-option
          *ngIf="!viewEntryGroups.length"
          disabled>
          <T str="No matches found"></T>
        </mat-option>
        <mat-option
          *ngFor="let group of viewEntryGroups"
          [value]="group">
          <span [innerHTML]="group.name | highlight: entryGroupFilterSearch"> </span>
          <span
            class="mat-caption"
            *ngIf="group.description">
            - {{ group.description }}
          </span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
      <textarea
        matInput
        type="text"
        name="message"
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="10"
        [placeholder]="'Message' | translate"
        [(ngModel)]="message">
      </textarea>
    </mat-form-field>
  </form>
</div>

<div
  mat-dialog-actions
  class="df">
  <button
    mat-button
    mat-dialog-close>
    <T str="Cancel"></T>
  </button>
  <button
    mat-raised-button
    (click)="save()"
    color="primary"
    [disabled]="!ecrdForm.valid">
    <T str="Save"></T>
  </button>
</div>
