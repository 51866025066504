<div class="container carousel-container">
  <div
    class="carousel position-relative"
    (swipeleft)="onNextClick()"
    (swiperight)="onPreviousClick()">
    <button
      class="arrow arrow-left"
      [matTooltip]="'Previous' | translate"
      (click)="onPreviousClick()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>

    <button
      (click)="onNextClick()"
      class="arrow arrow-right"
      [matTooltip]="'Next' | translate">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>

    <ng-container *ngFor="let fileUsage of fileUsages; let i = index">
      <apex-file-preview
        class="align-center"
        *ngIf="i === currentSlide"
        [size]="{ width, height }"
        [file]="fileUsage.File">
      </apex-file-preview>
    </ng-container>
  </div>
</div>
