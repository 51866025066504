import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Params, ResolveFn } from '@angular/router';
import { ResponsePortalAddonOrderDtoPageResponse } from '../../../../../../../generated/apex-rest';
import { PortalAddonOrdersService } from '../service/portal-addon-orders.service';

export const PortalAddonOrdersResolver: ResolveFn<ResponsePortalAddonOrderDtoPageResponse> = (
  route: ActivatedRouteSnapshot,
) => {
  const service = inject(PortalAddonOrdersService);

  const projectId = Number(route.paramMap.get('ProjectId'));
  const apartmentId = Number(route.paramMap.get('ApartmentId'));

  const { page, limit } = route.queryParams;

  const params: Params = {
    page: page || 0,
    limit: limit || 100,
  };

  return service.readAddonOrders(projectId, apartmentId, params.page, params.limit);
};
