import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PortalCaseDialogModule } from 'projects/apex/src/app/components/case-dialog/case-dialog.module';
import { AutocompleteModule } from '../../components/autocomplete/autocomplete.module';
import { BoxModule } from '../../components/box/box.module';
import { FileUsageModule } from '../../components/file-usage/file-usage.module';
import { ModelRoleModule } from '../../components/model-role/model-role.module';
import { PageInfoModule } from '../../components/page-info/page-info.module';
import { TextareaModule } from '../../components/textarea/textarea.module';
import { TranslateModule } from '../../components/translate/translate.module';
import { DaterModule } from '../../pipes/dater/dater.module';
import { HighlightPipeModule } from '../../pipes/highlight/highlight-pipe.module';
import { CaseModule } from '../case/case.module';
import { ObjectListModule } from '../object/components/object-list/object-list.module';
import { AgreementModule } from '../object/project/agreement/agreement.module';
import { EntryCardModule } from '../object/project/tenancy/entry-card/entry-card.module';
import { EntryGroupModule } from '../object/project/tenancy/entry-group/entry-group.module';
import { TenancyModule } from '../object/project/tenancy/tenancy.module';
import { ProductsModule } from '../object/safety/product/products/products.module';
import { PortalAgreementModule } from './agreement/agreement.module';
import { ApexPortalRoutingModule } from './portal-routing.module';
import { PortalComponent } from './portal.component';
import { PortalProductModule } from './product/product.module';
import { EntryCardRequestDeleteDialogComponent } from './tenancy/entry-card-request-delete-dialog.component';
import { EntryCardRequestDialogComponent } from './tenancy/entry-card-request-dialog.component';
import { PortalTenancyComponent } from './tenancy/info.component';

@NgModule({
  imports: [
    ApexPortalRoutingModule,

    CommonModule,
    FormsModule,

    MatDividerModule,
    MatIconModule,
    MatRippleModule,
    MatTooltipModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    TextareaModule,

    PageInfoModule,
    TenancyModule,
    TranslateModule,
    CaseModule,
    ModelRoleModule,
    ObjectListModule,
    EntryCardModule,
    AutocompleteModule,
    PortalAgreementModule,
    PortalProductModule,
    HighlightPipeModule,
    EntryGroupModule,
    DaterModule,
    AgreementModule,
    BoxModule,
    ProductsModule,
    PortalCaseDialogModule,
    FileUsageModule,
  ],
  declarations: [
    PortalComponent,
    PortalTenancyComponent,

    EntryCardRequestDialogComponent,
    EntryCardRequestDeleteDialogComponent,
  ],
})
export class ApexPortalModule {}
