import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileUsage } from '../../../models/file-usage';
import { HttpService } from '../../../services/http/http.service';
import { createApiUrl } from '../../../utils/functions';

@Injectable()
export class ClientPageDocumentsService extends HttpService<FileUsage> {
  route = 'apartment/document';

  readApartmentDocuments(apartmentId: number): Observable<FileUsage[]> {
    if (!apartmentId) {
      throw new Error('Apartment id is required');
    }

    return this.http.get<FileUsage[]>(createApiUrl('apartment', apartmentId, 'document'), this.options);
  }
}
