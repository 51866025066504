import moment from 'moment';
import { Customer } from './customer';
import { Model } from './model';
import { Project } from './project';

export class ClientPageInfo extends Model<ClientPageInfo> {
  name: string;
  text: string;
  date: number;
  visible = true;
  ProjectId?: number;
  CustomerId: number;
  CreatorId?: number;
  Customer?: Customer;
  Project?: Project;

  get previewText(): string {
    const div = document.createElement('div');

    div.innerHTML = this.text;

    const texts: string[] = [];

    div.childNodes.forEach((childNode) => texts.push(childNode.textContent));

    return texts.reduce<string>((prev, curr) => `${prev} ${curr}`, '');
  }

  get postedDateString(): string {
    return moment.unix(this.date).format('DD.MM.YYYY');
  }
}
