import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { Profile } from 'projects/apex/src/app/models/profile';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { BoxModule } from '../../../../../apex/src/app/components/box/box.module';
import { ModelRoleModule } from '../../../../../apex/src/app/components/model-role/model-role.module';
import { TranslateModule } from '../../../../../apex/src/app/components/translate/translate.module';
import { ObjectListModule } from '../../../../../apex/src/app/features/object/components/object-list/object-list.module';
import { Obj } from '../../../../../apex/src/app/features/object/object.model';
import { TenancyModule } from '../../../../../apex/src/app/features/object/project/tenancy/tenancy.module';
import { Tenancy } from '../../../../../apex/src/app/models/tenancy';
import { IconPipeModule } from '../../../../../apex/src/app/pipes/icon/icon-pipe.module';

@Component({
  selector: 'portal-tenancy-info',
  templateUrl: './portal-tenancy-info-page.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ModelRoleModule,
    TenancyModule,
    ObjectListModule,
    MatIconModule,
    IconPipeModule,
    MatDividerModule,
    MatTooltipModule,
    BoxModule,
  ],
})
export default class PortalTenancyInfoPageComponent implements OnInit, OnDestroy {
  @Input() expanded = true;

  tenancy: Tenancy;
  objects: Obj[];
  profile: Profile;

  subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.data
        .pipe(map((data) => [data.tenancy, data.objects, data.profile] as [Tenancy, Obj[], Profile]))
        .subscribe({
          next: ([tenancy, objects, profile]) => {
            this.tenancy = tenancy;
            this.objects = objects;
            this.profile = profile;
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
