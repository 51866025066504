<ng-container *ngIf="profile && tenancy && objects">
  <apex-box
    class="f1 am"
    [expanded]="expanded"
    icon="info">
    <T
      title
      str="Unit"></T>

    <T
      description
      str="General information about your unit">
    </T>

    <ng-container *ngIf="tenancy">
      <apex-tenancy-info
        class="am f1 p-minw-90"
        [tenancy]="tenancy">
      </apex-tenancy-info>
    </ng-container>
  </apex-box>

  <div class="df gap am">
    <apex-model-role
      [expanded]="true"
      *ngIf="tenancy.TenantCustomerId"
      class="f1"
      [CRM]="false"
      [viewMode]="true"
      model="tenancy"
      [modelId]="tenancy.id"
      role="administrator"
      [emptyStateText]="'No administrators added for this tenancy' | translate"
      [label]="'Administrator' | translate"
      [title]="'Administrators' | translate">
    </apex-model-role>
    <apex-model-role
      [expanded]="true"
      *ngIf="!tenancy.TenantCustomerId"
      class="f1"
      [CRM]="true"
      [viewMode]="true"
      model="tenancy"
      [modelId]="tenancy.id"
      role="tenant"
      [emptyStateText]="'No tenants added for this tenancy' | translate"
      [label]="'Tenant' | translate"
      [title]="'Tenants' | translate">
    </apex-model-role>

    <apex-object-list
      class="f1"
      [view]="true"
      [highlight]="tenancy.Objects"
      [asClient]="true"
      [objects]="objects">
    </apex-object-list>
  </div>
</ng-container>
