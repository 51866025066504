import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Folder } from '../../models/folder';
import { APIService } from '../../services/http/http.service';
import { EntityResponse } from '../../utils/types';

@Injectable()
export class ApartmentMOMService extends APIService<Folder> {
  getApartmentMOMFolder(
    projectId: number,
    apartmentId: number,
    folderId: string = null,
  ): Observable<EntityResponse<Folder>> {
    this.route = `project/${projectId}/apartment/${apartmentId}/mom`;

    if (folderId) {
      this.route = `${this.route}/${folderId}`;
    }

    return this.http.get<EntityResponse<Folder>>(`${this.apiUrl}/${this.route}`, this.options());
  }
}
