import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { t } from '../../../components/translate/translate.function';
import { snack, snackErr } from '../../../modules/snack.module';
import { UserService } from '../../../services/user/user.service';
import { nameCompare } from '../../../utils/functions';
import { EntryCard } from '../../object/project/tenancy/entry-card/entry-card.model';
import { EntryCardService } from '../../object/project/tenancy/entry-card/entry-card.service';
import { EntryGroup } from '../../object/project/tenancy/entry-group/entry-group.model';
import { EntryGroupService } from '../../object/project/tenancy/entry-group/entry-group.service';

@Component({
  selector: 'apex-entry-card-request-dialog',
  templateUrl: './entry-card-request-dialog.component.html',
})
export class EntryCardRequestDialogComponent implements OnInit, OnDestroy {
  @ViewChild('entryGroupInput') entryGroupInput: ElementRef<HTMLInputElement>;

  entryCard = new EntryCard();
  entryGroups: EntryGroup[] = [];
  viewEntryGroups: EntryGroup[] = [];
  entryGroupFilterSearch = '';

  message = '';

  private subscription = new Subscription();

  constructor(
    public dialogRef: MatDialogRef<EntryCardRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tenancyId: number;
      entryCard?: EntryCard;
    },
    private userService: UserService,
    private entryCardService: EntryCardService,
    private entryGroupService: EntryGroupService,
  ) {
    if (data.entryCard) {
      this.entryCard = new EntryCard(data.entryCard);
      this.entryCard.EntryGroups = data.entryCard?.EntryGroups?.map((eg) => new EntryGroup(eg));
    } else {
      this.entryCard.EntryGroups = [];
    }
  }

  ngOnInit(): void {
    if (this.data?.tenancyId) {
      this.entryGroupService.route = `tenancy/${this.data.tenancyId}/entry-group`;

      this.subscription.add(
        this.entryGroupService.query().subscribe({
          next: (entryGroups) => {
            this.entryGroups = (entryGroups || []).sort(nameCompare);
            this.viewEntryGroups = [...this.entryGroups];
          },
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setUserId(id: number): void {
    if (id) {
      const sub = this.userService.get(id).subscribe({
        next: (user) => {
          this.entryCard.UserId = user.id;
          this.entryCard.name = user.name;
          this.entryCard.mail = user.mail;
          this.entryCard.mobile = Number(user.mobile);
        },
      });

      this.subscription.add(sub);
    }
  }

  setUser(): void {
    if (this.entryCard.UserId) {
      this.entryCard.mail = null;
      this.entryCard.mobile = null;
      this.entryCard.UserId = null;
    }
  }

  save(): void {
    this.entryCard.TenancyId = this.data.tenancyId;

    const sub = this.entryCardService.save(this.entryCard, this.message).subscribe({
      next: (ec) => {
        snack(t('Saved'));

        this.dialogRef.close({
          entryCard: ec,
        });
      },
      error: (err) => snackErr(t('Could not save'), err),
    });

    this.subscription.add(sub);
  }

  addGroup(group: EntryGroup): void {
    if (group?.id && !this.entryCard.EntryGroups.find((g) => g.id === group.id)) {
      this.entryCard.EntryGroups.push(group);
      this.entryGroupFilterSearch = '';
      this.entryGroupInput.nativeElement.value = '';
    }
  }

  removeGroup(group: EntryGroup): void {
    this.entryCard.EntryGroups = this.entryCard.EntryGroups.filter((sg) => sg.id !== group.id);
  }

  onChange(): void {
    this.viewEntryGroups = this.entryGroups
      .filter((eg) => {
        if (typeof this.entryGroupFilterSearch === 'string') {
          const searchInput = this.entryGroupFilterSearch.trim().toLowerCase();
          const searchFilter = eg.name.toLowerCase().includes(searchInput);

          return searchFilter;
        } else {
          this.entryGroupFilterSearch = '';
        }

        return true;
      })
      .sort(nameCompare);
  }
}
