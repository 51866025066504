import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Directive, ElementRef, EventEmitter, HostListener, OnDestroy, Output } from '@angular/core';
import { Subject, of } from 'rxjs';
import { catchError, debounceTime, map, mergeMap } from 'rxjs/operators';

interface PostalReturn {
  zip: string;
  name: string;
}

const empty: PostalReturn = {
  zip: '',
  name: '',
};

@Directive({
  selector: 'input[apexPostalOutput]',
})
export class PostalOutputDirective implements AfterViewInit, OnDestroy {
  @Output() postalChange: EventEmitter<string> = new EventEmitter();

  searchAndOutput: Subject<string> = new Subject();
  searchAndOutput$ = this.searchAndOutput
    .pipe(
      debounceTime(100),
      mergeMap((v) => {
        if (v?.length !== 4) {
          return of(empty);
        }

        return this.client
          .get<PostalReturn>(`https://api.apexapp.io/public/zip/${v}`)
          .pipe(catchError((_) => of(empty)));
      }),
      map((v) => v.name),
    )
    .subscribe({
      next: (s) => {
        this.el.nativeElement.setAttribute('postal', s);
        this.postalChange.emit(s);
      },
    });

  constructor(
    private client: HttpClient,
    private el: ElementRef<HTMLInputElement>,
  ) {}

  @HostListener('keyup') onKeyUp(): void {
    this.searchAndOutput.next(this.el.nativeElement.value);
  }

  ngAfterViewInit(): void {
    this.el.nativeElement.setAttribute('postal', '');
    setTimeout(() => this.searchAndOutput.next(this.el.nativeElement.value), 100);
  }

  ngOnDestroy(): void {
    this.searchAndOutput$.unsubscribe();
  }
}
