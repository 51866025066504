import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { t } from '../../components/translate/translate.function';

const hasOneNumberRegex = /\d/;
const hasOneUppercaseRegex = /[A-Z]/;
const hasOneSpecialRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

const apexValidatePassword: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.value ?? '';

  if (password.length < 8) {
    return { password: t('Password must contain at least 8 characters') };
  }

  if (!hasOneUppercaseRegex.test(password)) {
    return { password: t('Password must contain at least 1 uppercase letter') };
  }

  if (!hasOneNumberRegex.test(password)) {
    return { password: t('Password must contain at least 1 number') };
  }

  if (!hasOneSpecialRegex.test(password)) {
    return { password: t('Password must contain at least 1 special character') };
  }

  return null;
};

@Directive({
  selector: '[apexValidatePassword]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidatePasswordDirective,
      multi: true,
    },
  ],
})
export class ValidatePasswordDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return apexValidatePassword(control);
  }
}
