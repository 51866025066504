import { Component, Input, OnInit } from '@angular/core';
import { orderBy } from 'lodash-es';
import { SignedUrls } from '../../models/file';

@Component({
  selector: 'apex-logo',
  templateUrl: './logo.component.html',
})
export class LogoComponent implements OnInit {
  @Input() logos: Array<{ File?: { signed?: Pick<SignedUrls, 'url'> } }> = [];

  @Input() logoUrl = '';

  ngOnInit(): void {
    this.insertLogo();
  }

  insertLogo(): void {
    if (this.logos?.length) {
      const sort = orderBy(this.logos, ['fileSort'], ['desc']).pop();

      if (sort?.File?.signed?.url) {
        this.logoUrl = sort.File.signed.url;
      }
    }
  }
}
