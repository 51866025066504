import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileUsageViewerModule } from '../../../components/file-usage-viewer/file-usage-viewer.module';
import { PageInfoModule } from '../../../components/page-info/page-info.module';
import { TranslateModule } from '../../../components/translate/translate.module';
import { ClientPageDocumentsRoutingModule } from './client-page-documents-routing.module';
import { ClientPageDocumentsComponent } from './client-page-documents.component';
import { ClientPageDocumentsService } from './client-page-documents.service';

@NgModule({
  declarations: [ClientPageDocumentsComponent],
  imports: [
    CommonModule,
    ClientPageDocumentsRoutingModule,
    PageInfoModule,
    TranslateModule,
    MatTableModule,
    MatSortModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    FileUsageViewerModule,
  ],
  providers: [ClientPageDocumentsService],
  exports: [ClientPageDocumentsComponent],
})
export class ClientPageDocumentsModule {}
