import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apartment } from 'projects/apex/src/app/models/apartment';

import { scrollWrap } from 'projects/apex/src/app/utils/functions';

@Component({
  selector: 'apex-client-addon-confirmation-page',
  templateUrl: './confirmation.component.html',
})
export class ClientAddonConfirmationPageComponent implements OnInit {
  apartment: Apartment;
  title: string;
  content: string;

  inPortal = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    scrollWrap(0, 0);

    const data = this.route.snapshot.data;

    this.apartment = data.apartment;
    this.content = data.confirmationText.content;
    this.title = data.confirmationText.title;

    this.inPortal = data.portal;
  }
}
