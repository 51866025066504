import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Product } from 'projects/apex/src/app/models/addon-cart';
import { AddonOrder } from 'projects/apex/src/app/models/addon-order';
import { ITotals, getTotals } from 'projects/apex/src/app/utils/functions';

@Component({
  selector: 'apex-client-addon-order-totals',
  templateUrl: './totals.component.html',
})
export class ClientAddonOrderTotalsComponent implements OnInit, OnChanges {
  @Input() addonOrder: AddonOrder;
  @Input() product: Product;
  totals: ITotals;

  ngOnInit(): void {
    if (this.addonOrder) {
      this.refreshTotals();
    }

    if (this.product) {
      this.refreshTotalsProduct();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.addonOrder && this.addonOrder) {
      this.refreshTotals();
    }

    if (changes.product && this.product) {
      this.refreshTotalsProduct();
    }
  }

  refreshTotals(): void {
    this.totals = getTotals(
      this.addonOrder.priceOut,
      this.addonOrder.VAT,
      this.addonOrder.unitPrice ? this.addonOrder.quantity : 1,
    );
  }

  refreshTotalsProduct(): void {
    this.totals = getTotals(
      this.product.AddonApartment.priceOut,
      this.product.AddonApartment.VAT,
      this.product.AddonApartment.unitPrice ? this.product.quantity : 1,
    );
  }
}
