import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'projects/apex/src/app/components/page-not-found/page-not-found.component';
import { ClientAddonOverviewPageComponent } from 'projects/apex/src/app/features/client-page/addon/pages/overview/overview.component';
import {
  ClientConfirmationTextResolver,
  ClientGetAddonApartmentResolver,
  ClientGetAddonCartResolver,
  ClientGetAddonCategoryResolver,
  ClientGetAddonOrderResolver,
  ClientGetApartmentResolver,
  ClientGetApartmentWithAddonsResolver,
  ClientGetProjectWithAddonOrdersResolver,
  ClientProjectTextResolver,
  ClientQueryAddonCategoryByApartmentResolver,
  ClientQueryAddonOrderResolver,
  ClientQueryAllAddonCategoryByApartmentResolver,
  ClientQueryApartmentWithAddonsResolver,
} from '../client-page.resolve';
import { ClientAddonPageComponent } from './pages/addons/addons.component';
import { ClientAddonApartmentsPageComponent } from './pages/apartments/apartments.component';
import { ClientAddonCartPageComponent } from './pages/cart/cart.component';
import { ClientAddonConfirmationPageComponent } from './pages/confirmation/confirmation.component';
import { ClientAddonInfoPageComponent } from './pages/info/info.component';
import { ClientAddonOrderPageComponent } from './pages/order/order.component';
import { ClientAddonOrdersPageComponent } from './pages/orders/orders.component';

const routes: Routes = [
  {
    path: ':ApartmentId/cart',
    component: ClientAddonCartPageComponent,
    resolve: {
      cart: ClientGetAddonCartResolver,
      apartment: ClientGetApartmentWithAddonsResolver,
    },
  },
  {
    path: ':ApartmentId/bekreftelse',
    component: ClientAddonConfirmationPageComponent,
    resolve: {
      apartment: ClientGetApartmentResolver,
      confirmationText: ClientConfirmationTextResolver,
    },
  },
  {
    path: ':ApartmentId/overview',
    component: ClientAddonOverviewPageComponent,
    resolve: {
      // cart: ClientGetAddonCartResolver,
      // apartment: ClientGetApartmentWithAddonsResovler,
      addonCategories: ClientQueryAllAddonCategoryByApartmentResolver,
      projectText: ClientProjectTextResolver,
    },
  },
  {
    path: 'ordre',
    component: ClientAddonOrdersPageComponent,
    resolve: {
      projects: ClientGetProjectWithAddonOrdersResolver,
    },
  },
  {
    path: 'ordre/:AddonOrderId',
    component: ClientAddonOrderPageComponent,
    resolve: {
      addonOrder: ClientGetAddonOrderResolver,
    },
  },
  {
    path: ':ApartmentId/:AddonApartmentId',
    component: ClientAddonInfoPageComponent,
    resolve: {
      cart: ClientGetAddonCartResolver,
      addonOrders: ClientQueryAddonOrderResolver,
      addonApartment: ClientGetAddonApartmentResolver,
      addonCategory: ClientGetAddonCategoryResolver,
    },
  },
  {
    path: ':ApartmentId',
    component: ClientAddonPageComponent,
    resolve: {
      cart: ClientGetAddonCartResolver,
      apartment: ClientGetApartmentWithAddonsResolver,
      addonCategories: ClientQueryAddonCategoryByApartmentResolver,
      projectText: ClientProjectTextResolver,
    },
  },
  {
    path: '',
    component: ClientAddonApartmentsPageComponent,
    resolve: {
      apartments: ClientQueryApartmentWithAddonsResolver,
    },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ClientPageAddonRoutingModule {}
