<div class="am df jfe">
  <button
    type="button"
    mat-flat-button
    (click)="downloadAll()">
    <T str="Download all"></T>
  </button>
</div>
<section class="mat-elevation-z2 am steppable">
  <div>
    <ng-container *ngFor="let object of objects | sortHierarchy; last as last">
      <div
        class="df w p-c steps"
        [ngClass]="'steps-' + object['depth']">
        <div class="df c f1">
          <div class="df aph">
            <h3>{{ object.name }}</h3>
          </div>
        </div>

        <div class="df f1 justify-flex-end p-c p-asfs">
          <apex-qr-code-image
            [path]="'/object/project/1/object/' + object.id"
            [title]="project.name"
            [name]="object.name"></apex-qr-code-image>
        </div>
      </div>
      <mat-divider *ngIf="!last"></mat-divider>
    </ng-container>
  </div>
</section>
