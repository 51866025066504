import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, OnDestroy, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Subscription } from 'rxjs';
import { InViewDirectiveModule } from '../../directives/in-view/in-view.directive.module';
import { CategoryOrAddon, CustomAddon } from '../../features/client-page/addon/pages/addons/addons.types';
import { ClientPageService } from '../../features/client-page/client-page.service';
import {
  categoriesAndAddons,
  filteredCategoriesAndAddons,
  inputWidth,
} from '../addon-search-button/addon-search-button.model';
import { FilePreviewModule } from '../file-preview/file-preview.module';
import { TranslateModule } from '../translate/translate.module';

@Component({
  selector: 'apex-addon-overlay-list',
  templateUrl: './addon-overlay-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocompleteModule,
    CdkConnectedOverlay,
    FormsModule,
    MatInputModule,
    TranslateModule,
    FilePreviewModule,
    InViewDirectiveModule,
    MatButtonModule,
    MatIconModule,
  ],
})
export class AddonOverlayListComponent implements OnDestroy {
  @Output() closed = new EventEmitter<void>();

  search: string;

  subscription$$ = new Subscription();

  @HostListener('click', ['$event']) hostClick($event: Event): void {
    $event.stopPropagation();
  }

  constructor(
    private clientService: ClientPageService,
    @Inject(categoriesAndAddons) public categories: CategoryOrAddon[],
    @Inject(filteredCategoriesAndAddons) public filteredCategories: CategoryOrAddon[],
    @Inject(inputWidth) public width: number,
  ) {}

  goTo(name: string): void {
    document.getElementById(name)?.scrollIntoView();
  }

  getAddonImage(addon: CustomAddon): void {
    this.subscription$$.add(
      this.clientService.getAddonImages(addon).subscribe({
        next: (fus) => {
          addon.images = fus;
        },
      }),
    );
  }

  filterAutocomplete(): void {
    this.filteredCategories = this.categories.filter((ca) => {
      const searchName = ca.category
        ? ca.category.name.toLowerCase().includes(this.search?.toLowerCase())
        : ca.addon
          ? ca.addon.name.toLowerCase().includes(this.search.toLowerCase())
          : false;

      return this.search.trim() ? searchName : true;
    });
  }

  closeOverlay(): void {
    this.closed.emit();
  }

  ngOnDestroy(): void {
    this.subscription$$.unsubscribe();
  }
}
