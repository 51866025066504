<apex-client-addon-breadcrumb
  [inOrder]="true"
  [addonOrder]="addonOrder">
</apex-client-addon-breadcrumb>

<apex-client-addon-apartment-info
  [addonApartment]="addonOrder.AddonApartment"
  [editable]="false">
</apex-client-addon-apartment-info>

<div class="amt">
  <button
    mat-raised-button
    (click)="back()">
    <T str="Back"></T>
  </button>
</div>
