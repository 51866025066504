import { Component, Input, OnInit } from '@angular/core';
import { orderBy } from 'lodash-es';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { AddonCategory } from 'projects/apex/src/app/models/addon-category';

@Component({
  selector: 'apex-client-addon-overview',
  templateUrl: './overview.component.html',
})
export class ClientAddonOverviewComponent implements OnInit {
  @Input() addonCategories: AddonCategory[] = [];
  @Input() projectText: { content: string; title: string };

  parentAddonCategories: AddonCategory[];
  addonApartments: AddonApartment[] = [];

  ngOnInit(): void {
    this.parentAddonCategories = this.addonCategories?.filter((ac) => !ac.ParentId);
    this.addonApartments = []
      .concat(...this.addonCategories?.map((ac) => ac.Addons))
      .filter((a) => a.AddonApartments?.length)
      .map((a) => a.AddonApartments[0]);
  }

  scrollToAddon(addonApartment: AddonApartment): void {
    document.getElementById(`aa-${addonApartment.id}`).scrollIntoView({ behavior: 'smooth' });
  }

  sorted(addonApartments: AddonApartment[]): AddonApartment[] {
    return orderBy(addonApartments, ['Addon.name']);
  }
}
