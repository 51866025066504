<h4
  [ngClass]="{ 'add-margin-bottom-small no-margin-bottom no-padding-bottom': fileUsages.length === 0 }"
  class="add-margin">
  <T
    str="Attachments ({count})"
    [vars]="{ count: fileUsages?.length ?? 0 }"></T>
</h4>

<div
  class="add-margin-left"
  *ngIf="!fileUsages.length">
  <T
    class="add-margin-left-small"
    str="No attachment"></T>
</div>

<div class="display-grid-cards card-width-200 add-margin">
  <ng-container *ngFor="let fileUsage of fileUsages">
    <mat-card
      appearance="raised"
      class="cardBox remove-margin add-padding">
      <mat-icon class="description grid-item-left add-margin-right-small add-padding-top-small">description</mat-icon>

      <div class="column grid-item-center">
        <div
          class="truncate mat-h3 add-margin-bottom-extra-small"
          title>
          {{ fileUsage.fileName }}
        </div>
        <div class="mat-caption">{{ fileUsage.File.size | fileSize }}</div>
      </div>
      <a href="{{ fileUsage.File.signed.withDispositionUrl }}">
        <mat-icon class="add-padding-top-small add-margin-left-extra-small">cloud_download</mat-icon>
      </a>
    </mat-card>
  </ng-container>
</div>
