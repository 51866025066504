import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarModule } from '../avatar/avatar.module';
import { LogoModule } from '../logo/logo.module';
import { BannerComponent } from './banner.component';

@NgModule({
  declarations: [BannerComponent],
  exports: [BannerComponent],
  imports: [CommonModule, LogoModule, AvatarModule],
})
export class BannerModule {}
