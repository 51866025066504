import { Pipe, PipeTransform } from '@angular/core';
import { AddonOrderCardData } from '../orders/types/addon-order-card-data.type';

@Pipe({
  name: 'filterAddonOrdersByCategory',
  standalone: true,
})
export class FilterAddonOrdersByCategoryPipe implements PipeTransform {
  transform(addonOrders: AddonOrderCardData[], categoryId: number): AddonOrderCardData[] {
    return addonOrders.filter((ao: AddonOrderCardData) => ao.AddonId === categoryId);
  }
}
