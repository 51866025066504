import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Profile } from 'projects/apex/src/app/models/profile';

@Component({
  selector: 'portal-profile',
  templateUrl: 'portal-profile-page.component.html',
})
export class PortalProfilePageComponent implements OnInit {
  profile: Profile;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.profile = this.route.snapshot.data.profile;
  }
}
