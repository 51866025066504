import { Addon } from './addon';
import { Apartment } from './apartment';
import { Marking } from './marking';
import { Model } from './model';
import { User } from './user';

export class AddonApartment extends Model<AddonApartment> {
  AddonId: number;
  Addon?: Addon;
  ApartmentId: number;
  Apartment?: Apartment;

  amount?: number;
  unitPriceIn?: number;
  unit?: string;
  priceIn: number;
  priceOut: number;
  VAT: number;
  unitPrice: boolean;
  surcharge?: number;

  dateStart: Date;
  dateEnd: Date;
  ContractorId: number;
  Contractor?: User;
  markingMin: number;
  markingMax: number;
  standardForCategory: boolean;
  requireApproval: boolean;
  requireSignature: boolean;
  Markings?: Marking[];
  filterFileUsage: number[];
}
