import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AvatarModule } from '../../../../components/avatar/avatar.module';
import { ColorPickerModule } from '../../../../components/color-picker/color-picker.module';
import { FileUsageModule } from '../../../../components/file-usage/file-usage.module';
import { ItemModule } from '../../../../components/item/item.module';
import { TranslateModule } from '../../../../components/translate/translate.module';
import { UserCardModule } from '../../../../directives/user-card/user-card.module';
import { DaterModule } from '../../../../pipes/dater/dater.module';
import { CaseContractorsModule } from '../../contractor/case-contractors.module';
import { CaseLogsModule } from '../../logs/case-logs.module';
import { CaseNewMessageModule } from '../../new-message/new-message.module';
import { CaseCollectionDialogComponent } from './case-collection-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    DaterModule,

    TranslateModule,
    ItemModule,
    ColorPickerModule,
    AvatarModule,
    UserCardModule,
    CaseLogsModule,
    FileUsageModule,
    CaseContractorsModule,

    MatDialogModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    CaseNewMessageModule,
  ],
  exports: [CaseCollectionDialogComponent],
  declarations: [CaseCollectionDialogComponent],
  providers: [],
})
export class CaseCollectionDialogModule {}
