import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOption } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import {
  ResponsePortalCaseDto,
  ResponsePortalCaseDtoPageResponse,
  ResponsePortalMainObjectDto,
} from '../../../../../generated/apex-rest';
import { AutocompleteModule } from '../../../../apex/src/app/components/autocomplete/autocomplete.module';
import { TranslateModule } from '../../../../apex/src/app/components/translate/translate.module';
import { RequestDialogComponent } from '../../../../apex/src/app/features/request/dialog/request-dialog.component';
import {
  RequestDialogData,
  RequestDialogReturnData,
} from '../../../../apex/src/app/features/request/dialog/request-dialog.types';
import { Request } from '../../../../apex/src/app/models/request';
import { constants } from '../../../../apex/src/app/utils/constants';
import { PortalCaseListModule } from '../apartment/case/list/portal-case-list.module';
import { PortalCaseViewModule } from '../apartment/case/view/portal-case-view.module';
import { PortalCasesService } from './services/portal-cases.service';

@Component({
  selector: 'portal-cases',
  templateUrl: 'portal-cases-page.component.html',
  standalone: true,
  imports: [
    CommonModule,
    PortalCaseListModule,
    PortalCaseViewModule,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    TranslateModule,
    AutocompleteModule,
    MatOption,
    MatSelect,
    MatDivider,
    MatPaginator,
    MatButtonModule,
  ],
  providers: [PortalCasesService],
})
export class PortalCasesPageComponent implements OnInit, OnDestroy {
  limit = 25;
  page = 0;
  count: number;

  cases: ResponsePortalCaseDto[] = [];
  apartments: ResponsePortalMainObjectDto[] = [];
  apartmentNames: Partial<ResponsePortalMainObjectDto>[] = [];
  search = '';
  selectedApartmentId: number | null = null;

  searchSubject = new Subject<string>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  private subscription = new Subscription();

  async ngOnInit(): Promise<void> {
    this.apartments = this.route.snapshot.data.entities ?? [];
    this.apartments.forEach((apartment) => this.apartmentNames.push({ id: apartment.id, name: apartment.name }));

    this.selectedApartmentId = this.route.snapshot.queryParams?.ApartmentId;

    this.subscription.add(
      this.route.data
        .pipe(
          map((data) => {
            const responsePortalCaseDtoPageResponse: ResponsePortalCaseDtoPageResponse = data.cases;

            if (!responsePortalCaseDtoPageResponse) {
              return undefined;
            }

            this.cases = responsePortalCaseDtoPageResponse.Collection;

            const queryParams = this.route.snapshot.queryParams;

            this.search = queryParams.search || '';

            return responsePortalCaseDtoPageResponse.Collection;
          }),
        )
        .subscribe({
          next: (cases) => {
            if (cases) {
              this.cases = cases;
            }
          },
        }),
    );

    this.subscription.add(
      this.route.queryParams.subscribe({
        next: (queryParams) => {
          this.search = (this.search || queryParams.search) ?? '';
        },
      }),
    );

    this.subscription.add(
      this.searchSubject.pipe(debounceTime(constants.inputDebounceTime)).subscribe((searchString) => {
        if (typeof searchString === 'string') {
          this.search = searchString;
        } else {
          this.search = (this.search || searchString) ?? '';
        }

        this.updateQueryParams(0);
      }),
    );
  }

  public updateQueryParams(page?: number): void {
    const queryParams: Params = {
      search: this.search ?? '',
      ApartmentId: this.selectedApartmentId,
      page: page ?? this.page,
      casePage: page ?? this.page,
    };

    void this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  searchStr(): void {
    this.searchSubject.next(this.search);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createRequest(): void {
    if (!this.selectedApartmentId) {
      return;
    }

    const requestData: Partial<Request> = {
      CustomerId: -1,
      ProjectId: '',
      ApartmentId: String(this.selectedApartmentId),
      ClientId: String(this.route.snapshot.data.profile.id),
    };

    this.dialog
      .open<RequestDialogComponent, RequestDialogData, RequestDialogReturnData>(RequestDialogComponent, {
        data: {
          request: new Request(requestData),
          mainObjects: this.route.snapshot.data.entities ?? [],
        },
        panelClass: ['apex-fullscreen-dialog', 'phone'],
      })
      .afterClosed()
      .pipe(filter((request) => !!request))
      .subscribe({
        next: () => {},
      });
  }
}
