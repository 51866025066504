import { inject } from '@angular/core';

import { ResolveFn } from '@angular/router';
import { ResponsePortalMainObjectDto } from '../../../../../../generated/apex-rest';
import { PortalService } from '../../portal.service';

export const PortalEntitiesResolver: ResolveFn<Array<ResponsePortalMainObjectDto>> = () => {
  const service = inject(PortalService);

  return service.readEntities();
};
