import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { AvatarModule } from '../../../../apex/src/app/components/avatar/avatar.module';
import { BannerModule } from '../../../../apex/src/app/components/banner/banner.module';
import { FileUsageModule } from '../../../../apex/src/app/components/file-usage/file-usage.module';
import { LinkCardsModule } from '../../../../apex/src/app/components/link-cards/link-cards.module';
import { PageInfoModule } from '../../../../apex/src/app/components/page-info/page-info.module';
import { TranslateModule } from '../../../../apex/src/app/components/translate/translate.module';
import { ApartmentInfoModule } from '../../../../apex/src/app/features/apartment/info/apartment-info.module';
import { CaseModule } from '../../../../apex/src/app/features/case/case.module';
import { CaseCollectionListModule } from '../../../../apex/src/app/features/case/collection/list/case-collection-list.module';
import { ClientPageInfoBoxModule } from '../../../../apex/src/app/features/client-page-info/box/client-page-info-box.module';
import { ClientPageDocumentsModule } from '../../../../apex/src/app/features/client-page/documents/client-page-documents.module';
import { RequestBoxModule } from '../../../../apex/src/app/features/request/box/request-box.module';
import { PortalCasesService } from '../cases/services/portal-cases.service';
import { PortalAddonOrdersService } from './addon/service/portal-addon-orders.service';
import { ApartmentPageRoutingModule } from './apartment-page-routing.module';
import { ApartmentPageComponent } from './apartment-page.component';
import { CaseRequestPageModule } from './case/case-request-page.module';
import { PortalMOMViewerModule } from './mom/portal-mom-viewer.module';
import { NewsModule } from './news/news.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,

    ApartmentPageRoutingModule,

    TranslateModule,
    AvatarModule,
    PageInfoModule,
    CaseModule,
    ClientPageInfoBoxModule,
    ApartmentInfoModule,
    RequestBoxModule,
    CaseCollectionListModule,
    FileUsageModule,
    PortalMOMViewerModule,
    MatCardModule,
    MatButtonModule,
    LinkCardsModule,
    ClientPageDocumentsModule,
    CaseRequestPageModule,
    NewsModule,
    BannerModule,
    MatTabsModule,
    MatDividerModule,
  ],
  exports: [ApartmentPageComponent],
  declarations: [ApartmentPageComponent],
  providers: [PortalAddonOrdersService, PortalCasesService],
})
export class ApartmentPageModule {}
