import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatLineModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { BoxModule } from '../../../../components/box/box.module';
import { TranslateModule } from '../../../../components/translate/translate.module';
import { DaterModule } from '../../../../pipes/dater/dater.module';
import { CaseService } from '../../case.service';
import { CaseCollectionDialogModule } from '../view/case-collection-dialog.module';
import { CaseCollectionListComponent } from './case-collection-list.component';

@NgModule({
  imports: [
    CommonModule,

    TranslateModule,
    BoxModule,
    DaterModule,
    CaseCollectionDialogModule,

    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatLineModule,
    MatPaginatorModule,
    MatDividerModule,
    MatDialogModule,
  ],
  exports: [CaseCollectionListComponent],
  declarations: [CaseCollectionListComponent],
  providers: [CaseService],
})
export class CaseCollectionListModule {}
