import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileUsageModule } from '../../../../../apex/src/app/components/file-usage/file-usage.module';
import { TranslateModule } from '../../../../../apex/src/app/components/translate/translate.module';
import { Obj } from '../../../../../apex/src/app/features/object/object.model';
import { Profile } from '../../../../../apex/src/app/models/profile';
import { Tenancy } from '../../../../../apex/src/app/models/tenancy';

@Component({
  selector: 'portal-tenancy-files',
  templateUrl: 'portal-tenancy-file-page.component.html',
  standalone: true,
  imports: [FileUsageModule, TranslateModule, CommonModule],
})
export default class PortalTenancyFilePageComponent implements OnInit, OnDestroy {
  tenancy: Tenancy;
  objects: Obj[];
  profile: Profile;

  subscription = new Subscription();

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subscription.add(
      this.route.data
        .pipe(map((data) => [data.tenancy, data.objects, data.profile] as [Tenancy, Obj[], Profile]))
        .subscribe({
          next: ([tenancy, objects, profile]) => {
            this.tenancy = tenancy;
            this.objects = objects;
            this.profile = profile;
          },
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
