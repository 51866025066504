import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from 'projects/apex/src/app/components/page-not-found/page-not-found.component';
import {
  TenancyObjectTreeResolver,
  TenancyResolver,
} from 'projects/apex/src/app/features/object/project/tenancy/tenancy.resolver';
import { GetProfileResolver } from 'projects/apex/src/app/services/user/profile.resolve';
import { PortalProfilePageComponent } from 'projects/portal/src/app/profile/portal-profile-page.component';
import { PortalStartPageComponent } from 'projects/portal/src/app/start/portal-start-page.component';
import { PortalTenancyPageComponent } from 'projects/portal/src/app/tenancy/portal-tenancy-page.component';
import { PortalProductComponent } from '../../../apex/src/app/features/portal/product/info.component';
import {
  PortalProductResolver,
  PortalProductsResolver,
} from '../../../apex/src/app/features/portal/product/product.resolver';
import { PortalApartmentCaseResolver, PortalMarkCaseReadResolver } from './apartment/case/case-request-page.resolver';
import { PortalCasesViewDialogEntryComponent } from './cases/portal-case-view-entry.component';
import { PortalCasesPageComponent } from './cases/portal-cases-page.component';
import { PortalCaseLogsResolver } from './cases/resolvers/portal-case-logs.resolver';
import { PortalCasesResolver } from './cases/resolvers/portal-cases.resolver';
import { PortalEntitiesResolver } from './start/resolver/portal-entities-resolver.service';
import PortalTenancyAccessCardPageComponent from './tenancy/access-card/portal-tenancy-access-card-page.component';
import PortalTenancyCasePageComponent from './tenancy/case/portal-tenancy-case-page.component';
import PortalTenancyFilePageComponent from './tenancy/file/portal-tenancy-file-page.component';
import PortalTenancyInfoPageComponent from './tenancy/info/portal-tenancy-info-page.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('../../../apex/src/app/features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [],
  },
  {
    path: '',
    resolve: {
      profile: GetProfileResolver,
    },
    children: [
      {
        path: '',
        resolve: {
          entities: PortalEntitiesResolver,
          products: PortalProductsResolver,
        },
        children: [
          {
            path: '',
            component: PortalStartPageComponent,
            resolve: {
              entities: PortalEntitiesResolver,
              products: PortalProductsResolver,
            },
          },
          {
            path: 'project/:ProjectId/apartment/:ApartmentId',
            loadChildren: () => import('./apartment/apartment-page.module').then((mod) => mod.ApartmentPageModule),
          },
          {
            path: 'profile',
            component: PortalProfilePageComponent,
          },
          {
            path: 'cases',
            runGuardsAndResolvers: 'always',
            resolve: {
              cases: PortalCasesResolver,
            },
            component: PortalCasesPageComponent,
            children: [
              {
                path: ':CaseId',
                resolve: {
                  caseItem: PortalApartmentCaseResolver,
                  caseLogs: PortalCaseLogsResolver,
                  caseMark: PortalMarkCaseReadResolver,
                },
                runGuardsAndResolvers: 'always',
                component: PortalCasesViewDialogEntryComponent,
              },
            ],
          },
          {
            path: 'tenancy/:tid',
            component: PortalTenancyPageComponent,
            resolve: {
              tenancy: TenancyResolver,
              objects: TenancyObjectTreeResolver,
            },
            children: [
              {
                path: '',
                pathMatch: 'prefix',
                redirectTo: 'info',
              },
              {
                path: 'info',
                component: PortalTenancyInfoPageComponent,
              },
              {
                path: 'cases',
                component: PortalTenancyCasePageComponent,
              },
              {
                path: 'files',
                component: PortalTenancyFilePageComponent,
              },
              {
                path: 'access-card',
                component: PortalTenancyAccessCardPageComponent,
              },
            ],
          },
          {
            path: 'product/:id',
            component: PortalProductComponent,
            resolve: {
              product: PortalProductResolver,
            },
          },
          {
            path: '**',
            component: PageNotFoundComponent,
          },
        ],
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
