<apex-page-info
  [title]="product?.name"
  icon="security"
  fontSet="material-icons-sharp">
</apex-page-info>

<section class="wrapper df c f1 aps">
  <div class="df afs w">
    <section class="product ams mat-elevation-z2 f1 p-minw-50">
      <div class="df ac ap">
        <mat-icon [matTooltip]="'Product' | translate"> security </mat-icon>
        <div class="mat-h3 amls rm">{{ product.name }}</div>
      </div>
      <mat-divider></mat-divider>
      <div
        class="df ac"
        *ngIf="product.description">
        <mat-icon
          class="am"
          [matTooltip]="'Description' | translate">
          notes
        </mat-icon>
        {{ product.description }}
      </div>
      <div
        class="df ac"
        *ngIf="productType.name">
        <mat-icon
          class="am"
          [matTooltip]="'Product Type (Type)' | translate">
          merge_type
        </mat-icon>
        {{ productType?.name }} ({{ productType?.type }})
      </div>
      <div
        class="df ac"
        *ngIf="product.data?.serialNumber">
        <mat-icon
          class="am"
          [matTooltip]="'Serial' | translate">
          code
        </mat-icon>
        {{ product.data.serialNumber }}
      </div>
      <div
        class="df ac"
        *ngIf="product.data?.productionDate">
        <mat-icon
          class="am"
          [matTooltip]="'Production Date' | translate">
          date_range
        </mat-icon>
        {{ product.data.productionDate | date: 'shortDate' }}
      </div>
    </section>

    <section class="controls ams mat-elevation-z2 f1">
      <div class="ap df r ac jsb">
        <div class="df r ac">
          <mat-icon class="amr">policy</mat-icon>
          <T str="Controls"></T>
        </div>
        <div>
          <strong> <T str="Next"></T> </strong>:
          <ng-container *ngIf="controls[0]?.data.next">
            {{ controls[0]?.data.next | date: 'shortDate' }}
          </ng-container>
          <ng-container *ngIf="!controls[0]?.data.next">
            <T str="None"></T>
          </ng-container>
        </div>
      </div>
      <mat-divider></mat-divider>
      <ng-container *ngIf="controls?.length; else noControls">
        <div
          class="ap df r ac jsb"
          [ngClass]="{ complete: control.data.status === 'valid', error: control.data.status === 'invalid' }"
          *ngFor="let control of controls">
          <div class="df r ac">
            <mat-icon
              class="amr"
              color="primary"
              *ngIf="control.data.status === 'valid'"
              [matTooltip]="'Valid' | translate">
              check_circle_outline
            </mat-icon>
            <mat-icon
              class="amr"
              color="warn"
              *ngIf="control.data.status === 'invalid'"
              [matTooltip]="'Invalid' | translate">
              error_outline
            </mat-icon>
            {{ control.data.date | date: 'shortDate' }}
            -
            <!-- @note Translations is handled in list component -->
            {{ control.data.status | titlecase | translate }}
          </div>
        </div>
      </ng-container>
      <ng-template #noControls>
        <div class="ap">
          <T str="No controls to show"></T>
        </div>
      </ng-template>
    </section>
  </div>
  <apex-file-usage
    class="ams"
    view="Grid"
    [disabled]="true"
    [title]="'Files' | translate"
    self="object"
    [selfId]="product.id"
    name="files">
  </apex-file-usage>
</section>

<button
  mat-fab
  class="fab-button"
  (click)="newRequest()">
  <mat-icon
    matTooltipPosition="left"
    [matTooltip]="'New request' | translate">
    add
  </mat-icon>
</button>
