import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { AutocompleteModule } from 'projects/apex/src/app/components/autocomplete/autocomplete.module';
import { FileUsageModule } from 'projects/apex/src/app/components/file-usage/file-usage.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { CaseService } from 'projects/apex/src/app/features/case/case.service';
import { TenancyService } from 'projects/apex/src/app/features/object/project/tenancy/tenancy.service';
import { EmbeddedMarkingsViewerModule } from '../embedded-markings-viewer/embedded-markings-viewer.module';
import { PortalCaseDialogComponent } from './case-dialog.component';

@NgModule({
  declarations: [PortalCaseDialogComponent],
  imports: [
    CommonModule,
    FormsModule,

    TranslateModule,
    AutocompleteModule,
    FileUsageModule,
    EmbeddedMarkingsViewerModule,

    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
  ],
  exports: [PortalCaseDialogComponent],
  providers: [CaseService, TenancyService],
})
export class PortalCaseDialogModule {}
