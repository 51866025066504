import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '../../../../../apex/src/app/components/translate/translate.module';
import { RequestListModule } from '../../../../../apex/src/app/features/request/list/request-list.module';
import { CaseRequestPageComponent } from './case-request-page.component';

import { MatDividerModule } from '@angular/material/divider';
import { RouterOutlet } from '@angular/router';
import { PortalCaseListModule } from './list/portal-case-list.module';
import { PortalCaseViewModule } from './view/portal-case-view.module';

@NgModule({
  declarations: [CaseRequestPageComponent],
  imports: [
    CommonModule,
    RequestListModule,
    PortalCaseListModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,

    MatDividerModule,
    RouterOutlet,
    PortalCaseViewModule,
  ],
})
export class CaseRequestPageModule {}
