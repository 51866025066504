<ng-container *ngIf="data.request as request">
  <div mat-dialog-title>
    <div class="df jsb ac f1">
      <h1>
        <T str="New request"></T>
      </h1>
      <button
        mat-icon-button
        (click)="dialogRef.close(null)">
        <mat-icon> clear</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <form #form="ngForm">
      <div class="df c jsb">
        <mat-form-field>
          <mat-label>
            <T str="Heading"></T>
          </mat-label>
          <input
            matInput
            cdkFocusInitial
            required
            name="caseName"
            type="text"
            [(ngModel)]="request.caseName" />
        </mat-form-field>
        <mat-form-field>
          <mat-label>
            <T str="Description"></T>
          </mat-label>
          <textarea
            [cdkTextareaAutosize]="true"
            required
            #desc
            matInput
            name="description"
            [(ngModel)]="request.description"></textarea>
          <mat-hint>{{ desc.value.length }} / 1024</mat-hint>
        </mat-form-field>
        <div class="df c gap">
          <mat-form-field subscriptSizing="dynamic">
            <mat-label><T str="Apartment"></T></mat-label>
            <mat-select
              [disabled]="true"
              required
              [(ngModel)]="data.request.ApartmentId"
              (ngModelChange)="apartmentChange($event)"
              name="apartment">
              <mat-option
                [value]="mainObject.id"
                *ngFor="let mainObject of data.mainObjects">
                {{ mainObject.name }}
                <ng-container *ngIf="mainObject?.Project?.name"> - {{ mainObject.Project.name }} </ng-container>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <ng-container *ngIf="request?.ProjectId">
            <apex-autocomplete
              class="amts"
              type="Field"
              [label]="'Discipline' | translate"
              name="field"
              [(ngModel)]="request.FieldId"
              [params]="{ ProjectId: request?.ProjectId }">
            </apex-autocomplete>
          </ng-container>
        </div>
        <apex-file-usage
          #files
          class="amv"
          name="files"
          view="List"
          [title]="'Files' | translate"
          self="request"
          [selfId]="0">
        </apex-file-usage>
      </div>
    </form>
  </div>
  <div
    mat-dialog-actions
    class="df jfe ac">
    <div>
      <button
        mat-raised-button
        (click)="dialogRef.close(null)">
        <T str="Cancel"></T>
      </button>
    </div>
    <div>
      <button
        mat-raised-button
        color="primary"
        class="aml"
        [disabled]="!form.valid"
        (click)="save()">
        <T str="Save"></T>
      </button>
    </div>
  </div>
</ng-container>
