import { Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import { FileUsageViewerDialogComponent } from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer-dialog.component';
import {
  FileUsageViewerCloseData,
  FileUsageViewerData,
  FileUsageViewerMode,
} from 'projects/apex/src/app/components/file-usage-viewer/file-usage-viewer.types';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { ClientPageService } from 'projects/apex/src/app/features/client-page/client-page.service';
import { AddonApartment } from 'projects/apex/src/app/models/addon-apartment';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Marking, MarkingModelType } from 'projects/apex/src/app/models/marking';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'apex-client-addon-apartment-card',
  templateUrl: './card.component.html',
})
export class ClientAddonApartmentCardComponent implements OnInit, OnDestroy {
  @Input() level = 0;
  @Input() addonApartment: AddonApartment;
  @Input() currentSelected: boolean;
  @Input() comment: string;
  @Input() editable = true;

  @Output() addonClicked = new EventEmitter<AddonApartment>();
  @Output() add = new EventEmitter<{ addonApartment: AddonApartment; comment: string }>();
  @Output() remove = new EventEmitter<AddonApartment>();
  @Output() clicked = new EventEmitter<AddonApartment>();

  get addTooltip(): string {
    const markAndAdd =
      !this.currentSelected && (this.addonApartment?.markingMin !== 0 || this.addonApartment?.markingMax !== 0);

    return markAndAdd ? t('Mark and add to cart') : t('Add to cart');
  }

  fileUsages: FileUsage[];
  isLastDayOrWeek: 'day' | 'week';
  timeLeft: string;
  hasExpired: boolean;

  private subscriptions: Subscription[] = [];

  constructor(
    private clientService: ClientPageService,
    private overlay: Overlay,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.addonApartment.markingMax !== 0 || this.addonApartment.markingMin !== 0) {
      this.getMarkings();
    }

    this.isOnLastDayOrWeek();
    this.calculateTimeLeft();
  }

  inView(): void {
    this.getAddonImages();
  }

  getMarkings(): void {
    const sub = this.clientService
      .getAllMarkings(
        'apartment',
        this.addonApartment.ApartmentId,
        'floorplans',
        'addon-apartment',
        this.addonApartment.id,
      )
      .pipe(take(1))
      .subscribe((markings: Marking[]) => {
        this.addonApartment.Markings = markings;
      });

    this.subscriptions.push(sub);
  }

  cartClicked(): void {
    if (!this.currentSelected && (this.addonApartment.markingMin !== 0 || this.addonApartment.markingMax !== 0)) {
      this.markingClicked();
    } else {
      this.add.emit({
        addonApartment: this.addonApartment,
        comment: '',
      });
    }
  }

  markingClicked(): void {
    const sub1 = this.clientService
      .getFloorplanImage(this.addonApartment)
      .pipe(take(1))
      .subscribe((floorplans: FileUsage[]) => {
        const sub2 = this.openAddonApartmentMarking({
          mode: FileUsageViewerMode.Mark,
          fileUsages: floorplans,
          editable: true,
          client: true,
          showFileUsageViewerComment: true,
          fileUsageViewerComment: this.comment,
          modelData: {
            model: MarkingModelType.AddonApartment,
            modelId: this.addonApartment.id,
          },
          markingData: {
            min: this.addonApartment.markingMin,
            max: this.addonApartment.markingMax,
          },
          text: `Marker hvor du vil ha "${this.addonApartment.Addon.name}"`, // @todo Translate
        }).subscribe((value: FileUsageViewerCloseData) => {
          if (value && value.valid) {
            this.getMarkings();
            this.add.emit({
              addonApartment: this.addonApartment,
              comment: value.comment,
            });
          }
        });

        this.subscriptions.push(sub2);
      });

    this.subscriptions.push(sub1);
  }

  openAddonApartmentMarking(data: FileUsageViewerData): Observable<FileUsageViewerCloseData> {
    return this.dialog
      .open(FileUsageViewerDialogComponent, {
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data,
      })
      .afterClosed();
  }

  isOnLastDayOrWeek(): void {
    const now = moment();
    const end = moment(this.addonApartment.dateEnd).endOf('day');
    const diff = end.diff(now, 'days');

    if (diff === 0) {
      this.isLastDayOrWeek = 'day';
    } else if (diff <= 7) {
      this.isLastDayOrWeek = 'week';
    } else {
      this.isLastDayOrWeek = null;
    }
  }

  calculateTimeLeft(): void {
    const now = moment();
    const end = moment(this.addonApartment.dateEnd).endOf('day');

    this.timeLeft = end.isValid() ? now.to(end, true) : '';
    this.hasExpired = now.isAfter(end);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  private getAddonImages(): void {
    const sub = this.clientService
      .getAddonImages(this.addonApartment.Addon)
      .pipe(take(1))
      .subscribe({
        next: (fus) => {
          this.fileUsages = fus;
        },
      });

    this.subscriptions.push(sub);
  }
}
