import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterOutlet } from '@angular/router';
import { PortalCaseListItemModule } from './item/portal-case-list-item.module';
import { PortalCaseListComponent } from './portal-case-list.component';

@NgModule({
  declarations: [PortalCaseListComponent],
  imports: [CommonModule, MatListModule, RouterOutlet, MatPaginatorModule, PortalCaseListItemModule],
  exports: [PortalCaseListComponent],
})
export class PortalCaseListModule {}
