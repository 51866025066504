import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogData, Product } from 'projects/apex/src/app/models/addon-cart';
import { quickDate } from 'projects/apex/src/app/utils/functions';

@Component({
  selector: 'apex-client-addon-alert-dialog',
  templateUrl: './alert-dialog.component.html',
})
export class ClientAddonAlertDialogComponent {
  expiredAddons: Product[];
  addonsExpireToday: Product[];
  addonsExpireTomorrow: Product[];

  quickDate = quickDate;

  constructor(
    public dialogRef: MatDialogRef<ClientAddonAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
  ) {}
}
