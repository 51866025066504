<ng-container *ngIf="profile$ | async as profile">
  <div class="df ac r aphs w-100">
    <div class="f1 df r ac">
      <button
        mat-icon-button
        type="button"
        (click)="toggle()">
        <mat-icon
          *ngIf="drawer.opened"
          [attr.aria-label]="'Close menu' | translate"
          >close
        </mat-icon>
        <mat-icon
          *ngIf="!drawer.opened"
          [attr.aria-label]="'Open menu' | translate"
          >menu
        </mat-icon>
      </button>
      <img
        src="../../assets/branding-apex-logo-h-fv2.svg"
        height="56"
        alt="APEX Logo" />

      <div class="f1">
        <apex-help-icon></apex-help-icon>
      </div>
    </div>
  </div>
</ng-container>
