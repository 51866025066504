import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { ValidateEqualDirective } from 'projects/apex/src/app/directives/validate-equal/validate-equal.directive';

const directives = [ValidateEqualDirective];

@NgModule({
  imports: [CommonModule, MatInputModule],
  providers: [],
  declarations: directives,
  exports: directives,
})
export class ValidateEqualDirectiveModule {}
