<nav
  [tabPanel]="tabPanel"
  mat-tab-nav-bar>
  <a
    mat-tab-link
    *ngFor="let link of links"
    [routerLink]="[link.url]"
    (click)="activeLink = link"
    [active]="activeLink === link">
    {{ link.translated }}
  </a>
</nav>
<mat-divider></mat-divider>

<mat-tab-nav-panel
  #tabPanel
  [routerLinkActiveOptions]="{ exact: true }"
  [routerLinkActive]="['active']">
  <section class="router-outlet-content">
    <router-outlet
      #portalOutlet="outlet"
      class="amt"></router-outlet>
  </section>
</mat-tab-nav-panel>
