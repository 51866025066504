<section class="list-container">
  <ng-container *ngIf="portalApartment | async as apartment">
    <apex-request-list
      [disableRequests]="apartment.Project.disableRequests || apartment.disableRequests"></apex-request-list>

    <portal-portal-case-list></portal-portal-case-list>

    <ng-container *ngIf="(hasCasesOrRequests$ | async) === false">
      <div class="df ac jc ap am">
        <T str="No cases or requests to show, create a new request and it will show up here"></T>
      </div>
    </ng-container>
  </ng-container>
</section>
