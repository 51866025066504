import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { FilePreviewModule } from '../../../../../../../apex/src/app/components/file-preview/file-preview.module';
import { FileViewerPreviewModule } from '../../../../../../../apex/src/app/components/file-viewer/preview/file-viewer-preview.module';
import { ItemModule } from '../../../../../../../apex/src/app/components/item/item.module';
import { TranslateModule } from '../../../../../../../apex/src/app/components/translate/translate.module';
import { CaseListItemWrapperModule } from '../../../../../../../apex/src/app/features/case/list-item-wrapper/case-list-item-wrapper.module';
import { PortalCaseListItemComponent } from './portal-case-list-item.component';

@NgModule({
  declarations: [PortalCaseListItemComponent],
  imports: [
    CommonModule,
    FileViewerPreviewModule,
    ItemModule,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
    RouterLink,
    CaseListItemWrapperModule,
    MatDialogModule,
    MatRippleModule,
    FilePreviewModule,
  ],
  exports: [PortalCaseListItemComponent],
})
export class PortalCaseListItemModule {}
