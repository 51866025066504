import { Component, Input } from '@angular/core';
import { ResponseFileUsageDto, ResponsePortalFileUsageDto } from '../../../../../../generated/apex-rest';
import { FileUsage } from '../../models/file-usage';

@Component({
  selector: 'apex-file-usage-attachments',
  templateUrl: './file-usage-attachments.component.html',
})
export class FileUsageAttachmentsComponent {
  @Input() fileUsages: Array<FileUsage | ResponseFileUsageDto | ResponsePortalFileUsageDto> = [];
}
