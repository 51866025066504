import { Injectable } from '@angular/core';
import { EMPTY, Observable, expand, from, map, mergeMap, toArray } from 'rxjs';
import { Apartment } from '../../../models/apartment';
import { ApartmentLog } from '../../../models/apartment-log';
import { APIService } from '../../../services/http/http.service';
import { createApiUrl } from '../../../utils/functions';
import { CollectionResponse, DeleteResponse, EntityResponse } from '../../../utils/types';

@Injectable()
export class ProjectApartmentService extends APIService<Apartment> {
  route = 'apartment';

  getApartment(ProjectId: number | string, ApartmentId: number | string): Observable<EntityResponse<Apartment>> {
    if (!ProjectId || !ApartmentId) {
      throw new Error('ProjectId and ApartmentId is required');
    }

    this.route = `project/${ProjectId}/apartment`;

    return super.get(ApartmentId);
  }

  queryApartments(projectId: number, page: number, limit: number): Observable<CollectionResponse<Apartment>> {
    if (!projectId) {
      throw new Error('ProjectId is required');
    }

    this.route = `project/${projectId}/apartment`;

    return super.query({
      params: {
        page: String(page),
        limit: String(limit),
      },
    });
  }

  queryAllApartments(projectId: number): Observable<Apartment[]> {
    if (!projectId) {
      throw new Error('ProjectId is required');
    }

    // Query page by page until all apartments are fetched
    return this.queryApartments(projectId, 0, 25).pipe(
      expand((res) => {
        if (res.next) {
          return this.queryApartments(projectId, res.page + 1, res.limit);
        }

        return EMPTY;
      }),
      mergeMap((res) => from(res.Collection).pipe(map((r) => r))),
      toArray(),
    );
  }

  saveApartment(model: Apartment, projectId: number): Observable<EntityResponse<Apartment>> {
    if (!model || !projectId) {
      throw new Error('Apartment and ProjectId is required');
    }

    this.route = `project/${projectId}/apartment`;

    return super.save(model);
  }

  deleteApartment(id: number | string, projectId: number): Observable<DeleteResponse> {
    if (!id || !projectId) {
      throw new Error('ApartmentId and ProjectId is required');
    }

    this.route = `project/${projectId}/apartment`;

    return super.delete(id);
  }

  getApartmentLog(apartmentId: number, projectId: number): Observable<ApartmentLog[]> {
    if (!apartmentId || !projectId) {
      throw new Error('ApartmentId and ProjectId is required');
    }

    const url = `${this.apiUrl}/project/${projectId}/apartment/${apartmentId}/log`;

    return this.http.get<ApartmentLog[]>(url, this.options());
  }

  sendClientInvites(projectId: number, apartmentIds: number[]): Observable<void> {
    if (!projectId || !apartmentIds?.length) {
      throw new Error('projectId and apartmentIds are required');
    }

    return this.http.post<void>(createApiUrl('project', projectId, 'client-invite'), apartmentIds, this.options());
  }
}
