import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import { t } from '../../components/translate/translate.function';

export const apexValidateEqual =
  (value: string | number): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (!control?.value) {
      return null;
    }

    if (control.value !== value) {
      return {
        equal: t('Values are not equal'),
      };
    }

    return null;
  };

@Directive({
  selector: '[apexValidateEqual]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidateEqualDirective,
      multi: true,
    },
  ],
})
export class ValidateEqualDirective implements Validator {
  @Input() apexValidateEqual: string | number;

  validate(control: AbstractControl): ValidationErrors | null {
    return apexValidateEqual(this.apexValidateEqual)(control);
  }
}
