import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale } from 'projects/apex/src/app/utils/functions';
import { localeInitializer } from 'projects/apex/src/app/utils/locale-init';
import { environment } from 'projects/apex/src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationService } from '../../../apex/src/app/components/translate/translation.service';

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'portal';

  mode$: Observable<'side' | 'over'>;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translationService: TranslationService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.assetsUrl}/icons.svg`),
    );

    void localeInitializer(locale());

    void this.translationService.init({
      token: environment.transifexToken,
    });
  }

  async ngOnInit(): Promise<void> {
    this.mode$ = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
      map((res) => {
        const xsmall = res.breakpoints[Breakpoints.XSmall];

        return xsmall ? 'over' : 'side';
      }),
    );

    try {
      await this.translationService.setCurrentLocale(locale());
    } catch (err) {
      console.error('Failed to get translations', err);
    }
  }
}
